<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container class="p-consumption-detail" :key="$route.fullPath">
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->

                <ab-main-title :main-text="$t('ofex.titleSectionOfex.tusConsumos')" />
                <p class="subtitle">{{ $t("ofex.ConsumptionsDetailView.subtitle") }}</p>
                <!--Select Contract -->
                <ab-select-contract :reload-page="false" />
                <ab-your-consumption :show-when-not-data="false" v-if="selectedContractNumber && selectedContractTelerecording" :key="selectedContractNumber" />

                <ab-dropdown-section
                    v-if="showDomesticModule"
                    v-model="domesticConsumptionDropdownOpen"
                    collapse-id="domestic-contracts-collapsable-section"
                    :label="$t('ofex.consumptionCalculatedView.subtitle')"
                >
                    <ab-domestic-consumption
                        v-if="userInvoices && userInvoices.length > 0 && selectedContractNumber"
                        :key="selectedContractNumber"
                    ></ab-domestic-consumption>

                    <ab-without-data class="mt-3" v-else :text="$t('ofex.consumptionCalculatedView.no-bill')" />
                </ab-dropdown-section>

                <ab-dropdown-section
                    v-model="consumptionTendencyDropdownOpen"
                    collapse-id="tendency-contracts-collapsable-section"
                    :label="$t('ofex.consumptionCalculatedView.consumption-last-bill-title')"
                >
                    <ab-tendency-consumption v-if="userInvoices && userInvoices.length > 0"></ab-tendency-consumption>
                    <ab-without-data class="mt-3" v-else :text="$t('ofex.consumptionCalculatedView.no-bill')" />
                </ab-dropdown-section>

                <ab-dropdown-section
                    v-model="evolutionChartVisible"
                    collapse-id="collapse-evolution-root"
                    :label="$t('ofex.ConsumptionsDetailView.evolucionConsumo')"
                >
                    <div v-if="userInvoices && userInvoices.length > 0">
                        <div class="mt-3">
                            <img
                                src="../assets/images/svg/chevron.svg"
                                alt="Boton desplegable seccion consumos de tres años"
                                title="img-chevron"
                                class="chevron ml-2"
                            />
                            <span class="text-subtitle ml-2">{{ $t("ofex.ConsumptionsDetailView.consumoThreeYears") }}</span>
                            <p class="text-detail-black space-left mt-3">{{ $t("ofex.ConsumptionsDetailView.continuacionThreeYears") }}</p>
                        </div>

                        <ab-consumption-evolution-chart :user-invoices="userInvoices" v-if="evolutionChartVisible" />

                        <div class="legend-container">
                            <span class="legend item1">{{ $t("ofex.ConsumptionsDetailView.facConsumoReal") }}</span>
                            <span class="legend item2">{{ $t("ofex.ConsumptionsDetailView.facConsumoEstimado") }}</span>
                        </div>
                        <div class="d-flex flex-row-reverse mt-3 mb-4">
                            <div class="size-button">
                                <ab-button-ofex
                                    :click-event="generateExcelConsumptionList"
                                    :font-awesome-icon="'download'"
                                    :text-button="$t('ofex.ConsumptionsDetailView.datosGraficas')"
                                ></ab-button-ofex>
                            </div>
                        </div>
                    </div>
                    <ab-without-data class="mt-3" :text="$t('ofex.withoutData.lastInvoice')" v-else />

                    <div class="consumption-section daily-evolution" v-if="selectedContractTelerecording">
                        <div class="w-100 line-bottom"></div>
                        <div class="mt-3">
                            <img src="../assets/images/svg/chevron.svg" class="chevron ml-2" alt="Boton desplegable seccion consumos diarios" />
                            <span class="text-subtitle ml-2">{{ $t("ofex.ConsumptionsDetailView.evolucionDiaria") }}</span>
                            <div class="toggle-container flex-wrap">
                                <div class="toggle-container-inner ml-left-auto">
                                    <span class="text-readings">{{ $t("ofex.ConsumptionsDetailView.toggleLectura") }}</span>
                                    <toggle-button
                                        :color="{ checked: '#75c791', unchecked: '#75c791', disabled: '#CCCCCC' }"
                                        :height="20"
                                        :width="40"
                                        v-model="dailyEvolutionToggleConsumptions"
                                        title="favoritos"
                                        aria-label="check-favoritos"
                                    />
                                    <span class="text-consumption">{{ $t("ofex.ConsumptionsDetailView.toggleConsumo") }}</span>
                                </div>
                                <div class="text-detail-container">
                                    <p class="text-detail-black space-left mt-3">
                                        {{ $t("ofex.ConsumptionsDetailView.seleccionaIntervaloDiarias") }}
                                    </p>
                                </div>
                            </div>
                            <div class="graphic-filter">
                                <div class="item">
                                    <div class="radio-multi-container">
                                        <div class="radio-multi-inner">
                                            <div class="radio-multi">
                                                <input
                                                    v-model="dailyEvolutionFilter.type"
                                                    checked="checked"
                                                    class=""
                                                    id="filter-type-invoices"
                                                    name="filter-type-invoices"
                                                    type="radio"
                                                    :value="'INVOICE'"
                                                />
                                                <label class="label-filter" for="filter-type-invoices">
                                                    {{ $t("ofex.ConsumptionsDetailView.graficosPorFactura") }}</label
                                                >
                                            </div>
                                            <div class="radio-multi">
                                                <input
                                                    v-model="dailyEvolutionFilter.type"
                                                    checked="checked"
                                                    class=""
                                                    id="filter-type-monthyear"
                                                    name="filter-type-monthyear"
                                                    type="radio"
                                                    :value="'MONTHYEAR'"
                                                />
                                                <label class="label-filter" for="filter-type-monthyear">{{
                                                    $t("ofex.ConsumptionsDetailView.graficaMesAno")
                                                }}</label>
                                            </div>
                                            <div class="radio-multi mb-0">
                                                <input
                                                    id="filter-type-weekyear"
                                                    v-model="dailyEvolutionFilter.type"
                                                    class=""
                                                    name="filter-type-weekyear"
                                                    type="radio"
                                                    :value="'WEEKYEAR'"
                                                />
                                                <label class="label-filter" for="filter-type-weekyear">{{
                                                    $t("ofex.ConsumptionsDetailView.graficaSemanaAno")
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item data">
                                    <div class="border-before dates-select-container">
                                        <section class="datepicker-container">
                                            <ab-select
                                                v-model="dailyEvolutionFilter.dates"
                                                class="consumption-select-margin"
                                                :data-list="hasInvoices ? consumptionInvoiceTypeSelect : null"
                                                data-key="invoiceNumber"
                                                data-value="key"
                                                :editable="dailyEvolutionFilter.type === 'INVOICE'"
                                                aria-label="datepicker-select"
                                                @hook:mounted="preselectDailyEvolutionFilterFirstValue"
                                            />
                                        </section>
                                    </div>
                                    <div class="border-before">
                                        <date-picker
                                            v-model="dailyEvolutionFilter.monthyear"
                                            type="month"
                                            value-type="date"
                                            :range="false"
                                            :placeholder="$t('ofex.invoiceView.dateRange')"
                                            :format="'MMM YYYY'"
                                            :title-format="'DD-MM-YYYY'"
                                            :lang="$i18n.locale"
                                            :disabled-date="monthlyDailyEvolutionFilterMinCalendarDate"
                                            :clearable="false"
                                            :disabled="dailyEvolutionFilter.type !== 'MONTHYEAR'"
                                        ></date-picker>
                                    </div>
                                    <div class="border-before">
                                        <date-picker
                                            v-model="dailyEvolutionFilter.weekyear"
                                            type="week"
                                            value-type="date"
                                            :range="false"
                                            :placeholder="$t('ofex.invoiceView.dateRange')"
                                            :formatter="formatWeekDate"
                                            :title-format="'DD-MM-YYYY'"
                                            :lang="$i18n.locale"
                                            :clearable="false"
                                            :disabled-date="weeklyDailyEvolutionFilterMinCalendarDate"
                                            :disabled="dailyEvolutionFilter.type !== 'WEEKYEAR'"
                                        ></date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <b-tabs content-class="mt-3 mb-4" ref="chartTabs">
                                <b-tab :title="$t('ofex.ConsumptionsDetailView.graficaConsumos')" active>
                                    <ab-consumption-timeline-chart
                                        ref="consumptionTimeLineChart"
                                        consumption-frequency="DAILY"
                                        :filter-dates="dailyEvolutionFilterDates"
                                        :value-property-name="dailyEvolutionValueProperty"
                                        :begin-at-zero="dailyEvolutionToggleConsumptions"
                                        v-if="evolutionChartVisible && $refs.chartTabs && $refs.chartTabs.currentTab === 0 && isDailyChartReady"
                                    />
                                    <div class="legend-container">
                                        <!--                                                    <span class="legend item1">{{ $t("ofex.ConsumptionsDetailView.facConsumoReal") }}</span>-->
                                        <!--                                                    <span class="legend item2">{{ $t("ofex.ConsumptionsDetailView.facConsumoEstimado") }}</span>-->
                                    </div>
                                    <div class="d-flex flex-row-reverse mt-3">
                                        <div class="size-button">
                                            <ab-button-ofex
                                                :click-event="generateExcelConsumptionChartList"
                                                :font-awesome-icon="'download'"
                                                :text-button="$t('ofex.ConsumptionsDetailView.datosGraficas')"
                                            ></ab-button-ofex>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab :title="$t('ofex.ConsumptionsDetailView.graficaConsumoMinimo')">
                                    <ab-consumption-timeline-chart
                                        ref="consumptionMinTimeLineChart"
                                        consumption-frequency="DAILY"
                                        :filter-dates="dailyEvolutionFilterDates"
                                        :y-axe-label="$t('modules.consumptionTimelineChart.minFlowYLabel')"
                                        value-property-name="minFlow"
                                        v-if="evolutionChartVisible && $refs.chartTabs && $refs.chartTabs.currentTab === 1 && isDailyChartReady"
                                    />
                                    <div class="d-flex flex-row-reverse mt-3">
                                        <div class="size-button">
                                            <ab-button-ofex
                                                :click-event="generateMinExcelConsumptionChartList"
                                                :font-awesome-icon="'download'"
                                                :text-button="$t('ofex.ConsumptionsDetailView.datosGraficas')"
                                            ></ab-button-ofex>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>

                    <div class="consumption-section hourly-evolution" v-if="selectedContractTelerecording">
                        <div class="w-100 line-bottom"></div>
                        <div class="mt-3">
                            <img src="../assets/images/svg/chevron.svg" class="chevron ml-2" alt="Boton desplegable seccion consumos horarios" />
                            <span class="text-subtitle ml-2">{{ $t("ofex.ConsumptionsDetailView.evolucionHoraria") }}</span>
                            <div class="toggle-container">
                                <div class="evolution-container">
                                    <span class="text-detail-black space-left">{{ $t("ofex.ConsumptionsDetailView.consumosHorarios") }}</span>
                                    <div class="box-datepicker pl-3">
                                        <ab-datepicker
                                            name="hourly-consumption-datepicker"
                                            input-alias="hourly-consumption-datepicker"
                                            label="input-datepicker"
                                            class="input-datepicker hourly-consumption-datepicker"
                                            date-format="DD-MM-YYYY"
                                            :popover="$t('ofex.ConsumptionsDetailView.seleccionaIntervaloHorarias')"
                                            :min-date="hourlyFilterMinCalendarDate()"
                                            v-model="hourlyEvolutionFilter.chooseDate"
                                        />
                                    </div>
                                </div>
                                <div class="toggle-container-inner">
                                    <span class="text-readings">{{ $t("ofex.ConsumptionsDetailView.toggleLectura") }}</span>
                                    <toggle-button
                                        :color="{ checked: '#75c791', unchecked: '#75c791', disabled: '#CCCCCC' }"
                                        :height="20"
                                        :width="40"
                                        v-model="hourlyEvolutionToggleConsumptions"
                                        title="favoritos"
                                        aria-label="check-favoritos"
                                    />
                                    <span class="text-consumption">{{ $t("ofex.ConsumptionsDetailView.toggleConsumo") }}</span>
                                </div>
                            </div>

                            <ab-consumption-timeline-chart
                                ref="consumptionHourTimeLineChart"
                                consumption-frequency="HOURLY"
                                :filter-dates="hourlyEvolutionFilterDates"
                                :value-property-name="hourlyEvolutionValueProperty"
                                :begin-at-zero="hourlyEvolutionToggleConsumptions"
                                v-if="evolutionChartVisible && hourlyEvolutionFilter.chooseDate"
                            />
                            <div class="d-flex mt-3 consumption-action">
                                <span class="text">{{ $t("ofex.ConsumptionsDetailView.hourlyXlsDownload") }}</span>
                                <div class="size-button">
                                    <ab-button-ofex
                                        :click-event="generateExcelHourConsumptionChartList"
                                        :font-awesome-icon="'download'"
                                        :text-button="$t('ofex.pagarFactura.descargar')"
                                    ></ab-button-ofex>
                                </div>
                            </div>
                            <div class="d-flex mt-3 consumption-action">
                                <span class="text">{{ $t("ofex.ConsumptionsDetailView.hourlyXlsDownloadComplete") }}</span>
                                <div class="size-button">
                                    <ab-button-ofex
                                        :click-event="generateCompleteExcelHourConsumptionChartList"
                                        :font-awesome-icon="'download'"
                                        :text-button="$t('ofex.pagarFactura.descargar')"
                                    ></ab-button-ofex>
                                </div>
                            </div>
                        </div>
                    </div>
                </ab-dropdown-section>

                <ab-dropdown-section
                    v-model="comparativeCollapseVisible"
                    collapse-id="collapse-comparative"
                    :label="$t('ofex.ConsumptionsDetailView.comparativaConsumos')"
                    v-if="selectedContractTelerecording"
                >
                    <div class="mt-3">
                        <div class="graphic-filter multi">
                            <div class="item">
                                <div class="radio-multi-container">
                                    <div class="radio-multi-inner">
                                        <div class="radio-multi">
                                            <input
                                                v-model="comparativeConsumptionsFilter.type"
                                                checked="checked"
                                                class=""
                                                id="filter-type-invoice-comparative"
                                                name="filter-type-invoice-comparative"
                                                type="radio"
                                                :value="'INVOICE'"
                                                ref="comparativeInvoiceRadio"
                                            />
                                            <label class="label-filter" for="filter-type-invoice-comparative">
                                                {{ $t("ofex.ConsumptionsDetailView.graficosPorFactura") }}</label
                                            >
                                        </div>
                                        <div class="radio-multi">
                                            <input
                                                v-model="comparativeConsumptionsFilter.type"
                                                checked="checked"
                                                class=""
                                                id="filter-type-monthyear-comparative"
                                                name="filter-type-monthyear-comparative"
                                                type="radio"
                                                :value="'MONTHYEAR'"
                                            />
                                            <label class="label-filter" for="filter-type-monthyear-comparative">{{
                                                $t("ofex.ConsumptionsDetailView.graficaMesAno")
                                            }}</label>
                                        </div>
                                        <div class="radio-multi mb-0">
                                            <input
                                                id="filter-type-weekyear-comparative"
                                                v-model="comparativeConsumptionsFilter.type"
                                                class=""
                                                name="filter-type-weekyear-comparative"
                                                type="radio"
                                                :value="'WEEKYEAR'"
                                            />
                                            <label class="label-filter" for="filter-type-weekyear-comparative">{{
                                                $t("ofex.ConsumptionsDetailView.graficaSemanaAno")
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item data mb-3">
                                <div class="border-before dates-select-container">
                                    <section class="datepicker-container">
                                        <ab-select
                                            v-model="comparativeConsumptionsFilter.first.dates"
                                            :data-list="hasInvoices ? invoiceTypeSelect : null"
                                            :editable="comparativeConsumptionsFilter.type === 'INVOICE'"
                                            @hook:mounted="preselectComparativeConsumptionsFilterValue"
                                            label="Factura 1"
                                        />
                                    </section>
                                </div>
                                <div class="border-before">
                                    <label class="label-datepicker">Mes 1</label>
                                    <date-picker
                                        v-model="comparativeConsumptionsFilter.first.monthyear"
                                        type="month"
                                        value-type="date"
                                        :range="false"
                                        :placeholder="$t('ofex.invoiceView.dateRange')"
                                        :format="'MMM YYYY'"
                                        :title-format="'DD-MM-YYYY'"
                                        :lang="$i18n.locale"
                                        :clearable="false"
                                        :disabled="comparativeConsumptionsFilter.type !== 'MONTHYEAR'"
                                    ></date-picker>
                                </div>
                                <div class="border-before">
                                    <label class="label-datepicker">Semana 1</label>
                                    <date-picker
                                        v-model="comparativeConsumptionsFilter.first.weekyear"
                                        type="week"
                                        value-type="date"
                                        :range="false"
                                        :placeholder="$t('ofex.invoiceView.dateRange')"
                                        :formatter="formatWeekDate"
                                        :title-format="'DD-MM-YYYY'"
                                        :lang="$i18n.locale"
                                        :clearable="false"
                                        :disabled-date="weeklyDailyEvolutionFilterMinCalendarDate"
                                        :disabled="comparativeConsumptionsFilter.type !== 'WEEKYEAR'"
                                    ></date-picker>
                                </div>
                            </div>
                            <div class="item data">
                                <div class="border-before dates-select-container">
                                    <section class="datepicker-container">
                                        <ab-select
                                            v-model="comparativeConsumptionsFilter.second.dates"
                                            :data-list="hasInvoices ? invoiceTypeSelect : null"
                                            :editable="comparativeConsumptionsFilter.type === 'INVOICE'"
                                            label="Factura 2"
                                        />
                                    </section>
                                </div>
                                <div class="border-before">
                                    <label class="label-datepicker">Mes 2</label>
                                    <date-picker
                                        v-model="comparativeConsumptionsFilter.second.monthyear"
                                        type="month"
                                        value-type="date"
                                        :range="false"
                                        :placeholder="$t('ofex.invoiceView.dateRange')"
                                        :format="'MMM YYYY'"
                                        :title-format="'DD-MM-YYYY'"
                                        :lang="$i18n.locale"
                                        :clearable="false"
                                        :disabled="comparativeConsumptionsFilter.type !== 'MONTHYEAR'"
                                    ></date-picker>
                                </div>
                                <div class="border-before">
                                    <label class="label-datepicker">Semana 2</label>
                                    <date-picker
                                        v-model="comparativeConsumptionsFilter.second.weekyear"
                                        type="week"
                                        value-type="date"
                                        :range="false"
                                        :placeholder="$t('ofex.invoiceView.dateRange')"
                                        :formatter="formatWeekDate"
                                        :title-format="'DD-MM-YYYY'"
                                        :lang="$i18n.locale"
                                        :clearable="false"
                                        :disabled-date="weeklyDailyEvolutionFilterMinCalendarDate"
                                        :disabled="comparativeConsumptionsFilter.type !== 'WEEKYEAR'"
                                    ></date-picker>
                                </div>
                            </div>
                        </div>
                        <ab-line-consumption-comparative
                            v-if="comparativeCollapseVisible && isComparativeChartReady"
                            :first-filter-dates="firstComparativeFilterDates"
                            :second-filter-dates="secondComparativeFilterDates"
                        />
                        <div class="legend-container" v-if="firstComparativeFilterDates !== null && secondComparativeFilterDates !== null">
                            <span class="legend consumption1">{{ legendMessageComparativeFirstFilter }}</span>
                            <span class="legend consumption2">{{ legendMessageComparativeSecondFilter }}</span>
                        </div>
                    </div>
                </ab-dropdown-section>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.beneficiosTelelectura.name')"
                    :href-left="$t('ofex.relatedContents.beneficiosTelelectura.url')"
                    :image-left="$t('ofex.relatedContents.beneficiosTelelectura.image')"
                    :name-center="$t('ofex.relatedContents.consellEstalvi.name')"
                    :href-center="$t('ofex.relatedContents.consellEstalvi.url')"
                    :image-center="$t('ofex.relatedContents.consellEstalvi.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import AbMainTitle from "../modules/AbMainTitle.vue";
import AbSelectContract from "../modules/AbSelectContract.vue";
import AbYourInterest from "../modules/AbYourInterest.vue";
import AbYourConsumption from "../modules/AbYourConsumption.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ca";
import "vue2-datepicker/locale/es";
import "vue2-datepicker/index.css";
import moment from "moment";

import { mapGetters, mapState } from "vuex";
import * as actionTypes from "@/store/action-types";
import AbConsumptionTimelineChart from "@/modules/charts/AbConsumptionTimelineChart";
import ConsumptionEvolutionListXlsxGenerator from "@/generators/xlsx/ConsumptionEvolutionListXlsxGenerator";
import ConsumptionEvolutionChartListXlsxGenerator from "@/generators/xlsx/ConsumptionEvolutionChartListXlsxGenerator";

import { AB_DATE_FORMAT, AB_DATETIME_FRIENDLY_FORMAT, AB_DATETIME_WITHOUT_SECONDS_FORMAT } from "@/plugins/ab-utils";
import ConsumptionsServiceClient from "@/rest/consumptionsServiceClient";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import AbTendencyConsumption from "@/modules/AbTendencyConsumption.vue";
import AbDomesticConsumption from "@/modules/AbDomesticConsumption.vue";

export default {
    name: "ConsumptionsDetailView",
    components: {
        AbDomesticConsumption,
        AbTendencyConsumption,
        AbConsumptionTimelineChart,
        AbMainTitle,
        AbSelectContract,
        AbYourInterest,
        AbYourConsumption,
        DatePicker,
    },
    data() {
        return {
            domesticConsumptionDropdownOpen: false,
            consumptionTendencyDropdownOpen: false,
            evolutionChartVisible: false,
            comparativeCollapseVisible: false,
            showEvolution: false,
            showCompare: false,
            dailyEvolutionToggleConsumptions: true,
            hourlyEvolutionToggleConsumptions: true,
            dailyEvolutionFilter: {
                type: "INVOICE", //INVOICE, MONTHYEAR, WEEKYEAR
                dates: null,
                monthyear: new Date(),
                weekyear: new Date(),
            },
            hourlyEvolutionFilter: {
                chooseDate: new Date(),
                dates: null,
            },
            comparativeConsumptionsFilter: {
                type: "INVOICE", //INVOICE, MONTHYEAR, WEEKYEAR
                first: {
                    dates: null,
                    monthyear: new Date(),
                    weekyear: new Date(),
                },
                second: {
                    dates: null,
                    monthyear: moment().subtract(1, "month").toDate(),
                    weekyear: moment().subtract(1, "week").toDate(),
                },
            },
            formatWeekDate: {
                //[optional] Date to String
                stringify: (date) => {
                    if (date) {
                        return `${moment(date).startOf("week").format(AB_DATE_FORMAT)} a ${moment(date).endOf("week").format(AB_DATE_FORMAT)}`;
                    } else {
                        return "";
                    }
                },
            },
        };
    },
    methods: {
        monthlyDailyEvolutionFilterMinCalendarDate(date) {
            return moment(date).isBefore(
                this.hasInvoices
                    ? this.consumptionTelerecordingFilterMinDate(this.userInvoices[0]).startOf("month")
                    : this.consumptionTelerecordingFilterMinDateWhenNotHasInvoices().startOf("month")
            );
        },
        weeklyDailyEvolutionFilterMinCalendarDate(date) {
            return moment(date).isBefore(
                this.hasInvoices
                    ? this.consumptionTelerecordingFilterMinDate(this.userInvoices[0]).startOf("week")
                    : this.consumptionTelerecordingFilterMinDateWhenNotHasInvoices().startOf("week")
            );
        },
        hourlyFilterMinCalendarDate() {
            // return moment().subtract(3, "years").toDate();
            return this.hasInvoices
                ? this.consumptionTelerecordingFilterMinDate(this.userInvoices[0]).toDate()
                : this.consumptionTelerecordingFilterMinDateWhenNotHasInvoices().toDate();
        },
        generateExcelConsumptionList: function () {
            let xlsxGenerator = new ConsumptionEvolutionListXlsxGenerator(`EvoluciónConsumos.xlsx`, this.userInvoices, this.$i18n);
            console.log(xlsxGenerator);
        },
        generateExcelConsumptionChartList: function () {
            let xlsxGenerator = new ConsumptionEvolutionChartListXlsxGenerator(
                `Evolución-consumos-diario.xlsx`,
                this.$refs.consumptionTimeLineChart.userConsumptions,
                this.$i18n,
                AB_DATETIME_WITHOUT_SECONDS_FORMAT,
                false,
                false
            );
            console.log(xlsxGenerator);
        },
        generateMinExcelConsumptionChartList: function () {
            let xlsxGenerator = new ConsumptionEvolutionChartListXlsxGenerator(
                `Evolución-consumos-minimo-diario.xlsx`,
                this.$refs.consumptionMinTimeLineChart.userConsumptions,
                this.$i18n,
                AB_DATETIME_WITHOUT_SECONDS_FORMAT,
                true,
                false
            );
            console.log(xlsxGenerator);
        },
        generateExcelHourConsumptionChartList: function () {
            let xlsxGenerator = new ConsumptionEvolutionChartListXlsxGenerator(
                `Evolución-consumos-horaria.xlsx`,
                this.$refs.consumptionHourTimeLineChart.userConsumptions,
                this.$i18n,
                AB_DATETIME_FRIENDLY_FORMAT,
                false,
                true
            );
            console.log(xlsxGenerator);
        },
        generateCompleteExcelHourConsumptionChartList: async function () {
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: true, trigger: "ConsumptionDetailView" });

            let completeFromDate = moment(this.hourlyEvolutionFilter.chooseDate).subtract(6, "days").format(AB_DATE_FORMAT);
            let completeEndDate = moment(this.hourlyEvolutionFilter.chooseDate).add(1, "day").endOf("day").format(AB_DATE_FORMAT);

            let completeHourlyConsumptionsResponse = await ConsumptionsServiceClient.getHourlyConsumptions(
                this.selectedContractNumber,
                this.clientId,
                this.userId,
                this.$i18n.locale,
                completeFromDate,
                completeEndDate,
                false
            );

            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "ConsumptionDetailView" });

            let xlsxGenerator = new ConsumptionEvolutionChartListXlsxGenerator(
                `Evolución-consumos-horaria.xlsx`,
                completeHourlyConsumptionsResponse.data,
                this.$i18n,
                AB_DATETIME_FRIENDLY_FORMAT,
                false,
                true
            );
            console.debug(xlsxGenerator);
        },
        preselectDailyEvolutionFilterFirstValue: function () {
            console.log("Evolución diaria - Preseleccionando primer valor disponible para tipo de factura " + this.dailyEvolutionFilter.type);
            if (this.hasInvoices) {
                this.dailyEvolutionFilter.dates = this.invoiceTypeSelect[0].key;
            }

            //preseleccionamos el día de los consumos horarios
            // this.hourlyEvolutionFilter.chooseDate = new Date();
        },
        preselectHourlyEvolutionFilterFirstValue: function () {
            console.log("Evolución horaria - Preseleccionando primer valor disponible");

            //preseleccionamos el día de los consumos horarios
            this.hourlyEvolutionFilter.chooseDate = new Date();
        },
        preselectComparativeConsumptionsFilterValue: function () {
            //preseleccionamos los datos de la gráfica comparativa
            console.log("Comparativa consumos - preseleccionando primer valor disponible para tipo de factura " + this.comparativeConsumptionsFilter.type);
            switch (this.comparativeConsumptionsFilter.type) {
                case "INVOICE":
                    if (this.hasInvoices) {
                        this.comparativeConsumptionsFilter.first.dates = this.invoiceTypeSelect[0].key;
                        if (this.invoiceTypeSelect[1]) {
                            this.comparativeConsumptionsFilter.second.dates = this.invoiceTypeSelect[1].key;
                        } else {
                            this.comparativeConsumptionsFilter.second.dates = null;
                        }
                        break;
                    }
                /* case "MONTHYEAR":
                    this.comparativeConsumptionsFilter.first.monthyear = new Date();
                    this.comparativeConsumptionsFilter.second.monthyear = new Date();
                    break;
                case "WEEKYEAR":
                    this.comparativeConsumptionsFilter.first.weekyear = new Date();
                    this.comparativeConsumptionsFilter.second.weekyear = new Date();
                    break;*/
            }
        },
        cleanFilters: function () {
            console.log("Limpiando filtro");
            this.dailyEvolutionFilter = {
                type: "INVOICE",
                dates: null,
            };
            this.hourlyEvolutionFilter = {
                chooseDate: null,
                dates: null,
            };
            this.comparativeConsumptionsFilter = {
                type: "INVOICE",
                first: {
                    dates: null,
                },
                second: {
                    dates: null,
                },
            };
        },
        buildFilters: function (filter, type) {
            switch (type) {
                case "INVOICE":
                    if (filter.dates) {
                        let datesArray = filter.dates.split(" ");
                        return {
                            fromDate: datesArray[0],
                            toDate: datesArray[1],
                        };
                    } else {
                        return null;
                    }
                case "MONTHYEAR":
                    return {
                        fromDate: moment(filter.monthyear).startOf("month").format("DD-MM-YYYY"),
                        toDate: moment(filter.monthyear).endOf("month").format("DD-MM-YYYY"),
                    };
                case "WEEKYEAR":
                    return {
                        fromDate: moment(filter.weekyear).startOf("week").format("DD-MM-YYYY"),
                        toDate: moment(filter.weekyear).endOf("week").format("DD-MM-YYYY"),
                    };
                default:
                    return null;
            }
        },
    },
    computed: {
        firstComparativeFilterDates: function () {
            return this.buildFilters(this.comparativeConsumptionsFilter.first, this.comparativeConsumptionsFilter.type);
        },
        secondComparativeFilterDates: function () {
            return this.buildFilters(this.comparativeConsumptionsFilter.second, this.comparativeConsumptionsFilter.type);
        },
        dailyEvolutionFilterDates: function () {
            return this.buildFilters(this.dailyEvolutionFilter, this.dailyEvolutionFilter.type);
        },
        dailyEvolutionValueProperty: function () {
            return this.dailyEvolutionToggleConsumptions ? "deltaConsumption" : "accumulatedConsumption";
        },
        hourlyEvolutionValueProperty: function () {
            return this.hourlyEvolutionToggleConsumptions ? "deltaConsumption" : "accumulatedConsumption";
        },
        hourlyEvolutionFilterDates: function () {
            return {
                fromDate: moment(this.hourlyEvolutionFilter.chooseDate).startOf("day").format("DD-MM-YYYY"),
                toDate: moment(this.hourlyEvolutionFilter.chooseDate).add(1, "day").startOf("day").format("DD-MM-YYYY"),
            };
        },
        legendMessageComparativeFirstFilter: function () {
            return this.$i18n.t("ofex.ConsumptionsDetailView.comparativeDynamicLegendMessage", {
                startDate: this.firstComparativeFilterDates.fromDate,
                endDate: this.firstComparativeFilterDates.toDate,
            });
        },
        legendMessageComparativeSecondFilter: function () {
            return this.$i18n.t("ofex.ConsumptionsDetailView.comparativeDynamicLegendMessage", {
                startDate: this.secondComparativeFilterDates.fromDate,
                endDate: this.secondComparativeFilterDates.toDate,
            });
        },
        isDailyChartReady: function () {
            // return this.dailyEvolutionFilter.type !== "INVOICE" || this.dailyEvolutionFilter.dates;
            return this.dailyEvolutionFilter.type !== "INVOICE" || this.dailyEvolutionFilter.dates;
        },
        isComparativeChartReady: function () {
            return this.firstComparativeFilterDates !== null && this.secondComparativeFilterDates !== null;
        },
        showDomesticModule: function () {
            return this.selectedContractIsDomestic && this.droughtConfiguration?.domesticConsumption?.showModule;
        },
        ...mapState(["userData", "isLoading", "clientData", "userInvoices", "selectedContractNumber", "contracts", "droughtConfiguration"]),
        ...mapGetters([
            "selectedContractTelerecording",
            "invoiceTypeSelect",
            "consumptionInvoiceTypeSelect",
            "consumptionTelerecordingFilterMinDate",
            "consumptionTelerecordingFilterMinDateWhenNotHasInvoices",
            "hasInvoices",
            "clientId",
            "userId",
            "selectedContractIsDomestic",
        ]),
    },
    watch: {
        "dailyEvolutionFilter.type": function (type) {
            console.log("Cambiando el tipo de filtro de evolución de consumos: " + type);
            this.preselectDailyEvolutionFilterFirstValue();
        },
        "comparativeConsumptionsFilter.type": function (type) {
            console.log("Cambiando el tipo de filtro de comparativa de consumos: " + type);
            this.preselectComparativeConsumptionsFilterValue();
        },
        userInvoices: function (invoices) {
            console.log("Detectando facturas" + invoices);
            if (!(invoices && invoices.length > 0)) {
                this.dailyEvolutionFilter.type = "MONTHYEAR";
                this.comparativeConsumptionsFilter.type = "MONTHYEAR";

                //esperamos un momento porque sino se produce una condición de carrera y a veces no detecta al elemento
                setTimeout(function () {
                    document.getElementById("filter-type-invoices").disabled = true;
                    document.getElementById("filter-type-invoice-comparative").disabled = true;
                }, 500);
            }
        },
    },
    created() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, activeContract: this.$route.params.contractId });
        this.$store.dispatch(actionTypes.GET_DROUGHT_CONFIGURATION_ACTION, { locale: this.$i18n.locale });
    },
    mounted() {
        console.log(`Consumos del contrato ${this.$route.params.contractId}`);

        let vueContext = this;
        // Hook para cuando se cambie el contrato
        this.$root.$on(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, (data) => {
            console.log("Ha cambiado el contrato " + data);
            vueContext.cleanFilters();
            vueContext.preselectDailyEvolutionFilterFirstValue();
            vueContext.preselectComparativeConsumptionsFilterValue();
            vueContext.preselectHourlyEvolutionFilterFirstValue();
        });

        this.$nextTick(function () {
            this.domesticConsumptionDropdownOpen = this.showDomesticModule;
            this.consumptionTendencyDropdownOpen = !this.showDomesticModule;
        });
    },
    beforeDestroy() {
        //limpiar filtros y estado de la vista
    },
};
</script>

<style lang="scss" scoped>
.consumption-select-margin {
    margin-top: -25px;
}
@media (max-width: 767px) {
    .consumption-select-margin {
        margin-top: -7px;
        margin-bottom: -1px;
    }
}
.p-consumption-detail {
    .m-select-your-contract {
        border-bottom: 1px solid $blue-dark;
        padding-bottom: 25px;
    }
    .m-your-consumption {
        padding: 20px 0;

        &.box {
            margin-bottom: 15px;
        }
    }
    .m-your-interest {
        margin-top: 90px;
    }
    .legend-linear-graphic {
        .text {
            @include font-size(12);
            @include line-height(14);
            font-weight: $font-primary-bold;
        }
    }
    .graphic-filter {
        display: flex;
        flex-wrap: wrap;
        background-color: #f8f8f8;
        padding: 15px;
        margin-bottom: 20px;

        &.multi {
            .item.data {
                .border-before:before {
                    top: 20px;
                }
            }
        }
        .item:first-child {
            flex-basis: 100%;
        }
        .item {
            flex-basis: 100%;

            &.data {
                display: flex;
                justify-content: space-between;
                @include respond-below(sm) {
                    flex-wrap: wrap;
                }

                > div {
                    position: relative;
                    flex-basis: 100%;

                    @include respond-above(sm) {
                        flex-basis: 30%;
                    }
                }
                .border-before {
                    @include respond-below(sm) {
                        margin-bottom: 20px;
                    }
                }
                .border-before:before {
                    background: $blue-dark;
                    position: absolute;
                    width: 1px;
                    height: 42px;
                    right: -8.5%;
                    top: -6px;
                    content: "";

                    @include respond-below(sm) {
                        content: none;
                    }
                }
                .border-before:last-child:before {
                    content: none;
                }
            }
        }
    }
    .toggle-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;

        @include respond-below(sm) {
            flex-wrap: wrap;
        }

        .ml-left-auto {
            margin-left: auto;
        }

        .text-detail-container {
            @include respond-below(sm) {
                display: none;
            }
        }

        .text-detail-black {
            @include respond-below(sm) {
                order: 2;
            }
        }
        .toggle-container-inner {
            @include respond-below(sm) {
                order: 1;
                flex: 100%;
                margin-left: 19px;
                margin-top: 25px;
            }

            .text-readings {
                margin-right: 15px;
                color: $color-secondary;
            }
            .text-consumption {
                margin-left: 15px;
                color: $color-secondary;
            }
            .vue-js-switch {
                margin-top: -2px;
            }
        }
    }
    .consumption-section {
        .consumption-action {
            justify-content: flex-end;

            @include respond-below(sm) {
                flex-wrap: wrap;
            }

            .text {
                margin-right: 20px;

                @include respond-below(sm) {
                    flex: 100%;
                    margin-right: 0;
                    margin-bottom: 7px;
                }
            }
            .size-button {
                @include respond-below(sm) {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .evolution-container {
        display: flex;
        flex-wrap: wrap;

        @include respond-above(sm) {
            flex-direction: row;
            flex-wrap: nowrap;
        }
    }
    .chevron {
        width: 12px;
        height: 12px;
    }
    .text {
        .inner,
        .collapse {
            flex-basis: 100%;
        }
    }
    .text-subtitle {
        font-weight: $font-primary-bold;
        @include font-size(16);
        @include line-height(19);
        color: $blue-dark;
    }
    .text-detail-black {
        font-weight: $font-primary-medium;
        @include font-size(14);
        @include line-height(23);
        color: $color-primary;
    }
    .space-small-left {
        margin-left: 24px;
    }
    .space-left {
        @include respond-above(sm) {
            margin-left: 28px;
        }
    }
    .size-button {
        @include respond-below(sm) {
            width: 100%;
        }
    }
    .line-bottom {
        border-bottom: 1px solid $blue-dark;
    }
    .half-div {
        float: left;
        width: 117px;
    }

    .radio-multi-inner {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @include respond-below(sm) {
            flex-wrap: wrap;
        }
        .radio-multi {
            display: flex;
            align-items: center;
            cursor: pointer;
            flex-basis: 100%;
            margin-bottom: 20px;

            @include respond-above(sm) {
                flex-basis: 30%;
                margin-bottom: 0;
            }
        }
        .textdnormal {
            @include font-size(14);
            @include line-height(16);
            font-weight: $font-primary-bold;
            margin-bottom: 0;
            margin-left: 10px;
        }
        input {
            width: 20px;
            height: 20px;
        }
    }
    label {
        margin-bottom: 0px !important;
    }
    .label-filter {
        font-weight: $font-primary-bold;
        @include font-size(14);
        @include line-height(16);
        color: $color-primary;
        margin-left: 10px;
    }
    .box-datepicker {
        width: 175px;
    }
    .input-datepicker {
        height: 32px;
        border-radius: 8px;
    }
    .mx-datepicker {
        width: 100%;
    }
}
</style>
<style lang="scss">
.p-consumption-detail {
    .hourly-consumption-datepicker .inputConInfo {
        height: 32px;
        position: relative;
        font-weight: $font-primary-medium;
        @include font-size(14);
        @include line-height(16);
        color: $color-dark;
    }
    .c-datepicker label {
        display: none;
    }
    .no-bill {
        margin-top: 10px;
        display: flex;
        gap: 5px;

        .no-bill-text {
            width: 300px;
            color: #299ec2;
            font-size: 16px;
            font-weight: bold;
            margin: 0;
        }

        img {
            place-self: center;
            width: 40px;
            height: 40px;
        }
    }
}
</style>
