var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('ab-loading',{attrs:{"full-page":true,"loader-text":_vm.$t('ofex.loadingText.home')},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('b-row',[_c('ab-left-nav-bar'),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"9"}},[(_vm.$isWebApp())?_c('app-breadcrumbs'):_vm._e(),_c('ab-main-title',{attrs:{"main-text":_vm.$t('ofex.managementHistory.title')}}),_c('ab-user-actions-advance-search',{ref:"historicAdvanceSearch",attrs:{"user-actions":_vm.internalUserActions},on:{"filter-actions":_vm.executeFilter,"clear-filter":_vm.resetFilter}}),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.tableRows,"style-class":"vgt-table","pagination-options":{
                    enabled: true,
                    mode: 'records',
                    perPage: 10,
                    perPageDropdown: [10, 20, 30],
                    dropdownAllowAll: false,
                    setCurrentPage: 1,
                    nextLabel: this.$i18n.tc('table.siguiente'),
                    prevLabel: this.$i18n.tc('table.atras'),
                    rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                    ofLabel: 'de',
                    allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                    label: 'selector-de-paginas',
                    name: 'perPageSelect',
                }}},[_c('div',{staticClass:"text-center",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(_vm._s(this.$i18n.tc("ofex.userActionsView.noDataTable")))])]),_c('div',{staticClass:"d-md-flex justify-content-end mt-4 mb-4"},[_c('div',{staticClass:"button-invoices"},[_c('ab-button-ofex',{attrs:{"font-awesome-icon":'print',"text-button":_vm.$t('ofex.managementHistory.pdf'),"click-event":_vm.generatePdfUserActionsList}})],1),_c('div',{staticClass:"button-invoices ml-md-4 mt-2 mt-md-0"},[_c('ab-button-ofex',{attrs:{"font-awesome-icon":'download',"text-button":_vm.$t('ofex.managementHistory.excel'),"click-event":_vm.generateExcelActionList}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }