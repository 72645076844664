<template>
    <div>
        <div class="msg-error text-center mt-5" v-if="!showPassword">
            <img src="../assets/images/cerrado.svg" />
            <p class="texto-sentim">{{ $t("ofex.passwordReset.loSentimos") }}</p>
            <p class="text-subtittle">{{ $t("ofex.passwordReset.noHaPodido") }}</p>
        </div>
        <b-container>
            <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
            <div v-if="showPassword">
                <ab-main-title :main-text="$t('ofex.passwordRecovery.newPassword')"></ab-main-title>
                <ab-return />
                <p class="required-fields mb-3">
                    {{ $t("ofex.passwordRecovery.camposObligatorios") }}
                </p>
                <ValidationObserver v-slot="{ invalid }">
                    <div class="row">
                        <div class="col-12 col-lg-10">
                            <ab-password
                                v-model="newPassword"
                                name="ofex-password"
                                v-bind:input-alias="$t('ofex.UserData.contrasena')"
                                v-bind:repeat-input-alias="$t('ofex.UserData.repiteContrasena')"
                                ref="userChangePassword"
                            >
                            </ab-password>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="box-send">
                            <ab-button-ofex
                                :click-event="updateUserCredentials"
                                :text-button="$t('ofex.passwordRecovery.enviar')"
                                :disabled="invalid"
                            ></ab-button-ofex>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </b-container>
    </div>
</template>
<script>
import * as actionTypes from "../store/action-types";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import * as viewMappingTypes from "../router/view-mapping-types";
import { mapState } from "vuex";

import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
    name: "PasswordRecoveryPublicView",
    props: {
        fontawesomeIcon: {
            default: "arrow-circle-right",
        },
    },
    data: function () {
        return {
            token: "",
            showPassword: true,
            newPassword: "",
            currentPassword: null,
            showCompare: false,
            observations: "",
            viewMappingTypes: viewMappingTypes,
        };
    },
    computed: {
        ...mapState(["isLoading", "userData"]),
    },
    mounted() {
        this.token = this.$route.query.promo;
        this.token = this.token != null ? this.token.replace("/", "%") : (this.showPassword = false);
        this.$store
            .dispatch(actionTypes.GET_VALIDATE_TOKEN, {
                token: this.token,
            })
            .then(() => {
                this.showPassword = true;
            })
            .catch((reason) => this.launchValidToken(reason));
    },
    methods: {
        launchValidToken: function (e) {
            console.log("El token ha expedido o no es válido:" + e);
            this.showPassword = false;
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "PasswordResetView" });
        },
        updateUserCredentials: function () {
            this.$store
                .dispatch(actionTypes.UPDATE_USER_CREDENTIALS, {
                    userId: this.userData.documentNumber,
                    clientId: this.userData.documentNumber,
                    currentPassword: this.token,
                    newPassword: this.newPassword,
                    locale: this.$i18n.locale,
                })
                .then(() => this.launchUpdateUserCredentialsChangedToast())
                .catch((reason) => this.launchUpdateUserCredentialsErrorToast(reason));
        },
        launchUpdateUserCredentialsChangedToast: function () {
            console.log("Lanzando mensaje de OK");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "EmailRecoveryView" });
            Swal.fire({
                text: this.$i18n.tc("ofex.toasts.credentialsChangedOK"),
                icon: "success",
                confirmButtonText: "OK",
            }).then(() => {
                this.$router.push(viewMappingTypes.LOGIN_VIEW_MAPPING.path);
            });
        },
        launchUpdateUserCredentialsErrorToast: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "UserDataView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.credentialsChangedError"),
                text: e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
    },
};
</script>

<style lang="scss">
.box-send {
    width: 193px;
    @include respond-below(md) {
        width: 100%;
    }
}
.subtitle-blue {
    @include font-size(14);
    @include line-height(28);
    color: $blue-dark;
    font-weight: $font-primary-bold;
}
.space-bottom {
    margin-bottom: 15px;
    @include respond-below(md) {
        margin-bottom: 10px;
    }
}
.padd-bottom {
    padding-bottom: 50px;
}
.line-bottom {
    border-bottom: 1px solid $blue-lighter;
}

.msg-error {
    background-color: $blue-lighter;
    height: 275px;
    width: 100%;
    padding-top: 20px;
    color: $color-lightest;
}

.msg-error img {
    width: 100px;
    height: 100px;
    margin-bottom: 25px;
}

.texto-sentim {
    font-size: 40px;
    font-weight: $font-primary-medium;
    margin-bottom: 35px;
}

.text-subtittle {
    font-size: 26px;
    font-weight: $font-primary;
}
</style>
