function executeAccessibilityFixes() {
    // añadir img de fallback dentro de todos los canvas o aria-label
    // https://www.chartjs.org/docs/2.9.4/general/accessibility.html#examples

    console.debug("Añadiendo alternativa textual a elementos canvas");
    const canvasToAddAltText = document.getElementsByTagName("canvas");
    for (let i = 0; i < canvasToAddAltText.length; i++) {
        canvasToAddAltText[i].setAttribute("aria-label", "Gráfico de aplicación para mostrar información acerca de tus facturas o consumos");
        canvasToAddAltText[i].setAttribute("role", "img");
    }
}

export { executeAccessibilityFixes };
