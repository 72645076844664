<script>
import { Doughnut } from "vue-chartjs";
import { executeAccessibilityFixes } from "@/plugins/accesibility";
// const { reactiveProp } = mixins;

export default {
    extends: Doughnut,
    // mixins: [reactiveProp],
    props: {
        chartdata: {
            type: Object,
            required: true,
        },
        options: {
            type: Object,
            required: false,
        },
    },
    watch: {
        chartdata: function () {
            console.log("Cambiando datos de gráfica de donuts");
            // this._chart.destroy();
            this.renderChart(this.chartdata, this.options);
        },
    },
    mounted() {
        this.renderChart(this.chartdata, this.options);
        executeAccessibilityFixes();
    },
};
</script>

<style></style>
