<template>
    <b-container class="v-invoice-digital">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9" v-if="invoice">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.detalleFactura.title')" />

                <ab-select-contract />
                <ab-select-invoice v-if="selectedInvoiceNumber" />

                <!--Detalle de la factura-->
                <div class="info-invoice mt-5">
                    <p class="text-invoice">
                        {{ $t("ofex.pagarFactura.factura") }}
                        <span class="detail-invoice ml-1"> {{ invoice.invoiceYear }}{{ invoice.invoiceNumber }} {{ monthFullName }} {{ yearEndDate }} </span>
                    </p>
                    <p class="text-contracte">
                        {{ $t("ofex.pagarFactura.contrato") }}<span class="number-contracte ml-1">{{ selectedContractNumber }}</span>
                    </p>
                    <p class="address-invoice">
                        {{ contract.supplyAddress.streetType }} {{ contract.supplyAddress.streetName }}, {{ contract.supplyAddress.number }}
                        {{ contract.supplyAddress.otherAddressData }} - {{ contract.supplyAddress.municipality }}
                    </p>
                    <div class="height-button-separate">
                        <div class="button-download float-right mt-2 mt-sm-0">
                            <ab-button-ofex
                                :click-event="downloadPdf"
                                class="button-blue"
                                :font-awesome-icon="'download'"
                                :text-button="$t('ofex.pagarFactura.descargar')"
                            ></ab-button-ofex>
                        </div>
                        <div class="h-div-button line-bottom"></div>
                    </div>
                </div>

                <section>
                    <ab-invoice-grey-box v-if="invoice" :invoice="invoice" />
                </section>

                <!-- sección gráfica factura DONUT -->
                <section>
                    <ab-invoice-summary v-if="invoice" :invoice="invoice" :show-total-debt="false" />
                </section>

                <!-- sección imagen factura-->
                <section v-if="invoice && invoice.amount !== 0">
                    <ab-invoice-amount-portions :invoice="invoice"></ab-invoice-amount-portions>
                </section>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-left="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-left="$t('ofex.relatedContents.facturaDigital.image')"
                    target-left="_self"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.quePagasFactura.name')"
                    :href-right="$t('ofex.relatedContents.quePagasFactura.url')"
                    :image-right="$t('ofex.relatedContents.quePagasFactura.image')"
                />
            </b-col>
            <b-col cols="12" md="12" lg="9" v-else>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.detalleFactura.title')" />
                <ab-select-contract />
                <ab-without-data :text="$t('ofex.withoutData.lastInvoice')"></ab-without-data>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-left="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-left="$t('ofex.relatedContents.facturaDigital.image')"
                    target-left="_self"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.quePagasFactura.name')"
                    :href-right="$t('ofex.relatedContents.quePagasFactura.url')"
                    :image-right="$t('ofex.relatedContents.quePagasFactura.image')"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import AbInvoiceGreyBox from "../modules/invoices/AbInvoiceGreyBox.vue";
import AbInvoiceAmountPortions from "../modules/invoices/AbInvoiceAmountPortions.vue";
import moment from "moment";
import { AB_DATE_FORMAT } from "@/plugins/ab-utils";
import { mapGetters, mapState } from "vuex";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";

export default {
    data: function () {
        return {
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
        };
    },
    name: "InvoiceDetailView",
    components: {
        AbInvoiceGreyBox,
        AbInvoiceAmountPortions,
    },
    computed: {
        ...mapState(["isLoading", "userInvoices", "selectedContractNumber", "selectedInvoiceNumber", "clientData"]),
        ...mapGetters(["getInvoiceByInvoiceNumber", "getContractByContractNumber", "lastInvoice"]),
        monthFullName: function () {
            return moment(this.invoice.endDate, AB_DATE_FORMAT, this.$i18n.locale).format("MMMM").toUpperCase();
        },
        yearEndDate: function () {
            return moment(this.invoice.endDate, AB_DATE_FORMAT, this.$i18n.locale).year();
        },
        contract: function () {
            return this.getContractByContractNumber(this.selectedContractNumber ? this.selectedContractNumber : this.$route.params.contractId);
        },
        invoice: function () {
            return this.getInvoiceByInvoiceNumber(this.selectedInvoiceNumber);
        },
    },
    methods: {
        downloadPdf: function () {
            this.$downloadInvoice({
                invoiceNumber: this.invoice.invoiceNumber,
                invoiceYear: this.invoice.invoiceYear,
                locale: this.$i18n.locale,
            });
        },
    },
    mounted() {
        let vueContext = this;
        this.$store
            .dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, activeContract: vueContext.$route.params.contractId })
            .then(function () {
                vueContext.$store.commit(mutationTypes.CHANGE_ACTIVE_INVOICE_MUTATION, {
                    invoiceNumber: vueContext.$route.params.invoiceId,
                });
            });

        // Hook para cuando se cambie el contrato
        this.$root.$on(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, (data) => {
            console.log("Ha cambiado el contrato " + data);
            if (typeof vueContext.lastInvoice !== "undefined") {
                vueContext.$store.commit(mutationTypes.CHANGE_ACTIVE_INVOICE_MUTATION, {
                    invoiceNumber: vueContext.lastInvoice.invoiceNumber,
                });
            }
        });
    },
};
</script>
<style lang="scss" scoped>
.info-invoice {
    width: 100%;
    height: auto;
}
.icon {
    @include font-size(16);
    @include line-height(19);
    color: $link-primary;
    font-weight: $font-primary-semiBold;
    margin-right: 7px;
}
.text-invoice {
    @include font-size(18);
    @include line-height(21);
    color: $link-primary;
    font-weight: $font-primary-bold;
}
.address-invoice {
    @include font-size(14);
    @include line-height(16);
    color: $color-secondary;
    font-weight: $font-primary-semiBold;
}
@include respond-below(sm) {
    .height-button-separate {
        height: 100px;
    }
}
.text-contracte {
    @include font-size(14);
    @include line-height(16);
    color: $color-secondary;
    font-weight: $font-primary-bold;
}
.detail-invoice {
    @include font-size(18);
    @include line-height(21);
    color: $color-secondary;
    font-weight: $font-primary-bold;
}
.number-contracte {
    @include font-size(14);
    @include line-height(16);
    color: $color-secondary;
    font-weight: $font-primary-medium;
}
.button-download {
    width: 140px;
}
@include respond-below(sm) {
    .button-download {
        width: 100%;
    }
}
.button-blue {
    padding: 0 !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
}
.h-div-button {
    height: 70px;
    margin-bottom: 17px;
}
.line-bottom {
    border-bottom: 1px solid $border-table;
}
</style>
