import { render, staticRenderFns } from "./AbIaeSelect.vue?vue&type=template&id=21c8cdaf&scoped=true"
import script from "./AbIaeSelect.vue?vue&type=script&lang=js"
export * from "./AbIaeSelect.vue?vue&type=script&lang=js"
import style1 from "./AbIaeSelect.vue?vue&type=style&index=1&id=21c8cdaf&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21c8cdaf",
  null
  
)

/* custom blocks */
import block0 from "./common/i18n-components.i18n?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Farnaldo%2FProyectosOficiales%2FZener%2FAguasDeBarcelona%2Fprojects%2Fab-ofex-components%2Fsrc%2Fcomponents%2FAbIaeSelect.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports