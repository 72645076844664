"use strict";

import BaseXlsxGenerator from "@/generators/xlsx/base/BaseXlsxGenerator";
import * as xlsx from "xlsx";

export default class LastReadingListXlsxGenerator extends BaseXlsxGenerator {
    constructor(filename, lastReadingRowsArray, i18n) {
        let rows = lastReadingRowsArray.map((i) => {
            return {
                contrato: i.contrato,
                suministro: i.suministro,
                fechaHoraUltimaLectura: i.fechaHoraUltimaLectura,
                ultimaLectura: i.ultimaLectura,
                consumo: i.consumo,
                aviso: i.fuga === 1 ? "Fuga" : i.exceso === 1 ? "Exceso" : "",
            };
        });

        let lastReadingsSheet = xlsx.utils.json_to_sheet(rows);

        lastReadingsSheet["A1"]["v"] = i18n.tc("ofex.tuContrato.contractNumber");
        lastReadingsSheet["B1"]["v"] = i18n.tc("ofex.tuContrato.supplyAddress");
        lastReadingsSheet["C1"]["v"] = i18n.tc("ofex.lastReading.fechaUltimaLectura");
        lastReadingsSheet["D1"]["v"] = i18n.tc("ofex.lastReading.ultimaLecturam");
        lastReadingsSheet["E1"]["v"] = i18n.tc("ofex.lastReading.ultimoConsumom");
        lastReadingsSheet["F1"]["v"] = i18n.tc("ofex.lastReading.aviso");
        super(filename, lastReadingsSheet);
    }
}
