import qs from "qs";
import BaseOfexServiceClient from "./BaseOfexServiceClient";

const PROMOS_BASE_URL = process.env.VUE_APP_API_PROMOS_BASE_URL;

export default {
    getValidateToken: async function (promoToken, lang = "es") {
        console.log("Validando el token para modificar contraseña");
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.post(
            `${PROMOS_BASE_URL}/promo/validateToken`,
            {
                promoToken: promoToken,
            },
            {
                params: {
                    lang: lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            //Respuesta en la validación del token
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
};
