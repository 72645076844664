<template>
    <b-container class="v-invoice-evolution">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9" v-if="selectedContractNumber && getContractByContractNumber">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.invoiceEvolutionView.title')" />
                <p class="subtitle">{{ $t("ofex.invoiceEvolutionView.subtitle") }}</p>
                <ab-select-contract />

                <b-row class="invoiceCont mb-3 mt-5">
                    <b-col cols="12" md="6">
                        <div>
                            <ab-select
                                class="invoiceBorder"
                                v-model="concept"
                                :label="$t('evolucionFacturas.comparacion')"
                                :data-list="computedConceptList"
                                placeholder="Seleccionar"
                                name="select-comparacion"
                            />
                        </div>
                    </b-col>
                    <b-col cols="12" md="6">
                        <div>
                            <ab-select
                                class="invoiceNoBorder"
                                v-model="chartType"
                                :label="$t('evolucionFacturas.tipoGrafico')"
                                :data-list="chartTypeList"
                                placeholder="Seleccionar"
                                name="select-tipoGrafico"
                            />
                        </div>
                    </b-col>
                </b-row>

                <div v-if="chartType === 'comparative'" class="mb-5">
                    <ab-years-legend v-model="selectedYears" />
                    <div class="chart-container comparative-chart mb-5">
                        <ab-line-comparative-chart
                            :user-invoices="userInvoices"
                            :concept="concept"
                            :concept-name="conceptName"
                            :selected-years="selectedYears"
                            :chart-type="chartType"
                        ></ab-line-comparative-chart>
                    </div>
                    <div class="ml-5">
                        <b-row>
                            <b-col cols="12" md="8">
                                <ab-chart-summary-table
                                    ref="chartSummary"
                                    :raw-list="userInvoices"
                                    :concept="concept"
                                    :years="selectedYears"
                                ></ab-chart-summary-table
                            ></b-col>
                            <b-col cols="12" md="3">
                                <b-row class="mb-3">
                                    <b-col cols="12">
                                        <div id="legend-evolution-line-chart">
                                            <ul class="dropdown-list">
                                                <li>{{ $t("ofex.common.charts.realLegend") }}</li>
                                                <li>{{ $t("ofex.common.charts.estimatedLegend") }}</li>
                                            </ul>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <ab-button-ofex
                                            aria-label="datosGraficas"
                                            class="mt-8"
                                            :text-button="$t('evolucionFacturas.datosGraficas')"
                                            :click-event="generateExcelLastReadingsList"
                                        ></ab-button-ofex>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>
                </div>
                <div v-else class="mb-5">
                    <div class="chart-container evolution-chart mb-5">
                        <ab-line-evolution-chart
                            :user-invoices="userInvoices"
                            :concept="concept"
                            :concept-name="conceptName"
                            :chart-type="chartType"
                        ></ab-line-evolution-chart>
                        <!--                        <ab-button-ofex
                            :font-awesome-icon="'print'"
                            :text-button="$t('ofex.managementHistory.pdf')"
                            :click-event="generatePdfUserActionsList"
                        ></ab-button-ofex>-->
                    </div>
                </div>
                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-left="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-left="$t('ofex.relatedContents.facturaDigital.image')"
                    target-left="_self"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.quePagasFactura.name')"
                    :href-right="$t('ofex.relatedContents.quePagasFactura.url')"
                    :image-right="$t('ofex.relatedContents.quePagasFactura.image')"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import { getI18nTextFromKey } from "@/plugins/ab-utils";
import InvoiceEvolutionChartListXlsxGenerator from "@/generators/xlsx/InvoiceEvolutionChartListXlsxGenerator";
export default {
    name: "InvoiceEvolutionView",
    data: function () {
        return {
            selectedYears: [],
            conceptList: [
                { key: "amount", text: this.$t("evolucionFacturas.importe") },
                { key: "waterServiceAmount", text: this.$t("evolucionFacturas.servicioAgua") },
                { key: "waterCanonAmount", text: this.$t("evolucionFacturas.canon") },
                { key: "tmtrAmount", text: "TMTR (€)" },
                { key: "clavegueramAmount", text: this.$t("evolucionFacturas.alcantarillado") },
            ],
            conceptListExtended: [
                { key: "amount", text: this.$t("evolucionFacturas.importe") },
                { key: "waterServiceAmount", text: this.$t("evolucionFacturas.servicioAgua") },
                { key: "waterCanonAmount", text: this.$t("evolucionFacturas.canon") },
                { key: "tmtrAmount", text: "TMTR (€)" },
                { key: "clavegueramAmount", text: this.$t("evolucionFacturas.alcantarillado") },
                { key: "trrAmount", text: this.$t("evolucionFacturas.trr") },
            ],
            chartTypeList: [
                { key: "evolution", text: this.$t("evolucionFacturas.evolucion") },
                { key: "comparative", text: this.$t("evolucionFacturas.comparacionTresAnnos") },
            ],
            concept: "amount",
            chartType: "comparative",
        };
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "userInvoices", "selectedContractNumber"]),
        ...mapGetters(["getContractByContractNumber"]),
        // Lógica para devolver un array u otro en dependencia si pertenece al municipio de Barcelona o no. Dentro del computed no inicializar arrays
        computedConceptList: function () {
            return this.getContractByContractNumber(this.selectedContractNumber).supplyAddress.municipality === "BARCELONA"
                ? this.conceptListExtended
                : this.conceptList;
        },
        conceptName: function () {
            return getI18nTextFromKey(this.concept, this.conceptListExtended).replace(" (€)", "");
        },
    },
    created() {
        let lastYear = new Date().getFullYear();
        this.selectedYears = [lastYear, lastYear - 1, lastYear - 2];
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
    },
    updated() {},
    methods: {
        generateExcelLastReadingsList: function () {
            let xlsxGenerator = new InvoiceEvolutionChartListXlsxGenerator(`EvoluciónTuFactura.xlsx`, this.$refs.chartSummary.rawList, this.$i18n);
            console.log(xlsxGenerator);
        },
    },
};
</script>
<style lang="scss" scoped>
.invoiceCont {
    background-color: $bg-grey-light;
    padding-bottom: 20px;
    padding-top: 15px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.invoiceBorder {
    border-right: 1px solid $blue-dark;
    flex-basis: 160px;
    width: 100%;
    padding-right: 40px;
    margin-left: 15px;
}

.invoiceNoBorder {
    width: 100%;
    padding-left: 10px;
    padding-right: 30px;
    margin-left: 15px;
}

#legend-evolution-line-chart {
    ul {
        list-style: none;
        font-weight: $font-primary-bold;
        color: $color-primary;
        @include font-size(13);
        @include line-height(23);

        li:first-of-type:before {
            content: "\25CF";
            color: #716f6a;
            font-weight: $font-primary-bold;
            padding-right: 10px;
        }
        li:last-of-type:before {
            content: "\25CB";
            color: #716f6a;
            font-weight: $font-primary-bold;
            padding-right: 10px;
        }
    }
}

@include respond-below(md) {
    .invoiceBorder {
        border-right: 0px solid;
        width: 100%;
        padding-right: 0px;
        margin-left: 0px;
    }
    .invoiceNoBorder {
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
    }
}
</style>
