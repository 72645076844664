<template>
    <div class="active-fsp-modal-container">
        <div class="container">
            <div class="row">
                <div class="col-lg-2">
                    <img class="img-ticket mt-2" src="@/assets/images/ofex/factura-digital/ticket.png" />
                </div>
                <div class="col-lg-10">
                    <span class="text mb-3">{{ $t("ofex.downloadInvoiceFlow.modalText") }}</span>
                </div>
            </div>
        </div>

        <!--        <span class="title-box">{{ $t("ofex.downloadInvoiceFlow.modalText") }}</span>-->
        <ValidationObserver ref="activeFspModalValidator" v-slot="{ reset, errors }">
            <ab-legal-section
                ref="fspSection"
                :fsp-es="legalUrls.fspEs"
                :fsp-ca="legalUrls.fspCa"
                :gdpr-es="legalUrls.gdprEs"
                :gdpr-ca="legalUrls.gdprCa"
                :legal-es="legalUrls.urEs"
                :legal-ca="legalUrls.urCa"
                :show-gdpr="false"
                :show-fsp="true"
                :show-legal="false"
                :float-left="false"
                :required-gdpr="false"
                :required-legal="false"
                :required-fsp="true"
            ></ab-legal-section>
            <p v-if="Object.values(errors).length > 0 && Object.values(errors)[0] && Object.values(errors)[0].length > 0" class="formTextErr displayYes">
                {{ Object.values(errors)[0][0] }}
            </p>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
    name: "AbActiveFspModal",
    props: {
        modalName: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {};
    },
    computed: {
        ...mapState(["userData"]),
        ...mapGetters(["legalUrls"]),
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.subtitle-change {
    color: $blue-dark;
    font-weight: $font-primary-bold;
    @include font-size(21);
}
.text {
    font-weight: $font-primary;
    @include font-size(16);
}
.link {
    color: $bg-primary;
    text-decoration: underline;
}
.text-conditions {
    font-weight: $font-primary-semiBold;
    @include font-size(14);
}
</style>
