"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";

const ACTIVITY_CODE = "UD";
export default class UserDataModifyPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId) {
        super("DatosUsuario.pdf", ACTIVITY_CODE, i18n, userId, clientId, "");
    }

    generatePdf(userOrClientDataModel, localUserData, localClientData, isCompany) {
        const POINTS = ": ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.userDataModify.title"), "");
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.userDataModify.title"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.numeroDocumento") + POINTS + userOrClientDataModel.documentNumber);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.nombre") + POINTS + (isCompany ? localUserData.firstName : localClientData.name));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.primerApellido") + POINTS + userOrClientDataModel.firstLastName);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.segundoApellido") + POINTS + userOrClientDataModel.secondLastName);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.correoElectronico") + POINTS + userOrClientDataModel.email);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.telefono") + POINTS + userOrClientDataModel.mainPhone);
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.userDataModify.idioma") +
                POINTS +
                (userOrClientDataModel.language === "es"
                    ? this.i18n.tc("ofex.pdfGenerator.userDataModify.espanol")
                    : this.i18n.tc("ofex.pdfGenerator.userDataModify.catalan"))
        );
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.usuarioTwitter") + POINTS + userOrClientDataModel.twitter);
        if (!isCompany) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.comunicaciones") + POINTS + (localClientData.advertising ? "Si" : "No"));
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.encuestas") + POINTS + (localClientData.surveys ? "Si" : "No"));
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.userDataModify.perfilado") + POINTS + (localClientData.profiling ? "Si" : "No"));
        }

        return this.pdf;
    }
}
