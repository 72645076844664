<template>
    <div class="v-invoice-digital">
        <!-- Búsqueda avanzada -->
        <ab-contracts-advance-search
            v-model="filteredContracts"
            ref="contractsAdvanceSearch"
            :contracts-invoices-f-s-p="contractsInvoicesFSP"
            :extra-filter-predicate="fspPredicate"
            :onlyActiveContracts="true"
        />
        <!--Tabla contratos -->
        <vue-good-table
            ref="table-desactivate"
            :columns="columns"
            :rows="tableRows"
            style-class="vgt-table"
            :select-options="{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                perPageDropdown: [10, 20, 30],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: this.$i18n.tc('table.siguiente'),
                prevLabel: this.$i18n.tc('table.atras'),
                rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                ofLabel: 'de',
                allLabel: this.$i18n.tc('ofex.tuContrato.tableTodo'),
                label: 'selector-de-paginas',
                name: 'perPageSelect',
            }"
        >
            <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'contrato'">
                    <label :for="props.row.contrato" class="contract" :class="{ pending: props.row.deuda }">{{ props.row.contrato }}</label>
                    <img v-if="props.row.telectura" class="icon-contrato" alt="imagen bola telelectura" src="../../../assets/images/bola-telelectura.png" />
                </div>
                <div v-line-clamp:20="2" v-else>
                    <span v-tooltip.top-center="props.formattedRow[props.column.field]">{{ props.formattedRow[props.column.field] }}</span>
                </div>
            </template>
            <div slot="emptystate" class="text-center textdnormal">
                {{ $t("ofex.tuContrato.emptyResults") }}
            </div>
        </vue-good-table>
    </div>
</template>
<script>
import { mapState } from "vuex";
import AbContractsAdvanceSearch from "../../../modules/AbContractsAdvanceSearch";
import { VueGoodTable } from "vue-good-table";
import { trimBackendCode } from "@/plugins/ab-utils";
export default {
    name: "AbDigitalInvoiceTableSelection",
    components: {
        AbContractsAdvanceSearch,
        VueGoodTable,
    },

    data: function () {
        return {
            filteredContracts: null,
        };
    },
    props: {
        contractsInvoicesFSP: {
            type: Array,
            required: true,
        },
        fsp: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapState(["contracts", "isLoading", "clientData"]),
        tableRows: function () {
            return this.filteredContracts !== null
                ? this.filteredContracts.map(this.mapContractToTableRow)
                : this.contractsInvoicesFSP.map(this.mapContractToTableRow);
        },
        contractsSelected: function () {
            return this.$refs["table-desactivate"].selectedRows;
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contrato",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                    tdClass: "",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "suministro",
                    tdClass: "col-suministro",
                    thClass: "col-suministro",
                },

                {
                    label: this.$t("table.poblacion"),
                    field: "municipio",
                    tdClass: "col-municipio",
                    thClass: "col-municipio",
                },
                {
                    label: this.$t("table.envio"),
                    field: "envio",
                    tdClass: "col-envio",
                    thClass: "col-envio",
                    html: true,
                },
            ];
        },
    },
    methods: {
        mapContractToTableRow: function (contractEntry) {
            return {
                yearInvoice: contractEntry.contractDetail.invoiceYear,
                contrato: contractEntry.contractDetail.contractNumber,
                alias: contractEntry.contractDetail.alias,
                suministro:
                    this.$i18n.tc(`ofex.backendCodes.streetTypes.${trimBackendCode(contractEntry.supplyAddress.streetType)}`) +
                    " " +
                    contractEntry.supplyAddress.streetName +
                    " " +
                    contractEntry.supplyAddress.number +
                    ", " +
                    contractEntry.supplyAddress.otherAddressData +
                    "," +
                    contractEntry.supplyAddress.postalCode,
                municipio: contractEntry.supplyAddress.municipality,
                envio: this.$i18n.tc(`ofex.backendCodes.deliveryType.${trimBackendCode(contractEntry.contractDetail.invoiceDeliveryType)}`),
            };
        },
        fspPredicate: function (value) {
            return this.fsp ? value.contractDetail.invoiceDeliveryType !== "FP" : value.contractDetail.invoiceDeliveryType === "FP";
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/digital-invoice.scss";
</style>
<style lang="scss" scoped>
.v-invoice-digital {
    .m-advance-search {
        .multiselect__select {
            width: 28px;
            height: 32px;
        }
        .multiselect__input {
            margin-top: 5px;
        }
    }
}
</style>
