<template>
    <div id="app" :class="{ native: $isNativeApp() }" aria-hidden="false">
        <!-- Componente para gestionar la inactividad del usuario-->
        <ab-idle-session v-if="isAuthenticated" />

        <ab-header-bar :key="$router.currentRoute.path" />
        <ab-error-modal v-if="isAuthenticated" />
        <ab-legal-check-flow v-if="legalCheckFlowEnabled === 'true' && clientData && selectedContractNumber && legalCheckStatus !== 'FINISHED'" />
        <ab-download-invoice-flow v-if="isAuthenticated" />
        <main :class="{ biometric: $router.currentRoute.meta.biometricsLogin }">
            <router-view :key="$router.currentRoute.path" v-if="contigenciasOk" />
            <contingencias-root-view v-else />
        </main>
        <!-- <ab-app-about v-if="$isNativeApp()" /> -->
    </div>
</template>

<script>
import AbHeaderBar from "./modules/common/AbHeaderBar.vue";
import { mapState, mapGetters } from "vuex";
import AbErrorModal from "@/modules/common/AbErrorModal";
import AbIdleSession from "@/modules/common/AbIdleSession";
import ContingenciasRootView from "@/views/ContingenciasRootView";
import AbDownloadInvoiceFlow from "@/modules/invoices/download/AbDownloadInvoiceFlow";
// Uncomment for delete fingerprints on app launch
// import { NativeBiometric } from "capacitor-native-biometric";

export default {
    name: "App",
    data: function () {
        return {
            userInactivityDuration: process.env.VUE_APP_INACTIVITY_DURATION,
            legalCheckFlowEnabled: process.env.VUE_APP_CONSENTS_FLOW_ENABLED,
        };
    },
    components: { AbDownloadInvoiceFlow, AbErrorModal, AbHeaderBar, AbIdleSession, ContingenciasRootView },
    computed: {
        ...mapState(["contigenciasStatus", "contigenciasOk", "clientData", "selectedContractNumber", "legalCheckStatus", "downloadInvoiceFlowVisible"]),
        ...mapGetters(["isAuthenticated"]),
    },
    // Uncomment for delete fingerprints on app launch
    // mounted() {
    //     NativeBiometric.deleteCredentials({
    //         server: "www.aiguesdebarcelona.cat",
    //     }).then();
    // },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap");
@import "./assets/styles/index.scss";
@import url("/o/aigues-principal-theme/css/footer.css");

main {
    padding: 130px 0;

    &.biometric {
        padding: 0 !important;
    }

    @include respond-above(md) {
        padding: 0;
    }
    @include respond-below(md) {
        padding: 60px 0 70px 0;
    }
}
.grecaptcha-badge {
    @include respond-below(md) {
        z-index: 1;
    }
}
.native {
    main {
        padding: 0;
        padding-top: max(70px, env(safe-area-inset-top));

        @supports (-webkit-touch-callout: none) {
            padding-bottom: 90px;
        }
        @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
            padding-top: max(30px, env(safe-area-inset-top));
            padding-bottom: 70px;
        }
    }
}
</style>
