<script>
import * as viewMappingTypes from "../../router/view-mapping-types";
import { mapGetters, mapState } from "vuex";
export default {
    name: "NavBarRenderless",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
        };
    },
    computed: {
        ...mapState(["selectedContractNumber", "contracts", "userInvoices", "droughtConfiguration"]),
        ...mapGetters([
            "hasAnyIndustrialOrComercialContract",
            "hasAnyDomesticContract",
            "hasAnyActiveContract",
            "hasAnyDroughtAffectedContract",
            "lastInvoice",
            "multicontract",
            "multiActiveContract",
            "isCompany",
            "contractsWithTelerecording",
        ]),
    },
    methods: {
        navigatePaymentData: function () {
            if (this.multiActiveContract || this.hasAnyActiveContract === false) {
                return {
                    name: viewMappingTypes.MODIFICATION_CONTRACT_SELECTOR_MAPPING.name,
                    query: { typeViewModification: viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.name },
                };
            } else {
                return {
                    name: viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.name,
                    params: { contractId: this.selectedContractNumber },
                };
            }
        },
        navigateAffectationContractsData: function () {
            if (this.multiActiveContract || this.hasAnyActiveContract === false) {
                return {
                    name: viewMappingTypes.AFFECTATIONS_CONTRACTS_MAPPING.name,
                    query: { typeViewModification: viewMappingTypes.AFFECTATIONS_CONTRACTS_MAPPING.name },
                };
            } else {
                return {
                    name: viewMappingTypes.AFFECTATIONS_CONTRACTS_DETAIL_MAPPING.name,
                    params: { contractId: this.selectedContractNumber },
                };
            }
        },
        navigateCorrespondenceData: function () {
            if (this.multiActiveContract || this.hasAnyActiveContract === false) {
                return {
                    name: viewMappingTypes.MODIFICATION_CONTRACT_SELECTOR_MAPPING.name,
                    query: { typeViewModification: viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.name },
                };
            } else {
                return {
                    name: viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.name,
                    params: { contractId: this.selectedContractNumber },
                };
            }
        },
        navigateOwnerData: function () {
            return {
                name: viewMappingTypes.MODIFICATION_OWNER_DATA_MAPPING.name,
                params: { contractId: this.selectedContractNumber },
            };
        },
        navigateCertificateOccupancy: function () {
            if (this.multiActiveContract || this.hasAnyActiveContract === false) {
                return {
                    name: viewMappingTypes.MODIFICATION_CONTRACT_SELECTOR_MAPPING.name,
                    query: { typeViewModification: viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name },
                };
            } else {
                return {
                    name: viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name,
                    params: { contractId: this.selectedContractNumber },
                };
            }
        },
        navigateIaeData: function () {
            if (this.multiActiveContract || this.hasAnyActiveContract === false) {
                return {
                    name: viewMappingTypes.MODIFICATION_CONTRACT_SELECTOR_MAPPING.name,
                    query: { typeViewModification: viewMappingTypes.INSERT_IAE_DATA_MAPPING.name },
                };
            } else {
                return {
                    name: viewMappingTypes.INSERT_IAE_DATA_MAPPING.name,
                    params: { contractId: this.selectedContractNumber },
                };
            }
        },
        navigateUnsuscribeContract: function () {
            if (this.multiActiveContract || this.hasAnyActiveContract === false) {
                return {
                    name: viewMappingTypes.UNSUSCRIBE_CONTRACT_SELECTOR_VIEW_MAPPING.name,
                    query: { typeViewModification: viewMappingTypes.UNSUSCRIBE_CONTRACT_SELECTOR_VIEW_MAPPING.name },
                };
            } else {
                return {
                    name: viewMappingTypes.UNSUSCRIBE_CONTRACT_VIEW_MAPPING.name,
                    params: { contractId: this.selectedContractNumber },
                };
            }
        },
        navigateReadMeterForm: function () {
            if (this.multiActiveContract) {
                return {
                    name: viewMappingTypes.READ_METER_SELECTOR_MAPPING.name,
                    query: { typeViewModification: viewMappingTypes.READ_METER_FORM_MAPPING.name },
                };
            } else {
                return {
                    name: viewMappingTypes.READ_METER_FORM_MAPPING.name,
                    params: { contractId: this.selectedContractNumber },
                };
            }
        },
    },
    render() {
        return this.$scopedSlots.default({
            navigateReadMeterForm: this.navigateReadMeterForm,
            navigateUnsuscribeContract: this.navigateUnsuscribeContract,
            navigateIaeData: this.navigateIaeData,
            navigateCertificateOccupancy: this.navigateCertificateOccupancy,
            navigateOwnerData: this.navigateOwnerData,
            navigateCorrespondenceData: this.navigateCorrespondenceData,
            navigatePaymentData: this.navigatePaymentData,
            navigateAffectationContractsData: this.navigateAffectationContractsData,
            selectedContractNumber: this.selectedContractNumber,
            lastInvoice: this.lastInvoice,
            contractsWithTelerecording: this.contractsWithTelerecording,
            isCompany: this.isCompany,
            hasAnyDomesticContract: this.hasAnyDomesticContract,
            hasAnyActiveContract: this.hasAnyActiveContract,
            hasAnyIndustrialOrComercialContract: this.hasAnyIndustrialOrComercialContract,
            droughtConfiguration: this.droughtConfiguration,
            hasAnyDroughtAffectedContract: this.hasAnyDroughtAffectedContract,
        });
    },
};
</script>
