import BaseGenerator from "./base/BasePdfGenerator.js";
import { trimBackendCode } from "@/plugins/ab-utils";
const ACTIVITY_CODE = "CD";
export default class ContractDetailPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId, contract) {
        super("DetalleContrato.pdf", ACTIVITY_CODE, i18n, userId, clientId, contract);
    }
    generatePdf(userData, clientData, contract, contractDetail, iban, isCompany, country) {
        const SPACE = "  ";

        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.detalleContrato.title"), this.i18n.tc("ofex.detalleContrato.pdf.subTitle"));
        this.generatePdfSection(this.i18n.tc("ofex.detalleContrato.sectionDetalleContrato"));

        this.generateLineText(this.i18n.tc("ofex.detalleContrato.contrato") + SPACE + contract.contractDetail.contractNumber);

        //Alias del contrato
        if (contract.contractDetail.alias == "") {
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.aliasContrato") + "");
        } else {
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.aliasContrato") + SPACE + contract.contractDetail.alias);
        }

        //Contrato con telelectura
        if (contract.contractDetail.telerecording) {
            this.generateLineText(this.i18n.tc("ofex.home.telelectura"));
        }

        //Estado del suministro
        this.generateLineText(
            this.i18n.tc("ofex.detalleContrato.estadoSuministro") +
                SPACE +
                this.i18n.tc("ofex.backendCodes.supplyStatus." + trimBackendCode(contractDetail.contractDetail.supplyStatus))
        );

        //Fecha de alta
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.fechaAlta") + SPACE + contract.contractDetail.registrationDate);

        //Fecha de baja
        if (contract.contractDetail.disabledDate != null) {
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.fechaBaja") + SPACE + contract.contractDetail.disabledDate);
        }

        //Tipo de suministro
        this.generateLineText(
            this.i18n.tc("ofex.detalleContrato.tipoSuministro") +
                SPACE +
                this.i18n.tc("ofex.backendCodes.supplyType." + contract.contractDetail.supplyTypeCode.replace(" ", "").replace(".", ""))
        );

        this.generateLineText(
            this.i18n.tc("ofex.modificarDatosContrato.direccionSuministro") +
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(contractDetail.supplyAddress.streetType)) +
                SPACE +
                contractDetail.supplyAddress.streetName +
                SPACE +
                contractDetail.supplyAddress.number +
                SPACE +
                contractDetail.supplyAddress.otherAddressData +
                SPACE +
                contractDetail.supplyAddress.municipality +
                SPACE
        );

        //Tipo de uso
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.tipoUso") + SPACE + contract.contractDetail.useTypeCode);

        //Caudal contratado o dotación
        if (contractDetail.contractDetail.supplyTypeCode !== "C.INCENDI") {
            if (contractDetail.contractDetail.supplyTypeCode === "AFORA.DIV" || contractDetail.contractDetail.supplyTypeCode === "AFORA.GEN") {
                this.generateLineText(this.i18n.tc("ofex.detalleContrato.dotacion") + SPACE + contractDetail.contractDetail.contractedWaterFlow);
            } else {
                this.generateLineText(this.i18n.tc("ofex.detalleContrato.caudalContratado") + SPACE + contractDetail.contractDetail.contractedWaterFlow);
            }
        }

        //Cédula de habitabilidad
        if (contractDetail.contractDetail.useTypeCode === "DOMESTIC") {
            this.generateLineText(
                contractDetail.contractDetail.habitabilityCertificate != null && contractDetail.contractDetail.habitabilityCertificate != ""
                    ? this.i18n.tc("ofex.detalleContrato.cedulaHabitabilidad") + SPACE + contractDetail.contractDetail.habitabilityCertificate
                    : this.i18n.tc("ofex.detalleContrato.cedulaHabitabilidad") + SPACE + "-"
            );
        }

        //Tipo de envío de factura
        this.generateLineText(
            this.i18n.tc("ofex.detalleContrato.tipoEnvioFactura") +
                SPACE +
                this.i18n.tc("ofex.backendCodes.deliveryType." + contractDetail.contractDetail.invoiceDeliveryType)
        );

        //Datos del titular
        this.generatePdfSection(this.i18n.tc("ofex.detalleContrato.datosTitular"));
        if (isCompany) {
            //Nombre y apellidos
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.nombreApellidos") + SPACE + clientData.name);

            //Tipo de documento
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.tipoDocumento") + SPACE + clientData.documentType);

            //Número de documento
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.nDocumento") + SPACE + clientData.documentNumber);

            //Teléfono principal
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.tlfPrincipal") + SPACE + clientData.mainPhone);

            //Email
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.email") + SPACE + clientData.email);
        } else {
            //Nombre y apellidos
            this.generateLineText(
                this.i18n.tc("ofex.detalleContrato.nombreApellidos") +
                    SPACE +
                    userData.firstName +
                    SPACE +
                    userData.firstLastName +
                    SPACE +
                    userData.secondLastName
            );

            //Tipo de documento
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.tipoDocumento") + SPACE + userData.documentType);

            //Número de documento
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.nDocumento") + SPACE + userData.documentNumber);

            //Teléfono principal
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.tlfPrincipal") + SPACE + userData.mainPhone);

            //Email
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.email") + SPACE + userData.email);
        }
        //Idioma
        this.generateLineText(
            this.i18n.tc("ofex.detalleContrato.idioma") +
                SPACE +
                `${userData.language == "es" ? this.i18n.tc("ofex.detalleContrato.espanol") : this.i18n.tc("ofex.detalleContrato.catalan")}`
        );

        //Dirección de correspondencia
        this.generatePdfSection(this.i18n.tc("ofex.detalleContrato.datosDirPostal"));

        this.generateLineText(this.i18n.tc("ofex.detalleContrato.nombreApellidos") + SPACE + contractDetail.mailHolderData.fullName);

        this.generateLineText(
            this.i18n.tc("ofex.detalleContrato.datosCorrespondencia") +
                SPACE +
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(contractDetail.mailAddress.streetType)) +
                SPACE +
                contractDetail.mailAddress.otherAddressData
        );

        //Tipo de documento
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.tipoDocumento") + SPACE + contractDetail.mailHolderData.documentType);

        //Número de documento
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.nDocumento") + SPACE + contractDetail.mailHolderData.documentNumber);

        //Idioma
        this.generateLineText(
            this.i18n.tc("ofex.detalleContrato.idioma") +
                SPACE +
                `${userData.language == "es" ? this.i18n.tc("ofex.detalleContrato.espanol") : this.i18n.tc("ofex.detalleContrato.catalan")}`
        );

        //Sección de Pago
        this.generatePdfSection(this.i18n.tc("ofex.detalleContrato.responsablePago"));

        if (contractDetail.paymentData.documentNumber === userData.documentNumber || contractDetail.paymentData.documentNumber === clientData.documentNumber) {
            this.generateLineText(
                this.i18n.tc("ofex.detalleContrato.nombreApellidos") +
                    SPACE +
                    contractDetail.paymentData.firstName +
                    " " +
                    contractDetail.paymentData.firstLastName +
                    " " +
                    contractDetail.paymentData.secondLastName
            );

            //Tipo de documento
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.tipoDocumento") + SPACE + contractDetail.paymentData.documentType);

            //Número de documento
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.nDocumento") + SPACE + contractDetail.paymentData.documentNumber);

            //Email
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.email") + SPACE + contractDetail.paymentData.email);
        }

        //Forma de pago
        this.generateLineText(
            this.i18n.tc("ofex.detalleContrato.formaPago") +
                SPACE +
                this.i18n.tc("ofex.backendCodes.paymentMethod." + trimBackendCode(contractDetail.paymentData.paymentMethod))
        );

        //Iban
        if (contractDetail.paymentData.paymentMethod === "BANCARIA" || contractDetail.paymentData.paymentMethod === "ENTITAT BANCARIA") {
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.iban") + SPACE + iban);
        }

        //País
        if (contractDetail.paymentData.iban && contractDetail.paymentData.country !== "ES") {
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.pais") + SPACE + country);
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.bic") + SPACE + contractDetail.paymentData.bic);
        }

        //Sección datos de facturación
        if (contractDetail && contractDetail.billingData) {
            this.generatePdfSection(this.i18n.tc("ofex.detalleContrato.datosFacturacion"));

            //Frecuencia de facturación

            this.generateLineText(
                this.i18n.tc("ofex.detalleContrato.frecuenciaFacturacion") +
                    SPACE +
                    this.i18n.tc("ofex.backendCodes.frequencyBilling." + contractDetail.billingData.billingFrequency)
            );

            if (
                trimBackendCode(contractDetail.contractDetail.useTypeCode) === "COMERCIAL" ||
                trimBackendCode(contractDetail.contractDetail.useTypeCode) === "INDUSTRIAL"
            ) {
                if (contractDetail.contractDetail.supplyTypeCode !== "C.INCENDI") {
                    this.generateLineText(this.i18n.tc("ofex.detalleContrato.tipoIAE") + SPACE + contractDetail.billingData.iae.type);
                    this.generateLineText(this.i18n.tc("ofex.detalleContrato.numeroIAE") + SPACE + contractDetail.billingData.iae.number);
                    this.generateLineText(this.i18n.tc("ofex.detalleContrato.descripcionIAE") + SPACE + contractDetail.billingData.iae.description);
                }
            }

            //Numero de personas vivienda
            if (contractDetail.contractDetail.useTypeCode === "DOMESTIC") {
                this.generateLineText(
                    contractDetail.billingData.residentsSize > 0
                        ? this.i18n.tc("ofex.detalleContrato.nPersonas") + SPACE + contractDetail.billingData.residentsSize
                        : this.i18n.tc("ofex.detalleContrato.nPersonas") + SPACE + this.i18n.tc("ofex.detalleContrato.noDisponible")
                );
            }
        }

        return this.pdf;
    }
}
