<template>
    <form class="select-component">
        <label class="select-label" :for="name">{{ label }}</label>
        <slot name="remote-reading"></slot>
        <validation-provider
            mode="eager"
            :name="inputAliasComputed"
            :vid="`${name}-vid`"
            :rules="validation"
            :debounce="validationDelay"
            v-slot="{ errors, classes }"
            class="select-container-outer"
        >
            <div class="select-container d-flex">
                <select
                    :id="name"
                    v-model="modelValue"
                    class="select row-select-blue"
                    :name="name"
                    :class="{ inputFormErr: errors.length > 0, 'row-downform': showRow, 'hided-row': !showRow }"
                    :disabled="!editable"
                    :readonly="readonly"
                    :aria-label="label"
                    :title="label"
                >
                    <option disabled value="">{{ placeholder }}</option>
                    <option v-for="(item, index) in dataList" :class="dataOptionClasses[index]" :key="item[dataKey]" :value="item[dataValue]">
                        {{ item[dataLabel] }}
                    </option>
                </select>
                <ab-popover :popover="popover" />
            </div>
            <div v-show="errors.length > 0" class="error-container">
                <p class="formTextErr">{{ errors[0] }}</p>
            </div>
        </validation-provider>
    </form>
</template>

<script>
import AbPopover from "./AbPopover";

export default {
    components: { AbPopover },
    model: {
        prop: "selectValue",
        event: "change",
    },
    props: {
        title: String,
        name: String,
        inputAlias: String,
        popover: String,
        label: String,
        placeholder: String,
        validation: Object,
        validationDelay: {
            type: Number,
            default: 300,
        },
        dataList: Array,
        dataOptionClasses: {
            type: Array,
            default: function () {
                return [];
            },
        },
        dataKey: {
            default: "key",
            type: String,
        },
        dataValue: {
            type: String,
            default: function () {
                return this.dataKey;
            },
        },
        dataLabel: {
            default: "text",
            type: String,
        },
        editable: {
            default: true,
            type: Boolean,
        },
        showRow: {
            default: true,
            type: Boolean,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["selectValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
        inputAliasComputed: function () {
            return this.inputAlias ? this.inputAlias : (this.label ?? "").toLowerCase().replace("*", "").replace(":", "");
        },
    },
};
</script>
<style lang="scss" scoped>
.inputFormErr {
    border: 1px solid #f48989 !important;
    background-color: #feefef !important;
}
</style>
<style lang="scss">
.select-component {
    display: flex;
    flex-wrap: wrap;

    @include respond-below(md) {
        margin-bottom: 20px;
    }
    .select-container-outer {
        flex: 1 auto;
    }
    .select-label,
    .select-container {
        width: 100%;
    }
    .select-label {
        color: $blue-dark;
        font-weight: $font-primary-bold;
        margin-bottom: 5px;
        @include font-size(12);
        @include line-height(16);
    }
    .select-container {
        flex: 1;

        .select {
            @include font-size(14);
            @include line-height(16);
            font-weight: $font-primary-semiBold;
            background: $color-lightest;
            border-radius: 8px;
            border: 1px solid #979797;
            height: 40px;
            width: 100%;
            padding: 0 25px 0 8px;
            appearance: none;
            cursor: pointer;
            overflow: hidden;
            color: gray;

            option {
                font-weight: $font-primary-medium;
            }
            @include respond-above(sm) {
                height: 33px;
            }
            &:focus {
                outline: none;
            }
            &:disabled {
                border-color: #979797;
            }
        }
        .row-select-blue {
            background-image: url("../assets/images/row-down-grey.png");
            background-size: 10px 8px;
            background-position: right 10px center;
            background-repeat: no-repeat;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            border: 1px solid #979797;
            &:disabled {
                background-color: #e5e5e5;
                background-image: url("../assets/images/row-down-grey.png");
            }
        }
        .error-container {
            width: 100%;

            p {
                margin-bottom: 0;
            }
        }
    }
}
</style>
