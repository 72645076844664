var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav-bar-renderless',{ref:"navBarRenderless",scopedSlots:_vm._u([{key:"default",fn:function({
        navigateReadMeterForm,
        navigateUnsuscribeContract,
        navigateIaeData,
        navigateCertificateOccupancy,
        navigateOwnerData,
        navigateCorrespondenceData,
        navigatePaymentData,
        navigateAffectationContractsData,
        selectedContractNumber,
        lastInvoice,
        contractsWithTelerecording,
        isCompany,
        hasAnyDomesticContract,
        hasAnyIndustrialOrComercialContract,
        droughtConfiguration,
    }){return [_c('b-col',{staticClass:"d-none d-lg-block",attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"nav-left-side"},[_c('ul',{staticClass:"nav-first-level"},[_c('li',{staticClass:"nav-item first-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":_vm.viewMappingTypes.HOME_VIEW_MAPPING.path}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.inicio")))])],1),_c('li',{staticClass:"nav-item first-level open"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.INVOICES_LIST_MAPPING.name, params: { contractId: selectedContractNumber } }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.tusFacturas")))]),_c('ul',{staticClass:"nav-second-level"},[_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.name }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.pagarTusFacturas")))])],1),(lastInvoice)?_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                                    name: _vm.viewMappingTypes.INVOICE_DETAIL_VIEW_MAPPING.name,
                                    params: { contractId: selectedContractNumber, invoiceId: lastInvoice.invoiceNumber },
                                }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.entenderFactura")))])],1):_vm._e(),_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                                    name: _vm.viewMappingTypes.EVOLUTION_INVOICES_MAPPING.name,
                                }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.evolucionFacturas")))])],1),(_vm.multicontract)?_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                                    name: _vm.viewMappingTypes.LAST_INVOICES_MAPPING.name,
                                }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.ultimasFacturas")))])],1):_vm._e(),_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":navigateReadMeterForm()}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.lecturaContador")))])],1),(_vm.rectifyingInvoicesViewEnabled === 'true')?_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{
                                    name: _vm.viewMappingTypes.RECTIFYING_INVOICES_LIST_MAPPING.name,
                                }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.facturasRectificativas")))])],1):_vm._e()])],1),_c('li',{staticClass:"nav-item first-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.INVOICE_DIGITAL_MAPPING.name }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.facturaDigital")))])],1),_c('li',{staticClass:"nav-item first-level open"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.CONSUMPTIONS_DETAIL_MAPPING.name, params: { contractId: selectedContractNumber } }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.tusConsumos")))]),_c('ul',{staticClass:"nav-second-level"},[(contractsWithTelerecording.length > 1)?_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.LAST_READINGS_DETAIL_MAPPING.name }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.ultimasLecturas")))])],1):_vm._e()])],1),(droughtConfiguration?.enabledAll)?_c('li',{staticClass:"nav-item first-level open"},[_c('router-link',{staticClass:"nav-link-item",class:{
                            'router-link-exact-active router-link-active': _vm.$route.name === _vm.viewMappingTypes.AFFECTATIONS_CONTRACTS_MAPPING.name,
                        },attrs:{"to":navigateAffectationContractsData()}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.afectaciones")))])],1):_vm._e(),_c('li',{staticClass:"nav-item first-level open"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.CONTRACTS_LIST_MAPPING.name, params: { contractId: selectedContractNumber } }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.tusContratos")))]),_c('ul',{staticClass:"nav-second-level"},[_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",class:{
                                    'router-link-exact-active router-link-active': _vm.$route.name === _vm.viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.name,
                                },attrs:{"to":navigatePaymentData()}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.modificar.datosPago")))])],1),_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",class:{
                                    'router-link-exact-active router-link-active':
                                        _vm.$route.name === _vm.viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.name,
                                },attrs:{"to":navigateCorrespondenceData()}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.modificar.datosCorrespondencia")))])],1),(isCompany)?_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",class:{
                                    'router-link-exact-active router-link-active': _vm.$route.name === _vm.viewMappingTypes.MODIFICATION_OWNER_DATA_MAPPING.name,
                                },attrs:{"to":navigateOwnerData()}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.modificar.datosTitular")))])],1):_vm._e(),(hasAnyDomesticContract === true)?_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",class:{
                                    'router-link-exact-active router-link-active':
                                        _vm.$route.name === _vm.viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name,
                                },attrs:{"to":navigateCertificateOccupancy()}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.introduce.cedulaHabitabilidad")))])],1):_vm._e(),(hasAnyIndustrialOrComercialContract === true)?_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",class:{
                                    'router-link-exact-active router-link-active': _vm.$route.name === _vm.viewMappingTypes.INSERT_IAE_DATA_MAPPING.name,
                                },attrs:{"to":navigateIaeData()}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.introduce.datosIae")))])],1):_vm._e(),_c('li',{staticClass:"nav-item second-level"},[_c('router-link',{staticClass:"nav-link-item",class:{
                                    'router-link-exact-active router-link-active': _vm.$route.name === _vm.viewMappingTypes.UNSUSCRIBE_CONTRACT_VIEW_MAPPING.name,
                                },attrs:{"to":navigateUnsuscribeContract()}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.bajaContrato")))])],1)])],1),_c('li',{staticClass:"nav-item first-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.NOTIFICATIONS_LIST_MAPPING.name }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.tusAVisos")))])],1),(_vm.authorizationsViewEnabled === 'true')?_c('li',{staticClass:"nav-item first-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.AUTHORIZATIONS_VIEW_MAPPING.name }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.autorizaciones")))])],1):_vm._e(),_c('li',{staticClass:"nav-item first-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.USER_ACTIONS_MAPPING.name }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.historialGestiones")))])],1),_c('li',{staticClass:"nav-item first-level"},[_c('a',{staticClass:"nav-link-item",attrs:{"target":"_blank","href":_vm.contrataSuministroURL}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.contrataSuministro")))])]),_c('li',{staticClass:"nav-item first-level"},[_c('router-link',{staticClass:"nav-link-item",attrs:{"to":{ name: _vm.viewMappingTypes.RECLAMATION_FORM_MAPPING.name }}},[_vm._v(_vm._s(_vm.$t("ofex.menuLateral.reclamacion")))])],1)])])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }