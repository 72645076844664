"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";

export default class ReclamationPdfGenerator extends BaseGenerator {
    constructor(i18n, user, client, contract) {
        super("Reclamaciones.pdf", "", i18n, user, client, contract);
    }
    generatePdf(formModel, isAuthenticated, selectedContractNumber, selectedInvoiceNumber) {
        console.log(`Generando pdf de baja de reclamaciones`);
        const SPACE = " ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.reclamations.title"), "");
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.reclamations.datosContacto"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.typeDocument") + SPACE + formModel.contactData.doc.type);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.numberDocument") + SPACE + formModel.contactData.doc.number);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.nombre") + SPACE + formModel.contactData.name);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.primerApellido") + SPACE + formModel.contactData.lastName);
        if (formModel.contactData.secondLastName) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.segundoApellido") + SPACE + formModel.contactData.secondLastName);
        }
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.tfnoPrincipal") + SPACE + formModel.contactData.mainPhone);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.email") + SPACE + formModel.contactData.email);

        if (isAuthenticated) {
            if (selectedContractNumber) {
                this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.numeroContrato") + SPACE + selectedContractNumber);
            }
            if (selectedInvoiceNumber) {
                this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.numeroFactura") + SPACE + selectedInvoiceNumber);
            }
        }
        if (formModel.reclamation.attachedFile.file.name != "") {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.ficheroAdjunto") + SPACE + formModel.reclamation.attachedFile.file.name);
        }

        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.reclamations.aceptacionCondiciones"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.aceptoCondiciones") + SPACE + "Si");
        if (formModel.reclamation.textArea) {
            this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.reclamations.reclamacion"));
        }
        if (formModel.reclamation.textArea) {
            this.generateMultilineText(formModel.reclamation.textArea);
        }

        return this.pdf;
    }
}
