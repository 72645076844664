<script>
import { Line } from "vue-chartjs";
import moment from "moment";
import fill from "lodash/fill";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import ConsumptionsServiceClient from "@/rest/consumptionsServiceClient";
import { mapGetters, mapState } from "vuex";
import { AB_DATE_FORMAT, AB_DATETIME_FORMAT } from "@/plugins/ab-utils";
import { executeAccessibilityFixes } from "@/plugins/accesibility";

export default {
    extends: Line,
    props: {
        firstFilterDates: {
            type: Object,
            required: true,
        },
        secondFilterDates: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            firstConsumptions: [],
            secondConsumptions: [],
        };
    },
    methods: {
        loadServiceData: function () {
            console.log("Cargando datos de gráfica comparativa");
            let vueContext = this;
            let showNegativeValues = false;
            //solo lo lanzamos si ya no está cargando otros datos la página
            if (!this.isLoading) {
                this.$store.commit(SET_LOADING_MUTATION, { isLoading: true, trigger: "AbConsumptionTimelineChart" });
            }

            ConsumptionsServiceClient.getDailyConsumptions(
                this.selectedContractNumber,
                // "9048284",
                this.clientId,
                this.userId,
                this.$i18n.locale,
                this.firstFilterDates.fromDate,
                this.firstFilterDates.toDate,
                showNegativeValues
            ).then((value) => {
                this.firstConsumptions = value.data;
                //rellenamos el segundo dataset
                ConsumptionsServiceClient.getDailyConsumptions(
                    vueContext.selectedContractNumber,
                    // "9048284",
                    vueContext.clientId,
                    vueContext.userId,
                    vueContext.$i18n.locale,
                    vueContext.secondFilterDates.fromDate,
                    vueContext.secondFilterDates.toDate,
                    showNegativeValues
                ).then((value) => {
                    vueContext.secondConsumptions = value.data;
                    if (vueContext.isLoading) {
                        vueContext.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "AbConsumptionTimelineChart" });
                    }
                    vueContext.renderAbChart();
                });
            });
        },
        fillOffsetDataset: function () {
            console.log("Igualando tamaño de ambos datasets");
            if (this.firstConsumptions.length > this.secondConsumptions.length) {
                this.secondConsumptions = fill(this.secondConsumptions, null, this.secondConsumptions.length, this.firstConsumptions.length);
            } else if (this.firstConsumptions.length < this.secondConsumptions.length) {
                this.firstConsumptions = fill(this.firstConsumptions, null, this.firstConsumptions.length, this.secondConsumptions.length);
            }
        },
        renderAbChart: function () {
            this.fillOffsetDataset();
            let chartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.$t("modules.consumptionTimelineChart.litros"),
                            },
                        },
                    ],
                    xAxes: [
                        {
                            id: "firstDatasetAxis",
                            scaleLabel: {
                                display: true,
                            },
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 0,
                                minRotation: 90,
                                mirror: true,
                            },
                        },
                    ],
                },
            };

            let chartData = {
                datasets: [
                    {
                        xAxisID: "firstDatasetAxis",
                        data: this.firstConsumptionDataset,
                        hoverBackgroundColor: "#95CAEA",
                        label: `Factura con consumo real`,
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: "#233A48",
                        borderColor: "#233A48", // The main line color
                        borderCapStyle: "butt",
                        borderDash: [], // try [5, 15] for instance
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "black",
                        pointBackgroundColor: this.consumptionPointsBackgroundColor,
                        pointBorderWidth: 1,
                        pointHoverRadius: 8,
                        pointHoverBackgroundColor: "#233A48",
                        pointHoverBorderColor: "#233A48",
                        pointHoverBorderWidth: 2,
                        pointRadius: 4,
                        pointHitRadius: 10,
                    },
                    {
                        xAxisID: "firstDatasetAxis",
                        data: this.secondConsumptionDataset,
                        hoverBackgroundColor: "#eadf95",
                        label: this.$t("modules.ConsumptionEvolutionChart.facturaConsumoReal"),
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: "#876b12",
                        borderColor: "#826c1c", // The main line color
                        borderCapStyle: "butt",
                        borderDash: [], // try [5, 15] for instance
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "black",
                        pointBackgroundColor: "#826c1c",
                        pointBorderWidth: 1,
                        pointHoverRadius: 8,
                        pointHoverBackgroundColor: "#826c1c",
                        pointHoverBorderColor: "#826c1c",
                        pointHoverBorderWidth: 2,
                        pointRadius: 4,
                        pointHitRadius: 10,
                    },
                ],
                labels: this.weekdayLabels,
            };

            this.renderChart(chartData, chartOptions);
        },
    },
    computed: {
        ...mapState(["selectedContractNumber", "isLoading"]),
        ...mapGetters(["clientId", "userId"]),
        firstConsumptionDataset: function () {
            return this.firstConsumptions.map((i) => {
                return {
                    x: moment(i.datetime, AB_DATETIME_FORMAT, "es").format("ddd").toUpperCase(),
                    y: i.deltaConsumption * 1000,
                };
            });
        },
        secondConsumptionDataset: function () {
            return this.secondConsumptions.map((i) => {
                return {
                    x: moment(i.datetime, AB_DATETIME_FORMAT, "es").format("ddd").toUpperCase(),
                    y: i.deltaConsumption * 1000,
                };
            });
        },
        consumptionPointsBackgroundColor: function () {
            return "#233A48";
        },
        weekdayLabels: function () {
            // TODO cogemos la menos de las fechas y la mayor de las fechas, las recorremos y vamos mapeando al array
            let momentDates = [
                moment(this.firstFilterDates.fromDate, AB_DATE_FORMAT),
                moment(this.firstFilterDates.toDate, AB_DATE_FORMAT),
                // moment(this.secondFilterDates.fromDate),
                // moment(this.secondFilterDates.toDate),
            ];

            let minDate = moment.min(momentDates);
            let maxDate = moment.max(momentDates);
            let labels = [];

            for (var m = moment(minDate); m.isBefore(maxDate); m.add(1, "days")) {
                let labelToAdd = m.format("ddd").substr(0, 2).toUpperCase();
                console.debug(`Añadiendo label ${labelToAdd} para fecha: ${m.format(AB_DATE_FORMAT)}`);
                labels.push(labelToAdd);
            }

            return labels;
        },
    },
    watch: {
        firstFilterDates: function () {
            console.log("Cambiando primeras fechas de filtros de consumos en gráfico");
            this.loadServiceData();
        },
        secondFilterDates: function () {
            console.log("Cambiando segundas fechas de filtros de consumos en gráfico");
            this.loadServiceData();
        },
    },
    mounted() {
        moment.locale("es");
        this.loadServiceData();
        executeAccessibilityFixes();
    },
};
</script>

<style lang="scss"></style>
