<template>
    <div class="app-about-container" v-if="appInfo">
        <p class="text-right mr-3 lh-sm">
            Build: {{ appInfo.build }} <br />
            Version: {{ appInfo.version }}
        </p>
    </div>
</template>

<script>
import { App } from "@capacitor/app";
export default {
    name: "AppAbout",
    data() {
        return {
            appInfo: null,
        };
    },
    methods: {},
    mounted() {
        App.getInfo().then((value) => (this.appInfo = value));
    },
};
</script>

<style lang="scss" scoped>
.app-about-container {
    position: absolute;
    margin: 0 !important;
    bottom: 30px;
    right: calc(50% - 50px);

    p {
        font-size: 8px;
        font-weight: bold;
    }
}
</style>
