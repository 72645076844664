<template>
    <b-card-body>
        <ValidationObserver ref="userForm" v-slot="{ reset, invalid, dirty }">
            <b-card-text>
                <div class="dropdown-inner">
                    <p class="reset-mail espacio-inf">{{ $t("ofex.bajaContratoDetalle.camposMarcados") }}</p>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                v-model="userOrClientDataModel.documentNumber"
                                name="user-data-nif"
                                data-key="key"
                                data-label="text"
                                :label="$t('ofex.UserData.nifLabel')"
                                :readonly="true"
                            />
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="name"
                                v-if="!isCompany"
                                v-model="localClientData.name"
                                name="user-data-name"
                                data-key="key"
                                data-label="text"
                                :label="$t('ofex.UserData.nameLabel')"
                                :readonly="true"
                            ></ab-input>
                            <ab-input
                                autocomplete="family-name"
                                v-if="isCompany"
                                v-model="localUserData.firstName"
                                name="user-data-name"
                                data-key="key"
                                data-label="text"
                                :label="$t('ofex.UserData.nameLabel')"
                                :readonly="true"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="family-name"
                                v-model="userOrClientDataModel.firstLastName"
                                name="user-data-surname"
                                data-key="key"
                                data-label="text"
                                :label="$t('ofex.UserData.surnameLabel')"
                                :readonly="true"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="family-name"
                                v-model="userOrClientDataModel.secondLastName"
                                name="user-data-second-surname"
                                data-key="key"
                                data-label="text"
                                :label="$t('ofex.UserData.surnameSecondLabel')"
                                :readonly="true"
                            ></ab-input>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-6">
                            <ab-input
                                autocomplete="email"
                                v-model="userOrClientDataModel.email"
                                name="userDataEmail"
                                ref="userDataEmail"
                                data-key="key"
                                data-label="text"
                                v-bind:validation-delay="200"
                                v-bind:validation="{
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                }"
                                :label="$t('ofex.UserData.emailLabel')"
                            ></ab-input>
                        </div>
                        <div
                            class="col-12 col-lg-6"
                            v-if="typeof $refs.userDataEmail !== 'undefined' && $refs.userDataEmail.$refs.validationProvider.flags.dirty"
                        >
                            <ab-input
                                autocomplete="email"
                                v-model="emailRepeat"
                                name="userDataRepeatEmail"
                                data-key="key"
                                data-label="text"
                                v-bind:validation-delay="200"
                                v-bind:validation="{
                                    is: userOrClientDataModel.email,
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                }"
                                :label="$t('ofex.UserData.emailRepeatLabel')"
                                :pasteable="false"
                            ></ab-input>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="tel-national"
                                v-model="userOrClientDataModel.mainPhone"
                                name="user-data-phone"
                                data-key="key"
                                data-label="text"
                                v-bind:validation="{ required: true, spanishPhone: true }"
                                :label="$t('ofex.UserData.phoneLabel')"
                                :popover="$t('ofex.modificarDatosContrato.tlfPreferente')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-select
                                v-model="userOrClientDataModel.language"
                                name="user-data-language"
                                data-key="key"
                                data-label="text"
                                v-bind:validation="{ required: true }"
                                v-bind:data-list="languageSelectData"
                                :label="$t('ofex.UserData.languageLabel')"
                            >
                            </ab-select>
                        </div>
                        <div class="col-12 col-lg-4">
                            <ab-input
                                v-show="false"
                                v-model="userOrClientDataModel.twitter"
                                name="user-data-twitter"
                                data-key="key"
                                :validation="{ regex: /^@[a-zA-Z0-9_]{1,15}$/ }"
                                data-label="text"
                                :label="$t('ofex.UserData.twitterLabel')"
                            ></ab-input>
                        </div>
                    </div>
                    <!-- todo: este modulo solo debe pintarse para empresas -->
                    <!--TODO:añadir modales -->
                    <div v-if="isCompany">
                        <p>
                            <router-link class="link d-inline-flex" :to="{ name: viewMappingTypes.MODIFICATION_OWNER_DATA_MAPPING.name }">{{
                                $t("ofex.UserData.modifyClient")
                            }}</router-link>
                        </p>
                        <p>
                            <span @click="openModal()" class="pointer link d-inline-flex">{{ $t("ofex.UserData.legal") }}</span>
                        </p>

                        <ab-remote-modal :show-cancel-button="false" :html-url-es="legalUrls.urEs" :html-url-ca="legalUrls.urCa" modal-name="legal-modal">
                        </ab-remote-modal>

                        <div class="container-buttons mb-3">
                            <ab-button-ofex
                                class="caja-button"
                                :text-button="$t('ofex.buttonsOfex.enviar')"
                                :click-event="modify"
                                :disabled="invalid || !dirty"
                            ></ab-button-ofex>
                        </div>
                        <ab-text-end></ab-text-end>
                    </div>
                    <!--  -->
                    <div v-if="!isCompany">
                        <!-- TODO cambiar input-alias cuando tengamos los textos -->
                        <div class="mb-3">
                            <div class="mb-3">
                                <ab-radio
                                    v-model="localClientData.advertising"
                                    name="user-data-advertising"
                                    v-bind:validation="{ required: true }"
                                    v-bind:title="$t('ofex.modificarDatosContrato.radio.comunicacionesPromocionales')"
                                    v-bind:description="$t('ofex.modificarDatosContrato.radio.textComunicacionesPromocionales')"
                                    :commercial-terms="true"
                                />
                            </div>
                            <div>
                                <ab-radio
                                    v-model="localClientData.surveys"
                                    name="user-data-surveys"
                                    v-bind:validation="{ required: true }"
                                    v-bind:title="$t('ofex.modificarDatosContrato.radio.encuestasAguas')"
                                    v-bind:description="$t('ofex.modificarDatosContrato.radio.textEncuestasAguas')"
                                    :commercial-terms="true"
                                />
                            </div>
                            <div>
                                <ab-radio
                                    v-model="localClientData.profiling"
                                    name="user-data-profiling"
                                    v-bind:validation="{ required: true }"
                                    v-bind:title="$t('ofex.modificarDatosContrato.radio.perfilado')"
                                    v-bind:description="$t('ofex.modificarDatosContrato.radio.textPerfilado')"
                                    :commercial-terms="true"
                                />
                            </div>
                        </div>
                        <!--TODO:añadir modal con condicione slegales -->
                        <span @click="openModal()" class="pointer link mb-3 d-inline-flex">{{ $t("ofex.UserData.legal") }}</span>
                        <ab-remote-modal :show-cancel-button="false" :html-url-es="legalUrls.urEs" :html-url-ca="legalUrls.urCa" modal-name="legal-modal">
                        </ab-remote-modal>
                        <div class="container-buttons mb-3">
                            <ab-button-ofex
                                class="caja-button"
                                :text-button="$t('ofex.buttonsOfex.enviar')"
                                :click-event="modify"
                                :disabled="invalid || !dirty"
                            ></ab-button-ofex>
                        </div>
                        <ab-text-end></ab-text-end>
                    </div>
                </div>
            </b-card-text>
        </ValidationObserver>
    </b-card-body>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import * as viewMappingTypes from "@/router/view-mapping-types";
import * as actionTypes from "@/store/action-types";
import UserDataModifyPdfGenerator from "@/generators/pdf/UserDataModifyPdfGenerator";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import Swal from "sweetalert2";

export default {
    name: "AbUserData",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
            showCompare: false,
            localUserData: Object.assign({}, this.userData),
            localClientData: Object.assign({}, this.clientData),
            emailRepeat: null,
        };
    },
    methods: {
        modify: function () {
            //verificamos si es pago con factura simple o por contrato
            // this.isCompany
            //   ? usersServiceClient.modifyUser(this.userId, this.clientId, this.localUserData)
            // : clientsServiceClient.modifyClient(this.userId, this.clientId, this.localClientData);
            let validator = this.$refs.userForm;
            validator.validate().then(async (result) => {
                if (result) {
                    let pdfGenerator = new UserDataModifyPdfGenerator(this.$i18n, this.userId, this.clientId, "");
                    pdfGenerator.generatePdf(this.userOrClientDataModel, this.localUserData, this.localClientData, this.isCompany);
                    await pdfGenerator.uploadPdf();
                    this.isCompany
                        ? this.$store
                              .dispatch(actionTypes.UPDATE_USER, {
                                  clientId: this.clientId,
                                  userId: this.userId,
                                  localUserData: this.localUserData,
                                  pdf: pdfGenerator.uploadedDocumentId,
                              })
                              .then(() => {
                                  this.$store
                                      .dispatch(actionTypes.GET_PROFILE_ACTION, {
                                          userId: this.userId,
                                          clientId: this.clientId,
                                      })
                                      .then((value) => {
                                          this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "AbUserData" });
                                          this.resetUnsavedFlag();
                                          Swal.fire({
                                              showCloseButton: true,
                                              showCancelButton: true,
                                              text: this.$t("formularios.modificarUsuario.sendMessage"),
                                              icon: "success",
                                              confirmButtonText: this.$t("common.okButton"),
                                              cancelButtonText: this.$t("common.descargarPDF"),
                                          })
                                              .then(async (result) => {
                                                  console.debug("Datos Modificados" + value);
                                                  if (!result.isConfirmed) {
                                                      pdfGenerator.downloadPdf();
                                                  }
                                              })
                                              .catch((reason) => this.launchUpdateUser(reason));
                                      });
                              })
                              .catch((reason) => {
                                  this.launchUpdateUser(reason);
                              })
                        : this.$store
                              .dispatch(actionTypes.UPDATE_CLIENT, {
                                  clientId: this.clientId,
                                  userId: this.userId,
                                  localClientData: this.localClientData,
                                  pdf: pdfGenerator.uploadedDocumentId,
                              })
                              .then((value) => {
                                  this.$notifyFormActionToMatomo(true, "user_modification");
                                  console.debug("Terminando de actualizar Cliente" + value);
                                  this.$store
                                      .dispatch(actionTypes.GET_PROFILE_ACTION, {
                                          userId: this.userId,
                                          clientId: this.clientId,
                                      })
                                      .then(() => {
                                          this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "AbUserData" });
                                          this.resetUnsavedFlag();
                                          Swal.fire({
                                              showCloseButton: true,
                                              showCancelButton: true,
                                              text: this.$t("formularios.modificarUsuario.sendMessage"),
                                              icon: "success",
                                              confirmButtonText: this.$t("common.okButton"),
                                              cancelButtonText: this.$t("common.descargarPDF"),
                                          }).then(async (result) => {
                                              console.debug("Datos Modificados" + value);
                                              if (!result.isConfirmed) {
                                                  pdfGenerator.downloadPdf();
                                              }
                                          });
                                      });
                              })
                              .catch((error) => {
                                  this.$notifyFormActionToMatomo(false, "user_modification", error);
                              });
                }
            });
        },
        openModal: function () {
            this.$modal.show("legal-modal");
        },
        launchUpdateUser: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "ContractModificationView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: e.response && e.response.data ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
        resetUnsavedFlag: function () {
            console.debug("Modificacion OK. Reseteamos el form para que ya no aparezcan datos modificados no guardados");
            this.$refs.userForm.reset();
        },
    },

    computed: {
        ...mapState(["userData", "isLoading", "clientData", "selectedContractNumber"]),
        ...mapGetters(["isCompany", "clientId", "userId", "legalUrls"]),
        userOrClientDataModel: function () {
            return this.isCompany ? this.localUserData : this.localClientData;
        },
        languageSelectData: function () {
            return [
                { key: "es", text: this.$t("ofex.detalleContrato.espanol") },
                { key: "ca", text: this.$t("ofex.detalleContrato.catalan") },
            ];
        },
        isEmailInputDirty: function () {
            return typeof this.$refs.userDataEmail !== "undefined" && this.$refs.userDataEmail.$refs.validationProvider.flags.dirty;
        },
    },
    async mounted() {
        this.localUserData = Object.assign({}, this.userData);
        this.localClientData = Object.assign({}, this.clientData);
        //Si venimos del enlace de factura digital para cerrar la modal
        Swal.close();
    },
};
</script>

<style lang="scss" scoped>
.pointer {
    cursor: pointer;
}
.p-user-detail-view {
    .container-buttons {
        display: flex;
        justify-content: flex-end;

        .btn {
            width: auto;
        }
        @include respond-below(sm) {
            flex-wrap: wrap;

            .btn {
                width: 100%;
                margin-bottom: 15px;
            }
            .link-btn {
                width: 100%;

                text-align: center;
                order: 2;
                margin-right: 0;
            }
        }
    }
    .info-small-text {
        @include font-size(12);
        @include line-height(23);
    }
    .link {
        color: $link-secondary;
        border-bottom: 1px solid $link-secondary;
        font-weight: $font-primary-medium;
        @include font-size(16);
        @include line-height(19);
    }
    .dropdown-inner {
        margin-top: 15px;
    }
    .dropdown-section {
        border-bottom: 0;
    }
}
</style>
