<template name="language-container">
    <div class="language-container" v-if="reloadPageOnChangeLang === 'true'">
        <a @click.stop.prevent="reloadPageLang('ca')" :class="{ selected: $i18n.locale === 'ca' }" class="link" href="#">CA</a>
        <a @click.stop.prevent="reloadPageLang('es')" :class="{ selected: $i18n.locale === 'es' }" class="link" href="#">ES</a>
    </div>
    <div class="language-container" v-else>
        <a @click.stop.prevent="changeLang('ca')" :class="{ selected: $i18n.locale === 'ca' }" class="link" href="#">CA</a>
        <a @click.stop.prevent="changeLang('es')" :class="{ selected: $i18n.locale === 'es' }" class="link" href="#">ES</a>
    </div>
</template>

<script>
import * as mutationTypes from "@/store/mutation-types";
import { localeChanged } from "vee-validate";
import { Capacitor } from "@capacitor/core";
import { savePreference } from "@/services/nativePreferences";
import { NATIVE_LOCALE_PREFERENCE_KEY } from "@/services/services-constant-types";

export default {
    name: "AbHeaderLanguage",
    data: function () {
        return {
            reloadPageOnChangeLang: process.env.VUE_APP_RELOAD_PAGE_ON_CHANGE_LANG,
        };
    },
    methods: {
        changeLang: function (lang) {
            this.$i18n.locale = lang;
            this.$store.commit(mutationTypes.CHANGE_I18N_LOCALE, { i18nLocale: lang });
            localeChanged();
        },
        reloadPageLang: function (lang) {
            if (Capacitor.isNativePlatform()) {
                savePreference(NATIVE_LOCALE_PREFERENCE_KEY, lang).then(() => {
                    console.debug("Almacenado idioma en preferencias nativas");
                    this.$reloadPageLang(lang);
                });
            } else {
                this.$reloadPageLang(lang);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.language-container {
    @include respond-above(md) {
        margin-right: 20px;
    }
    .link {
        color: $color-lightest;
        @include font-size(9);
        @include line-height(25);

        @include respond-below(md) {
            @include font-size(14);
            font-weight: $font-primary-medium;
        }

        &.selected {
            font-weight: $font-primary-bold;
        }
        &:first-child {
            padding-right: 7px;
            margin-right: 7px;
            border-right: 1px solid $color-lightest;
        }
    }
}
</style>
