"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import { trimBackendCode } from "@/plugins/ab-utils";

const ACTIVITY_CODE = "PS";
export default class InvoicePaymentByContractPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId) {
        super("PagoContrato.pdf", ACTIVITY_CODE, i18n, userId, clientId, null);
    }

    generatePdf(unpaidInvoices) {
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.lastReading.title"), "");

        var head = [
            [
                `${this.i18n.tc("ofex.invoicesTable.columnNames.invoiceNumber")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.consuptiomUntil")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.issueDate")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.consumption")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.amount")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.status")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.shippmentType")}`,
            ],
        ];
        var body = [unpaidInvoices.length];
        unpaidInvoices.forEach(
            (i, index) =>
                (body[index] = [
                    `${i.invoiceYear}${i.invoiceNumber}`,
                    `${i.endDate}`,
                    `${i.startDate}`,
                    `${i.issueDate}`,
                    `${i.consumptionVolume}`,
                    `${i.amount?.toLocaleString("ca-ES", { minimumFractionDigits: 0, maximumFractionDigits: 2 })} €`,
                    `${i.invoiceStatus}`,
                    `${this.i18n.tc(`ofex.backendCodes.invoiceStatus.${trimBackendCode(i.invoiceStatus)}`)}`,
                    `${i.invoiceNumber}`,
                ])
        );
        this.generateAutoTable(head, body, 4);
        return this.pdf;
    }
}
