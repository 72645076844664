<template slot="user-panel-container">
    <div class="user-panel-container">
        <div v-if="clientData" class="logged-container">
            <div class="notification-container" :class="{ iosNotification: $isIos() }">
                <ab-notifications v-if="notLoginView" name-icon="bell" />
            </div>
            <ab-user-panel-dropdown
                class="user-panel"
                :class="{ iosUserPanel: $isIos() }"
                :data-list="userPanelData"
                name-icon="caret-down"
                label=""
            ></ab-user-panel-dropdown>
        </div>
    </div>
</template>

<script>
import AbNotifications from "../../components/AbNotifications";
import AbUserPanelDropdown from "../../components/AbUserPanelDropdown";

import * as actionTypes from "../../store/action-types";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2";
import InvoiceFspPdfGeneator from "@/generators/pdf/InvoiceFspPdfGeneator";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import { CONTRACTS_SELECTION_CONFIGURATION_MAPPING, USER_VIEW_DATA_MAPPING, APP_SETTINGS_VIEW_MAPPING } from "@/router/view-mapping-types";

export default {
    name: "AbHeaderUserPanel",
    components: {
        AbNotifications,
        AbUserPanelDropdown,
    },
    data() {
        return {};
    },
    methods: {
        logout: function () {
            Swal.fire({
                text: this.$i18n.tc("ofex.facturaDigital.modalMonoDesactivate"),
                icon: "question",
                confirmButtonText: "OK",
                denyButtonText: "No",
                showDenyButton: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let contractNumbers = this.contracts.map((value) => value.contractDetail.contractNumber);
                    let pdfGenerator = new InvoiceFspPdfGeneator(this.$i18n, this.userId, this.clientId, "");
                    pdfGenerator.generatePdf(contractNumbers, "", false);
                    await pdfGenerator.uploadPdf();
                    pdfGenerator.downloadPdf();

                    this.$store
                        .dispatch(actionTypes.UPDATE_FSP, {
                            active: "INACTIVE",
                            fspUrl: "",
                            pdf: pdfGenerator.uploadedDocumentId,
                            contractsNumbers: contractNumbers,
                        })
                        .then((value) => {
                            console.debug("Datos Modificados" + value);
                            this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
                            this.$store.commit(SET_LOADING_MUTATION, { isLoading: true, trigger: "InvoiceDigitalView" });
                        })
                        .catch((reason) => this.launchUpdateFSP(reason));
                }
            });
            Swal.fire({
                text: this.$i18n.tc("ofex.header.logoutWarning"),
                icon: "question",
                confirmButtonText: this.$i18n.tc("common.yes"),
                denyButtonText: this.$i18n.tc("common.no"),
                showDenyButton: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch(actionTypes.LOGOUT_ACTION, { logoutReason: "explicit" });
                    this.$notifyLoginToMatomo("");
                } else {
                    console.log("Cancelado logout por el usuario");
                }
            });
        },
    },
    computed: {
        ...mapState(["clientData"]),
        ...mapGetters(["multicontract"]),
        userPanelData: function () {
            return [
                { text: this.$t("modules.headerUserPanel.datosUsuario"), path: USER_VIEW_DATA_MAPPING.path },
                {
                    text: this.$t("modules.headerUserPanel.asignarContratos"),
                    path: CONTRACTS_SELECTION_CONFIGURATION_MAPPING.path,
                    conditional: this.$store.getters.multicontractOfex,
                },
                {
                    text: this.$t("modules.headerUserPanel.configuracionApp"),
                    path: APP_SETTINGS_VIEW_MAPPING.path,
                    conditional: this.$isNativeApp() && false,
                },
                { text: this.$t("modules.headerUserPanel.cerrarSesion"), path: "#", callback: this.logout },
            ];
        },
        notLoginView: function () {
            return this.$route.name !== "Login";
        },
    },
};
</script>

<style lang="scss">
.user-panel-container {
    .login-container {
        background: $color-lightest;
        border-radius: 3px;

        .link {
            color: $bg-primary;
            font-weight: $font-primary-extraBold;
            @include font-size(11);
            @include line-height(25);
            padding: 10px;
            position: relative;

            &:first-child:after {
                background: $bg-primary;
                content: "";
                position: absolute;
                right: 0;
                top: 10px;
                bottom: 10px;
                background: #002e6b;
                width: 1px;
            }
        }
    }
    .logged-container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;

        .notification-container {
            margin-right: 20px;
            position: relative;
            cursor: pointer;

            .icon {
                color: $color-lightest;
                @include font-size(21);
            }
            .notification-alert {
                position: absolute;
                top: 5px;
                right: -3px;
                height: 21px;
                width: 21px;
                border-radius: 50%;
                background: #da1b1b;
                border: 1px solid $bg-primary;
                color: $color-lightest;
                font-weight: $font-primary-bold;
                @include font-size(8);
                @include line-height(10);
                text-align: center;
            }
            .bp-dropdown__btn {
                border: 0;
                padding: 10px 7px;
            }
            .bp-dropdown__btn--active {
                background: transparent;
            }
            .bp-dropdown__body {
                padding: 0;
                position: absolute;
                left: auto !important;
                right: 0;

                @include respond-below(md) {
                    position: fixed;
                    left: 0 !important;
                    top: 46px !important;
                    margin-top: 4px;
                    transition: all 0.3s ease-in-out;
                }
            }
            .btn-container {
                .icon {
                    color: $link-primary;
                    @include font-size(13);
                    margin-left: 8px;
                }
                .icon-config {
                    width: 20px;
                    margin-right: 8px;
                }
            }
        }
        .user-panel {
            position: relative;

            .bp-dropdown__body {
                position: absolute;
                right: 0;
                left: auto !important;
                padding: 0;
                box-shadow: 0 4px 2px 0 #cccccc;
                border-radius: 4px;

                @include respond-above(md) {
                    margin-top: -8px;
                }
            }
            .bp-dropdown__btn {
                border: 0;
                padding: 0;

                .icon-dropdown {
                    @include respond-below(md) {
                        display: none;
                    }
                }
            }
            .user-panel-inner {
                padding-right: 7px;

                @include respond-below(md) {
                    padding-right: 0;
                }
                img {
                    margin-top: -2px;
                }
            }
            .icon {
                color: $color-lightest;
                @include font-size(18);
            }
            .user-name {
                display: inline-block;

                @include respond-below(md) {
                    width: 100%;
                }
            }
            .user-welcome-container {
                font-weight: $font-primary-semiBold;
                @include font-size(11);
                @include line-height(13);
                margin-left: 7px;
                position: relative;
                color: $color-primary;

                @include respond-above(md) {
                    text-align: left;
                    color: $color-lightest;
                }
                span {
                    margin-left: 3px;
                    max-width: 186px;
                    margin-bottom: -3px;
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                    display: inline-block;
                    white-space: nowrap;

                    @include respond-above(md) {
                        max-width: 100px;
                    }
                }
            }
            .dropdown-list {
                @include respond-below(md) {
                    text-align: right;
                }
                .item {
                    .link {
                        display: inline-block;
                        width: 100%;
                        color: $color-primary;
                        padding: 12px;
                        white-space: nowrap;
                        @include font-size(14);
                        @include line-height(16);
                        font-weight: $font-primary-medium;

                        @include respond-below(md) {
                            padding: 12px 15px;
                        }
                    }
                }
                .item.selected {
                    .link {
                        font-weight: $font-primary-extraBold;
                    }
                }
                .item:hover {
                    .link {
                        background: $bg-primary;
                        color: $color-lightest;
                    }
                }
                .nav-link-item {
                    color: #9b9b9b;
                    font-weight: $font-primary-medium;
                    @include font-size(14);
                    @include line-height(16);
                }
                .item:hover {
                    color: $color-primary;

                    .nav-link-item {
                        color: $color-primary;
                    }
                }
                .user-name {
                    border-bottom: 1px solid #9b9b9b;

                    .user-welcome-container {
                        padding: 12px;

                        @include respond-below(md) {
                            color: $color-primary;
                            font-weight: $font-primary-medium;
                            @include font-size(14);
                            @include line-height(16);
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }
}
.native {
    .user-panel-container .logged-container {
        .user-panel .bp-dropdown__btn .icon-dropdown {
            display: none;
        }
        .notification-container .bp-dropdown__body {
            position: fixed;
            left: 0 !important;
            top: 46px !important;
            transition: all 0.3s ease-in-out;
        }
        .user-panel .user-welcome-container {
            margin-left: 0;
            @include respond-above(md) {
                color: $color-primary;
            }
        }
    }
}
.iosNotification {
    .bp-dropdown__body {
        top: initial !important;
        bottom: 0;
    }
}

.iosUserPanel {
    .bp-dropdown__body {
        top: initial !important;
        bottom: 50px;
    }
}
</style>
