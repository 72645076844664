<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.menuLateral.facturasRectificativas')" />
                <div v-if="rectifyingInvoices && rectifyingInvoices.length > 0">
                    <!-- Advanced search -->
                    <ab-invoices-advance-search />
                    <vue-good-table
                        :columns="columns"
                        :rows="tableRows"
                        style-class="vgt-table"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            perPageDropdown: [10, 20, 30],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: this.$i18n.tc('table.siguiente'),
                            prevLabel: this.$i18n.tc('table.atras'),
                            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                            ofLabel: 'de',
                            allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                            label: 'selector-de-paginas',
                            name: 'perPageSelect',
                        }"
                    >
                        <template slot="table-row" slot-scope="props">
                            <div class="col-envio-inner" v-if="props.column.field == 'compensationStatus'">
                                <span class="text">{{ props.row.compensationStatus }}</span>
                                <div class="buttons-group">
                                    <b-dropdown
                                        :id="props.row.fullInvoiceNumber"
                                        dropleft
                                        title="Menu dropdown"
                                        size="lg"
                                        variant="link"
                                        toggle-class="text-decoration-none"
                                        no-caret
                                        class="table-dropdown"
                                    >
                                        <b-dropdown-item-button
                                            aria-describedby="desplegable"
                                            aria-label="Descargar factura"
                                            title="Menu dropdown"
                                            @click="downloadInvoice(props.row.invoiceNumber, props.row.invoiceYear, props.row.contractNumber)"
                                            >{{ $t("ofex.invoicesTable.downloadButtonText") }}</b-dropdown-item-button
                                        >
                                        <a href="/facturas-rectificativas" target="_blank" v-if="props.row.compensationStatusCode !== 'COMPENSATED'">
                                            <b-dropdown-item-button aria-describedby="dropdown-header-1" aria-label="Menu dropdown" title="Menu dropdown">{{
                                                $t("common.reclamar")
                                            }}</b-dropdown-item-button>
                                        </a>
                                    </b-dropdown>
                                </div>
                            </div>
                        </template>

                        <div slot="emptystate" class="text-center textdnormal">
                            {{ $t("ofex.invoiceView.emptyResults") }}
                        </div>
                    </vue-good-table>
                    <div class="table-buttons-container" v-show="false">
                        <ab-button-ofex
                            :text-button="$t('ofex.invoicePaymentByContractView.listadoFacturasPDF')"
                            :font-awesome-icon="'download'"
                            :click-event="generatePdfInvoiceList"
                        ></ab-button-ofex>
                        <ab-button-ofex
                            :click-event="generateExcelContractList"
                            :text-button="$t('ofex.invoicePaymentByContractView.listadoFacturasExcel')"
                            :font-awesome-icon="'download'"
                        ></ab-button-ofex>
                        <!--                        <ab-button-ofex text-button="Descargar facturas en EXCEL" :font-awesome-icon="'download'"></ab-button-ofex>-->
                    </div>
                </div>
                <ab-without-data :text="$t('ofex.withoutData.rectifyingInvoices')" v-else />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import { mapState, mapGetters } from "vuex";
import * as actionTypes from "../store/action-types";
import AbMainTitle from "../modules/AbMainTitle.vue";
import AbInvoicesAdvanceSearch from "../modules/invoices/AbInvoicesAdvanceSearch";

import moment from "moment";
import InvoicesListXlsxGenerator from "@/generators/xlsx/InvoicesListXlsxGenerator";

export default {
    name: "InvoicesView",
    components: {
        AbMainTitle,
        AbInvoicesAdvanceSearch,
        VueGoodTable,
    },
    data: function () {
        return {
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
            apimSubscriptionKey: process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_HEADER,
            selected: [],
            selectAll: false,
            loading: false,
        };
    },
    methods: {
        mapInvoiceToTableRow: function (invoiceEntry) {
            return {
                fullInvoiceNumber: `${invoiceEntry.invoiceYear}${invoiceEntry.invoiceNumber}`,
                invoiceYear: invoiceEntry.invoiceYear,
                invoiceNumber: invoiceEntry.invoiceNumber,
                contractNumber: invoiceEntry.contractNumber,
                contractStatus: this.$t("ofex.backendCodes.contractStatus." + invoiceEntry.contractStatus),
                issueDate: invoiceEntry.issueDate,
                amount: `${this.$formatNumber(invoiceEntry.amount)} €`,
                compensationStatus: this.$t("ofex.backendCodes.compensationStatus." + invoiceEntry.compensationStatus),
                compensationStatusCode: invoiceEntry.compensationStatus,
            };
        },
        mapInvoiceToExcel: function (invoiceEntry) {
            return {
                fullInvoiceNumber: `${invoiceEntry.invoiceYear}${invoiceEntry.invoiceNumber}`,
                consuptiomUntil: invoiceEntry.endDate,
                issueDate: invoiceEntry.issueDate,
                consumption: invoiceEntry.consumptionVolume,
                amount: this.$formatNumber(invoiceEntry.amount),
                status: invoiceEntry.invoiceStatus,
                //contractNumber: invoiceEntry.contractNumber,
                shippmentType: this.$t("ofex.backendCodes.deliveryType." + invoiceEntry.invoice.toString()), //TODO obtener del contrato
            };
        },
        sortDateColumnConsumption: function (x, y, col, rowX, rowY) {
            let rowXMoment = moment(rowX.issueDate, "DD-MM-YYYY");
            let rowYMoment = moment(rowY.issueDate, "DD-MM-YYYY");
            return rowXMoment.isBefore(rowYMoment) ? -1 : rowXMoment.isAfter(rowYMoment) ? 1 : 0;
        },
        generateExcelContractList: function () {
            let xlsxGenerator = new InvoicesListXlsxGenerator(
                `Facturas-${this.selectedContractNumber}-${this.userId}.xlsx`,
                this.invoicesRow,
                this.selectedContractNumber,
                this.supplyAddress,
                this.$i18n
            );
            console.log(xlsxGenerator);
        },
        generatePdfInvoiceList: function () {
            // let pdfGenerator = new InvoiceListPdfGenerator(this.$i18n, this.userId, this.clientId, this.selectedContract);
            // pdfGenerator.generatePdf(
            //     this.filteredInvoices != null ? this.filteredInvoices : this.userInvoices,
            //     this.selectedContractNumber,
            //     this.supplyAddress
            // );
            // pdfGenerator.downloadPdf();
        },
        downloadInvoice: function (invoiceNumber, invoiceYear, contractNumber) {
            this.$store.dispatch(actionTypes.DOWNLOAD_INVOICE_ACTION, {
                invoiceNumber,
                invoiceYear,
                contractNumber,
                locale: this.$i18n.locale,
                invoiceType: "RECTIFYING",
            });
        },
    },
    computed: {
        ...mapState(["isLoading", "clientData", "rectifyingInvoices", "filteredInvoices", "selectedContractNumber"]),
        ...mapGetters(["userId", "getContractByContractNumber", "getContractSupplyCompleteAddress"]),
        contract: function () {
            return this.getContractByContractNumber(this.$route.params.contractId);
        },
        supplyAddress: function () {
            return this.getContractSupplyCompleteAddress(this.selectedContractNumber);
        },
        tableRows: function () {
            return this.filteredInvoices !== null
                ? this.filteredInvoices.map(this.mapInvoiceToTableRow)
                : this.rectifyingInvoices.map(this.mapInvoiceToTableRow);
        },
        invoicesRow: function () {
            return this.filteredInvoices !== null ? this.filteredInvoices.map(this.mapInvoiceToExcel) : this.rectifyingInvoices.map(this.mapInvoiceToExcel);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.invoicesTable.columnNames.invoiceNumber"),
                    field: "fullInvoiceNumber",
                    tdClass: "first-col",
                    thClass: "first-col nowrap",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.contract"),
                    field: "contractNumber",
                    tdClass: "second-col",
                    thClass: "nowrap",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.contractStatus"),
                    field: "contractStatus",
                    tdClass: "second-col",
                    thClass: "nowrap",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.issueDate"),
                    field: "issueDate",
                    sortFn: this.sortDateColumnConsumption,
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.amount"),
                    field: "amount",
                    sortFn: function (x, y) {
                        let xNumber = Number.parseFloat(x.replace(" €"));
                        let yNumber = Number.parseFloat(y.replace(" €"));
                        return xNumber < yNumber ? -1 : xNumber > yNumber ? 1 : 0;
                    },
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.status"),
                    field: "compensationStatus",
                    tdClass: "col-envio",
                    thClass: "col-envio",
                    html: true,
                },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_RECTIFYING_INVOICES, { locale: this.$i18n.locale });
    },
};
</script>

<style lang="scss" scoped>
.table-buttons-container {
    @include respond-above(sm) {
        .btn {
            flex: 1 1 auto;
            width: auto;
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.m-select-your-contract {
    border-bottom: 1px solid $blue-dark;
    padding-bottom: 20px;
}
</style>
