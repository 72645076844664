<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <dropdown ref="userPanelDropdown">
        <template slot="btn">
            <div class="user-panel-inner">
                <img v-if="isCompany" alt="" src="../assets/images/svg/business.svg" />
                <font-awesome-icon v-else class="icon" icon="user-alt"></font-awesome-icon>
                <mq-layout class="user-name" :mq="menuNativo">
                    <span class="user-welcome-container"
                        >Hola!
                        <span>{{ fullName }}</span>
                    </span>
                </mq-layout>
            </div>
        </template>
        <template slot="icon">
            <font-awesome-icon class="icon icon-dropdown" :icon="nameIcon"></font-awesome-icon>
        </template>
        <template slot="body">
            <div class="user-panel">
                <ul class="dropdown-list">
                    <mq-layout class="user-name" :mq="menuNativoSmall">
                        <li class="user-welcome-container">
                            Hola!<span>{{ fullName }}</span>
                        </li>
                    </mq-layout>
                    <!-- TODO arnaldo -  si esta seleccionado un li debe tener la clase selected -->
                    <li class="item" :key="item.path" v-on:click.stop.prevent="safeCallback(item.callback)" v-for="item in filteredDataList">
                        <router-link v-if="!item.callback" class="link" :to="item.path">{{ item.text }}</router-link>
                        <a href="#" class="link" v-else>{{ item.text }}</a>
                    </li>
                </ul>
            </div>
        </template>
    </dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "bp-vuejs-dropdown";

export default {
    name: "AbUserPanelDropdown",
    components: {
        Dropdown,
    },
    data() {
        return {
            isWebApp: this.$isWebApp(),
        };
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        nameIcon: {
            type: String,
        },
        dataList: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    methods: {
        safeCallback: function (callback) {
            this.$refs.userPanelDropdown.isHidden = true;
            return typeof callback === "function" ? callback.call() : () => void 0;
        },
    },
    computed: {
        filteredDataList: function () {
            //devolvemos un array filtrado con los que no tengan definido el atributo conditional o que efectivamente si lo definen, que lo cumplan.
            return this.dataList.filter((item) => typeof item.conditional !== "boolean" || item.conditional !== false);
        },
        menuNativo() {
            let isNative = [];
            if (this.isWebApp) {
                isNative = ["md", "lg"];
            } else {
                isNative = [""];
            }
            return isNative;
        },
        menuNativoSmall() {
            let isNativeSmall = [];
            if (this.isWebApp) {
                isNativeSmall = ["xs", "sm"];
            } else {
                isNativeSmall = ["xs", "sm", "md", "lg"];
            }
            return isNativeSmall;
        },
        ...mapGetters(["fullName", "isCompany"]),
    },
};
</script>

<style lang="scss">
.bp-dropdown__btn--active {
    background: transparent;
}
</style>
