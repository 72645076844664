<template>
    <div class="m-advance-search">
        <div class="contract-info">
            <!-- TODO Arnaldo dar logica al texto filtrados -->
            <div class="contract-info-inner">
                <span class="d-none">{{ $t("ofex.advanceSearch.infoContractAmount", { amount: userInvoices.length }) }}</span>
                <span v-if="filteredInvoices !== null" class="text-filter d-none">{{
                    $t("ofex.advanceSearch.infoContractFilter", { filter: filteredInvoices.length })
                }}</span>
            </div>
            <div class="text" v-on:click="show = !show" :class="show ? 'active' : ''">
                <span>{{ $t("ofex.advanceSearch.advanceSearchText") }}</span>
                <font-awesome-icon class="icon" icon="chevron-down"></font-awesome-icon>
            </div>
        </div>
        <transition name="slide-fade">
            <div class="dropdown" v-if="show">
                <div class="dropdown-inner">
                    <div class="dates-select-container">
                        <p class="title-module-filter">{{ $t("consultarTusFacturas.fechas") }}</p>
                        <section class="datepicker-container">
                            <span class="letraform-new">{{ $t("ofex.invoiceAdvanceSearch.periodoConsumo") }}</span>
                            <date-picker
                                v-model="filter.consumptionDateRange"
                                type="date"
                                value-type="format"
                                :range="true"
                                :placeholder="$t('ofex.invoiceView.dateRange')"
                                :format="'DD-MM-YYYY'"
                                :title-format="'DD-MM-YYYY'"
                                :lang="$i18n.locale"
                                :clearable="false"
                            >
                            </date-picker>
                        </section>
                        <section class="datepicker-container">
                            <span class="letraform-new">{{ $t("ofex.invoiceAdvanceSearch.fechaEmision") }}</span>
                            <date-picker
                                v-model="filter.issueDateRange"
                                type="date"
                                value-type="format"
                                :range="true"
                                :placeholder="$t('ofex.invoiceView.dateRange')"
                                :format="'DD-MM-YYYY'"
                                :title-format="'DD-MM-YYYY'"
                                :lang="$i18n.locale"
                                :clearable="false"
                            >
                            </date-picker>
                        </section>
                    </div>
                    <div class="consumption-input-container">
                        <p class="title-module-filter">{{ $t("consultarTusFacturas.consumos") }}</p>
                        <ab-input-detalle
                            v-model="filter.consumptionVolumeMin"
                            name="cconsumo-desde"
                            :label="$t('consultarTusFacturas.desde')"
                            input-alias="consumo-desde"
                            :validation="{ numeric: true }"
                            :detalle-text="'m'"
                            :detalle-text-sup="'3'"
                        >
                        </ab-input-detalle>
                        <ab-input-detalle
                            v-model="filter.consumptionVolumeMax"
                            name="consumo-hasta"
                            :label="$t('consultarTusFacturas.hasta')"
                            input-alias="consumo-hasta"
                            :validation="{ numeric: true }"
                            :detalle-text="'m'"
                            :detalle-text-sup="'3'"
                        >
                        </ab-input-detalle>
                    </div>
                    <div class="amount-input-container">
                        <p class="title-module-filter">{{ $t("consultarTusFacturas.importe") }}</p>
                        <ab-input-detalle
                            v-model="filter.amountMin"
                            name="importe-desde"
                            :label="$t('consultarTusFacturas.desde')"
                            input-alias="importe-desde"
                            :validation="{ numeric: true }"
                            :detalle-text="'€'"
                        >
                        </ab-input-detalle>
                        <ab-input-detalle
                            v-model="filter.amountMax"
                            name="importe-hasta"
                            :label="$t('consultarTusFacturas.hasta')"
                            input-alias="importe-hasta"
                            :validation="{ numeric: true }"
                            :detalle-text="'€'"
                        >
                        </ab-input-detalle>
                    </div>
                    <div class="filter-checkbox-container">
                        <p class="title-module-filter">{{ $t("consultarTusFacturas.estado") }}</p>
                        <div class="radio-multi-container">
                            <div class="radio-multi-inner">
                                <div class="radio-multi">
                                    <input v-model="statusEnum" :value="'paid'" checked="checked" id="paid" name="filter" type="radio" />
                                    <label class="label-filter" for="paid"> {{ $t("consultarTusFacturas.pagadas") }}</label>
                                </div>
                                <div class="radio-multi">
                                    <input v-model="statusEnum" :value="'unpaid'" id="noPaid" name="filter" type="radio" />
                                    <label class="label-filter" for="noPaid">{{ $t("consultarTusFacturas.noPagadas") }}</label>
                                </div>
                                <div class="radio-multi">
                                    <input v-model="statusEnum" :value="'inProcess'" name="filter" id="inProcess" type="radio" />
                                    <label class="label-filter" for="inProcess">{{ $t("consultarTusFacturas.enProceso") }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="search-button-container">
                        <a href="#" @click.prevent="clearFilter" class="link">{{ $t("ofex.advanceSearch.removeFilters") }}</a>
                        <div class="button-container-inner">
                            <ab-button-ofex :click-event="filterInvoices" :text-button="$t('ofex.advanceSearch.search')"></ab-button-ofex>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ca";
import "vue2-datepicker/locale/es";
import "vue2-datepicker/index.css";

import { mapState } from "vuex";
import * as actionTypes from "../../store/action-types";
import * as mutationTypes from "../../store/mutation-types";

import AbInputDetalle from "./AbInputDetalle";

export default {
    name: "AbInvoicesAdvanceSearch",
    components: {
        AbInputDetalle,
        DatePicker,
    },
    data() {
        return {
            show: false,
            statusEnum: null,
            filter: {
                // IMPORTANTE No definimos las propiedades porque Axios varia el comportamiento si es null o undefined

                // consumptionDateRange: [],
                // issueDateRange: [],
                // consumptionVolumeMin: null,
                // consumptionVolumeMax: null,
                // amountMin: null,
                // amountMax: null,
                status: {
                    //     paid: false,
                    //     unpaid: false,
                    //     inProcess: false,
                },
            },
        };
    },
    methods: {
        filterInvoices: function () {
            //https://zenerab.atlassian.net/browse/UATOFEX-125
            this.filter.status = this.statusFromRadioButtonValue();

            console.log(`Filtrando facturas con filtro: ${JSON.stringify(this.filter)}`);
            this.$store.dispatch(actionTypes.FILTER_USER_INVOICES_ACTIONS, {
                locale: this.$i18n.locale,
                filter: this.filter,
            });
        },
        clearFilter: function () {
            this.statusEnum = null;
            this.filter = {
                status: {},
            };
            this.$store.commit(mutationTypes.CLEAR_INVOICES_FILTER_MUTATION);
        },
        statusFromRadioButtonValue: function () {
            switch (this.statusEnum) {
                case "unpaid":
                    return { unpaid: true };
                case "paid":
                    return { paid: true };
                case "inProcess":
                    return { inProcess: true };
                default:
                    return {};
            }
        },
    },
    computed: {
        ...mapState(["userInvoices", "filteredInvoices"]),
    },
};
</script>

<style lang="scss" scoped>
.m-advance-search {
    .contract-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid $link-primary;
        padding-bottom: 10px;

        @include respond-above(sm) {
            margin-bottom: 0;
        }
        .contract-info-inner {
            color: $color-primary;
            font-weight: $font-primary-bold;
            @include font-size(18);
            @include line-height(21);

            @include respond-below(sm) {
                margin-bottom: 10px;
            }
            .text-filter {
                margin-left: 15px;
            }
        }
    }
    .radio-multi-container {
        @include respond-above(sm) {
            margin-top: 44px;
        }

        .label-filter {
            margin-left: 10px;
            margin-bottom: 15px;
            cursor: pointer;
        }
        input {
            cursor: pointer;
        }
    }
    .text {
        display: flex;
        align-items: center;
        color: $blue-dark;
        font-weight: $font-primary-bold;
        @include font-size(16);
        @include line-height(19);
        cursor: pointer;

        @include respond-below(sm) {
            margin-bottom: 10px;
        }
        .icon {
            transition: all 0.2s ease-in-out;
        }
        &.active {
            .icon {
                transform: rotate(180deg);
            }
        }
        span {
            margin-right: 10px;
        }
        .icon {
            margin-right: 7px;
        }
    }
    .dropdown {
        position: relative;
        flex: 100%;
        background: #f8f8f8;

        .icon {
            width: 17px;
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;

            @include respond-above(sm) {
                width: 10px;
            }
        }
        .dropdown-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px;

            @include respond-above(sm) {
                padding: 20px;
            }
            .search-button-container {
                display: flex;
                flex-basis: 100%;
                justify-content: flex-end;
                align-items: center;

                @include respond-below(sm) {
                    justify-content: space-between;
                    margin-top: 25px;
                }
                .link {
                    color: $button-bg-primary;
                    font-weight: $font-primary-semiBold;
                    @include font-size(16);
                    @include line-height(21);
                    padding: 8px;
                    margin-right: 30px;

                    @include respond-below(sm) {
                        flex: 1;
                        text-align: center;
                    }
                }
                .button-container-inner {
                    @include respond-below(sm) {
                        flex: 1;
                    }
                }
            }
            .dates-select-container {
                flex-basis: 100%;
                position: relative;

                .select-label {
                    margin: 0;
                }
                .select-component {
                    &:first-child {
                        margin-bottom: 20px;
                    }
                }
                @include respond-above(lg) {
                    flex-basis: 30%;
                    padding-right: 30px;
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 58px;
                        width: 1px;
                        height: 112px;
                        background: $blue-dark;
                    }
                }
                .info {
                    color: $color-primary;
                    @include font-size(11);
                    @include line-height(18);
                    margin-top: 5px;
                }
                .multiselect__select {
                    height: 32px;
                    text-align: right;
                    right: 0;
                }
            }
            .consumption-input-container {
                flex-basis: 100%;
                position: relative;
                margin-bottom: 20px;

                .input-container {
                    margin-bottom: 20px;
                }
                @include respond-above(sm) {
                    flex-basis: 33%;
                    padding-right: 30px;
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 58px;
                        width: 1px;
                        height: 112px;
                        background: $blue-dark;
                    }
                }
                @include respond-above(lg) {
                    flex-basis: 23%;
                    padding-right: 30px;
                    padding-left: 30px;
                    margin-bottom: 0;
                }
            }
            .amount-input-container {
                flex-basis: 100%;
                position: relative;
                margin-bottom: 20px;

                .input-container {
                    margin-bottom: 20px;
                }
                @include respond-above(sm) {
                    flex-basis: 33%;
                    padding-right: 30px;
                    padding-left: 30px;
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 58px;
                        width: 1px;
                        height: 112px;
                        background: $blue-dark;
                    }
                }
                @include respond-above(lg) {
                    flex-basis: 23%;
                    padding-right: 30px;
                    padding-left: 30px;
                    margin-bottom: 0;
                }
            }
            .filter-checkbox-container {
                flex-basis: 100%;

                .checkbox-group {
                    margin-bottom: 10px;
                }
                @include respond-above(sm) {
                    flex-basis: 33%;
                    padding-left: 30px;
                }
                @include respond-above(lg) {
                    flex-basis: 23%;
                    padding-left: 30px;
                }
            }
        }
        .dates-select-container {
            @include respond-below(lg) {
                margin-bottom: 20px;
            }
            .datepicker-container {
                margin-bottom: 20px;
            }
        }
    }
    .slide-fade-enter-active {
        max-height: 500px;
        transition: max-height 0.3s ease-in-out;
    }
    .slide-fade-leave-active {
        max-height: 500px;
        transition: max-height 0.3s ease-in-out;
    }
    .slide-fade-enter,
    .slide-fade-leave-to {
        max-height: 0;
        transition: max-height 0.1s ease-in-out;
        opacity: 0;
    }
    .title-module-filter {
        @include font-size(14);
        @include line-height(16);
        font-weight: $font-primary-bold;
    }
    .space-check-filter {
        margin-top: 37px;
    }
    .div-double-date {
        width: 100%;
        height: 32px;
        border-radius: 8px;
        background-color: transparent;
    }
    .w-50 {
        width: 50%;
    }
    .space-inf-filter {
        margin-bottom: 20px;
    }
}
</style>
