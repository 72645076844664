var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"text-normal mt-3"},[_vm._v(_vm._s(_vm.$t("ofex.authorizationsView.subsectionPerPersonSubtitle")))]),_c('ab-authorizations-advance-search-by-persons',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],ref:"authorizationsAdvanceSearch",attrs:{"total":this.contractAuthorizationsByPerson.length,"rows":_vm.tableRows.length},on:{"filter-persons":_vm.executeFilter,"clear-filter":_vm.resetFilter},model:{value:(_vm.filteredPersons),callback:function ($$v) {_vm.filteredPersons=$$v},expression:"filteredPersons"}}),(_vm.contracts)?_c('vue-good-table',{ref:"authorizationsGroupedByPersonsTable",attrs:{"columns":_vm.columns,"rows":_vm.tableRows,"style-class":"vgt-table","select-options":{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
            perPageDropdown: [10, 20, 30],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: this.$i18n.tc('table.siguiente'),
            prevLabel: this.$i18n.tc('table.atras'),
            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
            ofLabel: 'de',
            allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
            label: 'selector-de-paginas',
            name: 'perPageSelect',
        }}},[_c('div',{staticClass:"text-center textdnormal",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t("ofex.tuContrato.noPersons"))+" ")])]):_vm._e(),_c('div',{staticClass:"row mt-3 mb-3 justify-content-end"},[_c('div',{staticClass:"col-lg-4 mb-4"},[_c('ab-button-ofex',{attrs:{"text-button":_vm.$t('ofex.buttonsOfex.revokeAuthorization'),"click-event":_vm.deleteAuthorizations}})],1),_c('div',{staticClass:"col-lg-6"},[_c('ab-button-ofex',{attrs:{"text-button":_vm.$t('ofex.buttonsOfex.manageContracts'),"click-event":_vm.navigateToAuthorizationsByPerson}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }