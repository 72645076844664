<template>
    <div class="public-form">
        <ab-loading v-model="isLoading"></ab-loading>
        <div v-if="curStep == 1">
            <p class="subtitle pb-2">{{ $t("ofex.forms.reclamations.subtitle") }}</p>
            <!-- Datos de contacto -->
            <ValidationObserver ref="reclamationForm" v-slot="{ errors, invalid }">
                <!-- bloque de errores -->
                <ab-error-header v-if="$refs.reclamationForm" :error-list="validationErrors"></ab-error-header>
                <ab-error-header :error-list="submitErrorList"></ab-error-header>
                <section>
                    <div class="mb-3 line-top pt-2">
                        <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                        <span class="item-collapse">{{ $t("ofex.forms.reclamations.datosContacto") }}</span>
                    </div>
                    <ab-id-type-number
                        v-if="!isAuthenticated"
                        v-model="formModel.contactData.doc"
                        validation-type-name="datos-contacto-tipo-doc"
                        :allow-cif="false"
                        class="mb-5"
                        :number-input-alias="$t('alias.idTypeNumber.numeroDocumentoUser')"
                        validation-number-name="datos-contacto-numero-doc"
                    />
                    <div class="row mb-5" v-if="isAuthenticated">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                name="document-type-reclamation"
                                placeholder=""
                                :label="$t('ofex.forms.readMeter.tipoDocumento')"
                                :readonly="true"
                                v-bind:validation="{ required: false, min: 1, max: 50 }"
                                v-model="formModel.contactData.doc.type"
                                validation-type-name="reclamation-tipo-doc"
                            />
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                name="document-number-reclamation"
                                placeholder=""
                                :readonly="true"
                                :label="$t('ofex.forms.readMeter.numDocumento')"
                                v-bind:validation="{ required: false, min: 1, max: 50 }"
                                v-model="formModel.contactData.doc.number"
                                validation-type-name="reclamation-documento-doc"
                            />
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="given-name"
                                v-model="formModel.contactData.name"
                                name="nombre-datos-contacto"
                                :readonly="isAuthenticated ? true : false"
                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.nombre')"
                                v-bind:validation="{ required: true, max: 50 }"
                                v-bind:label="$t('ofex.bajaContratoDetalle.nombre')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="family-name"
                                v-model="formModel.contactData.lastName"
                                name="primer-apellido-datos-contacto"
                                :readonly="isAuthenticated ? true : false"
                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.primerApellido')"
                                v-bind:validation="{ required: true, max: 50 }"
                                v-bind:label="$t('ofex.bajaContratoDetalle.primerApellido')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="family-name"
                                v-if="!isAuthenticated || clientData.secondLastName != ''"
                                v-model="formModel.contactData.secondLastName"
                                name="segundo-apellido-datos-contacto"
                                :readonly="isAuthenticated ? true : false"
                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.segundoApellido')"
                                v-bind:validation="{ required: false, max: 50 }"
                                v-bind:label="$t('ofex.bajaContratoDetalle.segundoApellido')"
                            ></ab-input>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="tel-national"
                                v-model="formModel.contactData.mainPhone"
                                name="telefono-principal-datos-contacto"
                                :readonly="isAuthenticated ? true : false"
                                v-bind:placeholder="$t('ofex.forms.reclamations.placeholders.telefono')"
                                :popover="$t('ofex.forms.reclamations.prefeMovil')"
                                v-bind:validation="{ required: true, spanishPhone: true }"
                                v-bind:label="$t('ofex.forms.reclamations.telefono')"
                            >
                            </ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="email"
                                v-model="formModel.contactData.email"
                                name="email-datos-contacto"
                                :readonly="isAuthenticated ? true : false"
                                placeholder="ejemplo@dominio.com"
                                v-bind:validation-delay="200"
                                v-bind:validation="{
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                }"
                                v-bind:label="$t('ofex.forms.reclamations.email')"
                            >
                            </ab-input>
                        </div>
                        <div class="col-12 col-lg-3" v-if="!isAuthenticated">
                            <ab-input
                                autocomplete="email"
                                v-model="formModel.contactData.emailConfirmation"
                                name="email-confirmation-datos-contacto"
                                placeholder="ejemplo@dominio.com"
                                v-bind:validation-delay="200"
                                v-bind:validation="{
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                    is: formModel.contactData.email,
                                }"
                                v-bind:label="$t('ofex.forms.reclamations.repiteMail')"
                                :pasteable="false"
                            >
                            </ab-input>
                        </div>
                        <!-- 
                        <div class="col-12 col-lg-3">
                            <ab-input
                                v-show="false"
                                v-if="!isAuthenticated || clientData.twitter != ''"
                                name="usuario-twitter-datos-contacto"
                                v-model="formModel.contactData.twitter"
                                :readonly="isAuthenticated ? true : false"
                                :validation="{ twitter: true }"
                                v-bind:placeholder="$t('ofex.forms.reclamations.placeholders.twitter')"
                                v-bind:label="$t('ofex.forms.reclamations.twitter')"
                            >
                            </ab-input>
                        </div>
                        -->
                    </div>
                    <p class="text-normal mb-3">{{ $t("ofex.forms.reclamations.relacionado") }}</p>
                    <div class="row mb-5" v-if="!isAuthenticated">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                name="numero-contrato-datos-contacto"
                                v-model="formModel.contactData.contractNumber"
                                v-bind:label="$t('ofex.forms.reclamations.numeroContrato')"
                                :validation="{ required: false, max: 50 }"
                                v-bind:placeholder="$t('ofex.forms.reclamations.placeholders.numeroContrato')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                name="numero-factura-datos-contacto"
                                v-model="formModel.contactData.invoiceNumber"
                                :validation="{ required: false, max: 50 }"
                                v-bind:label="$t('ofex.forms.reclamations.numeroFactura')"
                                v-bind:placeholder="$t('ofex.forms.reclamations.placeholders.numeroFactura')"
                            ></ab-input>
                        </div>
                    </div>
                    <div class="row mb-5" v-if="isAuthenticated">
                        <div class="col-12 col-md-8">
                            <ab-select-contract
                                :vuex-model="false"
                                :allow-empty="true"
                                :required="false"
                                v-model="formModel.contactData.contractNumber"
                                :father-class="'reclamationContract'"
                                :label="$t('ofex.forms.reclamations.tuContrato')"
                                :placeholder="$t('common.todos')"
                                :reclamationPage="true"
                            />
                        </div>
                        <div class="col-12 col-md-4">
                            <ab-select-invoice
                                v-if="formModel.contactData.contractNumber"
                                :vuex-model="false"
                                :allow-empty="true"
                                :required="false"
                                :custom-datalist="customInvoiceDatalist"
                                v-model="formModel.contactData.invoiceNumber"
                                :father-class="'reclamationInvoice'"
                                :label="$t('ofex.forms.reclamations.tusFacturas')"
                                :placeholder="$t('common.todos')"
                            />
                        </div>
                    </div>
                </section>
                <!-- Reclamación -->
                <section>
                    <div class="line-top pt-2 mb-3">
                        <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                        <span class="item-collapse">{{ $t("ofex.forms.reclamations.reclamacion") }}</span>
                    </div>
                    <ab-text-area
                        name="observaciones-reclamaciones"
                        v-bind:max-length="1000"
                        v-bind:required="true"
                        v-bind:input-alias="$t('ofex.forms.reclamations.observaciones')"
                        v-model="formModel.reclamation.textArea"
                        v-bind:label="$t('ofex.forms.reclamations.mensaje')"
                    >
                    </ab-text-area>
                    <ab-upload
                        :input-alias="$t('upload.ficheroAdjunto')"
                        name="fichero-adjunto-reclamaciones"
                        :popover="$t('ofex.forms.reclamations.ficheroAdjunto')"
                        v-model="formModel.reclamation.attachedFile"
                        :actividad="activityId"
                        :api-base-url="contentManagerBaseUrl"
                        class="mb-5"
                        :user="userData.documentNumber"
                        :client="clientData.documentNumber"
                        :contract="formModel.contactData.contractNumber"
                    ></ab-upload>
                </section>
                <!-- Política de privacidad -->
                <section>
                    <div class="line-top pt-2 mb-3">
                        <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                        <span class="item-collapse">{{ $t("ofex.forms.reclamations.politicaPrivacidad") }}</span>
                    </div>
                    <ab-legal-section
                        :show-legal="false"
                        :show-gdpr="true"
                        :fsp-es="legalUrls.fspEs"
                        :fsp-ca="legalUrls.fspCa"
                        :gdpr-es="legalUrls.gdprEs"
                        :gdpr-ca="legalUrls.gdprCa"
                        :legal-es="legalUrls.urEs"
                        :legal-ca="legalUrls.urCa"
                    ></ab-legal-section>
                    <div class="line-top d-flex pt-3 justify-content-end">
                        <div class="box-send">
                            <vue-recaptcha
                                ref="formRecaptcha"
                                :sitekey="recaptchaSiteCode"
                                :load-recaptcha-script="true"
                                @verify="verifyCaptcha"
                                @expired="resetCaptcha"
                            >
                                <ab-button-ofex text-button="Enviar" :disabled="invalid"></ab-button-ofex>
                            </vue-recaptcha>
                        </div>
                    </div>
                    <!-- textos finales -->
                    <ab-text-end name="textos-finales" />
                </section>
            </ValidationObserver>
        </div>
        <div v-if="curStep == 2">
            <ab-confirmation
                :generate-pdf-handler="downloadPdf"
                confirmation-description=" "
                v-bind:confirmation-msg="$t('ofex.forms.reclamations.textConfirmation')"
            />
        </div>
    </div>
</template>

<script>
import AbTextEnd from "../components/AbTextEnd.vue";
import ReclamationPdfGenerator from "@/generators/pdf/ReclamationPdfGenerator";
import { mapState, mapGetters } from "vuex";
import InvoicesServiceClient from "@/rest/invoicesServiceClient";
import ContentManagerServiceClient from "@/rest/contentManagerServiceClient";

export default {
    components: { AbTextEnd },
    name: "ReclamationFormApp",
    data: function () {
        return {
            recaptchaSiteCode: process.env.VUE_APP_RECAPTCHA_SITE_CODE_INVISIBLE_MODE,
            recaptchaResponse: null,
            contentManagerBaseUrl: process.env.VUE_APP_API_CONTENT_MANAGER_BASE_URL,
            pdfGenerator: null,
            authenticated: Boolean,
            isLoading: false,
            curStep: 1,
            submitErrorList: [],
            customInvoiceDatalist: [],
            activityId: "",
            formModel: {
                contactData: {
                    doc: { type: "", number: "", exists: false },
                    name: "",
                    lastName: "",
                    secondLastName: "",
                    mainPhone: "",
                    email: "",
                    emailConfirmation: "",
                    twitter: "",
                    contractNumber: "",
                    invoiceNumber: "",
                },
                reclamation: {
                    textArea: "",
                    attachedFile: {
                        file: { name: "" },
                        ecmProps: { fileId: null, actividadId: null, documentoId: null },
                    },
                },
            },
        };
    },
    props: {
        clientData: {
            type: Object,
            required: false,
        },
        userData: {
            type: Object,
            required: false,
        },
        selectedContact: {
            type: String,
            required: false,
        },
        selectedInvoice: {
            type: String,
            required: false,
        },
        isAuthenticated: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    computed: {
        ...mapState(["selectedContractNumber", "selectedInvoiceNumber"]),
        ...mapGetters(["isCompany", "legalUrls"]),
        validationErrors: function () {
            return Object.values(this.$refs.reclamationForm.errors).flat();
        },
        userOrClientDataModel: function () {
            return this.userData;
        },
    },
    created: function () {
        console.debug("CREATED ROOT VUE");
        this.activityId = "RT" + new Date().getTime();
    },
    methods: {
        verifyCaptcha: function (response) {
            console.debug("Captcha response: " + response);
            this.recaptchaResponse = response;
            this.submitForm();
        },
        resetCaptcha: function () {
            this.isLoading = false;
            this.$refs.formRecaptcha.reset();
        },
        submitForm: function () {
            let validator = this.$refs.reclamationForm;
            validator.validate().then(async (result) => {
                if (result) {
                    this.pdfGenerator = new ReclamationPdfGenerator(
                        this.$i18n,
                        this.userData.documentNumber,
                        this.clientData.documentNumber,
                        this.formModel.contactData.contractNumber
                    );
                    this.pdfGenerator.generatePdf(
                        this.formModel,
                        this.isAuthenticated,
                        this.formModel.contactData.contractNumber,
                        this.formModel.contactData.invoiceNumber
                    );
                    await this.pdfGenerator.uploadPdfWithActivity(this.activityId, false);
                    await ContentManagerServiceClient.commitStorageDocumentsByActivity(this.activityId);
                    this.idDocumentoPdf = this.pdfGenerator.uploadedDocumentId;
                    this.sendForm();
                }
            });
        },
        /*
        handleRestError: function (error) {
            this.submitErrorList.push(error.body.errorMessage);
            console.error(error);
        }, */
        sendForm: function () {
            this.isLoading = true;
            console.debug("Submiting form");
            this.submitErrorList = [];
            this.$http
                .post(
                    `${process.env.VUE_APP_API_FORMS_PUBLIC_BASE_URL}/suggestions/create`,
                    {
                        captchaClientResponse: this.recaptchaResponse,
                        idDocumentoPdf: this.idDocumentoPdf,
                        actividadId: this.activityId,
                        lang: this.$i18n.locale,
                        dni: this.formModel.contactData.doc.number,
                        documentType: this.formModel.contactData.doc.type,
                        name: this.formModel.contactData.name,
                        firstName: this.formModel.contactData.lastName,
                        secondName: this.formModel.contactData.secondLastName,
                        twitter: this.formModel.contactData.twitter,
                        phone: this.formModel.contactData.mainPhone,
                        email: this.formModel.contactData.email,
                        suggestionText: this.formModel.reclamation.textArea,
                        contractNumber: this.formModel.contactData.contractNumber,
                        invoiceNumber: this.formModel.contactData.invoiceNumber,
                        userId: this.userData.documentNumber,
                        clientId: this.clientData.documentNumber,
                    },
                    {
                        params: {
                            clientId: this.clientData.documentNumber,
                            userId: this.userData.documentNumber,
                        },
                    }
                )
                .then((response) => {
                    console.log(response);
                    this.checkResponse(response);
                    this.isLoading = false;
                });
        },
        downloadPdf: function () {
            this.pdfGenerator.downloadPdf();
        },
        checkResponse: function (response) {
            if (response.status == "200") {
                this.$notifyFormActionToMatomo(true, "reclamation_form");
                this.isLoading = false;
                this.curStep = 2;
            } else {
                this.submitErrorList.push(response.body.errorMessage);
                this.$notifyFormActionToMatomo(false, "reclamation_form", response.body.errorMessage);
            }
        },
    },
    watch: {
        "formModel.contactData.contractNumber": async function (val) {
            console.debug("Cambiando contrato: " + val);
            this.formModel.contactData.invoiceNumber = null;

            //recargamos las facturas del contrato
            this.isLoading = true;
            let invoices = await InvoicesServiceClient.getUserInvoices(val, this.userData.documentNumber, this.clientData.documentNumber, this.$i18n.locale);
            this.customInvoiceDatalist = invoices.data.map((value) => {
                return {
                    text: ` Fra. ${value.startDate} a ${value.endDate}`,
                    invoiceNumber: value.invoiceNumber,
                    key: `${value.startDate} ${value.endDate}`,
                };
            });
            this.isLoading = false;
        },
    },
    mounted() {
        if (this.isAuthenticated) {
            this.formModel.contactData.doc.type = this.userOrClientDataModel.documentType;
            this.formModel.contactData.doc.number = this.userOrClientDataModel.documentNumber;
            this.formModel.contactData.name = this.userOrClientDataModel.firstName;
            this.formModel.contactData.lastName = this.userOrClientDataModel.firstLastName;
            this.formModel.contactData.secondLastName = this.userOrClientDataModel.secondLastName;
            this.formModel.contactData.mainPhone = this.userOrClientDataModel.mainPhone;
            this.formModel.contactData.email = this.userOrClientDataModel.email;
            this.formModel.contactData.twitter = this.userOrClientDataModel.twitter;
        }
    },
};
</script>

<style lang="scss" scoped>
.subtitle {
    @include font-size(14);
    @include line-height(16);
    font-weight: $font-primary;
    color: $color-secondary;
}
.text-normal {
    @include font-size(14);
    @include line-height(16);
    font-weight: $font-primary;
    color: $color-secondary;
}
.line-top {
    border-top: 1px solid $blue-dark;
}
.icon {
    position: relative;
    top: 2px;
    color: $blue-dark;
    width: 20px;
    height: 20px;
}
.item-collapse {
    @include font-size(16);
    @include line-height(26);
    color: $blue-dark;
    font-weight: $font-primary-bold;
}
textarea {
    min-height: 200px;
}
.box-send {
    width: 120px;
}
</style>
<style lang="scss">
#observaciones-reclamaciones {
    min-height: 200px;
    @include font-size(14);
}
</style>

<style lang="scss">
.reclamationContract {
    .select-component {
        flex-direction: column;
        .select-label {
            color: $blue-dark !important;
            font-weight: $font-primary-bold;
            @include font-size(12);
            line-height: 25px !important;
        }
    }

    .remote-reading {
        display: none;
    }
}
.reclamationInvoice {
    .select-component {
        flex-direction: column !important;
        .select-label {
            color: $blue-dark !important;
            font-weight: $font-primary-bold;
            @include font-size(12);
            line-height: 25px !important;
        }
    }
}
</style>
