"use strict";

import BaseXlsxGenerator from "@/generators/xlsx/base/BaseXlsxGenerator";
import * as xlsx from "xlsx";

export default class ConsumptionEvolutionListXlsxGenerator extends BaseXlsxGenerator {
    constructor(filename, consumptions, i18n) {
        let rows = consumptions.map((i) => {
            return {
                startDate: i.startDate,
                endDate: i.endDate,
                amount: i.amount + " €",
                consumptionVolume: i.consumptionVolume + " m2",
            };
        });

        let contractsSheet = xlsx.utils.json_to_sheet(rows);
        contractsSheet["A1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.periodoConsumo");
        contractsSheet["B1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.hasta");
        contractsSheet["C1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.importe");
        contractsSheet["D1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.consumo");

        super(filename, contractsSheet);
    }
}
