<template>
    <div class="m-advance-search">
        <div class="contract-info">
            <div class="text" v-on:click="show = !show" :class="show ? 'active' : ''">
                <span>{{ $t("ofex.advanceSearch.advanceSearchText") }}</span>
                <font-awesome-icon class="icon" icon="chevron-down"></font-awesome-icon>
            </div>
        </div>
        <transition name="slide-fade">
            <div class="dropdown" v-if="show">
                <div class="dropdown-inner box-dropdown">
                    <ab-select-contract
                        :vuex-model="false"
                        :allow-empty="true"
                        :father-class="'v-managment-history'"
                        v-model="filter.contractNumber"
                        :placeholder="$t('common.todos')"
                    />
                    <div class="d-md-flex mb-5 justify-content-between">
                        <div class="box-all mr-2">
                            <ab-autocompletable-select
                                class="your-contract"
                                :data-list="channelList"
                                v-model="filter.channel"
                                :placeholder="$t('common.todos')"
                                :label="$t('ofex.managementHistory.table.channel')"
                            />
                        </div>
                        <div class="box-all mr-2">
                            <div class="datepicker-container">
                                <span class="letraform-new">{{ $t("ofex.invoiceView.dateRange") }}</span>
                                <date-picker
                                    v-model="filter.notificationsDateRange"
                                    type="date"
                                    value-type="format"
                                    :range="true"
                                    :placeholder="$t('ofex.invoiceView.dateRange')"
                                    :format="'DD-MM-YYYY'"
                                    :title-format="'DD-MM-YYYY'"
                                    :lang="$i18n.locale"
                                    :clearable="false"
                                >
                                </date-picker>
                            </div>
                        </div>
                        <div class="box-all">
                            <ab-autocompletable-select
                                class="your-contract"
                                :data-list="statusList"
                                v-model="filter.status"
                                :placeholder="$t('common.todos')"
                                :label="$t('ofex.managementHistory.table.status')"
                            />
                        </div>
                    </div>
                    <div class="d-flex justify-content-end align-items-center">
                        <a href="#" @click.prevent="clearFilter" class="link mr-4">{{ $t("ofex.advanceSearch.removeFilters") }}</a>
                        <div class="button-container-inner">
                            <ab-button-ofex :click-event="filterActions" :text-button="$t('ofex.advanceSearch.search')"></ab-button-ofex>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import uniq from "lodash/uniq";
import compact from "lodash/compact";

export default {
    name: "AbUserActionsAdvanceSearch",
    components: { DatePicker },
    props: {
        userActions: {},
    },
    data() {
        return {
            show: false,
            filter: {},
        };
    },
    methods: {
        filterActions: function () {
            this.$emit("filter-actions", this.filter);
        },
        clearFilter: function () {
            this.filter = {
                frequency: {},
                type: {},
            };
            this.$emit("clear-filter", this.filter);
        },
    },
    computed: {
        contractsList: function () {
            return compact(uniq(this.userActions.map((action) => action.contractNumber)));
        },
        channelList: function () {
            return compact(uniq(this.userActions.map((action) => action.channel)));
        },
        statusList: function () {
            return compact(uniq(this.userActions.map((action) => action.status)));
        },
        ...mapState(["contracts"]),
    },
};
</script>

<style lang="scss" scoped>
.link {
    color: $blue-darker;
    font-weight: $font-primary-medium;
}
.contract-info {
    .text {
        display: flex;
        align-items: center;
        color: $blue-dark;
        font-weight: $font-primary-bold;
        @include font-size(16);
        @include line-height(19);
        cursor: pointer;
        margin-bottom: 20px;

        .icon {
            transition: all 0.2s ease-in-out;
        }
        &.active {
            .icon {
                transform: rotate(180deg);
            }
        }
        span {
            margin-right: 10px;
        }
        .icon {
            margin-right: 7px;
        }
    }
}
.box-dropdown {
    padding: 20px;
    background: $bg-grey-light;
}
.box-all {
    width: 30%;
    @include respond-below(sm) {
        width: 100%;
    }
}
.button-container-inner {
    width: 120px;
}
.datepicker-container {
    @include respond-below(sm) {
        margin-bottom: 20px;
    }
}
</style>

<style lang="scss">
.v-managment-history {
    @include respond-below(sm) {
        margin-bottom: 0px;
    }
    .select-component {
        .select-label {
            color: $blue-dark;
            margin-right: 15px;
        }
        .multiselect {
            @include respond-below(sm) {
                margin-top: 15px;
            }
        }
    }
}
.datepicker-container {
    .letraform-new {
        @include line-height(1);
    }
}
</style>
