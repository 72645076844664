function loadFont(pdf, fontName, fontType = "normal") {
    let fontFile = require(`../../../../assets/fonts/${fontName}.ttf`);
    let fontBase64 = fontFile.replace("data:font/ttf;base64,", "");

    pdf.addFileToVFS(`${fontName}.ttf`, fontBase64);
    pdf.addFont(`${fontName}.ttf`, fontName, fontType);
    pdf.setFont(fontName);
}

export { loadFont };
