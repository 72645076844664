import qs from "qs";
import store from "@/store/store";
import { ADD_FAVORITE_CONTRACT, REMOVE_FAVORITE_CONTRACT, SET_REST_ERROR } from "@/store/mutation-types";
import * as mutationTypes from "@/store/mutation-types";
import BaseOfexServiceClient from "./BaseOfexServiceClient";
import moment from "moment";

const CONTRACTS_BASE_URL = process.env.VUE_APP_API_CONTRACTS_BASE_URL;

export default {
    getUserContracts: async function (
        clientId,
        userId,
        assignationStatus,
        lang = "es",
        favorite,
        telerecording,
        municipality,
        streetName,
        alias,
        contractNumber,
        status
    ) {
        try {
            console.log("Obteniendo contratos del cliente");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${CONTRACTS_BASE_URL}/contracts`, {
                params: {
                    clientId,
                    userId,
                    assignationStatus: assignationStatus,
                    lang,
                    telerecording,
                    municipality,
                    streetName,
                    alias,
                    contractNumber,
                    status,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
                },
            });
            if (response.status === 200) {
                let contractsResult = response.data;
                if (favorite === true) {
                    console.log("Filtrando contratos favoritos");
                    contractsResult.data = contractsResult.data.filter((value) => store.getters.isFavoriteContract(value.contractDetail.contractNumber));
                }
                return contractsResult;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    getContractDetail: async function (clientId, userId, contractNumber, lang = "es") {
        try {
            console.log("Obteniendo detalles del contrato");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${CONTRACTS_BASE_URL}/contracts/${contractNumber}`, {
                params: {
                    clientId,
                    userId,
                    lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response.status === 200) {
                return response;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    deleteContract: async function (clientId, userId, contractNumber, userData, formModel, pdf, activity, lang = "es") {
        console.log("Obteniendo detalles del contrato");
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.patch(
            `${CONTRACTS_BASE_URL}/contracts/${contractNumber}`,
            {
                pdf: pdf,
                activity: activity,
                date: moment(formModel.datepicker, "MMMM Do YYYY, h:mm:ss a").format("L"),
                water: formModel.readValue,
                sameClient: formModel.bankDetailsDomiciliation,
                sameSupplyAddress: formModel.sameDirection,
                paymentData: {
                    firstName: formModel.headlineBankAccount.name,
                    firstLastName: formModel.headlineBankAccount.lastName,
                    secondLastName: formModel.headlineBankAccount.secondLastName,
                    documentType: formModel.headlineBankAccount.doc.type,
                    documentNumber: formModel.headlineBankAccount.doc.number,
                    mainPhone: userData.mainPhone,
                    email: userData.email,
                    country: formModel.dataBank.country,
                    iban: formModel.dataBank.iban,
                },
                address: {
                    province: formModel.shippingAddress.carrerer.province.name,
                    municipality: formModel.shippingAddress.carrerer.municipality.name,
                    streetType: formModel.shippingAddress.carrerer.streetType.code,
                    streetName: formModel.shippingAddress.carrerer.streetName,
                    number: formModel.shippingAddress.carrerer.number,
                    postalCode: formModel.shippingAddress.carrerer.cp,
                    floor: formModel.shippingAddress.carrerer.piso,
                    door: formModel.shippingAddress.carrerer.puerta,
                    staircase: formModel.shippingAddress.carrerer.escalera,
                    otherAddressData: formModel.shippingAddress.carrerer.otherAddressData,
                },
            },
            {
                params: {
                    clientId,
                    userId,
                    lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            return response;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
    updateAlias: async function (clientId, userId, contractNumber, alias, lang = "es") {
        try {
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.put(
                `${CONTRACTS_BASE_URL}/contracts/${contractNumber}/alias`,
                {
                    alias: alias,
                },
                {
                    params: {
                        clientId,
                        userId,
                        lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    updateFavorite: async function (clientId, userId, contractNumber, favorite, userData, lang = "es") {
        try {
            console.log("Configurando contrato como favorito: " + favorite);
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.put(
                `${CONTRACTS_BASE_URL}/contracts/${contractNumber}/favorite`,
                {
                    favorite: favorite,
                    mainPhone: userData.mainPhone,
                    documentType: userData.documentType,
                    documentNumber: userData.documentNumber,
                    email: userData.email,
                    language: userData.language,
                    favoriteContracts: userData.favoriteContracts,
                },
                {
                    params: {
                        clientId,
                        userId,
                        lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                //añadimos o borramos el contrato favorito de la lista local
                store.commit(favorite ? ADD_FAVORITE_CONTRACT : REMOVE_FAVORITE_CONTRACT, { contractNumber: contractNumber });
            } else {
                //si la respuesta no ha sido 200 lanzamos un error
                throw response.statusText;
            }
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    updateMailAddress: async function (clientId, userId, contractNumber, formModel, userData, pdf, activity, lang = "es") {
        try {
            console.log("Modificación dirección de correspondencia: " + contractNumber);
            let abRestClient = new BaseOfexServiceClient(false).abRestClient;
            let response = await abRestClient.put(
                `${CONTRACTS_BASE_URL}/contracts/${contractNumber}/mailAddress`,
                {
                    pdf: pdf,
                    email: userData.email,
                    musa: formModel.supplyAddress.musaRadio,
                    sameSupplyAddress: formModel.sameSupplyAddress,
                    activity: activity,
                    address: {
                        province: formModel.supplyAddress.carrerer.province.name,
                        municipality: formModel.supplyAddress.carrerer.municipality.name,
                        streetType: formModel.supplyAddress.carrerer.streetType.code,
                        streetName: formModel.supplyAddress.carrerer.streetName,
                        number: formModel.supplyAddress.carrerer.number,
                        postalCode: formModel.supplyAddress.carrerer.cp,
                        otherAddressData: formModel.supplyAddress.otherAddressData,
                        floor: formModel.supplyAddress.carrerer.piso,
                        door: formModel.supplyAddress.carrerer.puerta,
                        staircase: formModel.supplyAddress.carrerer.escalera,
                    },
                },
                {
                    params: {
                        clientId,
                        userId,
                        lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                return response.statusText;
            }
            throw response.statusText;
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    updateIAE: async function (clientId, userId, contractNumber, formModel, userData, pdf, activity, lang = "es") {
        try {
            console.log("Modificación dirección de datos IAE: " + contractNumber);
            let abRestClient = new BaseOfexServiceClient(false).abRestClient;
            let response = await abRestClient.put(
                `${CONTRACTS_BASE_URL}/contracts/${contractNumber}/iae`,
                {
                    pdf: pdf,
                    activity: activity,
                    email: userData.email,
                    iae: {
                        type: formModel.comercial.iae.type,
                        number: formModel.comercial.iae.code,
                    },
                },
                {
                    params: {
                        clientId,
                        userId,
                        lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                return response.statusText;
            }
            throw response.statusText;
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    updateCE: async function (clientId, userId, contractNumber, formModel, userData, pdf, activity, lang = "es") {
        try {
            console.log("Modificación de cédula de habitalidad: " + contractNumber);
            let abRestClient = new BaseOfexServiceClient(false).abRestClient;
            let response = await abRestClient.put(
                `${CONTRACTS_BASE_URL}/contracts/${contractNumber}/ce`,
                {
                    pdf: pdf,
                    activity: activity,
                    email: userData.email,
                    certificate: formModel.certificateOfOccupancy,
                    date: moment(formModel.dateCertificate, "MMMM Do YYYY, h:mm:ss a").format("L"),
                    expire: formModel.expirationCertificate,
                },
                {
                    params: {
                        clientId,
                        userId,
                        lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                return response.statusText;
            }
            throw response.statusText;
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    updateContractsAssignations: async function (clientId, userId, assignedList, unassignedList, lang = "es") {
        console.log("Modificando contratos asignados");
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;

        let assignedListFormatted = assignedList.map(function (contractNumber) {
            return { contractNumber: contractNumber, assignationStatus: "ASSIGNED" };
        });

        let unassignedListFormatted = unassignedList.map(function (contractNumber) {
            return { contractNumber: contractNumber, assignationStatus: "NOT_ASSIGNED" };
        });

        let assignationMap = [...assignedListFormatted, ...unassignedListFormatted];
        let response = await abRestClient.put(
            `${CONTRACTS_BASE_URL}/contracts/assignations`,
            {
                contractsAssignations: assignationMap,
            },
            {
                headers: {
                    "Accept-Language": lang,
                },
                params: {
                    clientId,
                    userId,
                    lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            return response.statusText;
        }
        throw response.statusText;
    },
};
