import { render, staticRenderFns } from "./AbIdleSession.vue?vue&type=template&id=70713a2a&scoped=true"
import script from "./AbIdleSession.vue?vue&type=script&lang=js"
export * from "./AbIdleSession.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70713a2a",
  null
  
)

/* custom blocks */
import block0 from "../../components/common/i18n-components.i18n?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Farnaldo%2FProyectosOficiales%2FZener%2FAguasDeBarcelona%2Fprojects%2Fab-ofex-components%2Fsrc%2Fmodules%2Fcommon%2FAbIdleSession.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports