<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <div class="row">
            <div class="col-12">
                <ab-input
                    v-model="modelValue"
                    :name="name"
                    :label="label"
                    :input-alias="inputAlias"
                    :placeholder="placeholder"
                    :validation="{
                        alpha_num: true,
                        required: isRequired,
                        max: 32,
                        min: 15,
                        iban: true,
                        remoteiban: false,
                        ibanCountry: { country: externalCountry },
                    }"
                >
                </ab-input>
                <!--                    <masked-input
                        :id="name"
                        v-model="modelValue"
                        class="ab-form-input pl-2 inputIban upper-input"
                        :name="name"
                        :class="errors"
                        :mask="[
                            /([A-Z]|[a-z])/,
                            /([A-Z]|[a-z])/,
                            /\d/,
                            /\d/,
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                            ' ',
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                        ]"
                        :guide="true"
                        :placeholder="placeholder"
                    >
                    </masked-input>-->

                <ab-popover :popover="popover" />
            </div>
        </div>
    </div>
</template>

<script>
// import MaskedInput from "vue-text-mask";
import IBAN from "iban";
import AbPopover from "./AbPopover.vue";

export default {
    components: {
        // MaskedInput,
        AbPopover,
    },
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: String,
        inputAlias: String,
        popover: String,
        label: String,
        placeholder: String,
        isRequired: {
            default: true,
            type: Boolean,
        },
        externalCountry: {
            type: String,
            default: "es",
        },
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["inputValue"];
            },
            set: function (newValue) {
                console.debug("Changing IBAN value");
                this.$emit("change", IBAN.electronicFormat(newValue.toUpperCase()));
            },
        },
        country: function () {
            return this.modelValue.length >= 2 ? this.modelValue.substring(0, 2).toLowerCase() : null;
        },
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
.upper-input {
    text-transform: uppercase;
}

.inputIban[type="number"] {
    -moz-appearance: textfield;
}

.inputIban[type="number"]::-webkit-inner-spin-button,
.inputIban[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.inputIban {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #979797;
    height: 32px;
    @include respond-below(sm) {
        height: 40px;
    }
}
</style>
