<template>
    <b-container>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.introduceDatosIAE.title')" />
                <ab-return />
                <!-- detalle del monocontrato -->
                <ab-detail-mono-contract :selected-contract="this.$route.params.contractId"></ab-detail-mono-contract>

                <div class="iae-data">
                    <ValidationObserver ref="iaeForm" v-slot="{ invalid }">
                        <p class="normal-detail mt-4">{{ $t("ofex.modificarDatosContrato.camposObligatorios") }}</p>
                        <ab-comercial-section-forms
                            :validation="{ required: true }"
                            class="mb-5"
                            :select-data="selectData"
                            :comercial="formModel.comercial"
                            :activity-id="activityId"
                            :user-id="userData.documentNumber"
                            :client-id="clientData.documentNumber"
                            :contract="selectedContract"
                        />
                        <div class="line-bottom mb-3"></div>
                        <div class="w-100 d-flex justify-content-end align-items-center mb-space">
                            <div class="btn-send">
                                <ab-button-ofex
                                    :text-button="$t('ofex.modificarDatosContrato.enviar')"
                                    :click-event="modify"
                                    :disabled="
                                        invalid
                                            ? true
                                            : formModel.comercial.certificadoNoActividad === true && formModel.comercial.attachedFile.file.name === ''
                                    "
                                    :show-popover="invalid"
                                    :popover="$t('formularios.buttom.sendMessage')"
                                ></ab-button-ofex>
                            </div>
                        </div>
                        <ab-text-end name="textos-finales"></ab-text-end>
                    </ValidationObserver>
                </div>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import AbComercialSectionForms from "../components/AbComercialSectionForms.vue";
import * as actionTypes from "@/store/action-types";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import IAEModifyPdfGenerator from "@/generators/pdf/IAEModifyPdfGenerator";
import AbDetailMonoContract from "../modules/contracts/modification/AbDetailMonoContract.vue";
import ContentManagerServiceClient from "@/rest/contentManagerServiceClient";
import AbReturn from "../components/AbReturn.vue";
export default {
    name: "InsertIaeData",
    components: {
        AbComercialSectionForms,
        AbDetailMonoContract,
        AbReturn,
    },
    data: function () {
        return {
            catalogueBaseUrl: process.env.VUE_APP_API_CATALOGUE_BASE_URL,
            selectedContract: "",
            activityId: "",
            selectData: {
                otrosMotivos: [
                    { key: "otrosMotivos1", text: this.$i18n.tc("comercialSectionForms.selectData.otrosMotivos1") },
                    { key: "otrosMotivos2", text: this.$i18n.tc("comercialSectionForms.selectData.otrosMotivos2") },
                    { key: "otrosMotivos3", text: this.$i18n.tc("comercialSectionForms.selectData.otrosMotivos3") },
                    { key: "otrosMotivos4", text: this.$i18n.tc("comercialSectionForms.selectData.otrosMotivos4") },
                    { key: "otrosMotivos5", text: this.$i18n.tc("comercialSectionForms.selectData.otrosMotivos5") },
                    { key: "otrosMotivos6", text: this.$i18n.tc("comercialSectionForms.selectData.otrosMotivos6") },
                    { key: "otrosMotivos7", text: this.$i18n.tc("comercialSectionForms.selectData.otrosMotivos7") },
                ],
                tipoViviendaSelectData: [
                    { key: "X", text: this.$i18n.tc("selects.tipoVivienda.propiedad") },
                    { key: "L", text: this.$i18n.tc("selects.tipoVivienda.alquiler") },
                ],
                motivo: [
                    { key: "motivo1", text: this.$i18n.tc("comercialSectionForms.selectData.motivo1") },
                    { key: "motivo2", text: this.$i18n.tc("comercialSectionForms.selectData.motivo2") },
                    { key: "motivo3", text: this.$i18n.tc("comercialSectionForms.selectData.motivo3") },
                    { key: "motivo4", text: this.$i18n.tc("comercialSectionForms.selectData.motivo4") },
                ],
            },
            formModel: {
                comercial: {
                    actividadEconomica: {
                        acreditada: "",
                    },
                    attachedFile: {
                        file: { name: "" },
                        ecmProps: { fileId: null, actividadId: null, documentoId: null },
                    },
                    motivo: "",
                    otrosMotivo: "",
                    certificadoNoActividad: "",
                    tipoLocal: "",
                    fechaContrato: null,
                    iae: {
                        type: "",
                        code: "",
                        typeDesc: "",
                        codeDesc: "",
                    },
                },
            },
        };
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "contracts", "contractDetail"]),
        ...mapGetters(["getContractByContractNumber", "multicontract", "isCompany", "clientId", "userId"]),
        contract: function () {
            return this.getContractByContractNumber(this.$route.params.contractId);
        },
    },
    mounted() {
        this.selectedContract = this.$route.params.contractId;
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, triggerChangeActiveContractLoading: false });
        this.$store.dispatch(actionTypes.GET_CONTRACT_DETAIL_ACTION, {
            contractNumber: this.$route.params.contractId,
        });
        this.activityId = "AS" + new Date().getTime();
    },
    methods: {
        modify: function () {
            let validator = this.$refs.iaeForm;
            validator.validate().then(async (result) => {
                if (result) {
                    console.log("Modificación datos IAE:" + this.selectedContract);
                    //this.formModel.iae.typeDesc = this.$refs.iaeSelect.tipoIaeSelectData.filter((value) => value.key === this.formModel.iae.type)[0].text;
                    //this.formModel.iae.codeDesc =
                    //    this.$i18n.locale === "ca" ? this.$refs.iaeSelect.iaeCode.descriptionCA : this.$refs.iaeSelect.iaeCode.descriptionES;
                    let pdfGenerator = new IAEModifyPdfGenerator(this.$i18n, this.userId, this.clientId, this.selectedContract);
                    pdfGenerator.generatePdf(
                        this.selectData,
                        this.formModel,
                        this.contractDetail,
                        this.selectedContract,
                        this.userData,
                        this.clientData,
                        this.isCompany
                    );
                    await pdfGenerator.uploadPdfWithActivity(this.activityId, false);
                    await ContentManagerServiceClient.commitStorageDocumentsByActivity(this.activityId);

                    console.log("PDF ID" + pdfGenerator.uploadedDocumentId);
                    this.$store
                        .dispatch(actionTypes.UPDATE_IAE, {
                            userId: this.userId,
                            clientId: this.clientId,
                            contractNumber: this.selectedContract,
                            formModel: this.formModel,
                            userData: this.userData,
                            pdf: pdfGenerator.uploadedDocumentId,
                            activity: this.activityId,
                        })
                        .then((value) => {
                            this.$notifyFormActionToMatomo(true, "iae_modification");
                            Swal.fire({
                                showCloseButton: true,
                                showCancelButton: true,
                                text: this.$t("formularios.datosIAE.sendMessage"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                                cancelButtonText: this.$t("common.descargarPDF"),
                            }).then(async (result) => {
                                console.debug("Datos Modificados" + value);
                                if (!result.isConfirmed) {
                                    pdfGenerator.downloadPdf();
                                    console.log("PDF ID" + pdfGenerator.uploadedDocumentId);
                                }
                            });
                        })
                        .catch((reason) => {
                            this.$notifyFormActionToMatomo(false, "iae_modification", reason?.response?.data.errorMessage);
                            this.launchUpdateIAE(reason);
                        });
                }
            });
        },
        launchUpdateIAE: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "ContractModificationView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/contract-modification.scss";
</style>
