var render = function render(){var _vm=this,_c=_vm._self._c;return (this.hasAnyActiveContract === true)?_c('b-container',[_c('b-row',[_c('ab-left-nav-bar'),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"9"}},[(_vm.$isWebApp())?_c('app-breadcrumbs'):_vm._e(),_c('ab-main-title',{attrs:{"main-text":_vm.$t('ofex.bajaContrato.title')}}),_c('p',{staticClass:"text-selection"},[_vm._v(_vm._s(_vm.$t("ofex.bajaContrato.contratoBaja")))]),_c('div',{staticClass:"unsuscribe-selector-contract"},[_c('ab-contracts-advance-search',{ref:"contractsAdvanceSearch",attrs:{"onlyActiveContracts":true},model:{value:(_vm.filteredContracts),callback:function ($$v) {_vm.filteredContracts=$$v},expression:"filteredContracts"}}),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.tableRows,"style-class":"vgt-table","pagination-options":{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        perPageDropdown: [10, 20, 30],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: this.$i18n.tc('table.siguiente'),
                        prevLabel: this.$i18n.tc('table.atras'),
                        rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                        ofLabel: 'de',
                        allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                        label: 'selector-de-paginas',
                        name: 'perPageSelect',
                    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'contrato')?_c('div',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedContractNumber),expression:"selectedContractNumber"}],attrs:{"type":"radio","aria-label":"Selección de contrato","title":props.row.contrato,"name":"radio-contract"},domProps:{"value":props.row.contrato,"checked":_vm._q(_vm.selectedContractNumber,props.row.contrato)},on:{"change":function($event){_vm.selectedContractNumber=props.row.contrato}}}),_c('span',{staticClass:"contract ml-2",class:{ pending: props.row.deuda }},[_vm._v(_vm._s(props.row.contrato))]),(props.row.telectura)?_c('img',{staticClass:"icon-contrato",attrs:{"alt":"imagen bola telelectura","src":require("../assets/images/bola-telelectura.png")}}):_vm._e()]):(props.column.field == 'alias')?_c('div',{staticClass:"col-alias-inner",on:{"click":function($event){return _vm.openEditAliasModal(props.row.contrato, props.row.alias)}}},[_c('span',[_vm._v(_vm._s(props.row.alias))])]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,2021018559)})],1),_c('div',{staticClass:"mb-2"},[_c('img',{staticClass:"icon-t",attrs:{"alt":"imagen bola telelectura","src":require("../assets/images/bola-telelectura.png")}}),_c('span',{staticClass:"ml-2 text-telelectura"},[_vm._v(_vm._s(_vm.$t("ofex.home.telelectura")))])]),_c('div',{staticClass:"d-flex flex-row-reverse"},[_c('div',{staticClass:"box-next"},[_c('ab-button-ofex',{attrs:{"click-event":_vm.navigateView,"text-button":_vm.$t('ofex.bajaContrato.siguiente')}})],1)])],1)],1)],1):_c('b-container',[_c('b-row',[_c('ab-left-nav-bar'),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"9"}},[_c('ab-main-title',{attrs:{"main-text":_vm.$t('ofex.bajaContrato.title')}}),_c('ab-without-data',{staticClass:"mt-3",attrs:{"text":_vm.$t('ofex.modificationContractSelector.noActiveContracts')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }