var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"p-contract-selection"},[_c('ab-loading',{attrs:{"opacity":0.85,"full-page":true,"loader-text":_vm.$t('ofex.loadingText.home')},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('b-row',[_c('ab-left-nav-bar'),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"9"}},[(_vm.$isWebApp())?_c('app-breadcrumbs'):_vm._e(),_c('ab-main-title',{attrs:{"main-text":_vm.$t('ofex.contratosSeleccion.title')}}),_c('p',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$t("ofex.contratosSeleccion.subTitle")))]),_c('p',{staticClass:"text-subtitle"},[_vm._v(_vm._s(_vm.$t("ofex.contratosSeleccion.estaHerramienta")))]),_c('ab-contracts-advance-search',{attrs:{"only-assigned-contracts":false},model:{value:(_vm.filteredContracts),callback:function ($$v) {_vm.filteredContracts=$$v},expression:"filteredContracts"}}),_c('vue-good-table',{ref:"assignation-contract-table",attrs:{"columns":_vm.columns,"rows":_vm.tableRows,"rowStyleClass":_vm.getRowStyleClass,"style-class":"vgt-table","select-options":{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true },"pagination-options":{
                    enabled: true,
                    mode: 'records',
                    perPage: 10,
                    perPageDropdown: [10, 20, 30],
                    dropdownAllowAll: false,
                    setCurrentPage: 1,
                    nextLabel: this.$i18n.tc('table.siguiente'),
                    prevLabel: this.$i18n.tc('table.atras'),
                    rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                    ofLabel: 'de',
                    allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                    label: 'selector-de-paginas',
                    name: 'perPageSelect',
                }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'contrato')?_c('div',[_c('label',{staticClass:"contract",class:{ pending: props.row.deuda },attrs:{"for":props.row.contrato}},[_vm._v(_vm._s(props.row.contrato))]),(props.row.telectura)?_c('img',{staticClass:"icon-contrato",attrs:{"alt":"icono contrato","src":require("../assets/images/bola-telelectura.png")}}):_vm._e()]):_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp:20",value:(2),expression:"2",arg:"20"}]},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(props.formattedRow[props.column.field]),expression:"props.formattedRow[props.column.field]",modifiers:{"top-center":true}}]},[_vm._v(_vm._s(props.formattedRow[props.column.field]))])])]}}])},[_c('div',{staticClass:"text-center textdnormal",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t("ofex.tuContrato.emptyResults"))+" ")])]),_c('div',[_c('img',{staticClass:"img-check",attrs:{"id":"check-img","alt":"imagen check","src":require("../assets/images/check-table-select.png")}}),_c('label',{staticClass:"text-small ml-2",attrs:{"for":"check-img"}},[_vm._v(_vm._s(_vm.$t("ofex.contratosSeleccion.contratoAsignado")))])]),_c('div',[_c('img',{staticClass:"icon-contrato separator-left",attrs:{"alt":"icono contrato separador","src":require("../assets/images/bola-telelectura.png")}}),_c('span',{staticClass:"text-small ml-2"},[_vm._v(_vm._s(_vm.$t("ofex.contratosSeleccion.contratosTelelectura")))])]),_c('div',[(_vm.hasAnyInactiveContract === true)?_c('div',{staticClass:"text-inactive-contract"},[_c('p',{staticClass:"text-small ml-2"},[_vm._v(_vm._s(_vm.$t("ofex.home.inactivos")))])]):_vm._e()]),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"box-button-save float-right pt-2"},[_c('ab-button-ofex',{attrs:{"text-button":_vm.$t('common.saveButton'),"click-event":_vm.assignContractSelected}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }