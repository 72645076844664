<template>
    <b-container>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.modificarDatosTitular.title')" />
                <p class="subtitle-collapse">
                    {{ $t("ofex.modificarDatosContrato.subtituloTitular") }}
                    <router-link :to="{ name: viewMappingTypes.USER_VIEW_DATA_MAPPING.name }"
                        ><span class="router-link">{{ $t("ofex.modificarDatosContrato.modificarDatosUsuario") }}</span></router-link
                    >
                </p>
                <p class="normal-detail mt-4">{{ $t("ofex.modificarDatosContrato.camposObligatorios") }}</p>
                <ValidationObserver ref="ownerForm" v-slot="{ reset }">
                    <div class="row mt-3 mb-5">
                        <div class="col-12 col-lg-2">
                            <ab-input
                                v-model="localClientData.documentNumber"
                                name="cif-owner"
                                v-bind:validation="{ required: false, max: 50 }"
                                :readonly="true"
                                v-bind:label="$t('ofex.modificarDatosContrato.cif')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <ab-input
                                autocomplete="name"
                                v-model="localClientData.name"
                                name="cif-owner"
                                v-bind:validation="{ required: false, max: 50 }"
                                :readonly="true"
                                v-bind:label="$t('ofex.modificarDatosContrato.nombreEmpresa')"
                            ></ab-input>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-4">
                            <ab-input
                                autocomplete="email"
                                v-model="localClientData.email"
                                name="email-owner"
                                v-bind:label="$t('ofex.modificarDatosContrato.email')"
                                v-bind:validation-delay="200"
                                v-bind:validation="{
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                }"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-4">
                            <ab-input
                                autocomplete="email"
                                v-model="emailRepeat"
                                name="reEmail-owner"
                                data-key="key"
                                data-label="text"
                                v-bind:validation-delay="200"
                                v-bind:validation="{
                                    is: localClientData.email,
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                }"
                                v-bind:label="$t('ofex.modificarDatosContrato.reemail')"
                            ></ab-input>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="tel-national"
                                v-model="localClientData.mainPhone"
                                name="main-phone-owner"
                                v-bind:validation="{ required: true, spanishPhone: true }"
                                v-bind:label="$t('ofex.modificarDatosContrato.telefono')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="tel-national"
                                v-model="localClientData.phone"
                                name="mobile-phone-owner"
                                v-bind:validation="{ required: false, spanishPhone: true }"
                                v-bind:label="$t('ofex.modificarDatosContrato.telefonoMovil')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-select
                                v-model="localClientData.language"
                                name="language"
                                :placeholder="'Seleccionar'"
                                v-bind:validation="{ required: true }"
                                v-bind:data-list="languageSelectData"
                                v-bind:label="$t('ofex.detalleContrato.idioma')"
                            >
                            </ab-select>
                        </div>
                    </div>
                    <!-- ab-radio -->
                    <ab-radio
                        v-model="localClientData.advertising"
                        name="promotional-communications"
                        v-bind:title="$t('ofex.modificarDatosContrato.radio.comunicacionesPromocionales')"
                        v-bind:validation="{ required: true }"
                        :commercial-terms="true"
                        class="mb-2"
                        v-bind:description="$t('ofex.modificarDatosContrato.radio.textComunicacionesPromocionales')"
                    ></ab-radio>
                    <ab-radio
                        v-model="localClientData.surveys"
                        name="polls-aguas"
                        v-bind:title="$t('ofex.modificarDatosContrato.radio.encuestasAguas')"
                        v-bind:validation="{ required: true }"
                        :commercial-terms="true"
                        class="mb-2"
                        v-bind:description="$t('ofex.modificarDatosContrato.radio.textEncuestasAguas')"
                    ></ab-radio>
                    <ab-radio
                        v-model="localClientData.profiling"
                        name="profiling"
                        v-bind:title="$t('ofex.modificarDatosContrato.radio.perfilado')"
                        v-bind:validation="{ required: true }"
                        :commercial-terms="true"
                        class="mb-3"
                        v-bind:description="$t('ofex.modificarDatosContrato.radio.textPerfilado')"
                    ></ab-radio>
                    <div class="line-bottom mb-3"></div>
                    <!-- botones enviar FORM-->
                    <div class="w-100 d-flex justify-content-end align-items-center mb-space">
                        <a class="btn-cancel">{{ $t("ofex.bajaContratoDetalle.cancelar") }}</a>
                        <div class="btn-send">
                            <ab-button-ofex :text-button="$t('ofex.modificarDatosContrato.enviar')" :click-event="modify"></ab-button-ofex>
                        </div>
                    </div>
                </ValidationObserver>
                <ab-text-end name="textos-finales"></ab-text-end>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import * as viewMappingTypes from "../router/view-mapping-types";
import { mapGetters, mapState } from "vuex";
import * as actionTypes from "../store/action-types";
import CustomerModifyPdfGenerator from "../generators/pdf/CustomerModifyPdfGenerator";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import Swal from "sweetalert2";
export default {
    name: "ModificationOwnerData",
    data() {
        return {
            localClientData: Object.assign({}, this.clientData),
            emailRepeat: null,
            viewMappingTypes: viewMappingTypes,
        };
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "contracts", "selectedContractNumber"]),
        ...mapGetters(["isCompany", "getInvoiceByInvoiceNumber", "multicontract", "clientId", "userId"]),
        languageSelectData: function () {
            return [
                { key: "es", text: this.$t("ofex.detalleContrato.espanol") },
                { key: "ca", text: this.$t("ofex.detalleContrato.catalan") },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        //aqui recibira la prop del numero de contrato que seleccionen de la tabla
        this.localClientData = Object.assign({}, this.clientData);
    },
    watch: {},
    methods: {
        modify: function () {
            let validator = this.$refs.ownerForm;
            validator.validate().then(async (result) => {
                if (result) {
                    let pdfGenerator = new CustomerModifyPdfGenerator(this.$i18n, this.userId, this.clientId, "");
                    let pdf = pdfGenerator.generatePdf(this.localClientData);
                    await pdfGenerator.uploadPdf();
                    console.debug(pdf);
                    this.$store
                        .dispatch(actionTypes.UPDATE_CLIENT, {
                            clientId: this.clientId,
                            userId: this.userId,
                            localClientData: this.localClientData,
                            pdf: pdfGenerator.uploadedDocumentId,
                        })
                        .then((value) => {
                            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "ModificationOwnerData" });
                            Swal.fire({
                                text: this.$t("formularios.modificarTitular.sendMessage"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                            }).then(() => {
                                console.debug("Terminando de actualizar Cliente" + value);
                                this.$store
                                    .dispatch(actionTypes.GET_PROFILE_ACTION, {
                                        userId: this.userId,
                                        clientId: this.clientId,
                                    })
                                    .then((value) => {
                                        console.debug("Actualizamos datos en caché" + value);
                                    });
                            });
                        })
                        .catch((reason) => this.launchUpdateUser(reason));
                }
            });
        },
        launchUpdateUser: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "ContractModificationView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/contract-modification.scss";
</style>
