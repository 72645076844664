<template>
    <div>
        <div
            class="biometrics-registered-block flex-column align-items-center"
            :class="$router.currentRoute.meta.biometricsRegistered ? 'd-flex' : ''"
            v-show="$router.currentRoute.meta.biometricsRegistered"
        >
            <div class="biometrics-registered-inner d-flex flex-column align-items-center">
                <img class="biometric-icon big" alt="icono acceso biométrico" :src="authImageSrc" />
                <p>{{ $t("ofex.loginOfex.touchIdRegisteredTitle") }}</p>
                <span class="text-center">{{ $t("ofex.loginOfex.touchIdRegisteredMsg") }}</span>
            </div>
            <router-link to="/inicio">
                {{ $t("ofex.loginOfex.touchIdRegisteredBtn") }}
            </router-link>
        </div>
        <div :class="{ native_login: $isNativeApp() }" v-show="!$router.currentRoute.meta.biometricsRegistered" @keyup.enter="enterEventHandler">
            <div id="divLogin" v-show="!showContingenciasLogin">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.login')"></ab-loading>
                <div class="container">
                    <ab-dnie-login-modal ref="dnieModal" v-show="showDniModal"> </ab-dnie-login-modal>

                    <div class="row" v-show="$isWebApp()">
                        <div class="col">
                            <h1 class="title-login mt-2 mb-5">{{ $t("ofex.loginOfex.title") }}</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6 native-option">
                            <div class="biometric-register-block" v-show="$router.currentRoute.meta?.biometricsLogin">
                                <div class="biometrics-registered-inner">
                                    <div v-if="hasFingerprint" to="/login-touchid" class="d-flex flex-column align-items-center href-password">
                                        <img class="biometric-icon big" alt="icono acceso biométrico" :src="authImageSrc" />
                                        <span>{{ $t("ofex.loginOfex.touchIdRegisterTitle") }}</span>
                                    </div>
                                    <div v-if="hasFaceId" to="/login-faceid" class="mt-4 d-flex flex-column align-items-center href-password">
                                        <img class="biometric-icon big" alt="icono acceso biométrico" :src="authImageSrc" />
                                        <span>{{ $t("ofex.loginOfex.touchIdRegisterTitle") }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4-desktop" :class="{ hidden: $router.currentRoute.meta?.biometricsLogin, login_type_native: $isNativeApp() }">
                                <div class="col">
                                    <div
                                        :class="elementSelected == 'particulares' ? 'active-nav-login' : 'disabled-login'"
                                        @click="selectElement('particulares')"
                                        class="div-box-login particular text-center pt-3 pt-lg-1 pointer"
                                    >
                                        {{ $t("ofex.loginOfex.particulares") }}
                                    </div>
                                    <div
                                        :class="elementSelected == 'empresas' ? 'active-nav-login' : 'disabled-login'"
                                        @click="selectElement('empresas')"
                                        class="div-box-login business text-center pt-3 pt-lg-1 pointer"
                                    >
                                        {{ $t("ofex.loginOfex.empresas") }}
                                    </div>
                                    <div class="div-box-login hide-box d-none d-lg-block"></div>
                                    <!----------------------------------------
                            ------------- PARTICULARES ---------------
                            ------------------------------------------>
                                </div>
                            </div>
                            <div
                                class="border-blue-respon"
                                :class="{ biometrics: $router.currentRoute.meta?.biometricsLogin, native: $isNativeApp() }"
                                v-show="elementSelected == 'particulares'"
                            >
                                <ValidationObserver ref="individualLoginForm" v-slot="{ reset }">
                                    <div class="row">
                                        <div class="col mt-2">
                                            <ab-box-error-login v-bind:error-list="loginError"></ab-box-error-login>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-md-6 mt-lg-0">
                                            <ab-input
                                                autocomplete="username"
                                                name="individual-user-id"
                                                v-model="individualUserId"
                                                :input-alias="$t('ofex.loginOfex.label.nif')"
                                                :placeholder="$t('ofex.loginOfex.label.nif')"
                                                :is-login="true"
                                                :validation-delay="1000"
                                                :validation="{ required: true, spanishdocument: { allowNifNie: true, allowCif: false } }"
                                                :focus-in-event="clearErrorMsg"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-md-6 mt-lg-0">
                                            <ab-input
                                                autocomplete="current-password"
                                                name="individual-password"
                                                v-model="password"
                                                :input-alias="$t('ofex.loginOfex.label.contrasena')"
                                                :placeholder="$t('ofex.loginOfex.label.contrasena')"
                                                :is-login="true"
                                                :css-class-list="['modify-password']"
                                                :type="typeInputPassword"
                                                v-bind:validation="{ required: true }"
                                                :right-icon-src="rightIconSrc"
                                                @right-button="toggleShowPassword"
                                                :focus-in-event="clearErrorMsg"
                                            ></ab-input>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-6"></div>
                                        <div class="col-12 col-lg-6">
                                            <router-link :to="`/recuperar-tu-contrasena/${(isCompany = 'particular')}`" class="href-password">
                                                {{ $t("ofex.loginOfex.olvidoContrasena") }}
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="row mt-25">
                                        <div class="col">
                                            <div class="box-button-login">
                                                <vue-recaptcha
                                                    ref="recaptchaComponentParticular"
                                                    :sitekey="recaptchaSiteCode"
                                                    :load-recaptcha-script="true"
                                                    @verify="verifyCaptcha"
                                                    @expired="resetCaptchaParticular"
                                                    :class="{ native: $isNativeApp() }"
                                                >
                                                    <ab-button-ofex
                                                        type="submit"
                                                        :text-button="
                                                            $router.currentRoute.meta?.biometricsLogin
                                                                ? $t('ofex.loginOfex.botonRegister')
                                                                : $t('ofex.loginOfex.botonLogin')
                                                        "
                                                    ></ab-button-ofex>
                                                </vue-recaptcha>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-show="$isNativeApp() && !$router.currentRoute.meta?.biometricsLogin">
                                        <div class="col text-center mb-lg-0">
                                            <template v-if="hasCredentials">
                                                <a v-if="hasFingerprint" href="#" @click="handleExistingCredentialsClick" class="href-password href-biometrics">
                                                    <img class="biometric-icon mr-2" alt="icono acceso biométrico" :src="authImageSrc" />
                                                    {{ biometricLoginText }}
                                                </a>
                                                <a v-if="hasFaceId" href="#" @click="handleExistingCredentialsClick" class="href-password href-biometrics">
                                                    <img class="biometric-icon mr-2" alt="icono acceso biométrico" :src="authImageSrc" />
                                                    {{ biometricLoginText }}
                                                </a>
                                            </template>
                                            <template v-else>
                                                <router-link v-if="hasFingerprint" to="/login-touchid" class="href-password href-biometrics">
                                                    <img class="biometric-icon mr-2" alt="icono acceso biométrico" :src="authImageSrc" />
                                                    {{ biometricLoginText }}
                                                </router-link>
                                                <router-link v-if="hasFaceId" to="/login-faceid" class="href-password href-biometrics">
                                                    <img class="biometric-icon mr-2" alt="icono acceso biométrico" :src="authImageSrc" />
                                                    {{ biometricLoginText }}
                                                </router-link>
                                            </template>
                                        </div>
                                    </div>

                                    <div class="row mt-40" v-show="!$router.currentRoute.meta?.biometricsLogin">
                                        <div class="col text-center">
                                            <a target="_blank" :href="urlUser">
                                                <font-awesome-icon class="icon-signUp" :icon="fontawesomeIcon"></font-awesome-icon>
                                                <span class="ml-1 text-signUp">{{ $t("ofex.loginOfex.registrate") }}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row mt-2" v-show="$isWebApp()" v-if="loginWithCertificateEnabled === 'true'">
                                        <div class="col text-center mb-lg-0">
                                            <a href="#" @click.prevent="dnieModal()">
                                                <font-awesome-icon class="icon-signUp" :icon="fontawesomeIcon"></font-awesome-icon>
                                                <span class="ml-1 text-signUp">{{ $t("ofex.loginOfex.dnie") }}</span>
                                            </a>
                                        </div>
                                    </div>

                                    <!-- TODO no se si es esto lo que falla-->
                                </ValidationObserver>
                            </div>
                            <!----------------------------------------
                    ------------- FIN PARTICULARES -----------
                    ------------------------------------------>

                            <!----------------------------------------
                    ----------------- EMPRESAS ---------------
                    ------------------------------------------>
                            <div
                                class="border-blue-respon"
                                :class="{ biometrics: $router.currentRoute.meta?.biometricsLogin, native: $isNativeApp() }"
                                v-show="elementSelected == 'empresas'"
                            >
                                <ValidationObserver ref="companyLoginForm" v-slot="{ reset }">
                                    <div class="row">
                                        <div class="col mt-2">
                                            <ab-box-error-login v-bind:error-list="loginError"></ab-box-error-login>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-md-6 mt-lg-0">
                                            <ab-input
                                                name="company-client-id"
                                                v-model="companyClientId"
                                                :input-alias="$t('ofex.loginOfex.label.cif')"
                                                :placeholder="$t('ofex.loginOfex.label.cif')"
                                                :is-login="true"
                                                :validation-delay="1000"
                                                :validation="{ required: true, spanishdocument: { allowNifNie: false, allowCif: true } }"
                                                :focus-in-event="clearErrorMsg"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-md-6 mt-lg-0">
                                            <ab-input
                                                name="company-user-id"
                                                v-model="companyUserId"
                                                :input-alias="$t('ofex.loginOfex.label.nifEmpleado')"
                                                :placeholder="$t('ofex.loginOfex.label.nifEmpleado')"
                                                :is-login="true"
                                                :validation-delay="1000"
                                                :validation="{ required: true, spanishdocument: { allowNifNie: true, allowCif: false } }"
                                                :focus-in-event="clearErrorMsg"
                                            ></ab-input>
                                        </div>
                                    </div>
                                    <div class="row mt-4-desktop">
                                        <div class="col-12 col-md-6 mt-lg-0">
                                            <ab-input
                                                autocomplete="current-password"
                                                name="company-password"
                                                v-model="password"
                                                :input-alias="$t('ofex.loginOfex.label.contrasena')"
                                                :placeholder="$t('ofex.loginOfex.label.contrasena')"
                                                :is-login="true"
                                                :css-class-list="['modify-password']"
                                                :validation="{ required: true }"
                                                :type="typeInputPassword"
                                                :right-icon-src="rightIconSrc"
                                                @right-button="toggleShowPassword"
                                                :focus-in-event="clearErrorMsg"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-md-6 forget-password business">
                                            <router-link :to="`/recuperar-tu-contrasena/${(isCompany = 'empresa')}`" class="href-password">
                                                {{ $t("ofex.loginOfex.olvidoContrasena") }}
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col">
                                            <div class="box-button-login business">
                                                <vue-recaptcha
                                                    ref="recaptchaComponentCompany"
                                                    :sitekey="recaptchaSiteCode"
                                                    :load-recaptcha-script="true"
                                                    @verify="verifyCaptcha"
                                                    @expired="resetCaptchaCompany"
                                                >
                                                    <ab-button-ofex
                                                        class="mb-4 mb-lg-0 mt-4 mt-lg-0"
                                                        type="submit"
                                                        :text-button="
                                                            $router.currentRoute.meta?.biometricsLogin
                                                                ? $t('ofex.loginOfex.botonRegister')
                                                                : $t('ofex.loginOfex.botonLogin')
                                                        "
                                                    ></ab-button-ofex>
                                                </vue-recaptcha>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-show="$isNativeApp() && !$router.currentRoute.meta?.biometricsLogin">
                                        <div class="col text-center mb-lg-0">
                                            <template v-if="hasCredentials">
                                                <a v-if="hasFingerprint" href="#" @click="handleExistingCredentialsClick" class="href-password href-biometrics">
                                                    <img class="biometric-icon mr-2" alt="icono acceso biométrico" :src="authImageSrc" />
                                                    {{ biometricLoginText }}
                                                </a>
                                                <a v-if="hasFaceId" href="#" @click="handleExistingCredentialsClick" class="href-password href-biometrics">
                                                    <img class="biometric-icon mr-2" alt="icono acceso biométrico" :src="authImageSrc" />
                                                    {{ biometricLoginText }}
                                                </a>
                                            </template>
                                            <template v-else>
                                                <router-link v-show="hasFingerprint" to="/login-touchid-empresa" class="href-password href-biometrics">
                                                    <img class="biometric-icon" alt="icono acceso biométrico" :src="authImageSrc" />
                                                    {{ biometricLoginText }}
                                                </router-link>
                                                <router-link v-show="hasFaceId" to="/login-faceid" class="href-password href-biometrics">
                                                    <img class="biometric-icon mr-2" alt="icono acceso biométrico" :src="authImageSrc" />
                                                    {{ biometricLoginText }}
                                                </router-link>
                                            </template>
                                        </div>
                                    </div>

                                    <div class="row mt-40" v-show="!$router.currentRoute.meta?.biometricsLogin">
                                        <div class="col text-center">
                                            <a target="_blank" :href="urlUser">
                                                <font-awesome-icon class="icon-signUp" :icon="fontawesomeIcon"></font-awesome-icon>
                                                <span class="ml-1 text-signUp">{{ $t("ofex.loginOfex.registrate") }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </ValidationObserver>
                            </div>
                            <!----------------------------------------
                    ---------------- FIN EMPRESAS ------------
                    ------------------------------------------>
                        </div>
                        <div class="d-none d-lg-block col-6 text-center native-hide">
                            <img alt="Mujer escribiendo en teclado" src="../assets/images/login-woman.png" class="img-family" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6"></div>
                        <div class="col-6"></div>
                    </div>
                </div>
            </div>
            <ab-contingencias v-show="showContingenciasLogin" />
            <div class="native-footer">
                <mq-layout class="native-footer-inner" :mq="footerNativo">
                    <AbHeaderLanguage class="native-language" name="language-container" />
                    <div>
                        <img class="logo" alt="logo aigües de barcelona" src="../assets/images/logo1.svg" />
                    </div>
                    <mq-layout class="native-app-info" :mq="infoNativo">
                        <router-link to="/native_app_info" class="special-link">
                            <span>i</span>
                        </router-link>
                    </mq-layout>
                </mq-layout>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import * as actionTypes from "../store/action-types";
import * as mutationTypes from "../store/mutation-types";
import * as viewMappingTypes from "../router/view-mapping-types";
import Swal from "sweetalert2";
import AbDnieLoginModal from "../modules/dnie/AbDnieLoginModal";
import AbHeaderLanguage from "../modules/common/AbHeaderLanguage";
import { toggleLiferayHomeContentVisibility } from "@/plugins/ab-utils";
import { NativeBiometric } from "capacitor-native-biometric";
import { App } from "@capacitor/app";
import monitorServiceClient from "@/rest/monitorServiceClient";
import { Capacitor } from "@capacitor/core";

export default {
    components: { AbDnieLoginModal, AbHeaderLanguage },
    name: "Login",
    model: {
        prop: "passwordValue",
        event: "change",
    },
    props: {
        fontawesomeIcon: {
            default: "arrow-circle-right",
        },
    },
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
            loginWithCertificateEnabled: process.env.VUE_APP_CERTIFICATE_LOGIN_ENABLED,
            showDniModal: false,
            showPassword: false,
            elementSelected: "particulares",
            password: null,
            individualUserId: null,
            companyClientId: null,
            companyUserId: null,
            recaptchaSiteCode: process.env.VUE_APP_RECAPTCHA_SITE_CODE_INVISIBLE_MODE,
            recaptchaResponse: null,
            showContingenciasLogin: false,
            biometryType: false,
            hasCredentials: false,
            credentials: null,
            appRequiredVersion: null,
            isWebApp: this.$isWebApp(),
        };
    },
    computed: {
        ...mapState(["loginError", "isLoading", "contigenciasStatus"]),
        typeInputPassword: function () {
            return this.showPassword ? "text" : "password";
        },
        urlUser: function () {
            if (this.$i18n.locale === "es") {
                return process.env.VUE_APP_SUBSCRIBE_USER_ES_URL;
            } else {
                return process.env.VUE_APP_SUBSCRIBE_USER_CA_URL;
            }
        },
        hasFingerprint: function () {
            return this.biometryType === 1 || this.biometryType === 3 || this.biometryType === 6;
        },
        hasFaceId: function () {
            return this.biometryType === 2;
        },
        authImageSrc: function () {
            switch (this.biometryType) {
                case 1:
                case 3:
                    return require("../assets/images/svg/huella.svg");
                case 2:
                    return require("../assets/images/svg/face-id.svg");
                case 6:
                    return require("../assets/images/svg/multiple-auth.svg");
                default:
                    return undefined;
            }
        },
        rightIconSrc: function () {
            return this.showPassword ? require("../assets/images/svg/eye-slash.svg") : require("../assets/images/svg/eye.svg");
        },
        biometricLoginText: function () {
            switch (this.biometryType) {
                case 1:
                case 3:
                    return this.$i18n.t("ofex.loginOfex.biometric-touch");
                case 2:
                    return this.$i18n.t("ofex.loginOfex.biometric-faceid");
                case 6:
                    return this.$i18n.t("ofex.loginOfex.biometric-multiple");
                default:
                    return undefined;
            }
        },
        footerNativo() {
            let isFooterNative = [];
            if (this.isWebApp) {
                isFooterNative = ["xs", "sm"];
            } else {
                isFooterNative = ["xs", "sm", "md", "lg"];
            }
            return isFooterNative;
        },
        infoNativo() {
            let isInfoNative = [];
            if (this.isWebApp) {
                isInfoNative = [""];
            } else {
                isInfoNative = ["xs", "sm", "md", "lg"];
            }
            return isInfoNative;
        },
    },
    methods: {
        enterEventHandler: function () {
            this.elementSelected === "particulares" ? this.$refs.recaptchaComponentParticular.execute() : this.$refs.recaptchaComponentCompany.execute();
        },
        login: function () {
            console.log("Validando y haciendo login");

            let validator = this.elementSelected === "particulares" ? this.$refs.individualLoginForm : this.$refs.companyLoginForm;
            validator.validate().then((result) => {
                if (result) {
                    if (this.$isNativeApp()) {
                        this.checkExistingCredentials();
                        // this.loginTransaction();
                    } else {
                        this.loginTransaction();
                    }
                }
            });
        },
        checkExistingCredentials: async function () {
            const result = await NativeBiometric.isAvailable();
            if (result && result.isAvailable) {
                // Comprueba si hay credenciales guardadas
                NativeBiometric.getCredentials({
                    server: "www.aiguesdebarcelona.cat",
                })
                    .then((savedCredentials) => {
                        console.log("CREDENTIALS", savedCredentials);
                        if (savedCredentials) {
                            const userIdentificationForm = this.elementSelected === "particulares" ? this.individualUserId : this.companyUserId;
                            if (userIdentificationForm != savedCredentials.username) {
                                Swal.fire({
                                    text: this.$t("app.deleteBiometricText"),
                                    icon: "warning",
                                    cancelButtonText: this.$t("app.deleteBiometricContinue"),
                                    confirmButtonText: this.$t("app.deleteBiometricConfirm"),
                                    allowEscapeKey: false,
                                    allowOutsideClick: false,
                                    showCancelButton: true,
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        NativeBiometric.deleteCredentials({
                                            server: "www.aiguesdebarcelona.cat",
                                        }).then(() => {
                                            console.log("CREDENCIALES BORRADAS");
                                            this.hasCredentials = false;
                                            this.credentials = null;
                                            this.loginTransaction();
                                        });
                                    } else {
                                        this.loginTransaction();
                                    }
                                });
                            } else {
                                this.loginTransaction();
                            }
                        }
                    })
                    .catch((error) => {
                        console.warn("Error en catch de checkExistingCredentials", error, error.code);
                        this.loginTransaction();
                    });
            } else {
                this.loginTransaction();
            }
        },
        dnieModal: function () {
            console.debug("Mostrando modal para DNI Electrónico");
            this.showDniModal = true;
            Swal.fire({
                html: '<div id="dnie-swal-modal"></div>',
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: true,
                allowEscapeKey: false,
                customClass: {
                    popup: "re-size",
                },
                onBeforeOpen: () => {
                    document.getElementById("dnie-swal-modal").appendChild(this.$refs.dnieModal.$el);
                },
            });
        },
        loginTransaction: function () {
            if (this.contigenciasStatus["crm"] && this.contigenciasStatus["azureIdentity"]) {
                this.$store
                    .dispatch(actionTypes.LOGIN_ACTION, {
                        userIdentification: this.elementSelected === "particulares" ? this.individualUserId : this.companyUserId,
                        companyIdentification: this.companyClientId,
                        password: this.password,
                        recaptchaClientResponse: this.recaptchaResponse,
                        isBiometricLogin: this.$router.currentRoute.meta?.biometricsLogin,
                    })
                    .finally(() => {
                        this.elementSelected === "particulares"
                            ? this.$refs.recaptchaComponentParticular.reset()
                            : this.$refs.recaptchaComponentCompany.reset();
                        console.debug("Login done finally");
                    });
            } else {
                this.showContingenciasLogin = true;
            }
        },
        handleAutoLogin: function () {
            if (this.$route.query.autoLogin === "true") {
                console.log("Haciendo login automático");
                this.elementSelected = this.$route.query.loginType; //particulares o empresas
                this.password = this.$route.query.password;
                if (this.elementSelected === "particulares") {
                    this.individualUserId = this.$route.query.userId;
                } else {
                    this.companyUserId = this.$route.query.userId;
                    this.companyClientId = this.$route.query.clientId;
                }
                this.loginTransaction();
            }
        },
        // eslint-disable-next-line no-unused-vars
        toggleShowPassword: function () {
            this.showPassword = !this.showPassword;
        },
        selectElement: function (element) {
            if (element === "particulares") {
                this.companyClientId = null;
                this.companyUserId = null;
                this.password = null;
            } else {
                this.individualUserId = null;
                this.password = null;
            }
            this.$refs.individualLoginForm.reset();
            this.$refs.companyLoginForm.reset();
            //TODO val this.$validator.errors.clear();

            this.elementSelected = element;
        },
        clearErrorMsg: function () {
            this.$store.commit(mutationTypes.CLEAR_LOGIN_ERROR_MSG_MUTATION);
        },
        verifyCaptcha: function (response) {
            console.debug("Verificando captcha. Captcha response: " + response);
            this.recaptchaResponse = response;
            this.login();
        },
        resetCaptchaParticular: function () {
            // eslint-disable-next-line no-undef
            this.$refs.recaptchaComponentParticular.reset(); //resetamos el recaptcha para ser capaces de darle al botón nuevamente
        },
        resetCaptchaCompany: function () {
            // eslint-disable-next-line no-undef
            this.$refs.recaptchaComponentCompany.reset(); //resetamos el recaptcha para ser capaces de darle al botón nuevamente
        },
        performBiometricVerification: async function () {
            // Comprueba si tiene algun método biométrico disponible
            const result = await NativeBiometric.isAvailable();
            // console.log("BIOMETRICS: ", result);
            console.debug("BIOMETRICS ERROR: ", result.errorCode);
            console.debug("BIOMETRICS AVAILABLE: ", result.isAvailable);
            console.debug("BIOMETRICS TYPE: ", result.biometryType);
            if (result && result.isAvailable) {
                this.biometryType = result.biometryType;

                try {
                    // Comprueba si hay credenciales guardadas
                    const credentials = await NativeBiometric.getCredentials({
                        server: "www.aiguesdebarcelona.cat",
                    });
                    if (credentials) {
                        this.hasCredentials = true;
                        this.credentials = credentials;
                    }
                } catch (error) {
                    console.warn("No existen credenciales guardadas aun.");
                }
            }
        },
        handleExistingCredentialsClick: async function () {
            await NativeBiometric.verifyIdentity({
                reason: this.$i18n.t("ofex.loginOfex.biometry-verify.reason"),
                title: this.$i18n.t("ofex.loginOfex.biometry-verify.title"),
                subtitle: this.$i18n.t("ofex.loginOfex.biometry-verify.reason"),
                maxAttempts: 3,
            })
                .then(() => {
                    this.individualUserId = this.credentials.username;
                    this.password = this.credentials.password;
                    this.enterEventHandler();
                })
                .catch((error) => {
                    console.log("ERROR LOGIN", error.code);
                    if (parseInt(error.code) === 16) {
                        console.warn("LOGIN CANCELADO. SE BORRAN CREDENCIALES");
                        NativeBiometric.deleteCredentials({
                            server: "www.aiguesdebarcelona.cat",
                        }).then(() => {
                            console.log("CREDENCIALES BORRADAS");
                            this.hasCredentials = false;
                            this.credentials = null;
                        });
                    }
                });
        },
        checkBiometryType: async function () {
            const result = await NativeBiometric.isAvailable();
            if (result && result.isAvailable) {
                this.biometryType = result.biometryType;
            }
        },
        checkAppRequiredVersions: async function () {
            if (this.$isNativeApp()) {
                console.debug("Verificando versiones instaladas");
                this.appRequiredVersion = await monitorServiceClient.getAppRequiredVersions();
                console.log(this.appRequiredVersion);
                App.getInfo().then((result) => {
                    if (result.build < this.appRequiredVersion[Capacitor.getPlatform()].minBuild) {
                        let params;
                        if (this.$isIos()) {
                            params = {
                                text: this.$i18n.tc("app.requiredUpdate"),
                                icon: "warning",
                                confirmButtonText: this.$i18n.tc("app.storeButton"),
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                showCancelButton: false,
                            };
                        } else {
                            params = {
                                text: this.$i18n.tc("app.requiredUpdate"),
                                icon: "warning",
                                cancelButtonText: this.$i18n.tc("app.exitButton"),
                                confirmButtonText: this.$i18n.tc("app.storeButton"),
                                allowEscapeKey: false,
                                allowOutsideClick: false,
                                showCancelButton: true,
                            };
                        }
                        Swal.fire(params).then((result) => {
                            if (result.isConfirmed) {
                                window.location =
                                    Capacitor.getPlatform() === "android"
                                        ? "https://play.google.com/store/apps/details?id=com.phonegap.agbar"
                                        : "https://apps.apple.com/es/app/aig%C3%BCes-de-barcelona/id916476787";
                            } else {
                                App.exitApp();
                            }
                        });
                    }
                });
            }
        },
    },
    mounted() {
        this.checkAppRequiredVersions();
        if (this.$route.params.logoutReason && this.$route.params.logoutReason === "inactivity") {
            Swal.fire({
                titleText: this.$t("ofex.loginOfex.inactivityReason"),
                icon: "warning",
            });
        } else if (this.$route.params.logoutReason && this.$route.params.logoutReason === "expiration") {
            Swal.fire({
                titleText: this.$t("ofex.loginOfex.expirationReason"),
                icon: "warning",
            });
        }
        this.handleAutoLogin();
    },
    beforeMount() {
        toggleLiferayHomeContentVisibility("block");
        if (this.$isNativeApp()) {
            this.checkBiometryType();
            if (this.$router.currentRoute.meta?.typeSelected == "empresas") {
                this.elementSelected = this.$router.currentRoute.meta?.typeSelected;
            }
            if (!this.$router.currentRoute.meta.biometricsRegistered) {
                this.performBiometricVerification();
            }
        }
    },
    beforeDestroy() {
        //ocultamos el contenido en todas las páginas que no sean el Login
        toggleLiferayHomeContentVisibility("none");
    },
};
</script>

<style lang="scss">
@keyframes animateWave {
    0% {
        transform: scale(1, 0);
    }
    100% {
        transform: scale(1, 1);
    }
}
#divLogin {
    width: 100%;
    height: 485px;
    //background-image: url("../assets/images/svg/bgLogin.svg");
    //background-repeat: no-repeat;
    //background-size: 100%;
    //background-position: bottom;
    //transform-origin: bottom;
    // animation: animateWave 1000ms cubic-bezier(0.23, 1, 0.32, 1);

    @include respond-above(md) {
        margin-top: 45px;
    }
}

.labels-login {
    font-weight: $font-primary-bold;
    color: $blue-dark;
    @include font-size(12);
    @include line-height(25);

    @include respond-below(md) {
        color: $link-primary;
        font-weight: $font-primary-medium;
    }
}
.href-password {
    text-decoration: none;
    color: $bg-primary;
    font-weight: $font-primary-semiBold;
    @include font-size(12);
    @include line-height(14);
}
@include respond-below(md) {
    .href-password {
        color: $color-dark;
    }
}
.pointer {
    cursor: pointer;
}
.icon-signUp {
    color: $bg-primary;
}
//hasta lg
@include respond-below(md) {
    .icon-signUp {
        color: $link-primary;
        vertical-align: middle;
    }
}
.title-login {
    font-weight: $font-primary-extraBold;
    @include font-size(36);
    @include line-height(42);
    color: $bg-primary;
}
//hasta md
@include respond-below(md) {
    .title-login {
        color: $color-dark;
        font-weight: $font-primary-medium;
    }
}
.div-box-login {
    color: #757575;
    border-bottom: 2px solid $bg-primary;
    height: 42px;
    float: left;
    @include font-size(18);
    font-weight: $font-primary-bold;

    @include respond-below(md) {
        color: $color-lightest;
    }
    &.particular {
        width: 28%;
        @include respond-below(md) {
            width: 50%;
        }
    }
    &.business {
        width: 24%;
        @include respond-below(md) {
            width: 50%;
        }
    }
    &.hide-box {
        width: 48%;
    }
}
@include respond-below(md) {
    .div-box-login {
        height: 55px;
        padding-top: 6%;
        border-bottom: 0;
    }
}
.w-24 {
    width: 24%;
}
//hasta md
@include respond-below(md) {
    .w-24 {
        width: 50%;
    }
}
.mt-9 {
    margin-top: 9px;
}
.mt-25 {
    margin-top: 25px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-40 {
    margin-top: 40px;
}
.active-nav-login {
    border-bottom: 0px;
    color: $bg-primary;
    border-top: 2px solid $bg-primary;
    border-right: 2px solid $bg-primary;
    border-left: 2px solid $bg-primary;
}
@include respond-below(md) {
    .active-nav-login {
        color: $color-lightest;
        background-color: $button-bg-primary;
        border-left: 1px solid $bg-primary;
        border-top: 1px solid $bg-primary;
        border-right: 1px solid $bg-primary;
    }
}
.box-button-login {
    width: 219px;
    margin: auto;

    @include respond-below(md) {
        width: 100%;
    }
}
.text-signUp {
    color: $bg-primary;
    font-weight: $font-primary-semiBold;
    @include font-size(14);
    @include line-height(16);
}

.text-dnie {
    color: $blue-dark;
    font-weight: $font-primary-semiBold;
}

.text-dnie:hover {
    color: $bg-primary;
}

@include respond-below(md) {
    .text-signUp {
        color: $blue-lighter;
    }
}
.text-signUp:hover {
    color: $bg-primary;
}
.img-family {
    max-width: 70%;
    height: auto;
}
//Ajustando al 100% input para el login
.inputConInfo {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #979797;
}
.modify-password {
    border-radius: 8px 0 0 8px;
    border: 1px solid #979797;
    font-weight: $font-primary;
}
.formTextErr {
    margin-top: 5px;
    margin-bottom: 0;
    color: $error-color;
    @include font-size(14);
    @include line-height(16);
    font-weight: $font-primary;
}
.letraform-new {
    color: $blue-dark;
    font-weight: $font-primary-bold;
    margin-bottom: 5px;
    @include font-size(12);
    @include line-height(16);
}
.input-right-button {
    height: 40px;
    text-align: center;
    cursor: pointer;
    border-left: 0px;
    width: 63px;
    padding-top: 1px;
    border-right: 1px solid #979797;
    border-top: 1px solid #979797;
    border-bottom: 1px solid #979797;
    border-radius: 0px 8px 8px 0px;
    @include line-height(35);

    @include respond-above(sm) {
        height: 32px;
        @include line-height(26);
    }
    @include respond-below(md) {
        border-right: 0;
        border-top: 0;
        border-radius: 0;
    }
}
input.inputFormErr + .input-right-button {
    border-right: 1px solid $error-color;
    border-top: 1px solid $error-color;
    border-bottom: 1px solid $error-color;
    background-color: #feefef;
}
.textdnormal {
    color: #979797;
    font-weight: $font-primary-semiBold;
    @include font-size(12);
    @include line-height(14);
}
.forget-password {
    margin-top: 5px;

    @include respond-above(md) {
        margin-top: 30px;
    }
    &.business {
        margin-top: 5px;

        @include respond-above(sm) {
            margin-top: 43px;
        }
        @include respond-above(md) {
            margin-top: 0;
        }
        @include respond-above(lg) {
            margin-top: 5px;
        }
        @include respond-below(md) {
            margin-top: 0;
        }
    }
}
.box-button-login.business {
    margin-top: 25px;
}
.mb-4-desktop {
    @include respond-above(md) {
        margin-bottom: 1.5rem;
    }
}
.mt-4-desktop {
    @include respond-above(md) {
        margin-top: 1.5rem;
    }
}

.native .grecaptcha-badge {
    visibility: hidden !important;
}

.biometric-icon {
    width: 60px;
    height: 40px;

    &.big {
        width: 128px;
        height: 61px;
        margin-bottom: 10px;
    }
}

.biometric-register-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
        font-size: 0.85rem;
    }
}

.hidden {
    display: none !important;
}
.biometrics-registered-block {
    img {
        margin-bottom: 40px;
    }
    p {
        font-size: 1.2rem;
        font-weight: 700;
        color: #28506a;
    }
    span {
        font-size: 0.85rem;
        font-weight: 600;
        color: #747474;
        padding: 0 15%;
        margin-bottom: 20px;
    }
    a {
        width: 80%;
        padding: 5px;
        background-color: #28506a;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        font-weight: 600;

        &:hover {
            color: #ccc;
        }
    }
}
.biometrics-registered-inner {
    margin-top: 100px;
}
.href-biometrics {
    color: $blue-darker !important;
    margin-top: 20px;
    display: block;
}

.native_login {
    position: relative;
    margin-top: -20px;

    @supports (-webkit-touch-callout: none) {
        margin-top: 0;
    }
}

//nativo
@include respond-below(md) {
    .native-language {
        &.language-container {
            .link {
                color: $bg-primary;

                &:first-child {
                    border-right: 1px solid $bg-primary;
                }
            }
        }
    }
    #divLogin {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-image: none;

        .title-login {
            display: none;
        }

        .inputConInfo {
            border-radius: 0;
            padding-left: 0;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid $blue-darkest;

            &::placeholder {
                color: $blue-darkest;
                @include font-size(14);
                @include line-height(16);
                font-weight: $font-primary-bold;
            }
        }
        .href-password {
            @include font-size(14);
            @include line-height(16);
            font-weight: $font-primary-semiBold;
        }

        .box-button-login {
            width: 219px;
        }
        .icon-signUp {
            color: $bg-primary;
        }
        .text-signUp {
            color: $blue-darkest;
            @include font-size(14);
            @include line-height(16);
        }
        .div-box-login {
            color: $blue-darkest;
            background: $color-lightest;
            font-weight: $font-primary-bold;
            @include font-size(20);
            @include line-height(23);

            &.active-nav-login {
                border-radius: 10px 10px 0 0;
                border-top: 2px solid $blue-darkest;
                border-right: 2px solid $blue-darkest;
                border-left: 2px solid $blue-darkest;
            }
            &.disabled-login {
                border-bottom: 2px solid $blue-darkest;
            }
        }
        #divLogin {
            height: auto;
            background-image: none;
            margin-top: 20px !important;
        }
        .box-button-login {
            .btn-primary {
                margin: 30px 0 !important;
            }
        }
        .input-right-button {
            border-bottom: 1px solid $bg-primary;
            border-radius: 0;
        }

        .border-blue-respon {
            padding: 4%;

            &.biometrics,
            &.native {
                border: 0 !important;
            }
        }
        input.inputFormErr + .input-right-button {
            border-right: 0 !important;
            border-top: 0 !important;
            border-left: 0 !important;
            border-bottom: 1px solid $error-color;
            border-radius: 0;
            background-color: #ffffff !important;
        }
        input.inputFormErr {
            border-right: 0 !important;
            border-top: 0 !important;
            border-left: 0 !important;
            border-bottom: 1px solid $error-color;
            border-radius: 0;
            background-color: #ffffff !important;
        }
    }
    .native-footer {
        background-color: $color-lightest;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;

        @include respond-below(sm) {
            max-width: 540px;
        }

        div {
            &:nth-child(2) {
                display: flex;
                justify-content: center;
            }

            &.native-app-info {
                display: flex;
                justify-content: flex-end;
                flex: 0 0 33%;
            }

            img {
                width: 100%;
                max-width: 100px;
            }

            a.special-link {
                width: 25px;
                height: 25px;
                background: #284f6a;
                color: #fff;
                text-align: center;
                border-radius: 100%;
                margin-top: 15px;
            }
        }
    }
    .native-footer-inner {
        display: flex;
        justify-content: space-between;
        padding: 0;
        flex: 0 0 100%;
        margin-bottom: 25px;
        margin-top: 15px;
        position: relative;

        div:nth-child(2) {
            position: absolute;
            left: 50%;
            margin-left: -50px;
        }
    }
    .native {
        .native-footer-inner {
            flex: 0 0 63%;
        }
    }
}
.native {
    .native-language {
        &.language-container {
            .link {
                color: $bg-primary;

                &:first-child {
                    border-right: 1px solid $bg-primary;
                }
            }
        }
    }
    #divLogin {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-image: none;

        .title-login {
            display: none;
        }

        .inputConInfo {
            border-radius: 0;
            padding-left: 0;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 1px solid $blue-darkest;

            &::placeholder {
                color: $blue-darkest;
                @include font-size(14);
                @include line-height(16);
                font-weight: $font-primary-bold;
            }
        }
        .href-password {
            @include font-size(14);
            @include line-height(16);
            font-weight: $font-primary-semiBold;
        }

        .box-button-login {
            width: 219px;
        }
        .icon-signUp {
            color: $bg-primary;
        }
        .text-signUp {
            color: $bg-primary;
            @include font-size(14);
            @include line-height(16);
        }
        .div-box-login {
            color: $blue-darkest;
            background: $color-lightest;
            font-weight: $font-primary-bold;
            height: 55px;
            @include font-size(20);
            @include line-height(23);
            padding-top: 1rem !important;

            &.active-nav-login {
                border-radius: 10px 10px 0 0;
                border-top: 2px solid $blue-darkest;
                border-right: 2px solid $blue-darkest;
                border-left: 2px solid $blue-darkest;
            }
            &.disabled-login {
                border-bottom: 2px solid $blue-darkest;
            }
            &.hide-box {
                display: none !important;
            }
            &.particular,
            &.business {
                width: 50%;
            }
        }
        #divLogin {
            height: auto;
            background-image: none;
            margin-top: 20px !important;
        }
        .box-button-login {
            .btn-primary {
                margin: 30px 0 !important;
            }
        }
        .input-right-button {
            border-top: 0;
            border-right: 0;
            border-bottom: 1px solid $blue-darkest;
            border-left: 0;
            border-radius: 0;
        }

        .border-blue-respon {
            padding: 4%;

            &.biometrics,
            &.native {
                border: 0 !important;
            }
        }
        input.inputFormErr + .input-right-button {
            border-right: 0 !important;
            border-top: 0 !important;
            border-left: 0 !important;
            border-bottom: 1px solid $error-color;
            border-radius: 0;
            background-color: #ffffff !important;
        }
        input.inputFormErr {
            border-right: 0 !important;
            border-top: 0 !important;
            border-left: 0 !important;
            border-bottom: 1px solid $error-color;
            border-radius: 0;
            background-color: #ffffff !important;
        }
        .native-hide {
            display: none !important;
        }
        .native-option {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    }
    .native-footer {
        background-color: #ffffff;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;

        @include respond-below(md) {
            max-width: 720px;
            margin: 0 auto;
        }
        @include respond-below(sm) {
            max-width: 540px;
        }

        div {
            img {
                width: 100%;
                max-width: 100px;
            }
            &.native-app-info {
                flex: 0;
            }
            a.special-link {
                display: block;
                width: 25px;
                height: 25px;
                background: #284f6a;
                color: #fff;
                text-align: center;
                border-radius: 100%;
                margin-top: 0;
            }
        }
    }
    .native-footer-inner {
        display: flex;
        justify-content: space-between;
        padding: 0;
        flex: 0 0 100%;
        margin-bottom: 25px;
        margin-top: 15px;

        .language-container {
            margin-right: 0;

            .link {
                @include font-size(14);
            }
        }
    }
}
</style>
