<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container class="p-contract-view">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <ab-edit-alias-modal ref="editAliasModal" />
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.tuContrato.title')"></ab-main-title>

                <p class="subtitle">{{ $t("ofex.tuContrato.subtitle") }}</p>
                <ab-contracts-advance-search v-model="filteredContracts" ref="contractsAdvanceSearch" />
                <vue-good-table
                    :columns="columns"
                    :rows="tableRows"
                    :rowStyleClass="getRowStyleClass"
                    style-class="vgt-table"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        perPageDropdown: [10, 20, 30],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: this.$i18n.tc('table.siguiente'),
                        prevLabel: this.$i18n.tc('table.atras'),
                        rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                        ofLabel: 'de',
                        allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                    }"
                    @on-per-page-change="onPerPageChange"
                >
                    <template #table-row="props">
                        <div v-if="props.column.field === 'contrato'">
                            <span class="contract" :class="{ pending: props.row.deuda }">{{ props.row.contrato }}</span>
                            <img v-if="props.row.telectura" class="icon-contrato" alt="icono contrato" src="../assets/images/bola-telelectura.png" />
                        </div>
                        <div
                            class="col-alias-inner"
                            v-else-if="props.column.field === 'alias' && supplyActiveStatus(props.row.estado)"
                            @click="openEditAliasModal(props.row.contrato, props.row.alias)"
                        >
                            <span>{{ props.row.alias }}</span>
                            <ab-popover fontawesome-icon="pen" v-bind:popover="$t('ofex.tuContrato.popoverEditAlias')" />
                        </div>
                        <div v-else-if="props.column.field === 'deuda'">
                            <router-link to="/tuscontratos/tusfacturas/pagar-facturas" v-if="props.row.deuda">
                                <span class="pending">{{ $t("ofex.tuContrato.pagar") }}</span>
                            </router-link>
                            <span v-else>{{ $t("ofex.tuContrato.sinDeuda") }}</span>
                            <font-awesome-icon v-if="props.row.deuda" class="icon-pay" icon="arrow-right"></font-awesome-icon>
                        </div>
                        <div class="col-envio-inner d-flex align-items-center" v-else-if="props.column.field === 'envio'">
                            <span class="text">{{ props.row.envio }}</span>
                            <div class="buttons-group d-flex align-items-center">
                                <b-dropdown
                                    :id="`dropdow-first-level-${props.row.contrato}`"
                                    dropleft
                                    size="lg"
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    class="table-dropdown"
                                >
                                    <b-dropdown-item v-if="supplyActiveStatus(props.row.estado)" @click.native="launchSecondLevelDropdown(props.row.contrato)">
                                        {{ $t("ofex.tuContrato.dataModify") }}
                                    </b-dropdown-item>
                                    <router-link :to="`/tuscontratos/${props.row.contrato}/tusconsumos`">
                                        <b-dropdown-item-button aria-describedby="desplegable">{{
                                            $t("ofex.tuContrato.consumptionDetail")
                                        }}</b-dropdown-item-button>
                                    </router-link>
                                    <router-link :to="`/tuscontratos/${props.row.contrato}/tusfacturas`">
                                        <b-dropdown-item-button aria-describedby="desplegable">{{
                                            $t("ofex.tuContrato.invoiceDetail")
                                        }}</b-dropdown-item-button>
                                    </router-link>
                                    <b-dropdown-item-button aria-describedby="desplegable" @click="routeToContractDetail(props.row.contrato)">{{
                                        $t("ofex.tuContrato.detail")
                                    }}</b-dropdown-item-button>
                                </b-dropdown>
                                <b-dropdown
                                    :id="`dropdow-second-level-${props.row.contrato}`"
                                    ref="dropdownSecondLevel"
                                    dropleft
                                    variant="link"
                                    no-caret
                                    class="hide-dropdown overlay-avoid-click"
                                >
                                    <router-link :to="`/tuscontratos/modificacion/modificar-datos-pago/${props.row.contrato}`">
                                        <b-dropdown-item-button aria-describedby="desplegable">{{
                                            $t("ofex.menuLateral.modificar.datosPago")
                                        }}</b-dropdown-item-button>
                                    </router-link>
                                    <router-link :to="`/tuscontratos/modificacion/modificar-datos-correspondencia/${props.row.contrato}`">
                                        <b-dropdown-item-button aria-describedby="desplegable">{{
                                            $t("ofex.menuLateral.modificar.datosCorrespondencia")
                                        }}</b-dropdown-item-button>
                                    </router-link>
                                    <router-link :to="`/tuscontratos/modificacion/titular/${props.row.contrato}`" v-if="isCompany">
                                        <b-dropdown-item-button aria-describedby="desplegable">{{
                                            $t("ofex.menuLateral.modificar.datosTitular")
                                        }}</b-dropdown-item-button>
                                    </router-link>
                                    <router-link
                                        :to="`/tuscontratos/modificacion/introduce-cedula-de-habitabilidad/${props.row.contrato}`"
                                        v-if="getContractByContractNumber(props.row.contrato).contractDetail.useTypeCode === 'DOMESTIC'"
                                    >
                                        <b-dropdown-item-button aria-describedby="desplegable">{{
                                            $t("ofex.menuLateral.introduce.cedulaHabitabilidad")
                                        }}</b-dropdown-item-button>
                                    </router-link>
                                    <router-link
                                        :to="`/tuscontratos/modificacion/introduce-datos-del-iae/${props.row.contrato}`"
                                        v-if="getContractByContractNumber(props.row.contrato).contractDetail.useTypeCode !== 'DOMESTIC'"
                                    >
                                        <b-dropdown-item-button aria-describedby="desplegable">{{
                                            $t("ofex.menuLateral.introduce.datosIae")
                                        }}</b-dropdown-item-button>
                                    </router-link>
                                    <router-link :to="`/tuscontratos/${props.row.contrato}/baja_suministro`">
                                        <b-dropdown-item-button aria-describedby="desplegable">{{
                                            $t("ofex.menuLateral.bajaContratoContractDropdown")
                                        }}</b-dropdown-item-button>
                                    </router-link>
                                </b-dropdown>
                                <img
                                    v-if="props.row.favorito && supplyActiveStatus(props.row.estado)"
                                    alt="icono favoritos activado"
                                    class="icon-favoritos"
                                    src="../assets/images/svg/favoritos.svg"
                                    @click="editFavorite(props.row.contrato, false)"
                                />
                                <img
                                    v-else-if="supplyActiveStatus(props.row.estado)"
                                    alt="icono favoritos desactivado"
                                    class="icon-favoritos"
                                    src="../assets/images/svg/favoritos-off.svg"
                                    @click="editFavorite(props.row.contrato, true)"
                                />
                            </div>
                        </div>
                        <div v-line-clamp:20="2" v-else>
                            <span v-tooltip.top-center="props.formattedRow[props.column.field]">{{ props.formattedRow[props.column.field] }}</span>
                        </div>
                    </template>
                    <div slot="emptystate" class="text-center textdnormal">
                        {{ $t("ofex.tuContrato.emptyResults") }}
                    </div>
                </vue-good-table>
                <div class="table-legend">
                    <div class="legend-remote-reading">
                        <img class="icon-contrato" alt="icono contrato" src="../assets/images/bola-telelectura.png" />
                        <span class="text">{{ $t("ofex.tuContrato.legendRemoteReading") }}</span>
                    </div>
                    <div class="legend-favorites">
                        <img alt="icono favoritos" class="icon-favoritos" src="../assets/images/svg/favoritos.svg" />
                        <span class="text">{{ $t("ofex.tuContrato.legendFavorites") }}</span>
                    </div>
                    <div v-if="hasAnyInactiveContract === true" class="text-inactive-contract">
                        <span class="text">{{ $t("ofex.home.inactivos") }}</span>
                    </div>
                </div>
                <div class="table-buttons-container">
                    <ab-button-ofex
                        :text-button="$t('ofex.tuContrato.botonDescargarPdf')"
                        :font-awesome-icon="'download'"
                        :click-event="generatePdfContractList"
                    ></ab-button-ofex>
                    <ab-button-ofex
                        :text-button="$t('ofex.tuContrato.botonDescargarExcel')"
                        :font-awesome-icon="'download'"
                        :click-event="generateExcelContractList"
                    ></ab-button-ofex>
                </div>
                <ab-contract-links :contract="this.oneContractWithTelerecording" :show-telerecording-button="hasAnyTelerecording" v-if="$isWebApp()" />

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import AbContractsAdvanceSearch from "../modules/AbContractsAdvanceSearch";
import { mapGetters, mapState } from "vuex";
import * as actionTypes from "../store/action-types";
import router from "../router/router";
import * as viewMappingTypes from "@/router/view-mapping-types";
import ContractListXlsxGenerator from "@/generators/xlsx/ContractListXlsxGenerator";
import ContractListPdfGenerator from "@/generators/pdf/ContractListPdfGenerator";
import AbEditAliasModal from "@/modules/common/AbEditAliasModal";
import { trimBackendCode } from "@/plugins/ab-utils";

export default {
    name: "ContractsView",
    components: {
        AbEditAliasModal,
        AbContractsAdvanceSearch,
        VueGoodTable,
    },
    data: function () {
        return {
            oneContractWithTelerecording: "",
            filteredContracts: null,
            itemsSP: [],
            itemsFP: [],
            selected: [],
            selectAll: false,
            loading: false,
            tableSelectOptions: {
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: "custom-class",
                selectionText: "Contratos Seleccionados",
                clearSelectionText: this.$t("ofex.tuContrato.limpiar"),
                disableSelectInfo: true, // disable the select info panel on top
            },
        };
    },

    methods: {
        onPerPageChange: function (params) {
            if (params.currentPerPage === 10) {
                this.$nextTick().then(() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                });
            }
        },
        mapContractToTableRow: function (contractEntry) {
            let supplyAddress = this.getContractSupplyCompleteAddress(contractEntry.contractDetail.contractNumber);
            return {
                // yearInvoice: contractEntry.contractDetail.invoiceYear,
                contrato: contractEntry.contractDetail.contractNumber,
                alias: contractEntry.contractDetail.alias ? contractEntry.contractDetail.alias : "",
                suministro: supplyAddress,
                deuda: contractEntry.contractDetail.invoicesPending,
                envio: this.$i18n.tc(`ofex.backendCodes.deliveryType.${contractEntry.contractDetail.invoiceDeliveryType}`),
                telectura: contractEntry.contractDetail.telerecording,
                favorito: contractEntry.contractDetail.favorite,
                estado: this.supplyStatusTranslation(contractEntry.contractDetail.supplyStatus),
            };
        },
        mapContractToExcel: function (contractEntry) {
            let supplyAddress = this.getContractSupplyCompleteAddress(contractEntry.contractDetail.contractNumber);
            return {
                contrato: contractEntry.contractDetail.contractNumber,
                alias: contractEntry.contractDetail.alias ? contractEntry.contractDetail.alias : "",
                suministro: supplyAddress,
                deuda: contractEntry.contractDetail.invoicesPending ? this.$t("ofex.contractsView.conDeuda") : this.$t("ofex.contractsView.sinDeuda"),
                envio: this.$i18n.tc(`ofex.backendCodes.deliveryType.${contractEntry.contractDetail.invoiceDeliveryType}`),
                telectura: contractEntry.contractDetail.telerecording ? "Telelectura" : "",
                favorito: contractEntry.contractDetail.favorite ? this.$t("ofex.contractsView.favoritos") : "",
                estado: this.supplyStatusTranslation(contractEntry.contractDetail.supplyStatus),
            };
        },
        openEditAliasModal: function (contractNumber, alias) {
            this.$refs.editAliasModal.openModal(contractNumber, alias);
        },
        editFavorite: function (contractNumber, favorite) {
            console.log("Editando favorito: " + favorite);
            this.$store
                .dispatch(actionTypes.UPDATE_FAVORITE, {
                    contractNumber: contractNumber,
                    favorite: favorite,
                    locale: this.$i18n.locale,
                })
                .then((value) => {
                    console.debug("Terminando de actualizar favorito" + value);
                    this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
                    //TODO pendiente de obtener el atributo favorito real del listado de contratos
                    // this.$refs.contractsAdvanceSearch.filterContracts();
                });
        },
        routeToContractDetail: function (contractNumber) {
            router.push({ name: viewMappingTypes.CONTRACT_DETAIL_MAPPING.name, params: { contractId: contractNumber } });
        },
        launchSecondLevelDropdown: function (contractNumber) {
            document.getElementById(`dropdow-second-level-${contractNumber}__BV_toggle_`).click();
        },
        generateExcelContractList: function () {
            let xlsxGenerator = new ContractListXlsxGenerator(`Contratos-${this.userId}.xlsx`, this.tableExcel, this.$i18n);
            console.log(xlsxGenerator);
        },
        generatePdfContractList: function () {
            let pdfGenerator = new ContractListPdfGenerator(this.$i18n, this.userId, this.clientId, this.selectedContract);
            pdfGenerator.generatePdf(this.filteredContracts != null ? this.filteredContracts : this.contracts);
            pdfGenerator.downloadPdf();
        },
        supplyStatusTranslation: function (supplyStatus) {
            return this.$t("ofex.backendCodes.supplyStatus." + this.supplyStatusBackendCode(supplyStatus));
        },
        supplyStatusBackendCode: function (supplyStatus) {
            return trimBackendCode(supplyStatus);
        },
        supplyActiveStatus: function (supplyStatus) {
            let status = trimBackendCode(supplyStatus);
            return status.toUpperCase() == "BAIXA" || status.toUpperCase() == "BAJA" ? false : true;
        },
        getRowStyleClass(row) {
            return row.estado.toUpperCase() == "BAIXA" || row.estado.toUpperCase() == "BAJA" ? "baja-row" : "";
        },
    },
    computed: {
        ...mapState(["contracts", "isLoading", "clientData"]),
        ...mapGetters([
            "isCompany",
            "hasAnyTelerecording",
            "hasAnyActiveContract",
            "hasAnyInactiveContract",
            "contractsWithTelerecording",
            "userId",
            "clientId",
            "getContractSupplyCompleteAddress",
            "getContractByContractNumber",
        ]),
        tableRows: function () {
            return this.filteredContracts !== null ? this.filteredContracts.map(this.mapContractToTableRow) : this.contracts.map(this.mapContractToTableRow);
        },
        tableExcel: function () {
            return this.filteredContracts !== null ? this.filteredContracts.map(this.mapContractToExcel) : this.contracts.map(this.mapContractToExcel);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contrato",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$t("ofex.tuContrato.estado"),
                    field: "estado",
                    tdClass: "col-estado",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                    tdClass: "col-alias",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "suministro",
                    tdClass: "col-suministro",
                    thClass: "col-suministro",
                },

                {
                    label: this.$t("ofex.tuContrato.pendingInvoice"),
                    field: "deuda",
                    tdClass: "col-pago",
                    thClass: "col-pago",
                    sortFn: function (x, y, col, rowX, rowY) {
                        // console.debug(`Comparando X:${x} Y:${y} COL:${col} ROWX:${rowX} ROWY:${rowY}`);
                        return Number(rowX.deuda) < Number(rowY.deuda) ? -1 : Number(rowX.deuda) > Number(rowY.deuda) ? 1 : 0;
                    },
                },
                {
                    label: this.$t("table.envio"),
                    field: "envio",
                    tdClass: "col-envio",
                    thClass: "col-envio",
                    html: true,
                },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        if (this.contractsWithTelerecording.length === 1) {
            this.oneContractWithTelerecording = this.contractsWithTelerecording[0].contractDetail.contractNumber;
            this.$store.dispatch(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, {
                contractNumber: this.oneContractWithTelerecording,
                locale: this.$root.$i18n.locale,
            });
        }
    },
    beforeDestroy() {
        this.filteredContracts = null;
    },
};
</script>

<style lang="scss">
.p-contract-view {
    .m-advance-search {
        .multiselect__select {
            width: 28px;
            height: 32px;
        }
        .multiselect__input {
            margin-top: 5px;
        }
    }
    .subtitle {
        margin-bottom: 25px;
    }
    .vgt-table {
        .col-suministro {
            min-width: 220px !important;
        }
        .col-envio {
            .text {
                min-width: 88px;
            }
        }
    }
}

.hide-dropdown {
    .btn-link {
        cursor: default !important;
    }
}

.overlay-avoid-click {
    width: 0;
}
.baja-row {
    background-color: #f8d5d3;
}
.text-inactive-contract {
    @include font-size(12);
    @include line-height(14);
    color: $color-primary;
    font-weight: $font-primary-medium;
    margin-top: 8px;
    margin-left: 29px;
    background-color: #f8d5d3;
}
</style>
