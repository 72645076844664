<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container>
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <ab-change-lang-dialog v-if="comingFromLogin && clientData && !isLoading && legalCheckStatus === 'FINISHED' && $isWebApp()"></ab-change-lang-dialog>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.home.title')" :username="userData?.firstName" :exclamation="'!'" />
                <!--Select Contract -->
                <ab-select-contract />

                <ab-situacion-sequia
                    v-if="selectedContractNumber && showHomeDroughtModule && droughtConfiguration?.enabledAll"
                    :contract-number="selectedContractNumber"
                />

                <ab-title-section-ofex
                    :src-img="require('../assets/images/circleGraphicDig.png')"
                    :title-section="$t('ofex.titleSectionOfex.tusConsumos')"
                ></ab-title-section-ofex>

                <template v-if="userInvoices && userInvoices.length > 0 && selectedContractNumber">
                    <template v-if="showDomesticModule">
                        <ab-domestic-consumption :key="selectedContractNumber + '-domestic'"></ab-domestic-consumption>
                    </template>
                    <template v-else-if="!showDomesticModule">
                        <ab-tendency-consumption :key="selectedContractNumber + '-tendency'"></ab-tendency-consumption>
                    </template>
                </template>

                <ab-without-data class="mt-3" v-else :text="$t('ofex.home.noDataYet')" />

                <ab-title-section-ofex
                    v-if="!lastInvoice"
                    :src-img="require('../assets/images/circleTicket.png')"
                    :title-section="$t('ofex.titleSectionOfex.ultimaFactura.titleSection')"
                ></ab-title-section-ofex>
                <ab-title-section-ofex
                    :src-img="require('../assets/images/circleTicket.png')"
                    :sub-title="true"
                    :title-section="$t('ofex.titleSectionOfex.ultimaFactura.titleSection')"
                    :text-section="
                        $t('ofex.titleSectionOfex.ultimaFactura.textSection', {
                            fromDay: startDateMoment.date(),
                            fromMonth: startDateMoment.format('MMMM'),
                            fromYear: startDateMoment.year(),
                            toDay: endDateMoment.date(),
                            toMonth: endDateMoment.format('MMMM'),
                            toYear: endDateMoment.year(),
                        })
                    "
                    v-if="lastInvoice"
                ></ab-title-section-ofex>
                <ab-invoice-summary :invoice="lastInvoice" v-if="userInvoices && userInvoices.length > 0" />
                <ab-without-data :text="$t('ofex.withoutData.lastInvoice')" v-else />

                <!--Evolution envoices -->
                <ab-title-section-ofex
                    :src-img="require('../assets/images/circleGraphic.png')"
                    :title-section="$t('ofex.titleSectionOfex.EvolucionFactura')"
                ></ab-title-section-ofex>
                <ab-contingencias class="mb-5" v-if="!contigenciasStatus['sicab']" />
                <ab-evolution-invoices :contract="selectedContractNumber" v-else-if="userInvoices && userInvoices.length > 0" />
                <ab-without-data :text="$t('ofex.withoutData.lastInvoice')" v-else />

                <ab-contingencias class="mb-5" v-if="selectedContractNumber && selectedContractTelerecording && !contigenciasStatus['mdm']" />

                <!--Service incidents -->
                <ab-title-section-ofex
                    :src-img="require('../assets/images/circleBombilla.png')"
                    :title-section="$t('ofex.titleSectionOfex.incidenciasServicio')"
                ></ab-title-section-ofex>
                <ab-service-incidents
                    v-if="(droughtConfiguration?.enabledAll && hasAnyDroughtAffectedContract) || (unreadNotifications && unreadNotifications.length > 0)"
                />
                <ab-without-data v-else :text="$t('ofex.withoutData.incidents')" />

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.explicamosAdc.name')"
                    :href-left="$t('ofex.relatedContents.explicamosAdc.url')"
                    :image-left="$t('ofex.relatedContents.explicamosAdc.image')"
                    :name-center="$t('ofex.relatedContents.quePagasFactura.name')"
                    :href-center="$t('ofex.relatedContents.quePagasFactura.url')"
                    :image-center="$t('ofex.relatedContents.quePagasFactura.image')"
                    :name-right="$t('ofex.relatedContents.consellEstalvi.name')"
                    :href-right="$t('ofex.relatedContents.consellEstalvi.url')"
                    :image-right="$t('ofex.relatedContents.consellEstalvi.image')"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import AbLeftNavBar from "../modules/common/AbLeftNavBar.vue";
import AbMainTitle from "../modules/AbMainTitle.vue";
import AbTitleSectionOfex from "../modules/AbTitleSectionOfex";
import AbSelectContract from "../modules/AbSelectContract.vue";
import AbYourInterest from "../modules/AbYourInterest.vue";
import AbEvolutionInvoices from "../modules/AbEvolutionInvoices";
import AbInvoiceSummary from "../modules/AbInvoiceSummary";
import AbServiceIncidents from "../modules/AbServiceIncidents";
import AbLoading from "../components/AbLoading";
import AbWithoutData from "../components/AbWithoutData";

import * as actionTypes from "../store/action-types";

import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { AB_DATE_FORMAT } from "@/plugins/ab-utils";
import { LOGIN_VIEW_MAPPING } from "@/router/view-mapping-types";
import AbSituacionSequia from "@/modules/AbSituacionSequia.vue";
import AbTendencyConsumption from "@/modules/AbTendencyConsumption.vue";
import AbDomesticConsumption from "@/modules/AbDomesticConsumption.vue";

export default {
    name: "Home",
    components: {
        AbDomesticConsumption,
        AbTendencyConsumption,
        AbLeftNavBar,
        AbMainTitle,
        AbSelectContract,
        AbYourInterest,
        AbEvolutionInvoices,
        AbInvoiceSummary,
        AbServiceIncidents,
        AbLoading,
        AbWithoutData,
        AbTitleSectionOfex,
        AbSituacionSequia,
    },
    data: function () {
        return {
            comingFromLogin: false,
        };
    },
    computed: {
        ...mapState([
            "selectedContractNumber",
            "userData",
            "isLoading",
            "clientData",
            "userInvoices",
            "unreadNotifications",
            "legalCheckStatus",
            "contigenciasStatus",
            "droughtConfiguration",
            "contracts",
            "activeContracts",
        ]),
        ...mapGetters([
            "selectedContractTelerecording",
            "totalUnpaidAmount",
            "lastInvoice",
            "showHomeDroughtModule",
            "hasAnyDroughtAffectedContract",
            "selectedContractIsDomestic",
        ]),
        startDateMoment: function () {
            return moment(this.lastInvoice.startDate, AB_DATE_FORMAT, this.$i18n.locale);
        },
        endDateMoment: function () {
            return moment(this.lastInvoice.endDate, AB_DATE_FORMAT, this.$i18n.locale);
        },
        showDomesticModule: function () {
            return this.selectedContractIsDomestic && this.droughtConfiguration?.domesticConsumption?.showModule;
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        this.$store.dispatch(actionTypes.GET_ACTIVE_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        this.$store.dispatch(actionTypes.GET_UNREAD_NOTIFICATIONS_ACTION, { locale: this.$i18n.locale });
        this.$store.dispatch(actionTypes.GET_DROUGHT_CONFIGURATION_ACTION, { locale: this.$i18n.locale });
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            // si venimos del Login cargamos el idioma del usuario
            if (from.name === LOGIN_VIEW_MAPPING.name) {
                vm.comingFromLogin = true;
            }
        });
    },
};
</script>

<style lang="scss" scoped>
.m-main-title {
    margin-bottom: 35px;

    @include respond-above(sm) {
        margin-bottom: 20px;
    }
}
</style>
