<i18n src="./common/i18n-components.i18n"></i18n>

<!--https://xaksis.github.io/vue-good-table/guide/#basic-example-->

<template>
    <div>
        <ab-loading v-model="loading" :full-page="true" :loader-text="$t('contrato.loadingContracts')"></ab-loading>

        <div v-show="showTables && itemsFP.length > 1" class="contratos-container mt-3">
            <h3 class="mb-0 section-header-h3">{{ $t("table.labelContratosPendientes") }}</h3>

            <div class="row mt-3">
                <div class="col-12">
                    <div class="hr-azul-form"></div>
                </div>
            </div>

            <vue-good-table
                :ref="reference"
                :columns="columns"
                :search-options="{ enabled: true }"
                :rows="itemsFP"
                :row-style-class="'text-table-form'"
                :pagination-options="tablePaginationOptions"
                :select-options="tableSelectOptions"
                @on-selected-rows-change="reportSelectedContracts"
            >
                <div slot="emptystate" class="text-center textdnormal">
                    {{ $t("contrato.sinDatos") }}
                </div>
            </vue-good-table>
        </div>

        <div v-show="showTables && itemsSP.length > 0" class="contratos-container mt-3">
            <h3 class="mb-0 section-header-h3">{{ $t("table.labelContratosActivos") }}</h3>

            <div class="row mt-3">
                <div class="col-12">
                    <div class="hr-azul-form"></div>
                </div>
            </div>

            <vue-good-table
                :columns="columns"
                :rows="itemsSP"
                :row-style-class="'text-table-form'"
                :pagination-options="tablePaginationOptions"
                @on-selected-rows-change="reportSelectedContracts"
            >
                <div slot="emptystate" class="text-center textdnormal">
                    {{ $t("contrato.sinDatos") }}
                </div>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import { VueGoodTable } from "vue-good-table";
import AbLoading from "./AbLoading.vue";

export default {
    components: {
        VueGoodTable,
        AbLoading,
    },
    props: {
        docNumber: {
            type: String,
            required: true,
        },
        showTables: {
            type: Boolean,
            default: true,
        },
        reference: {
            type: String,
            required: false,
            default: "tabla-contratos",
        },
    },
    data: function () {
        return {
            columns: [
                {
                    label: this.$i18n.tc("table.numContrato"),
                    field: "contrato",
                },
                {
                    label: this.$i18n.tc("table.aliasContrato"),
                    field: "alias",
                },
                {
                    label: this.$i18n.tc("table.poblacion"),
                    field: "poblacion",
                },
                {
                    label: this.$i18n.tc("table.calle"),
                    field: "direccion",
                },
                {
                    label: this.$i18n.tc("table.envio"),
                    field: "envio",
                },
            ],
            itemsSP: [],
            itemsFP: [],
            selected: [],
            selectAll: false,
            loading: false,
            tableSelectOptions: {
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: "custom-class",
                selectionText: this.$i18n.tc("table.contratosSeleccionados"),
                clearSelectionText: this.$i18n.tc("table.limpiar"),
                disableSelectInfo: true, // disable the select info panel on top
            },
            tablePaginationOptions: {
                enabled: true,
                perPage: 5,
                nextLabel: this.$i18n.tc("table.siguiente"),
                prevLabel: this.$i18n.tc("table.atras"),
                rowsPerPageLabel: this.$i18n.tc("table.filasPagina"),
                ofLabel: "de",
                pageLabel: this.$i18n.tc("table.pagina"), // for 'pages' mode
                allLabel: this.$i18n.tc("table.todos"),
            },
        };
    },
    created() {
        // this.calculateContracts();
    },
    methods: {
        reportSelectedContracts: function (contracts) {
            console.debug("Cambiando contratos");
            this.$emit("selected-contracts-change", contracts.selectedRows);
        },
        calculateContracts: function () {
            this.calculateContractsExplicit(this.docNumber);
        },
        calculateContractsExplicit: function (explicitDocNumber) {
            this.loading = true;
            console.log("Obteniendo contratos del usuario " + explicitDocNumber);
            return this.$http
                .get("/o/ofex/contracts", {
                    params: {
                        clientId: explicitDocNumber,
                        userId: explicitDocNumber,
                    },
                })
                .then(
                    function (response) {
                        console.debug("Pintando tabla de contratos");
                        let allItems = response.data.data.map(function (i) {
                            return {
                                contrato: i.contractDetail.contractNumber,
                                alias: i.contractDetail.alias,
                                poblacion: i.supplyAddress.municipality,
                                direccion: i.supplyAddress.streetType + " " + i.supplyAddress.streetName + " " + i.supplyAddress.number,
                                envio: this.i18n.tc("ofex.backendCodes.deliveryType." + i.contractDetail.invoiceDeliveryType),
                                codigoEnvio: i.contractDetail.invoiceDeliveryType,
                            };
                        }); //Filtramos solamente los de FP

                        let grouped = groupBy(allItems, "codigoEnvio");

                        console.log(grouped);

                        if (grouped["FP"]) {
                            this.itemsFP = grouped["FP"];
                        }

                        if (grouped["SP"]) {
                            this.itemsSP = grouped["SP"];
                        }

                        //si solamente es uno seleccionaremos programáticamente
                        if (this.itemsFP.length === 1) {
                            this.$set(this.itemsFP[0], "vgtSelected", true);
                        }

                        this.loading = false;
                    },
                    function (error) {
                        console.error(error);
                        this.loading = false;
                    }
                );
        },
    },
};
</script>

<style src="vue-good-table/dist/vue-good-table.css"></style>
