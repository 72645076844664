import BaseOfexServiceClient from "./BaseOfexServiceClient";

const DROUGHT_BASE_URL = process.env.VUE_APP_API_DROUGHT_BASE_URL;

export default {
    getDroughtConfiguration: async function (userId, clientId, lang = "es") {
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.get(`${DROUGHT_BASE_URL}/drought/configuration`, {
            params: {
                userId,
                clientId,
                lang,
            },
        });
        if (response.status === 200) {
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
    getDroughtAffectationDataByCode: async function (userId, clientId, code, lang = "es") {
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.get(`${DROUGHT_BASE_URL}/drought/affectations`, {
            params: {
                userId,
                clientId,
                queryNumber: code,
                queryType: "CONTRACT",
                lang,
            },
        });
        if (response.status === 200) {
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
};
