<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="row">
        <div class="col-12">
            <div class="card format-card">
                <ab-section-header :section-name="$t('baterias.numeroEquiposMedidaTrasladar')"></ab-section-header>

                <div v-for="(item, index) in baterias" :key="item.letra" class="row mt-5 mb-5">
                    <div class="col-8 col-lg-4">
                        <ab-input
                            v-model="baterias[index].letra"
                            :name="name + 'letra' + index"
                            :input-alias="$t('alias.letraBateria')"
                            placeholder="Bateria o, A,B C..."
                            :validation="{ required: true, min: 1 }"
                            :label="$t('baterias.bateriaAntigua')"
                        ></ab-input>
                    </div>

                    <div class="col-8 col-lg-4">
                        <ab-input
                            v-model="baterias[index].planta"
                            :name="name + 'planta' + index"
                            :input-alias="$t('alias.numeroPlantas')"
                            placeholder="1, 2, 3..."
                            :popover="$t('baterias.equiposMedidaPopover')"
                            :validation="{ required: true, min: 1 }"
                            :label="$t('baterias.equiposMedidaLabel')"
                        ></ab-input>
                    </div>
                </div>

                <div class="row mt-2 mb-5">
                    <div class="col-12 col-lg-8">
                        <button type="button" class="botonBateria" @click="addNewEntry">
                            <i class="fas fa-plus-circle"
                                ><span class="textdnormal ml-1">{{ $t("baterias.addBateria") }}</span></i
                            >
                        </button>
                    </div>

                    <div v-if="isMulti" class="col-12 col-lg-4">
                        <button type="button" class="botonBateria" @click="removeLastEntry">
                            <i class="fas fa-minus-circle"
                                ><span class="textdnormal ml-1">{{ $t("baterias.deleteBateria") }}</span></i
                            >
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AbInput from "./AbInput.vue";
import AbSectionHeader from "./AbSectionHeader.vue";

export default {
    components: {
        AbInput,
        AbSectionHeader,
    },
    model: {
        prop: "bateriasList",
        event: "change",
        type: Array,
    },
    props: {
        required: {
            default: false,
            type: Boolean,
        },
        name: {
            required: true,
            type: String,
        },
        validationScope: String,
        validationEvent: String,
    },
    data: function () {
        return {
            baterias: [{ letra: "", planta: "" }],
        };
    },
    computed: {
        isMulti: function () {
            return this.baterias.length > 1;
        },
    },
    methods: {
        addNewEntry: function () {
            this.baterias.push({ letra: "", planta: "" });
            console.log(this.baterias);
        },
        removeLastEntry: function () {
            this.baterias.pop();
        },
    },
};
</script>
