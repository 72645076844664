<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container class="p-affectations-view" v-if="this.hasAnyActiveContract === true">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <ab-edit-alias-modal ref="editAliasModal" />
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.afectaciones-contratos.header')"></ab-main-title>

                <p class="subtitle">{{ $t("ofex.afectaciones-contratos.subtitle") }}</p>

                <ab-contracts-advance-search v-model="filteredContracts" ref="contractsAdvanceSearch" :onlyActiveContracts="true" />
                <vue-good-table
                    :columns="columns"
                    :rows="tableRows"
                    style-class="vgt-table"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        perPageDropdown: [10, 20, 30],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: this.$i18n.tc('table.siguiente'),
                        prevLabel: this.$i18n.tc('table.atras'),
                        rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                        ofLabel: 'de',
                        allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                    }"
                    @on-per-page-change="onPerPageChange"
                >
                    <template #table-row="props">
                        <div v-if="props.column.field === 'contrato'">
                            <span class="contract" :class="{ pending: props.row.deuda }">{{ props.row.contrato }}</span>
                            <img v-if="props.row.telelectura" class="icon-contrato" alt="icono contrato" src="../assets/images/bola-telelectura.png" />
                        </div>
                        <div
                            class="col-alias-inner"
                            v-else-if="props.column.field === 'alias'"
                            @click="openEditAliasModal(props.row.contrato, props.row.alias)"
                        >
                            <span>{{ props.row.alias }}</span>
                            <ab-popover fontawesome-icon="pen" v-bind:popover="$t('ofex.tuContrato.popoverEditAlias')" />
                        </div>
                        <div class="col-afectacion-inner d-flex align-items-center" v-else-if="props.column.field === 'afectacion'">
                            <span class="text">{{ props.row.afectacion }}</span>
                            <div class="buttons-group d-flex align-items-center">
                                <b-dropdown
                                    :id="`dropdown-first-level-${props.row.contrato}`"
                                    dropleft
                                    size="lg"
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    class="table-dropdown"
                                >
                                    <b-dropdown-item-button
                                        v-if="props.row.esAfectado"
                                        aria-describedby="desplegable"
                                        @click="routeToAffectationDetail(props.row.contrato)"
                                        >{{ $t("ofex.tuContrato.detail") }}</b-dropdown-item-button
                                    >
                                    <b-dropdown-item-button v-else class="disabled-menu" aria-describedby="desplegable" disabled>{{
                                        $t("ofex.tuContrato.detail")
                                    }}</b-dropdown-item-button>
                                </b-dropdown>

                                <img v-if="props.row.esAfectado" alt="icono de alerta" width="40px" src="../assets/images/svg/warning.svg" />
                            </div>
                        </div>
                        <div v-line-clamp:20="2" v-else>
                            <span v-tooltip.top-center="props.formattedRow[props.column.field]">{{ props.formattedRow[props.column.field] }}</span>
                        </div>
                    </template>
                    <div slot="emptystate" class="text-center textdnormal">
                        {{ $t("ofex.tuContrato.emptyResults") }}
                    </div>
                </vue-good-table>
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.afectaciones-contratos.header')"></ab-main-title>
                <ab-without-data class="mt-3" :text="$t('ofex.afectaciones-contratos.noActiveContracts')" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import AbEditAliasModal from "@/modules/common/AbEditAliasModal.vue";
import { mapGetters, mapState } from "vuex";
import AbContractsAdvanceSearch from "@/modules/AbContractsAdvanceSearch.vue";
import * as actionTypes from "@/store/action-types";
import router from "../router/router";
import * as viewMappingTypes from "@/router/view-mapping-types";
import { isDroughtAffected } from "@/plugins/ab-utils";

export default {
    name: "AffectationsContractsView",
    components: { AbContractsAdvanceSearch, AbEditAliasModal },
    data: function () {
        return {
            filteredContracts: null,
        };
    },
    methods: {
        onPerPageChange: function (params) {
            if (params.currentPerPage === 10) {
                this.$nextTick().then(() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                });
            }
        },
        mapContractToTableRow: function (contractEntry) {
            let supplyAddress = this.getContractSupplyCompleteAddress(contractEntry.contractDetail.contractNumber);
            return {
                // yearInvoice: contractEntry.contractDetail.invoiceYear,
                contrato: contractEntry.contractDetail.contractNumber,
                alias: contractEntry.contractDetail.alias ? contractEntry.contractDetail.alias : "",
                suministro: supplyAddress,
                deuda: contractEntry.contractDetail.invoicesPending,
                /*TODO cambiar parameter de afectacion favorite por correspondiente*/
                afectacion: isDroughtAffected(contractEntry)
                    ? this.$t("ofex.afectaciones-contratos-detalle.afectado")
                    : this.$t("ofex.afectaciones-contratos-detalle.no-afectado"),
                esAfectado: isDroughtAffected(contractEntry),
            };
        },
        routeToAffectationDetail: function (contractNumber) {
            router.push({ name: viewMappingTypes.AFFECTATIONS_CONTRACTS_DETAIL_MAPPING.name, params: { contractId: contractNumber } });
        },
        openEditAliasModal: function (contractNumber, alias) {
            this.$refs.editAliasModal.openModal(contractNumber, alias);
        },
    },
    computed: {
        ...mapState(["activeContracts", "isLoading", "clientData"]),
        ...mapGetters([
            "isCompany",
            "hasAnyTelerecording",
            "contractsWithTelerecording",
            "userId",
            "clientId",
            "getContractSupplyCompleteAddress",
            "getContractByContractNumber",
            "hasAnyActiveContract",
        ]),
        tableRows: function () {
            return this.filteredContracts !== null
                ? this.filteredContracts.map(this.mapContractToTableRow)
                : this.activeContracts.map(this.mapContractToTableRow);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contrato",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                    tdClass: "col-alias",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "suministro",
                    tdClass: "col-suministro",
                    thClass: "col-suministro",
                },
                {
                    label: this.$t("ofex.afectaciones-contratos.affectationColumnHeader"),
                    field: "afectacion",
                    tdClass: "col-afectacion",
                    thClass: "col-afectacion",
                },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_ACTIVE_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        if (this.contractsWithTelerecording.length === 1) {
            this.oneContractWithTelerecording = this.contractsWithTelerecording[0].contractDetail.contractNumber;
            this.$store.dispatch(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, {
                contractNumber: this.oneContractWithTelerecording,
                locale: this.$root.$i18n.locale,
            });
        }
    },
    beforeDestroy() {
        this.filteredContracts = null;
    },
};
</script>

<style scoped lang="scss">
.col-afectacion-inner {
    justify-content: space-between;
}
.hide-dropdown {
    .btn-link {
        cursor: default !important;
    }
}

.overlay-avoid-click {
    width: 0;
}
.disabled-menu {
    cursor: not-allowed;
    background-color: $bg-grey-light;
    text-align: center;
    &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
    }
}
</style>
