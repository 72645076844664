"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";

const ACTIVITY_CODE = "UD";
export default class ResetEmailUserPdfGenerator extends BaseGenerator {
    constructor(i18n, user, client) {
        super("SolicitudCambioCorreoElectronico.pdf", ACTIVITY_CODE, i18n, user, client, "");
    }

    generatePdf(form, isCompany, attachedFile1, attachedFile2) {
        const POINTS = ": ";
        const SPACE = " ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.emailRecovery.title"), "");

        this.generateLineText(this.i18n.tc("ofex.emailRecovery.subTitle1"));
        this.generateLineText(this.i18n.tc("ofex.emailRecovery.subTitle2"));
        this.generatePdfSection(this.i18n.tc("ofex.emailRecovery.title"));
        this.generateLineText(
            isCompany
                ? this.i18n.tc("ofex.emailRecovery.numeroDocumentoTitular") +
                      POINTS +
                      form.companyIdentification.doc.type +
                      SPACE +
                      form.companyIdentification.doc.number +
                      SPACE +
                      form.userIdentification.doc.type +
                      SPACE +
                      form.userIdentification.doc.number
                : this.i18n.tc("ofex.emailRecovery.numeroDocumentoUser") +
                      POINTS +
                      form.userIdentification.doc.type +
                      SPACE +
                      form.userIdentification.doc.number
        );
        this.generateLineText(this.i18n.tc("ofex.emailRecovery.nombre2") + POINTS + form.name);
        this.generateLineText(this.i18n.tc("ofex.emailRecovery.primerApellido2") + POINTS + form.firstLastName);
        if (form.secondLastName) {
            this.generateLineText(this.i18n.tc("ofex.emailRecovery.segundoApellido") + POINTS + form.secondLastName);
        }
        this.generateLineText(this.i18n.tc("ofex.emailRecovery.telefono2") + POINTS + form.phone);
        this.generateLineText(this.i18n.tc("ofex.emailRecovery.numeroFactura2") + POINTS + form.invoiceNumber);
        this.generateLineText(this.i18n.tc("ofex.emailRecovery.importeFactura2") + POINTS + form.invoiceAmount);
        this.generateLineText(this.i18n.tc("ofex.emailRecovery.email") + POINTS + form.email);

        this.generateLineText(this.i18n.tc("upload.ficheroAdjunto") + POINTS + attachedFile1.file.name);
        if (attachedFile2.file.name != "") this.generateLineText(this.i18n.tc("upload.ficheroAdjunto") + POINTS + attachedFile2.file.name);

        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.reclamations.aceptacionCondiciones"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.aceptoCondiciones") + SPACE + "Si");
        return this.pdf;
    }
}
