<template>
    <div class="m-select-your-contract" :class="fatherClass">
        <ab-autocompletable-select
            v-model="modelValue"
            name="selectContract"
            :input-alias="$t('ofex.forms.reclamations.tusFacturasAlias')"
            :placeholder="placeholder"
            :validation="{ required: required }"
            :data-list="reclamationPage ? activeContractsSummaryList : contractsSummaryList"
            data-key="key"
            data-label="text"
            :pending-ofex-slot="selectedContractWithPendingInvoices"
            :inactive-ofex-slot="true"
            :class="
                selectedContractWithPendingInvoices
                    ? { 'selected-pending': selectedContractWithPendingInvoices }
                    : { 'selected-inactive-contract': selectedInactiveContract }
            "
            :label="label"
            :allow-empty="allowEmpty"
        >
            <span slot="remote-reading" v-if="selectedContractTelerecording" class="remote-reading">{{ $t("ofex.home.telelectura") }}</span>
        </ab-autocompletable-select>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import * as actionTypes from "../store/action-types";
import AbAutocompletableSelect from "../components/AbAutocompletableSelect.vue";

export default {
    name: "AbSelectContract",
    components: {
        AbAutocompletableSelect,
    },
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        vuexModel: {
            type: Boolean,
            default: true,
        },
        allowEmpty: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: function () {
                return this.$i18n.tc("common.seleccionar");
            },
        },
        fatherClass: {
            required: false,
            type: String,
        },
        label: {
            required: false,
            default: function () {
                return this.$i18n.tc("ofex.home.tuContrato");
            },
        },
        required: {
            type: Boolean,
            default: true,
            required: false,
        },
        //TODO unificar con vuexModel y crear enumerado
        reloadPage: {
            type: Boolean,
            default: false,
        },
        reclamationPage: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            model: {
                autoCompletableSelect: [],
            },
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.reclamationPage
                    ? this.vuexModel === true
                        ? this.activeContractsSummaryList.find((i) => i.key == this.selectedContractNumber)
                        : this.activeContractsSummaryList.find((i) => i.key == this.$attrs["inputValue"])
                    : this.vuexModel === true
                    ? this.contractsSummaryList.find((i) => i.key == this.selectedContractNumber)
                    : this.contractsSummaryList.find((i) => i.key == this.$attrs["inputValue"]);
            },
            set: function (newValue) {
                if (this.reloadPage) {
                    console.log("Recargando pagina " + this.$route.path);
                    this.$router.replace({ name: this.$route.name, params: { contractId: newValue.key } }).then(() => location.reload());
                } else {
                    if (this.vuexModel === true) {
                        let vueContext = this;
                        this.$store
                            .dispatch(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, { contractNumber: newValue.key, locale: this.$root.$i18n.locale })
                            .then(function () {
                                vueContext.$root.$emit(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, newValue.key);
                            });
                    } else {
                        newValue != null ? this.$emit("change", newValue.key) : this.$emit("change", null);
                    }
                }
            },
        },
        ...mapGetters([
            "contractsSummaryList",
            "activeContractsSummaryList",
            "selectedContractTelerecording",
            "selectedContractWithPendingInvoices",
            "selectedInactiveContract",
        ]),
        ...mapState(["selectedContractNumber"]),
    },
};
</script>

<style lang="scss">
.m-select-your-contract {
    margin-bottom: 20px;

    @include respond-above(sm) {
        flex-wrap: nowrap;
        align-items: center;
    }

    .selected-pending {
        .select-component :not(.multiselect--active) {
            .multiselect__tags {
                color: $error-color !important;
            }
        }
        .select-component {
            .multiselect .multiselect__tags {
                .multiselect__single {
                    color: $error-color !important;
                }
            }
        }
    }
    .selected-inactive-contract {
        .select-component :not(.multiselect--active) {
            .multiselect__tags {
                background: #f8d5d3;
            }
        }
        .select-component {
            .multiselect .multiselect__tags {
                .multiselect__single {
                    background: transparent;
                }
            }
        }
    }
    .multiselect__content-wrapper {
        .multiselect__content {
            .inactive-contract {
                background: #f8d5d3;
            }
            .multiselect__option--highlight {
                .inactive-contract {
                    background: #eb817a;
                }
            }
        }
    }
    .select-component {
        display: flex;
        flex: 0 0 100%;
        margin-bottom: 20px;
        justify-content: space-between;
        flex-wrap: wrap;

        @include respond-above(sm) {
            flex: 1;
            margin-bottom: 0;
            flex-wrap: nowrap;
        }
        .select-label {
            width: auto;
        }
        .invoices-pending {
            color: $error-color;
        }
        .inactive-contract {
            background: $error-color;
        }
        .select-label {
            flex: 0 0 auto;
            @include font-size(18);
            @include line-height(23);
            font-weight: $font-primary-bold;
            color: $color-primary;
            margin-bottom: 7px;

            @include respond-above(sm) {
                margin: 5px 20px 0 0;
                @include line-height(21);
            }
        }
        .select-container {
            flex: 1 100%;

            @include respond-above(sm) {
                flex: 1 auto;
            }
        }
        .multiselect__single {
            margin-top: 4px !important;
            padding-right: 33px;

            @include line-height(26);
            @include respond-above(sm) {
                @include line-height(22);
            }
        }
        .multiselect__select {
            height: 40px !important;
            @include respond-above(sm) {
                height: 32px !important;
            }
        }
        .multiselect__content-wrapper {
            overflow-x: hidden;
        }
    }
}
.remote-reading {
    flex: 0 auto;
    background: $border-table;
    color: $color-lightest;
    width: auto;
    height: 24px;
    padding: 4px 12px;
    border-radius: 8px;
    margin-bottom: 15px;
    font-weight: $font-primary-bold;
    white-space: nowrap;
    @include font-size(12);
    @include line-height(18);

    @include respond-above(sm) {
        margin: 4px 0 0 20px;
        flex: 0 0 auto;
        order: 3;
    }
}
</style>
