<template>
    <b-container>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.modificationContractSelector.title')" />
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <ab-table-select-contract v-if="multicontract" v-model="selectedContract" :modification-type="typeViewModification"></ab-table-select-contract>
                <div class="mb-2">
                    <img class="icon-t" alt="imagen bola telelectura" src="../assets/images/bola-telelectura.png" />
                    <span class="ml-2 text-telelectura">{{ $t("ofex.home.telelectura") }}</span>
                </div>
                <!-- boton -->
                <div class="d-flex flex-row-reverse">
                    <div class="box-next">
                        <ab-button-ofex
                            :disabled="selectedContract == null || selectedContract === ''"
                            :click-event="navigateView"
                            :text-button="$t('ofex.bajaContrato.siguiente')"
                        ></ab-button-ofex>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import AbTableSelectContract from "../modules/contracts/modification/AbTableSelectContract";
import * as actionTypes from "@/store/action-types";
export default {
    name: "ReadMeterSelector",
    components: {
        AbTableSelectContract,
    },
    data: function () {
        return {
            selectedContract: "",
            typeViewModification: "",
        };
    },
    computed: {
        ...mapState(["isLoading", "contracts", "clientData"]),
        ...mapGetters(["multicontract"]),
    },
    mounted() {
        console.debug("Mounted " + this.$route.query.typeViewModification);
        this.typeViewModification = this.$route.query.typeViewModification;
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
    },
    methods: {
        navigateView: function () {
            this.$router.push({
                name: this.typeViewModification,
                params: { contractId: this.selectedContract },
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.icon-t {
    width: 16px;
    height: auto;
}
.text-telelectura {
    @include font-size(12);
    @include line-height(14);
    color: $color-primary;
    font-weight: $font-primary-medium;
}
.box-next {
    @include respond-below(md) {
        width: 100%;
    }
}
</style>
