<template>
    <b-container class="p-contract-selection">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.contratosSeleccion.title')" />
                <p class="subtitle">{{ $t("ofex.contratosSeleccion.subTitle") }}</p>
                <p class="text-subtitle">{{ $t("ofex.contratosSeleccion.estaHerramienta") }}</p>
                <!-- Búsqueda avanzada -->
                <ab-contracts-advance-search v-model="filteredContracts" :only-assigned-contracts="false" />
                <!--Tabla contratos -->
                <vue-good-table
                    :columns="columns"
                    :rows="tableRows"
                    :rowStyleClass="getRowStyleClass"
                    ref="assignation-contract-table"
                    style-class="vgt-table"
                    :select-options="{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        perPageDropdown: [10, 20, 30],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: this.$i18n.tc('table.siguiente'),
                        prevLabel: this.$i18n.tc('table.atras'),
                        rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                        ofLabel: 'de',
                        allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                        label: 'selector-de-paginas',
                        name: 'perPageSelect',
                    }"
                >
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field == 'contrato'">
                            <label :for="props.row.contrato" class="contract" :class="{ pending: props.row.deuda }">{{ props.row.contrato }}</label>
                            <img v-if="props.row.telectura" class="icon-contrato" alt="icono contrato" src="../assets/images/bola-telelectura.png" />
                        </div>
                        <div v-line-clamp:20="2" v-else>
                            <span v-tooltip.top-center="props.formattedRow[props.column.field]">{{ props.formattedRow[props.column.field] }}</span>
                        </div>
                    </template>
                    <div slot="emptystate" class="text-center textdnormal">
                        {{ $t("ofex.tuContrato.emptyResults") }}
                    </div>
                </vue-good-table>
                <div>
                    <img id="check-img" class="img-check" alt="imagen check" src="../assets/images/check-table-select.png" />
                    <label for="check-img" class="text-small ml-2">{{ $t("ofex.contratosSeleccion.contratoAsignado") }}</label>
                </div>
                <div>
                    <img class="icon-contrato separator-left" alt="icono contrato separador" src="../assets/images/bola-telelectura.png" />
                    <span class="text-small ml-2">{{ $t("ofex.contratosSeleccion.contratosTelelectura") }}</span>
                </div>
                <div>
                    <div v-if="hasAnyInactiveContract === true" class="text-inactive-contract">
                        <p class="text-small ml-2">{{ $t("ofex.home.inactivos") }}</p>
                    </div>
                </div>
                <div class="w-100">
                    <div class="box-button-save float-right pt-2">
                        <ab-button-ofex :text-button="$t('common.saveButton')" :click-event="assignContractSelected"></ab-button-ofex>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import difference from "lodash/difference";
import { mapState, mapGetters } from "vuex";
import AbContractsAdvanceSearch from "../modules/AbContractsAdvanceSearch";
import { VueGoodTable } from "vue-good-table";
import { trimBackendCode } from "@/plugins/ab-utils";
import * as actionTypes from "@/store/action-types";
import Swal from "sweetalert2";
import { SET_LOADING_MUTATION, CLEAR_NOTIFICATIONS_MUTATION } from "@/store/mutation-types";
export default {
    name: "ContractsSelectionView",
    components: {
        AbContractsAdvanceSearch,
        VueGoodTable,
    },

    data: function () {
        return {
            filteredContracts: null,
            loading: false,
        };
    },
    computed: {
        ...mapState(["allContracts", "isLoading", "clientData", "contracts"]),
        ...mapGetters(["hasAnyInactiveContract"]),
        contractsSelected: function () {
            return this.$refs["assignation-contract-table"].selectedRows;
        },
        tableRows: function () {
            return this.filteredContracts !== null ? this.filteredContracts.map(this.mapContractToTableRow) : this.allContracts.map(this.mapContractToTableRow);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contrato",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                    tdClass: "",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "suministro",
                    tdClass: "col-suministro",
                    thClass: "col-suministro",
                },

                {
                    label: this.$t("table.poblacion"),
                    field: "municipio",
                    tdClass: "col-municipio",
                    thClass: "col-municipio",
                },
                {
                    label: this.$t("table.fechaAlta"),
                    field: "fechaAlta",
                    tdClass: "col-fecha-alta",
                    thClass: "col-fecha-alta",
                    html: true,
                },
            ];
        },
    },
    methods: {
        assignContractSelected: function () {
            let allContractNumbers =
                this.filteredContracts != null && this.filteredContracts.length > 0
                    ? this.filteredContracts.map((value) => value.contractDetail.contractNumber)
                    : this.allContracts.map((value) => value.contractDetail.contractNumber);
            let assignedContractNumbers = this.contractsSelected.map((value) => value.contrato);
            let unassignedContractNumbers = difference(allContractNumbers, assignedContractNumbers);
            this.$store
                .dispatch(actionTypes.UPDATE_CONTRACTS_ASSIGNED, {
                    assignedList: assignedContractNumbers,
                    unassignedList: unassignedContractNumbers,
                    locale: this.$i18n.locale,
                })
                .then(() => this.reloadContractsAndShowOkMessage())
                .catch((reason) => this.launchContractAssignedErrorToast(reason));
        },
        launchContractAssignedChangedToast: function () {
            console.log("Lanzando mensaje de OK");
            Swal.fire({
                text: this.$i18n.tc("ofex.toasts.contractsAssignedOK"),
                icon: "success",
                confirmButtonText: "OK",
            });
        },
        launchContractAssignedErrorToast: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "ContractsSelectionView" });
            Swal.fire({
                text: e.response && e.response.data.errorMessage ? e.response.data.errorMessage : this.$i18n.tc("ofex.toasts.contractsAssignedError"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
        mapContractToTableRow: function (contractEntry) {
            return {
                telectura: contractEntry.contractDetail.telerecording,
                yearInvoice: contractEntry.contractDetail.invoiceYear,
                contrato: contractEntry.contractDetail.contractNumber,
                alias: contractEntry.contractDetail.alias ? contractEntry.contractDetail.alias : "",
                suministro:
                    this.$i18n.tc(`ofex.backendCodes.streetTypes.${trimBackendCode(contractEntry.supplyAddress.streetType)}`) +
                    " " +
                    contractEntry.supplyAddress.streetName +
                    " " +
                    contractEntry.supplyAddress.number +
                    ", " +
                    contractEntry.supplyAddress.otherAddressData,
                municipio: contractEntry.supplyAddress.municipality,
                fechaAlta: contractEntry.contractDetail.registrationDate,
                vgtSelected: contractEntry.contractDetail.assignationStatus === "ASSIGNED" || contractEntry.contractDetail.assignationStatus === "PENDING",
                estado: this.supplyStatusTranslation(contractEntry.contractDetail.supplyStatus),
            };
        },
        reloadContractsAndShowOkMessage: async function () {
            try {
                await this.$store.dispatch(actionTypes.GET_ALL_CONTRACTS_ACTION, { locale: this.$i18n.locale });
                await this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
                this.$store.commit(CLEAR_NOTIFICATIONS_MUTATION);
                this.launchContractAssignedChangedToast();
            } catch (reason) {
                this.launchContractAssignedErrorToast(reason);
            }
        },
        getRowStyleClass(row) {
            return row.estado.toUpperCase() == "BAIXA" || row.estado.toUpperCase() == "BAJA" ? "baja-row" : "";
        },
        supplyStatusTranslation: function (supplyStatus) {
            return this.$t("ofex.backendCodes.supplyStatus." + this.supplyStatusBackendCode(supplyStatus));
        },
        supplyStatusBackendCode: function (supplyStatus) {
            return trimBackendCode(supplyStatus);
        },
    },
    mounted() {
        // this.$set(this.tableRows[0], "vgtSelected", true);
        this.$store.dispatch(actionTypes.GET_ALL_CONTRACTS_ACTION, { locale: this.$i18n.locale });
    },
    beforeRouteLeave(to, from, next) {
        this.$unsaveFormDataWarning(this.$i18n, to, from, next, () => this.contractsSelected.length !== this.contracts.length);
    },
};
</script>
<style lang="scss" scoped>
.bgred {
    background-color: red !important;
}
.subtitle {
    color: $blue-dark;
    @include font-size(18);
    @include line-height(26);
    font-weight: $font-primary-bold;
}
.text-subtitle {
    color: $color-secondary;
    @include font-size(16);
    @include line-height(28);
    font-weight: $font-primary-medium;
}
.top-space {
    position: relative;
    top: 3px;
}
.contract {
    margin-bottom: 0px;
}
.space-left {
    margin-left: 11px;
    position: relative;
    top: 4px;
}
.separator-left {
    margin-left: 11px;
}
.text-small {
    @include font-size(12);
    @include line-height(14);
    font-weight: $font-primary-medium;
}
.icon-contrato {
    width: 16px;
    height: 16px;
}
.box-button-save {
    width: 133px;
}
@include respond-below(sm) {
    .box-button-save {
        width: 100%;
    }
}
.img-check {
    width: 18px;
    margin-left: 10px;
}
</style>
<style lang="scss">
.p-contract-selection {
    .m-advance-search {
        .multiselect__select {
            width: 28px;
            height: 32px;
        }
        .multiselect__input {
            margin-top: 5px;
        }
    }
}
.baja-row {
    background-color: #f8d5d3 !important;
}
.text-inactive-contract {
    width: 135px;
    background-color: #f8d5d3;
}
</style>
