<template>
    <b-container v-if="this.hasAnyActiveContract === true">
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.modificationContractSelector.title')" />
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <ab-table-select-contract
                    ref="tableSelectContract"
                    v-if="multicontract"
                    v-model="selectedContract"
                    :modification-type="typeViewModification"
                ></ab-table-select-contract>
                <div class="mb-2">
                    <img class="icon-t" alt="imagen bola telelectura" src="../assets/images/bola-telelectura.png" />
                    <span class="ml-2 text-telelectura">{{ $t("ofex.home.telelectura") }}</span>
                </div>

                <!-- boton -->
                <div class="d-flex flex-row-reverse">
                    <div class="box-next">
                        <ab-button-ofex
                            :disabled="selectedContract == null || selectedContract === ''"
                            :click-event="navigateView"
                            :text-button="$t('ofex.bajaContrato.siguiente')"
                        ></ab-button-ofex>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <!--Main title -->
                <ab-main-title :main-text="this.mainText" />
                <ab-without-data class="mt-3" :text="$t('ofex.modificationContractSelector.noActiveContracts')" />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import AbTableSelectContract from "../modules/contracts/modification/AbTableSelectContract";
import * as actionTypes from "@/store/action-types";
import * as viewMappingTypes from "../router/view-mapping-types";
export default {
    name: "ModificationContractSelector",
    components: {
        AbTableSelectContract,
    },
    data: function () {
        return {
            selectedContract: "",
            typeViewModification: "",
            mainText: "",
        };
    },
    computed: {
        ...mapState(["isLoading", "contracts", "clientData"]),
        ...mapGetters(["multicontract", "hasAnyActiveContract"]),
    },
    mounted() {
        console.debug("Mounted " + this.$route.query.typeViewModification);
        this.typeViewModification = this.$route.query.typeViewModification;
        this.$store.dispatch(actionTypes.GET_ACTIVE_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        this.titleModify();
    },
    updated() {
        console.debug("Updated " + this.$route.query.typeViewModification);
        this.typeViewModification = this.$route.query.typeViewModification;
    },
    /*
       Remember that params or query changes won't trigger enter/leave navigation guards. You can either watch the $route object to react to those changes, or use the beforeRouteUpdate in-component guard.
       https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
     */
    beforeRouteUpdate(to, from, next) {
        console.debug("Before route update from: " + from.query.typeViewModification + " --  to:  " + to.query.typeViewModification);
        this.typeViewModification = to.query.typeViewModification;

        //limpiamos datos de la búsqueda avanzada
        this.$refs.tableSelectContract.$refs.advanceSearch.clearFilter();
        next();
    },
    methods: {
        navigateView: function () {
            this.$router.push({
                name: this.typeViewModification,
                params: { contractId: this.selectedContract },
            });
        },
        titleModify: function () {
            if (this.typeViewModification == viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.name) {
                this.mainText = this.$t("ofex.modificarDatosPago.title");
            } else if (this.typeViewModification == viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.name) {
                this.mainText = this.$t("ofex.modificarDatosCorrespondencia.title");
            } else if (this.typeViewModification == viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name) {
                this.mainText = this.$t("ofex.introduceCedulaHabitabilidad.title");
            } else if (this.typeViewModification == viewMappingTypes.INSERT_IAE_DATA_MAPPING.name) {
                this.mainText = this.$t("ofex.introduceDatosIAE.title");
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.icon-t {
    width: 16px;
    height: auto;
}
.icon-info {
    width: 17px;
    height: auto;
    margin-left: 7px;
}
.text-telelectura {
    @include font-size(12);
    @include line-height(14);
    color: $color-primary;
    font-weight: $font-primary-medium;
}

.box-next {
    @include respond-below(md) {
        width: 100%;
    }
}
</style>
