<template>
    <b-container class="p-user-detail-view">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.UserData.datosUsuario')"></ab-main-title>
                <p class="subtitle">{{ $t("ofex.UserData.visualizaOmodifica") }}</p>
                <div class="dropdown-section" role="tablist" aria-busy="true">
                    <b-card no-body>
                        <b-card-header header-tag="header" role="tab">
                            <div class="dropdown-section-inner" block v-b-toggle.accordion-1 variant="info">
                                <div class="text">
                                    <div variant="tab" class="text-inner">
                                        <font-awesome-icon class="icon" icon="chevron-circle-right"></font-awesome-icon>
                                        <span class="mr-2">{{ $t("ofex.UserData.datosUsuario") }}</span>
                                        <span class="unsaved-text" v-if="$refs.userDataForm && userDataFormUnsaved">{{ $t("ofex.unsavedDataText") }}</span>
                                    </div>
                                </div>
                            </div>
                        </b-card-header>
                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                            <p class="mt-2 subtitle-blue">{{ $t("ofex.UserData.subtitle") }}</p>
                            <ab-user-data ref="userDataForm" />
                        </b-collapse>
                    </b-card>
                    <b-card no-body>
                        <b-card-header header-tag="header" class="" role="tab">
                            <div class="dropdown-section-inner" block v-b-toggle.accordion-2 variant="info">
                                <div class="text">
                                    <div variant="tab" class="text-inner">
                                        <font-awesome-icon class="icon" icon="chevron-circle-right"></font-awesome-icon>
                                        <span class="mr-2">{{ $t("ofex.UserData.cambiarContrasena") }}</span>
                                        <span class="unsaved-text" v-if="$refs.changePasswordValidator && changePasswordFormUnsaved">{{
                                            $t("ofex.unsavedDataText")
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </b-card-header>
                        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                            <b-card-body>
                                <b-card-text>
                                    <ValidationObserver ref="changePasswordValidator" v-slot="{ invalid }">
                                        <div class="dropdown-inner">
                                            <p class="reset-mail espacio-inf">{{ $t("ofex.bajaContratoDetalle.camposMarcados") }}</p>
                                            <b-row>
                                                <b-col class="col-12 col-lg-6 mb-3">
                                                    <ab-input
                                                        autocomplete="current-password"
                                                        id="currentPassword"
                                                        name="currentPassword"
                                                        :label="$t('ofex.UserData.contrasenaActual')"
                                                        :validation="{ required: true }"
                                                        :validation-delay="1000"
                                                        :type="typeInputPassword"
                                                        :css-class-list="['input-v-password']"
                                                        v-model="currentPassword"
                                                        :right-icon-src="rightIconSrc"
                                                        @right-button="toggleShowPassword" /></b-col
                                            ></b-row>

                                            <ab-password
                                                id="newPassword"
                                                v-model="newPassword"
                                                name="ofex-password"
                                                :user-doc="userData.documentNumber"
                                                :username="userData.firstName"
                                                :firstname="userData.firstLastName"
                                                :secondname="userData.secondLastName"
                                                v-bind:input-alias="$t('ofex.UserData.contrasena')"
                                                v-bind:repeat-input-alias="$t('ofex.UserData.repiteContrasena')"
                                                ref="userChangePassword"
                                            >
                                            </ab-password>
                                            <div class="container-buttons mb-3">
                                                <ab-button-ofex
                                                    class="caja-button"
                                                    :text-button="$t('ofex.buttonsOfex.enviar')"
                                                    :click-event="updateUserCredentials"
                                                    :disabled="invalid"
                                                    :show-popover="invalid"
                                                    :popover="$t('formularios.buttom.sendMessage')"
                                                ></ab-button-ofex>
                                            </div>
                                            <ab-text-end></ab-text-end></div
                                    ></ValidationObserver>
                                </b-card-text>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <b-card no-body>
                        <b-card-header header-tag="header" class="" role="tab">
                            <div class="dropdown-section-inner" block v-b-toggle.accordion-3 variant="info">
                                <div class="text">
                                    <div variant="tab" class="text-inner">
                                        <font-awesome-icon class="icon" icon="chevron-circle-right"></font-awesome-icon>
                                        <span class="mr-2">{{ $t("ofex.UserData.bajaUsuario") }}</span>
                                        <span class="unsaved-text" v-if="$refs.unsubscribeUserForm && unsubscribeUserFormUnsaved">{{
                                            $t("ofex.unsavedDataText")
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </b-card-header>
                        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                            <unsubscribe-user ref="unsubscribeUserForm" />
                        </b-collapse>
                    </b-card>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapState } from "vuex";
import AbUserData from "../modules/users/modification/AbUserData.vue";
import UnsubscribeUser from "../modules/users/modification/UnsubscribeUser.vue";
import * as actionTypes from "@/store/action-types";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";

export default {
    name: "UserDataView",
    components: { AbUserData, UnsubscribeUser },
    data: function () {
        return {
            showPassword: false,
            newPassword: "",
            currentPassword: null,
            showCompare: false,
            observations: "",
        };
    },
    methods: {
        toggleShowPassword: function () {
            this.showPassword = !this.showPassword;
        },
        updateUserCredentials: function () {
            let validator = this.$refs.changePasswordValidator;
            validator.validate().then(async (result) => {
                if (result) {
                    this.$store
                        .dispatch(actionTypes.UPDATE_USER_CREDENTIALS, {
                            currentPassword: this.currentPassword,
                            newPassword: this.newPassword,
                            locale: this.clientData.language,
                        })
                        .then(() => this.launchUpdateUserCredentialsChangedToast())
                        .catch((reason) => this.launchUpdateUserCredentialsErrorToast(reason));
                }
            });
        },
        launchUpdateUserCredentialsChangedToast: function () {
            console.log("Lanzando mensaje de OK");
            this.$notifyFormActionToMatomo(true, "user_data_password_modification");
            this.$refs.changePasswordValidator.reset();

            Swal.fire({
                text: this.$i18n.tc("ofex.toasts.credentialsChangedOK"),
                icon: "success",
                confirmButtonText: "OK",
            }).then(() => {
                this.currentPassword = "";
                this.$refs.userChangePassword.cleanPassword();
                this.$root.$emit("bv::toggle::collapse", "accordion-2"); //cerramos collapse de password
            });
        },
        launchUpdateUserCredentialsErrorToast: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "UserDataView" });
            let errorMessage = e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage");
            this.$notifyFormActionToMatomo(false, "user_data_password_modification", errorMessage);
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.credentialsChangedError"),
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
            });
        },
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData"]),
        typeInputPassword: function () {
            return this.showPassword ? "text" : "password";
        },
        userDataFormUnsaved: function () {
            return this.$refs.userDataForm.$refs.userForm.flags.dirty;
        },
        changePasswordFormUnsaved: function () {
            return this.$refs.changePasswordValidator.flags.dirty;
        },
        unsubscribeUserFormUnsaved: function () {
            return this.$refs.unsubscribeUserForm.reason.length > 0;
        },
        rightIconSrc: function () {
            return this.showPassword ? require("../assets/images/svg/eye-slash.svg") : require("../assets/images/svg/eye.svg");
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
    },
    beforeRouteLeave(to, from, next) {
        this.$unsaveFormDataWarning(
            this.$i18n,
            to,
            from,
            next,
            () => this.unsubscribeUserFormUnsaved || this.changePasswordFormUnsaved || this.userDataFormUnsaved
        );
    },
};
</script>
<style lang="scss">
.p-user-detail-view {
    .container-buttons {
        display: flex;
        justify-content: flex-end;

        .btn {
            width: auto;
        }
        @include respond-below(sm) {
            flex-wrap: wrap;

            .btn {
                width: 100%;
                margin-bottom: 15px;
            }
            .link-btn {
                width: 100%;
                text-align: center;
                order: 2;
                margin-right: 0;
            }
        }
    }
    .info-small-text {
        @include font-size(12);
        @include line-height(23);
    }
    .link {
        color: $link-secondary;
        border-bottom: 1px solid $link-secondary;
        font-weight: $font-primary-medium;
        @include font-size(14);
        @include line-height(19);
    }
    .adress {
        color: $link-secondary;
        font-weight: $font-primary-bold;
        @include font-size(14);
        @include line-height(19);
    }
    .subtitle.small {
        @include font-size(14);
    }
    .dropdown-inner {
        margin-top: 15px;
    }
    .dropdown-section {
        border-bottom: 0;
    }
}
.subtitle-blue {
    @include font-size(14);
    @include line-height(28);
    color: $blue-dark;
    font-weight: $font-primary-bold;
}
.input-v-password {
    width: 60%;
    float: left;
    height: 32px;
    border: 1px solid #979797;
    font-weight: $font-primary;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
</style>
<!--TODO rodri/javi esto no sería scoped-->
<style lang="scss">
.unsaved-text {
    color: $warning-color;
}
.p-user-detail-view {
    .textarea-container {
        margin-bottom: 20px;

        label {
            @include font-size(16);
        }
        textarea {
            min-height: 200px;
        }
        .textdownbolagrey {
            @include font-size(12);
            @include line-height(16);
        }
    }
    .select-component {
        .select-container .select {
            border: 1px solid #979797;
        }
        .text-danger {
            margin-top: 5px;
            @include font-size(14);
            @include line-height(16);
        }
    }
}
</style>
<style lang="scss">
#currentPassword,
#newPassword {
    input.inputFormErr + .input-right-button {
        border-right: 1px solid #df0707;
        border-top: 1px solid #df0707;
    }
    .input-right-button {
        border-right: 1px solid #979797;
        border-top: 1px solid #979797;
        border-radius: 0px 8px 8px 0px;
    }
}
</style>
