<template>
    <div class="ab-change-lang-dialog-root"></div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
    name: "AbChangeLangDialog",
    data: function () {
        return {};
    },
    methods: {
        showLangDialog: function (originLang, clientLang) {
            console.debug(`Idioma origen: ${originLang} - Idioma guardado: ${clientLang}`);
            let vueContext = this;
            setTimeout(function () {
                Swal.fire({
                    toast: false,
                    text: vueContext.$i18n.tc("ofex.langModal.questionText"),
                    icon: "question",
                    confirmButtonText: vueContext.$i18n.tc(`ofex.langModal.change`),
                    showCancelButton: true,
                    cancelButtonText: vueContext.$i18n.tc(`ofex.langModal.continue`),
                }).then((result) => {
                    if (result.isConfirmed) {
                        vueContext.$reloadPageLang(clientLang);
                    }
                });
            }, 1500);
        },
    },
    computed: {
        ...mapState(["clientData"]),
    },
    created() {
        let originLang = this.$root.$i18n.locale;
        let clientLang = this.clientData.language;
        console.log(`Idioma origen: ${originLang} - Idioma guardado: ${clientLang}`);
        if (originLang !== clientLang) {
            this.showLangDialog(originLang, clientLang);
        }
    },
};
</script>

<style>
.swal2-popup.swal2-toast .swal2-header {
    margin-right: 15px;
}
</style>
