<template>
    <div class="legend-years-container mb-3">
        <div
            :class="[{ 'item-disabled': !selectedYear.includes(year) }, `legend-year item item${index + 1}`]"
            :key="year"
            v-for="(year, index) in years"
            @click="modelValue = year"
        >
            {{ year }}
        </div>
    </div>
</template>
<script>
import remove from "lodash/remove";

export default {
    model: {
        prop: "modelYears",
        event: "change",
    },
    props: {
        years: {
            type: Array,
            default: function () {
                let lastYear = new Date().getFullYear();
                return [lastYear, lastYear - 1, lastYear - 2];
            },
        },
    },
    data() {
        return {
            selectedYear: [],
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["modelYears"];
            },
            set: function (year) {
                if (this.selectedYear.includes(year) === false) {
                    this.selectedYear.push(year);
                } else {
                    this.selectedYear = remove(this.selectedYear, (n) => n !== year);
                }
                this.$emit("change", this.selectedYear);
            },
        },
    },
    methods: {
        //TODO cambiar por un set computed para que funcione con v-model
        selectYear: function (year) {
            this.selectedYear = year;
        },
    },
    created() {
        this.selectedYear = [...this.years];
    },
};
</script>
<style scoped>
.legend-year {
    cursor: pointer;
}

.item-disabled {
    background: grey !important;
}
</style>
