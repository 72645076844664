"use strict";

import BaseXlsxGenerator from "@/generators/xlsx/base/BaseXlsxGenerator";
import * as xlsx from "xlsx";

export default class UserActionsListXlsxGenerator extends BaseXlsxGenerator {
    constructor(filename, actionRowsArray, i18n, selectedContractNumber, supplyAddress) {
        let actionsSheet = xlsx.utils.json_to_sheet(actionRowsArray, { origin: "A3" });
        xlsx.utils.sheet_add_aoa(actionsSheet, [[i18n.tc("ofex.invoicesTable.numContrato") + " " + selectedContractNumber]], { origin: "A1" });
        xlsx.utils.sheet_add_aoa(actionsSheet, [[i18n.tc("ofex.invoicesTable.direccion") + " " + supplyAddress]], { origin: "A2" });
        xlsx.utils.sheet_add_aoa(actionsSheet, [[i18n.tc("ofex.managementHistory.table.channel")]], { origin: "A3" });
        xlsx.utils.sheet_add_aoa(actionsSheet, [[i18n.tc("ofex.managementHistory.table.init")]], { origin: "B3" });
        xlsx.utils.sheet_add_aoa(actionsSheet, [[i18n.tc("ofex.managementHistory.table.req")]], { origin: "C3" });
        xlsx.utils.sheet_add_aoa(actionsSheet, [[i18n.tc("ofex.managementHistory.table.subject")]], { origin: "D3" });
        xlsx.utils.sheet_add_aoa(actionsSheet, [[i18n.tc("ofex.managementHistory.table.status")]], { origin: "E3" });
        xlsx.utils.sheet_add_aoa(actionsSheet, [[i18n.tc("ofex.managementHistory.table.end")]], { origin: "F3" });

        super(filename, actionsSheet);
    }
}
