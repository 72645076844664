<i18n src="@/components/common/i18n-components.i18n"></i18n>
<template>
    <div>
        <p class="text-normal mt-3">{{ $t("ofex.authorizationsView.subsectionPerPersonSubtitle") }}</p>
        <ab-authorizations-advance-search-by-persons
            v-show="true"
            v-model="filteredPersons"
            ref="authorizationsAdvanceSearch"
            @filter-persons="executeFilter"
            @clear-filter="resetFilter"
            :total="this.contractAuthorizationsByPerson.length"
            :rows="tableRows.length"
        />
        <vue-good-table
            v-if="contracts"
            ref="authorizationsGroupedByPersonsTable"
            :columns="columns"
            :rows="tableRows"
            style-class="vgt-table"
            :select-options="{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                perPageDropdown: [10, 20, 30],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: this.$i18n.tc('table.siguiente'),
                prevLabel: this.$i18n.tc('table.atras'),
                rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                ofLabel: 'de',
                allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                label: 'selector-de-paginas',
                name: 'perPageSelect',
            }"
        >
            <div slot="emptystate" class="text-center textdnormal">
                {{ $t("ofex.tuContrato.noPersons") }}
            </div>
        </vue-good-table>

        <div class="row mt-3 mb-3 justify-content-end">
            <div class="col-lg-4 mb-4">
                <ab-button-ofex :text-button="$t('ofex.buttonsOfex.revokeAuthorization')" :click-event="deleteAuthorizations"></ab-button-ofex>
            </div>
            <div class="col-lg-6">
                <!--  TODO revisar añadir v-show para que solo aparezca el boton cuando no hay ningún check seleccionado-->
                <ab-button-ofex :text-button="$t('ofex.buttonsOfex.manageContracts')" :click-event="navigateToAuthorizationsByPerson"></ab-button-ofex>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import "vue2-datepicker/locale/ca";
import "vue2-datepicker/locale/es";
import "vue2-datepicker/index.css";
import AbAuthorizationsAdvanceSearchByPersons from "@/modules/authorizations/AbAuthorizationsAdvanceSearchByPersons";
import { VueGoodTable } from "vue-good-table";
import * as viewMappingTypes from "@/router/view-mapping-types";
import Swal from "sweetalert2";
import * as actionTypes from "@/store/action-types";

export default {
    name: "",
    components: {
        AbAuthorizationsAdvanceSearchByPersons,
        VueGoodTable,
    },
    data() {
        return {
            show: false,
            filteredPersons: null,
            tableSelectOptions: {
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: "custom-class",
                selectionText: "Autorizaciones Seleccionadas",
                clearSelectionText: this.$t("ofex.tuContrato.limpiar"),
                disableSelectInfo: true, // disable the select info panel on top
            },
        };
    },
    methods: {
        executeFilter: function (filter) {
            if (filter.document) {
                [...this.filteredPersons] = this.contractAuthorizationsByPerson.filter(
                    (person) => person.documentNumber.toLowerCase() == filter.document.toLowerCase()
                );
            }
            if (filter.name) {
                [...this.filteredPersons] = this.contractAuthorizationsByPerson.filter(
                    (person) => person.firstName.toLowerCase().indexOf(filter.name.toLowerCase()) !== -1
                );
            }
            if (filter.email) {
                [...this.filteredPersons] = this.contractAuthorizationsByPerson.filter(
                    (person) => person.email.toLowerCase().indexOf(filter.email.toLowerCase()) !== -1
                );
            }
        },
        resetFilter: function () {
            this.filteredPersons = [...this.contractAuthorizationsByPerson];
        },
        mapPersonToRowEntry: function (person) {
            return {
                documentNumber: person.documentNumber,
                fullName: `${person.firstName} ${person.firstLastName} ${person.secondLastName}`,
                email: person.email,
                phone: person.phone,
            };
        },
        navigateToAuthorizationsByPerson: function () {
            if (this.$refs.authorizationsGroupedByPersonsTable.selectedRows.length === 1) {
                let documentNumber = this.$refs.authorizationsGroupedByPersonsTable.selectedRows[0].documentNumber;
                this.$router.push({ name: viewMappingTypes.AUTHORIZATIONS_BY_PERSON_VIEW_MAPPING.name, params: { documentNumber: documentNumber } });
            } else {
                Swal.fire({
                    showCloseButton: true,
                    showCancelButton: false,
                    text: "Debe seleccionar una sola persona para gestionar sus personas autorizaciones",
                    icon: "warning",
                });
            }
        },

        deleteAuthorizations: function () {
            if (this.$refs.authorizationsGroupedByPersonsTable.selectedRows.length > 0) {
                Swal.fire({
                    text: this.$i18n.tc("ofex.authorizationsView.revokeConfirmation"),
                    icon: "warning",
                    confirmButtonText: "OK",
                    showCancelButton: true,
                    cancelButtonText: this.$i18n.tc("ofex.common.unsavedFormDataCancelText"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        let documentNumbers = this.$refs.authorizationsGroupedByPersonsTable.selectedRows.map((row) => row.documentNumber);
                        this.$store
                            .dispatch(actionTypes.DELETE_AUTHORIZATIONS_ACTION, {
                                documentNumbers: documentNumbers,
                                authorizationType: "CONTRACT",
                                locale: this.$i18n.locale,
                            })
                            .then(() => {
                                this.$store
                                    .dispatch(actionTypes.GET_CONTRACT_AUTHORIZATIONS_BY_PERSON_ACTION, {
                                        locale: this.$i18n.locale,
                                        triggerLoading: false,
                                    })
                                    .then(() =>
                                        Swal.fire({
                                            text: this.$i18n.tc("ofex.authorizationsView.confirmedAuthorizationRevoked"),
                                            icon: "success",
                                            confirmButtonText: "OK",
                                            showCancelButton: false,
                                        })
                                    );
                            })
                            .catch(() => {
                                Swal.fire({
                                    text: this.$i18n.tc("ofex.authorizationsView.missedAuthorizationRevoked"),
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    showCancelButton: false,
                                });
                            });
                    }
                });
            } else {
                Swal.fire({
                    showCloseButton: true,
                    showCancelButton: false,
                    text: "Debe seleccionar al menos un contrato para revocar", //TODO traducir
                    icon: "warning",
                });
            }
        },
    },
    computed: {
        ...mapState(["contracts", "contractAuthorizationsByPerson", "authorizations"]),
        ...mapGetters(["getContractSupplyCompleteAddress"]),
        tableRows: function () {
            return this.filteredPersons !== null
                ? this.filteredPersons.map(this.mapPersonToRowEntry)
                : this.contractAuthorizationsByPerson.map(this.mapPersonToRowEntry);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.UserData.nifLabel"),
                    field: "documentNumber",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$t("ofex.authorizationsView.fullNameColumnName"),
                    field: "fullName",
                },
                {
                    label: this.$t("modules.authorizationsViewByPersons.correoInputLabel"),
                    field: "email",
                },
                {
                    label: this.$t("ofex.authorizationsView.phoneColumnName"),
                    field: "phone",
                },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_CONTRACT_AUTHORIZATIONS_BY_PERSON_ACTION, { locale: this.$i18n.locale, triggerLoading: false });
    },
};
</script>

<style lang="scss" scoped></style>
