import { Preferences } from "@capacitor/preferences";
import { Capacitor } from "@capacitor/core";

async function getPreferenceKeys() {
    if (Capacitor.isNativePlatform()) {
        return await Preferences.keys();
    } else {
        return null;
    }
}

async function hasKey(key) {
    if (Capacitor.isNativePlatform()) {
        let result = await getPreferenceKeys();
        console.log(`Keys: ${result.keys}`);
        return result.keys.includes(key);
    } else {
        return false;
    }
}

async function getPreferenceValue(key) {
    if (Capacitor.isNativePlatform()) {
        let result = await Preferences.get({ key });
        return result.value;
    } else {
        return null;
    }
}

async function savePreference(key, value) {
    if (Capacitor.isNativePlatform()) {
        await Preferences.set({ key, value });
    }
}

export { getPreferenceKeys, hasKey, savePreference, getPreferenceValue };
