<template>
    <div class="m-evolution-invoices box">
        <div class="graphic-container" aria-label="Gráfico de evolución de facturas de consumo real y estimado">
            <ab-invoice-evolution-chart :show-legend="false" :user-invoices="userInvoices" v-if="userInvoices && userInvoices.length > 0" />
            <div class="legend-container">
                <span class="legend item1" aria-label="Consumo real">{{ $t("ofex.invoiceView.facturaConsumoReal") }}</span>
                <span class="legend item2" aria-label="Consumo estimado">{{ $t("ofex.invoiceView.facturaConsumoEstimado") }}</span>
            </div>
        </div>
        <div class="graphic-button-container">
            <ab-button-ofex
                class="pos-button-graphic"
                :click-event="routerNavigateDetailInvoice"
                :text-button="$t('ofex.buttonsOfex.consultaFacturas')"
            ></ab-button-ofex>
        </div>
    </div>
</template>

<script>
import * as viewMappingTypes from "../router/view-mapping-types";
import { mapState, mapGetters } from "vuex";

export default {
    name: "AbEvolutionInvoices",
    data: function () {
        return {};
    },
    props: {
        contract: {
            type: String,
            default: "",
            required: false,
        },
    },
    computed: {
        ...mapState(["userInvoices"]),
        ...mapGetters(["lastInvoice"]),
    },
    methods: {
        routerNavigateDetailInvoice: function () {
            this.$router.push({ name: viewMappingTypes.INVOICES_LIST_MAPPING.name, params: { contractId: this.contract } });
        },
    },
};
</script>

<style lang="scss">
.m-evolution-invoices {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;

    &.box {
        @include respond-above(sm) {
            margin-bottom: 50px;
        }
    }
    .graphic-container {
        flex: 100%;
        margin-bottom: 40px;
        display: block;
        overflow-x: auto;

        @include respond-above(sm) {
            flex: 100%;
            margin-right: 20px;
            margin-bottom: 0;
        }
        .chartjs-render-monitor {
            height: 300px;
        }
    }
    .graphic-button-container {
        flex: 70%;

        @include respond-above(sm) {
            flex: 0 0 30%;
            margin-bottom: -10px;
        }
    }
}
</style>
