"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import moment from "moment";
import { AB_DATETIME_FORMAT, AB_DATETIME_FRIENDLY_FORMAT } from "@/plugins/ab-utils";
const ACTIVITY_CODE = "PS";
export default class UserActionsListPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId) {
        super("AccionesUsuario.pdf", ACTIVITY_CODE, i18n, userId, clientId, null);
    }

    generatePdf(actions, contractSelect, supplyAddress) {
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.managementHistory.title"), "");
        this.generateLineText(this.i18n.tc("ofex.invoicesTable.numContrato") + "  " + contractSelect);
        this.generateLineText(this.i18n.tc("ofex.invoicesTable.direccion") + "  " + supplyAddress);

        var head = [
            [
                `${this.i18n.tc("ofex.managementHistory.table.channel")}`,
                `${this.i18n.tc("ofex.managementHistory.table.init")}`,
                `${this.i18n.tc("ofex.managementHistory.table.req")}`,
                `${this.i18n.tc("ofex.managementHistory.table.subject")}`,
                `${this.i18n.tc("ofex.managementHistory.table.status")}`,
                `${this.i18n.tc("ofex.managementHistory.table.end")}`,
            ],
        ];
        var body = [actions.length];
        actions.forEach(
            (i, index) =>
                (body[index] = [
                    `${i.channel}`,
                    `${moment(i.date, AB_DATETIME_FORMAT).format(AB_DATETIME_FRIENDLY_FORMAT)}`,
                    `${i.numberReq}`,
                    `${i.subject}`,
                    `${i.status}`,
                    `${i.closeDate ? moment(i.closeDate, AB_DATETIME_FORMAT).format(AB_DATETIME_FRIENDLY_FORMAT) : ""}`,
                ])
        );

        this.generateAutoTable(head, body, 6);
        return this.pdf;
    }
}
