<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="row">
        <div class="col-12 col-lg-3 c-iae-type" aria-hidden="true">
            <ab-select
                v-model="iaeType"
                :name="name + '-code'"
                :input-alias="$t('iae.alias.tipoIAE')"
                :placeholder="$t('common.seleccionar')"
                :label="$t('iae.labelTipo')"
                :validation="{ required: isRequired }"
                :data-list="tipoIaeSelectData"
            >
            </ab-select>
        </div>

        <div class="col-12 col-lg-9 box-autocomplete">
            <ab-autocompletable-select
                v-model="iaeCode"
                :name="name + '-code'"
                :label="$t('iae.label')"
                :input-alias="$t('iae.alias.numeroIAE')"
                :placeholder="$t('common.seleccionar')"
                :validation="{ required: isRequired }"
                :data-list="iaeList"
                data-key="code"
                :data-label="dataLabelLocale"
                :readonly="readonly"
            >
            </ab-autocompletable-select>
        </div>
    </div>
</template>

<script>
import AbSelect from "./AbSelect";
import AbAutocompletableSelect from "./AbAutocompletableSelect";

export default {
    name: "AbIaeSelect",
    components: {
        AbSelect,
        AbAutocompletableSelect,
    },
    model: {
        prop: "iaeModel",
        event: "change",
    },
    props: {
        title: String,
        name: String,
        popover: String,
        isRequired: {
            default: true,
            type: Boolean,
        },
        readonly: {
            default: false,
            type: Boolean,
        },
        apiBaseUrl: {
            required: false,
            default: "https://abapimngmnt-pre.azure-api.net/ofex-catalogue-api",
            type: String,
        },
    },
    data: function () {
        return {
            lastTypeCalculated: "",
            iae: {
                type: "",
                code: "",
            },
            tipoIaeSelectData: [
                { key: "I", text: this.$i18n.tc("iae.tipos.industrial") },
                { key: "P", text: this.$i18n.tc("iae.tipos.profesional") },
                { key: "A", text: this.$i18n.tc("iae.tipos.artistico") },
            ],
            iaeList: [],
        };
    },
    computed: {
        iaeType: {
            get: function () {
                let type = this.$attrs["iaeModel"].type;
                // console.log("Getting IAE type" + type);
                if (this.lastTypeCalculated !== type) {
                    this.calculateIae(type); //TODO esto se empieza a llamar en bucle, averiguar causa
                }
                return type;
            },
            set: function (newValue) {
                console.log("Setting IAE type: " + newValue);
                this.iae.type = newValue;
                this.iae.descriptionType = this.findDescTypeIae(newValue);
                this.$emit("change", this.iae);
            },
        },
        iaeCode: {
            get: function () {
                // console.log("Getting IAE value" + this.$attrs["iaeModel"].code);
                let code = this.$attrs["iaeModel"].code;
                return this.findIaeByCode(code);
            },
            set: function (newValue) {
                // console.log("Setting IAE value: " + newValue);
                this.iaeType = newValue.iae;
                this.iae.code = newValue.code;
                this.iae.descriptionES = newValue.descriptionES;
                this.iae.descriptionCA = newValue.descriptionCA;
                this.$emit("change", this.iae);
            },
        },
        dataLabelLocale: function () {
            return "description" + this.$i18n.locale.substring(0, 2).toUpperCase();
        },
    },
    created() {
        this.calculateIae();
    },
    methods: {
        findIaeByCode: function (code) {
            return this.iaeList.find((iae) => iae.code === code);
        },
        findDescTypeIae: function (code) {
            return this.tipoIaeSelectData.filter((iae) => iae.key === code)[0].text;
        },
        calculateIae: async function (type) {
            this.lastTypeCalculated = type;

            let vueContext = this;
            this.$http.get(`${this.apiBaseUrl}/miscellany/catalogue/iae`).then(
                function (response) {
                    vueContext["iaeList"] = response.data.iaeList
                        .filter((value) => value.visible === true)
                        .filter((value) => !type || value.iae === type)
                        .map(function (value) {
                            return {
                                iae: value.iae,
                                code: value.code,
                                descriptionES: `${value.code} - ${value.descriptionES}`,
                                descriptionCA: `${value.code} - ${value.descriptionCA}`,
                            };
                        });
                },
                function (error) {
                    console.error(error);
                }
            );
        },
    },
};
</script>
<style lang="scss" scoped></style>
<style lang="scss">
.box-autocomplete {
    .multiselect__placeholder {
        margin-top: 1px;
        @include respond-below(sm) {
            margin-top: 3px;
        }
    }
    .multiselect__single {
        margin-top: 0px;
        @include font-size(14);
        font-weight: $font-primary-medium;
    }
}
</style>
