<template>
    <div class="img-invoice box">
        <div class="item water-supply">
            <div class="item-inner">
                <div class="box-donut">
                    <ab-invoice-donut-chart :chartdata="waterSupplyDataset" :options="AbImageDetailInvoiceOptions" />
                </div>
                <img class="ml-4" title="Agüas de Barcelona" src="../../assets/images/svg/logo-aguas.png" />
                <span class="number-move porcentage-water-supply">{{ waterSupplyPercentage }}%</span>
            </div>
            <p class="title-block-invoice">{{ $t("ofex.detalleFactura.suministroAgua") }}</p>
            <div class="info">
                <p class="number-contracte" v-html="$t('ofex.detalleFactura.captacion')"></p>
                <p class="water-management" v-html="$t('ofex.detalleFactura.gestionAgues')"></p>
                <p class="buy-water" v-html="$t('ofex.detalleFactura.compraAgua')"></p>
            </div>
        </div>
        <div class="item water-canon">
            <div class="item-inner">
                <div class="box-donut">
                    <ab-invoice-donut-chart :chartdata="waterCanonDataset" :options="AbImageDetailInvoiceOptions" />
                </div>
                <img class="ml-4" title="Agencia Catalana del Agua" src="../../assets/images/svg/logo-ag-cat.png" />
                <span class="number-move porcentage-water-canon">{{ waterCanonPercentage }}%</span>
            </div>
            <p class="title-block-invoice">{{ $t("ofex.detalleFactura.canonAgua") }}</p>
            <div class="info">
                <p class="number-contracte" v-html="$t('ofex.detalleFactura.tributoAgencia')"></p>
                <p class="number-contracte" v-html="$t('ofex.detalleFactura.contribuyeFinanciacion')"></p>
            </div>
        </div>
        <div class="item sewer-rate" v-if="contract && contract.supplyAddress.gbar">
            <div class="item-inner">
                <div class="box-donut">
                    <ab-invoice-donut-chart :chartdata="clavegueramDataset" :options="AbImageDetailInvoiceOptions" />
                </div>
                <img class="ml-4" title="Ajuntament de Barcelona" :src="require(`../../assets/images/ayuntamientos/${contract.supplyAddress.gbar}.png`)" />
                <span class="number-move porcentage-sewer-rate">{{ clavegueramPercentage }}%</span>
            </div>
            <p class="title-block-invoice">{{ $t("ofex.detalleFactura.tasaAlcantarillado") }}</p>
            <div class="info">
                <p class="number-contracte" v-html="$t('ofex.detalleFactura.tasaAyuntamientos')"></p>
            </div>
        </div>
        <div class="item waste-treatment">
            <div class="item-inner">
                <div class="box-donut">
                    <ab-invoice-donut-chart :chartdata="tmtrDataset" :options="AbImageDetailInvoiceOptions" />
                </div>
                <img class="ml-4" title="Área metropolitana de Barcelona" src="../../assets/images/svg/logo-ar-metro.png" />
                <span class="number-move porcentage-waste-treatment">{{ tmtrPercentage }}%</span>
            </div>
            <p class="title-block-invoice">{{ $t("ofex.detalleFactura.tratamientoDeResiduos") }}</p>
            <div class="info">
                <p class="number-contracte" v-html="$t('ofex.detalleFactura.tasaTratamiento')"></p>
            </div>
        </div>
        <div class="item box-iva">
            <div class="item-inner">
                <div class="box-donut">
                    <ab-invoice-donut-chart :chartdata="ivaDataset" :options="AbImageDetailInvoiceOptions" />
                </div>
                <img class="ml-4" title="Agencia Tributaria" src="../../assets/images/svg/logo-ag-tr.png" />
                <span class="number-move porcentage-box-iva">{{ ivaPercentage }}%</span>
            </div>
            <p class="title-block-invoice">IVA</p>
            <div class="info">
                <p class="number-contracte" v-html="$t('ofex.detalleFactura.agenciaTributaria')"></p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import AbInvoiceDonutChart from "../../modules/charts/AbInvoiceDonutChart";

export default {
    name: "AbInvoiceAmountPortions",
    components: {
        AbInvoiceDonutChart,
    },
    props: {
        invoice: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState(["selectedContractNumber"]),
        ...mapGetters(["getInvoiceByInvoiceNumber", "getContractByContractNumber"]),
        consumptionPlusFeeAmount: function () {
            return this.invoice.waterServiceAmount.toFixed(2);
        },
        waterSupplyDataset: function () {
            return {
                datasets: [
                    {
                        data: [this.invoice.amount - this.consumptionPlusFeeAmount, this.consumptionPlusFeeAmount],
                        backgroundColor: ["#d9d9d9", "#68c5e4"],
                    },
                ],
            };
        },
        waterSupplyPercentage: function () {
            return this.percentageAmount(this.consumptionPlusFeeAmount);
        },
        waterCanonDataset: function () {
            return {
                datasets: [
                    {
                        data: [this.invoice.amount - this.invoice.waterCanonAmount, this.invoice.waterCanonAmount],
                        backgroundColor: ["#d9d9d9", "#147eb3"],
                    },
                ],
            };
        },
        waterCanonPercentage: function () {
            return this.percentageAmount(this.invoice.waterCanonAmount);
        },
        clavegueramDataset: function () {
            return {
                datasets: [
                    {
                        data: [this.invoice.amount - this.invoice.clavegueramAmount, this.invoice.clavegueramAmount],
                        backgroundColor: ["#d9d9d9", "#417290"],
                    },
                ],
            };
        },
        clavegueramPercentage: function () {
            return this.percentageAmount(this.invoice.clavegueramAmount);
        },
        tmtrDataset: function () {
            return {
                datasets: [
                    {
                        data: [this.invoice.amount - this.invoice.tmtrAmount, this.invoice.tmtrAmount],
                        backgroundColor: ["#d9d9d9", "#8a8b8a"],
                    },
                ],
            };
        },
        tmtrPercentage: function () {
            return this.percentageAmount(this.invoice.tmtrAmount);
        },
        ivaDataset: function () {
            return {
                datasets: [
                    {
                        data: [this.invoice.amount - this.invoice.ivaAmount, this.invoice.ivaAmount],
                        backgroundColor: ["#d9d9d9", "#555555"],
                    },
                ],
            };
        },
        ivaPercentage: function () {
            return this.percentageAmount(this.invoice.ivaAmount);
        },
        contract: function () {
            return this.getContractByContractNumber(this.selectedContractNumber);
        },
    },
    data: function () {
        return {
            AbImageDetailInvoiceOptions: {
                responsive: true,
                cutoutPercentage: 57,
                maintainAspectRatio: true,
                legend: {
                    display: false,
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                tooltips: {
                    enabled: false,
                },
            },
        };
    },
    mounted() {},
    methods: {
        randomScalingFactor: function () {
            return Math.round(Math.random() * 100);
        },
        percentageAmount: function (specificAmount) {
            return this.invoice.amount !== 0 ? Math.round((specificAmount * 100) / this.invoice.amount) : 0;
        },
        calculatePercentageDataset: function (percentageAmount) {
            return [percentageAmount, 100 - percentageAmount];
        },
    },
};
</script>
<style lang="scss" scoped>
.img-invoice {
    background-image: url("../../assets/images/ofex/detalle-factura/img-factura.png");
    background-repeat: no-repeat;
    background-position: -15px 0;
    padding-top: 25px;

    @include respond-below(sm) {
        background-image: url("../../assets/images/ofex/detalle-factura/responsive-img-invoice.png");
        width: 100%;
        background-repeat: no-repeat;
        height: auto;
        background-position: -50px 0px;
    }
    .item {
        @include respond-below(sm) {
            margin-bottom: 30px;
        }
        .item-inner {
            display: flex;
            align-items: center;
            margin-bottom: 4px;

            .box-donut {
                width: 80px;
                height: 80px;
            }
        }
    }
    .water-supply {
        .info {
            height: 108px;
            @include respond-below(sm) {
                height: auto;
            }
        }
    }
    .water-canon {
        .info {
            height: 112px;
            @include respond-below(sm) {
                height: auto;
            }
        }
    }
    .sewer-rate {
        .info {
            height: 68px;
            @include respond-below(sm) {
                height: auto;
            }
        }
    }
    .waste-treatment {
        .info {
            height: 63px;
            @include respond-below(sm) {
                height: auto;
            }
        }
    }
    .title-block-invoice {
        @include font-size(16);
        @include line-height(22);
        color: $link-primary;
        font-weight: $font-primary-bold;
        margin-bottom: 6px;
    }
    .water-management {
        @include font-size(14);
        @include line-height(21);
        color: $blue-lightest;
        font-weight: $font-primary-semiBold;
        margin-bottom: 0;
    }
    .buy-water {
        @include font-size(14);
        @include line-height(21);
        color: $blue-darker;
        font-weight: $font-primary-semiBold;
    }
    .number-contracte {
        @include font-size(14);
        @include line-height(21);
        color: $color-secondary;
        font-weight: $font-primary-medium;
        margin-bottom: 0;
    }
    .number-move {
        position: relative;
        left: -235px;
        @include font-size(18);
        @include line-height(20);
        color: $link-primary;
        font-weight: $font-primary-bold;
        width: 45px;
        text-align: center;
    }
}
</style>
