<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div :id="name" class="row">
        <div class="col-12">
            <span class="title-final">
                {{ $t("textEnd.titleOne") }}
            </span>
            <p class="text-final">
                {{ $t("textEnd.textOne") }}
            </p>

            <span class="title-final">
                {{ $t("textEnd.titleTwo") }}
            </span>
            <p class="text-final" v-html="$t('textEnd.textSecond')"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: "AbTextEnd",
    props: {
        name: String,
        titleOne: String,
        textOne: String,
        titleTwo: String,
        textTwo: String,
    },
};
</script>
<style lang="scss" scoped>
.title-final {
    font-weight: $font-primary-bold;
    @include font-size(12);
    @include line-height(23);
}
.text-final {
    font-weight: $font-primary-medium;
    @include font-size(12);
    @include line-height(23);
}
</style>
