<template>
    <b-container>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-main-title :main-text="$t('ofex.pagarFactura.pagoCompletado')" />

                <ab-confirmation
                    :title="$t('ofex.pagarFactura.transaccionCompleta')"
                    :confirmation-msg="
                        $t('ofex.pagarFactura.comodidadDomiciliacion', {
                            windowLocationPathname: pathname,
                            contractId: this.payment.contractNumber,
                        })
                    "
                    :confirmation-description="' '"
                    :show-pdf-button="false"
                ></ab-confirmation>

                <div class="line-top">
                    <div class="box-button float-right mt-4">
                        <ab-button-ofex
                            :click-event="routerNavigateHome"
                            class="p-0 pt-2 pb-2"
                            :text-button="$t('ofex.pagarFactura.volverInicio')"
                        ></ab-button-ofex>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import * as viewMappingTypes from "../router/view-mapping-types";
import PaymentSummaryPdfGeneator from "@/generators/pdf/PaymentSummaryPdfGeneator";
// import InvoicesServiceClient from "@/rest/invoicesServiceClient";
// import { includes } from "lodash";
import paymentsServiceClient from "@/rest/paymentsServiceClient";
import { sleep } from "@/plugins/ab-utils";
export default {
    name: "InvoicePaymentCompletedView",
    computed: {
        ...mapState(["clientData", "payment"]),
        ...mapGetters(["clientId", "userId"]),
        pathname: function () {
            return window.location.pathname;
        },
    },
    methods: {
        routerNavigateHome: function () {
            this.$router.push({ name: viewMappingTypes.HOME_VIEW_MAPPING.name });
        },
    },
    async mounted() {
        //Confirmamos el pago en siebel
        if (this.payment != null) {
            console.debug("Empezando proceso de confirmación de pago");

            let pdfGenerator = new PaymentSummaryPdfGeneator(this.$i18n, this.userId, this.clientId, this.payment.contractNumber);

            //obtenemos toda la informacion de las facturas a confirmar/cancelar
            // let unpaidInvoices = await InvoicesServiceClient.getUserInvoices(this.payment.contractNumber, this.clientId, this.$i18n.locale, 0, "DEBT");
            // let invoicesToConfirm = unpaidInvoices.data.filter((i) => includes(this.payment.pendingInvoicesNumbers, `${i.invoiceYear}${i.invoiceNumber}`));

            let invoicesToConfirm = [];

            await sleep(process.env.VUE_APP_PAYMENT_WAIT_TO_CONFIRM);

            pdfGenerator.generatePdf(invoicesToConfirm, this.payment.amount, true);

            await pdfGenerator.uploadPdf(false);

            //Pago completado lanzamos evento
            this.$notifyFormActionToMatomo(true, "completed_payment");

            console.debug("Haciendo petición de confirmación");

            await paymentsServiceClient.confirmationPayment(
                this.clientId,
                this.userId,
                this.payment.contractNumber,
                this.payment.pendingInvoicesNumbers,
                this.clientData.email,
                this.payment.amount,
                this.payment.dsMerchantOrder,
                this.payment.dsMerchantProductDescription,
                "023", //Operation Ok Payment
                pdfGenerator.uploadedDocumentId
            );
        }
    },
};
</script>
<style lang="scss" scoped>
.line-top {
    width: 100%;
    border-top: 1px solid $blue-dark;
}
.box-button {
    width: 238px;
}
</style>
