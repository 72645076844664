<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container>
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" />
        <b-row v-if="person">
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-main-title :main-text="$t('ofex.authorizationsView.title')"></ab-main-title>
                <ab-return />

                <ab-dropdown-section
                    v-model="dropdownVisible"
                    collapse-id="authorizationsByContractSection1"
                    :label="$t('ofex.authorizationsViewByContract.dropdownTitle')"
                >
                    <p class="authorizations-section-subtitle">{{ $t("ofex.authorizationsViewByContract.section1Text") }}</p>
                    <div class="data-section m-3 mb-5">
                        <p class="small-element-blue">
                            {{ $t("ofex.UserData.nifLabel") }}<span class="element-black">{{ person.documentNumber }}</span>
                        </p>
                        <p class="small-element-blue">
                            {{ $t("ofex.authorizationsView.fullNameColumnName") }}<span class="element-black">{{ person.firstName }}</span>
                        </p>
                        <p class="small-element-blue">
                            {{ $t("ofex.detalleContrato.correoElectronico") }}<span class="element-black">{{ person.email }}</span>
                        </p>
                        <p class="small-element-blue">
                            {{ $t("ofex.pdfGenerator.changeOwnership.telefono") }}<span class="element-black">{{ person.phone }}</span>
                        </p>
                    </div>

                    <p class="authorizations-section-subtitle">{{ $t("ofex.authorizationsViewByContract.section2Text") }}</p>
                    <!--                    <ab-authorizations-advance-search-by-contract
                        v-show="true"
                        :show_checkbox="true"
                        ref="authorizationsAdvanceSearch"
                        @filter-contracts="executeFilter"
                        @clear-filter="resetFilter"
                        :total="contracts.length"
                        :rows="tableRows.length"
                    />-->
                    <vue-good-table
                        v-if="contracts"
                        ref="authorizationsGroupedByContractTable"
                        :columns="columns"
                        :rows="tableRows"
                        :rowStyleClass="getRowStyleClass"
                        style-class="vgt-table vgt-table-wrap"
                        :select-options="{ enabled: false }"
                        :sort-options="{
                            enabled: true,
                            initialSortBy: { field: 'registrationDate', type: 'desc' },
                        }"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            perPageDropdown: [10, 20, 30],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: this.$i18n.tc('table.siguiente'),
                            prevLabel: this.$i18n.tc('table.atras'),
                            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                            ofLabel: 'de',
                            allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                            label: 'selector-de-paginas',
                            name: 'perPageSelect',
                        }"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field === 'contractAuth'">
                                <input
                                    :disabled="props.row.status === 'BAIXA'"
                                    type="radio"
                                    :id="'tableradio-' + props.row.contractNumber + '-true'"
                                    :name="'tableradio-' + props.row.contractNumber"
                                    v-model="tableAuthorizatedContracts[props.row.contractNumber]"
                                    :value="true"
                                />
                            </span>
                            <span v-else-if="props.column.field === 'contractUnauth'">
                                <input
                                    type="radio"
                                    :id="'tableradio-' + props.row.contractNumber + '-true'"
                                    :name="'tableradio-' + props.row.contractNumber"
                                    v-model="tableAuthorizatedContracts[props.row.contractNumber]"
                                    :value="false"
                                />
                            </span>
                        </template>
                        <div slot="emptystate" class="text-center textdnormal">
                            {{ $t("ofex.tuContrato.emptyResults") }}
                        </div>
                    </vue-good-table>

                    <div class="ow mt-3 mb-3 justify-content-end">
                        <div v-if="hasAnyInactiveContract === true" class="text-inactive-contract">
                            <p class="text-normal mt-1">{{ $t("ofex.home.inactivos") }}</p>
                        </div>
                    </div>

                    <div class="row mt-3 mb-5">
                        <div class="col-lg-9"></div>
                        <div class="col-lg-3">
                            <ab-button-ofex :click-event="saveStatus" :text-button="$t('ofex.configNotification.saveButton')"></ab-button-ofex>
                        </div>
                    </div>
                </ab-dropdown-section>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.beneficiosTelelectura.name')"
                    :href-left="$t('ofex.relatedContents.beneficiosTelelectura.url')"
                    :image-left="$t('ofex.relatedContents.beneficiosTelelectura.image')"
                    :name-center="$t('ofex.relatedContents.consellEstalvi.name')"
                    :href-center="$t('ofex.relatedContents.consellEstalvi.url')"
                    :image-center="$t('ofex.relatedContents.consellEstalvi.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import AbLeftNavBar from "../modules/common/AbLeftNavBar.vue";
import AbLoading from "../components/AbLoading";
import { VueGoodTable } from "vue-good-table";
import ContractPartialModificationPdfGenerator from "@/generators/pdf/ContractPartialModificationPdfGenerator";
import ContentManagerServiceClient from "@/rest/contentManagerServiceClient";

import * as actionTypes from "../store/action-types";

import { mapGetters, mapState } from "vuex";
import _isEquals from "lodash/isEqual";
import _reduce from "lodash/reduce";
import Swal from "sweetalert2";

export default {
    name: "AuthorizationsByPersonView",
    components: {
        AbLeftNavBar,
        AbLoading,
        VueGoodTable,
    },
    data: function () {
        return {
            activityId: "",
            initialTableAuthorizatedContracts: {}, //esta variable la asignamos para hacer un diff posteriormente
            tableAuthorizatedContracts: {},
            filteredContracts: null,
            dropdownVisible: true,
        };
    },

    methods: {
        executeFilter: function (filter) {
            this.filteredContracts = this.contracts
                .filter((c) => this.contractMunicipalityFilter(c, filter))
                .filter((c) => this.contractStreeNameFilter(c, filter))
                .filter((c) => this.contractNumberFilter(c, filter))
                .filter((c) => this.contractAliasFilter(c, filter))
                .filter((c) => this.contractStatusAuthFilter(c, filter))
                .filter((c) => this.contractStatusNotAuthFilter(c, filter));

            if (filter.auth && filter.notAuth) {
                [...this.filteredContracts] = this.contracts;
            }
        },
        resetFilter: function () {
            this.filteredContracts = [...this.contracts];
        },
        contractMunicipalityFilter: function (c, filter) {
            if (typeof filter.municipality === "undefined" || filter.municipality === null) {
                return true;
            } else {
                return c.supplyAddress.municipality === filter.municipality;
            }
        },
        contractStreeNameFilter: function (c, filter) {
            if (typeof filter.streetName === "undefined" || filter.streetName === null) {
                return true;
            } else {
                return c.supplyAddress.streetName === filter.streetName;
            }
        },
        contractNumberFilter: function (c, filter) {
            if (typeof filter.contractNumber === "undefined" || filter.contractNumber === null) {
                return true;
            } else {
                return c.contractDetail.contractNumber === filter.contractNumber;
            }
        },
        contractAliasFilter: function (c, filter) {
            if (typeof filter.alias === "undefined" || filter.alias === null) {
                return true;
            } else {
                return c.contractDetail.alias === filter.alias;
            }
        },
        contractStatusAuthFilter: function (c, filter) {
            if (typeof filter.auth === "undefined" || filter.auth === null) {
                return true;
            } else {
                return this.person.authorizedContractNumbers.includes(c.contractDetail.contractNumber);
            }
        },
        contractStatusNotAuthFilter: function (c, filter) {
            if (typeof filter.notAuth === "undefined" || filter.notAuth === null) {
                return true;
            } else {
                return !this.person.authorizedContractNumbers.includes(c.contractDetail.contractNumber);
            }
        },
        mapContractToGroupEntry: function (contract) {
            return {
                contractNumber: contract.contractDetail.contractNumber,
                alias: contract.contractDetail.alias,
                supplyAddress: this.getContractSupplyCompleteAddress(contract.contractDetail.contractNumber),
                registrationDate: contract.contractDetail.registrationDate,
                contractAuth: this.getPersonAuthContract(contract.contractDetail.contractNumber, this.person),
                status: contract.contractDetail.supplyStatus,
            };
        },
        getPersonAuthContract: function (contract, person) {
            if (person.authorizedContractNumbers.includes(contract)) return true;
            return false;
        },
        saveStatus: async function () {
            const diff = this.computeAuthorizationChanges();
            let contractMap = diff.map((item) => {
                let key = Object.keys(item)[0];
                return {
                    contractNumber: key,
                    value: item[key],
                };
            });
            console.log(this.clientData, this.person, contractMap);
            this.pdfGenerator = new ContractPartialModificationPdfGenerator(this.$i18n, this.userData.documentNumber, this.clientData.documentNumber, null);
            this.pdfGenerator.generatePdf(this.clientData, this.person, contractMap);
            await this.pdfGenerator.uploadPdfWithActivity(this.activityId, false);
            await ContentManagerServiceClient.commitStorageDocumentsByActivity(this.activityId);
            this.idDocumentoPdf = this.pdfGenerator.uploadedDocumentId;

            this.$store
                .dispatch(actionTypes.UPDATE_PARTIAL_AUTHORIZATIONS, {
                    documentNumber: this.person.documentNumber,
                    firstName: this.person.firstName,
                    firstLastName: this.person.firstLastName,
                    secondLastName: this.person.secondLastName,
                    phone: this.person.phone,
                    email: this.person.email,
                    activityId: this.activityId,
                    idDocumentoPdf: this.idDocumentoPdf,
                    contractMap: contractMap,
                })
                .then((result) => {
                    //recalculamos los contratos iniciales
                    this.initialTableAuthorizatedContracts = { ...this.tableAuthorizatedContracts };
                    console.log(result);
                    Swal.fire({
                        text: this.$t("ofex.authorizationsViewByPersons.operationOk"),
                        icon: "success",
                        confirmButtonText: this.$t("common.okButton"),
                    });
                });
        },
        computeAuthorizationChanges: function () {
            // devuelve las diferencias entre ambos objetos
            return _reduce(
                this.tableAuthorizatedContracts,
                (result, value, key) => (_isEquals(value, this.initialTableAuthorizatedContracts[key]) ? result : result.concat({ [key]: value })),
                []
            );
        },
        getRowStyleClass(row) {
            return row.status.toUpperCase() === "BAIXA" || row.status.toUpperCase() == "BAJA" ? "baja-row" : "";
        },
    },
    computed: {
        ...mapState(["contracts", "contractAuthorizationsByPerson", "authorizationsByContract", "isLoading", "userData", "clientData"]),
        ...mapGetters(["getContractByContractNumber", "getContractSupplyCompleteAddress", "hasAnyInactiveContract"]),
        columns: function () {
            return [
                {
                    label: this.$t("ofex.advanceSearch.authorized"),
                    field: "contractAuth",
                    type: "boolean",
                    sortable: true,
                    tdClass: "first-col",
                    thClass: "first-col",
                    width: "110px",
                },
                {
                    label: this.$t("ofex.advanceSearch.notAuthorized"),
                    field: "contractUnauth",
                    type: "boolean",
                    sortable: false,
                    thClass: "no-sortable-header",
                },
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contractNumber",
                    type: "number",
                    sortable: true,
                    width: "80px",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                    sortable: true,
                    width: "140px",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "supplyAddress",
                    sortable: true,
                    thClass: "short-column",
                },
                {
                    label: this.$t("ofex.tuContrato.registrationDate"),
                    field: "registrationDate",
                    sortable: true,
                    width: "110px",
                },
            ];
        },
        tableRows: function () {
            return this.filteredContracts !== null
                ? this.filteredContracts.map(this.mapContractToGroupEntry)
                : this.contracts.map(this.mapContractToGroupEntry);
        },
        person: function () {
            return this.contractAuthorizationsByPerson.find((entry) => entry.documentNumber === this.$route.params.documentNumber);
        },
    },
    mounted() {
        this.activityId = "AC" + new Date().getTime();
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        this.$store.dispatch(actionTypes.GET_CONTRACT_AUTHORIZATIONS_BY_PERSON_ACTION, { locale: this.$i18n.locale }).then(() => {
            this.contracts
                .map((e) => e.contractDetail.contractNumber)
                .forEach((number) => {
                    this.initialTableAuthorizatedContracts[number] = this.person.authorizedContractNumbers.includes(number);
                    this.tableAuthorizatedContracts[number] = this.person.authorizedContractNumbers.includes(number);
                });
        });
        this.$store.dispatch(actionTypes.GET_AUTHORIZATIONS_BY_CONTRACT_ACTION, { locale: this.$i18n.locale });
    },
};
</script>

<style lang="scss" scoped>
.small-element-blue {
    @include font-size(12);
    @include line-height(14);
    color: $blue-lighter;
    font-weight: $font-primary-bold;
    margin-bottom: 10px;
}

.data-section {
    margin: 0 !important;
    padding: 1rem;
    border-top: 1px solid #26749f;
    border-bottom: 1px solid #26749f;
}

.element-black {
    color: black;
    font-weight: lighter;
    margin-left: 1rem;
}

.no-sortable-header span {
    &:before,
    &:after {
        display: none !important;
    }
}
.baja-row {
    background-color: #f8d5d3 !important;
}
.text-inactive-contract {
    width: 135px;
    background-color: #f8d5d3;
}
</style>
