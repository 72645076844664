<template>
    <nav-bar-renderless
        ref="navBarRenderless"
        v-slot="{
            navigateReadMeterForm,
            navigateUnsuscribeContract,
            navigateIaeData,
            navigateCertificateOccupancy,
            navigateOwnerData,
            navigateCorrespondenceData,
            navigatePaymentData,
            navigateAffectationContractsData,
            selectedContractNumber,
            lastInvoice,
            contractsWithTelerecording,
            isCompany,
            hasAnyDomesticContract,
            hasAnyIndustrialOrComercialContract,
            droughtConfiguration,
        }"
    >
        <b-col cols="12" md="3" class="d-none d-lg-block">
            <div class="nav-left-side">
                <ul class="nav-first-level">
                    <li class="nav-item first-level">
                        <router-link class="nav-link-item" :to="viewMappingTypes.HOME_VIEW_MAPPING.path">{{ $t("ofex.menuLateral.inicio") }}</router-link>
                    </li>
                    <li class="nav-item first-level open">
                        <router-link
                            class="nav-link-item"
                            :to="{ name: viewMappingTypes.INVOICES_LIST_MAPPING.name, params: { contractId: selectedContractNumber } }"
                            >{{ $t("ofex.menuLateral.tusFacturas") }}</router-link
                        >
                        <ul class="nav-second-level">
                            <li class="nav-item second-level">
                                <router-link class="nav-link-item" :to="{ name: viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.name }">{{
                                    $t("ofex.menuLateral.pagarTusFacturas")
                                }}</router-link>
                            </li>
                            <li class="nav-item second-level" v-if="lastInvoice">
                                <router-link
                                    class="nav-link-item"
                                    :to="{
                                        name: viewMappingTypes.INVOICE_DETAIL_VIEW_MAPPING.name,
                                        params: { contractId: selectedContractNumber, invoiceId: lastInvoice.invoiceNumber },
                                    }"
                                    >{{ $t("ofex.menuLateral.entenderFactura") }}</router-link
                                >
                            </li>
                            <li class="nav-item second-level">
                                <router-link
                                    class="nav-link-item"
                                    :to="{
                                        name: viewMappingTypes.EVOLUTION_INVOICES_MAPPING.name,
                                    }"
                                    >{{ $t("ofex.menuLateral.evolucionFacturas") }}</router-link
                                >
                            </li>
                            <li class="nav-item second-level" v-if="multicontract">
                                <router-link
                                    class="nav-link-item"
                                    :to="{
                                        name: viewMappingTypes.LAST_INVOICES_MAPPING.name,
                                    }"
                                    >{{ $t("ofex.menuLateral.ultimasFacturas") }}</router-link
                                >
                            </li>
                            <li class="nav-item second-level">
                                <router-link class="nav-link-item" :to="navigateReadMeterForm()">{{ $t("ofex.menuLateral.lecturaContador") }}</router-link>
                            </li>
                            <li class="nav-item second-level" v-if="rectifyingInvoicesViewEnabled === 'true'">
                                <router-link
                                    class="nav-link-item"
                                    :to="{
                                        name: viewMappingTypes.RECTIFYING_INVOICES_LIST_MAPPING.name,
                                    }"
                                    >{{ $t("ofex.menuLateral.facturasRectificativas") }}</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item first-level">
                        <router-link class="nav-link-item" :to="{ name: viewMappingTypes.INVOICE_DIGITAL_MAPPING.name }">{{
                            $t("ofex.menuLateral.facturaDigital")
                        }}</router-link>
                    </li>
                    <li class="nav-item first-level open">
                        <router-link
                            class="nav-link-item"
                            :to="{ name: viewMappingTypes.CONSUMPTIONS_DETAIL_MAPPING.name, params: { contractId: selectedContractNumber } }"
                            >{{ $t("ofex.menuLateral.tusConsumos") }}</router-link
                        >
                        <ul class="nav-second-level">
                            <li class="nav-item second-level" v-if="contractsWithTelerecording.length > 1">
                                <router-link class="nav-link-item" :to="{ name: viewMappingTypes.LAST_READINGS_DETAIL_MAPPING.name }">{{
                                    $t("ofex.menuLateral.ultimasLecturas")
                                }}</router-link>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item first-level open" v-if="droughtConfiguration?.enabledAll">
                        <router-link
                            class="nav-link-item"
                            :class="{
                                'router-link-exact-active router-link-active': $route.name === viewMappingTypes.AFFECTATIONS_CONTRACTS_MAPPING.name,
                            }"
                            :to="navigateAffectationContractsData()"
                            >{{ $t("ofex.menuLateral.afectaciones") }}</router-link
                        >
                    </li>
                    <li class="nav-item first-level open">
                        <router-link
                            class="nav-link-item"
                            :to="{ name: viewMappingTypes.CONTRACTS_LIST_MAPPING.name, params: { contractId: selectedContractNumber } }"
                            >{{ $t("ofex.menuLateral.tusContratos") }}</router-link
                        >
                        <ul class="nav-second-level">
                            <li class="nav-item second-level">
                                <!-- Si es multicontrato navega al selector de contratos si no directamente a modificar datos de pago-->
                                <router-link
                                    class="nav-link-item"
                                    :class="{
                                        'router-link-exact-active router-link-active': $route.name === viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.name,
                                    }"
                                    :to="navigatePaymentData()"
                                    >{{ $t("ofex.menuLateral.modificar.datosPago") }}</router-link
                                >
                            </li>
                            <li class="nav-item second-level">
                                <router-link
                                    class="nav-link-item"
                                    :class="{
                                        'router-link-exact-active router-link-active':
                                            $route.name === viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.name,
                                    }"
                                    :to="navigateCorrespondenceData()"
                                    >{{ $t("ofex.menuLateral.modificar.datosCorrespondencia") }}</router-link
                                >
                            </li>
                            <li class="nav-item second-level" v-if="isCompany">
                                <router-link
                                    class="nav-link-item"
                                    :class="{
                                        'router-link-exact-active router-link-active': $route.name === viewMappingTypes.MODIFICATION_OWNER_DATA_MAPPING.name,
                                    }"
                                    :to="navigateOwnerData()"
                                    >{{ $t("ofex.menuLateral.modificar.datosTitular") }}</router-link
                                >
                            </li>
                            <li class="nav-item second-level" v-if="hasAnyDomesticContract === true">
                                <router-link
                                    class="nav-link-item"
                                    :class="{
                                        'router-link-exact-active router-link-active':
                                            $route.name === viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name,
                                    }"
                                    :to="navigateCertificateOccupancy()"
                                    >{{ $t("ofex.menuLateral.introduce.cedulaHabitabilidad") }}</router-link
                                >
                            </li>
                            <li class="nav-item second-level" v-if="hasAnyIndustrialOrComercialContract === true">
                                <router-link
                                    class="nav-link-item"
                                    :class="{
                                        'router-link-exact-active router-link-active': $route.name === viewMappingTypes.INSERT_IAE_DATA_MAPPING.name,
                                    }"
                                    :to="navigateIaeData()"
                                    >{{ $t("ofex.menuLateral.introduce.datosIae") }}</router-link
                                >
                            </li>
                            <li class="nav-item second-level">
                                <router-link
                                    class="nav-link-item"
                                    :class="{
                                        'router-link-exact-active router-link-active': $route.name === viewMappingTypes.UNSUSCRIBE_CONTRACT_VIEW_MAPPING.name,
                                    }"
                                    :to="navigateUnsuscribeContract()"
                                    >{{ $t("ofex.menuLateral.bajaContrato") }}</router-link
                                >
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item first-level">
                        <router-link class="nav-link-item" :to="{ name: viewMappingTypes.NOTIFICATIONS_LIST_MAPPING.name }">{{
                            $t("ofex.menuLateral.tusAVisos")
                        }}</router-link>
                    </li>
                    <li class="nav-item first-level" v-if="authorizationsViewEnabled === 'true'">
                        <router-link class="nav-link-item" :to="{ name: viewMappingTypes.AUTHORIZATIONS_VIEW_MAPPING.name }">{{
                            $t("ofex.menuLateral.autorizaciones")
                        }}</router-link>
                    </li>
                    <li class="nav-item first-level">
                        <router-link class="nav-link-item" :to="{ name: viewMappingTypes.USER_ACTIONS_MAPPING.name }">{{
                            $t("ofex.menuLateral.historialGestiones")
                        }}</router-link>
                    </li>
                    <li class="nav-item first-level">
                        <a target="_blank" class="nav-link-item" :href="contrataSuministroURL">{{ $t("ofex.menuLateral.contrataSuministro") }}</a>
                    </li>
                    <li class="nav-item first-level">
                        <router-link class="nav-link-item" :to="{ name: viewMappingTypes.RECLAMATION_FORM_MAPPING.name }">{{
                            $t("ofex.menuLateral.reclamacion")
                        }}</router-link>
                    </li>
                </ul>
            </div>
        </b-col>
    </nav-bar-renderless>
</template>

<script>
import NavBarRenderless from "@/modules/common/NavBarRenderless";
import * as viewMappingTypes from "../../router/view-mapping-types";
import { mapGetters } from "vuex";
export default {
    name: "AbLeftNavBar",
    components: {
        NavBarRenderless,
    },
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
            authorizationsViewEnabled: process.env.VUE_APP_AUTHORIZATIONS_ENABLED,
            rectifyingInvoicesViewEnabled: process.env.VUE_APP_RECTIFYING_INVOICES_ENABLED,
        };
    },

    computed: {
        ...mapGetters(["multicontract"]),
        contrataSuministroURL: function () {
            return process.env[`VUE_APP_ALTA_NUEVA_Y_CAMBIO_TITULAR_${this.$i18n.locale.toUpperCase()}_URL`];
        },
    },
};
</script>

<style lang="scss" scoped>
.nav-left-side {
    margin-top: 88px;

    .nav-first-level {
        .nav-item {
            border-top: 0;
        }
        .nav-item:last-child {
            border-bottom: 0;
        }
    }
    .nav-item {
        border-top: 1px solid $button-bg-secondary;
        border-bottom: 1px solid $button-bg-secondary;

        .nav-link-item {
            background-image: url("../../assets/images/svg/chevron.svg");
            background-repeat: no-repeat;
            background-position: 8px 13px;
            background-size: 13px;
            font-weight: $font-primary-medium;
            color: $color-dark;
            width: 100%;
            display: inline-block;
            padding: 7px 0 7px 28px;
            @include font-size(16);

            &.router-link-exact-active {
                font-weight: $font-primary-bold;
                color: $blue-lighter;
            }
        }
        &.open {
            .nav-second-level {
                display: block;
            }
            > .nav-link-item {
                padding-bottom: 0;
            }
        }
        &:hover {
            .nav-link-item {
                color: $color-primary;
            }
        }
    }
    .nav-second-level {
        display: none;
        padding-left: 35px;

        .nav-item:last-child {
            border-bottom: 0;
        }
    }
}
</style>
