"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import moment from "moment";
export default class ReadMeterPdfGenerator extends BaseGenerator {
    constructor(i18n, user, client, contract) {
        super("LecturaContador.pdf", "", i18n, user, client, contract);
    }
    generatePdf(formModel) {
        console.log(`Generando pdf de baja de lectura contador`);
        const SPACE = " ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.readMeter.title"), "");
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.readMeter.datosPersonales"));

        if (formModel.contactData.idWith === "contrato") {
            this.generateLineText(
                this.i18n.tc("ofex.pdfGenerator.readMeter.identificacionMediante") + SPACE + this.i18n.tc("ofex.pdfGenerator.readMeter.contrato")
            );
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.readMeter.numContrato") + SPACE + formModel.contactData.identificationContract);
        }
        if (formModel.contactData.idWith === "factura") {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.readMeter.identificacionMediante") + SPACE + formModel.contactData.idWith);
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.readMeter.numFactura") + SPACE + formModel.contactData.identificationInvoice);
        }

        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.readMeter.tipoDocumento") + SPACE + formModel.contactData.doc.type);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.readMeter.numDocumento") + SPACE + formModel.contactData.doc.number);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.readMeter.nombre") + SPACE + formModel.contactData.name);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.readMeter.primerApellido") + SPACE + formModel.contactData.lastName);
        if (formModel.contactData.secondLastName) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.segundoApellido") + SPACE + formModel.contactData.secondLastName);
        }
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.tfnoPrincipal") + SPACE + formModel.contactData.mainPhone);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.reclamations.email") + SPACE + formModel.contactData.email);

        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.readMeter.datosLectura"));
        moment.locale("es");
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.readMeter.fechaLectura") + SPACE + moment(formModel.dataRead.dateRead, "Do MMMM YYYY, h:mm:ss a").format("L")
        );

        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.readMeter.lectura") + SPACE + formModel.dataRead.read);
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.readMeter.politicaPrivacidad"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.readMeter.aceptoCondiciones") + SPACE + "Si");
        return this.pdf;
    }
}
