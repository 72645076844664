<template>
    <div class="consent-modal-container">
        <ValidationObserver ref="consentsForm" v-slot="{ reset }">
            <ab-radio
                class="mb-2"
                v-model="updateConsents.advertising"
                name="advertising"
                :validation="{ required: true }"
                :title="$t('ofex.modificarDatosContrato.radio.comunicacionesPromocionales')"
                :description="$t('ofex.modificarDatosContrato.radio.textComunicacionesPromocionales')"
                :commercial-terms="true"
            />
            <ab-radio
                class="mb-2"
                v-model="updateConsents.surveys"
                name="user-data-surveys"
                :validation="{ required: true }"
                :title="$t('ofex.modificarDatosContrato.radio.encuestasAguas')"
                :description="$t('ofex.modificarDatosContrato.radio.textEncuestasAguas')"
                :input-alias="$t('ofex.urConsentModal.encuestas')"
                :commercial-terms="true"
            />
            <ab-radio
                v-model="updateConsents.profiling"
                name="user-data-profiling"
                :validation="{ required: true }"
                :title="$t('ofex.modificarDatosContrato.radio.perfilado')"
                :description="$t('ofex.modificarDatosContrato.radio.textPerfilado')"
                :commercial-terms="true"
            />
        </ValidationObserver>
        <button type="button" @click="validateConsents" class="swal2-confirm swal2-styled" style="display: inline-block;" aria-label="">
            {{ $t("common.saveButton") }}
        </button>
    </div>
</template>

<script>
import { mapState } from "vuex";
import * as viewMappingTypes from "../../router/view-mapping-types";
export default {
    name: "AbConsentsModal",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
            updateConsents: {
                advertising: null,
                surveys: null,
                profiling: null,
            },
        };
    },
    computed: {
        ...mapState(["clientData"]),
    },
    methods: {
        validateConsents: function () {
            let validator = this.$refs.consentsForm;
            validator.validate().then(async (result) => {
                if (result) {
                    this.$emit("consents-accepted", result);
                }
            });
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped></style>
