<i18n src="../../components/common/i18n-components.i18n"></i18n>
<template>
    <div class="mb-5">
        <div class="line-bottom">
            <span class="label-black"
                >{{ $t("ofex.invoicePaymentByContractView.tuContrato") }} <span class="value-address ml-1"> {{ contractAddressHeader }}</span></span
            >
        </div>
        <vue-good-table
            v-if="unpaidInvoices"
            :columns="columns"
            :rows="tableRows"
            style-class="vgt-table"
            class="mb-space-table"
            :class="fatherClass"
            :sort-options="{
                enabled: true,
                initialSortBy: { field: 'issueDate', type: 'asc' },
            }"
            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                perPageDropdown: [10, 20, 30],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: this.$i18n.tc('table.siguiente'),
                prevLabel: this.$i18n.tc('table.atras'),
                rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                ofLabel: 'de',
                allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                label: 'selector-de-paginas',
                name: 'perPageSelect',
            }"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'fullInvoiceNumber'">
                    <span class="pending">{{ props.row.fullInvoiceNumber }}</span>
                </span>
                <span v-else-if="props.column.field == 'status'">
                    <router-link :to="`/tuscontratos/${contractNumber}/tusfacturas/${props.row.invoiceNumber}/pagar-facturas`">
                        <span class="pending">{{ $t("ofex.tuContrato.pagar") }}</span>
                    </router-link>
                    <font-awesome-icon class="icon-pay" icon="arrow-right"></font-awesome-icon>
                </span>
                <div v-else-if="props.column.field == 'consumption'">
                    <span class="text">{{ props.row.consumption }}</span> m<sup>3</sup>
                </div>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
        </vue-good-table>
        <div class="payment-all-invoices d-flex align-items-center">
            <div class="btn-payment-acordion">
                <ab-button-ofex
                    class="btn-white"
                    :font-awesome-icon="'euro-sign'"
                    :text-button="$t('ofex.invoicePaymentByContractView.titleButton')"
                    v-b-toggle="'collapse-payment-method-contract' + contractNumber"
                ></ab-button-ofex>
            </div>
            <div>
                <span class="text-payment-all"
                    >{{ $t("ofex.invoicePaymentByContractView.totalPendiente") }}
                    <span class="number-payment-all">{{ $formatNumber(totalAmount) }}€</span>
                </span>
            </div>
        </div>
        <!--acordion-->
        <b-collapse :id="'collapse-payment-method-contract' + contractNumber" accordion="ab-payment-methods-accordion">
            <div class="box-accordion pl-4 pr-4">
                <ab-payment-methods-accordion
                    :multiple-payment="true"
                    :contract-number="contractNumber"
                    :collapse-name="collapseName"
                    :initial-load-barcode="$parent.visible"
                    :invoices="unpaidInvoiceNumbersWithYear"
                ></ab-payment-methods-accordion>
            </div>
        </b-collapse>
        <div class="line-bottom mt-5"></div>
    </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import InvoicesServiceClient from "@/rest/invoicesServiceClient";
import AbPaymentMethodsAccordion from "./AbPaymentMethodsAccordion";
import { trimBackendCode } from "@/plugins/ab-utils";

export default {
    name: "AbPendingInvoicesByContract",
    components: {
        VueGoodTable,
        AbPaymentMethodsAccordion,
    },
    props: {
        collapseName: Number,
        contractNumber: String,
        fatherClass: {
            type: String,
            default: "",
        },
    },
    data: function () {
        return {
            unpaidInvoices: null,
            contract: null,
        };
    },
    methods: {
        mapInvoiceToTableRow: function (invoiceEntry) {
            return {
                fullInvoiceNumber: `${invoiceEntry.invoiceYear}${invoiceEntry.invoiceNumber}`,
                endDate: invoiceEntry.endDate,
                startDate: invoiceEntry.startDate,
                issueDate: invoiceEntry.issueDate,
                consumption: invoiceEntry.consumptionVolume,
                amount: `${this.$formatNumber(invoiceEntry.amount)} €`,
                status: this.$t(`ofex.backendCodes.invoiceStatus.${trimBackendCode(invoiceEntry.invoiceStatus)}`),
                shippmentType: this.$t("ofex.backendCodes.deliveryType." + invoiceEntry.invoice.toString()),
                invoiceNumber: invoiceEntry.invoiceNumber,
            };
        },
        sortDateColumnIssueDate: function (x, y, col, rowX, rowY) {
            let rowXMoment = moment(rowX.issueDate, "DD-MM-YYYY");
            let rowYMoment = moment(rowY.issueDate, "DD-MM-YYYY");
            return rowXMoment.isBefore(rowYMoment) ? -1 : rowXMoment.isAfter(rowYMoment) ? 1 : 0;
        },
        sortDateColumnEndDate: function (x, y, col, rowX, rowY) {
            let rowXMoment = moment(rowX.endDate, "DD-MM-YYYY");
            let rowYMoment = moment(rowY.endDate, "DD-MM-YYYY");
            return rowXMoment.isBefore(rowYMoment) ? -1 : rowXMoment.isAfter(rowYMoment) ? 1 : 0;
        },
    },
    computed: {
        ...mapState(["clientData"]),
        ...mapGetters(["contractsSummaryList", "getContractByContractNumber", "clientId", "userId"]),
        totalAmount: function () {
            return this.unpaidInvoices ? this.unpaidInvoices.map((i) => i.amount).reduce((a, b) => a + b, 0) : 0;
        },
        contractAddressHeader: function () {
            return this.contractsSummaryList.find((i) => i.key === this.contractNumber).text;
        },
        tableRows: function () {
            return this.unpaidInvoices.map(this.mapInvoiceToTableRow);
        },
        unpaidInvoiceNumbersWithYear: function () {
            return this.unpaidInvoices ? this.unpaidInvoices.map((i) => `${i.invoiceYear}${i.invoiceNumber}`) : [];
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.invoicesTable.columnNames.invoiceNumber"),
                    field: "fullInvoiceNumber",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col nowrap",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.consuptiomUntil"),
                    field: "endDate",
                    tdClass: "second-col",
                    thClass: "nowrap",
                    sortFn: this.sortDateColumnEndDate,
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.issueDate"),
                    field: "issueDate",
                    sortFn: this.sortDateColumnIssueDate,
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.consumption"),
                    field: "consumption",
                    sortFn: function (x, y, col, rowX, rowY) {
                        return Number(rowX.consumption) < Number(rowY.consumption) ? -1 : Number(rowX.consumption) > Number(rowY.consumption) ? 1 : 0;
                    },
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.amount"),
                    field: "amount",
                    sortFn: function (x, y) {
                        let xNumber = Number.parseFloat(x.replace(" €"));
                        let yNumber = Number.parseFloat(y.replace(" €"));
                        return xNumber < yNumber ? -1 : xNumber > yNumber ? 1 : 0;
                    },
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.status"),
                    field: "status",
                    tdClass: "col-pago",
                    thClass: "col-pago",
                    html: true,
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.shippmentType"),
                    field: "shippmentType",
                    tdClass: "col-envio",
                    thClass: "col-envio",
                    html: true,
                },
            ];
        },
    },
    mounted() {
        console.debug("MOUNTED PENDING_INVOICE_BY_CONTRACT");
        let vueContext = this;
        InvoicesServiceClient.getUserInvoices(
            this.contractNumber,
            this.userId,
            this.clientId,
            this.$i18n.locale,
            36,
            "ALL",
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            true
        ).then((serviceResult) => {
            vueContext.unpaidInvoices = serviceResult.data;
        });
        this.contract = this.getContractByContractNumber(this.contractNumber);
    },
};
</script>
<style lang="scss" scoped>
.box-accordion {
    width: 100%;
    height: auto;
    background-color: $bg-grey-light;
}
.payment-all-invoices {
    width: 100%;
    padding: 20px;
    background-color: $bg-grey-light;
    justify-content: space-between;

    @include respond-below(sm) {
        flex-wrap: wrap;
        justify-content: center;

        div:first-child {
            order: 2;
            margin-top: 20px;
        }
    }
}
.btn-payment-acordion {
    display: flex;
    flex-basis: 100%;

    .btn {
        width: auto;

        @include respond-below(sm) {
            width: 100%;
        }
    }
}
.number-payment-all {
    @include font-size(16);
    @include line-height(20);
    color: $error-color;
    font-weight: $font-primary-bold;
}
.text-payment-all {
    @include font-size(16);
    @include line-height(20);
    color: $color-primary;
    font-weight: $font-primary-bold;
    white-space: nowrap;
    padding-left: 20px;
}
.label-black {
    @include font-size(18);
    @include line-height(21);
    color: $color-secondary;
    font-weight: $font-primary-bold;
}
.value-address {
    @include font-size(14);
    @include line-height(16);
    color: $color-secondary;
    font-weight: $font-primary-semiBold;
}
.line-bottom {
    width: 100%;
    border-bottom: 1px solid $blue-lighter;
    padding-bottom: 8px;
}
.p-invoice-payment .mb-space-table {
    margin-bottom: 9px;
}
</style>
