<template>
    <b-container>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-contingencias redirect-url="/"> </ab-contingencias>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import AbContingencias from "../components/AbContingencias";
import { mapGetters } from "vuex";

export default {
    name: "ContingenciasRootView",
    components: { AbContingencias },
    props: {
        showLeftNavbar: {
            default: function () {
                return this.isAuthenticated;
            },
            type: Boolean,
        },
    },
    data: function () {
        return {};
    },
    methods: {},
    computed: {
        ...mapGetters(["isAuthenticated"]),
    },
    mounted() {},
};
</script>
