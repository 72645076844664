<template>
    <!--    <div class="steps-header" v-if="curStep < (stepNames.length + 1) ">-->
    <div class="card-head">
        <!-- <i class="fas fa-chevron-circle-right fa-lg"> -->
        <img class="icon" alt="icono" src="../assets/images/svg/chevron.svg" />
        <p class="mb-0 section-header-h3">{{ sectionName }}</p>
    </div>
</template>

<script>
export default {
    props: {
        sectionName: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.section-header-h3 {
    @include font-size(16);
    @include line-height(26);
    font-weight: $font-primary-bold;
    display: inline;
    color: $blue-dark;
    padding-left: 7px;
    width: 100%;
    text-align: left;
    margin-bottom: 25px !important;
}
.fa-chevron-circle-right {
    color: #189ede;
    cursor: default !important;
}
.card-head {
    border-top: 1px solid $blue-dark;
    padding-top: 10px;
    margin-bottom: 25px !important;
}
.reset-mail {
    @include line-height(23);
    @include font-size(14);
    font-weight: $font-primary;
}
.icon {
    width: 14px;
}
</style>
