<template>
    <b-container class="p-all-notices">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title
                    :main-text="$t('ofex.notificationsView.todosAvisos')"
                    :route-config-notices="true"
                    :right-text="$t('ofex.configNotices.title')"
                ></ab-main-title>
                <div class="select-contract-container">
                    <ab-notifications-advance-search @filter-notifications="executeFilter" @clear-filter="resetFilter" />
                </div>
                <ab-notifications-list
                    :text="$t('ofex.notificationList.sinAvisos')"
                    v-if="internalNotifications"
                    :notifications="internalNotifications"
                    :pagination="true"
                    :page-size="6"
                />
                <ab-config-notices />

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.consellEstalvi.name')"
                    :href-left="$t('ofex.relatedContents.consellEstalvi.url')"
                    :image-left="$t('ofex.relatedContents.consellEstalvi.image')"
                    :name-center="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-center="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-center="$t('ofex.relatedContents.facturaDigital.image')"
                    target-center="_self"
                    :name-right="$t('ofex.relatedContents.afectaciones.name')"
                    :href-right="$t('ofex.relatedContents.afectaciones.url')"
                    :image-right="$t('ofex.relatedContents.afectaciones.image')"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { AB_DATETIME_FORMAT, AB_DATE_FORMAT } from "@/plugins/ab-utils";
import moment from "moment";
import { mapState } from "vuex";
import * as actionTypes from "@/store/action-types";
import isNil from "lodash/isNil";
import { STORE_LOAD_NOTIFICATIONS } from "@/store/mutation-types";

export default {
    name: "NotificationsView",
    data: function () {
        return {
            internalNotifications: null,
        };
    },
    methods: {
        executeFilter: function (filter) {
            console.log(`Filtrando notificaciones ${filter}`);
            this.internalNotifications = this.notifications
                .filter((n) => this.notificationContractFilter(n, filter))
                .filter((n) => this.notificationFrequencyFilter(n, filter))
                .filter((n) => this.notificationTypeFilter(n, filter))
                .filter(
                    (n) =>
                        typeof filter.notificationsDateRange === "undefined" ||
                        this.isDateBetweenRange(n.date, filter.notificationsDateRange[0], filter.notificationsDateRange[1])
                );
        },
        resetFilter: function () {
            this.internalNotifications = [...this.notifications];
        },
        isDateBetweenRange: function (date, start, end) {
            let dateMoment = moment(date, AB_DATETIME_FORMAT);
            let startMoment = moment(start, AB_DATE_FORMAT);
            let endMoment = moment(end, AB_DATE_FORMAT);
            return dateMoment.isBetween(startMoment, endMoment);
        },
        notificationFrequencyFilter: function (n, filter) {
            if (isNil(filter.frequency["PUNTUAL"]) && isNil(filter.frequency["PERIODICO"]) && isNil(filter.frequency["ESPECIAL"])) {
                return true;
            } else {
                return filter.frequency[n.frequency] === true;
            }
        },
        notificationContractFilter: function (n, filter) {
            if (typeof filter.contractNumber === "undefined" || filter.contractNumber === null) {
                return true;
            } else {
                return n.contractNumber === filter.contractNumber;
            }
        },
        notificationTypeFilter: function (n, filter) {
            if (
                isNil(filter.type["LECTURA"]) &&
                isNil(filter.type["FACTURACION"]) &&
                isNil(filter.type["REQUERIMIENTO"]) &&
                isNil(filter.type["CONTRATACION"]) &&
                isNil(filter.type["CONTADOR"]) &&
                isNil(filter.type["AFECTACION_SERVICIO"])
            ) {
                return true;
            } else {
                return filter.type[n.type.type] === true;
            }
        },
    },
    computed: {
        ...mapState(["notifications", "isLoading"]),
    },
    async mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, {
            locale: this.$i18n.locale,
            triggerLoading: false,
            triggerChangeActiveContractLoading: false,
        });
        if (this.notifications.length === 0) {
            await this.$store.dispatch(actionTypes.GET_USER_NOTIFICATIONS_ACTION, { locale: this.$i18n.locale, triggerLoading: true });
        }

        //marcamos para que ya no cargue más las notificaciones en la sesión
        this.$store.commit(STORE_LOAD_NOTIFICATIONS, { loadNotifications: false });

        //asignamos una copia para poder hacer los filtros, etc
        this.internalNotifications = [...this.notifications];
    },
};
</script>
<style lang="scss" scoped>
.p-all-notices {
    .select-contract-container {
        display: flex;
        justify-content: space-between;

        .m-select-your-contract {
            flex-basis: 270px;
        }
    }
}
</style>
<style lang="scss">
.p-all-notices {
    .contract-info-inner {
        @include respond-below(sm) {
            flex-basis: 100%;

            .multiselect {
                margin-top: 20px;
            }
        }
    }
    .m-main-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include respond-below(sm) {
            flex-wrap: wrap;

            .title {
                flex-basis: 100%;
            }
        }
        .right-item {
            position: relative;
            font-weight: $font-primary-bold;
            @include font-size(18);
            @include line-height(30);
            color: $blue-dark;
            margin-bottom: 15px;
            padding-left: 40px;

            &:before {
                position: absolute;
                top: -5px;
                left: 0;
                content: "";
                background-image: url("../assets/images/svg/config.svg");
                background-repeat: no-repeat;
                background-size: 100%;
                width: 28px;
                height: 30px;
            }
        }
    }
    .dropdown-list {
        .item-container .notification-title {
            @include font-size(18);
            @include line-height(30);

            @include respond-below(sm) {
                display: block;
                @include line-height(22);
            }
        }
        .notification-link {
            min-height: 114px;
        }
        .img-container {
            width: 63px;
        }
    }
    .multiselect {
        @include respond-above(sm) {
            width: 160px;
        }
    }
    .multiselect__placeholder {
        margin-top: 1px;

        @include respond-above(sm) {
            margin-top: 0;
        }
    }
    .m-select-your-contract {
        .multiselect__placeholder {
            margin-top: 1px;

            @include respond-above(sm) {
                margin-top: 1px;
            }
        }
        .multiselect__content-wrapper {
            min-width: 250px;
        }
        .select-component {
            @include respond-below(sm) {
                justify-content: flex-start;
                flex-wrap: nowrap;

                .select-label {
                    margin-right: 20px;
                }
            }
        }
    }
}
</style>
