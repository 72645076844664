<script>
import { Bar } from "vue-chartjs";
import moment from "moment";
import { AB_DATE_FORMAT } from "@/plugins/ab-utils";
import { isBrowser } from "mobile-device-detect";
import { executeAccessibilityFixes } from "@/plugins/accesibility";

export default {
    extends: Bar,
    props: {
        userInvoices: {
            type: Array,
            required: true,
        },
    },
    data: function () {
        return {
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
        };
    },
    methods: {
        downloadPdf: function (e, data) {
            if (isBrowser) {
                //TODO sacar a método global de utilidades (duplicado)
                if (data.length > 0) {
                    let activeElement = data[0]._chart.getElementAtEvent(e);
                    console.debug(activeElement);
                    if (activeElement.length > 0) {
                        let selectedInvoice = this.userInvoices[activeElement[0]._index];
                        console.debug(selectedInvoice);
                        this.$downloadInvoice({
                            invoiceNumber: selectedInvoice.invoiceNumber,
                            invoiceYear: selectedInvoice.invoiceYear,
                            locale: this.$i18n.locale,
                        });
                    }
                }
            }
        },
        renderInvoiceEvolutionChart: function () {
            console.log("Creando gráfica de evolucion de facturas");

            let invoicesEvolutionBarOptions = {
                responsive: true,
                maintainAspectRatio: false,
                onClick: this.downloadPdf,
                legend: {
                    display: false,
                    position: "bottom",
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.$t("modules.ConsumptionEvolutionChart.metrosCubicos"),
                            },
                        },
                    ],
                    xAxes: [
                        {
                            offset: true,
                            type: "time",
                            distribution: "series",
                            time: {
                                timeFormat: AB_DATE_FORMAT,
                                tooltipFormat: "ll",
                            },
                            scaleLabel: {
                                display: true,
                            },
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 0,
                                minRotation: 90,
                                mirror: true,
                            },
                        },
                    ],
                },
                // https://zenerab.atlassian.net/browse/UATOFEX-182
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                tooltips: {
                    backgroundColor: "#FFF",
                    borderColor: "#417290",
                    borderWidth: 1,
                    titleFontColor: "#417290",
                    callbacks: {
                        label: function () {
                            return "";
                        },
                        title: function () {
                            return "Descargar factura";
                        },
                    },
                },
            };

            let invoicesEvolutionBarData = {
                datasets: [
                    {
                        data: this.timeConsumptionDataset,
                        hoverBackgroundColor: this.datasetHoverColors,
                        backgroundColor: this.datasetBackgroundColors,
                        label: this.$t("modules.ConsumptionEvolutionChart.facturaConsumoReal"),
                        minBarLength: 5,
                    },
                ],
            };

            this.renderChart(invoicesEvolutionBarData, invoicesEvolutionBarOptions);
        },
    },
    computed: {
        timeConsumptionDataset: function () {
            return this.userInvoices.map((i) => {
                return {
                    x: moment(i.endDate, AB_DATE_FORMAT, "es"),
                    y: i.consumptionVolume,
                };
            });
        },
        datasetBackgroundColors: function () {
            // return this.userInvoices.map((i) => (i.estimatedReading ? "#008f39" : "#417290"));
            return this.userInvoices.map((i) => (i.estimatedReading ? "#417290" : "#417290"));
        },
        datasetHoverColors: function () {
            // return this.userInvoices.map((i) => (i.estimatedReading ? "#31cb6f" : "#95CAEA"));
            return this.userInvoices.map((i) => (i.estimatedReading ? "#95CAEA" : "#95CAEA"));
        },
    },
    watch: {
        userInvoices: function () {
            console.log("Cambiando datos de facturas en gráfico");
            // this._chart.destroy();
            this.renderInvoiceEvolutionChart();
        },
    },
    mounted() {
        this.renderInvoiceEvolutionChart();
        executeAccessibilityFixes();
    },
};
</script>

<style lang="scss"></style>
