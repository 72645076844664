<template>
    <b-container class="p-notice-detail">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row v-if="item">
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.noticeDetail.title')"></ab-main-title>
                <ab-return />
                <div class="m-notices">
                    <ul class="dropdown-list">
                        <li :class="`item ${notificationTypeCss(item.frequency)}`">
                            <div class="notification-link" :to="`/notifications/${item.id}`">
                                <div class="d-flex">
                                    <div class="img-container">
                                        <img :alt="item.type.type" :src="require(`../assets/images/svg/AVISOS/${item.type.categoryCode}.svg`)" />
                                    </div>
                                    <div class="item-container">
                                        <span class="notification-title">{{ item.summary }}</span>
                                        <span class="notice-type">{{ $t("ofex.notificationsView.frequency." + item.frequency) }}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="info">
                        <p class="color-notice-title">
                            {{ $t("ofex.noticeDetail.fecha") }}<span class="text-black"> {{ notificationDate }}</span>
                        </p>
                        <p class="color-notice-title">
                            {{ $t("ofex.noticeDetail.hora") }}<span class="text-black"> {{ notificationHour }}</span>
                        </p>

                        <div v-html="item.detail"></div>
                        <!--                        <div class="btn-container">
                            <ab-button-ofex :click-event="downloadPDF" :font-awesome-icon="'download'" text-button="Descargar PDF"></ab-button-ofex>
                        </div>-->
                        <div v-if="item.metadata.invoiceNumber" class="d-flex justify-content-end">
                            <div class="box-button-download">
                                <ab-button-ofex
                                    class="button-download"
                                    :font-awesome-icon="'download'"
                                    :click-event="downloadInvoice"
                                    :text-button="$t('ofex.buttonsOfex.descargarFactura')"
                                ></ab-button-ofex>
                            </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import { AB_DATETIME_FORMAT, AB_DATE_FORMAT, AB_TIME_FORMAT } from "@/plugins/ab-utils";
import AbReturn from "../components/AbReturn.vue";
import * as actionTypes from "@/store/action-types";
import { STORE_LOAD_NOTIFICATIONS } from "@/store/mutation-types";
export default {
    name: "NotificationsDetailView",
    components: {
        AbReturn,
    },
    data: function () {
        return {
            item: null,
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
        };
    },
    computed: {
        ...mapState(["notifications", "isLoading"]),
        ...mapGetters(["getNotificationById"]),
        notificationDate: function () {
            return moment(this.item.date, AB_DATETIME_FORMAT).format(AB_DATE_FORMAT);
        },
        notificationHour: function () {
            return moment(this.item.date, AB_DATETIME_FORMAT).format(AB_TIME_FORMAT);
        },
    },
    methods: {
        notificationTypeCss: function (frequency) {
            switch (frequency) {
                case "PUNTUAL":
                    return "punctual";
                case "PERIODICO":
                    return "periodic";
                case "ESPECIAL":
                    return "special";
                default:
                    return "";
            }
        },
        downloadInvoice: function () {
            this.$downloadInvoice({
                invoiceNumber: this.item.metadata.invoiceNumber.substring(4),
                invoiceYear: this.item.metadata.invoiceNumber.substring(0, 4),
                contractNumber: this.item.contractNumber,
                locale: this.$i18n.locale,
            });
        },
    },
    async mounted() {
        await this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, {
            locale: this.$i18n.locale,
            triggerLoading: false,
            triggerChangeActiveContractLoading: false,
        });
        if (this.notifications.length === 0) {
            await this.$store.dispatch(actionTypes.GET_USER_NOTIFICATIONS_ACTION, { locale: this.$i18n.locale, triggerLoading: true });
        }

        this.item = this.getNotificationById(this.$route.params.notificationId);
        //marcamos para que ya no cargue más las notificaciones en la sesión
        this.$store.commit(STORE_LOAD_NOTIFICATIONS, { loadNotifications: false });
    },
};
</script>
<style lang="scss" scoped>
.text-black {
    color: $color-secondary;
}
.color-notice-title {
    color: $bg-primary;
}
.box-button-download {
    width: 250px;
    @include respond-below(md) {
        width: 100%;
    }
}
.p-notice-detail {
    .m-notices {
        .notification-link {
            border-top: 1px solid #757575;
            border-bottom: 0;

            .img-container {
                width: 80px;
            }
            .notification-title {
                white-space: break-spaces;
            }
        }
        .info {
            background: $bg-grey-light;
            padding: 40px 25px 25px 25px;
            font-weight: $font-primary-semiBold;
            @include font-size(16);
            @include line-height(19);

            .btn-container {
                justify-content: flex-end;

                .btn {
                    @include respond-above(sm) {
                        width: 250px;
                    }
                }
            }
        }
        .item-container {
            display: flex;
            flex-wrap: wrap;
            align-self: center;

            .notification-title {
                order: 2;
                flex-basis: 100%;
                @include font-size(20);
                @include line-height(24);
            }
        }
    }
}
</style>
<style lang="scss">
.info .titulo td {
    text-align: center;
    font-weight: $font-primary-bold;
    color: $bg-primary;
}
.info .interna tr {
    text-align: center;
    color: $blue-darker;
}
.m-notices .resalta2 {
    color: $blue-medium;
}
.m-notices .resalta {
    color: $bg-primary;
}
</style>
