import store from "@/store/store";
import * as mutationTypes from "@/store/mutation-types";
import { SET_REST_ERROR } from "@/store/mutation-types";
import BaseOfexServiceClient from "./BaseOfexServiceClient";

const MONITOR_BASE_URL = process.env.VUE_APP_API_MONITOR_BASE_URL;

export default {
    checkContingencias: async function (lang = "es") {
        try {
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${MONITOR_BASE_URL}/monitor/contingencies`, {
                params: {
                    lang: lang,
                },
            });
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log(response.data);
                //añadimos o borramos el contrato favorito de la lista local
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },

    getAppRequiredVersions: async function (lang = "es") {
        try {
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${MONITOR_BASE_URL}/monitor/app/required-versions`, {
                params: {
                    lang: lang,
                },
            });
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log(response.data);
                //añadimos o borramos el contrato favorito de la lista local
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
};
