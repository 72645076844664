"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import { trimBackendCode } from "@/plugins/ab-utils";
const ACTIVITY_CODE = "MM";
export default class MailAddressModifyPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId, contract) {
        super("ModificacionEnvioCorrespondecia.pdf", ACTIVITY_CODE, i18n, userId, clientId, contract);
    }
    generatePdf(formModel, contractDetail, selectedContract, userData) {
        const POINTS = ": ";
        const SPACE = "  ";

        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.mailAddress.title"), "");
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.mailAddress.detalleContrato"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.titularContrato") + POINTS + contractDetail.contractHolderData.firstName);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.nifNieTitular") + POINTS + contractDetail.contractHolderData.documentNumber);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.correoElectronico") + POINTS + userData.email);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.numeroContrato") + POINTS + selectedContract);

        let otherAddressData = formModel.supplyAddress.otherAddressData;
        if (otherAddressData == null || otherAddressData == "") {
            otherAddressData =
                formModel.supplyAddress.carrerer.piso + " " + formModel.supplyAddress.carrerer.puerta + " " + formModel.supplyAddress.carrerer.escalera;
        }
        /*
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.mailAddress.direccionCorrespondencia") +
                POINTS +
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(formModel.supplyAddress.carrerer.streetType.code)) +
                SPACE +
                formModel.supplyAddress.carrerer.streetName +
                SPACE +
                formModel.supplyAddress.carrerer.number +
                SPACE +
                otherAddressData +
                SPACE +
                formModel.supplyAddress.carrerer.municipality.name +
                SPACE
        );*/
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.mailAddress.direcccionPostal"), "");
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.provincia") + POINTS + formModel.supplyAddress.carrerer.province.name);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.municipio") + POINTS + formModel.supplyAddress.carrerer.municipality.name);

        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.mailAddress.tipoVia") +
                POINTS +
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(formModel.supplyAddress.carrerer.streetType.code)) +
                SPACE
        );

        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.nombreVia") + POINTS + formModel.supplyAddress.carrerer.streetName);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.numeroVia") + POINTS + formModel.supplyAddress.carrerer.number);
        if (!formModel.sameSupplyAddress) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.piso") + POINTS + formModel.supplyAddress.carrerer.piso);
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.puerta") + POINTS + formModel.supplyAddress.carrerer.puerta);
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.escalera") + POINTS + formModel.supplyAddress.carrerer.escalera);
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.codigoPostal") + POINTS + formModel.supplyAddress.carrerer.cp);
        } else {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.pisoPuerta") + POINTS + formModel.supplyAddress.otherAddressData);
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.codigoPostal") + POINTS + formModel.supplyAddress.carrerer.cp);
        }

        if (contractDetail.contractDetail.musa) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.mailAddress.cesionDatos") + POINTS + (formModel.supplyAddress.musaRadio ? "Si" : "No"));
        }
        return this.pdf;
    }
}
