<template>
    <div>
        <b-container class="p-contract-selection" v-show="multiActiveContract && !showConfigure">
            <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
            <b-row>
                <ab-left-nav-bar />
                <b-col cols="12" md="12" lg="9">
                    <!--Main title -->
                    <ab-main-title :main-text="$t('ofex.configNotification.title')" />
                    <p class="subtitle">{{ $t("ofex.configNotification.subtitleContractsMulti") }}</p>
                    <ab-contracts-advance-search v-model="filteredContracts" :onlyActiveContracts="true" />
                    <!--Tabla contratos -->
                    <vue-good-table
                        :columns="columns"
                        :rows="tableRows"
                        ref="assignation-contract-table"
                        style-class="vgt-table"
                        :select-options="{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            perPageDropdown: [10, 20, 30],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: this.$i18n.tc('table.siguiente'),
                            prevLabel: this.$i18n.tc('table.atras'),
                            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                            ofLabel: 'de',
                            allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                            label: 'selector-de-paginas',
                            name: 'perPageSelect',
                        }"
                    >
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'contrato'">
                                <label :for="props.row.contrato" class="contract" :class="{ pending: props.row.deuda }">{{ props.row.contrato }}</label>
                                <img
                                    v-if="props.row.telectura"
                                    class="icon-contrato"
                                    alt="imagen bola telelectura"
                                    src="../assets/images/bola-telelectura.png"
                                />
                            </div>
                            <div v-line-clamp:20="2" v-else>
                                <span v-tooltip.top-center="props.formattedRow[props.column.field]">{{ props.formattedRow[props.column.field] }}</span>
                            </div>
                        </template>
                        <div slot="emptystate" class="text-center textdnormal">
                            {{ $t("ofex.tuContrato.emptyResults") }}
                        </div>
                    </vue-good-table>
                    <div class="w-100">
                        <div class="box-button-save float-right pt-2">
                            <ab-button-ofex
                                :text-button="$t('ofex.configNotification.next')"
                                ref="bottom-contracts"
                                v-bind:disabled="this.contractsConfig.length == 0 || active"
                                :click-event="configureNotification"
                            ></ab-button-ofex>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container
            class="p-configuration-notices"
            v-if="contractsConfig != null"
            v-show="(!multiActiveContract && hasAnyActiveContract === true) || showConfigure"
        >
            <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
            <b-row>
                <ab-left-nav-bar />
                <b-col cols="12" md="12" lg="9">
                    <ab-main-title :main-text="$t('ofex.configNotification.title')" />
                    <ab-return :custom-back-event="goBackFirstPage" v-if="multiActiveContract" />
                    <ab-return v-else />
                    <p class="subtitle" v-if="!multiActiveContract">{{ $t("ofex.configNotification.subtitleMono") }} {{ selectedContractNumber }}.</p>
                    <p class="subtitle" v-else>{{ $t("ofex.configNotification.subtitleMulti") }}</p>
                    <div class="box-info">
                        <div class="info">
                            <span class="phone-text">
                                {{ $t("ofex.configNotification.phone") }} <span class="phone">{{ userData.mainPhone }}</span>
                            </span>
                            <span class="email-text">
                                {{ $t("ofex.configNotification.email") }}<span class="email">{{ userData.email }}</span>
                            </span>
                        </div>
                        <router-link :to="`/datos-usuario`" class="link">
                            {{ $t("ofex.configNotification.modifyUser") }}
                        </router-link>
                    </div>
                    <div class="notification-config-table">
                        <div class="notification-config-row title-row">
                            <div class="first-col"></div>
                            <div class="second-col">{{ $t("ofex.configNotification.advises") }}</div>
                            <div class="checkbox-col">
                                <span>{{ $t("ofex.configNotification.smsAll") }}</span>
                                <div class="checkbox-container">
                                    <ab-checkbox :label="$t('ofex.configNotification.label')" name="sms" v-model="filter.allsms" />
                                    <img class="icon" alt="icono sms" src="../assets/images/svg/comment-dots.svg" />
                                </div>
                            </div>
                            <div class="checkbox-col">
                                <span>{{ $t("ofex.configNotification.emailAll") }}</span>
                                <div class="checkbox-container">
                                    <ab-checkbox :label="$t('ofex.configNotification.label')" name="email" v-model="filter.allemails" />
                                    <img class="icon" alt="icono" src="../assets/images/svg/envelope.svg" />
                                </div>
                            </div>
                        </div>

                        <div v-for="(itemCode, codeIndex) in configNotificationsList" v-bind:key="itemCode.key">
                            <div
                                :class="`item ${notificationBoxCss(categoryIndex, itemCode.categoryNotificationList.length)} `"
                                v-for="(itemCategory, categoryIndex) in itemCode.categoryNotificationList"
                            >
                                <div v-if="categoryIndex == 0" class="first-col">
                                    {{ $t("ofex.backendCodes.notifications." + itemCode.code) }}
                                </div>
                                <div v-else class="first-col"></div>
                                <div class="second-col d-flex" :id="buildPopover(itemCategory.categoryId)">
                                    {{ $t("ofex.backendCodes.notifications.categories." + itemCategory.categoryId + ".message") }}
                                    <font-awesome-icon
                                        class="icon lecture-popover ml-1 mt-1"
                                        v-if="hasPopover(itemCategory.categoryId)"
                                        :name="buildPopover(itemCategory.categoryId)"
                                        :icon="'exclamation-circle'"
                                        v-b-popover.hover.right="{
                                            customClass: 'my-popover-class',
                                            variant: 'light',
                                            content: buildPopover(itemCategory.categoryId),
                                        }"
                                        variant="info"
                                        title=""
                                    ></font-awesome-icon>
                                </div>
                                <div class="checkbox-col">
                                    <div class="d-flex checkbox-container">
                                        <ab-checkbox
                                            :label="$t('ofex.configNotification.label')"
                                            v-model="configNotificationsList[codeIndex].categoryNotificationList[categoryIndex].sms"
                                        />
                                        <img class="icon" alt="icono" src="../assets/images/svg/comment-dots.svg" />
                                    </div>
                                </div>
                                <div class="checkbox-col">
                                    <div class="d-flex checkbox-container">
                                        <ab-checkbox
                                            v-if="hasPopover(itemCategory.categoryId)"
                                            :label="$t('ofex.configNotification.label')"
                                            v-model="configNotificationsList[codeIndex].categoryNotificationList[categoryIndex].mail"
                                        />
                                        <ab-checkbox
                                            v-else
                                            :label="$t('ofex.configNotification.label')"
                                            v-model="configNotificationsList[codeIndex].categoryNotificationList[categoryIndex].mail"
                                        />
                                        <img class="icon" alt="icono" src="../assets/images/svg/envelope.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="button-container">
                        <ab-button-ofex :click-event="modify" :disabled="active" :text-button="$t('ofex.configNotification.saveButton')"></ab-button-ofex>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="p-configuration-notices" v-if="hasAnyActiveContract === false">
            <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
            <b-row>
                <ab-left-nav-bar />
                <b-col cols="12" md="12" lg="9">
                    <ab-main-title :main-text="$t('ofex.configNotification.title')" />
                    <ab-without-data class="mt-3" :text="$t('ofex.configNotification.noActiveContracts')" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { trimBackendCode } from "@/plugins/ab-utils";
import { mapGetters, mapState } from "vuex";
import * as actionTypes from "@/store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import Swal from "sweetalert2";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import NotificationsServiceClient from "@/rest/notificationsServiceClient";
import i18n from "@/i18n";
import AbContractsAdvanceSearch from "../modules/AbContractsAdvanceSearch";
import { validate } from "vee-validate";
import * as viewMappingTypes from "../router/view-mapping-types";
import * as viewMappings from "@/router/view-mapping-types";
export default {
    name: "NotificationConfigurationView",
    components: { AbContractsAdvanceSearch },
    data() {
        return {
            filteredContracts: null,
            contract: null,
            filter: {},
            showConfigure: false,
            configNotificationsList: [],
            contractsConfig: [],
            originalCheckedRowsSize: 0,
            viewMappingTypes: viewMappingTypes,
            active: false,
            mailValid: false,
        };
    },
    computed: {
        ...mapState(["activeContracts", "isLoading", "userData", "selectedContractNumber"]),
        ...mapGetters(["multiActiveContract", "clientId", "userId", "activeContractsWithTelerecording", "isCompany", "hasAnyActiveContract"]),

        tableRows: function () {
            return this.filteredContracts !== null
                ? this.filteredContracts.map(this.mapContractToTableRow)
                : this.activeContracts.map(this.mapContractToTableRow);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contrato",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                    tdClass: "",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "suministro",
                    tdClass: "col-suministro",
                    thClass: "col-suministro",
                },

                {
                    label: this.$t("table.poblacion"),
                    field: "municipio",
                    tdClass: "col-municipio",
                    thClass: "col-municipio",
                },
            ];
        },
        contractsSelected: function () {
            return this.$refs["assignation-contract-table"].selectedRows;
        },
        hasContractsBothTelerecording: function () {
            return this.activeContractsWithTelerecording.length != this.activeContracts.length ? true : false;
        },
    },
    methods: {
        goBackFirstPage: function () {
            this.showConfigure = false;
        },
        configureNotification: function () {
            //this.contractsAssign = this.$refs["assignation-contract-table"].selectedRows;
            this.showConfigure = true;
        },
        notificationBoxCss: function (index, length) {
            if (length == 0) {
                return "notification-config-row border-gray";
            } else {
                if (index == length - 1) {
                    return "notification-config-row border-gray";
                } else {
                    return "notification-config-row";
                }
            }
        },
        hasPopover: function (code) {
            if ((code == "A001" || code == "A004") && this.hasContractsBothTelerecording) {
                return true;
            } else if (code == "E002") {
                return true;
            }
            return false;
        },
        buildPopover: function (code) {
            return i18n.tc("ofex.backendCodes.notifications.categories." + code + ".popover");
        },
        getActive: function (code) {
            if (this.contractsConfig != null && !this.multiActiveContract) {
                return this.contractsConfig[0] != null ? this.contractsConfig[0].active : false;
            } else if (this.contractsConfig != null && this.multiActiveContract && this.contractsConfig.length > 0) {
                return this.contractsConfig.filter((value) => value.contractNumber === code)[0] != null
                    ? this.contractsConfig.filter((value) => value.contractNumber === code)[0].active
                    : false;
            } else {
                return false;
            }
        },
        getContract: function () {
            if (this.multiActiveContract) {
                this.contractsConfig[0].contractNumber;
            }
        },
        mapContractToTableRow: function (contractEntry) {
            return {
                yearInvoice: contractEntry.contractDetail.invoiceYear,
                contrato: contractEntry.contractDetail.contractNumber,
                alias: contractEntry.contractDetail.alias ? contractEntry.contractDetail.alias : "",
                suministro:
                    this.$i18n.tc(`ofex.backendCodes.streetTypes.${trimBackendCode(contractEntry.supplyAddress.streetType)}`) +
                    " " +
                    contractEntry.supplyAddress.streetName +
                    " " +
                    contractEntry.supplyAddress.number +
                    ", " +
                    contractEntry.supplyAddress.otherAddressData,
                municipio: contractEntry.supplyAddress.municipality,
                vgtSelected: this.getActive(contractEntry.contractDetail.contractNumber),
            };
        },
        validateMobilePhone: function (value) {
            return validate(value, "spanishMobile");
        },
        validateEmail: function (value) {
            return validate(value, "email");
        },
        modify: function () {
            console.debug("Modificación de correspondecia contrato:" + this.selectedContract);
            let contractsAdd = [];
            this.showConfigure = false;
            let ids = this.contractsSelected.map((value) => value.contrato);
            this.contractsConfig.filter((contract) => {
                ids.includes(contract.contractNumber)
                    ? contractsAdd.push({ contractNumber: contract.contractNumber, active: true })
                    : contractsAdd.push({ contractNumber: contract.contractNumber, active: false });
            });
            //this.contractsConfig.filter((i) => (this.contractsConfig.includes(i.contractNumber) ? ([0].active = true) : ([0].active = false)));
            this.$store
                .dispatch(actionTypes.UPDATE_CONFIG_NOTIFICATIONS_ACTION, {
                    clientId: this.clientId,
                    userId: this.userId,
                    locale: this.$i18n.locale,
                    configNotificationsList: this.configNotificationsList,
                    contractsDataList: contractsAdd,
                })
                .then((value) => {
                    this.$notifyFormActionToMatomo(true, "config_notificacion");
                    Swal.fire({
                        text: this.$t("formularios.configuracionAvisos.sendMessage"),
                        icon: "success",
                        confirmButtonText: this.$t("ofex.configNotification.okButton"),
                    }).then(() => {
                        console.debug("Datos Modificados" + value);
                        this.contractsAdd = null;
                    });
                })
                .catch((reason) => {
                    this.$notifyFormActionToMatomo(false, "config_notificacion");
                    this.launchUpdateNotifications(reason);
                });
        },
        checkPhoneEmail: async function () {
            let isMobilePhoneValid = (await this.validateMobilePhone(this.userData.mainPhone)).valid;
            let isEmailValid = (await this.validateEmail(this.userData.email)).valid;
            let message =
                !isMobilePhoneValid && !isEmailValid
                    ? this.$i18n.t("ofex.configNotification.error.phoneEmail", {
                          windowLocationPathname: this.$windowLocationPathname,
                          modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                      })
                    : !isMobilePhoneValid
                    ? this.$i18n.t("ofex.configNotification.error.phone", {
                          windowLocationPathname: this.$windowLocationPathname,
                          modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                      })
                    : !isEmailValid
                    ? this.$i18n.t("ofex.configNotification.error.email", {
                          windowLocationPathname: this.$windowLocationPathname,
                          modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                      })
                    : null;

            if (message != null) {
                this.active = true;
                Swal.fire({
                    html: message,
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        },
    },
    launchUpdateNotifications: function (e) {
        console.log("Lanzando mensaje de error");
        this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "NotificationConfigurationView" });
        Swal.fire({
            titleText: this.$i18n.tc("ofex.configNotification.changedError"),
            text: e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
            icon: "error",
            confirmButtonText: "OK",
        });
    },
    watch: {
        "filter.allsms": function (val) {
            this.configNotificationsList.filter((i) =>
                i.categoryNotificationList.forEach(function (e) {
                    e.sms = val;
                })
            );
        },
        "filter.allemails": function (val) {
            this.configNotificationsList.filter((i) =>
                i.categoryNotificationList.forEach(function (e) {
                    e.mail = val;
                })
            );
        },
    },
    mounted() {
        this.$store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: "NotificationConfigurationView" });
        NotificationsServiceClient.getConfigUserNotifications(this.clientId, this.userId, this.$i18n.locale).then((serviceResult) => {
            this.configNotificationsList = serviceResult.configNotificationsList;
            this.contractsConfig = serviceResult.contractsDataList != null ? serviceResult.contractsDataList : [];
            this.originalCheckedRowsSize = this.contractsConfig != null ? this.contractsConfig.filter((x) => x.active).length : 0;
            this.$store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: "NotificationConfigurationView" });
            this.mailValid = this.validateEmail(this.userData.email);
            this.checkPhoneEmail();
        });
        this.$store.dispatch(actionTypes.GET_ACTIVE_CONTRACTS_ACTION, {
            locale: this.$i18n.locale,
            triggerLoading: false,
            triggerChangeActiveContractLoading: false,
        });
        if (!this.multiActiveContract && this.activeContracts.length > 0) {
            this.contract = this.activeContracts[0].contractDetail.contractNumber;
            this.contractsConfig = this.activeContracts;
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$unsaveFormDataWarning(
            this.$i18n,
            to,
            from,
            next,
            () => this.showConfigure === true || this.contractsSelected.length !== this.originalCheckedRowsSize
        );
    },
};
</script>
<style lang="scss" scoped>
.lecture-popover {
    color: $border-table;
}
.my-popover-class {
    background-color: black !important;
    border-radius: 4px;
    opacity: 0.9 !important;
    color: white !important;
}

.subtitle {
    color: $blue-dark;
    @include font-size(18);
    @include line-height(26);
    font-weight: $font-primary-bold;
}
.text-subtitle {
    color: $color-secondary;
    @include font-size(16);
    @include line-height(28);
    font-weight: $font-primary-medium;
}
.top-space {
    position: relative;
    top: 3px;
}
.contract {
    margin-bottom: 0px;
}
.space-left {
    margin-left: 11px;
    position: relative;
    top: 4px;
}
.separator-left {
    margin-left: 11px;
}
.text-small {
    @include font-size(12);
    @include line-height(14);
    font-weight: $font-primary-medium;
}
.icon-contrato {
    width: 16px;
    height: 16px;
}
.box-button-save {
    width: 133px;
}
@include respond-below(sm) {
    .box-button-save {
        width: 100%;
    }
}
</style>
<style lang="scss">
.p-contract-selection {
    .m-advance-search {
        .multiselect__select {
            width: 28px;
            height: 32px;
        }
        .multiselect__input {
            margin-top: 5px;
        }
    }
}

.p-configuration-notices {
    .contract-number {
        color: $color-primary;
    }
    .box-info {
        background: $bg-grey-light;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 40px;

        .link {
            font-weight: $font-primary-medium;
            @include font-size(14);
            @include line-height(16);
            color: $blue-lighter;
            text-decoration: underline;
        }
        .info {
            display: flex;
            flex-wrap: wrap;
            font-weight: $font-primary-bold;
            @include font-size(14);
            @include line-height(16);

            @include respond-above(sm) {
                flex-basis: 100%;
                margin-bottom: 20px;
            }
            @include respond-above(lg) {
                flex-basis: auto;
                margin-bottom: 0;
            }

            .phone-text {
                margin-right: 50px;
                white-space: nowrap;

                @include respond-below(sm) {
                    flex-basis: 100%;
                    margin-bottom: 20px;
                }
            }
            .email-text {
                @include respond-below(sm) {
                    flex-basis: 100%;
                    margin-bottom: 20px;
                }
            }
            .phone,
            .email {
                font-weight: $font-primary-medium;
                white-space: nowrap;
            }
        }
    }
    .notification-config-table {
        margin-bottom: 40px;

        @include respond-below(sm) {
            overflow-x: auto;
            position: relative;

            .notification-config-row {
                width: 600px;
            }
        }

        .notification-config-row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            padding-bottom: 10px;

            &.title-row {
                border-bottom: 1px solid $blue-dark;

                .second-col {
                    align-self: flex-end;
                    @include font-size(16);
                    @include line-height(28);
                    color: $blue-dark;
                    font-weight: $font-primary-bold;
                }
            }
            &.border-gray {
                border-bottom: 1px solid $border-table;
            }

            .first-col {
                flex-basis: 353px;
                @include font-size(18);
                @include line-height(28);
                color: $blue-dark;
                font-weight: $font-primary-bold;
                margin-right: 15px;
            }
            .second-col {
                flex-basis: 317px;
                @include font-size(16);
                @include line-height(28);
                color: $color-primary;
                font-weight: $font-primary-bold;
                margin-right: 15px;
            }
            .checkbox-col {
                flex-basis: 110px;

                .icon {
                    width: 20px;
                    margin-left: 5px;
                }
                .checkbox-container {
                    display: flex;
                }
                span {
                    display: inline-block;
                    @include font-size(12);
                    @include line-height(17);
                    color: $color-form-text;
                    font-weight: $font-primary-semiBold;
                }
            }
        }
    }
    .button-container {
        justify-content: flex-end;
        display: flex;

        .btn {
            flex-basis: 160px;
        }
    }
}
</style>
