<template>
    <div class="legend-table-container box">
        <table class="table legend-table-graphic">
            <thead>
                <tr>
                    <th>{{ $t("evolucionFacturas.any") }}</th>
                    <th>{{ $t("evolucionFacturas.min") }}</th>
                    <th>{{ $t("evolucionFacturas.media") }}</th>
                    <th>{{ $t("evolucionFacturas.max") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="year in yearsWithData">
                    <td>
                        <span :class="`year item${originalYears.indexOf(year) + 1}`"></span>
                        {{ year }}
                    </td>
                    <td>{{ $formatNumber(min(year)) }}</td>
                    <td>{{ $formatNumber(average(year)) }}</td>
                    <td>{{ $formatNumber(max(year)) }}</td>
                </tr>
            </tbody>
        </table>
        <div class="item"></div>
        <div></div>
        <div></div>
    </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import InvoiceEvolutionChartListXlsxGenerator from "@/generators/xlsx/InvoiceEvolutionChartListXlsxGenerator";
import moment from "moment";
import { AB_DATE_FORMAT } from "@/plugins/ab-utils";

export default {
    name: "AbChartSummaryTable",
    props: {
        rawList: {
            type: Array,
            required: true,
        },
        concept: {
            type: String,
            required: true,
        },
        years: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            originalYears: null,
        };
    },
    methods: {
        min: function (year) {
            return Math.min(...this.groupedByYearDataset[year].map((x) => x[this.concept]));
        },
        max: function (year) {
            return Math.max(...this.groupedByYearDataset[year].map((x) => x[this.concept]));
        },
        average: function (year) {
            let list = this.groupedByYearDataset[year].map((x) => x[this.concept]);
            return list.reduce((previous, current) => (current += previous)) / list.length;
        },
        generateExcelLastReadingsList: function () {
            let xlsxGenerator = new InvoiceEvolutionChartListXlsxGenerator(`Evolución de tu factura.xlsx`, this.years, this.groupedByYearDataset, this.$i18n);
            console.log(xlsxGenerator);
        },
    },
    computed: {
        groupedByYearDataset: function () {
            return groupBy(this.rawList, (i) => moment(i.endDate, AB_DATE_FORMAT, "es").year());
        },
        yearsWithData: function () {
            // return Object.keys(this.groupedByYearDataset).filter((k) => typeof this.groupedByYearDataset[k] !== undefined);
            return this.years.filter((k) => typeof this.groupedByYearDataset[k] !== "undefined").sort();
        },
    },
    created() {
        this.originalYears = [...this.years];
    },
};
</script>

<style scoped></style>
