/**
 * ValidateSpanishID. Returns the type of document and checks its validity.
 *
 * Usage:
 *     ValidateSpanishID( str );
 *
 *     > ValidateSpanishID( '12345678Z' );
 *     // { type: 'dni', valid: true }
 *
 *     > ValidateSpanishID( 'B83375575' );
 *     // { type: 'cif', valid: false }
 *
 * The algorithm is adapted from other solutions found at:
 * - http://www.compartecodigo.com/javascript/validar-nif-cif-nie-segun-ley-vigente-31.html
 * - http://es.wikipedia.org/wiki/C%C3%B3digo_de_identificaci%C3%B3n_fiscal
 */

"use strict";

const DNI_REGEX = /^(\d{8})([A-Z])$/;
const SPECIAL_NIF_REGEX = /^([KLM])(\d{7})([A-Z])$/;
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

export default function validateSpanishDoc(str, allowCif, allowNifNie) {
    console.debug("Validating document " + str, " , empresa: " + allowCif);

    // Ensure upcase and remove whitespace
    str = str.toUpperCase().replace(/\s/, "");

    var valid = false;
    var type = spainIdType(str);

    switch (type) {
        case "NIF":
        case "SPECIAL_NIF":
            valid = allowNifNie && (validDNI(str) || validSpecialNIF(str));
            type = "NIF";
            break;
        case "NIE":
            valid = allowNifNie && validNIE(str);
            break;
        case "CIF":
            valid = allowCif && validCIF(str);
            break;
    }

    const allowedTypes = [];
    if (allowCif) {
        allowedTypes.push("CIF");
    } else if (allowNifNie) {
        allowedTypes.push("NIF");
        allowedTypes.push("NIE");
    }

    return {
        type: type === "SPECIAL_NIF" ? "NIF" : type,
        data: {
            allowedTypes: allowedTypes,
        },
        valid: valid,
    };
}

var spainIdType = function (str) {
    if (str.match(DNI_REGEX)) {
        return "NIF";
    }
    if (str.match(SPECIAL_NIF_REGEX)) {
        return "SPECIAL_NIF";
    }
    if (str.match(CIF_REGEX)) {
        return "CIF";
    }
    if (str.match(NIE_REGEX)) {
        return "NIE";
    }
};

const validDNI = function (dni) {
    var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    var letter = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter === dni.charAt(8);
};

const validNIE = function (nie) {
    // Change the initial letter for the corresponding number and validate as DNI
    var nie_prefix = nie.charAt(0);

    switch (nie_prefix) {
        case "X":
            nie_prefix = 0;
            break;
        case "Y":
            nie_prefix = 1;
            break;
        case "Z":
            nie_prefix = 2;
            break;
    }

    return validDNI(nie_prefix + nie.substr(1));
};

const validCIF = function (cif) {
    if (cif && cif.length === 0) {
        return false;
    }

    var match = cif.match(CIF_REGEX);

    if (match && match.length > 0) {
        var letter = match[1],
            number = match[2],
            control = match[3];

        var even_sum = 0;
        var odd_sum = 0;
        var n;

        for (var i = 0; i < number.length; i++) {
            n = parseInt(number[i], 10);

            // Odd positions (Even index equals to odd position. i=0 equals first position)
            if (i % 2 === 0) {
                // Odd positions are multiplied first.
                n *= 2;

                // If the multiplication is bigger than 10 we need to adjust
                odd_sum += n < 10 ? n : n - 9;

                // Even positions
                // Just sum them
            } else {
                even_sum += n;
            }
        }

        //https://es.wikipedia.org/wiki/C%C3%B3digo_de_identificaci%C3%B3n_fiscal
        var total_sum = even_sum + odd_sum;
        var future_control_digit = total_sum.toString().substr(-1);

        var control_digit = future_control_digit !== "0" ? 10 - future_control_digit : future_control_digit;
        var control_letter = "JABCDEFGHI"[control_digit];

        if (number.substring(0, 2) === "00") return control == control_letter;

        // Control must be a digit
        if (letter.match(/[ABEH]/)) {
            return control == control_digit;

            // Control must be a letter
        } else if (letter.match(/[KPQS]/)) {
            return control == control_letter;

            // Can be either
        } else {
            return control == control_digit || control == control_letter;
        }
    } else {
        return false;
    }
};

const validSpecialNIF = function (nif) {
    var match = nif.match(SPECIAL_NIF_REGEX);
    var letter = match[1],
        number = match[2],
        control = match[3];

    console.debug(`"Letter ${letter} number ${number} and control ${control}`);

    let control_digit = number % 23;
    let control_letter = "TRWAGMYFPDXBNJZSQVHLCKE"[control_digit];

    return control === control_letter;
};
