<template>
    <div v-if="popover != null" class="qs mb-2">
        <font-awesome-icon class="icon" :icon="fontawesomeIcon" v-b-popover.hover.top="popover" title=""></font-awesome-icon>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        popover: {
            required: false,
            type: String,
        },
        fontawesomeIcon: {
            default: "exclamation-circle",
        },
    },
};
</script>
<style lang="scss">
.qs {
    position: relative;
    width: auto;
    align-self: center;
    height: 17px;
    margin-left: 10px;
    cursor: pointer;

    .icon {
        color: #717171;
        @include font-size(20);

        @include respond-above(sm) {
            @include font-size(18);
        }
    }
    .tooltip-inner {
        background: #636363 !important;
        color: $color-lightest !important;
        @include font-size(12);
        @include line-height(16);
        font-weight: $font-primary-medium !important;
    }
}
.tooltip-inner {
    background-color: #636363 !important;
    color: $color-lightest !important;
    @include font-size(12);
    @include line-height(16);
    font-weight: $font-primary-medium !important;
}
.popover {
    background-color: #636363;
    border-radius: 4px;

    .popover-body {
        color: $color-form-text;
        @include font-size(12);
        @include line-height(16);
        font-weight: $font-primary-medium;
    }
    .arrow::after {
        border-top-color: #636363;
    }
}
</style>
