<template>
    <div class="dnie-login-modal-container">
        <div class="dnie-header">
            <div class="img-container">
                <img src="../../assets/images/dnie/logo_dnie_1024_768.png" />
            </div>
            <h1 class="title-login">
                <b> {{ $t("ofex.dnie.access") }} </b>
            </h1>
        </div>
        <div class="dnie-modal-text">
            <p class="text mt-3" v-html="$t('ofex.dnie.useDnie')"></p>
            <p class="text mb-5" v-html="$t('ofex.dnie.useDnie-2')"></p>
        </div>
        <div class="dnie-modal-text">
            <p class="dnie-left-align mb-4">
                <b>{{ $t("ofex.dnie.howToUse") }}</b>
            </p>
            <p class="dnie-left-align text">{{ $t("ofex.dnie.itsNecessary") }}</p>
            <ul class="list-dnie mb-4">
                <li class="text">{{ $t("ofex.dnie.firstLi") }}</li>
                <li class="text">{{ $t("ofex.dnie.secondLi") }}</li>
                <li class="text">
                    {{ $t("ofex.dnie.thirdLi") }} <a href="https://www.dnielectronico.es" class="link" target="_blank">www.dnielectronico.es</a>
                    {{ $t("ofex.dnie.thirdLi-2") }}
                </li>
            </ul>
            <p>{{ $t("ofex.dnie.clickNext") }}</p>

            <!--            TODO usar ab-button-ofex-->
            <!-- <button>Acceder con DNI electronico</button> -->
            <div class="button-container">
                <ab-button-ofex class="dnie-button" :text-button="$t('ofex.dnie.access')" :click-event="loginDnie"></ab-button-ofex>
            </div>
            <hr />
            <div class="footer text-center">
                <ul>
                    <li class="text">
                        {{ $t("ofex.dnie.firstVideo") }}

                        <a href="https://www.youtube.com/watch?v=KNxWFv3s_R0"><img src="../../assets/images/dnie/dnie-image-1.png" /></a> <br />
                    </li>
                    <li class="text">
                        {{ $t("ofex.dnie.secondVideo") }}
                        <a
                            href="https://sede.seg-social.gob.es/wps/portal/sede/sede/Inicio/CertificadosDigitales/DNIElectronico/03DNIElectronico/!ut/p/z1/jZBPa4NAEMW_Si4e44xuCNrbEkPzz9ASRLOXYmSzLuiurNsK-fRdaC4tjWZu8_i94b0BBgUwVX5JUVqpVdm4_cyWHyRYLoIYg8MrbtdIs3SfxeSdYBJCPgrECOwZPz4YOunfTQGuQWjSVSqAdaWt51JdNRTJcTtbN7yyRitZOQHJXykHNno7DqYAvAMj7Vx80ejLz6epupDI5TT8yg03_qdxcm1t17946OEwDL7QWjTcr3Tr4X-WWvcWit8knHgPXZsVt8MG5VubRzai39CWDkU!/dz/d5/L2dBISEvZ0FBIS9nQSEh/"
                            ><img class="dnie-img" src="../../assets/images/dnie/dnie-image-2.png"
                        /></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import * as viewMappingTypes from "../../router/view-mapping-types";
import Swal from "sweetalert2";
import * as actionTypes from "@/store/action-types";
//import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
    name: "AbDnieLoginModal",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
        };
    },
    computed: {},
    methods: {
        loginDnie: async function () {
            Swal.close();
            // let result = await this.$http.get(`${process.env.VUE_APP_API_LOGIN_CERTIFICATE_GET_TOKEN}`);
            this.$store.dispatch(actionTypes.LOGIN_CERTIFICATE_ACTION);
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.subtitle-change {
    color: $blue-dark;
    font-weight: $font-primary-bold;
    @include font-size(21);
}
.text {
    font-weight: $font-primary;
    @include font-size(16);
}
.link {
    color: $bg-primary;
    text-decoration: underline;
}
.text-conditions {
    font-weight: $font-primary-semiBold;
    @include font-size(14);
}

.dnie-header {
    display: flex;
    text-align: center;
}

.footer {
    display: flex;
    flex-direction: column;
}
.footer ul {
    margin-top: 7px;
}
.footer ul li {
    display: flex;
    flex-direction: column;
}
.footer img {
    height: 100px;
    align-self: center;
    margin-top: 15px;
}
.footer ul li:first-child img {
    margin-bottom: 30px;
}
.dnie-img {
    height: 60px !important;
    align-self: center;
}

.dnie-modal-text {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.dnie-modal-text .button-container {
    margin: 5px auto 15px;
}

.dnie-modal-text hr {
    width: 100%;
    color: $blue-dark;
}

.dnie-left-align {
    align-self: flex-start;
    text-align: start;
}

.title-login {
    text-align: center;
    align-self: center;
}
.list-dnie {
    li {
        background-image: url("../../assets/images/svg/chevron.svg");
        background-repeat: no-repeat;
        background-position: 8px 10px;
        background-size: 13px;
        width: 100%;
        display: inline-block;
        padding: 7px 0 7px 28px;
        margin-bottom: 15px;
    }
}
@include respond-below(sm) {
    .title-login {
        @include font-size(28);
        @include line-height(34);
        font-weight: 800;
        color: $blue-dark;
        text-align: left;
    }
    .dnie-header {
        flex-wrap: wrap;
        height: auto;
    }
}
@include respond-above(sm) {
    .img-container {
        width: 200px;
        margin-right: 30px;
    }
    .button-container {
        .btn {
            width: auto;
            margin: 0 auto;
        }
    }
}
</style>
