import qs from "qs";
import store from "../store/store";
import { SET_REST_ERROR } from "../store/mutation-types";
import * as mutationTypes from "../store/mutation-types";
import BaseOfexServiceClient from "./BaseOfexServiceClient";

const LOGIN_BASE_URL = process.env.VUE_APP_API_LOGIN_BASE_URL;

export default {
    recoveryMail: async function (
        identificationDocumentType,
        userDocumentType,
        userIdentification,
        companyIdentification,
        firstName,
        firstLastName,
        secondLastName,
        phone,
        invoiceNumber,
        invoiceAmount,
        newEmail,
        pdf,
        activity,
        lang = "es"
    ) {
        console.log("Enviando petición para recuperar mail");
        store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true });

        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.post(
            `${LOGIN_BASE_URL}/auth/recoverings/mail`,
            {
                identification_document_type: identificationDocumentType,
                user_document_type: userDocumentType,
                user_identification: userIdentification,
                company_identification: companyIdentification,
                firstName: firstName,
                firstLastName: firstLastName,
                secondLastName: secondLastName,
                mobilephone: phone,
                invoice_number: invoiceNumber,
                invoice_amount: invoiceAmount,
                new_email: newEmail,
                pdf: pdf,
                activity: activity,
            },
            {
                params: {
                    lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            return true;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
    getProfile: async function (userId, clientId, lang = "es") {
        console.debug(`Enviando petición para recuperar los datos del usuario/cliente. UserId: ${userId} - ClientId: ${clientId}`);
        try {
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(
                `${LOGIN_BASE_URL}/auth/getProfile`,
                {},
                {
                    params: {
                        userId,
                        clientId,
                        lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
        }
    },
    logout: async function () {
        console.debug(`Enviando petición para revocar el token`);
        try {
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(`${LOGIN_BASE_URL}/auth/logout`);
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.debug("Token revocated");
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
        }
    },
};
