<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container class="p-invoices-view">
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.lastInvoices.title')" />
                <div v-if="lastInvoices && lastInvoices.length > 0">
                    <vue-good-table
                        :columns="columns"
                        :rows="tableRows"
                        :totalRows="allContracts.length"
                        style-class="vgt-table"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            perPageDropdown: [10, 20, 30],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: this.$i18n.tc('table.siguiente'),
                            prevLabel: this.$i18n.tc('table.atras'),
                            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                            ofLabel: 'de',
                            allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                            label: 'selector-de-paginas',
                            name: 'perPageSelect',
                        }"
                        :sort-options="{
                            enabled: false,
                        }"
                        @on-page-change="reloadInvoicesPage"
                        @on-per-page-change="reloadInvoicesPage"
                    >
                        <template slot="table-row" slot-scope="props">
                            <div v-if="props.column.field == 'fullInvoiceNumber'">
                                <span :class="props.row.status === 'No Pagada' ? 'pending' : ''">{{ props.row.fullInvoiceNumber }}</span>
                            </div>
                            <div class="col-envio-inner" v-else-if="props.column.field == 'shippmentType'">
                                <span class="text">{{ props.row.shippmentType }}</span>
                                <div class="buttons-group">
                                    <b-dropdown
                                        :id="props.row.fullInvoiceNumber"
                                        dropleft
                                        title="Menu dropdown"
                                        size="lg"
                                        variant="link"
                                        toggle-class="text-decoration-none"
                                        no-caret
                                        class="table-dropdown"
                                    >
                                        <b-dropdown-item-button
                                            aria-label="Descargar factura"
                                            aria-describedby="Menu dropdown-Descargar factura"
                                            title="Menu dropdown"
                                            @click="downloadInvoice(props.row.invoiceNumber, props.row.invoiceYear, props.row.contractNumber)"
                                            >{{ $t("ofex.invoicesTable.downloadButtonText") }}</b-dropdown-item-button
                                        >
                                        <router-link :to="`/tuscontratos/${props.row.contractNumber}/tusfacturas/${props.row.invoiceNumber}/entender-factura`">
                                            <b-dropdown-item-button aria-describedby="dropdown-header-1" aria-label="Menu dropdown" title="Menu dropdown">{{
                                                $t("ofex.tuContrato.detail")
                                            }}</b-dropdown-item-button>
                                        </router-link>
                                    </b-dropdown>
                                </div>
                            </div>
                            <div v-else-if="props.column.field == 'status'">
                                <router-link
                                    v-if="props.row.status === 'No Pagada'"
                                    :to="`/tuscontratos/${props.row.contractNumber}/tusfacturas/${props.row.invoiceNumber}/pagar-facturas`"
                                >
                                    <span class="pending">{{ invoiceStatusTranslation(props.row.status) }}</span>
                                </router-link>
                                <span v-else>{{ invoiceStatusTranslation(props.row.status) }}</span>
                                <font-awesome-icon v-if="props.row.status === 'No Pagada'" class="icon-pay" icon="arrow-right"></font-awesome-icon>
                            </div>
                            <div v-else-if="props.column.field == 'consumption'">
                                <span class="text">{{ props.row.consumption }}</span> m<sup>3</sup>
                            </div>
                            <div v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                        </template>
                        <div slot="emptystate" class="text-center textdnormal">
                            {{ $t("ofex.invoiceView.emptyResults") }}
                        </div>
                    </vue-good-table>

                    <ab-contract-links
                        v-if="$isWebApp() && unpaidInvoices && unpaidInvoices.length > 0"
                        :contract="this.$route.params.contractId"
                        :show-telerecording-button="false"
                    />
                </div>
                <ab-without-data :text="$t('ofex.withoutData.lastInvoice')" v-else />

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-left="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-left="$t('ofex.relatedContents.facturaDigital.image')"
                    target-left="_self"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.quePagasFactura.name')"
                    :href-right="$t('ofex.relatedContents.quePagasFactura.url')"
                    :image-right="$t('ofex.relatedContents.quePagasFactura.image')"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import { mapState, mapGetters } from "vuex";
import * as actionTypes from "../store/action-types";
import AbMainTitle from "../modules/AbMainTitle.vue";
import AbYourInterest from "../modules/AbYourInterest.vue";

import * as mutationTypes from "@/store/mutation-types";
import { trimBackendCode } from "@/plugins/ab-utils";
import { Capacitor } from "@capacitor/core";

export default {
    name: "InvoicesView",
    components: {
        AbMainTitle,
        AbYourInterest,
        VueGoodTable,
    },
    data: function () {
        return {
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
            apimSubscriptionKey: Capacitor.isNativePlatform()
                ? process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_NATIVE_HEADER
                : process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_HEADER,
            selected: [],
            selectAll: false,
            loading: false,
        };
    },
    methods: {
        mapInvoiceToTableRow: function (invoiceEntry) {
            return {
                fullInvoiceNumber: `${invoiceEntry.invoiceYear}${invoiceEntry.invoiceNumber}`,
                invoiceYear: invoiceEntry.invoiceYear,
                invoiceNumber: invoiceEntry.invoiceNumber,
                consuptiomUntil: invoiceEntry.endDate,
                issueDate: invoiceEntry.issueDate,
                consumption: invoiceEntry.consumptionVolume,
                amount: `${this.$formatNumber(invoiceEntry.amount)} €`,
                status: invoiceEntry.invoiceStatus,
                contractNumber: invoiceEntry.contractNumber,
                shippmentType: this.$t("ofex.backendCodes.deliveryType." + invoiceEntry.invoice.toString()), //TODO obtener del contrato
            };
        },
        invoiceStatusTranslation: function (invoiceStatus) {
            return this.$i18n.tc(`ofex.backendCodes.invoiceStatus.${trimBackendCode(invoiceStatus)}`);
        },
        downloadInvoice: function (invoiceNumber, invoiceYear, contractNumber) {
            this.$store.dispatch(actionTypes.DOWNLOAD_INVOICE_ACTION, {
                invoiceNumber,
                invoiceYear,
                contractNumber,
                locale: this.$i18n.locale,
                invoiceType: "NORMAL",
            });
        },
        reloadInvoicesPage(params) {
            console.log(params);
            this.$store.dispatch(actionTypes.GET_LAST_INVOICES_ACTION, {
                locale: this.$i18n.locale,
                pageNumber: params.currentPage,
                pageSize: params.currentPerPage,
                triggerLoading: true,
            });
        },
    },
    computed: {
        ...mapState(["allContracts", "contracts", "unpaidInvoices", "isLoading", "clientData", "lastInvoices", "filteredInvoices", "selectedContractNumber"]),
        ...mapGetters(["userId", "getContractByContractNumber", "getContractSupplyCompleteAddress"]),
        contract: function () {
            return this.getContractByContractNumber(this.$route.params.contractId);
        },
        supplyAddress: function () {
            return this.getContractSupplyCompleteAddress(this.selectedContractNumber);
        },
        tableRows: function () {
            return this.filteredInvoices !== null ? this.filteredInvoices.map(this.mapInvoiceToTableRow) : this.lastInvoices.map(this.mapInvoiceToTableRow);
        },
        invoicesRow: function () {
            return this.filteredInvoices !== null ? this.filteredInvoices.map(this.mapInvoiceToExcel) : this.lastInvoices.map(this.mapInvoiceToExcel);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contractNumber",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col last-invoice-col",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.invoiceNumber"),
                    field: "fullInvoiceNumber",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col nowrap last-invoice-col",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.consuptiomUntil"),
                    field: "consuptiomUntil",
                    tdClass: "second-col",
                    thClass: "nowrap last-invoice-col",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.issueDate"),
                    field: "issueDate",
                    thClass: "last-invoice-col",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.consumption"),
                    field: "consumption",
                    thClass: "last-invoice-col",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.amount"),
                    field: "amount",
                    thClass: "last-invoice-col",
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.status"),
                    field: "status",
                    tdClass: "col-pago",
                    thClass: "col-pago last-invoice-col",
                    html: true,
                },
                {
                    label: this.$t("ofex.invoicesTable.columnNames.shippmentType"),
                    field: "shippmentType",
                    tdClass: "col-envio",
                    thClass: "col-envio",
                    html: true,
                },
            ];
        },
    },
    mounted() {
        console.log(`Listado de facturas del contrato ${this.$route.params.contractId}`);
        this.$store.dispatch(actionTypes.GET_ALL_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        this.$store.dispatch(actionTypes.GET_LAST_INVOICES_ACTION, { locale: this.$i18n.locale, pageNumber: 1, pageSize: 10, triggerLoading: true });
    },
    beforeDestroy() {
        this.$store.commit(mutationTypes.CLEAR_INVOICES_FILTER_MUTATION);
    },
};
</script>

<style lang="scss" scoped>
.table-buttons-container {
    @include respond-above(sm) {
        .btn {
            flex: 1 1 auto;
            width: auto;
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.m-select-your-contract {
    border-bottom: 1px solid $blue-dark;
    padding-bottom: 20px;
}
</style>

<style lang="scss">
.last-invoice-col span::before {
    content: "";
    position: absolute;
    border-left: 0 solid transparent !important;
    border-right: 0 solid transparent !important;
    border-bottom: 0 solid #cfd3e0 !important;
}

.last-invoice-col span::after {
    content: "";
    position: absolute;
    border-left: 0 solid transparent !important;
    border-right: 0 solid transparent !important;
    border-bottom: 0 solid #cfd3e0 !important;
}
</style>
