/**
 * Poner en name un nombre descriptivo de la página y en path la ruta
 * @type {{path: string, name: string}}
 */

export const WELCOME_APP_VIEW_MAPPING = {
    name: "Welcome App",
    path: "/welcome",
};

export const LOGIN_VIEW_MAPPING = {
    name: "Login",
    path: "/login",
};

export const LOGIN_TOUCHID_VIEW_MAPPING = {
    name: "Login",
    path: "/login-touchid",
};

export const LOGIN_TOUCHID_EMPRESA_VIEW_MAPPING = {
    name: "Login",
    path: "/login-touchid-empresa",
};

export const REGISTERED_TOUCHID_VIEW_MAPPING = {
    name: "RegisteredTouchId",
    path: "/registered-touchid",
};

export const LOGIN_FACEID_VIEW_MAPPING = {
    name: "Login",
    path: "/login-faceid",
};

export const LOGIN_PASSWORDLESS_VIEW_MAPPING = {
    name: "Login Passwordless",
    path: "/passwordless-login",
};

export const HOME_VIEW_MAPPING = {
    name: "Area Privada",
    path: "/inicio",
};

export const PASSWORD_RECOVERY_MAPPING = {
    name: "Recuperar tu contraseña",
    path: "/recuperar-tu-contrasena/:isCompany",
};

export const PASSWORD_RECOVERY_PUBLIC_MAPPING = {
    name: "Modificar tu contrasena",
    path: "/modificar-tu-contrasena",
};

export const INVOICE_DETAIL_MAPPING = {
    name: "Tus Facturas",
    path: "/tus-facturas",
};

//TODO esto está duplicado?
export const INVOICE_CHECK_DETAIL_MAPPING = {
    name: "Consultar tus facturas",
    path: "/consultar-facturas",
};

export const INVOICES_LIST_MAPPING = {
    name: "Tus facturas",
    path: "/tuscontratos/:contractId/tusfacturas",
};

export const RECTIFYING_INVOICES_LIST_MAPPING = {
    name: "Facturas Rectificativas",
    path: "/facturas-rectificativas",
};

export const CONSUMPTIONS_DETAIL_MAPPING = {
    name: "Tus consumos",
    path: "/tuscontratos/:contractId/tusconsumos",
};
export const AFFECTATIONS_CONTRACTS_MAPPING = {
    name: "Tus afectaciones",
    path: "/afectaciones-contratos",
};
export const AFFECTATIONS_CONTRACTS_DETAIL_MAPPING = {
    name: "Detalle de la afectación del contrato",
    path: "/afectaciones-contratos/:contractId",
};

export const LAST_READINGS_DETAIL_MAPPING = {
    name: "Tus ultimas lecturas",
    path: "/ultimas-lecturas-telelectura",
};

export const CONTRACTS_LIST_MAPPING = {
    name: "Contratos",
    path: "/tuscontratos",
};

export const CONTRACT_DETAIL_MAPPING = {
    name: "Detalle del Contrato",
    path: "/tuscontratos/:contractId",
};

export const NOTIFICATIONS_LIST_MAPPING = {
    name: "Notificaciones",
    path: "/lista_avisos",
};

export const NOTIFICATION_DETAIL_MAPPING = {
    name: "Detalle de una notificación",
    path: "/lista_avisos/:notificationId",
};

export const EMAIL_RECOVERY_MAPPING = {
    name: "Recuperar email",
    path: "/recuperar-email",
};

export const EMAIL_RECOVERY_CONFIRM_MAPPING = {
    name: "Confirmación recuperar email",
    path: "/confirmacion-recuperar-email",
};

export const INVOICE_PAYMENT_BY_CONTRACTS = {
    name: "Pagar las facturas por contratos",
    path: "/tuscontratos/tusfacturas/pagar-facturas",
};

export const INVOICE_PAYMENT = {
    name: "Pagar tu Factura",
    path: "/tuscontratos/:contractId/tusfacturas/:invoiceId/pagar-facturas",
};

export const UNSUSCRIBE_CONTRACT_SELECTOR_VIEW_MAPPING = {
    name: "Selector del contrato (Baja)",
    path: "/tuscontratos/baja_suministro/listacontratos",
};

export const UNSUSCRIBE_CONTRACT_VIEW_MAPPING = {
    name: "Baja del contrato",
    path: "/tuscontratos/:contractId/baja_suministro",
};

export const CHARTS_MAPPING = {
    name: "Charts",
    path: "/graficas",
};
export const INVOICE_DETAIL_VIEW_MAPPING = {
    name: "Detalle de tu factura",
    path: "/tuscontratos/:contractId/tusfacturas/:invoiceId/entender-factura",
};
export const INVOICE_PAYMENT_COMPLETED = {
    name: "Facturas transacción completa",
    path: "/tuscontratos/tusfacturas/pagar-facturas/completado",
};

export const INVOICE_PAYMENT_ERROR = {
    name: "Facturas transacción errónea",
    path: "/tuscontratos/tusfacturas/pagar-facturas/error",
};

export const EVOLUTION_INVOICES_MAPPING = {
    name: "Evolución de tus facturas",
    path: "/tuscontratos/tusfacturas/evolucion-facturas",
};

export const LAST_INVOICES_MAPPING = {
    name: "Tus últimas facturas",
    path: "/tuscontratos/tusfacturas/ultimas-facturas",
};

export const UNSUSCRIBE_CONTRACT_DETAIL = {
    name: "Detalle baja de un contrato",
    path: "/tuscontratos/:contractId/baja_suministro/detalle",
};

export const CONFIRMATION_UNSUSCRIBE_CONTRACT_MAPPING = {
    name: "Confirmacion baja de un contrato",
    path: "/tuscontratos/baja_suministro/confirmacion",
};

export const USER_VIEW_DATA_MAPPING = {
    name: "Datos de usuario",
    path: "/datos-usuario",
};

export const INVOICE_DIGITAL_MAPPING = {
    name: "Factura digital",
    path: "/tuscontratos/tusfacturas/factura-digital",
};

export const CONTRACTS_SELECTION_CONFIGURATION_MAPPING = {
    name: "Seleccion contratos",
    path: "/configuraciones/tuscontratos/seleccion",
};

export const CONFIGURATION_NOTICES_MAPPING = {
    name: "Configuracion de avisos",
    path: "/avisos/configuracion-avisos",
};

export const MODIFICATION_PAYMENT_DATA_MAPPING = {
    name: "modificar datos de pago",
    path: "/tuscontratos/modificacion/modificar-datos-pago/:contractId",
};

export const MODIFICATION_CORRESPONDENCE_DATA_MAPPING = {
    name: "modificar datos de correspondencia",
    path: "/tuscontratos/modificacion/modificar-datos-correspondencia/:contractId",
};

export const MODIFICATION_OWNER_DATA_MAPPING = {
    name: "modificar datos del titular",
    path: "/tuscontratos/modificacion/titular/:contractId",
};

export const MODIFICATION_CONTRACT_SELECTOR_MAPPING = {
    name: "Selección de contrato para modificación",
    path: "/tuscontratos/modificacion/listacontratos",
};

export const INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING = {
    name: "Introduce la cédula de habitabilidad",
    path: "/tuscontratos/modificacion/introduce-cedula-de-habitabilidad/:contractId",
};

export const INSERT_IAE_DATA_MAPPING = {
    name: "Introduce los datos del IAE",
    path: "/tuscontratos/modificacion/introduce-datos-del-iae/:contractId",
};

export const USER_ACTIONS_MAPPING = {
    name: "Tu historial de acciones de usuario",
    path: "/historial-gestiones",
};

export const READ_METER_FORM_MAPPING = {
    name: "Lectura del contador",
    path: "/introduce_lectura/:contractId",
};

export const READ_METER_SELECTOR_MAPPING = {
    name: "Selección de contrato para lectura del contador",
    path: "/introduce_lectura/listacontratos",
};

export const RECLAMATION_FORM_MAPPING = {
    name: "Reclamaciones",
    path: "/reclamacion",
};

export const MODIFY_PAYMENT_REDIRECTION_MAPPING = {
    name: "Redirección a modificar datos de pago",
    path: "/tuscontratos/modificacion/modificar-datos-pago",
};

export const AUTHORIZATIONS_VIEW_MAPPING = {
    name: "Autorizaciones",
    path: "/autorizaciones",
};

export const NEW_AUTHORIZATION_VIEW_MAPPING = {
    name: "Nueva Autorización",
    path: "/autorizaciones/nueva",
};

export const AUTHORIZATIONS_BY_CONTRACT_VIEW_MAPPING = {
    name: "Autorizaciones por Contrato",
    path: "/autorizaciones/contrato/:contractId",
};

export const AUTHORIZATIONS_BY_PERSON_VIEW_MAPPING = {
    name: "Autorizaciones por persona",
    path: "/autorizaciones/contrato/persona/:documentNumber",
};

export const NATIVE_APP_INFORMATION = {
    name: "Información AB",
    path: "/native_app_info",
};

export const NATIVE_APP_OFFICES_INFORMATION = {
    name: "Información oficinas AB",
    path: "/native_app_offices_info",
};
export const AFFECTATIONS_VIEW = {
    name: "Tus afectaciones",
    path: "/tusafectaciones",
};
export const AFFECTATIONS_CONTRACT_DETAIL_VIEW = {
    name: "Detalle de las afectaciones del Contrato",
    path: "/tusafectaciones/:contractId",
};
export const APP_SETTINGS_VIEW_MAPPING = {
    name: "Configuracion de la app",
    path: "/app_settings",
};
