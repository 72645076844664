<template>
    <b-container>
        <ab-loading v-model="isLoading" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.managementHistory.title')" />
                <ab-user-actions-advance-search
                    ref="historicAdvanceSearch"
                    :user-actions="internalUserActions"
                    @filter-actions="executeFilter"
                    @clear-filter="resetFilter"
                />
                <vue-good-table
                    :columns="columns"
                    :rows="tableRows"
                    style-class="vgt-table"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        perPageDropdown: [10, 20, 30],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: this.$i18n.tc('table.siguiente'),
                        prevLabel: this.$i18n.tc('table.atras'),
                        rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                        ofLabel: 'de',
                        allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                        label: 'selector-de-paginas',
                        name: 'perPageSelect',
                    }"
                >
                    <div slot="emptystate" class="text-center">{{ this.$i18n.tc("ofex.userActionsView.noDataTable") }}</div>
                </vue-good-table>
                <!--buttons-->
                <div class="d-md-flex justify-content-end mt-4 mb-4">
                    <div class="button-invoices">
                        <ab-button-ofex
                            :font-awesome-icon="'print'"
                            :text-button="$t('ofex.managementHistory.pdf')"
                            :click-event="generatePdfUserActionsList"
                        ></ab-button-ofex>
                    </div>
                    <div class="button-invoices ml-md-4 mt-2 mt-md-0">
                        <ab-button-ofex
                            :font-awesome-icon="'download'"
                            :text-button="$t('ofex.managementHistory.excel')"
                            :click-event="generateExcelActionList"
                        ></ab-button-ofex>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import AbUserActionsAdvanceSearch from "../modules/userActions/AbUserActionsAdvanceSearch";
import AbLeftNavBar from "../modules/common/AbLeftNavBar.vue";

import UserActionsListPdfGenerator from "@/generators/pdf/UserActionsListPdfGenerator";
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapState } from "vuex";
import CustomersServiceClient from "@/rest/customersServiceClient";
import moment from "moment";
import { AB_DATETIME_FORMAT, AB_DATE_FORMAT, AB_DATETIME_FRIENDLY_FORMAT } from "../plugins/ab-utils";
import UserActionsListXlsxGenerator from "@/generators/xlsx/UserActionsListXlsxGenerator";
import AbLoading from "../components/AbLoading.vue";

export default {
    name: "UserActionsView",
    components: {
        AbUserActionsAdvanceSearch,
        VueGoodTable,
        AbLoading,
        AbLeftNavBar,
    },
    data: function () {
        return {
            userActions: [],
            internalUserActions: [],
            isLoading: true,
        };
    },
    computed: {
        ...mapState(["selectedContractNumber"]),
        ...mapGetters(["userId", "clientId", "getContractSupplyCompleteAddress"]),

        tableRows: function () {
            return this.internalUserActions.map(this.mapActionsUserToTableRow);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.managementHistory.table.channel"),
                    field: "channel",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$t("ofex.managementHistory.table.init"),
                    field: "initDate",
                    tdClass: "second-col",
                    thClass: "nowrap",
                },
                {
                    label: this.$t("ofex.managementHistory.table.req"),
                    field: "req",
                },
                {
                    label: this.$t("ofex.managementHistory.table.subject"),
                    field: "subject",
                },
                {
                    label: this.$t("ofex.managementHistory.table.status"),
                    field: "status",
                },
                {
                    label: this.$t("ofex.managementHistory.table.end"),
                    field: "endDate",
                },
            ];
        },
        supplyAddress: function () {
            return this.getContractSupplyCompleteAddress(this.selectedContractNumber);
        },
    },
    methods: {
        executeFilter: function (filter) {
            this.isLoading = true;
            console.log(`Filtrando notificaciones ${filter}`);
            this.internalUserActions = this.userActions
                .filter((n) => this.statusFilter(n, filter))
                .filter((n) => this.channelFilter(n, filter))
                .filter((n) => this.contractFilter(n, filter))
                .filter(
                    (n) =>
                        typeof filter.notificationsDateRange === "undefined" ||
                        this.isDateBetweenRange(n.date, filter.notificationsDateRange[0], filter.notificationsDateRange[1])
                );
            this.isLoading = false;
        },
        resetFilter: function () {
            this.internalUserActions = [...this.userActions];
        },
        mapActionsUserToTableRow: function (actionEntry) {
            this.isLoading = false;
            return {
                channel: actionEntry.channel,
                initDate: moment(actionEntry.date, AB_DATETIME_FORMAT).format(AB_DATETIME_FRIENDLY_FORMAT),
                req: actionEntry.numberReq,
                subject: actionEntry.subject,
                status: actionEntry.status,
                endDate: actionEntry.closeDate ? moment(actionEntry.closeDate, AB_DATETIME_FORMAT).format(AB_DATETIME_FRIENDLY_FORMAT) : null,
            };
        },
        channelFilter: function (n, filter) {
            if (typeof filter.channel === "undefined" || filter.channel == null) {
                return true;
            } else {
                return filter.channel === n.channel;
            }
        },
        statusFilter: function (n, filter) {
            if (typeof filter.status === "undefined" || filter.status == null) {
                return true;
            } else {
                return filter.status === n.status;
            }
        },
        contractFilter: function (n, filter) {
            if (typeof filter.contractNumber === "undefined" || filter.contractNumber == null) {
                return true;
            } else {
                return filter.contractNumber === n.contractNumber;
            }
        },
        isDateBetweenRange: function (date, start, end) {
            let dateMoment = moment(date, AB_DATETIME_FORMAT);
            let startMoment = moment(start, AB_DATE_FORMAT);
            let endMoment = moment(end, AB_DATE_FORMAT);
            return dateMoment.isBetween(startMoment, endMoment);
        },
        generateExcelActionList: function () {
            let xlsxGenerator = new UserActionsListXlsxGenerator(
                `AccionesUsuario-${this.userId}.xlsx`,
                this.tableRows,
                this.$i18n,
                this.selectedContractNumber,
                this.supplyAddress
            );
            console.log(xlsxGenerator);
        },
        generatePdfUserActionsList: function () {
            let pdfGenerator = new UserActionsListPdfGenerator(this.$i18n, this.userId, this.clientId);
            pdfGenerator.generatePdf(
                this.internalUserActions != null ? this.internalUserActions : this.userActions,
                this.selectedContractNumber,
                this.supplyAddress
            );
            pdfGenerator.downloadPdf();
        },
    },
    mounted() {
        CustomersServiceClient.historyClient(this.userId, this.clientId, this.$i18n.locale)
            .then((serviceResult) => {
                this.userActions = serviceResult.historyActionsList;
                this.internalUserActions = [...this.userActions];
            })
            .catch(() => {
                this.isLoading = false;
            });
    },
};
</script>

<style lang="scss" scoped>
.table-buttons-container {
    @include respond-above(sm) {
        .btn {
            flex: 1 1 auto;
            width: auto;
            margin: 0 10px;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.m-select-your-contract {
    border-bottom: 1px solid $blue-dark;
    padding-bottom: 20px;
}
</style>
