<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="row mt-5">
        <div class="col-12">
            <div class="marco-contingencias">
                <a :href="redirectUrl">
                    <img class="img-triangle" src="../assets/images/caution-sign.png" />
                </a>
                <p class="text-contingencias">{{ $t("contingencias.text") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        redirectUrl: {
            default: "/formularios",
            type: String,
        },
    },
};
</script>
<style>
.marco-contingencias {
    max-width: 628px;
    height: 367px;
    border: 1px solid grey;
    margin: auto;
}

@media (max-width: 629px) {
    .marco-contingencias {
        width: 100%;
        height: auto;
        border: 1px solid grey;
    }
}

.img-triangle {
    width: 97px;
    height: 94px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 73px;
}

.text-contingencias {
    color: #3cb2d6;
    font-family: "Raleway";
    font-size: 18px;
    margin-top: 24px;
    margin-left: 83px;
    margin-right: 83px;
}

@media (max-width: 629px) {
    .text-contingencias {
        margin-left: 3%;
        margin-right: 3%;
        text-align: center;
    }
}

.button-submit-contingencias {
    width: 177px;
    height: 39px;
    border-radius: 5px;
    border: 1px solid #1c6897;
    background-color: #1c6897;
    margin: auto;
}

.span-aceptar-button {
    color: #ffffff;
    font-family: "Raleway bold";
    font-size: 16px;
}
</style>
