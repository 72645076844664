<template>
    <div>
        <!--Acordeon pagos tarjeta-->
        <div no-body class="line-bottom pb-0" v-if="calculatedBarcode">
            <div class="section-payments-card">
                <p class="options-payments">{{ $t("ofex.pagarFactura.opcionesPago") }}</p>
                <span
                    v-b-toggle="'collapsecard-' + contractNumber"
                    header-tag="header"
                    :id="'buttonPaymentCard' + contractNumber"
                    class="text-accordion pointer"
                >
                    <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                    <span>{{ $t("ofex.pagarFactura.pagarConTarjeta") }}</span>
                </span>
                <b-collapse :accordion="'paymenth-method-inner-accordion' + contractNumber" visible :id="'collapsecard-' + contractNumber" class="">
                    <div class="no-border mt-4">
                        <p class="text-info-card" v-html="$t('ofex.pagarFactura.podrasPagar')"></p>
                        <p class="text-important mt-4">
                            {{ $t("ofex.pagarFactura.importante") }}<span class="text-detail-important"> {{ $t("ofex.pagarFactura.avisoImportante") }}</span>
                        </p>
                        <div class="pay-btn-container">
                            <ab-button-ofex
                                :font-awesome-icon="'credit-card'"
                                :text-button="$t('ofex.pagarFactura.pagoTarjeta')"
                                class="button-blue mt-4 mt-lg-0"
                                :click-event="launchPaymentCardEvent"
                            ></ab-button-ofex>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
        <!--Acordeon pagos Bizum-->
        <div no-body class="line-bottom pb-0" v-if="bizumEnabled === 'true' && calculatedBarcode">
            <div class="section-payments-bizum">
                <span
                    v-b-toggle="'collapsebizum-' + contractNumber"
                    header-tag="header"
                    :id="'buttonPaymentBizum' + contractNumber"
                    class="text-accordion pointer"
                >
                    <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                    <span>{{ $t("ofex.pagarFactura.pagarConBizum") }}</span>
                </span>
                <b-collapse :accordion="'paymenth-method-inner-accordion' + contractNumber" :id="'collapsebizum-' + contractNumber" class="">
                    <div class="no-border mt-4">
                        <p class="text-info-card" v-html="$t('ofex.pagarFactura.bizumSectionDetail')"></p>
                        <p class="text-important mt-4">
                            {{ $t("ofex.pagarFactura.importante") }}<span class="text-detail-important"> {{ $t("ofex.pagarFactura.avisoImportante") }}</span>
                        </p>
                        <div class="pay-btn-container">
                            <ab-button-ofex
                                :src-svg="require('../../assets/images/svg/bizum.svg')"
                                :src-svg-hover="require('../../assets/images/svg/bizum_fill.svg')"
                                :max-img-height="true"
                                :text-button="$t('ofex.pagarFactura.pagoBizum')"
                                class="button-blue mt-4 mt-lg-0"
                                :click-event="launchPaymentBizumEvent"
                            ></ab-button-ofex>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
        <!--Acordeon pagos CaixaBankNow -->
        <div no-body class="" v-if="calculatedBarcode">
            <div class="section-payment-caixa">
                <span
                    header-tag="header"
                    v-b-toggle="'collapsecaixa-' + contractNumber"
                    :id="'buttonPaymentCaixa' + contractNumber"
                    class="text-accordion pointer"
                >
                    <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                    <span>{{ $t("ofex.pagarFactura.pagarConCaixa") }}</span>
                </span>
                <b-collapse :accordion="'paymenth-method-inner-accordion' + contractNumber" :id="'collapsecaixa-' + contractNumber" class="">
                    <div class="no-border mt-4">
                        <p class="text-info-card">{{ $t("ofex.pagarFactura.codigoCPR") }}</p>
                        <span class="detail-invoice-cpr d-block mt-4">{{ $t("ofex.pagarFactura.CPR") }} {{ invoiceCPR }}</span>
                        <span class="detail-invoice-cpr d-block">{{ $t("ofex.pagarFactura.emisor") }} {{ invoiceIssuer }}</span>
                        <span class="detail-invoice-cpr d-block">{{ $t("ofex.pagarFactura.referencia") }} {{ invoiceReference }}</span>
                        <span class="detail-invoice-cpr d-block">{{ $t("ofex.pagarFactura.identificacion") }} {{ invoiceIdentification }}</span>
                        <span class="detail-invoice-cpr d-block"
                            >{{ $t("ofex.pagarFactura.importe") }} {{ $formatNumber(this.paymentGatewayDataByType["card"].amount) }} €</span
                        >
                        <p class="text-info-card mt-4">{{ $t("ofex.pagarFactura.codigoBarras") }}</p>
                        <div class="invoice-cpr-container">
                            <input
                                ref="invoiceBarcodeInput"
                                type="text"
                                readonly="readonly"
                                :id="'inputNumberCopyPayment' + contractNumber"
                                class="detail-invoice-cpr input-custom"
                                v-model="calculatedBarcode"
                            />
                            <font-awesome-icon class="icon-copy ml-2 pointer" icon="copy" @click="copyPaste('numberCodePayment')"></font-awesome-icon>
                        </div>
                        <p class="mt-4 text-info-card">{{ $t("ofex.pagarFactura.ventanaPago") }}</p>
                        <p class="text-important mt-4">
                            {{ $t("ofex.pagarFactura.importante") }}<span class="text-detail-important"> {{ $t("ofex.pagarFactura.pagoEfectuado") }}</span>
                        </p>
                        <div class="pay-btn-container">
                            <div>
                                <ab-button-ofex
                                    :click-event="openCaixa"
                                    :text-button="$t('ofex.pagarFactura.pagoMedianteCaixa')"
                                    class="icon-caixa"
                                    :src-svg="require('../../assets/images/ofex/pagar-factura/Pago-caixabanc.svg')"
                                    :src-svg-hover="require('../../assets/images/ofex/pagar-factura/Pago-caixabanc-hover.svg')"
                                ></ab-button-ofex>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
    </div>
</template>
<script>
import paymentsServiceClient from "@/rest/paymentsServiceClient";
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
import * as mutationTypes from "@/store/mutation-types";

export default {
    name: "AbPaymentMethodsAccordion",
    props: {
        collapseName: Number,
        contractNumber: {
            type: String,
            required: true,
        },
        multiplePayment: {
            type: Boolean,
            required: true,
        },
        invoices: {
            type: Array,
            required: true,
            description: "Listado de numeros de facturas concatenados con el año",
        },
        invoiceBarcode: {
            type: String,
            required: false,
        },
        invoiceAmount: {
            type: Number,
            required: false,
        },
        initialLoadBarcode: {
            type: Boolean,
            default: false,
        },
        paymentType: {
            type: String,
            default: "CARD",
        },
    },
    data: function () {
        return {
            bizumEnabled: process.env.VUE_APP_BIZUM_PAYMENT_ENABLED,
            calculatedBarcode: null,
            paymentGatewayDataByType: {
                bizum: null,
                card: null,
            },
        };
    },
    computed: {
        ...mapGetters(["clientId", "userId"]),
        ...mapState(["userData"]),
        invoiceCPR: function () {
            return this.calculatedBarcode ? this.calculatedBarcode.substr(0, 5) + "94" : "";
        },
        invoiceIssuer: function () {
            return this.calculatedBarcode ? this.calculatedBarcode.substr(5, 11) : "";
        },
        invoiceReference: function () {
            return this.calculatedBarcode ? this.calculatedBarcode.substr(16, 13) : "";
        },
        invoiceIdentification: function () {
            return this.calculatedBarcode ? this.calculatedBarcode.substr(29, 6) : "";
        },
    },
    methods: {
        calculateBarcodeIfNotPresent: async function () {
            if (this.calculatedBarcode == null) {
                await this.loadBarcode();
            }
        },
        loadBarcode: async function () {
            this.$store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true });

            //verificamos si es pago con factura simple o por contrato y calculamos para los diferentes tipos de pago
            for (const paymentType of Object.keys(this.paymentGatewayDataByType)) {
                console.log("Calculando datos de pasarela de pago para el tipo de pago " + paymentType);
                this.paymentGatewayDataByType[paymentType] = this.multiplePayment
                    ? await paymentsServiceClient.getGatewayDataByContract(
                          this.clientId,
                          this.userId,
                          this.contractNumber,
                          this.invoices,
                          this.userData.email,
                          paymentType.toUpperCase(),
                          this.userData.internalId,
                          this.$i18n.locale
                      )
                    : await paymentsServiceClient.getGatewayDataByInvoice(
                          this.clientId,
                          this.userId,
                          this.contractNumber,
                          this.invoices[0],
                          this.invoiceBarcode,
                          this.userData.email,
                          paymentType.toUpperCase(),
                          this.userData.internalId,
                          this.$i18n.locale
                      );
            }

            this.$store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
            this.calculatedBarcode = this.multiplePayment ? this.paymentGatewayDataByType["card"].invoiceBarcode : this.invoiceBarcode;
        },
        launchPaymentByType: function (paymentType) {
            console.log("Lanzando pago para " + paymentType);

            let paymentGatewayData = this.paymentGatewayDataByType[paymentType];
            this.$store.commit(mutationTypes.STORE_PAYMENT_DATA_MUTATION, { payment: paymentGatewayData });

            if (this.$isNativeApp()) {
                //forzamos a regenerar los datos de pago para evitar bug de Redsys en App
                this.loadBarcode().then(() => {
                    let timestamp = new Date().getTime();
                    let proxyUrl = `https://www.aiguesdebarcelona.cat/proxy-redsys-ab?Ds_SignatureVersion=${paymentGatewayData.dsSignatureVersion}&Ds_MerchantParameters=${paymentGatewayData.dsMerchantParameters}&Ds_Signature=${paymentGatewayData.dsSignature}&Request_UUID=${timestamp}&Redsys_Env=${process.env.VUE_APP_REDSYS_ENV}`;
                    window.location = proxyUrl;
                });
            } else {
                let paymentForm = document.createElement("form");
                paymentForm.setAttribute("method", "post");
                paymentForm.setAttribute("action", process.env.VUE_APP_REDSYS_URL);
                paymentForm.setAttribute("target", this.$isWebApp() ? "_self" : "_blank");

                let signatureVersionField = document.createElement("input");
                signatureVersionField.setAttribute("type", "text");
                signatureVersionField.setAttribute("name", "Ds_SignatureVersion");
                signatureVersionField.setAttribute("value", paymentGatewayData.dsSignatureVersion);

                let merchantParametersField = document.createElement("input");
                merchantParametersField.setAttribute("type", "text");
                merchantParametersField.setAttribute("name", "Ds_MerchantParameters");
                merchantParametersField.setAttribute("value", paymentGatewayData.dsMerchantParameters);

                let signatureField = document.createElement("input");
                signatureField.setAttribute("type", "text");
                signatureField.setAttribute("name", "Ds_Signature");
                signatureField.setAttribute("value", paymentGatewayData.dsSignature);

                paymentForm.appendChild(signatureVersionField);
                paymentForm.appendChild(merchantParametersField);
                paymentForm.appendChild(signatureField);
                document.body.appendChild(paymentForm);
                paymentForm.submit();
            }
        },
        launchPaymentCardEvent: function () {
            this.launchPaymentByType("card");
        },
        launchPaymentBizumEvent: function () {
            this.launchPaymentByType("bizum");
        },
        copyPaste: function () {
            this.$refs.invoiceBarcodeInput.select();
            document.execCommand("copy");

            Swal.fire({
                titleText: this.$t("ofex.pagarFactura.numeroCopiado"),
                icon: "success",
            });
        },
        openCaixa() {
            window.open(this.$t("ofex.pagarFactura.urlCaixaBank"), "_blank");
        },
    },
    async created() {
        console.log("Creando accordion de pagos");
        this.$parent.$on("shown", this.calculateBarcodeIfNotPresent);
        if (this.initialLoadBarcode) {
            await this.loadBarcode();
        }
    },
    mounted() {
        console.debug("Montando accordion de pagos");
    },
    destroyed() {
        console.debug("Destruyendo accordion de pagos");
    },
};
</script>
<style lang="scss" scoped>
.line-bottom {
    border-bottom: 1px solid $border-table;
}

.section-payments-card {
    padding-bottom: 17px;
}

.section-payments-bizum {
    padding-bottom: 17px;
    padding-top: 17px;
}

.options-payments {
    @include font-size(18);
    @include line-height(18);
    color: $color-secondary;
    font-weight: $font-primary-bold;
    margin-bottom: 43px;
}

.text-accordion {
    @include font-size(16);
    color: $blue-dark;
    font-weight: $font-primary-bold;

    &.not-collapsed {
        .icon {
            transform: rotate(90deg);
        }
    }
}
.invoice-cpr-container {
    display: flex;

    .detail-invoice-cpr {
        @include respond-below(sm) {
            flex: 1;
        }
    }
}
.pay-btn-container {
    display: flex;
    justify-content: flex-end;

    .btn {
        width: auto;

        @include respond-below(sm) {
            width: 100%;
        }
    }
}

.text-info-card {
    @include font-size(14);
    @include line-height(22);
    color: $color-secondary;
    font-weight: $font-primary-medium;
}

.section-payment-caixa {
    padding-top: 17px;
    padding-bottom: 17px;
}

.detail-invoice-cpr {
    @include font-size(16);
    @include line-height(22);
    color: $color-secondary;
    font-weight: $font-primary-bold;
}

.input-custom {
    border: 0px;
    width: 54%;
    @include font-size(16);
    @include line-height(18);
    color: $color-secondary;
    font-weight: $font-primary-bold;
}

.pointer {
    cursor: pointer;
}

.text-important {
    @include font-size(16);
    @include line-height(21);
    color: $link-primary;
    font-weight: $font-primary-bold;
}

.text-detail-important {
    @include font-size(16);
    @include line-height(21);
    color: $color-secondary;
    font-weight: $font-primary-medium;
}

.icon-copy {
    @include font-size(16);
    @include line-height(19);
    color: $link-primary;
    font-weight: $font-primary-semiBold;
    margin-right: 7px;
}
</style>
