<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <div class="row mt-3 d-flex align-items-center">
            <div class="col-12 col-md-2 text-center"><img alt="" class="img-fixed mt-5 mt-md-0" :src="imageSrc" /></div>

            <div class="col-12 col-md-10">
                <h1 class="h1formsuccess">{{ title }}</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-left">
                <h2 class="titleh1down text-normal mt-5 mb-4" :class="enterClass" v-html="confirmationMsg"></h2>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <p v-html="confirmationDescription"></p>
            </div>
        </div>

        <div v-if="showPdfButton" class="row mt-5">
            <div class="col-12 text-center">
                <font-awesome-icon icon="download" class="fa-4x mb-3 confirmation-download" @click="generatePdfHandler"></font-awesome-icon>
                <p class="confirmation-donwload-text text-normal" v-html="$t('confirmation.descargarSolicitud')"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        enterClass: {
            type: String,
            required: false,
        },
        imageSrc: {
            type: String,
            default: require("../assets/images/tic-green.png"),
        },
        homeUrl: {
            type: String,
            default: "/",
        },
        title: {
            type: String,
            default: function () {
                return this.$i18n.tc("confirmation.title");
            },
        },
        confirmationMsg: {
            type: String,
            default: function () {
                return this.$i18n.tc("confirmation.confirmationMsg");
            },
        },
        confirmationDescription: {
            type: String,
            default: function () {
                return this.$i18n.tc("confirmation.confirmationDescription");
            },
        },
        generatePdfHandler: {
            type: Function,
        },
        showPdfButton: {
            type: Boolean,
            default: true,
        },
        showCaution: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="scss" scoped>
.confirmation-download {
    color: #515151;
}

.img-fixed {
    height: 85px;
}

.confirmation-download:hover {
    cursor: pointer;
    color: $blue-dark;
}

.h1formsuccess {
    color: $blue-dark;
    font-weight: $font-primary-bold;
    @include font-size(26);
}

.titleh1down {
    font-weight: $font-primary-bold;
    @include font-size(18);
    color: $blue-dark;
}
</style>
