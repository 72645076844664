<template>
    <div class="m-contract-links box">
        <div class="item pending-invoices">
            <div class="left-side">
                <div class="icon-container">
                    <img class="icon" alt="iconoPago" src="../assets/images/svg/pendientes-pago.svg" />
                </div>
                <span class="text">{{ $t("ofex.contractLinks.facturaPendienteTitle") }}</span>
            </div>
            <router-link :to="{ name: viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.name }">
                <div class="right-side link-item">
                    {{ $t("ofex.contractLinks.facturaPendienteLink") }}<font-awesome-icon class="icon" icon="arrow-right"></font-awesome-icon>
                </div>
            </router-link>
        </div>
        <div class="item remote-reading-link" v-if="showTelerecordingButton">
            <div class="left-side">
                <div class="icon-container">
                    <img class="icon" alt="iconoTelelectura" src="../assets/images/svg/consumo-telelectura.svg" />
                </div>
                <span class="text">{{ $t("ofex.contractLinks.consumoTelelecturaTtile") }}</span>
            </div>
            <router-link :to="checkURL()">
                <div class="right-side link-item">
                    {{ $t("ofex.contractLinks.consumoTelelecturaLink") }}<font-awesome-icon class="icon" icon="arrow-right"></font-awesome-icon>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as viewMappingTypes from "../router/view-mapping-types";
export default {
    name: "AbContractLinks",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
        };
    },
    props: {
        showTelerecordingButton: {
            type: Boolean,
            required: false,
            default: true,
        },
        rutePendingInvoices: {
            type: String,
            required: false,
        },
        contract: {
            type: String,
            required: false,
        },
    },
    computed: {
        ...mapGetters(["pendingInvoices"]),
    },
    mounted() {},
    methods: {
        checkURL: function () {
            if (!this.contract) {
                return { name: viewMappingTypes.LAST_READINGS_DETAIL_MAPPING.name };
            } else {
                return { name: viewMappingTypes.CONSUMPTIONS_DETAIL_MAPPING.name, params: this.contract };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.m-contract-links {
    .item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 25px;

        @include respond-above(sm) {
            padding: 15px 25px;
        }

        &.pending-invoices {
            background: $blue-lighter;

            .text {
                color: $color-lightest;
            }
            .link-item {
                color: $color-lightest;
            }
        }
        &.remote-reading-link {
            background: #f8f8f8;

            .text {
                color: $blue-dark;
            }
            .link-item {
                color: $blue-dark;
            }
        }

        .left-side {
            display: flex;
            align-items: center;

            .icon-container {
                flex: 0 0 auto;
                width: 35px;
                text-align: center;
                margin-right: 20px;
            }
            .text {
                font-weight: $font-primary-bold;
                @include font-size(20);
                @include line-height(26);
                margin-right: 20px;
            }
        }
        .right-side {
            align-self: flex-end;
            white-space: nowrap;

            &.link-item {
                font-weight: $font-primary-bold;
                @include font-size(16);
                @include line-height(19);

                .icon {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
