import IBAN from "iban";

import validateSpanishDoc from "../components/common/cif-validator";
import BaseOfexServiceClient from "../rest/BaseOfexServiceClient";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

export default {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        console.debug("Configurating Validator");

        console.debug(options.i18n.tc("dataRecover.dialogDescription"));

        Vue.component("ValidationProvider", ValidationProvider);
        Vue.component("ValidationObserver", ValidationObserver);

        configure({
            classes: {
                failed: "inputFormErr",
            },
            defaultMessage: (field, values) => {
                values._field_ = field;
                return options.i18n.t(`validations.${values._rule_}`, values);
            },
        });

        // https://logaretm.github.io/vee-validate/guide/rules.html#installing-all-rules
        Object.keys(rules).forEach((rule) => {
            extend(rule, rules[rule]);
        });

        // eslint-disable-next-line no-undef
        //TODO vee migration
        extend("iban", {
            // eslint-disable-next-line no-unused-vars
            getMessage: (field) => options.i18n.tc("validations.iban"),
            validate: (value) => IBAN.isValid(value),
        });

        const ibanCountryValidation = (value, { country } = {}) => {
            return country === IBAN.electronicFormat(value).slice(0, 2) && IBAN.isValid(value);
        };

        const ibanCountryParamNames = ["country"];

        extend("ibanCountry", {
            validate: ibanCountryValidation,
            getMessage: (field, params, data) => {
                return data.message;
            },
            params: ibanCountryParamNames,
        });

        const passwordNameCriteriaValidation = (value, { userDoc, username, firstname, secondname } = {}) => {
            // console.debug(`Validando passwordName: ${value} - ${userDoc} - ${username} - ${firstname} - ${secondname}`);
            return !(
                (userDoc.length > 0 && value.toLowerCase().includes(userDoc.toLowerCase())) ||
                (username.length > 0 && value.toLowerCase().includes(username.toLowerCase())) ||
                (firstname.length > 0 && value.toLowerCase().includes(firstname.toLowerCase())) ||
                (secondname.length > 0 && value.toLowerCase().includes(secondname.toLowerCase()))
            );
        };
        const passwordNameCriteriaParamNames = ["userDoc", "username", "firstname", "secondname"];

        // eslint-disable-next-line no-undef
        extend("passwordname", {
            validate: passwordNameCriteriaValidation,
            params: passwordNameCriteriaParamNames,
            // eslint-disable-next-line no-unused-vars
            getMessage: (field, params, data) => options.i18n.tc("validations.passwordname"),
        });

        const fspOneOrMoreValidation = (value, { fspActive, selectedFsp } = {}) => {
            return !fspActive || selectedFsp > 0;
        };
        const fspOneOrMoreParamNames = ["fspActive", "selectedFsp"];

        extend("fspOneOrMore", {
            validate: fspOneOrMoreValidation,
            params: fspOneOrMoreParamNames,
            // eslint-disable-next-line no-unused-vars
            getMessage: (field, params, data) => options.i18n.tc("validations.fspOneOrMore"),
        });

        // eslint-disable-next-line no-undef
        extend("spanishPhone", {
            // eslint-disable-next-line no-unused-vars
            getMessage: (field) => options.i18n.tc("validations.spanishPhone"),
            validate: (value) => {
                let regexPhone = /^(\+34|034|0034)?[6|7|8|9][0-9]{8}$/;
                return regexPhone.test(value);
            },
        });

        // eslint-disable-next-line no-undef
        extend("spanishMobile", {
            // eslint-disable-next-line no-unused-vars
            getMessage: (field) => options.i18n.tc("validations.spanishMobile"),
            validate: (value) => {
                let regexPhone = /^(\+34|034|0034)?[6][0-9]{8}$/;
                return regexPhone.test(value);
            },
        });

        extend("fax", {
            // eslint-disable-next-line no-unused-vars
            getMessage: (field) => options.i18n.tc("validations.fax"),
            validate: (value) => {
                let regexPhone = /^(\+34|034|0034)?[6|7|8|9][0-9]{8}$/;
                return regexPhone.test(value);
            },
        });

        // eslint-disable-next-line no-undef
        extend("twitter", {
            // eslint-disable-next-line no-unused-vars
            getMessage: (field) => options.i18n.tc("validations.twitter"),
            validate: (value) => {
                let regexTwitter = /^@[a-zA-Z0-9_]{1,15}$/;
                return regexTwitter.test(value);
            },
        });

        const spanishDocValidation = (value, { allowNifNie, allowCif } = {}) => {
            return validateSpanishDoc(value, allowCif, allowNifNie);
        };

        extend("spanishdocument", {
            validate: spanishDocValidation,
            message: (field, params) => {
                console.debug(field);
                console.debug(params);
                return options.i18n.tc("validations.spanishdocument", 1, { type: field });
            },
            params: ["allowNifNie", "allowCif"],
        });
        // getMessage: (field, params, data) => options.i18n.tc("validations.spanishdocument", 1, { type: data.allowedTypes.join(" \\ ") }),

        // eslint-disable-next-line no-undef
        extend("spanishcif", {
            // eslint-disable-next-line no-unused-vars
            getMessage: (field) => options.i18n.tc("validations.spanishcif"),
            validate: (value) => validateSpanishDoc(value, true, false).valid,
        });

        const carrererValidation = (value, { municipality, streetType, apiDomain } = {}) => {
            console.debug("Remote carrerer validation");
            return new BaseOfexServiceClient().abRestClient
                .get(`${apiDomain ? apiDomain : process.env.VUE_APP_API_CARRERER_BASE_URL}/streets/validate`, {
                    params: {
                        query: value,
                        municipality: municipality,
                        type: streetType,
                    },
                })
                .then(
                    function (response) {
                        console.debug(response);
                        return {
                            valid: response.data,
                            data: {
                                message: options.i18n.tc("validations.carrererValidation"),
                            },
                        };
                    },
                    function (error) {
                        console.error(error.data);
                        return {
                            valid: error.data,
                            data: {
                                message: options.i18n.tc("validations.carrererValidation"),
                            },
                        };
                    }
                );
        };

        const carrererValidationParamNames = ["municipality", "streetType", "apiDomain"];

        extend("carrerer", {
            validate: carrererValidation,
            getMessage: (field, params, data) => {
                return data.message;
            },
            params: carrererValidationParamNames,
        });

        // TODO pasar a i18n
        const cpValidation = (value, { municipality, apiDomain } = {}) => {
            console.debug("Remote cp validation");
            return new BaseOfexServiceClient().abRestClient
                .get(`${apiDomain ? apiDomain : process.env.VUE_APP_API_CARRERER_BASE_URL}/cp/validate`, {
                    params: {
                        query: value,
                        municipality: municipality,
                    },
                })
                .then(
                    function (response) {
                        console.debug(response);
                        return {
                            valid: response.data,
                            data: {
                                message: `${options.i18n.tc("validations.codigoPostal")} ${value} ${options.i18n.tc(
                                    "validations.noMunicipio"
                                )} ${municipality}`,
                            },
                        };
                    },
                    function (error) {
                        console.error(error.data);
                        return {
                            valid: error.data,
                            data: {
                                message: `${options.i18n.tc("validations.codigoPostal")} ${value} ${options.i18n.tc(
                                    "validations.noMunicipio"
                                )} ${municipality}`,
                            },
                        };
                    }
                );
        };

        const cpValidationParamNames = ["municipality", "apiDomain"];

        extend("cp", {
            validate: cpValidation,
            getMessage: (field, params, data) => {
                return data.message;
            },
            params: cpValidationParamNames,
        });
    },
};
