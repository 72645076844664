<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="carrerer-container">
        <div v-if="showProvince" class="row">
            <div class="col-12 col-lg-4">
                <ab-province-select
                    v-model="provinceModel"
                    :name="carrererName + '-province'"
                    :input-alias="$t('carrerer.alias.provincia')"
                    :placeholder="$t('carrerer.placeholders.provincia')"
                    :label="$t('carrerer.labels.provincia')"
                    :validation-scope="validationScope"
                    :validation="{ required: true }"
                    :api-domain="apiDomain"
                >
                </ab-province-select>
            </div>
        </div>

        <div :class="showProvince ? 'row mt-5' : 'row'">
            <div class="col-12 col-lg-4">
                <ab-autocompletable-select
                    v-model="municipalityModel"
                    :name="carrererName + '-municipality'"
                    :input-alias="$t('carrerer.alias.municipio')"
                    placeholder="Seleccionar"
                    class="widthCarrererSelect"
                    :validation-scope="validationScope"
                    :validation="{ required: true }"
                    :data-list="municipalityDataSource"
                    data-key="name"
                    data-label="name"
                    :label="$t('carrerer.labels.municipio')"
                    @change="onMunicipalityChangeRecalculateDatasources"
                >
                </ab-autocompletable-select>
            </div>

            <div class="col-12 col-lg-3">
                <ab-autocompletable-select
                    v-model="streetTypeModel"
                    :name="carrererName + '-street-type'"
                    :input-alias="$t('carrerer.alias.tipoVia')"
                    placeholder="Seleccionar"
                    class="widthCarrererSelect"
                    :validation-scope="validationScope"
                    :validation="{ required: true }"
                    :data-list="streetTypeDataSource"
                    data-key="streetType"
                    :data-label="lang === 'ca' ? 'streetTypeOfexCAT' : 'streetTypeOfexESN'"
                    :label="$t('carrerer.labels.tipoVia')"
                    @change="onStreetTypeChangeRecalculateDatasources"
                >
                </ab-autocompletable-select>
            </div>

            <div class="col-12 col-lg-5">
                <ab-autocompletable-input
                    v-model="streetNameModel"
                    :name="carrererName + '-street-name'"
                    :input-alias="$t('carrerer.alias.nombreVia')"
                    class="widthNameVia"
                    :validation-scope="validationScope"
                    :validation="{
                        required: true,
                        carrerer: { municipality: municipalityModel.name, streetType: streetTypeModel.streetType, apiDomain: apiDomain },
                    }"
                    :data-list="streetDataSource"
                    :min-chars="autocompletableMinChars"
                    :auto-first="true"
                    validation-event="change"
                    :data-control="carrererDataControl"
                    :selected-filter="selectedFilter"
                    :label="$t('carrerer.labels.nombreVia')"
                    @autocompletableSelected="handleStreetSelection"
                >
                </ab-autocompletable-input>
            </div>
        </div>

        <div class="row mb-5 mt-5">
            <div class="col-12 col-lg-2">
                <ab-autocompletable-select
                    v-model="numberModel"
                    :name="carrererName + '-number'"
                    :input-alias="$t('carrerer.alias.numero')"
                    placeholder="Seleccionar"
                    :validation-scope="validationScope"
                    :validation="{ required: true }"
                    :data-list="numberCatalogueDataSource"
                    data-key="locationCod"
                    data-label="numbers"
                    :label="$t('carrerer.labels.numeroVia')"
                    :readonly="numberCatalogueDataSource.length === 0"
                    @autocompletableSelected="handleNumberSelection"
                >
                </ab-autocompletable-select>
            </div>
            <div class="col-12 col-lg-6">
                <ab-autocompletable-select
                    v-model="destinyModel"
                    :name="carrererName + '-destiny'"
                    :input-alias="$t('carrerer.alias.destiny')"
                    placeholder="Seleccionar"
                    :validation-scope="validationScope"
                    :validation="{ required: true }"
                    :data-list="destinyCatalogueDataSource"
                    class="widthCarrererSelect"
                    data-key="supply"
                    data-label="numberDesti"
                    :label="$t('carrerer.labels.pisopuerta')"
                    :readonly="destinyCatalogueDataSource.length === 0"
                >
                </ab-autocompletable-select>
            </div>

            <div class="col-12 col-lg-3">
                <ab-postal-code-select
                    v-model="cpModel"
                    :city="municipalityModel.name"
                    :api-domain="apiDomain"
                    :name="carrererName + '-cp'"
                    :input-alias="$t('carrerer.alias.cp')"
                    :validation-scope="validationScope"
                >
                </ab-postal-code-select>
            </div>
        </div>

        <!--region accesibilidad-->
        <div id="carrererInfo" role="region" aria-live="polite" class="d-none">
            <h2 :id="'carrererInfoTitle' + carrererName">Dirección introducida</h2>
            <p :id="'provinceInfoTitle' + carrererName">Provincia: {{ carrerer.province }}</p>
            <p :id="'municipalityInfoTitle' + carrererName">Municipio: {{ carrerer.municipality.name }}</p>
            <p :id="'addressInfoTitle' + carrererName">
                Dirección:: {{ carrerer.streetType.streetTypeOfexESN }} {{ carrerer.streetName }} {{ carrerer.number }}
            </p>
        </div>
    </div>
</template>

<script>
// import cloneDeep from 'lodash/cloneDeep'

import AbAutocompletableInput from "./AbAutocompletableInput.vue";
import AbAutocompletableSelect from "./AbAutocompletableSelect.vue";
import AbProvinceSelect from "./AbProvinceSelect.vue";
import AbPostalCodeSelect from "./AbPostalCodeSelect.vue";

export default {
    components: {
        AbAutocompletableInput,
        AbAutocompletableSelect,
        AbProvinceSelect,
        AbPostalCodeSelect,
    },
    model: {
        prop: "carrererModel",
        event: "carrerer-changed",
    },
    props: {
        validation: Object,
        validationScope: String,
        validationEvent: String,
        carrererName: String,
        showProvince: {
            default: false,
            type: Boolean,
        },
        autocompletableMinChars: {
            default: 1,
            type: Number,
        },
        apiDomain: {
            required: false,
            default: function () {
                return carrererApiBaseUrl;
            },
            type: String,
        },
        apiDomainCatalogue: {
            required: false,
            default: function () {
                return carrererApiCatalogueBaseUrl;
            },
            type: String,
        },
    },
    data: function () {
        return {
            lang: "es",
            selectedFilter: function (dataList, val, label) {
                if (!dataList) console.warn("Datalist is not valid!!");

                return dataList.filter(function (item) {
                    return item.streetName === val && item.municipality === label.substring(label.lastIndexOf(", ") + 2) && label.includes(item.streetType);
                })[0];
            },
            // eslint-disable-next-line no-unused-vars
            carrererDataControl: function (item, input) {
                return {
                    label: item.streetName + " (" + item.streetType + "), " + item.municipality,
                    // label: item.streetName + " (" + item.streetType + ")",
                    value: item.streetName,
                };
            },
            carrerer: {
                municipality: [],
                province: { id: "08", name: "BARCELONA" },
                sapCode: "",
                streetName: "",
                streetType: [],
                number: "",
                destiny: "",
                cp: "",
            },
            streetDataSource: [],
            streetTypeDataSource: [],
            municipalityDataSource: [],
            numberCatalogueDataSource: [],
            destinyCatalogueDataSource: [],
        };
    },
    computed: {
        /*modelValue: {
            get: function () {
                return this.$attrs["carrererModel"];
            },
            set: function (newValue) {
                this.$emit('carrerer-changed', newValue)
            }
        },*/

        provinceModel: {
            get: function () {
                return this.$attrs["carrererModel"].province;
            },
            set: function (newValue) {
                this.carrerer.province = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        municipalityModel: {
            get: function () {
                return this.$attrs["carrererModel"].municipality;
            },
            set: function (newValue) {
                this.carrerer.municipality = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        streetTypeModel: {
            get: function () {
                return this.$attrs["carrererModel"].streetType;
            },
            set: function (newValue) {
                this.carrerer.streetType = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        streetNameModel: {
            get: function () {
                return this.$attrs["carrererModel"].streetName;
            },
            set: function (newValue) {
                this.carrerer.streetName = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        numberModel: {
            get: function () {
                return this.$attrs["carrererModel"].number;
            },
            set: function (newValue) {
                this.carrerer.number = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        destinyModel: {
            get: function () {
                return this.$attrs["carrererModel"].destiny;
            },
            set: function (newValue) {
                this.carrerer.destiny = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        cpModel: {
            get: function () {
                return this.$attrs["carrererModel"].cp;
            },
            set: function (newValue) {
                this.carrerer.cp = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        scopedName: function () {
            return this.validationScope + "." + this.name;
        },
        calculatedValidationEvent: function () {
            if (this.validationEvent !== undefined) return this.validationEvent;
            else return "input";
        },
    },
    watch: {
        "carrerer.province": function (province) {
            console.debug("Provincia cambiada: " + province.name);

            this.municipalityModel = [];
            this.streetTypeModel = [];
            this.streetNameModel = "";
            this.numberModel = "";
            this.resetDestinyAndNumber();

            this.calculateDatasource(this.getMunicipalitiesRestURL(province.name), this, "municipalityDataSource");
            this.calculateDatasource(`${this.apiDomain}/streettypes/all`, this, "streetTypeDataSource");
        },
    },
    created() {
        this.$emit("carrerer-changed", this.carrerer);

        this.lang = typeof themeDisplay !== "undefined" ? themeDisplay.getLanguageId().substring(0, 2) : "es";
        this.$validator = this.$parent.$validator;

        this.calculateDatasource(`${this.apiDomain}/streets/all`, this, "streetDataSource", { allMunicipality: this.showProvince });
        this.calculateDatasource(this.getMunicipalitiesRestURL(this.carrerer.province.name), this, "municipalityDataSource");
        this.calculateDatasource(`${this.apiDomain}/streettypes/all`, this, "streetTypeDataSource");

        console.debug("CARRERER CREATED");
    },
    mounted() {
        console.debug("CARRERER MOUNTED");
    },
    methods: {
        handleStreetSelection: function (streetSelection) {
            console.log(`Handling street selection: ${streetSelection}`);
            if (streetSelection) {
                this.municipalityModel = this.calculateMunicipalityDetail(streetSelection.municipality);
                this.streetTypeModel = this.calculateStreetTypeDetail(streetSelection.streetType);
                this.streetNameModel = streetSelection.streetName;
                this.carrerer.sapCode = streetSelection.sapCode;
                // this.$emit('carrerer-changed', this.carrerer)
            }

            // TODO parametros dinamicos
            this.calculateDatasource(`${this.apiDomainCatalogue}/estates-by-street`, this, "numberCatalogueDataSource", {
                municipalityCode: this.carrerer.municipality.agbarCode,
                streetCode: this.carrerer.sapCode,
            });
        },

        handleNumberSelection: function (numberSelection) {
            console.log(`Estate selected: ` + numberSelection);
            this.calculateDatasource(`${this.apiDomainCatalogue}/destinations-by-estate`, this, "destinyCatalogueDataSource", numberSelection);
        },
        calculateDatasource: function (url, vueContext, targetName, params, callback) {
            console.debug("Filling carrerer street data source");
            this.$http.get(url, { params: params }).then(
                function (response) {
                    console.debug("OK calling to -> " + url);
                    vueContext[targetName] = response.body;
                    if (callback) callback.call();
                },
                function (error) {
                    console.debug("ERROR calling to -> /o/carrerer/streets/all");
                    console.error(error);
                }
            );
        },
        calculateMunicipalityDetail: function (municipalityName) {
            return this.municipalityDataSource.find(function (item) {
                return item.name === municipalityName;
            });
        },
        calculateStreetTypeDetail: function (streetTypeName) {
            return this.streetTypeDataSource.find(function (item) {
                return item.streetType === streetTypeName;
            });
        },
        onMunicipalityChangeRecalculateDatasources: function () {
            console.debug("Recalculando tipos de vias");
            this.calculateDatasource(`${this.apiDomain}/streettypes/all`, this, "streetTypeDataSource", {
                municipality: this.carrerer.municipality.name,
            });
            this.streetNameModel = "";
            this.recalculateStreetDatasource();
            this.resetDestinyAndNumber();
        },
        onStreetTypeChangeRecalculateDatasources: function () {
            this.recalculateStreetDatasource();
        },
        recalculateStreetDatasource: function () {
            console.debug(
                "Filtering street datasource by municipality: " + this.carrerer.municipality.name + " and street type " + this.carrerer.streetType.streetType
            );
            this.calculateDatasource(`${this.apiDomain}/streets/filter`, this, "streetDataSource", {
                municipality: this.carrerer.municipality.name,
                type: this.carrerer.streetType.streetType,
            });
        },
        getMunicipalitiesRestURL: function (provinceName) {
            return `${this.apiDomain}/municipalities/province?province=${provinceName}&validate=${this.showProvince ? "false" : "true"}`;
        },
        resetDestinyAndNumber: function () {
            this.destinyModel = "";
            this.numberModel = "";
            this.destinyCatalogueDataSource = [];
            this.numberCatalogueDataSource = [];
        },
    },
};
</script>

<style lang="scss">
@import "../assets/styles/common.scss";
</style>
