<template>
    <b-card-body>
        <b-card-text>
            <div class="dropdown-inner">
                <div>
                    <p
                        v-if="getContractsWithDigitalInvoices.length > 0"
                        v-html="multicontract ? $t('ofex.UserData.deseasBajaFsp') : $t('ofex.UserData.deseasBajaMonoFsp')"
                    ></p>
                    <p v-else v-html="multicontract ? $t('ofex.UserData.deseasBaja') : $t('ofex.UserData.deseasBajaMono')"></p>
                    <!-- todo: este bloque solo debe aparecer con usuarios monocontrato -->
                    <div v-if="contract && !multicontract && getContractsWithDigitalInvoices.length > 0">
                        <p class="adress">{{ this.streetTypeMailAddres() }} {{ contract.mailAddress.otherAddressData }}</p>
                        <p>
                            {{ $t("ofex.UserData.otraDireccion") }}
                            <!--TODO:Va una modal o redireccion? -->
                            <router-link
                                :to="{
                                    name: viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.name,
                                    params: { contractId: this.selectedContractNumber },
                                }"
                                >{{ $t("ofex.UserData.datosCorrespondencia") }}</router-link
                            >
                        </p>
                    </div>
                    <!--  -->
                    <p>
                        {{ $t("ofex.UserData.lamentamosDecision") }}
                    </p>
                </div>
                <div class="textarea-container">
                    <ab-text-area
                        name="observaciones"
                        v-bind:max-length="1000"
                        v-bind:input-alias="$t('ofex.UserData.observaciones')"
                        v-model="reason"
                        v-bind:label="$t('ofex.UserData.motivoBaja')"
                    >
                    </ab-text-area>
                </div>
                <div class="container-buttons mb-3">
                    <ab-button-ofex class="caja-button" :text-button="$t('ofex.buttonsOfex.enviar')" :click-event="deleteUser"></ab-button-ofex>
                </div>
                <ab-text-end></ab-text-end>
            </div>
        </b-card-text>
    </b-card-body>
</template>

<script>
import { trimBackendCode } from "@/plugins/ab-utils";
import * as viewMappingTypes from "../../../router/view-mapping-types";
import { mapGetters, mapState } from "vuex";
import UnsubscribeUserPdfGeneator from "@/generators/pdf/UnsubscribeUserPdfGeneator";
import * as actionTypes from "@/store/action-types";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
// import * as actionTypes from "@/store/action-types";

export default {
    name: "UnsubscribeUser",
    data: function () {
        return {
            showCompare: false,
            reason: "",
            viewMappingTypes: viewMappingTypes,
        };
    },
    computed: {
        ...mapState(["userData", "selectedContractNumber", "clientData"]),
        ...mapGetters(["multicontract", "getContractByContractNumber", "isCompany", "clientId", "userId", "getContractsWithDigitalInvoices"]),
        contract: function () {
            return this.getContractByContractNumber(this.selectedContractNumber);
        },
    },
    mounted() {
        this.localUserData = Object.assign({}, this.userData);
    },
    methods: {
        deleteUser: function () {
            Swal.fire({
                title: this.$t("ofex.UserData.deleteUserMessage"),
                showCancelButton: true,
                confirmButtonText: this.$t("common.okButton"),
                cancelButtonText: this.$t("ofex.UserData.cancelButton"),
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let pdfGenerator = new UnsubscribeUserPdfGeneator(this.$i18n, this.userId, this.clientId, "");
                    pdfGenerator.generatePdf(this.reason, this.isCompany, this.userData, this.clientData);
                    await pdfGenerator.uploadPdf();
                    console.log("ID del pdf " + pdfGenerator.uploadedDocumentId);
                    this.$store
                        .dispatch(actionTypes.DELETE_USER, {
                            localUserData: this.localUserData,
                            reason: this.observations,
                            pdfDocumentId: pdfGenerator.uploadedDocumentId,
                        })
                        .then((value) => {
                            this.$notifyFormActionToMatomo(true, "unsubscribe_user_modification");
                            Swal.fire({
                                text: this.$t("ofex.UserData.deleteUserConfirmMessage"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                            }).then(() => {
                                console.debug("Deslogando usuario" + value);
                                this.$store.dispatch(actionTypes.LOGOUT_ACTION);
                            });
                        })
                        .catch((error) => {
                            this.$notifyFormActionToMatomo(false, "unsubscribe_user_modification", error);
                        });
                } else if (result.isDenied) {
                    Swal.fire("Changes are not saved", "", "info");
                }
            });
        },
        streetTypeBackendCode: function () {
            return trimBackendCode(this.contract.mailAddress.streetType);
        },
        streetTypeMailAddres: function () {
            return this.$t("ofex.backendCodes.streetTypes." + this.streetTypeBackendCode());
        },
    },
};
</script>
