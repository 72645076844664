<template>
    <div id="info-block">
        <div id="screen-title" class="">
            <span>{{ $t("ofex.nativeAppInfoView.information") }}</span>
        </div>
        <div id="version-block">
            <div id="version-title" class="title">
                <img alt="info-icon" class="block-icon" :src="require(`../assets/images/info.png`)" />
                <span>{{ $t("ofex.nativeAppInfoView.versionApp") }}</span>
            </div>
            <div id="version-info" class="content">
                <span>Build: {{ appInfo.build }}. {{ $t("ofex.nativeAppInfoView.version") }}: {{ appInfo.version }}</span>
            </div>
        </div>
        <div id="legal-terms-block">
            <div id="legal-terms-icon" class="title">
                <img alt="info-icon" class="block-icon" :src="require(`../assets/images/app.png`)" />
                <span>{{ $t("ofex.nativeAppInfoView.legalTerms") }}</span>
            </div>
            <div id="legal-terms-info" class="content">
                <span>{{ $t("ofex.nativeAppInfoView.legalTermsInfo") }}</span>
                <ul>
                    <li>
                        <a :href="$t('alias.ofex.nativeAppInfoView.legalTermsHref1')">
                            <font-awesome-icon class="" icon="arrow-right" /><span>{{ $t("ofex.nativeAppInfoView.legalTermsLink1") }}</span>
                        </a>
                    </li>
                    <li>
                        <a :href="$t('alias.ofex.nativeAppInfoView.legalTermsHref2')">
                            <font-awesome-icon class="" icon="arrow-right" /><span>{{ $t("ofex.nativeAppInfoView.legalTermsLink2") }}</span>
                        </a>
                    </li>
                    <li>
                        <a :href="$t('alias.ofex.nativeAppInfoView.legalTermsHref3')">
                            <font-awesome-icon class="" icon="arrow-right" /><span>{{ $t("ofex.nativeAppInfoView.legalTermsLink3") }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div id="interest-numbers-block">
            <div id="interest-numbers-icon" class="title">
                <img alt="info-icon" class="block-icon" :src="require(`../assets/images/phone.png`)" />
                <span>{{ $t("ofex.nativeAppInfoView.interestingNumbers") }}</span>
            </div>
            <div id="interest-numbers-info" class="content">
                <span
                    >{{ $t("ofex.nativeAppInfoView.customerAttention") }}: <a href="tel:935219777">935 219 777</a> /
                    <a href="tel:900710710">900 710 710</a></span
                >
                <span>{{ $t("ofex.nativeAppInfoView.failures") }}: <a href="tel:900700720">900 700 720</a></span>
            </div>
        </div>
        <div id="offices-block">
            <div id="offices-icon" class="title">
                <img alt="info-icon" class="block-icon" :src="require(`../assets/images/person.png`)" />
                <router-link to="/native_app_offices_info" class="href-password">
                    <span>{{ $t("ofex.nativeAppInfoView.offices") }}</span> <font-awesome-icon class="" icon="arrow-right" />
                </router-link>
            </div>
        </div>
        <div id="go-back-info">
            <router-link to="/login" class="href-password">
                <font-awesome-icon class="" icon="arrow-left" /><span>{{ $t("ofex.nativeAppInfoView.back") }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { App } from "@capacitor/app";
export default {
    name: "NativeAppInfo",
    data() {
        return {
            appInfo: null,
        };
    },
    methods: {},
    mounted() {
        App.getInfo().then((value) => (this.appInfo = value));
    },
};
</script>

<style lang="scss" scoped>
#info-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px;
    border: 1px solid #284f6a;
    border-radius: 10px;
    color: #284f6a;

    #screen-title {
        height: 50px;
        text-align: center;
        background-color: #284f6a;
        color: white;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        display: flex;
        justify-content: center;

        span {
            align-self: center;
        }
    }
    #go-back-info {
        height: 50px;
        text-align: center;
        background-color: #d8dce1;
        color: #284f6a;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        display: flex;
        justify-content: center;

        a {
            align-self: center;
            display: flex;
            gap: 10px;
            justify-content: center;

            svg {
                width: 25px;
                height: 25px;
            }

            span {
                line-height: 25px;
            }
        }
    }

    #version-block,
    #legal-terms-block,
    #interest-numbers-block {
        border-bottom: 1px solid #dde2e4;
    }

    #version-block,
    #legal-terms-block,
    #interest-numbers-block,
    #offices-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;

        .title {
            display: flex;
            align-items: center;
            gap: 15px;

            img {
                width: 45px;
            }

            span {
                font-size: 1.2rem;
                font-weight: 700;
            }

            a {
                display: flex;
                gap: 15px;
                align-items: center;
                color: #284f6a;

                svg {
                    width: 14px;
                    height: 14px;
                    background-color: #284f6a;
                    color: white;
                    border-radius: 100%;
                    padding: 2px;
                }

                span {
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-left: 60px;
            font-size: 0.9rem;
            font-weight: 600;

            span {
                line-height: 1.2rem;
                font-size: 0.85rem;
                font-weight: 700;
            }

            ul {
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                gap: 8px;

                li {
                    padding: 0 15px;

                    a {
                        display: flex;
                        gap: 15px;
                        align-items: center;
                        color: #284f6a;

                        svg {
                            width: 14px;
                            height: 14px;
                            background-color: #284f6a;
                            color: white;
                            border-radius: 100%;
                            padding: 2px;
                        }

                        span {
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}
</style>
