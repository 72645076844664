"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";

export default class ContractPartialModificationPdfGenerator extends BaseGenerator {
    constructor(i18n, user, client, contract) {
        super("NuevaModificacionParcial.pdf", "", i18n, user, client, contract);
    }
    generatePdf(client, person, contractMap) {
        console.log(`Generando pdf nueva modificación parcial`);
        const SPACE = " ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.partialModification.title"), "");

        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.newAuth.dataAuthorizator"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.typeDocument") + SPACE + client.documentType);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.numberDocument") + SPACE + client.documentNumber);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.nombre") + SPACE + client.name);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.primerApellido") + SPACE + client.firstLastName);
        if (client.secondLastName) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.segundoApellido") + SPACE + client.secondLastName);
        }
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.tfnoPrincipal") + SPACE + client.mainPhone);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.email") + SPACE + client.email);

        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.newAuth.dataAuthorizated"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.numberDocument") + SPACE + person.documentNumber);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.nombre") + SPACE + person.firstName);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.primerApellido") + SPACE + person.firstLastName);
        if (person.secondLastName) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.segundoApellido") + SPACE + person.secondLastName);
        }
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.tfnoPrincipal") + SPACE + person.phone);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.email") + SPACE + person.email);

        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.partialModification.contracts"));
        let contractForString = [];
        contractMap.map((contract) => {
            let contractValueText = this.i18n.tc("ofex.pdfGenerator.partialModification.notAuth");
            if (contract.value === true) contractValueText = this.i18n.tc("ofex.pdfGenerator.partialModification.auth");
            contractForString.push(
                this.i18n.tc("ofex.pdfGenerator.partialModification.contractNumber") +
                    SPACE +
                    contract.contractNumber +
                    SPACE +
                    "(" +
                    this.i18n.tc("ofex.pdfGenerator.partialModification.newValue") +
                    SPACE +
                    contractValueText +
                    ")"
            );
        });
        this.generateMultilineText(contractForString.join(", "));

        return this.pdf;
    }
}
