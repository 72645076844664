"use strict";

import BaseXlsxGenerator from "@/generators/xlsx/base/BaseXlsxGenerator";
import * as xlsx from "xlsx";

export default class InvoiceEvolutionChartListXlsxGenerator extends BaseXlsxGenerator {
    constructor(filename, rawList, i18n) {
        let rows = rawList.map((x) => {
            return {
                startDate: x.startDate,
                endDate: x.endDate,
                consumptionVolume: x.consumptionVolume,
                amount: x.amount,
                waterServiceAmount: x.waterServiceAmount,
                waterCanonAmount: x.waterCanonAmount,
                tmtrAmount: x.tmtrAmount != null ? x.tmtrAmount : "",
                trrAmount: x.trrAmount != null ? x.trrAmount : "",
                clavegueramAmount: x.clavegueramAmount != null ? x.clavegueramAmount : "",
                ivaAmount: x.ivaAmount != null ? x.ivaAmount : "",
            };
        });

        let consumptionsSheet = xlsx.utils.json_to_sheet(rows);
        consumptionsSheet["A1"]["v"] = i18n.tc("evolucionFacturas.periodoConsumo");
        consumptionsSheet["B1"]["v"] = i18n.tc("evolucionFacturas.periodoHasta");
        consumptionsSheet["C1"]["v"] = i18n.tc("evolucionFacturas.consumos");
        consumptionsSheet["D1"]["v"] = i18n.tc("evolucionFacturas.importe");
        consumptionsSheet["E1"]["v"] = i18n.tc("evolucionFacturas.servicioAgua");
        consumptionsSheet["F1"]["v"] = i18n.tc("evolucionFacturas.canon");
        consumptionsSheet["G1"]["v"] = i18n.tc("evolucionFacturas.tmrt");
        consumptionsSheet["H1"]["v"] = i18n.tc("evolucionFacturas.trr");
        consumptionsSheet["I1"]["v"] = i18n.tc("evolucionFacturas.alcantarillado");
        consumptionsSheet["J1"]["v"] = i18n.tc("evolucionFacturas.iva");
        super(filename, consumptionsSheet);
    }
}
