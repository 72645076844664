import createMapper from "map-factory";

const AB_DATE_FORMAT = "DD-MM-YYYY";
// const AB_DATETIME_FORMAT = "DD-MM-YYYYTHH:mm:ssZ";
const AB_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
const AB_DATETIME_FRIENDLY_FORMAT = "DD-MM-YYYY HH:mm:ss";
const AB_DATETIME_WITHOUT_SECONDS_FORMAT = "DD-MM-YYYY HH:mm";
const AB_TIME_FORMAT = "HH:mm";

//Modelo de carrerer vacío
function emptyCarrerer() {
    return {
        municipality: {
            ineCode: "",
            agbarCode: "",
            name: "",
            validate: false,
            sap: "",
            provinceCode: "",
            provinceName: "",
        },
        province: { id: "08", name: "BARCELONA" },
        sapCode: "",
        streetName: "",
        streetType: {
            code: "",
            streetType: "",
            streetTypeOfexESN: "",
            streetTypeOfexCAT: "",
        },
        number: "",
        piso: "",
        puerta: "",
        escalera: "",
        destiny: "",
        cp: "",
    };
}

function getI18nTextFromKey(key, array) {
    let result = array.filter((i) => i.key === key);
    return result.length > 0 ? result[0].text : key;
}

//TODO migrar ya que el IE11 no soporta el IE11
/**
 * Transformamos el modelo de datos del Carrerer a el que espera la interfaz REST
 *
 * @param carrererObj
 * @returns {*}
 */
function mapCarrererToRestModel(carrererObj) {
    const map = createMapper();

    map("cp").to("codigoPostal");
    map("province.id").to("provincia");
    map("municipality.ineCode").to("municipio");
    map("streetType.streetType").to("tipoVia");
    map("streetName").to("nombreVia");
    map("number").to("numero");
    map("piso").to("piso");
    map("escalera").to("escalera");
    map("puerta").to("puerta");

    return map.execute(carrererObj);
}

function calculateChartMaxYAxis(data, stepSize) {
    let roundedData = data.filter((value) => value !== null).map((value) => value.toFixed(0));
    // eslint-disable-next-line no-constant-condition
    for (let i = Math.max(...roundedData) + 1; true; i++) {
        if (i % stepSize === 0) {
            return i + stepSize;
        }
    }
}

function trimBackendCode(code) {
    if (code) {
        let replaced = code
            .replaceAll(" ", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("+", "")
            .replaceAll(/ /g, "");
        return replaced && replaced.length > 0 ? replaced : "DEFAULT";
    } else {
        return "DEFAULT";
    }
}

function isValidDateRange(dateRange) {
    return dateRange != null && dateRange.length >= 2 && dateRange[0] != null && dateRange[1] != null;
}

function isDroughtAffected(contractEntry) {
    return (
        contractEntry?.droughtData?.maxExpectedAffectationCode !== null &&
        contractEntry.droughtData.maxExpectedAffectationCode.length > 0 &&
        contractEntry.droughtData.maxExpectedAffectationCode !== "00"
    );
}

function toggleLiferayHomeContentVisibility(display) {
    if (document.getElementById("home-squares-journal")) {
        document.getElementById("home-squares-journal").style.display = display;
    }
}

function cryptoSha256(text) {
    let forge = require("node-forge");
    let md = forge.md.sha256.create();
    md.update(text, "utf8");
    return md.digest().toHex();
}

async function cryptoSha256Native(text) {
    let dataBuffer = new TextEncoder().encode(text);
    let hashBuffer = await crypto.subtle.digest("SHA-256", dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
}

async function sleep(msec) {
    return new Promise((resolve) => setTimeout(resolve, msec));
}

function fileToBase64(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
        reader.readAsDataURL(blob);
    });
}

export {
    getI18nTextFromKey,
    mapCarrererToRestModel,
    emptyCarrerer,
    calculateChartMaxYAxis,
    trimBackendCode,
    isValidDateRange,
    isDroughtAffected,
    cryptoSha256,
    cryptoSha256Native,
    toggleLiferayHomeContentVisibility,
    sleep,
    fileToBase64,
    AB_DATE_FORMAT,
    AB_DATETIME_FORMAT,
    AB_DATETIME_FRIENDLY_FORMAT,
    AB_DATETIME_WITHOUT_SECONDS_FORMAT,
    AB_TIME_FORMAT,
};
