import Vue from "vue";
import App from "./App.vue";
import { App as CapacitorApp } from "@capacitor/app";
import i18n from "./i18n";
import store from "./store/store";
import router from "./router/router";

import * as AbOfexComponents from "./index";
import validatorConfigurator from "./plugins/vue-ab-validator-configurator";
import commonPlugin from "./plugins/vue-ab-common";
import { StatusBar } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import * as mutationTypes from "@/store/mutation-types";
import { localeChanged } from "vee-validate";
import { getPreferenceValue } from "@/services/nativePreferences";
import { NATIVE_LOCALE_PREFERENCE_KEY } from "@/services/services-constant-types";

Vue.config.productionTip = false;

console.log(process.env.VUE_APP_API_LOGIN_GET_TOKEN);

Vue.use({ install: AbOfexComponents.install });
Vue.use(validatorConfigurator, { i18n: i18n });
Vue.use(commonPlugin, {
    gtmContainer: process.env.VUE_APP_GTM_ID,
    router: router,
});

const vueInstance = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

CapacitorApp.addListener("appUrlOpen", function (event) {
    // Example url: https://beerswift.app/tabs/tabs2
    // slug = /tabs/tabs2
    const slug = event.url.split("area-clientes#").pop();

    console.log("Redireccionando a " + slug);
    // We only push to the route if there is a slug present
    if (slug) {
        router
            .push({
                path: slug,
            })
            .then((result) => console.log("Ruta resuelta" + result));
    }
});

if (Capacitor.isNativePlatform()) {
    SplashScreen.show({
        showDuration: 2000,
        autoHide: true,
    }).then((r) => console.debug("Splash result " + r));

    getPreferenceValue(NATIVE_LOCALE_PREFERENCE_KEY).then((lang) => {
        console.debug("Obtenido locale almacenado (main.js 60) " + lang);
        if (lang !== null) {
            console.debug("Cambiando idioma");
            vueInstance.$i18n.locale = lang;
            store.commit(mutationTypes.CHANGE_I18N_LOCALE, { i18nLocale: lang });
            localeChanged();
        }
    });
}

if (Capacitor.getPlatform() === "ios") {
    StatusBar.hide().then((r) => console.debug("Ocultando status bar en iOS. " + r));
}
