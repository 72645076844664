<template>
    <div :class="fatherClass">
        <ab-loading v-model="fileUploading" :full-page="true"></ab-loading>
        <div class="row">
            <div class="col-12" :class="{ 'extra-margin-bottom': $isNativeApp() }">
                <div class="float-left">
                    <label class="letraform-new w-100">
                        {{ label }}
                    </label>
                </div>
                <div class="float-left" :class="{ 'extra-margin-left': $isNativeApp() }">
                    <ab-popover :popover="popover" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <validation-provider
                    :vid="`${name}-vid`"
                    :name="inputAlias"
                    :rules="{ required: required, size: maxSize, mimes: allowedExtensions }"
                    ref="provider"
                    v-slot="{ validate, errors }"
                >
                    <input :id="name" ref="fileInput" type="file" hidden="hidden" @change="processSelectedFiles" />
                    <button type="button" class="custom-button" :class="{ 'btn-validation-error': errors.length > 0 }" @click="triggerUploadFile">
                        <font-awesome-icon v-if="!hasAtachment" icon="paperclip" class="azuleta mr-2" @click="triggerUploadFile"></font-awesome-icon>
                        <font-awesome-icon v-if="hasAtachment" icon="trash" class="azuleta mr-2" @click.stop="clearInput"></font-awesome-icon>
                        <!--<i v-if="!hasAtachment" class="fas fa-paperclip azuleta mr-1"></i>
                    <i v-if="hasAtachment" class="fas fa-trash-alt azuleta mr-1"></i>-->
                        <span v-if="!hasAtachment">{{ $t("upload.adjuntarArchivo") }}</span>
                        <span v-if="hasAtachment">{{ truncatedFileName }}</span>
                    </button>
                    <p v-show="errors.length > 0" class="formTextErr">{{ errors[0] }}</p>
                </validation-provider>
            </div>
        </div>
        <span :id="'name-fichero' + name" class="d-none"></span>
    </div>
</template>

<script>
import AbPopover from "./AbPopover.vue";
import AbLoading from "./AbLoading.vue";
import { fileToBase64 } from "@/plugins/ab-utils";

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { AbPopover, AbLoading },
    model: {
        prop: "attachment",
        event: "change",
    },
    props: {
        inputAlias: {
            default: function () {
                return this.$i18n.tc("upload.alias");
            },
            type: String,
        },
        homeUrl: String,
        uploadUrl: String,
        name: String,
        actividad: {
            default: "OF" + new Date().getTime(),
            type: String,
        },
        required: {
            default: false,
            type: Boolean,
        },
        maxSize: {
            // tamaño max en KB
            default: 10240,
            type: Number,
        },
        label: {
            default: function () {
                return this.$i18n.tc("upload.ficheroAdjunto");
            },
            type: String,
        },
        popover: {
            required: false,
            type: String,
        },
        user: {
            required: false,
            type: String,
        },
        client: {
            required: false,
            type: String,
        },
        usePublicSubscription: {
            default: false,
            type: Boolean,
        },
        contract: {
            required: false,
            type: String,
        },
        buttonText: {
            //TODO traducir
            default: function () {
                return this.$i18n.tc("upload.adjuntarArchivo");
            },
            type: String,
        },
        fatherClass: {
            type: String,
            default: "",
        },
        apiBaseUrl: {
            required: false,
            default: "",
            type: String,
        },
        allowedExtensions: {
            type: String,
            default: function () {
                return "doc|docx|pdf|jpeg|png|tiff";
            },
        },
        validation: {
            type: Object,
        },
    },
    data: function () {
        return {
            fileUploading: false,
            attachmentData: {
                file: null,
                ecmProps: { fileId: null, actividadId: null, documentoId: null },
            },
        };
    },
    computed: {
        hasAtachment: function () {
            return this.attachmentData.file !== null;
        },
        truncatedFileName: function () {
            return this.truncateText(this.attachmentData.file.name, 20);
        },
    },
    methods: {
        clearInput: async function () {
            await this.deleteFileFromStorage();
            this.$refs.fileInput.value = "";
            this.attachmentData.file = null;
            this.$emit("change", null);
        },
        processSelectedFiles: async function (e) {
            const { valid } = await this.$refs.provider.validate(e);
            if (valid) {
                console.log("Se ha adjuntado");
                let fileIn = this.$refs.fileInput;
                let files = fileIn.files;
                this.attachmentData.file = files[0];
                await this.uploadFileToECM();
                this.$emit("change", this.attachmentData);
            } else {
                console.log("Changed attachment");
                let fileIn = this.$refs.fileInput;
                if (fileIn.files.length == 0) {
                    console.log("Se ha borrado");
                    this.attachmentData.file = null;
                    this.$emit("change", null);
                }
            }
        },
        triggerUploadFile: function () {
            let fileIn = this.$refs.fileInput;
            fileIn.click();
        },
        truncateText: function (text, limit) {
            if (!text || !limit) return;
            const ending = "...";
            return text.length > limit ? text.substring(0, limit - ending.length) + ending : text;
        },
        errorUnload: function (error) {
            console.error(error);
            this.fileUploading = false;
        },
        uploadFileToECM: async function () {
            this.fileUploading = true;

            let jsonData = {
                actividadId: this.actividad,
                filename: this.attachmentData.file.name,
                adjuntoFormulario: true,
                userId: this.user ? this.user : "",
                clientId: this.client ? this.client : "",
                contract: this.contract ? this.contract : "SP",
                content: await fileToBase64(this.attachmentData.file),
            };

            this.$http
                .post(`${this.apiBaseUrl}/content-manager/upload/file-base64`, jsonData, {
                    params: {
                        clientId: this.client,
                        userId: this.user,
                    },
                    headers: {
                        "Ocp-Apim-Subscription-Key": this.usePublicSubscription
                            ? process.env.VUE_APP_APIM_PUBLIC_FORMS_SUBSCRIPTION_KEY_HEADER
                            : process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_NATIVE_HEADER,
                    },
                })
                .then((response) => {
                    if (response.status === 200 || response.status === 202 || response.status === 204) {
                        console.log("ID del fichero generado correctamente");
                        this.attachmentData.ecmProps.fileId = response.data.fileId;
                        this.attachmentData.ecmProps.actividadId = response.data.actividadId;
                        this.attachmentData.ecmProps.documentoId = response.data.documentoId;
                    }
                    this.fileUploading = false;
                })
                .catch((reason) => this.errorUnload(reason));
        },
        deleteFileFromStorage: function () {
            this.fileUploading = true;
            this.$http
                .delete(`${this.apiBaseUrl}/content-manager/files/${this.attachmentData.ecmProps.documentoId}`)
                .then((response) => {
                    if (response.status === 200 || response.status === 202 || response.status === 204) {
                        console.log("Borrado correctamente");
                    }
                    this.fileUploading = false;
                })
                .catch((reason) => this.errorUnload(reason));
        },
    },
};
</script>
<style lang="scss">
.custom-button {
    background: $color-lightest;
    border: 1px solid grey;
    height: 35px;
    color: $color-secondary;
    border-radius: 5px;
    cursor: pointer;
    width: 200px;
    outline: none;
}

.btn-validation-error {
    background: #feefef;
}

#custom-text {
    margin-left: 10px;
    font-family: sans-serif;
    color: #aaa;
}

.azuleta {
    color: $blue-dark;
}

.mostraOculto {
    display: block;
}

.far {
    cursor: pointer;
}

.buttonAdjuntar[type="checkbox"] {
    -webkit-appearance: none;
}

.adjuntarform {
    width: 150px;
    height: 32px !important;
    border-radius: 4px !important;
    border: 1px solid #979797 !important;
}

.extra-margin-left {
    margin-left: 10px;
}
.extra-margin-bottom {
    margin-bottom: 10px;
}
</style>
