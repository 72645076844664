<template>
    <div class="m-box-grey box" v-if="invoice">
        <div class="item">
            <div class="box-img-circle">
                <img alt="" class="img-circle" src="../../assets/images/ofex/pagar-factura/fuentes_innovacion.png" />
            </div>
            <div class="box-grey-text">
                <p class="number-contracte">{{ $t("ofex.pagarFactura.periodoConsumo") }}</p>
                <p class="data-text-blue">{{ invoice.startDate }}</p>
                <p class="data-text-blue">{{ invoice.endDate }}</p>
            </div>
        </div>
        <div class="item">
            <div class="box-img-circle">
                <img alt="" class="img-circle" src="../../assets/images/ofex/pagar-factura/agenda.png" />
            </div>
            <div class="box-grey-text">
                <p class="number-contracte">{{ $t("ofex.pagarFactura.fechaEmision") }}</p>
                <p class="data-text-blue">{{ invoice.issueDate }}</p>
            </div>
        </div>
        <div class="item">
            <div class="box-img-circle">
                <img alt="" class="img-circle" src="../../assets/images/ofex/pagar-factura/indicadores_2.png" />
            </div>
            <div class="box-grey-text">
                <p class="number-contracte">{{ $t("ofex.pagarFactura.consumoRealizado") }}</p>
                <p class="data-text-blue">{{ invoice.consumptionVolume }} m<sup>3</sup></p>
                <p class="data-text-blue" v-if="invoice.consumptionDays > 0">{{ $formatNumber(litersByDay, 0, 0) }} l/{{ $t("ofex.pagarFactura.dia") }}</p>
            </div>
        </div>
        <div class="item">
            <div class="box-img-circle">
                <img alt="" class="img-circle" src="../../assets/images/ofex/pagar-factura/factura_y_tarifas.png" />
            </div>
            <div class="box-grey-text">
                <p class="number-contracte">{{ $t("ofex.pagarFactura.importeFactura") }}</p>
                <p class="data-text-blue">{{ $formatNumber(invoice.amount) }} €</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "InvoiceGreyBox",
    props: {
        invoice: {
            type: Object,
            required: true,
        },
    },
    computed: {
        litersByDay: function () {
            return (this.invoice.consumptionVolume * 1000) / this.invoice.consumptionDays;
        },
    },
};
</script>

<style lang="scss" scoped>
.m-box-grey {
    display: flex;
    justify-content: space-between;
    background-color: #f8f8f8;
    padding: 15px;

    @include respond-below(lg) {
        flex-wrap: wrap;
    }

    .item {
        display: flex;
        padding-right: 10px;
        padding-left: 10px;
        border-right: 1px solid $blue-dark;
        flex-basis: 25%;

        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            padding-right: 0;
            border: 0;
        }
        @include respond-below(lg) {
            flex-basis: 47%;

            &:nth-child(2) {
                padding-right: 0;
                border: 0;
            }
            &:nth-child(3) {
                padding-left: 0;
                margin-top: 20px;
            }
            &:nth-child(4) {
                padding-right: 0;
                border: 0;
                margin-top: 20px;
            }
        }
        @include respond-below(sm) {
            flex-basis: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-right: 0;

            &:nth-child(3) {
                margin-top: 0;
            }
            &:nth-child(4) {
                margin-top: 0;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        .box-img-circle {
            width: 50px;
            margin-right: 10px;
        }
        .box-grey-text {
            .number-contracte {
                margin-bottom: 5px;
                @include font-size(14);
                @include line-height(18);
                font-weight: $font-primary-medium;
            }
            .data-text-blue {
                margin-bottom: 0;
                @include font-size(18);
                @include line-height(21);
                font-weight: $font-primary-bold;
                color: $blue-dark;
            }
        }
    }
}
</style>
