<template>
    <section>
        <div class="row mt-5 mb-5">
            <div class="col-12 col-lg-3">
                <ab-select
                    v-model="comercial.tipoLocal"
                    name="tipo-local"
                    v-bind:input-alias="$t('alias.usoDelAgua.tipoLocal')"
                    placeholder="Seleccionar"
                    v-bind:popover="$t('popovers.usoDelAgua.tipoVivienda')"
                    v-bind:validation="{ required: true }"
                    v-bind:data-list="selectData.tipoViviendaSelectData"
                    v-bind:label="$t('labels.tipoLocal')"
                >
                </ab-select>
            </div>
            <div class="col-12 col-lg-3">
                <ab-datepicker2
                    v-model="comercial.fechaContrato"
                    name="comercial-fecha-contrato"
                    :input-alias="$t('alias.usoDelAgua.fechaContratoComercial')"
                    v-bind:popover="$t('popovers.usoDelAgua.fechaContrato')"
                    :validation-required="true"
                    :label="$t('comercialSectionForms.fechaContrato')"
                ></ab-datepicker2>
            </div>
        </div>
        <p class="text-normal">{{ this.$t("comercialSectionForms.primerTexto") }}</p>
        <p class="text-normal mb-4" v-html="this.$t('comercialSectionForms.segundoTexto')"></p>
        <span class="text-blue d-block">{{ $t("comercialSectionForms.puedesAcreditar") }}</span>
        <validation-provider
            :name="$t('comercialSectionForms.actividadEconomica')"
            :vid="`section-comercial-yes-vid`"
            :rules="validation"
            v-slot="{ errors, classes }"
        >
            <ab-radio-ur
                :name="$t('comercialSectionForms.actividadEconomica')"
                :validation="{ required: true }"
                v-bind:input-alias="$t('comercialSectionForms.certificadoActividad')"
                class="mb-5"
                v-model="comercial.actividadEconomica.acreditada"
            ></ab-radio-ur>
        </validation-provider>
        <div v-if="comercial.actividadEconomica.acreditada" class="mt-3">
            <ab-iae-select v-model="comercial.iae" name="numero-iae" :api-base-url="catalogueBaseUrl" :is-required="true" />
            <div class="row mt-2 mb-3">
                <div class="col-12 text-right">
                    <a
                        class="list"
                        href="https://www.aiguesdebarcelona.cat/documents/20126/980178/listado_IAE.pdf/dfdfee5f-7fff-3f5d-dccb-e0e61036075f"
                        target="_blank"
                        >{{ $t("texts.listadoIae") }}</a
                    >
                </div>
            </div>
        </div>
        <div class="row mt-3 mb-3" v-if="comercial.actividadEconomica.acreditada === false">
            <div class="col-12 col-lg-5 mb-4">
                <ab-select
                    v-model="comercial.motivo"
                    name="motivo"
                    :validation="{ required: true }"
                    v-bind:input-alias="$t('comercialSectionForms.motivo')"
                    :placeholder="$t('comercialSectionForms.placeholder.motivo')"
                    v-bind:data-list="selectData.motivo"
                    v-bind:label="$t('comercialSectionForms.labelMotivo')"
                >
                </ab-select>
            </div>
        </div>
        <div class="row mt-3 mb-3" v-if="comercial.motivo === 'motivo4' && comercial.actividadEconomica.acreditada === false">
            <div class="col-12 col-lg-5 mb-4">
                <ab-select
                    v-model="comercial.otrosMotivos"
                    name="otros-motivos"
                    :validation="{ required: true }"
                    v-bind:input-alias="$t('comercialSectionForms.aliasMotivos')"
                    :placeholder="$t('comercialSectionForms.placeholder.motivo')"
                    v-bind:data-list="selectData.otrosMotivos"
                    v-bind:label="$t('comercialSectionForms.otrosMotivos')"
                >
                </ab-select>
            </div>
        </div>
        <p
            v-if="comercial.motivo === 'motivo1' && comercial.actividadEconomica.acreditada === false"
            class="text-normal-b"
            v-html="$t('ofex.emailRecovery.motivo1')"
        ></p>
        <div v-if="(comercial.actividadEconomica.acreditada === false && comercial.motivo === 'motivo3')">
            <span class="text-blue d-block">{{ $t("ofex.emailRecovery.certificadoAcredite") }}</span>
            <validation-provider
                :name="$t('comercialSectionForms.certificadoActividad')"
                :vid="`certificate-activity-no-vid`"
                :rules="validation"
                v-slot="{ errors, classes }"
            >
                <ab-radio-ur
                    :name="$t('comercialSectionForms.certificadoActividad')"
                    :validation="{ required: true }"
                    v-bind:input-alias="$t('comercialSectionForms.certificadoActividad')"
                    v-model="comercial.certificadoNoActividad"
                    class="mb-5"
                ></ab-radio-ur>
            </validation-provider>
        </div>
        <div class="mt-3">
            <p
                v-if="(comercial.actividadEconomica.acreditada === false && comercial.certificadoNoActividad === true && comercial.motivo === 'motivo3')"
                class="text-normal-b"
                v-html="$t('ofex.emailRecovery.adjuntoSi')"
            ></p>
            <p
                v-if="comercial.actividadEconomica.acreditada === false && comercial.certificadoNoActividad === false && comercial.motivo === 'motivo3'"
                class="text-normal-b"
                v-html="$t('ofex.emailRecovery.adjuntoNo')"
            ></p>
            <!--            <p
                v-if="comercial.actividadEconomica.acreditada === false && comercial.certificadoNoActividad === false && comercial.motivo === 'motivo4'"
                class="text-normal-b"
                v-html="$t('ofex.emailRecovery.motivo4NoAdjunto')"
            ></p>-->
        </div>
        <ab-upload
            v-if="(comercial.actividadEconomica.acreditada === false && comercial.certificadoNoActividad === true && comercial.motivo === 'motivo3')"
            name="attached-file"
            :input-alias="$t('comercialSectionForms.certificadoEscaneado')"
            v-model="comercial.attachedFile"
            :actividad="activityId"
            :contract="contract"
            :user="userId"
            :client="clientId"
            :label="$t('ofex.emailRecovery.adjuntoCertificado')"
            :api-base-url="contentManagerBaseUrl"
            :required="true"
            :popover="$t('upload.detailArchivoAdjunto')"
        ></ab-upload>
    </section>
</template>

<script>
import AbIaeSelect from "../components/AbIaeSelect.vue";
export default {
    name: "AbComercialSectionForms",
    components: {
        AbIaeSelect,
    },
    data: function () {
        return {
            catalogueBaseUrl: process.env.VUE_APP_API_CATALOGUE_BASE_URL,
            contentManagerBaseUrl: process.env.VUE_APP_API_CONTENT_MANAGER_BASE_URL,
        };
    },
    props: {
        selectData: Object,
        comercial: {
            type: Object,
            required: true,
        },
        activityId: {
            type: String,
            required: true,
        },
        userId: {
            required: false,
            type: String,
        },
        clientId: {
            required: false,
            type: String,
        },
        contract: {
            required: false,
            type: String,
        },
        validation: Object,
    },
    computed: {},
    watch: {
        "comercial.motivo": function (motivo) {
            switch (motivo) {
                case "motivo1":
                    this.comercial.iae.type = "I";
                    this.comercial.iae.code = "XXXX";
                    break;
                case "motivo2":
                    this.comercial.iae.type = "I";
                    this.comercial.iae.code = "505";
                    break;
            }
        },
        "comercial.certificadoNoActividad": function (val) {
            if (this.comercial.motivo === "motivo3") {
                this.comercial.iae.type = val === true ? "" : "I";
                this.comercial.iae.code = val === true ? "" : "XXXX";
            }
        },
        "comercial.otrosMotivos": function (val) {
            if (this.comercial.motivo === "motivo4") {
                switch (val) {
                    case "otrosMotivos7":
                        this.comercial.iae.type = "I";
                        this.comercial.iae.code = "EXE";
                        break;
                    default:
                        this.comercial.iae.type = "I";
                        this.comercial.iae.code = "XXXX";
                        break;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.text-normal {
    @include font-size(14);
    @include line-height(16);
    font-weight: $font-primary;
}
.text-normal-b {
    @include font-size(14);
    font-weight: $font-primary;
}
.text-blue {
    @include font-size(15);
    @include line-height(16);
    color: $blue-dark;
    font-weight: $font-primary-bold;
}
.label {
    @include font-size(19);
    @include line-height(16);
    color: $blue-dark;
    font-weight: $font-primary-bold;
}
.type-radio {
    height: 20px;
    width: 20px;
}
.list {
    @include font-size(14);
    @include line-height(16);
    color: $blue-dark;
    font-weight: $font-primary-bold;
    text-decoration: underline;
}
</style>
