<template>
    <div class="link-back">
        <div class="link" @click="goToBack">
            <font-awesome-icon class="icon mr-1" icon="arrow-left"></font-awesome-icon>{{ $t("ofex.detalleContrato.volver") }}
        </div>
    </div>
</template>

<script>
export default {
    name: "AbReturn",
    props: {
        stepBackNumber: {
            type: Number,
            default: -1,
        },
        customBackEvent: {
            type: Function,
            required: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        goToBack: function () {
            if (typeof this.customBackEvent !== "undefined" && typeof this.customBackEvent === "function") {
                this.customBackEvent.call();
            } else {
                this.$router.go(this.stepBackNumber);
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
