<template>
    <div>
        <b-container>
            <b-row>
                <ab-left-nav-bar />
                <b-col cols="12" md="12" lg="9">
                    <!-- Main title -->
                    <ab-main-title :main-text="$t('ofex.forms.readMeter.title')" />
                    <!-- lectura del contador -->
                    <read-meter-form-app
                        v-if="hasAnyActiveContract === true"
                        :contract-number="contractNumber"
                        :is-authenticated="isAuthenticated"
                        :is-loading="isLoading"
                        :user-data="userData"
                        :client-data="clientData"
                        :legal-urls="legalUrls"
                    ></read-meter-form-app>
                    <ab-without-data class="mt-3" v-else :text="$t('ofex.forms.readMeter.noActiveContracts')" />

                    <ab-your-interest
                        :name-left="$t('ofex.relatedContents.facturaDigital.name')"
                        :href-left="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                        :image-left="$t('ofex.relatedContents.facturaDigital.image')"
                        target-left="_self"
                        :name-center="$t('ofex.relatedContents.bonifications.name')"
                        :href-center="$t('ofex.relatedContents.bonifications.url')"
                        :image-center="$t('ofex.relatedContents.bonifications.image')"
                        :name-right="$t('ofex.relatedContents.quePagasFactura.name')"
                        :href-right="$t('ofex.relatedContents.quePagasFactura.url')"
                        :image-right="$t('ofex.relatedContents.quePagasFactura.image')"
                    />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import ReadMeterFormApp from "../forms/ReadMeterFormApp";
import { mapGetters, mapState } from "vuex";
export default {
    name: "ReadMeterFormView",
    components: { ReadMeterFormApp },
    data: function () {
        return {
            contractNumber: "",
        };
    },
    computed: {
        ...mapState(["isLoading", "userData", "clientData"]),
        ...mapGetters(["isAuthenticated", "legalUrls", "hasAnyActiveContract"]),
    },
    mounted() {
        this.contractNumber = this.$route.params.contractId;
    },
};
</script>
<style lang="scss" scoped></style>
