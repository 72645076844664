<template>
    <div>
        <ab-active-fsp-modal :key="modalKey" ref="activeFspModal" modal-name="active-fsp-modal" v-show="showActiveFspModal" />
    </div>
</template>

<script>
import * as viewMappingTypes from "@/router/view-mapping-types";
import AbActiveFspModal from "@/modules/invoices/download/AbActiveFspModal";
import Swal from "sweetalert2";
import { mapGetters, mapState } from "vuex";
import * as actionTypes from "@/store/action-types";
import LiferayServiceClient from "@/rest/liferayServiceClient";
import InvoiceFspPdfGeneator from "@/generators/pdf/InvoiceFspPdfGeneator";
import * as viewMappings from "@/router/view-mapping-types";
import { trimBackendCode } from "@/plugins/ab-utils";

export default {
    name: "AbDownloadInvoiceFlow",
    components: { AbActiveFspModal },
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
            showActiveFspModal: false,
            modalKey: Date.now(), //atributo para que Vue recargue el componente del modal
        };
    },
    computed: {
        ...mapState(["selectedContractNumber", "userInvoices", "contracts", "clientData"]),
        ...mapGetters(["isContractDigitalInvoiceDisabled", "getContractByContractNumber", "clientId", "userId", "legalUrls"]),
    },
    methods: {
        dispatchDownload: function (invoiceNumber, invoiceYear, contractNumber) {
            this.$store.dispatch(actionTypes.DOWNLOAD_INVOICE_ACTION, {
                invoiceNumber,
                invoiceYear,
                contractNumber,
                locale: this.$i18n.locale,
            });
        },
        activateFsp: async function () {
            let contractNumbers = [this.selectedContractNumber];
            let urls = await LiferayServiceClient.getLegalUrls(this.$i18n.locale);
            let fspUrl = urls.fsp;
            let pdfGenerator = new InvoiceFspPdfGeneator(this.$i18n, this.userId, this.clientId, "");
            pdfGenerator.generatePdf(contractNumbers, fspUrl, true);
            await pdfGenerator.uploadPdf();
            return this.$store.dispatch(actionTypes.UPDATE_FSP, {
                active: "ACTIVE_FSP",
                fspUrl: fspUrl,
                pdf: pdfGenerator.uploadedDocumentId,
                contractsNumbers: contractNumbers,
            });
        },
        downloadInvoiceFlow: function (payload) {
            console.debug("Flujo de descarga de factura. Comprobando si el contrato tiene activado la factura digital.");
            let contractNumber = payload.contractNumber ? payload.contractNumber : this.selectedContractNumber;

            if (this.shouldActiveFspToDownloadInvoice(contractNumber)) {
                this.showActiveFspModal = true;
                Swal.fire({
                    html: '<div id="active-fsp-modal"></div>',
                    showConfirmButton: true,
                    confirmButtonText: this.$t("ofex.facturaDigital.activar"),
                    showCancelButton: true,
                    allowOutsideClick: true,
                    allowEscapeKey: true,
                    onBeforeOpen: () => {
                        document.getElementById("active-fsp-modal").appendChild(this.$refs.activeFspModal.$el);
                    },
                    preConfirm: async () => {
                        let validator = this.$refs.activeFspModal.$refs.activeFspModalValidator;
                        let result = await validator.validate();
                        let accepted = this.$refs.activeFspModal.$refs.fspSection.fspAceptado;
                        console.debug(`Modal aceptado (${accepted}) y validación con resultado (${result})`);
                        return result === true
                            ? {
                                  accepted: accepted,
                                  validated: result,
                              }
                            : false;
                    },
                }).then((result) => {
                    if (result.isConfirmed && result.value.accepted) {
                        this.activateFsp().then(() => {
                            this.$store
                                .dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, triggerChangeActiveContract: false })
                                .then(() => {
                                    this.dispatchDownload(payload.invoiceNumber, payload.invoiceYear, payload.contractNumber);
                                    Swal.fire({
                                        showCloseButton: true,
                                        showCancelButton: false,
                                        text: this.$t("formularios.habilitarFacturaDigital.sendMessage"),
                                        icon: "success",
                                        confirmButtonText: this.$t("common.okButton"),
                                        html:
                                            this.$i18n.t("ofex.facturaDigital.modalMonoActivate1", { email: this.clientData.email }) +
                                            this.$i18n.t("ofex.facturaDigital.modalActivate", {
                                                windowLocationPathname: window.location.pathname,
                                                modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                                            }),
                                    });
                                });
                        });
                    }
                    this.showActiveFspModal = false;
                    this.modalKey = Date.now();
                });
            } else {
                this.dispatchDownload(payload.invoiceNumber, payload.invoiceYear, payload.contractNumber);
            }
        },
        shouldActiveFspToDownloadInvoice: function (contractNumber) {
            let contract = this.getContractByContractNumber(contractNumber);
            let supplyStatus = contract.contractDetail.supplyStatus;
            let paymentMethod = trimBackendCode(contract.paymentData.paymentMethod);

            let digitalInvoiceDisabled = this.isContractDigitalInvoiceDisabled(contractNumber);
            let contractStatusValid = supplyStatus
                ? trimBackendCode(supplyStatus) === "CONTRACTATINOINSTALLAT" || trimBackendCode(supplyStatus) === "ENVIGOR"
                : true;

            let paymentMethodValid =
                paymentMethod !== "ADMINISTRACIO" &&
                paymentMethod !== "ADMINISTRADOR" &&
                paymentMethod !== "ADMINISTRADOR/CLIENTSSINGULARS" &&
                paymentMethod !== "ADMINISTRADORDEFINQUES" &&
                paymentMethod !== "AJUNTAMENT" &&
                paymentMethod !== "JURIDIC";

            return digitalInvoiceDisabled && contractStatusValid && paymentMethodValid;
        },
    },
    created() {
        this.$root.$on("download-invoice", this.downloadInvoiceFlow);
    },
};
</script>

<style lang="scss"></style>
