<template>
    <b-container v-if="!selectedInactiveContract">
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.introduceCedulaHabitabilidad.title')" />
                <ab-return />
                <!-- detalle del monocontrato -->
                <ab-detail-mono-contract :selected-contract="this.$route.params.contractId"></ab-detail-mono-contract>
                <!--bloque monocontrato-->
                <p class="normal-detail mt-4">{{ $t("ofex.modificarDatosContrato.camposObligatorios") }}</p>
                <ValidationObserver ref="ceForm" v-slot="{ invalid }">
                    <div class="row mb-3">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                v-model="formModel.certificateOfOccupancy"
                                name="certificate-occcupancy"
                                :validation="{ required: true }"
                                :input-alias="$t('ofex.modificarDatosContrato.cedulaHabitabilidad')"
                                placeholder=""
                                :label="$t('ofex.modificarDatosContrato.cedulaHabitabilidad')"
                            >
                            </ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-datepicker2
                                v-model="formModel.dateCertificate"
                                name="date-certificate"
                                :input-alias="$t('ofex.modificarDatosContrato.fechaCedula')"
                                :validation-required="true"
                                :label="$t('ofex.modificarDatosContrato.fechaCedula')"
                            ></ab-datepicker2>
                        </div>
                        <div class="col-12 col-lg-6">
                            <ab-select
                                v-model="formModel.expirationCertificate"
                                name="expiration-certificate"
                                :input-alias="$t('ofex.modificarDatosContrato.expiracionCedula')"
                                placeholder="Seleccionar"
                                :data-list="caducidadCedulaSelectData"
                                :validation="{ required: true }"
                                :label="$t('ofex.modificarDatosContrato.expiracionCedula')"
                            >
                            </ab-select>
                        </div>
                    </div>

                    <div class="line-bottom mb-3"></div>
                    <!-- botones FORM -->
                    <div class="w-100 d-flex justify-content-end align-items-center mb-space">
                        <div class="btn-send">
                            <ab-button-ofex
                                :text-button="$t('ofex.modificarDatosContrato.enviar')"
                                :click-event="modify"
                                :disabled="invalid"
                                :show-popover="invalid"
                                :popover="$t('formularios.buttom.sendMessage')"
                            ></ab-button-ofex>
                        </div>
                    </div>
                </ValidationObserver>
                <ab-text-end name="textos-finales"></ab-text-end>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.introduceCedulaHabitabilidad.title')" />
                <ab-without-data class="mt-3" :text="$t('ofex.modificationContractSelector.noActiveSelectedContracts')" />

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import * as actionTypes from "../store/action-types";
import Swal from "sweetalert2";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import CEModifyPdfGenerator from "@/generators/pdf/CEModifyPdfGenerator";
import AbDetailMonoContract from "../modules/contracts/modification/AbDetailMonoContract.vue";
import AbReturn from "../components/AbReturn.vue";
export default {
    name: "InsertCertificateOfOccupancy",
    components: {
        AbDetailMonoContract,
        AbReturn,
    },
    data: function () {
        return {
            selectedContract: "",
            caducidadCedulaSelectData: [
                { key: "10", text: "10" },
                { key: "15", text: "15" },
                { key: "25", text: "25" },
            ],
            formModel: {
                certificateOfOccupancy: "",
                dateCertificate: null,
                expirationCertificate: "",
            },
        };
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "contracts", "contractDetail", "selectedContractNumber"]),
        ...mapGetters(["getContractByContractNumber", "multicontract", "isCompany", "clientId", "userId", "selectedInactiveContract"]),
        contract: function () {
            return this.getContractByContractNumber(this.$route.params.contractId);
        },
    },
    mounted() {
        this.selectedContract = this.$route.params.contractId;
        //Comprobamos que el contrato seleccionado no sea inactivo
        if (!this.selectedInactiveContract) {
            //aqui recibira la prop del numero de contrato que seleccionen de la tabla
            this.$store
                .dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, triggerChangeActiveContractLoading: false })
                .then(() => {});
            this.$store.dispatch(actionTypes.GET_CONTRACT_DETAIL_ACTION, {
                contractNumber: this.$route.params.contractId,
            });
        }
    },
    methods: {
        modify: function () {
            let validator = this.$refs.ceForm;
            validator.validate().then(async (result) => {
                if (result) {
                    console.debug("Modificación datos de Cédula:" + this.selectedContract);
                    let pdfGenerator = new CEModifyPdfGenerator(this.$i18n, this.userId, this.clientId, this.selectedContract);
                    pdfGenerator.generatePdf(this.formModel, this.contractDetail, this.selectedContract, this.userData, this.clientData, this.isCompany);
                    await pdfGenerator.uploadPdf();
                    console.log("PDF ID" + pdfGenerator.uploadedDocumentId);
                    this.$store
                        .dispatch(actionTypes.UPDATE_CE, {
                            userId: this.userId,
                            clientId: this.clientId,
                            contractNumber: this.selectedContract,
                            formModel: this.formModel,
                            userData: this.userData,
                            pdf: pdfGenerator.uploadedDocumentId,
                            activity: pdfGenerator.activityId,
                        })
                        .then((value) => {
                            this.$notifyFormActionToMatomo(true, "certificate_occupancy_modification");
                            Swal.fire({
                                showCloseButton: true,
                                showCancelButton: true,
                                text: this.$t("formularios.datosCedula.sendMessage"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                                cancelButtonText: this.$t("common.descargarPDF"),
                            }).then(async (result) => {
                                console.debug("Datos Modificados" + value);
                                if (!result.isConfirmed) {
                                    pdfGenerator.downloadPdf();
                                }
                            });
                        })
                        .catch((reason) => {
                            this.launchUpdateCE(reason);
                            this.$notifyFormActionToMatomo(false, "certificate_occupancy_modification", reason?.response?.data.errorMessage);
                        });
                }
            });
        },
        launchUpdateCE: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "ContractModificationView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/contract-modification.scss";
</style>
