<template>
    <div>
        <validation-provider :name="inputAliasComputed" :vid="`${name}-vid`" :rules="validation" v-slot="{ errors, classes }">
            <div class="row c-radio">
                <div class="col-12 col-lg-10 text-left">
                    <span class="d-block title" :class="commercialTerms ? 'title-terms' : 'text-musa'">{{ title }}</span>
                    <span class="description" :class="commercialTerms ? 'text-terms' : 'text-musa'">{{ description }}</span>
                </div>
                <div class="col-12 col-lg-2 pt-lg-3">
                    <div class="row">
                        <div class="col-12 d-flex mt-4">
                            <div class="">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <input :id="name + '-true'" v-model="modelValue" checked="checked" class="" :name="name" type="radio" :value="true" />
                                    </div>
                                    <div>
                                        <label class="text-musa ml-1" :for="name + '-true'"> {{ positiveLabel }} </label>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-3">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <input :id="name + '-false'" v-model="modelValue" class="" :name="name" type="radio" :value="false" />
                                    </div>
                                    <div>
                                        <label class="text-musa ml-1" :for="name + '-false'"> {{ negativeLabel }} </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-show="errors.length > 0" class="text-error-radio displayYes">{{ errors[0] }}</p>
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
export default {
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: String,
        inputAlias: String,
        title: String,
        description: String,
        validation: Object,
        positiveLabel: {
            default: "Si",
            type: String,
        },
        negativeLabel: {
            default: "No",
            type: String,
        },
        commercialTerms: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            radioValue: "",
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["inputValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
        inputAliasComputed: function () {
            return this.inputAlias ? this.inputAlias.toLowerCase() : this.title.toLowerCase().replace("*", "").replace(":", "");
        },
    },
};
</script>
<style lang="scss" scoped>
.text-error-radio {
    margin-top: 5px;
    margin-bottom: 0;
    color: $error-color;
    @include font-size(14);
    @include line-height(16);
    font-weight: $font-primary;
    text-align: left;
}
label {
    margin-bottom: 8px;
}
.text-musa {
    font-weight: $font-primary-bold;
    @include font-size(12);
    @include line-height(23);
}
input[type="radio"] {
    cursor: pointer;
    height: 20px;
    width: 20px;
}
.title-terms {
    font-weight: $font-primary-bold;
    @include font-size(16);
    @include line-height(28);
}
.text-terms {
    font-weight: $font-primary-medium;
    @include font-size(12);
    @include line-height(23);
}

.c-radio {
    margin-bottom: 20px;

    .title {
        @include font-size(16);
        @include line-height(28);
        font-weight: $font-primary-bold;
    }
    .description {
        @include font-size(12);
        @include line-height(23);
        font-weight: $font-primary-medium;
    }
}
</style>
