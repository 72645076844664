<template>
    <b-container>
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()" />
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.facturaDigital.title')" />
                <p class="subtitle" v-if="multiActiveContract">{{ $t("ofex.facturaDigital.subTitle") }}</p>
                <!-- bloque collapse alta / baja MULTICONTRATO -->
                <div v-if="multiActiveContract" class="accordion-invoice-digital" role="tablist" aria-busy="true">
                    <!-- collapse de alta -->
                    <ValidationObserver ref="enabledDigitalForm" v-slot="{ reset }">
                        <section class="line-section mt-2 pb-2">
                            <span v-b-toggle.collapse-enabled-digital header-tag="header" id="enabled-digital" role="tab" class="text-accordion pointer">
                                <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                                {{ $t("ofex.facturaDigital.alta") }}
                            </span>
                            <b-collapse id="collapse-enabled-digital" role="tabpanel" accordion="accordion-invoice-digital">
                                <!-- subtitle -->
                                <p class="subtitle-alta mt-2">{{ $t("ofex.facturaDigital.subtitleAlta") }}</p>
                                <!--tabla de selección -->
                                <ab-digital-invoice-table-selection
                                    v-if="invoicesNotSP.length > 0"
                                    :contracts-invoices-f-s-p="invoicesNotSP"
                                    :fsp="false"
                                    class="mt-2"
                                    ref="tableActive"
                                ></ab-digital-invoice-table-selection>
                                <ab-legal-section
                                    :father-class="'v-invoice-digital'"
                                    ref="legalSection"
                                    v-if="invoicesNotSP.length > 0"
                                    :fsp-es="legalUrls.fspEs"
                                    :fsp-ca="legalUrls.fspCa"
                                    :gdpr-es="legalUrls.gdprEs"
                                    :gdpr-ca="legalUrls.gdprCa"
                                    :legal-es="legalUrls.urEs"
                                    :legal-ca="legalUrls.urCa"
                                    :show-gdpr="false"
                                    :show-fsp="true"
                                    :show-legal="false"
                                    :legal-label-linked="$t('ofex.facturaDigital.condicionesFSP')"
                                ></ab-legal-section>
                                <div class="w-100 d-flex justify-content-end">
                                    <div class="box-button-save pt-2">
                                        <ab-button-ofex
                                            v-if="invoicesNotSP.length > 0"
                                            :disabled="active"
                                            :click-event="successfullyActivated"
                                            :text-button="$t('ofex.facturaDigital.activar')"
                                            text="boton activar factura digital"
                                        ></ab-button-ofex>
                                    </div>
                                </div>
                                <ab-remote-modal
                                    :show-cancel-button="false"
                                    v-if="invoicesNotSP.length === 0"
                                    :html-url-es="legalUrls.fspEs"
                                    :html-url-ca="legalUrls.fspCa"
                                    modal-name="fsp-modal"
                                >
                                </ab-remote-modal>

                                <!--mensaje informativo de activación -->
                                <ab-information-message v-if="invoicesNotSP.length === 0"></ab-information-message>
                            </b-collapse>
                        </section>
                    </ValidationObserver>
                    <ValidationObserver ref="disabledDigitalForm" v-slot="{ reset }">
                        <!-- collapse de baja -->
                        <section class="line-section mt-2 pb-2">
                            <span v-b-toggle.collapse-disabled-digital header-tag="header" id="disabled-digital" role="tab" class="text-accordion pointer">
                                <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                                {{ $t("ofex.facturaDigital.baja") }}
                            </span>
                            <b-collapse id="collapse-disabled-digital" role="tabpanel" accordion="accordion-invoice-digital">
                                <!-- subtitle -->
                                <p class="subtitle-alta mt-2">{{ $t("ofex.facturaDigital.subtitleBaja") }}</p>
                                <ab-digital-invoice-table-selection
                                    v-if="invoicesSP.length > 0"
                                    :contracts-invoices-f-s-p="invoicesSP"
                                    :fsp="true"
                                    class="mt-2"
                                    ref="tableInactivation"
                                ></ab-digital-invoice-table-selection>
                                <div class="w-100 d-flex justify-content-end">
                                    <div class="box-button-save pt-2">
                                        <ab-button-ofex
                                            v-if="invoicesSP.length > 0"
                                            :click-event="verifyInactivation"
                                            :disabled="active"
                                            :text-button="$t('ofex.facturaDigital.desactivar')"
                                            text="boton desactivar factura digital"
                                        ></ab-button-ofex>
                                    </div>
                                </div>
                                <!-- Mensaje cuando no hay contratos para la baja -->
                                <p v-if="!invoicesSP.length > 0" class="p-black-unsubscribe">{{ $t("ofex.facturaDigital.messageBajaFull") }}</p>
                            </b-collapse>
                        </section>
                    </ValidationObserver>
                </div>

                <!-- BLOQUE MONOCONTRATO -->
                <div v-if="!multiActiveContract && hasAnyActiveContract === true" class="v-monocontract">
                    <!--factura digital activada-->
                    <ab-digital-invoice-enabled :active="active" v-if="invoicesSP.length > 0"></ab-digital-invoice-enabled>
                    <!--factura digital desactivada-->
                    <ab-digital-invoice-disabled :active="active" v-else></ab-digital-invoice-disabled>
                </div>
                <ab-without-data class="mt-3" v-else-if="hasAnyActiveContract === false" :text="$t('ofex.facturaDigital.noActiveContracts')" />

                <ab-your-interest
                    class="mt-5"
                    :name-left="$t('ofex.relatedContents.quePagasFactura.name')"
                    :href-left="$t('ofex.relatedContents.quePagasFactura.url')"
                    :image-left="$t('ofex.relatedContents.quePagasFactura.image')"
                    :name-center="$t('ofex.relatedContents.calculadoraConsumos.name')"
                    :href-center="$t('ofex.relatedContents.calculadoraConsumos.url')"
                    :image-center="$t('ofex.relatedContents.calculadoraConsumos.image')"
                    :name-right="$t('ofex.relatedContents.consellEstalvi.name')"
                    :href-right="$t('ofex.relatedContents.consellEstalvi.url')"
                    :image-right="$t('ofex.relatedContents.consellEstalvi.image')"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import * as viewMappingTypes from "../router/view-mapping-types";
import { mapGetters, mapState } from "vuex";
import AbDigitalInvoiceDisabled from "../modules/invoices/digital/AbDigitalInvoiceDisabled.vue";
import AbDigitalInvoiceEnabled from "../modules/invoices/digital/AbDigitalInvoiceEnabled.vue";
import AbInformationMessage from "../modules/invoices/digital/AbInformationMessage.vue";
import AbDigitalInvoiceTableSelection from "../modules/invoices/digital/AbDigitalInvoiceTableSelection.vue";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import * as actionTypes from "@/store/action-types";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import InvoiceFspPdfGeneator from "@/generators/pdf/InvoiceFspPdfGeneator";
import * as viewMappings from "@/router/view-mapping-types";
import { validate } from "vee-validate";
import LiferayServiceClient from "@/rest/liferayServiceClient";

export default {
    name: "InvoiceDigitalView",
    components: {
        AbDigitalInvoiceDisabled,
        AbDigitalInvoiceEnabled,
        AbInformationMessage,
        AbDigitalInvoiceTableSelection,
    },
    data() {
        return {
            viewMappingTypes: viewMappingTypes,
            active: false,
        };
    },
    computed: {
        ...mapState(["isLoading", "activeContracts", "selectedContractNumber", "clientData", "userData"]),
        ...mapGetters([
            "multiActiveContract",
            "getContractByContractNumber",
            "isCompany",
            "getContractsWithDigitalInvoices",
            "getContractsWithPaperInvoices",
            "clientId",
            "userId",
            "legalUrls",
            "getContractSupplyCompleteAddress",
            "hasAnyActiveContract",
        ]),
        invoicesSP: function () {
            return this.getContractsWithDigitalInvoices ? this.getContractsWithDigitalInvoices : this.contract;
        },
        invoicesNotSP: function () {
            return this.getContractsWithPaperInvoices ? this.getContractsWithPaperInvoices : this.contract;
        },
        contract: function () {
            return this.getContractByContractNumber(this.selectedContractNumber);
        },
    },
    methods: {
        checkPhoneEmail: async function () {
            let message =
                !(await this.validateMobilePhone(this.userData.mainPhone)) && !(await this.validateEmail(this.userData.email))
                    ? this.$i18n.t("ofex.facturaDigital.error.phoneEmail", {
                          windowLocationPathname: window.location.pathname,
                          modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                      })
                    : !(await this.validateMobilePhone(this.userData.mainPhone))
                    ? this.$i18n.t("ofex.facturaDigital.error.phone", {
                          windowLocationPathname: window.location.pathname,
                          modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                      })
                    : !(await this.validateEmail(this.userData.email))
                    ? this.$i18n.t("ofex.facturaDigital.error.email", {
                          windowLocationPathname: window.location.pathname,
                          modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                      })
                    : null;

            if (message != null) {
                this.active = true;
                Swal.fire({
                    html: message,
                    icon: "success",
                    confirmButtonText: "OK",
                });
            }
        },

        validateMobilePhone: async function (value) {
            return await validate(value, "spanishMobile");
        },
        validateEmail: async function (value) {
            return await validate(value, "email");
        },
        verifyInactivation: function () {
            let validator = this.$refs.disabledDigitalForm;
            let contractNumbers = this.$refs.tableInactivation.contractsSelected.map((value) => value.contrato);
            let supplyAddress = null;
            contractNumbers.length === 1 ? (supplyAddress = this.getContractSupplyCompleteAddress(contractNumbers[0])) : (supplyAddress = "");
            validator.validate().then(async (result) => {
                if (result && contractNumbers !== "") {
                    let pdfGenerator = new InvoiceFspPdfGeneator(this.$i18n, this.userId, this.clientId, "");
                    pdfGenerator.generatePdf(contractNumbers, "", false);
                    await pdfGenerator.uploadPdf();

                    console.log("PDF ID" + pdfGenerator.uploadedDocumentId);
                    this.$store
                        .dispatch(actionTypes.UPDATE_FSP, {
                            active: "INACTIVE",
                            fspUrl: "",
                            pdf: pdfGenerator.uploadedDocumentId,
                            contractsNumbers: contractNumbers,
                        })
                        .then(() => {
                            this.$notifyFormActionToMatomo(true, "fsp_inactive_modification");
                            this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale }).then(() => {
                                Swal.fire({
                                    showCancelButton: false,
                                    text: this.$t("formularios.desabilitarFacturaDigital.sendMessage"),
                                    icon: "success",
                                    confirmButtonText: this.$t("common.okButton"),
                                    html:
                                        contractNumbers.length === 1
                                            ? this.$i18n.t("ofex.facturaDigital.modaltitleDesactivate1", { address: supplyAddress }) +
                                              this.$i18n.t("ofex.facturaDigital.modaltitleDesactivate2", {
                                                  windowLocationPathname: this.$windowLocationPathname,
                                                  modifyUrl: viewMappings.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.path.replace(
                                                      ":contractId",
                                                      contractNumbers[0]
                                                  ),
                                              })
                                            : this.$i18n.t("ofex.facturaDigital.desactivarExito", {
                                                  windowLocationPathname: this.$windowLocationPathname,
                                                  modifyUrl:
                                                      viewMappings.MODIFICATION_CONTRACT_SELECTOR_MAPPING.path +
                                                      "?typeViewModification=modificar datos de correspondencia",
                                              }),
                                });
                            });
                        })
                        .catch((reason) => {
                            this.$notifyFormActionToMatomo(false, "fsp_inactive_modification", reason?.response?.data.errorMessage);
                            this.launchUpdateFSP(reason);
                        });
                }
            });
        },
        successfullyActivated: function () {
            let validator = this.$refs.enabledDigitalForm;
            validator.validate().then(async (result) => {
                let contractNumbers = this.$refs.tableActive.contractsSelected.map((value) => value.contrato);
                let urls = await LiferayServiceClient.getLegalUrls(this.$i18n.locale);
                let fspUrl = urls.fsp;
                if (result && contractNumbers !== "") {
                    let pdfGenerator = new InvoiceFspPdfGeneator(this.$i18n, this.userId, this.clientId, "");
                    pdfGenerator.generatePdf(contractNumbers, fspUrl, true);
                    await pdfGenerator.uploadPdf();
                    console.log("PDF ID" + pdfGenerator.uploadedDocumentId);
                    this.$store
                        .dispatch(actionTypes.UPDATE_FSP, {
                            active: "ACTIVE_FSP",
                            fspUrl: fspUrl,
                            pdf: pdfGenerator.uploadedDocumentId,
                            contractsNumbers: contractNumbers,
                        })
                        .then(() => {
                            this.$notifyFormActionToMatomo(true, "fsp_enabled_modification");
                            this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
                        })
                        .then(() => {
                            Swal.fire({
                                showCloseButton: true,
                                showCancelButton: false,
                                text: this.$t("formularios.habilitarFacturaDigital.sendMessage"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                                html:
                                    contractNumbers.length === 1
                                        ? this.$i18n.t("ofex.facturaDigital.modalMonoActivate1", { email: this.userData.email }) +
                                          this.$i18n.t("ofex.facturaDigital.modalActivate", {
                                              windowLocationPathname: this.$windowLocationPathname,
                                              modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                                          })
                                        : this.$i18n.t("ofex.facturaDigital.activadoExito", { email: this.userData.email }) +
                                          this.$i18n.t("ofex.facturaDigital.modalActivate", {
                                              windowLocationPathname: this.$windowLocationPathname,
                                              modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                                          }),
                            });
                        })
                        .catch((reason) => {
                            this.$notifyFormActionToMatomo(false, "fsp_enabled_modification", reason?.response?.data.errorMessage);
                            this.launchUpdateFSP(reason);
                        });
                }
            });
        },
        launchUpdateFSP: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "InvoiceDigitalView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
    },

    mounted() {
        this.$store.dispatch(actionTypes.GET_ACTIVE_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        this.checkPhoneEmail();
    },
};
</script>
<style lang="scss" scoped>
.subtitle-alta {
    @include font-size(14);
    @include line-height(21);
    font-weight: $font-primary-bold;
    color: $blue-dark;
}
.p-black-unsubscribe {
    font-weight: $font-primary-bold;
    @include font-size(18);
    @include line-height(30);
}
.pointer {
    cursor: pointer;
}
.text-accordion {
    @include font-size(16);
    color: $blue-dark;
    font-weight: $font-primary-bold;

    &.not-collapsed {
        .icon {
            transform: rotate(90deg);
        }
    }
}
.line-section {
    border-bottom: 1px solid $border-table;
}
</style>
