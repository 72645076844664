<script>
import { Bar } from "vue-chartjs";
import moment from "moment";
import { AB_DATE_FORMAT } from "@/plugins/ab-utils";
import { isBrowser } from "mobile-device-detect";
import { executeAccessibilityFixes } from "@/plugins/accesibility";

export default {
    extends: Bar,
    props: {
        userInvoices: {
            type: Array,
            required: true,
        },
    },
    data: function () {
        return {
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
        };
    },
    methods: {
        downloadPdf: function (e, data) {
            if (isBrowser) {
                //TODO sacar a método global de utilidades (duplicado)
                if (data.length > 0) {
                    let activeElement = data[0]._chart.getElementAtEvent(e);
                    console.debug(activeElement);
                    if (activeElement.length > 0) {
                        let selectedInvoice = this.userInvoices[activeElement[0]._index];
                        console.debug(selectedInvoice);
                        this.$downloadInvoice({
                            invoiceNumber: selectedInvoice.invoiceNumber,
                            invoiceYear: selectedInvoice.invoiceYear,
                            locale: this.$i18n.locale,
                        });
                    }
                }
            }
        },
        renderInvoiceEvolutionChart: function () {
            console.log("Creando gráfica de evolucion de facturas");
            var vueContext = this;

            let invoicesEvolutionBarOptions = {
                responsive: true,
                maintainAspectRatio: false,
                onClick: this.downloadPdf,
                legend: {
                    display: false,
                    position: "bottom",
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.$i18n.tc("modules.ConsumptionEvolutionChart.metrosCubicos"),
                            },
                        },
                    ],
                    xAxes: [
                        {
                            offset: true,
                            distribution: "series",
                            scaleLabel: {
                                display: true,
                            },
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 0,
                                minRotation: 90,
                                mirror: true,
                            },
                            maxBarThickness: 40,
                        },
                    ],
                },
                // https://zenerab.atlassian.net/browse/UATOFEX-182
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                tooltips: {
                    backgroundColor: "#FFF",
                    borderColor: "#417290",
                    borderWidth: 1,
                    titleFontColor: "#417290",
                    callbacks: {
                        label: function () {
                            return "";
                        },
                        title: function (tooltipItem) {
                            console.debug(tooltipItem);
                            let invoice = vueContext.timeConsumptionDataset[tooltipItem[0].index];
                            return vueContext.$i18n.t(
                                vueContext.$isNativeApp()
                                    ? "modules.ConsumptionEvolutionChart.chartTooltipNative"
                                    : "modules.ConsumptionEvolutionChart.chartTooltip",
                                {
                                    date: invoice.endDate,
                                    amount: vueContext.$formatNumber(invoice.amount),
                                    volume: invoice.consumptionVolume,
                                }
                            );
                        },
                    },
                },
            };

            let invoicesEvolutionBarData = {
                datasets: [
                    {
                        data: this.timeConsumptionDatasetVolume,
                        hoverBackgroundColor: this.datasetHoverColors,
                        backgroundColor: this.datasetBackgroundColors,
                        label: this.$t("modules.ConsumptionEvolutionChart.facturaConsumoReal"),
                        minBarLength: 5,
                    },
                ],
                labels: this.monthYearsLabels,
            };

            this.renderChart(invoicesEvolutionBarData, invoicesEvolutionBarOptions);
        },
    },
    computed: {
        timeConsumptionDataset: function () {
            return this.userInvoices.slice().reverse();
        },
        timeConsumptionDatasetVolume: function () {
            return this.timeConsumptionDataset.map((i) => i.consumptionVolume);
        },
        datasetBackgroundColors: function () {
            return this.timeConsumptionDataset.map((i) => (i.estimatedReading ? "#008f39" : "#417290"));
        },
        datasetHoverColors: function () {
            return this.timeConsumptionDataset.map((i) => (i.estimatedReading ? "#31cb6f" : "#95CAEA"));
        },
        monthYearsLabels: function () {
            return this.timeConsumptionDataset.map((i) => moment(i.endDate, AB_DATE_FORMAT, "es").format("MMM YYYY").toUpperCase());
        },
    },
    watch: {
        userInvoices: function () {
            console.log("Cambiando datos de facturas en gráfico");
            // this._chart.destroy();
            this.renderInvoiceEvolutionChart();
        },
    },
    mounted() {
        this.renderInvoiceEvolutionChart();
        executeAccessibilityFixes();
    },
};
</script>

<style lang="scss"></style>
