<template>
    <validation-provider :name="inputAliasComputed" :vid="`${name}-vid`" :rules="validation" v-slot="{ errors, classes }">
        <div class="d-flex justify-content-around mt-5">
            <div class="d-flex align-items-center">
                <input
                    :id="`yes-${inputAliasComputed}`"
                    type="radio"
                    :name="`yes-${inputAliasComputed}`"
                    class="form-check-input move-input-form"
                    v-model="modelValue"
                    :disabled="isAuthenticated ? true : false"
                    v-bind:value="true"
                />
                <label :for="`yes-${inputAliasComputed}`" class="form-check-label class-subscribe">{{ positiveLabel }}</label>
            </div>
            <div class="d-flex align-items-center">
                <input
                    :id="`no-${inputAliasComputed}`"
                    type="radio"
                    :name="`no-${inputAliasComputed}`"
                    class="form-check-input move-input-form"
                    v-model="modelValue"
                    v-bind:value="false"
                />
                <label :for="`no-${inputAliasComputed}`" class="form-check-label class-subscribe">{{ negativeLabel }}</label>
            </div>
        </div>
        <div class="w-100 text-center">
            <p v-show="errors.length > 0" class="formTextErr displayYes">{{ errors[0] }}</p>
        </div>
    </validation-provider>
</template>

<script>
export default {
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: String,
        inputAlias: String,
        validation: Object,
        positiveLabel: {
            default: "Si",
            type: String,
        },
        negativeLabel: {
            default: "No",
            type: String,
        },
        isAuthenticated: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: function () {
        return {
            radioValue: "",
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["inputValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
        inputAliasComputed: function () {
            return this.inputAlias ? this.inputAlias : this.name.toLowerCase().replace("*", "").replace(":", "");
        },
    },
};
</script>
<style lang="scss" scoped>
.text-musa {
    font-weight: $font-primary-bold;
    @include font-size(12);
    @include line-height(23);
}
input[type="radio"] {
    cursor: pointer;
    height: 20px;
    width: 20px;
}
.title-terms {
    font-weight: $font-primary-bold;
    @include font-size(16);
    @include line-height(28);
}
.text-terms {
    font-weight: $font-primary-medium;
    @include font-size(12);
    @include line-height(23);
}

.c-radio {
    margin-bottom: 20px;

    .title {
        @include font-size(16);
        @include line-height(28);
        font-weight: $font-primary-bold;
    }
    .description {
        @include font-size(12);
        @include line-height(23);
        font-weight: $font-primary-medium;
    }
}

.class-subscribe {
    margin-left: 5px;
    color: $blue-dark;
    font-weight: $font-primary-bold;
    @include font-size(19);
}
</style>
