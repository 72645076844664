"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import { trimBackendCode } from "@/plugins/ab-utils";
import { getI18nTextFromKey } from "@/plugins/ab-utils";
import moment from "moment";
const ACTIVITY_CODE = "IA";
export default class IAEModifyPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId, contract) {
        super("ModificaciónIAE.pdf", ACTIVITY_CODE, i18n, userId, clientId, contract);
    }
    generatePdf(selectData, formModel, contractDetail, selectedContract, userData, clientData, isCompany) {
        const SPACE = " ";
        moment.locale("es");
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.IAEModify.title"), "");
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.IAEModify.detalleContrato"));
        this.generateLineText(
            isCompany
                ? this.i18n.tc("ofex.pdfGenerator.IAEModify.titularContrato") + SPACE + clientData.name
                : this.i18n.tc("ofex.pdfGenerator.IAEModify.titularContrato") +
                      SPACE +
                      userData.firstName +
                      SPACE +
                      userData.firstLastName +
                      SPACE +
                      userData.secondLastName
        );
        this.generateLineText(
            isCompany
                ? this.i18n.tc("ofex.pdfGenerator.IAEModify.nifNieTitular") + SPACE + clientData.documentNumber
                : this.i18n.tc("ofex.pdfGenerator.IAEModify.nifNieTitular") + SPACE + userData.documentNumber
        );
        this.generateLineText(
            isCompany
                ? this.i18n.tc("ofex.pdfGenerator.IAEModify.correoElectronico") + SPACE + clientData.email
                : this.i18n.tc("ofex.pdfGenerator.IAEModify.correoElectronico") + SPACE + userData.email
        );
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.IAEModify.numeroContrato") + SPACE + selectedContract);

        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.IAEModify.direccionSuministro") +
                SPACE +
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(contractDetail.supplyAddress.streetType)) +
                SPACE +
                contractDetail.supplyAddress.streetName +
                SPACE +
                contractDetail.supplyAddress.number +
                SPACE +
                contractDetail.supplyAddress.otherAddressData +
                SPACE +
                contractDetail.supplyAddress.municipality +
                SPACE
        );

        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.IAEModify.datosIAE"), "");
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.tipolocal") +
                SPACE +
                getI18nTextFromKey(formModel.comercial.tipoLocal, selectData.tipoViviendaSelectData)
        );
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.fechaContrato") +
                SPACE +
                moment(formModel.comercial.fechaContrato, "MMMM Do YYYY, h:mm:ss a").format("L")
        );
        if (formModel.comercial.actividadEconomica.acreditada === true) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.IAEModify.tipoIAE") + SPACE + formModel.comercial.iae.descriptionType);
            this.i18n.locale === "ca"
                ? this.generateLineText(this.i18n.tc("ofex.pdfGenerator.IAEModify.numeroIAE") + SPACE + formModel.comercial.iae.descriptionCA)
                : this.generateLineText(this.i18n.tc("ofex.pdfGenerator.IAEModify.numeroIAE") + SPACE + formModel.comercial.iae.descriptionES);
        } else {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.IAEModify.acreditarActividadEconomica") + SPACE + "No");
            this.generateLineText(
                this.i18n.tc("ofex.pdfGenerator.IAEModify.indicaMotivo") + SPACE + getI18nTextFromKey(formModel.comercial.motivo, selectData.motivo)
            );
            if (formModel.comercial.motivo === "motivo4") {
                this.generateLineText(
                    this.i18n.tc("ofex.pdfGenerator.IAEModify.otrosMotivos") +
                        SPACE +
                        getI18nTextFromKey(formModel.comercial.otrosMotivos, selectData.otrosMotivos)
                );
            }
            if (formModel.comercial.motivo === "motivo3" || formModel.comercial.motivo === "motivo4") {
                if (formModel.comercial.certificadoNoActividad === true) {
                    this.generateLineText(this.i18n.tc("ofex.pdfGenerator.IAEModify.disponesCertificado") + SPACE + "Si");
                    this.generateLineText(
                        this.i18n.tc("ofex.pdfGenerator.IAEModify.certificadoEscaneado") + SPACE + formModel.comercial.attachedFile.file.name
                    );
                } else {
                    this.generateLineText(this.i18n.tc("ofex.pdfGenerator.IAEModify.disponesCertificado") + SPACE + "No");
                }
            }
        }

        return this.pdf;
    }
}
