<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <div>
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-container v-if="!selectedInactiveContract && this.contract && this.contractDetail">
            <b-row>
                <ab-left-nav-bar />
                <b-col cols="12" md="12" lg="9">
                    <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                    <!--Main title -->
                    <ab-main-title :main-text="$t('ofex.bajaContrato.title')" />
                    <ab-return v-if="multicontract" />
                    <span class="text-selection marked-fields">{{ $t("ofex.bajaContratoDetalle.camposMarcados") }}</span>
                    <!--DATOS DEL CONTRATO/SUMINISTRO-->
                    <section>
                        <h2 class="title-section line-bottom mt-3">{{ $t("ofex.bajaContratoDetalle.datosContrato") }}</h2>
                        <p class="element-blue mt-4">
                            {{ $t("ofex.bajaContratoDetalle.numContrato") }} <span class="element-black">{{ contract.contractDetail.contractNumber }}</span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.direccionSuministro") }}
                            <span class="element-black"
                                >{{ supplyStreetTypeTranslation() }} {{ contractDetail.supplyAddress.streetName }},
                                {{ contractDetail.supplyAddress.otherAddressData }} {{ contractDetail.supplyAddress.municipality }}</span
                            >
                        </p>
                        <p class="subtitle-blue mt-4">{{ $t("ofex.bajaContratoDetalle.fechaRescision") }}</p>
                        <div class="row">
                            <div class="col-12 col-lg-3">
                                <ab-datepicker
                                    v-model="formModel.datepicker"
                                    name="usoAgua-fechaContrato"
                                    v-bind:input-alias="$t('ofex.bajaContratoDetalle.aliasFechaRescision')"
                                    v-bind:label="$t('ofex.bajaContratoDetalle.labelFechaRescision')"
                                    :validation-required="false"
                                    :min-date="this.today"
                                    :max-date="this.oneMonth"
                                ></ab-datepicker>
                            </div>
                        </div>
                        <p class="subtitle-blue mt-3">{{ $t("ofex.bajaContratoDetalle.ultimaLecturaContador") }}</p>
                        <p class="detail-last-read">{{ $t("ofex.bajaContratoDetalle.textDetailUltimaFactura") }}</p>
                        <div class="w-100">
                            <div class="half-div">
                                <div>
                                    <label class="label-for-read-value" for="lectura-contador">
                                        {{ $t("ofex.bajaContratoDetalle.lectura") }}
                                    </label>
                                </div>
                                <div class="box-read">
                                    <div class="box-black-read">
                                        <input
                                            v-model="formModel.readValue"
                                            id="lectura-contador"
                                            type="number"
                                            name="lectura-contador"
                                            class="custom-input-read"
                                            aria-label="introduce-lectura-contador"
                                        />
                                    </div>
                                    <div class="box-red-cero d-flex align-items-center justify-content-center">
                                        <span class="number-cero"
                                            >,000 <span class="number-cubic">m<sup>3</sup></span></span
                                        >
                                    </div>
                                </div>
                                <p class="text-detail-small mt-5" v-html="$t('ofex.bajaContratoDetalle.textDetailRead')"></p>
                                <div>
                                    <!--boton adjuntar archivo-->
                                    <ab-upload
                                        name="attached-file"
                                        v-model="formModel.attachedFile"
                                        :actividad="activityId"
                                        :father-class="'v-upload'"
                                        :api-base-url="contentManagerBaseUrl"
                                        :user="this.userId"
                                        :client="this.clientId"
                                        :contract="contract.contractDetail.contractNumber"
                                    ></ab-upload>
                                    <div class="box-text-button mt-2">
                                        <p class="text-detail-small">{{ $t("ofex.bajaContratoDetalle.detailArchivoAdjunto") }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="half-div">
                                <!--DIV FOTO CONTADOR-->
                                <div class="box-photoCounter bg-color-photoCounter">
                                    <!-- <div class="box-transparent d-flex justify-content-center align-items-center">
                                        <span class="text-photoCount" v-html="$t('ofex.bajaContratoDetalle.pendienteFotoContador')"></span>
                                    </div> -->
                                </div>
                                <div class="box-text-detail-photo">
                                    <p class="text-detail-small mt-2">{{ $t("ofex.bajaContratoDetalle.textDetailPhotoCount") }}</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!--Identificación del cliente titular-->
                    <section>
                        <h3 class="title-section line-bottom mt-5">{{ $t("ofex.bajaContratoDetalle.identificacionClienteTitular") }}</h3>
                        <p class="element-blue mt-4">
                            {{ $t("ofex.detalleContrato.nombreApellidos")
                            }}<span
                                class="element-black"
                                v-html="isCompany ? clientData.name : userData.firstName + ' ' + userData.firstLastName + ' ' + userData.secondLastName"
                            ></span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.tipoDocumento")
                            }}<span class="element-black" v-html="isCompany ? clientData.documentType : userData.documentType"></span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.nDocumento")
                            }}<span class="element-black" v-html="isCompany ? clientData.documentNumber : userData.documentNumber"></span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.tlfPrincipal")
                            }}<span class="element-black" v-html="isCompany ? clientData.mainPhone : userData.mainPhone"></span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.correoElectronico")
                            }}<span class="element-black" v-html="isCompany ? clientData.email : userData.email"></span>
                        </p>
                        <p class="element-blue" v-if="false">
                            {{ $t("ofex.bajaContratoDetalle.usuarioTwitter")
                            }}<span class="element-black" v-html="isCompany ? clientData.twitter : userData.twitter"></span>
                        </p>
                        <p class="element-blue" v-if="isCompany">
                            {{ $t("ofex.detalleContrato.idioma")
                            }}<span
                                class="element-black"
                                v-text="clientData.language == 'es' ? $t('ofex.detalleContrato.espanol') : $t('ofex.detalleContrato.catalan')"
                            ></span>
                        </p>
                        <p class="element-blue" v-if="!isCompany">
                            {{ $t("ofex.detalleContrato.idioma")
                            }}<span
                                class="element-black"
                                v-text="userData.language == 'es' ? $t('ofex.detalleContrato.espanol') : $t('ofex.detalleContrato.catalan')"
                            ></span>
                        </p>
                    </section>
                    <!--Identificación del solicitante rescisión del suministro-->
                    <section>
                        <h4 class="title-section line-bottom mt-5">{{ $t("ofex.bajaContratoDetalle.identificacionSolicitanteRescision") }}</h4>
                        <p class="element-blue mt-4">
                            {{ $t("ofex.detalleContrato.nombreApellidos")
                            }}<span class="element-black" v-html="userData.firstName + ' ' + userData.firstLastName + ' ' + userData.secondLastName"></span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.tipoDocumento") }}<span class="element-black" v-html="userData.documentType"></span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.nDocumento") }}<span class="element-black" v-html="userData.documentNumber"></span>
                        </p>
                        <p class="element-blue">{{ $t("ofex.detalleContrato.tlfPrincipal") }}<span class="element-black" v-html="userData.mainPhone"></span></p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.correoElectronico") }}<span class="element-black" v-html="userData.email"></span>
                        </p>
                    </section>
                    <!--Identificación del titular de la cuenta bancaria particular-->
                    <ValidationObserver ref="unsuscribeContractForm" v-slot="{ reset, invalid }">
                        <section>
                            <h5 class="title-section line-bottom mt-5">{{ $t("ofex.bajaContratoDetalle.identificacionTitularCuenta") }}</h5>
                            <p class="text-bank-data">{{ $t("ofex.bajaContratoDetalle.modificacionDatosBancarios") }}</p>
                            <div v-if="!isCompany">
                                <div class="mt-4">
                                    <input
                                        type="checkbox"
                                        v-model="formModel.bankDetailsDomiciliation"
                                        name="checkbox-bank-detail-domiciliation"
                                        value="accepted"
                                        unchecked-value="not_accepted"
                                        aria-label="checkbox-bank-detail-domiciliation"
                                    />
                                    <span class="ml-2 text-checkBox-domiciliation">{{ $t("ofex.bajaContratoDetalle.checkBoxDomiciliacion") }}</span>
                                    <ab-id-type-number
                                        v-if="!formModel.bankDetailsDomiciliation"
                                        class="mt-3 mb-5"
                                        v-model="formModel.headlineBankAccount.doc"
                                        v-bind:allow-cif="false"
                                        v-bind:remote-validation="false"
                                        validation-type-name="particular-tipo-documento"
                                        validation-number-name="particular-numero-documento"
                                        :event-blur="checkEventBlur"
                                    >
                                    </ab-id-type-number>
                                    <div class="row mb-5 mt-3" v-if="formModel.bankDetailsDomiciliation">
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                v-model="formModel.headlineBankAccount.doc.type"
                                                :readonly="true"
                                                :label="$t('ofex.forms.readMeter.tipoDocumento')"
                                                name="type-doc-info-payment"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                v-model="formModel.headlineBankAccount.doc.number"
                                                :readonly="true"
                                                :label="$t('ofex.forms.readMeter.numDocumento')"
                                                name="number-doc-info-payment"
                                            ></ab-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-3">
                                            <ab-select
                                                v-if="!readOnlyvalue"
                                                v-model="formModel.headlineBankAccount.treatment"
                                                name="tratamiento-titular-cuenta-bancaria"
                                                placeholder="Seleccionar"
                                                v-bind:validation="{ required: true }"
                                                v-bind:data-list="select.treatmentSelectData"
                                                v-bind:label="$t('ofex.bajaContratoDetalle.tratamiento')"
                                            >
                                            </ab-select>
                                            <ab-input
                                                autocomplete="honorific-prefix"
                                                v-if="readOnlyvalue"
                                                v-model="formModel.headlineBankAccount.treatment"
                                                name="tratamiento-titular-cuenta-bancaria"
                                                v-bind:placeholder="''"
                                                :readonly="true"
                                                v-bind:validation="{ required: true, max: 50 }"
                                                v-bind:label="$t('ofex.bajaContratoDetalle.tratamiento')"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                autocomplete="given-name"
                                                v-model="formModel.headlineBankAccount.name"
                                                name="nombre-titular-cuenta-bancaria"
                                                :readonly="readOnlyvalue"
                                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.nombre')"
                                                v-bind:validation="{ required: true, max: 50 }"
                                                v-bind:label="$t('ofex.bajaContratoDetalle.nombre')"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                autocomplete="last-name"
                                                v-model="formModel.headlineBankAccount.lastName"
                                                name="primer-apellido-titular-cuenta-bancaria"
                                                :readonly="readOnlyvalue"
                                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.primerApellido')"
                                                v-bind:validation="{ required: true, max: 50 }"
                                                v-bind:label="$t('ofex.bajaContratoDetalle.primerApellido')"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                autocomplete="last-name"
                                                v-model="formModel.headlineBankAccount.secondLastName"
                                                name="segundo-apellido-titular-cuenta-bancaria"
                                                :readonly="readOnlyvalue"
                                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.segundoApellido')"
                                                v-bind:validation="{ required: false, max: 50 }"
                                                v-bind:label="$t('ofex.bajaContratoDetalle.segundoApellido')"
                                            ></ab-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Identificación del titular de la cuenta bancaria empresa-->
                            <div v-if="isCompany">
                                <div class="mt-4">
                                    <input
                                        type="checkbox"
                                        v-model="formModel.bankDetailsDomiciliation"
                                        name="checkbox-bank-detail-domiciliation"
                                        value="accepted"
                                        unchecked-value="not_accepted"
                                    />
                                    <span class="ml-2 text-checkBox-domiciliation">{{ $t("ofex.bajaContratoDetalle.checkBoxDomiciliacion") }}</span>
                                    <ab-id-type-number
                                        v-if="!formModel.bankDetailsDomiciliation"
                                        class="mt-3 mb-5"
                                        v-model="formModel.headlineBankAccount.doc"
                                        v-bind:allow-cif="true"
                                        v-bind:remote-validation="false"
                                        validation-type-name="particular-tipo-documento"
                                        validation-number-name="particular-numero-documento"
                                        :event-blur="checkEventBlur"
                                    >
                                    </ab-id-type-number>
                                    <div class="row mb-5 mt-3" v-if="formModel.bankDetailsDomiciliation">
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                v-model="formModel.headlineBankAccount.doc.type"
                                                :readonly="true"
                                                :label="$t('ofex.forms.readMeter.tipoDocumento')"
                                                name="type-doc-info-payment"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                v-model="formModel.headlineBankAccount.doc.number"
                                                :readonly="true"
                                                :label="$t('ofex.forms.readMeter.numDocumento')"
                                                name="number-doc-info-payment"
                                            ></ab-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-lg-3" v-if="!readOnlyvalue">
                                            <ab-select
                                                v-model="formModel.headlineBankAccount.treatment"
                                                name="tratamiento-titular-cuenta-bancaria"
                                                placeholder="Seleccionar"
                                                v-bind:validation="{ required: true }"
                                                v-bind:data-list="select.treatmentSelectData"
                                                v-bind:label="$t('ofex.bajaContratoDetalle.tratamiento')"
                                            >
                                            </ab-select>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                autocomplete="given-name"
                                                v-model="formModel.headlineBankAccount.name"
                                                name="nombre-titular-cuenta-bancaria"
                                                :readonly="readOnlyvalue"
                                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.nombre')"
                                                v-bind:validation="{ required: true, max: 50 }"
                                                v-bind:label="$t('ofex.bajaContratoDetalle.nombre')"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                autocomplete="last-name"
                                                v-if="!readOnlyvalue"
                                                v-model="formModel.headlineBankAccount.lastName"
                                                name="primer-apellido-titular-cuenta-bancaria"
                                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.primerApellido')"
                                                v-bind:validation="{ required: true, max: 50 }"
                                                v-bind:label="$t('ofex.bajaContratoDetalle.primerApellido')"
                                                :readonly="readOnlyvalue"
                                            ></ab-input>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                            <ab-input
                                                autocomplete="last-name"
                                                v-if="!readOnlyvalue"
                                                v-model="formModel.headlineBankAccount.secondLastName"
                                                name="segundo-apellido-titular-cuenta-bancaria"
                                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.segundoApellido')"
                                                v-bind:validation="{ required: false, max: 50 }"
                                                v-bind:label="$t('ofex.bajaContratoDetalle.segundoApellido')"
                                                :readonly="readOnlyvalue"
                                            ></ab-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <!--Datos bancarios (donde se enviará la factura de liquidación)-->
                        <section>
                            <h6 class="title-section line-bottom mt-5">{{ $t("ofex.bajaContratoDetalle.identificacionCuenta") }}</h6>
                            <div class="row mt-3 mb-5" v-if="!formModel.bankDetailsDomiciliation && showBankInfo">
                                <div class="col-12 col-lg-3">
                                    <ab-country-select
                                        v-if="!formModel.bankDetailsDomiciliation"
                                        v-model="formModel.dataBank.country"
                                        name="pais-datos-bancarios"
                                        v-bind:input-alias="$t('ofex.bajaContratoDetalle.pais')"
                                        placeholder="Seleccionar"
                                        ref="countryiban"
                                        v-bind:validation="{ required: true }"
                                        v-bind:label="$t('ofex.bajaContratoDetalle.pais')"
                                    >
                                    </ab-country-select>
                                    <ab-input
                                        v-if="formModel.bankDetailsDomiciliation"
                                        name="pais-datos-bancarios"
                                        v-model="formModel.dataBank.country"
                                        :readonly="true"
                                        v-bind:label="$t('ofex.bajaContratoDetalle.pais')"
                                    ></ab-input>
                                </div>
                            </div>
                            <div class="row" v-if="showBankInfo">
                                <div class="col-12 col-lg-6">
                                    <ab-iban
                                        v-if="!formModel.bankDetailsDomiciliation"
                                        name="iban-datos-bancarios"
                                        v-model="formModel.dataBank.iban"
                                        v-bind:input-alias="$t('ofex.bajaContratoDetalle.iban')"
                                        v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.iban')"
                                        v-bind:is-required="true"
                                        v-bind:validation="{
                                            alpha_num: true,
                                            required: true,
                                            max: 32,
                                            min: 15,
                                            iban: true,
                                        }"
                                        :external-country="formModel.dataBank.country"
                                        v-bind:label="$t('ofex.bajaContratoDetalle.iban')"
                                    ></ab-iban>
                                    <ab-input
                                        v-if="formModel.bankDetailsDomiciliation"
                                        name="iban-datos-bancarios"
                                        v-model="formModel.dataBank.iban"
                                        :readonly="true"
                                        :label="$t('ofex.bajaContratoDetalle.iban')"
                                    ></ab-input>
                                </div>
                                <div class="col-12 col-lg-2" v-if="formModel.dataBank.country != 'ES'">
                                    <ab-input
                                        v-model="formModel.dataBank.bic"
                                        name="bic-data-bank"
                                        :input-alias="'BIC'"
                                        :readonly="formModel.bankDetailsDomiciliation ? true : false"
                                        :placeholder="'BIC'"
                                        v-bind:validation="{ required: true, max: 11, min: 8 }"
                                        label="BIC*"
                                    >
                                    </ab-input>
                                </div>
                            </div>
                            <div class="row mt-3 mb-5" v-if="!showBankInfo">
                                <div class="col-12 col-lg-6 mt-3">
                                    <p class="element-blue">
                                        {{ $t("ofex.detalleContrato.formaPago")
                                        }}<span class="element-black">{{ $t("ofex.backendCodes.paymentMethod." + methodPaymentBackendCode()) }}</span>
                                    </p>
                                </div>
                            </div>
                        </section>
                        <!--Dirección de envío del comprobante de rescisión-->
                        <section>
                            <p class="title-section line-bottom mt-5">{{ $t("ofex.bajaContratoDetalle.comprobanteRescision") }}</p>
                            <p class="text-bank-data">{{ $t("ofex.bajaContratoDetalle.modificarDireccion") }}</p>
                            <div class="mt-4">
                                <input
                                    type="checkbox"
                                    v-model="formModel.sameDirection"
                                    name="checkbox-same-direction"
                                    value="accepted"
                                    unchecked-value="not_accepted"
                                    aria-label="checkbox-same-direction"
                                />
                                <span class="ml-2 text-checkBox-domiciliation">{{ $t("ofex.bajaContratoDetalle.mismaDireccion") }}</span>
                            </div>
                            <ab-carrerer
                                v-if="!formModel.sameDirection"
                                class="mt-3"
                                v-model="formModel.shippingAddress.carrerer"
                                carrerer-name="direccion-de-envio"
                                :show-province="true"
                                :api-domain="carrererBaseUrl"
                            />
                            <div v-else>
                                <div class="row mt-3">
                                    <div class="col-12 col-lg-3">
                                        <ab-input
                                            autocomplete="address-level1"
                                            v-model="formModel.shippingAddress.carrerer.province.name"
                                            name="province-supplyAddress"
                                            placeholder=""
                                            :readonly="true"
                                            v-bind:label="$t('carrerer.labels.provincia')"
                                        ></ab-input>
                                    </div>
                                </div>
                                <div class="row mt-5">
                                    <div class="col-12 col-lg-4">
                                        <ab-input
                                            autocomplete="address-level2"
                                            v-model="formModel.shippingAddress.carrerer.municipality.name"
                                            name="municipality-supplyAddress"
                                            placeholder=""
                                            :readonly="true"
                                            v-bind:label="$t('carrerer.labels.municipio')"
                                        ></ab-input>
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <ab-input
                                            v-model="formModel.shippingAddress.carrerer.streetType.code"
                                            name="streetType-supplyAddress"
                                            placeholder=""
                                            :readonly="true"
                                            v-bind:label="$t('carrerer.labels.tipoVia')"
                                        ></ab-input>
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <ab-input
                                            autocomplete="address-line1"
                                            v-model="formModel.shippingAddress.carrerer.streetName"
                                            name="streetName-supplyAddress"
                                            placeholder=""
                                            :readonly="true"
                                            v-bind:label="$t('carrerer.labels.nombreVia')"
                                        ></ab-input>
                                    </div>
                                    <div class="col-12 col-lg-2">
                                        <ab-input
                                            autocomplete="address-line2"
                                            v-model="formModel.shippingAddress.carrerer.number"
                                            name="number-supplyAddress"
                                            placeholder=""
                                            :readonly="true"
                                            v-bind:label="$t('carrerer.labels.numeroVia')"
                                        ></ab-input>
                                    </div>
                                </div>
                                <div class="row mt-5 mb-5">
                                    <div class="col-12 col-lg-2">
                                        <ab-input
                                            autocomplete="address-line3"
                                            v-model="formModel.shippingAddress.otherAddressData"
                                            name="apartament-supplyAddress"
                                            placeholder=""
                                            :readonly="true"
                                            v-bind:label="$t('ofex.modificarDatosContrato.pisoPuerta')"
                                        ></ab-input>
                                    </div>
                                    <div class="col-12 col-lg-3">
                                        <ab-input
                                            autocomplete="postal-code"
                                            v-model="formModel.shippingAddress.carrerer.cp"
                                            name="postalCode-supplyAddress"
                                            placeholder=""
                                            :readonly="true"
                                            v-bind:label="$t('carrerer.labels.cp')"
                                        ></ab-input>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <!-- sección botones y textos finales -->
                        <section>
                            <div class="w-100 d-flex justify-content-end line-bottom pb-4">
                                <div class="box-button mt-2">
                                    <ab-button-ofex
                                        :text-button="$t('ofex.bajaContratoDetalle.enviar')"
                                        :click-event="modify"
                                        :disabled="invalid"
                                        :show-popover="invalid"
                                        :popover="$t('formularios.buttom.sendMessage')"
                                    ></ab-button-ofex>
                                </div>
                            </div>
                            <div class="mt-4">
                                <span class="d-block title-last-text">{{ $t("ofex.bajaContratoDetalle.titleSeguridadEnvio") }}</span>
                                <span class="d-block text-last-text">{{ $t("ofex.bajaContratoDetalle.textSeguridadEnvio") }}</span>
                                <span class="d-block title-last-text mt-3">{{ $t("ofex.bajaContratoDetalle.titleCaracterPersonal") }}</span>
                                <span class="d-block text-last-text" v-html="$t('ofex.bajaContratoDetalle.textCaracterPersonal')"></span>
                            </div>
                        </section>
                    </ValidationObserver>

                    <ab-your-interest
                        :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                        :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                        :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                        :name-center="$t('ofex.relatedContents.bonifications.name')"
                        :href-center="$t('ofex.relatedContents.bonifications.url')"
                        :image-center="$t('ofex.relatedContents.bonifications.image')"
                        :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                        :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                        :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                        target-right="_self"
                    />
                </b-col>
            </b-row>
        </b-container>
        <b-container v-else-if="selectedInactiveContract">
            <b-row>
                <ab-left-nav-bar />
                <b-col cols="12" md="12" lg="9">
                    <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                    <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                    <!--Main title -->
                    <ab-main-title :main-text="$t('ofex.bajaContrato.title')" />
                    <ab-without-data class="mt-3" :text="$t('ofex.modificationContractSelector.noActiveSelectedContracts')" />
                    <ab-your-interest
                        :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                        :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                        :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                        :name-center="$t('ofex.relatedContents.bonifications.name')"
                        :href-center="$t('ofex.relatedContents.bonifications.url')"
                        :image-center="$t('ofex.relatedContents.bonifications.image')"
                        :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                        :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                        :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                        target-right="_self"
                    />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { emptyCarrerer, trimBackendCode } from "../plugins/ab-utils";
import ContractUnsubscribePdfGenerator from "@/generators/pdf/ContractUnsubscribePdfGenerator";
import * as actionTypes from "@/store/action-types";
import moment from "moment";
import Swal from "sweetalert2";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import AbReturn from "../components/AbReturn.vue";
import ContentManagerServiceClient from "@/rest/contentManagerServiceClient";

export default {
    name: "UnsuscribeContractView",
    components: {
        AbReturn,
    },
    data: function () {
        return {
            carrererBaseUrl: process.env.VUE_APP_API_CARRERER_BASE_URL,
            readOnlyvalue: false,
            showBankInfo: true,
            contentManagerBaseUrl: process.env.VUE_APP_API_CONTENT_MANAGER_BASE_URL,
            today: moment()._d,
            oneMonth: moment().add(1, "months")._d,
            activityId: "",
            select: {
                treatmentSelectData: [
                    { key: "Sr.", text: this.$t("ofex.bajaContratoDetalle.selectTratamiento.sr") },
                    { key: "Sra.", text: this.$t("ofex.bajaContratoDetalle.selectTratamiento.sra") },
                    { key: "Sres.", text: this.$t("ofex.bajaContratoDetalle.selectTratamiento.sres") },
                ],
            },
            formModel: {
                datepicker: moment().toDate(),
                readValue: "",
                bankDetailsDomiciliation: null,
                headlineBankAccount: {
                    doc: { type: "", number: "", exists: false },
                    treatment: "",
                    name: "",
                    lastName: "",
                    secondLastName: "",
                    mainPhone: "",
                    email: "",
                },
                dataBank: {
                    country: "ES",
                    iban: "",
                    bic: "",
                },
                sameDirection: null,
                shippingAddress: {
                    carrerer: emptyCarrerer(),
                    otherAddressData: "",
                },
                attachedFile: {
                    file: { name: "" },
                    ecmProps: { fileId: null, actividadId: null, documentoId: null },
                },
                selectedInactiveContract: false,
            },
        };
    },
    computed: {
        ...mapState(["userData", "clientData", "isLoading", "contracts", "contractDetail"]),
        ...mapGetters(["getContractByContractNumber", "isCompany", "clientId", "userId", "multicontract", "selectedInactiveContract"]),
        contract: function () {
            return this.getContractByContractNumber(this.$route.params.contractId);
        },
    },
    mounted() {
        this.activityId = "AS" + new Date().getTime();
        this.selectedContract = this.$route.params.contractId;
        //Comprobamos que el contrato seleccionado no sea inactivo
        if (!this.selectedInactiveContract) {
            this.$store
                .dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, triggerChangeActiveContractLoading: false })
                .then(() => {});
            this.$store.dispatch(actionTypes.GET_CONTRACT_DETAIL_ACTION, {
                contractNumber: this.$route.params.contractId,
            });
        }
    },
    methods: {
        checkEventBlur: function () {
            //Pone documento y es el mismo que el pagador
            if (this.formModel.headlineBankAccount.doc.number.toUpperCase() == this.userOrClient().documentNumber.toUpperCase()) {
                this.readOnlyvalue = true;
                this.dumpInfoFormModel();
            } else {
                this.deleteInfoFormModel();
                this.warningOwnerPayment();
            }
        },
        supplyStreetTypeTranslation: function () {
            return this.$t("ofex.backendCodes.streetTypes." + trimBackendCode(this.contractDetail.supplyAddress.streetType));
        },
        dumpInfoFormModel: function () {
            console.log("dumpInfoFormModel" + this.contractDetail);
            this.formModel.headlineBankAccount.doc.type = this.isCompany ? this.clientData.documentType : this.userData.documentType;
            this.formModel.headlineBankAccount.treatment = this.isCompany ? "" : this.userData.treatment + ".";
            if (!this.formModel.headlineBankAccount.doc.number || this.formModel.bankDetailsDomiciliation) {
                this.formModel.headlineBankAccount.doc.number =
                    this.userOrClient().documentNumber != this.contractDetail.paymentData.documentNumber
                        ? this.changeValue(this.contractDetail.paymentData.documentNumber)
                        : this.userOrClient().documentNumber;
            }

            this.formModel.headlineBankAccount.name = this.isCompany
                ? this.clientData.documentNumber != this.contractDetail.paymentData.documentNumber &&
                  this.formModel.headlineBankAccount.doc.number.toUpperCase() != this.clientData.documentNumber.toUpperCase()
                    ? "******"
                    : this.clientData.name
                : this.userData.documentNumber != this.contractDetail.paymentData.documentNumber &&
                  this.formModel.headlineBankAccount.doc.number.toUpperCase() != this.clientData.documentNumber.toUpperCase()
                ? "******"
                : this.userData.firstName;

            this.formModel.headlineBankAccount.lastName =
                this.userOrClient().documentNumber != this.contractDetail.paymentData.documentNumber &&
                this.formModel.headlineBankAccount.doc.number.toUpperCase() != this.userOrClient().documentNumber.toUpperCase()
                    ? "******"
                    : this.userOrClient().firstLastName;

            this.formModel.headlineBankAccount.secondLastName =
                this.userOrClient().documentNumber != this.contractDetail.paymentData.documentNumber &&
                this.formModel.headlineBankAccount.doc.number.toUpperCase() != this.userOrClient().documentNumber.toUpperCase()
                    ? "******"
                    : this.userOrClient().secondLastName;

            this.formModel.dataBank.country = this.contractDetail.paymentData.country;

            this.formModel.dataBank.iban = this.formModel.bankDetailsDomiciliation
                ? this.changeIBAN()
                : this.userOrClient().documentNumber != this.contractDetail.paymentData.documentNumber &&
                  this.formModel.headlineBankAccount.doc.number.toUpperCase() != this.userOrClient().documentNumber.toUpperCase()
                ? this.changeIBAN()
                : "";

            this.formModel.dataBank.bic = this.formModel.bankDetailsDomiciliation
                ? this.changeValue(this.contractDetail.paymentData.bic)
                : this.userOrClient().documentNumber != this.contractDetail.paymentData.documentNumber &&
                  this.formModel.headlineBankAccount.doc.number.toUpperCase() != this.userOrClient().documentNumber.toUpperCase()
                ? this.changeValue(this.contractDetail.paymentData.bic)
                : "";

            trimBackendCode(this.contractDetail.paymentData.paymentMethod) != "BANCARIA" &&
            trimBackendCode(this.contractDetail.paymentData.paymentMethod) != "ENTITATBANCARIA"
                ? (this.showBankInfo = false)
                : (this.showBankInfo = true);
        },
        deleteInfoFormModel: function () {
            this.formModel.headlineBankAccount.doc.type = "";
            this.formModel.headlineBankAccount.doc.number = "";
            this.formModel.headlineBankAccount.treatment = "";
            this.formModel.headlineBankAccount.name = "";
            this.formModel.headlineBankAccount.lastName = "";
            this.formModel.headlineBankAccount.secondLastName = "";
            this.formModel.dataBank.country = "ES";
            this.formModel.dataBank.iban = "";
            this.formModel.dataBank.bic = "";
            this.showBankInfo = true;
        },
        dumpInfoSupplyFormModel: function () {
            this.formModel.shippingAddress.carrerer.province.name = this.contractDetail.supplyAddress.province;
            this.formModel.shippingAddress.carrerer.municipality = { name: this.contractDetail.supplyAddress.municipality };
            this.formModel.shippingAddress.carrerer.streetType = { code: this.contractDetail.supplyAddress.streetType };
            this.formModel.shippingAddress.carrerer.streetName = this.contractDetail.supplyAddress.streetName;
            this.formModel.shippingAddress.carrerer.number = this.contractDetail.supplyAddress.number;
            this.formModel.shippingAddress.carrerer.cp = this.contractDetail.supplyAddress.postalCode;
            this.formModel.shippingAddress.otherAddressData = this.contractDetail.supplyAddress.floor;
        },
        deleteInfoSupplyFormModel: function () {
            this.formModel.shippingAddress.carrerer.province.name = "";
            this.formModel.shippingAddress.carrerer.municipality = "";
            this.formModel.shippingAddress.carrerer.streetType = "";
            this.formModel.shippingAddress.carrerer.streetName = "";
            this.formModel.shippingAddress.carrerer.number = "";
            this.formModel.shippingAddress.otherAddressData = "";
            this.formModel.shippingAddress.carrerer.cp = "";
            this.formModel.shippingAddress.otherAddressData = "";
        },
        modify: function () {
            let validator = this.$refs.unsuscribeContractForm;
            validator.validate().then(async (result) => {
                if (result) {
                    console.debug("Baja de suministro:" + this.contract.contractDetail.contractNumber);
                    let pdfGenerator = new ContractUnsubscribePdfGenerator(this.$i18n, this.userId, this.clientId, this.contract.contractDetail.contractNumber);
                    pdfGenerator.generatePdf(this.formModel, this.contractDetail, this.contract.contractDetail.contractNumber, this.clientData, this.userData);
                    await pdfGenerator.uploadPdfWithActivity(this.activityId, false);
                    console.log("PDF ID" + pdfGenerator.uploadedDocumentId);
                    await ContentManagerServiceClient.commitStorageDocumentsByActivity(this.activityId);
                    this.$store
                        .dispatch(actionTypes.DELETE_CONTRACT, {
                            clientId: this.clientId,
                            userId: this.userId,
                            userData: this.userData,
                            contractNumber: this.contract.contractDetail.contractNumber,
                            formModel: this.formModel,
                            pdf: pdfGenerator.uploadedDocumentId,
                            activity: this.activityId,
                        })
                        .then((value) => {
                            this.$notifyFormActionToMatomo(true, "unsuscribe_contract");
                            Swal.fire({
                                showCloseButton: true,
                                showCancelButton: true,
                                text: this.$t("formularios.bajaUsuario.sendMessage"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                                cancelButtonText: this.$t("common.descargarPDF"),
                            }).then(async (result) => {
                                console.debug("Datos Modificados" + value);
                                if (!result.isConfirmed) {
                                    pdfGenerator.downloadPdf();
                                }
                            });
                        })
                        .catch((reason) => this.launchUpdateDeleteContract(reason));
                }
            });
        },
        warningOwnerPayment: function () {
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                html: this.$i18n.tc("ofex.bajaContratoDetalle.warningOwnerPayment"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
        launchUpdateDeleteContract: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "UserDataView" });
            let errorMessage = e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage");
            this.$notifyFormActionToMatomo(false, "unsuscribe_contract", errorMessage);
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: errorMessage,
                icon: "error",
                confirmButtonText: "OK",
            });
        },
        changeIBAN: function () {
            let iban = this.contractDetail.paymentData.iban;
            let firstSection = iban.substring(2, 4);
            let secondSection = iban.substring(21, 23);
            let thirdSection = iban.substring(35, 39);
            iban = iban.replace(firstSection, "**");
            iban = iban.replace(secondSection, "**");
            iban = iban.replace(thirdSection, "****");
            this.iban = iban;
            return this.iban;
        },
        changeValue: function (value) {
            let firstSection = value.substring(0, value.length / 2);
            value = value.replace(firstSection, "***");
            return value;
        },
        userOrClient: function () {
            return this.isCompany ? this.clientData : this.userData;
        },
        methodPaymentBackendCode: function () {
            return trimBackendCode(this.contractDetail.paymentData.paymentMethod);
        },
        validateModifiableInactiveContractMethod: function () {
            var invalidStatuses = ["EN VIGOR", "CONTRACTAT I NO INSTAL.LAT"];
            var supplyStatus = this.getContractByContractNumber(this.selectedContract).contractDetail.supplyStatus;

            if (!invalidStatuses.includes(supplyStatus)) {
                Swal.fire({
                    icon: "warning",
                    text: this.$t("ofex.modificarDatosContrato.warningModify"),
                    showConfirmButton: true,
                    confirmButtonText: this.$t("common.okButton"),
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then((result) => {
                    console.debug(result);
                    if (result.isConfirmed) {
                        this.$router.go(-1);
                    }
                });
            }
        },
    },
    watch: {
        "formModel.bankDetailsDomiciliation": function (val) {
            if (val) {
                this.readOnlyvalue = true;
                this.dumpInfoFormModel();
            } else {
                this.readOnlyvalue = false;
                this.deleteInfoFormModel();
            }
        },
        "formModel.sameDirection": function (val) {
            if (val) {
                this.dumpInfoSupplyFormModel();
            } else {
                this.deleteInfoSupplyFormModel();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.awesomplete {
    display: block !important;
}
.marked-fields {
    font-weight: $font-primary-medium;
    @include font-size(14);
    @include line-height(16);
}
.title-section {
    font-weight: $font-primary-bold;
    @include font-size(18);
    @include line-height(21);
}
.line-bottom {
    border-bottom: 1px solid $blue-dark;
}
.element-blue {
    @include font-size(14);
    @include line-height(21);
    color: $blue-lighter;
    font-weight: $font-primary-bold;
}
.element-black {
    @include font-size(14);
    @include line-height(21);
    font-weight: $font-primary-medium;
    margin-left: 3px;
    color: $color-primary;
}
.subtitle-blue {
    @include font-size(16);
    @include line-height(19);
    font-weight: $font-primary-bold;
    color: $blue-darker;
}

.detail-last-read {
    @include font-size(14);
    @include line-height(24);
    font-weight: $font-primary-medium;
}
.box-read {
    width: 213px;
}
@include respond-below(sm) {
    .box-read {
        width: 100%;
    }
}
.box-black-read {
    width: 140px;
    height: 40px;
    background-color: $color-secondary;
    border-radius: 4px 0px 0px 4px;
    float: left;
}
@include respond-below(sm) {
    .box-black-read {
        width: 75%;
    }
}
.box-red-cero {
    width: 73px;
    height: 40px;
    background-color: #ce2222;
    float: right;
    border-radius: 0px 4px 4px 0px;
    color: $color-lightest;
}
@include respond-below(sm) {
    .box-red-cero {
        width: 25%;
    }
}
.custom-input-read {
    font-weight: $font-primary-medium;
    @include font-size(14);
    @include line-height(16);
    color: $color-form-text;
    margin-top: 4px;
    width: 130px;
    margin-left: 5px;
    height: 32px;
    border-radius: 8px;
}
@include respond-below(sm) {
    .custom-input-read {
        width: 95%;
    }
}
.custom-input-read::-webkit-inner-spin-button,
.custom-input-read::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.custom-input-read {
    -moz-appearance: textfield;
}
.number-cero {
    font-weight: $font-primary-medium;
    @include font-size(20);
}
.number-cubic {
    font-weight: $font-primary-medium;
    @include font-size(14);
}
.label-for-read-value {
    color: $blue-dark;
    font-weight: $font-primary-bold;
    @include font-size(12);
    @include line-height(14);
}
.text-detail-small {
    font-weight: $font-primary-medium;
    @include font-size(10);
    @include line-height(15);
}
.box-photoCounter {
    width: 294px;
    height: 183px;
    border: 1px solid $border-table;
    background: linear-gradient(
            to top left,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%
        ),
        linear-gradient(
            to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%
        );
}
@include respond-below(sm) {
    .box-photoCounter {
        width: 100%;
    }
}
.bg-color-photoCounter {
    background-image: url("../assets/images/foto-contador.png");
    background-size: contain;
    background-repeat: no-repeat;
}
.space-right {
    padding-right: 20%;
}
.text-photoCount {
    font-weight: $font-primary-bold;
    @include font-size(24);
    @include line-height(29);
    color: $bg-primary;
}
.box-text-detail-photo {
    width: 294px;
}
.box-red-border {
    width: 160px;
    height: 137px;
    border-radius: 8px;
    border: 3px solid #cd0707;
}
.box-transparent {
    width: 294px;
    height: 183px;
    background-image: url(../assets/images/ofex/baja-contrato/box-red.png);
    background-repeat: no-repeat;
    background-size: 160px 137px;
    background-position: 19px 22px;
}
.box-text-button {
    width: 299px;
}
@include respond-below(sm) {
    .box-text-button {
        width: 100%;
    }
}
.half-div {
    width: 50%;
    float: left;
}
@include respond-below(sm) {
    .half-div {
        width: 100%;
        float: left;
    }
}
.size-button {
    width: 299px;
    margin-top: 35px;
}
@include respond-below(sm) {
    .size-button {
        width: 100%;
    }
}
.text-bank-data {
    font-weight: $font-primary-bold;
    @include font-size(14);
    @include line-height(15);
    color: $blue-darker;
    margin-top: 28px;
}
.text-checkBox-domiciliation {
    font-weight: $font-primary-medium;
    @include font-size(14);
    @include line-height(28);
}
label {
    margin-bottom: 0px !important;
}
.title-last-text {
    font-weight: $font-primary-bold;
    @include font-size(12);
    @include line-height(23);
}
.text-last-text {
    font-weight: $font-primary-medium;
    @include font-size(12);
    @include line-height(23);
}
.box-button {
    @include respond-below(sm) {
        width: 100%;
    }
}
.link {
    color: $blue-lighter;
    cursor: pointer;
}
</style>
<style lang="scss">
.v-upload {
    .custom-button {
        background-color: $blue-darker !important;
        color: $color-lightest;
        font-weight: $font-primary-bold;
        .azuleta {
            color: $color-lightest;
        }
    }
    @include respond-below(sm) {
        .custom-button {
            width: 100%;
        }
    }
    label {
        display: none;
    }
    .icon-popover {
        display: none;
    }
}
</style>
