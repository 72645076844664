<i18n src="../../components/common/i18n-components.i18n"></i18n>

<template>
    <div v-if="errorList.length > 0" class="box-error-login pl-24">
        <font-awesome-icon class="mt-8 text-white" :icon="fontawesomeIcon"></font-awesome-icon>
        <span v-for="error in new Set(errorList)" class="datos-ko ml-2">{{ error }}</span>
    </div>
</template>

<script>
export default {
    name: "BoxErrorLogin",
    props: {
        errorList: {
            type: Array,
            required: true,
        },
        fontawesomeIcon: {
            default: "exclamation-triangle",
        },
    },
};
</script>

<style lang="scss" scoped>
.box-error-login {
    width: 99%;
    height: 35px;
    background-color: $blue-dark;
    margin-bottom: 20px;
}
.pl-24 {
    padding-left: 24px;
}
.mt-8 {
    margin-top: 8px;
}
.datos-ko {
    color: $color-lightest;
    font-weight: $font-primary-bold;
    @include line-height(19);
    @include font-size(16);
}
</style>
