<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <v-dialog />
    </div>
</template>

<script>
import VModal from "vue-js-modal";
import moment from "moment";

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: { VModal },
    props: {
        modelName: {
            default: "formModel",
            type: String,
        },
        stepName: {
            default: "curStep",
            type: String,
        },
        dateTimestampName: {
            default: "modelDateTimestamp",
            type: String,
        },
        storageDurationHours: {
            default: 1,
            type: Number,
        },
        formModel: {
            required: true,
            type: Object,
        },
        curStep: {
            required: true,
            type: Number,
        },
    },
    data: function () {
        return {};
    },
    computed: {},
    watch: {
        formModel: {
            // eslint-disable-next-line no-unused-vars
            handler(newModel) {
                console.debug("Storing model");
                this.$localStorage.set(this.dateTimestampName, moment().unix());
                this.$localStorage.set(this.modelName, newModel);
                this.$localStorage.set(this.stepName, this.curStep);
            },
            deep: true,
        },
    },
    created() {
        this.$localStorage = this.$parent.$localStorage;
    },
    mounted: function () {
        console.debug("Mounted AbDataRecover");

        this.$i18n.locale = typeof themeDisplay !== "undefined" ? themeDisplay.getLanguageId().substring(0, 2) : "es";

        if (this.$localStorage.hasKey(this.modelName) && this.$localStorage.hasKey(this.dateTimestampName)) {
            let savedMoment = moment.unix(this.$localStorage.get(this.dateTimestampName));
            console.log("Fecha en que se han guardado los últimos datos: " + savedMoment.format());

            //si no han pasado la cantidad de días máximo desde que se guardaron los últimos datos
            let minutesDiff = moment().diff(savedMoment, "minutes");

            console.log(`Han pasado ${minutesDiff} minutos desde que se guardaron los datos`);

            if (minutesDiff < this.storageDurationHours * 60) {
                this.checkRestoreFormModelAndShowDialog();
            } else {
                //borramos los datos
                console.log(`Borramos los datos`);
                this.$localStorage.clear();
            }
        }
    },
    methods: {
        checkRestoreFormModelAndShowDialog: function () {
            console.log("Check restore form model and show dialog");
            var vueContext = this;
            this.$modal.show("dialog", {
                title: vueContext.$i18n.tc("dataRecover.title"),
                text: vueContext.$i18n.tc("dataRecover.dialogDescription"),
                buttons: [
                    {
                        title: vueContext.$i18n.tc("common.no"),
                        handler: () => {
                            vueContext.$localStorage.remove(vueContext.modelName);
                            vueContext.$modal.hide("dialog");
                        },
                    },
                    {
                        title: vueContext.$i18n.tc("common.yes"), // Button title
                        default: true, // Will be triggered by default if 'Enter' pressed.
                        handler: () => {
                            let formModel = vueContext.$localStorage.get(vueContext.modelName);
                            let curStep = vueContext.$localStorage.get(vueContext.stepName);
                            vueContext.$emit("recovered", {
                                formModel: formModel,
                                curStep: curStep,
                            });

                            vueContext.$modal.hide("dialog");
                        },
                    },
                ],
            });
        },
    },
};
</script>
