"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
const ACTIVITY_CODE = "CM";
export default class CustomerModifyPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId) {
        super("DatosTitular.pdf", ACTIVITY_CODE, i18n, userId, clientId, "");
    }

    generatePdf(localClientData) {
        const POINTS = ": ";
        console.log(`Generando pdf datos del titular`);
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.holderDataModify.title"), "");
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.holderDataModify.title"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.holderDataModify.cif") + POINTS + localClientData.documentNumber);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.holderDataModify.nombreEmpresa") + POINTS + localClientData.name);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.holderDataModify.correoElectronico") + POINTS + localClientData.email);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.holderDataModify.telefonoPrincipal") + POINTS + localClientData.mainPhone);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.holderDataModify.telefonoMovil") + POINTS + localClientData.phone);
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.holderDataModify.idioma") +
                POINTS +
                (localClientData.language === "es"
                    ? this.i18n.tc("ofex.pdfGenerator.holderDataModify.espanol")
                    : this.i18n.tc("ofex.pdfGenerator.holderDataModify.catalan"))
        );
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.holderDataModify.comunicaciones") + POINTS + (localClientData.advertising ? "Si" : "No"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.holderDataModify.encuestas") + POINTS + (localClientData.surveys ? "Si" : "No"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.holderDataModify.perfilado") + POINTS + (localClientData.profiling ? "Si" : "No"));

        return this.pdf;
    }
}
