<template>
    <b-container>
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <b-col cols="12" md="12">
                <ab-main-title :main-text="$t('ofex.passwordRecovery.title')"></ab-main-title>
                <ab-return></ab-return>
                <p class="subtitle-blue-recovery">
                    {{ $t("ofex.passwordRecovery.subTitle") }}
                </p>
                <p class="required-fields mb-3">
                    {{ $t("ofex.passwordRecovery.camposObligatorios") }}
                </p>
                <!----- PARTICULARES ---->
                <div v-if="isCompany === 'particular'">
                    <ValidationObserver ref="individualForm" v-slot="{ reset, invalid }">
                        <section>
                            <div class="row space-bottom">
                                <div class="col-12 col-lg-2">
                                    <ab-input
                                        autocomplete="username"
                                        name="username-recovery"
                                        v-model="userRecovery"
                                        :input-alias="$t('ofex.passwordRecovery.alias.usuario')"
                                        placeholder="NIF o NIE"
                                        :validation-delay="1000"
                                        :validation="{ required: true, spanishdocument: { allowNifNie: true, allowCif: false } }"
                                        :label="$t('ofex.passwordRecovery.nif')"
                                    ></ab-input>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <ab-input
                                        autocomplete="email"
                                        name="email-recovery"
                                        v-model="emailRecovery"
                                        :input-alias="$t('ofex.passwordRecovery.alias.recuperarEmail')"
                                        :placeholder="$t('ofex.passwordRecovery.placeholder.email')"
                                        :validation-delay="1000"
                                        :validation="{ required: true, email: true, max: 255 }"
                                        :popover="$t('ofex.passwordRecovery.popoverEmail')"
                                        :label="$t('ofex.passwordRecovery.labelEmail')"
                                    ></ab-input>
                                </div>
                            </div>
                            <div class="padd-bottom">
                                <div class="col-12 offset-lg-2 col-lg-8 without-padding">
                                    <router-link :to="{ name: viewMappingTypes.EMAIL_RECOVERY_MAPPING.name }">
                                        <font-awesome-icon class="blue-dark" :icon="fontawesomeIcon"></font-awesome-icon>
                                        <span class="ml-1 blue-dark forget-password">{{ $t("ofex.passwordRecovery.olvidoEmail") }}</span>
                                    </router-link>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row espacio-inf">
                                <div class="col-12">
                                    <div styler>
                                        <!-- Política de privacidad -->
                                        <section>
                                            <div class="pt-2 mb-4">
                                                <ab-section-header
                                                    class="section-head"
                                                    :section-name="$t('ofex.forms.reclamations.politicaPrivacidad')"
                                                ></ab-section-header>
                                            </div>
                                            <ab-legal-section
                                                :show-legal="false"
                                                :show-gdpr="true"
                                                :fsp-es="legalUrls.fspEs"
                                                :fsp-ca="legalUrls.fspCa"
                                                :gdpr-es="legalUrls.gdprEs"
                                                :gdpr-ca="legalUrls.gdprCa"
                                                :legal-es="legalUrls.urEs"
                                                :legal-ca="legalUrls.urCa"
                                            ></ab-legal-section>
                                            <div class="card-head"></div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row line-top espacio-inf">
                                <div class="col-12">
                                    <div styler>
                                        <div class="d-flex pt-3 justify-content-end">
                                            <div class="box-send">
                                                <vue-recaptcha
                                                    ref="recaptchaComponentParticular"
                                                    :sitekey="recaptchaSiteCode"
                                                    :load-recaptcha-script="true"
                                                    @verify="verifyCaptcha"
                                                    @expired="resetCaptchaParticular"
                                                >
                                                    <ab-button-ofex
                                                        type="submit"
                                                        class="caja-button"
                                                        :text-button="$t('ofex.buttonsOfex.enviar')"
                                                        :disabled="invalid"
                                                        :show-popover="invalid"
                                                        :popover="$t('formularios.buttom.sendMessage')"
                                                    ></ab-button-ofex>
                                                </vue-recaptcha>
                                            </div>
                                        </div>
                                        <div class="pt-4 mb-4">
                                            <ab-text-end name="textos-finales" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ValidationObserver>
                </div>
                <!---- EMPRESA --->
                <div v-else>
                    <ValidationObserver ref="companyForm" v-slot="{ reset, invalid }">
                        <section>
                            <div class="row mb-lg-4">
                                <div class="col-12 col-lg-2">
                                    <ab-input
                                        name="CIF"
                                        v-model="clientRecovery"
                                        input-alias="CIF"
                                        placeholder="CIF"
                                        :validation-delay="1000"
                                        :validation="{ required: true, spanishdocument: { allowNifNie: false, allowCif: true } }"
                                        :label="$t('ofex.passwordRecovery.cif')"
                                    ></ab-input>
                                </div>
                                <div class="col-12 col-lg-2">
                                    <ab-input
                                        name="NIF-empleado"
                                        v-model="userRecovery"
                                        input-alias="NIF / NIE"
                                        placeholder="NIF / NIE"
                                        :validation-delay="1000"
                                        :validation="{ required: true, spanishdocument: { allowNifNie: true, allowCif: false } }"
                                        :label="$t('ofex.passwordRecovery.nieEmpleado')"
                                    ></ab-input>
                                </div>
                            </div>
                            <div class="row space-bottom">
                                <div class="col-12 col-lg-4">
                                    <ab-input
                                        autocomplete="email"
                                        name="email-recovery"
                                        v-model="emailRecovery"
                                        :input-alias="$t('ofex.passwordRecovery.alias.recuperarEmail')"
                                        :placeholder="$t('ofex.passwordRecovery.placeholder.email')"
                                        :validation-delay="1000"
                                        :validation="{ required: true, email: true, max: 255 }"
                                        :popover="$t('ofex.passwordRecovery.popoverEmail')"
                                        :label="$t('ofex.passwordRecovery.labelEmail')"
                                    ></ab-input>
                                </div>
                            </div>
                            <div class="padd-bottom">
                                <router-link :to="'/recuperar-email?identification=company'">
                                    <font-awesome-icon class="blue-dark" :icon="fontawesomeIcon"></font-awesome-icon>
                                    <span class="ml-1 blue-dark forget-password">{{ $t("ofex.passwordRecovery.olvidoEmail") }}</span>
                                </router-link>
                            </div>
                        </section>
                        <section>
                            <div class="row espacio-inf">
                                <div class="col-12">
                                    <div styler>
                                        <!-- Política de privacidad -->
                                        <section>
                                            <div class="pt-2 mb-4">
                                                <ab-section-header
                                                    class="section-head"
                                                    :section-name="$t('ofex.forms.reclamations.politicaPrivacidad')"
                                                ></ab-section-header>
                                            </div>
                                            <ab-legal-section
                                                :show-legal="false"
                                                :show-gdpr="true"
                                                :fsp-es="legalUrls.fspEs"
                                                :fsp-ca="legalUrls.fspCa"
                                                :gdpr-es="legalUrls.gdprEs"
                                                :gdpr-ca="legalUrls.gdprCa"
                                                :legal-es="legalUrls.urEs"
                                                :legal-ca="legalUrls.urCa"
                                            ></ab-legal-section>
                                            <div class="card-head"></div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="row line-top espacio-inf">
                                <div class="col-12">
                                    <div styler>
                                        <div class="d-flex pt-3 justify-content-end">
                                            <div class="box-send">
                                                <vue-recaptcha
                                                    ref="recaptchaComponentCompany"
                                                    :sitekey="recaptchaSiteCode"
                                                    :load-recaptcha-script="true"
                                                    @verify="verifyCaptcha"
                                                    @expired="resetCaptchaCompany"
                                                    :class="{ native: $isNativeApp() }"
                                                >
                                                    <ab-button-ofex
                                                        type="submit"
                                                        class="caja-button"
                                                        :text-button="$t('ofex.buttonsOfex.enviar')"
                                                        :disabled="invalid"
                                                        :show-popover="invalid"
                                                        :popover="$t('formularios.buttom.sendMessage')"
                                                    ></ab-button-ofex>
                                                </vue-recaptcha>
                                            </div>
                                        </div>
                                        <div class="pt-4 mb-4">
                                            <ab-text-end name="textos-finales" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </ValidationObserver>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import * as viewMappingTypes from "../router/view-mapping-types";
import * as actionTypes from "../store/action-types";
import Swal from "sweetalert2";
import { mapGetters, mapState } from "vuex";
export default {
    name: "PasswordRecovery",
    props: {
        fontawesomeIcon: {
            default: "arrow-circle-right",
        },
    },
    data: function () {
        return {
            isCompany: "",
            viewMappingTypes: viewMappingTypes,
            titlePasswordRecovery: "Título",
            userRecovery: "",
            clientRecovery: "",
            emailRecovery: "",
            recaptchaSiteCode: process.env.VUE_APP_RECAPTCHA_SITE_CODE_INVISIBLE_MODE,
            recaptchaResponse: null,
        };
    },
    computed: {
        ...mapState(["isLoading"]),
        ...mapGetters(["legalUrls"]),
    },
    methods: {
        resetPassword: function () {
            let validator = this.isCompany === "particular" ? this.$refs.individualForm : this.$refs.companyForm;
            validator.validate().then((result) => {
                if (result) {
                    this.$store
                        .dispatch(actionTypes.RESET_USER_CREDENTIALS, {
                            clientId: this.isCompany === "particular" ? this.userRecovery : this.clientRecovery,
                            userId: this.userRecovery,
                            email: this.emailRecovery,
                            locale: this.$i18n.locale,
                        })
                        .then((value) => {
                            this.$notifyFormActionToMatomo(true, "password_modification");
                            Swal.fire({
                                text: this.$t("ofex.toasts.credentialsResetOK"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                            }).then(() => {
                                this.$router.push(viewMappingTypes.LOGIN_VIEW_MAPPING.path);
                                console.debug("Datos Enviados" + value);
                            });
                        })
                        .catch((reason) => {
                            this.$notifyFormActionToMatomo(false, "password_modification", reason?.response?.data.errorMessage);
                            this.launchResetPassword(reason);
                        })
                        .finally(
                            this.isCompany === "particular" ? this.$refs.recaptchaComponentParticular.reset() : this.$refs.recaptchaComponentCompany.reset()
                        );
                }
            });
        },
        launchResetPassword: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "PasswordRecovery" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.credentialsResetChangedError"),
                text: e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
        verifyCaptcha: function (response) {
            console.debug("Captcha response: " + response);
            this.recaptchaResponse = response;
            this.resetPassword();
        },
        resetCaptchaParticular: function () {
            // eslint-disable-next-line no-undef
            this.$refs.recaptchaComponentParticular.reset(); //resetamos el recaptcha para ser capaces de darle al botón nuevamente
        },
        resetCaptchaCompany: function () {
            // eslint-disable-next-line no-undef
            this.$refs.recaptchaComponentCompany.reset(); //resetamos el recaptcha para ser capaces de darle al botón nuevamente
        },
        mounted() {
            this.isCompany === "particular" ? this.$refs.recaptchaComponentParticular.execute() : this.$refs.recaptchaComponentCompany.execute();
        },
    },
    mounted() {
        this.isCompany = this.$route.params.isCompany;
    },
};
</script>

<style lang="scss" scoped>
.box-send {
    width: 193px;
    @include respond-below(md) {
        width: 100%;
    }
}
.required-fields {
    @include font-size(14);
    @include line-height(19);
    font-weight: $font-primary-medium;
}
.subtitle-blue-recovery {
    font-weight: $font-primary-bold;
    color: $blue-dark;
    @include font-size(18);
    @include line-height(21);
}
.blue-dark {
    color: $blue-dark;
}
.space-bottom {
    margin-bottom: 50px;
    @include respond-below(md) {
        margin-bottom: 15px;
    }
}
.padd-bottom {
    padding-bottom: 50px;
}
.line-bottom {
    border-bottom: 1px solid $blue-lighter;
}
.blue-border-top {
    border-top: 1px solid $blue-lighter;
}
.forget-password {
    font-weight: $font-primary;
    @include font-size(14);
}

.without-padding {
    padding: 0;
}
.card-head {
    border-top: 1px solid $blue-dark;
    padding-top: 10px;
    margin-bottom: 25px !important;
}

.native .grecaptcha-badge {
    visibility: hidden !important;
}
</style>
