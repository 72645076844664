<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="invoice-verification">
        <ab-loading v-model="invoiceLoading" :full-page="true"></ab-loading>

        <ab-id-type-number
            v-model="invoiceDoc"
            :allow-cif="isEmpresa"
            :allow-nif-nie="!isEmpresa"
            :validation-type-name="namesPrefix + '-tipo-documento'"
            :validation-number-name="namesPrefix + '-numero-documento'"
        >
        </ab-id-type-number>

        <div class="row mt-5 mb-5">
            <div class="col-8 col-lg-4">
                <ab-input
                    v-model="invoiceNumberValue"
                    :name="namesPrefix + '-numero-factura'"
                    :input-alias="$t('alias.invoice.numeroFactura')"
                    :placeholder="$t('invoiceVerification.numberPlaceholder')"
                    :popover="$t('invoiceVerification.numberPopover')"
                    :validation="{ required: true }"
                    :label="$t('invoiceVerification.numberLabel')"
                ></ab-input>
            </div>

            <div class="col-8 col-lg-4">
                <ab-input
                    v-model="invoiceAmountValue"
                    :readonly="invoiceNumberValue.length === 0"
                    :name="namesPrefix + '-importe-factura'"
                    :input-alias="$t('alias.invoice.importeFactura')"
                    :placeholder="$t('invoiceVerification.amountPlaceholder')"
                    :popover="$t('invoiceVerification.amountPopover')"
                    :validation="{ required: true, double: 2 }"
                    :label="$t('invoiceVerification.amountLabel')"
                ></ab-input>
            </div>

            <!--<div class="row mt-3 ml-1">
                <div class="col-12">
                    <p class="formTextErr displayYes">{{ $t('invoiceVerification.errorLabel') }}</p>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
import AbInput from "./AbInput.vue";
import AbLoading from "./AbLoading.vue";
import AbIdTypeNumber from "./AbIdTypeNumber.vue";

export default {
    components: {
        AbInput,
        AbIdTypeNumber,
        AbLoading,
    },
    model: {
        prop: "invoiceNumberAmount",
        event: "change",
    },
    props: {
        namesPrefix: String,
        isEmpresa: {
            default: false,
            type: Boolean,
        },
    },
    data: function () {
        return {
            invoiceLoading: false,
            invoice: {
                number: "",
                amount: "",
                doc: { type: "", number: "", exists: false },
                isValid: null,
            },
        };
    },
    computed: {
        invoiceNumberValue: {
            get: function () {
                console.debug("Getting invoice number");
                return this.$attrs["invoiceNumberAmount"].number;
            },
            set: function (newValue) {
                console.debug("Changing invoice number + " + newValue);
                this.invoice.number = newValue;
                this.$emit("change", this.invoice);
            },
        },
        invoiceAmountValue: {
            get: function () {
                console.debug("Getting invoice amount");
                return this.$attrs["invoiceNumberAmount"].amount;
            },
            set: function (newValue) {
                console.debug("Changing invoice amount + " + newValue);
                this.invoice.amount = newValue;
                this.$emit("change", this.invoice);
            },
        },
        invoiceDoc: {
            get: function () {
                console.debug("Getting invoice doc");
                return this.$attrs["invoiceNumberAmount"].doc;
            },
            set: function (newValue) {
                console.debug("Changing invoice doc + " + newValue);
                this.invoice.doc = newValue;
                this.$emit("change", this.invoice);
            },
        },
    },
    watch: {},
    methods: {
        scrollToHeader: function () {
            if (this.$root.$refs.steps) {
                this.$root.$refs.steps.scrollToHeader();
            }
        },
        isValidInvoice: async function () {
            this.invoiceLoading = true;

            let requestBody = {
                lang: typeof themeDisplay !== "undefined" ? themeDisplay.getLanguageId().substring(0, 2) : "es",
                documentType: this.invoiceDoc.type,
                documentNumber: this.invoiceDoc.number,
                invoiceNumber: this.invoiceNumberValue,
                invoiceAmount: this.invoiceAmountValue,
            };

            try {
                let validationResult = await this.$http.post("/o/ofex/invoices/validate", requestBody);
                console.debug("validation result " + validationResult);
                this.invoiceLoading = false;
                this.invoice.isValid = validationResult.body.result;

                if (!validationResult.body.result) {
                    this.scrollToHeader();
                }

                this.$emit("user-data", validationResult.body.userData);
                return validationResult.body;
            } catch (error) {
                this.invoiceLoading = false;
                console.error(error);
                this.invoice.isValid = false;
                this.scrollToHeader();
            }
        },
    },
};
</script>
