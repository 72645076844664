<template>
    <div>
        <ab-title-section-ofex
            :src-img="require('../assets/images/icono_sequia.png')"
            :title-section="$t('sequia.header')"
            class="mb-3"
        ></ab-title-section-ofex>
        <div class="ab-situacion-sequia box">
            <article class="left-section">
                <div class="left-content-wrapper affectation-text">
                    <img
                        v-if="affectationData?.imageUrl"
                        :src="affectationData.imageUrl"
                        alt="porcentaje de afectación"
                        v-show="droughtConfiguration.showAffectationImage"
                    />
                    <div v-if="this.detailText" class="status" v-html="this.detailText"></div>
                    <div v-else class="status" v-html="droughtConfiguration.defaultAdcHomeMessageHtml"></div>
                    <div class="detail-links" v-if="isAffected">
                        <router-link
                            class="nav-to-detail"
                            :to="{
                                name: viewMappingTypes.AFFECTATIONS_CONTRACTS_DETAIL_MAPPING.name,
                                params: { contractId: this.contractNumber },
                                hash: '#affectation-table',
                            }"
                        >
                            <p>{{ $t("sequia.restrictions") }}</p>
                            <img src="@/assets/images/row-right.png" alt="icono de navegar al enlace" />
                        </router-link>
                    </div>
                </div>
            </article>
            <article class="right-section" v-if="droughtConfiguration.embalses.showModule">
                <p class="titulo-sequia">{{ $t("sequia.tituloDos") }}</p>
                <div class="right-section-inner">
                    <div class="right-content-wrapper" :class="dropState">
                        <svg
                            width="92px"
                            height="119px"
                            class="icon-drop"
                            viewBox="0 0 92 119"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                        >
                            <title>Untitled 3</title>
                            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Group" stroke="#000000">
                                    <path
                                        d="M45.773,2.811 L46.192723,3.23058419 C54.6093067,11.6851049 90,48.215176 90,71.0909091 C90,83.7779466 85.0682376,95.2672322 77.0842467,103.582575 C69.1290942,111.867882 58.1412721,117 46,117 C33.8587279,117 22.8709058,111.867882 14.9157533,103.582575 C6.93176237,95.2672322 2,83.7779466 2,71.0909091 C2,47.951212 37.7784509,10.823318 45.64971,2.93517169 L45.773,2.811 Z"
                                        id="Oval"
                                        stroke-width="4"
                                    ></path>
                                    <path
                                        d="M2.68265258,86.3993674 C22.2022324,82.5193478 32.1817808,88.7733085 42.9590281,90.1428979 C54.4469073,91.6027955 69.025048,88.7733085 89.217306,80.6833982"
                                        id="Path"
                                        stroke-width="3"
                                        stroke-linejoin="round"
                                    ></path>
                                    <polygon
                                        id="Path-2"
                                        fill="#000000"
                                        points="4.87098435 87.0372133 12.6916678 85.5971511 21.2490314 85.5971511 27.0888889 87.0372133 33.7333333 88.8151475 37.8374583 89.4463338 44.1300708 90.510904 52.094295 90.510904 57.7861928 90.510904 60.7323222 90.510904 68.3989126 88.8151475 73.3253134 87.0372133 79.613181 84.8133021 84.9633246 83.0117941 89.217306 80.6833982 86.9650116 86.9837382 85.8575659 90.510904 83.4265875 96.1706213 80.9970171 98.7631901 78.5770354 102.132503 76.3806391 104.424902 73.3253134 108.347619 68.3989126 111.483073 64.3231761 113.607968 60.7323222 115.277598 55.4241622 116.842982 49.9227326 116.842982 42.0087171 116.842982 35.98835 116.842982 29.2433225 113.607968 23.5833421 111.483073 18.860841 108.347619 15.2113009 105.219447 11.2222921 100.365702 8.38281181 96.1706213 6.21210542 92.7272727 4.87098435 89.4463338"
                                    ></polygon>
                                </g>
                            </g>
                        </svg>
                        <div class="remain-percentage">
                            <p class="drop-upper-percentage mb-0">{{ droughtConfiguration.embalses.level }}%</p>
                            <p class="drop-upper-static mb-0">{{ $t("sequia.watterStaticText") }}</p>
                        </div>
                        <div class="remain-number" v-show="false">
                            <p class="drop-lower-number mb-0">{{ droughtConfiguration.embalses.hm3 }} Hm3</p>
                            <p class="drop-lower-static mb-0">{{ $t("sequia.watterStaticText") }}</p>
                        </div>
                    </div>
                    <ul class="status-list">
                        <li class="item" v-for="(item, i) in listProps" :key="i">
                            <a
                                v-if="item === getActiveStatus(item, i)"
                                :href="getTranslationUrl(item.url)"
                                class="status-link"
                                :class="statusActiveClass(item, i)"
                                target="_blank"
                            >
                                <div class="li_dot"></div>
                                <p class="status-text mb-0">{{ getTranslation(item.text) }}</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </article>
        </div>
    </div>
</template>

<!--DISEÑO DEL MODULO-->
<!--https://projectsofex.invisionapp.com/console/share/AXZ2FEJ4GRH/984184187-->

<script>
import { mapGetters, mapState } from "vuex";
import * as viewMappingTypes from "@/router/view-mapping-types";
import { isDroughtAffected } from "@/plugins/ab-utils";

export default {
    name: "AbSituacionSequia",
    props: {
        contractNumber: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            affectationData: null,
            listProps: [
                { text: "sequia.estado-uno", url: "sequia.urlEstadoUno" },
                { text: "sequia.estado-dos", url: "sequia.urlEstadoDos" },
                { text: "sequia.estado-tres", url: "sequia.urlEstadoTres" },
                { text: "sequia.estado-cuatro", url: "sequia.urlEstadoCuatro" },
                { text: "sequia.estado-cinco", url: "sequia.urlEstadoCinco" },
                { text: "sequia.estado-seis", url: "sequia.urlEstadoSeis" },
            ],
            affectProps: {
                status: "sequia.status",
                affectation: "sequia.affectation",
            },
        };
    },
    methods: {
        getTranslation(text) {
            return `${this.$t(text)}`;
        },
        getTranslationUrl(url) {
            return `${this.$t(url)}`;
        },
        statusActiveClass(item, i) {
            const isSelected = i === this.selected ? "selected" : "";
            const name = item.text.split(".")[1];
            return name + " " + isSelected;
        },
        getActiveStatus(item, i) {
            return this.selected === i ? item : null;
        },
    },
    computed: {
        viewMappingTypes() {
            return viewMappingTypes;
        },
        ...mapState(["droughtConfiguration", "i18nLocale", "selectedContractNumber"]),
        ...mapGetters(["getContractByContractNumber", "userId", "clientId"]),
        selected: function () {
            switch (this.droughtConfiguration.embalses.color) {
                case "blue":
                    return 0;
                case "green":
                    return 1;
                case "yellow":
                    return 2;
                case "orange":
                    return 3;
                case "pink":
                    return 4;
                case "red":
                    return 5;
                default:
                    return -1;
            }
        },
        contract: function () {
            return this.getContractByContractNumber(this.selectedContractNumber);
        },
        dropState() {
            return this.listProps[this.selected].text.split(".")[1];
        },
        detailText() {
            return this.i18nLocale === "es"
                ? this.contract?.droughtData.affectationsByDate[0]?.adcHomeMessageHtmlEs
                : this.contract?.droughtData.affectationsByDate[0]?.adcHomeMessageHtmlCa;
        },
        isAffected() {
            return isDroughtAffected(this.contract);
        },
    },
    mounted() {
        /*DroughtServiceClient.getDroughtAffectationDataByCode(
            this.userId,
            this.clientId,
            this.getContractByContractNumber(this.contractNumber).droughtData.affectedDestinyCode,
            this.$i18n.locale
        ).then((result) => (this.affectationData = result));*/
    },
};
</script>

<style lang="scss" scoped>
.ab-situacion-sequia {
    display: flex;

    @include respond-below(sm) {
        flex-wrap: wrap;
    }

    .titulo-sequia {
        color: $blue-lightest;
        @include font-size(21);
        @include line-height(25);
        margin-bottom: 20px;
        font-weight: $font-primary-bold;
        @include respond-below(sm) {
            margin-bottom: 20px;
        }
    }
    img {
        align-self: center;
        justify-self: center;
        margin-right: 10px;
        min-width: 100px;
    }
    .right-section {
        text-align: center;
        flex: 50%;
        padding: 0 30px;
        @include respond-below(lg) {
            padding: 0 15px;
        }
        .right-section-inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 30px;
        }
        .status-list {
            margin-bottom: 0;
            .item {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
                .status-link {
                    display: flex;
                    align-items: center;

                    .li_dot {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                    p {
                        margin: 0;
                        @include font-size(14);
                        @include line-height(18);
                        white-space: nowrap;
                        font-weight: $font-primary-medium;
                        color: $color-form-text;
                    }
                    &.estado-uno {
                        .li_dot {
                            background-color: #26749f;
                        }
                        .status-text {
                            color: #26749f;
                        }
                    }
                    &.estado-dos {
                        .li_dot {
                            background-color: #168500;
                        }
                        .status-text {
                            color: #168500;
                        }
                    }
                    &.estado-tres {
                        .li_dot {
                            background-color: #7f7810;
                        }
                        .status-text {
                            color: #7f7810;
                        }
                    }
                    &.estado-cuatro {
                        .li_dot {
                            background-color: #b35b14;
                        }
                        .status-text {
                            color: #b35b14;
                        }
                    }
                    &.estado-cinco {
                        .li_dot {
                            background-color: #ff9090;
                        }
                        .status-text {
                            color: #ff9090;
                        }
                    }
                    &.estado-seis {
                        .li_dot {
                            background-color: #ac0606;
                        }
                        .status-text {
                            color: #ac0606;
                        }
                    }
                    &.selected {
                        .li_dot {
                            width: 20px;
                            height: 20px;
                            margin-left: -2px;
                        }
                        .status-text {
                            font-weight: $font-primary-extraBold;
                        }
                    }
                }
            }
        }
        .right-content-wrapper {
            position: relative;
            align-items: center;
            margin: 0 auto;

            &.estado-uno {
                .remain-percentage p {
                    color: #26749f;
                }
                .icon-drop {
                    g {
                        stroke: #26749f;
                    }
                    polygon {
                        fill: #26749f;
                    }
                }
            }
            &.estado-dos {
                .remain-percentage p {
                    color: #168500;
                }
                .icon-drop {
                    g {
                        stroke: #168500;
                    }
                    polygon {
                        fill: #168500;
                    }
                }
            }
            &.estado-tres {
                .remain-percentage p {
                    color: #ebe052;
                }
                .icon-drop {
                    g {
                        stroke: #ebe052;
                    }
                    polygon {
                        fill: #ebe052;
                    }
                }
            }
            &.estado-cuatro {
                .remain-percentage p {
                    color: #e67519;
                }
                .icon-drop {
                    g {
                        stroke: #e67519;
                    }
                    polygon {
                        fill: #e67519;
                    }
                }
            }
            &.estado-cinco {
                .remain-percentage p {
                    color: #ff9190;
                }
                .icon-drop {
                    g {
                        stroke: #ff9190;
                    }
                    polygon {
                        fill: #ff9190;
                    }
                }
            }
            &.estado-seis {
                .remain-percentage p {
                    color: #df0707;
                }
                .icon-drop {
                    g {
                        stroke: #df0707;
                    }
                    polygon {
                        fill: #df0707;
                    }
                }
            }
            img {
                margin-right: 30px;

                @include respond-below(xs) {
                    margin-right: 15px;
                }
            }
            .remain-percentage {
                position: absolute;
                bottom: 45px;
                left: 20px;

                .drop-upper-percentage {
                    font-weight: $font-primary-bold;
                    height: 24px;
                    text-align: center;
                }
                .drop-upper-static {
                    @include font-size(10);
                    @include line-height(11);
                    font-weight: $font-primary-semiBold;
                }
            }
            .remain-number {
                position: absolute;
                bottom: 1px;
                left: 20px;
                color: $color-lightest;

                .drop-lower-number {
                    font-weight: $font-primary-bold;
                    @include font-size(9);
                    @include line-height(10);
                    margin: 0;
                    text-align: center;
                }
                .drop-lower-static {
                    @include font-size(9);
                    @include line-height(10);
                    font-weight: $font-primary-bold;
                }
            }
            .drop-upper-static,
            .drop-lower-static {
                width: 50px;
                text-align: center;
            }
        }
    }
    .left-section {
        display: flex;
        place-items: center;
        flex: 50%;
        padding: 0 30px;
        @include respond-below(lg) {
            padding: 0 15px;
        }
        .left-content-wrapper {
            display: flex;
            flex-direction: column;

            @include respond-below(lg) {
                flex-wrap: wrap;
                justify-content: center;

                img {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
            .detail-links {
                display: flex;
                flex-direction: column;
                place-items: center;
                .nav-to-detail {
                    display: flex;
                    margin-bottom: 10px;

                    img {
                        min-width: 20px !important;
                        margin-left: 10px;
                    }

                    p {
                        margin: 0;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }

            .status {
                width: 100%;
                color: $blue-dark;
                font-weight: $font-primary-medium;
                @include font-size(14);
                @include line-height(26);
                margin-bottom: 20px;
                @include respond-below(sm) {
                    margin-bottom: 30px;
                }

                ul {
                    list-style: disc;

                    li {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}
</style>
