import Storage from "vue-web-storage";
import VueCookies from "vue-cookies";
import VueGtm from "@gtm-support/vue2-gtm";
import VuePikaday from "@enrian/vue-pikaday";
import VueI18n from "vue-i18n/dist/vue-i18n.common";
import VModal from "vue-js-modal";
import VueRecaptcha from "vue-recaptcha";
import Vuex from "vuex";
import vueDebounce from "vue-debounce";
import VueCollapse from "vue2-collapse";
import VScrollLock from "v-scroll-lock";
import Vue2Crumbs from "vue-2-crumbs";
import VueGoodTablePlugin from "vue-good-table";
import { ToggleButton } from "vue-js-toggle-button";

import * as SentryVue from "@sentry/vue";
import * as SentryCapacitor from "@sentry/capacitor";

import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { BCarousel } from "bootstrap-vue";
import VueMq from "vue-mq";
import Dropdown from "bp-vuejs-dropdown";
import lineClamp from "vue-line-clamp";
import VTooltip from "v-tooltip";
import Vidle from "v-idle";

import { Capacitor } from "@capacitor/core";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faExclamationCircle,
    faDownload,
    faAirFreshener,
    faArrowCircleRight,
    faMapMarkerAlt,
    faBell,
    faUserAlt,
    faCaretDown,
    faExclamationTriangle,
    faArrowRight,
    faArrowLeft,
    faStar,
    faEdit,
    faFileInvoiceDollar,
    faFileInvoice,
    faTint,
    faBars,
    faChevronDown,
    faSearch,
    faPen,
    faChevronCircleRight,
    faCreditCard,
    faCopy,
    faWifi,
    faEuroSign,
    faPrint,
    faCheck,
    faTimes,
    faPaperclip,
    faHistory,
    faTrashAlt,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import BaseOfexServiceClient from "../rest/BaseOfexServiceClient";
import { LOGIN_VIEW_MAPPING } from "@/router/view-mapping-types";
import Swal from "sweetalert2";
import i18n from "@/i18n";
import moment from "moment";
import { router } from "@";
import VueMatomo from "vue-matomo";

export default {
    install: function (Vue, options) {
        //Instalamos todos los plugins comunes
        Vue.use(VueI18n);
        Vue.use(Vuex);
        Vue.use(vueDebounce);

        Vue.use(Storage, {
            prefix: options.elementId,
            drivers: ["session", "local"], // default 'local'
        });
        Vue.use(VueCookies);

        if (process.env.VUE_APP_GTM_ENABLED === "true") {
            console.log("Configurando Google Tag Manager");

            Vue.use(VueGtm, {
                id: options.gtmContainer, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
                defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
                enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
                debug: false, // Whether or not display console logs debugs (optional)
                loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
                vueRouter: options.router, // Pass the router instance to automatically sync with router (optional)
                trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
            });
        }

        if (process.env.VUE_APP_MATOMO_ENABLED === "true") {
            Vue.use(VueMatomo, {
                // Configure your matomo server and site by providing
                host: process.env.VUE_APP_MATOMO_HOST,
                siteId: Capacitor.isNativePlatform() ? process.env.VUE_APP_MATOMO_APP_SITE_ID : process.env.VUE_APP_MATOMO_SITE_ID,

                // Enables automatically registering pageviews on the router
                router: router,

                // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
                // Default: false
                enableHeartBeatTimer: true,

                // userId: store?.getters?.isAuthenticated ? store?.getters?.userId : undefined,

                // Whether or not to log debug information
                // Default: false
                debug: process.env.VUE_APP_MATOMO_DEBUG,
            });
        }

        Chart.plugins.unregister(ChartDataLabels);

        Vue.use(VuePikaday);
        Vue.use(VModal, { dialog: true });
        Vue.use(Vidle);

        library.add(faExclamationCircle);
        library.add(faDownload);
        library.add(faAirFreshener);
        library.add(faMapMarkerAlt);
        library.add(faBell);
        library.add(faUserAlt);
        library.add(faArrowCircleRight);
        library.add(faCaretDown);
        library.add(faExclamationTriangle);
        library.add(faArrowRight);
        library.add(faArrowLeft);
        library.add(faStar);
        library.add(faEdit);
        library.add(faFileInvoiceDollar);
        library.add(faFileInvoice);
        library.add(faTint);
        library.add(faBars);
        library.add(faChevronDown);
        library.add(faSearch);
        library.add(faPen);
        library.add(faChevronCircleRight);
        library.add(faCreditCard);
        library.add(faCopy);
        library.add(faWifi);
        library.add(faHistory);
        library.add(faEuroSign);
        library.add(faPrint);
        library.add(faCheck);
        library.add(faTimes);
        library.add(faPaperclip);
        library.add(faTrashAlt);
        library.add(faTrash);
        Vue.component("font-awesome-icon", FontAwesomeIcon);

        Vue.component("VModal", VModal);
        Vue.component("VueRecaptcha", VueRecaptcha);
        Vue.use(Vue2Crumbs);

        Vue.use(BootstrapVue);
        Vue.use(IconsPlugin);
        Vue.use(VueMq, {
            breakpoints: {
                xs: 768,
                sm: 992,
                md: 1280,
                lg: Infinity,
            },
        });
        Vue.use(Dropdown);
        Vue.use(VueCollapse);
        Vue.use(VScrollLock);
        Vue.use(VueGoodTablePlugin);
        Vue.component("ToggleButton", ToggleButton);
        Vue.use(lineClamp);
        Vue.use(VTooltip);
        Vue.component("b-carousel", BCarousel);

        //integracion Sentry
        if (process.env.VUE_APP_SENTRY_ENABLED === "true") {
            if (Capacitor.isNativePlatform()) {
                SentryCapacitor.init({
                    Vue,
                    environment: process.env.NODE_ENV,
                    release: "ab-ofex-components@" + process.env.VUE_APP_VERSION,
                    dsn: "https://e65cc5373ffa4d3fba8c8c193df70c95@o1072847.ingest.sentry.io/4505345902903296",
                    // This sets the sample rate of Sentry Replay
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,

                    integrations: [SentryVue.browserTracingIntegration({ router }), SentryVue.replayIntegration()],

                    // We recommend adjusting this value in production, or using tracesSampler
                    // for finer control
                    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
                });
            } else {
                SentryVue.init({
                    Vue,
                    environment: process.env.NODE_ENV,
                    release: "ab-ofex-components@" + process.env.VUE_APP_VERSION,
                    dsn: "https://ea9c7fd58b014840b8698adde660e1ec@o1072847.ingest.sentry.io/6072282",
                    ignoreErrors: ["chunk-vendors-legacy", "Cannot read properties of null", "Cannot read properties of undefined"],
                    // This sets the sample rate of Sentry Replay
                    replaysSessionSampleRate: 0.1,
                    replaysOnErrorSampleRate: 1.0,
                    integrations: [SentryVue.browserTracingIntegration({ router }), SentryVue.replayIntegration()],

                    // We recommend adjusting this value in production, or using tracesSampler
                    // for finer control
                    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE,
                });
            }
        }

        //configuramos el locale del momentJs
        moment.locale(i18n.locale);

        let abRestClient = new BaseOfexServiceClient().abRestClient;

        Vue.prototype.http = abRestClient;
        Vue.prototype.$http = abRestClient;

        Vue.prototype.$reloadPageLang = function (lang) {
            let currentPath = window.location.pathname;
            if (currentPath.startsWith(`/es/`) || currentPath.startsWith(`/ca/`)) {
                if (currentPath.substring(1, 2) === lang) {
                    location.reload();
                } else {
                    window.location.pathname = currentPath.replace(new RegExp("/es/|/ca/", "i"), `/${lang}/`);
                }
            } else {
                window.location.pathname = `/${lang}` + currentPath;
            }
        };

        //Inicializa un objeto imagen dado una URL
        Vue.prototype.$initializeImage = function (url) {
            let image = new Image();
            image.src = url;
            return image;
        };

        Vue.prototype.$downloadInvoice = function (args) {
            console.debug("Emitiendo evento de descarga de factura");
            this.$root.$emit("download-invoice", args);
        };

        //Valida que un usuario exista basado en su NIF y PASS
        Vue.prototype.$checkUserExists = async function (nif, password) {
            let requestBody = {
                user: nif,
                password: password,
                lang: this.$i18n.locale,
            };
            let validateResult = await abRestClient.post(`${process.env.VUE_APP_API_FORMS_PUBLIC_BASE_URL}/users/validate`, requestBody);
            return validateResult.body.exists;
        };

        //Valida que la fecha no sea nula y la formatea
        Vue.prototype.$safeDateFormat = function (date) {
            return date ? date.toLocaleDateString("es-ES") : null;
        };

        Vue.prototype.$formatNumber = function (number, minimumFractionDigits = 2, maximumFractionDigits = 2) {
            return number?.toLocaleString("ca-ES", { minimumFractionDigits, maximumFractionDigits });
        };

        Vue.prototype.$formatConsumption = function (number, short) {
            // si el consumo es menor de 100m3
            if (number !== null && number < 100) {
                return `${Vue.prototype.$formatNumber(number * 1000, 0, 0)} ${short ? "l" : i18n.tc("ofex.home.litros")}`;
            } else {
                return `${Vue.prototype.$formatNumber(number)} m<sup>3</sup>`;
            }
        };

        Vue.prototype.$currencyFormat = function (number) {
            return number?.toLocaleString("ca-ES", { style: "currency", currency: "EUR" });
        };

        Vue.prototype.$isNativeApp = function () {
            return Capacitor.isNativePlatform();
        };

        Vue.prototype.$isWebApp = function () {
            return !Capacitor.isNativePlatform();
        };

        Vue.prototype.$isIos = function () {
            return Capacitor.getPlatform() === "ios";
        };

        Vue.prototype.$unsaveFormDataWarning = function (i18n, to, from, next, predicate) {
            // Si hay datos sin guardar y no estamos yendo al login
            if (predicate.apply() && to.name !== LOGIN_VIEW_MAPPING.name) {
                Swal.fire({
                    text: i18n.tc("ofex.common.unsavedFormDataMessage"),
                    icon: "warning",
                    confirmButtonText: "OK",
                    showCancelButton: true,
                    cancelButtonText: i18n.tc("ofex.common.unsavedFormDataCancelText"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        next();
                    } else {
                        next(false);
                    }
                });
            } else {
                next();
            }
        };

        //Unifica el formato de una persona para poder ser enviada al API
        Vue.prototype.$mapPersonToApiIdentification = function (person) {
            if (person != null) {
                let telefono = person.tlfPrincipal ? person.tlfPrincipal : person.tlfMovil;
                telefono = telefono ? telefono : person.telefono;

                return {
                    tipoDocumento: person.doc.type,
                    numeroDocumento: person.doc.number,
                    nombre: person.nombre,
                    primerApellido: person.primerApellido,
                    segundoApellido: person.segundoApellido,
                    telefono: telefono,
                    tlfMovil: person.tlfMovil,
                    email: person.email,
                    twitter: person.twitter,
                };
            } else {
                return null;
            }
        };

        Vue.prototype.$notifyLoginToMatomo = async function (userId) {
            let user = userId ? userId : "";
            window._paq.push(["trackEvent", "login", "ok", user]);
        };
        Vue.prototype.$notifyErrorLoginToMatomo = function (errorMsg) {
            window._paq.push(["trackEvent", "login", "error", errorMsg]);
        };
        Vue.prototype.$notifyFormActionToMatomo = function (success, formName, cause = "") {
            window._paq.push(["trackEvent", "formularios", formName, `${success} - ${cause}`]);
        };
        Vue.prototype.$windowLocationPathname = window.location.pathname;

        Vue.prototype.$isMatomoAvalaible = function () {
            return process.env.VUE_APP_MATOMO_ENABLED === "true" && this.$matomo;
        };
    },
};
