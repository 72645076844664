<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <div class="row mt-4">
            <div class="col-1 .col-xs-1">
                <input
                    :id="name"
                    v-model="checkValue"
                    v-validate="validation"
                    class="checkBoxForm"
                    type="checkbox"
                    :name="name"
                    :data-vv-scope="validationScope"
                    data-vv-validate-on="input|change"
                    :data-vv-as="inputAlias"
                    :class="{ 'checkbox-error': errors.has(scopedName) || errors.has(scopedDocName) }"
                    @click="showContrato()"
                />

                <input
                    v-model="urlOpened"
                    v-validate="'required:true'"
                    type="hidden"
                    :name="docName"
                    :data-vv-scope="validationScope"
                    data-vv-validate-on="input|change"
                    :data-vv-as="docName"
                />
            </div>

            <div class="col-10 letraCardForm ml-1" v-html="legalTextComputed">
                {{ legalText }}
            </div>
        </div>

        <div v-if="errors.has(scopedName) || errors.has(scopedDocName)" class="row">
            <div class="col-12">
                <div class="anch90">
                    <p v-show="errors.has(scopedName)" class="fontSpecialForm formTextErr displayYes" style="float: left;">{{ errors.first(scopedName) }}</p>
                    <p v-show="errors.has(scopedDocName)" class="fontSpecialForm formTextErr displayYes" style="float: left;">
                        {{ errors.first(scopedDocName) }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: String,
        inputAlias: String,
        title: String,
        legalText: String,
        legalTextKey: String,
        docName: String,
        docUrl: String,
        validation: Object,
        validationScope: String,
    },
    data: function () {
        return {
            checkValue: false,
            urlOpened: false,
        };
    },
    computed: {
        scopedName: function () {
            return this.validationScope + "." + this.name;
        },
        scopedDocName: function () {
            return this.validationScope + "." + this.docName;
        },
        legalTextComputed: function () {
            return this.legalTextKey && this.legalTextKey.length > 0 ? this.$i18n.t(this.legalTextKey, { docUrl: this.docUrl }) : this.legalText;
        },
    },
    watch: {
        checkValue: function (val) {
            console.debug("Changing radio value");
            this.inputValue = val;
            this.$emit("change", this.checkValue);
        },
    },
    created() {
        this.$validator = this.$parent.$validator;
    },
    methods: {
        showContrato: function () {
            if (!this.urlOpened) {
                this.openContract();
                this.urlOpened = true;
            }
        },
        openContract: function () {
            window.open(this.docUrl);
        },
    },
};
</script>

<style>
.legal-image-cursor {
    cursor: pointer;
}
</style>
