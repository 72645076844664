<i18n src="../../components/common/i18n-components.i18n"></i18n>

<template>
    <v-idle
        ref="idleController"
        v-show="false"
        @idle="logoutByIdleSession"
        @remind="sessionIdleReminder"
        :reminders="[reminderTime]"
        :duration="userInactivityDuration"
    />
</template>

<script>
import * as actionTypes from "@/store/action-types";
import Swal from "sweetalert2";

export default {
    name: "AbIdleSession",
    props: {},
    data: function () {
        return {
            userInactivityDuration: parseInt(process.env.VUE_APP_INACTIVITY_DURATION),
            remainderAt: parseInt(process.env.VUE_APP_REMAINDER_AT),
        };
    },
    methods: {
        sessionIdleReminder: function () {
            const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: true,
                confirmButtonText: this.$t("common.ampliar"),
                timer: this.remainderAt * 1000,
                timerProgressBar: true,
                icon: "warning",
            });

            Toast.fire({
                title: `${this.$i18n.tc("ofex.toasts.inactividad")} ${(this.userInactivityDuration / 60).toFixed(0)} ${this.$i18n.tc("ofex.toasts.minutos")}`,
            });
        },
        logoutByIdleSession: function () {
            this.$store.dispatch(actionTypes.LOGOUT_ACTION, { logoutReason: "inactivity" });
        },
    },
    computed: {
        reminderTime: function () {
            let diff = this.userInactivityDuration - this.remainderAt;
            return this.userInactivityDuration - diff;
        },
    },
    mounted() {
        //TODO revisar que esto no cierre otros modales de SweetAlert
        // window.addEventListener("mousemove", () => Swal.close());
    },
};
</script>

<style lang="scss" scoped></style>
