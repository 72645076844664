<template>
    <div class="m-your-interest box" v-if="$isWebApp()">
        <p class="texto-informacion">{{ $t("ofex.home.puedeInteresar") }}</p>
        <div class="item-container">
            <a class="item" :href="hrefLeft" :target="targetLeft">
                <img alt="contenido relacionado izquierda" :src="imageLeft" />
                <p class="text">{{ nameLeft }}</p>
            </a>
            <a class="item" :href="hrefCenter" :target="targetCenter">
                <img alt="contenido relacionado centro" :src="imageCenter" />
                <p class="text">{{ nameCenter }}</p>
            </a>
            <a class="item" :href="hrefRight" :target="targetRight">
                <img alt="contenido relacionado derecho" :src="imageRight" />
                <p class="text">{{ nameRight }}</p>
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "AbYourInterest",
    props: {
        nameLeft: {
            type: String,
            default: "Pendiente de rellenar",
        },
        imageLeft: {
            type: String,
            default: "https://www.aiguesdebarcelona.cat/documents/20126/948597/Imagen+Base+Contenido+Relacionado.jpg/16053d55-4123-0f34-09be-bc5b31feb0c9",
        },
        hrefLeft: {
            type: String,
            default: "/",
        },
        targetLeft: {
            type: String,
            default: "_blank",
        },
        nameCenter: {
            type: String,
            default: "Pendiente de rellenar",
        },
        imageCenter: {
            type: String,
            default: "https://www.aiguesdebarcelona.cat/documents/20126/948597/Imagen+Base+Contenido+Relacionado.jpg/16053d55-4123-0f34-09be-bc5b31feb0c9",
        },
        hrefCenter: {
            type: String,
            default: "/",
        },
        targetCenter: {
            type: String,
            default: "_blank",
        },
        nameRight: {
            type: String,
            default: "Pendiente de rellenar",
        },
        imageRight: {
            type: String,
            default: "https://www.aiguesdebarcelona.cat/documents/20126/948597/Imagen+Base+Contenido+Relacionado.jpg/16053d55-4123-0f34-09be-bc5b31feb0c9",
        },
        hrefRight: {
            type: String,
            default: "/",
        },
        targetRight: {
            type: String,
            default: "_blank",
        },
    },
};
</script>

<style lang="scss" scoped>
.m-your-interest {
    .item-container {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        @include respond-below(md) {
            flex-wrap: wrap;
        }

        .item {
            flex: 1;
            margin-right: 25px;

            @include respond-below(md) {
                flex: 100%;
                margin-right: 0;
                margin-bottom: 25px;
            }

            &:last-child {
                margin-right: 0;

                @include respond-below(md) {
                    margin-bottom: 0;
                }
            }
            img {
                border-radius: 8px 8px 0 0;
            }
            .text {
                background: $bg-primary;
                color: $color-lightest;
                font-weight: $font-primary-medium;
                justify-content: center;
                height: 65px;
                align-items: center;
                display: flex;
                @include font-size(16);
                @include line-height(19);
                padding: 7px 20px;
                text-align: center;
                border-radius: 0 0 8px 8px;

                a {
                    color: $color-lightest;
                }
            }
        }
    }
    .texto-informacion {
        color: $blue-darkest;
        font-weight: $font-primary-bold;
        @include font-size(16);
    }
}
</style>
