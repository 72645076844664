"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";

import { trimBackendCode } from "@/plugins/ab-utils";
import moment from "moment";
const ACTIVITY_CODE = "CE";
export default class CEModifyPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId, contract) {
        super("ModificacionCedulaHabitabilidad.pdf", ACTIVITY_CODE, i18n, userId, clientId, contract);
    }
    generatePdf(formModel, contractDetail, selectedContract, userData, clientData, isCompany) {
        const SPACE = " ";

        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.title"), "");
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.detalleContrato"));
        this.generateLineText(
            isCompany
                ? this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.titularContrato") + SPACE + clientData.name
                : this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.titularContrato") +
                      SPACE +
                      userData.firstName +
                      SPACE +
                      userData.firstLastName +
                      SPACE +
                      userData.secondLastName
        );
        this.generateLineText(
            isCompany
                ? this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.nifNieParticular") + SPACE + clientData.documentNumber
                : this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.nifNieParticular") + SPACE + userData.documentNumber
        );
        this.generateLineText(
            isCompany
                ? this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.correoElectronico") + SPACE + clientData.email
                : this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.correoElectronico") + SPACE + userData.email
        );
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.numeroContrato") + SPACE + selectedContract);

        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.direccionSuministro") +
                SPACE +
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(contractDetail.supplyAddress.streetType)) +
                SPACE +
                contractDetail.supplyAddress.streetName +
                SPACE +
                contractDetail.supplyAddress.number +
                SPACE +
                contractDetail.supplyAddress.otherAddressData +
                SPACE +
                contractDetail.supplyAddress.municipality +
                SPACE
        );
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.DatosCedula"), "");
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.cedulaHabitabilidad") + SPACE + formModel.certificateOfOccupancy);
        moment.locale("es");
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.fechaCedula") +
                SPACE +
                moment(formModel.dateCertificate, "Do MMMM YYYY, h:mm:ss a").format("L")
        );
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.CEModifyPdfGenerator.caducidadCedula") + SPACE + formModel.expirationCertificate);
        return this.pdf;
    }
}
