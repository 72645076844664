<i18n src="./common/i18n-components.i18n"></i18n>

<!--TODO REVISAR TRADUCCIONES -->

<template>
    <div class="ab-legal-section mt-3" :class="fatherClass">
        <ab-remote-modal :html-url-es="timestampLegalEsURL" :html-url-ca="timestampLegalCaURL" :modal-name="legalModalUuid" @modalresult="legalModalResult" />
        <ab-remote-modal :html-url-es="timestampGdprEsURL" :html-url-ca="timestampGdprCaURL" :modal-name="gdprModalUuid" @modalresult="gdprModalResult" />
        <ab-remote-modal
            v-if="showFsp"
            :html-url-es="timestampFspEsURL"
            :html-url-ca="timestampFspCaURL"
            :modal-name="fspModalUuid"
            @modalresult="fspModalResult"
        >
        </ab-remote-modal>
        <div v-if="showLegal">
            <div class="d-flex" :class="{ 'flex-justify-center': !floatLeft }">
                <div class="">
                    <label :for="legalModalUuid" class="text-normal ml-1"
                        >{{ legalLabelEntry }}
                        <a href="#" class="titlelegal" @click.prevent="showLegalModal(false)">
                            {{ linkedTextLegal }}
                        </a>
                    </label>
                </div>
                <div class="ml-1">
                    <validation-provider
                        v-if="showLegal"
                        :name="$t('legalSection.legalAlias')"
                        v-slot="{ errors }"
                        :vid="`${legalModalUuid}-vid`"
                        :rules="{ required: requiredLegal ? { allowFalse: false } : false }"
                    >
                        <div class="ml-2" :class="errors.length > 0 ? 'border-error' : ''">
                            <input
                                :id="legalModalUuid"
                                v-model="legalAceptado"
                                aria-label="aviso legal"
                                class="digital-input"
                                :name="legalModalUuid"
                                type="checkbox"
                                @click="showLegalModal(true)"
                            />
                        </div>
                    </validation-provider>
                    <input id="legalModalUuid" name="legalModalUuid" v-show="false" v-model="legalLeido" type="checkbox" />
                </div>
            </div>
        </div>
        <div v-if="showGdpr" class="mt-1">
            <div class="d-flex" :class="{ 'flex-justify-center': !floatLeft }">
                <div class="">
                    <label :for="gdprModalUuid" class="text-normal ml-1"
                        >{{ $t("legalSection.aceptoLa") }}
                        <a href="#" class="titlelegal" @click.prevent="showGdprModal(false)">
                            {{ linkedTextGdpr }}
                        </a>
                    </label>
                </div>
                <div class="ml-1">
                    <validation-provider
                        :name="$t('legalSection.gdprAlias')"
                        :vid="`${gdprModalUuid}-vid`"
                        v-slot="{ errors }"
                        :rules="{ required: requiredGdpr ? { allowFalse: false } : false }"
                    >
                        <div class="ml-2" :class="errors.length > 0 ? 'border-error' : ''">
                            <input
                                :id="gdprModalUuid"
                                v-model="gdprAceptado"
                                aria-label="politica de privacidad"
                                :name="gdprModalUuid"
                                type="checkbox"
                                @click="showGdprModal(true)"
                            />
                        </div>
                    </validation-provider>
                    <input v-show="false" v-model="gdprLeido" type="checkbox" />
                </div>
            </div>
        </div>
        <!--        Se cambio el v-if por v-show por bug detectado en https://zenerab.atlassian.net/browse/NW-1510-->
        <!--      TODO revisar regresión con v-if y bug NW-1510. Posible solucion con vee-validate 3 es parametrizar el allowFalse-->
        <div v-if="showFsp">
            <div class="d-flex" :class="{ 'flex-justify-center': !floatLeft }">
                <div class="">
                    <label :for="fspModalUuid" class="text-normal ml-1"
                        >{{ $t("legalSection.aceptoLas") }}
                        <a href="#" class="titlelegal" @click.prevent="showFspModal(false)" v-html="linkedTextFsp"> </a>
                    </label>
                </div>
                <div class="ml-1">
                    <validation-provider
                        :name="$t('legalSection.fspAlias')"
                        :vid="`${fspModalUuid}-vid`"
                        v-slot="{ errors }"
                        :rules="{ required: requiredFsp ? { allowFalse: false } : false }"
                    >
                        <div class="ml-2" :class="errors.length > 0 ? 'border-error' : ''">
                            <input
                                :id="fspModalUuid"
                                v-model="fspAceptado"
                                aria-label="factura digital"
                                :name="fspModalUuid"
                                type="checkbox"
                                @click="showFspModal(true)"
                            />
                        </div>
                    </validation-provider>
                    <input v-show="false" v-model="fspLeido" type="checkbox" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AbRemoteModal from "./AbRemoteModal.vue";

export default {
    components: {
        AbRemoteModal,
    },
    props: {
        fatherClass: {
            required: false,
            type: String,
        },
        legalEs: {
            required: false,
            type: String,
        },
        legalCa: {
            required: false,
            type: String,
        },
        legalLabelEntry: {
            type: String,
            default: function () {
                return this.$i18n.tc("legalSection.aceptoEl");
            },
        },
        legalLabelLinked: {
            type: String,
            default: function () {
                return this.$i18n.tc("legalSection.avisoLegal");
            },
        },
        gdprEs: {
            required: false,
            type: String,
        },
        gdprCa: {
            required: false,
            type: String,
        },
        fspEs: {
            required: false,
            type: String,
        },
        fspCa: {
            required: false,
            type: String,
        },
        showGdpr: {
            default: true,
            type: Boolean,
        },
        showLegal: {
            default: true,
            type: Boolean,
        },
        showFsp: {
            default: false,
            type: Boolean,
        },
        requiredGdpr: {
            default: true,
            type: Boolean,
        },
        requiredLegal: {
            default: true,
            type: Boolean,
        },
        requiredFsp: {
            default: true,
            type: Boolean,
        },
        floatLeft: {
            default: true,
            type: Boolean,
        },
    },
    data: function () {
        return {
            legalModalUuid: null,
            legalLeido: null,
            legalAceptado: null,
            gdprModalUuid: null,
            gdprLeido: null,
            gdprAceptado: null,
            fspModalUuid: null,
            fspLeido: null,
            fspAceptado: null,
        };
    },
    computed: {
        timestampLegalEsURL: function () {
            return this.legalEs + "&t=" + Date.now();
        },
        timestampLegalCaURL: function () {
            return this.legalCa + "&t=" + Date.now();
        },
        timestampGdprEsURL: function () {
            return this.gdprEs + "&t=" + Date.now();
        },
        timestampGdprCaURL: function () {
            return this.gdprCa + "&t=" + Date.now();
        },
        timestampFspEsURL: function () {
            return this.fspEs + "&t=" + Date.now();
        },
        timestampFspCaURL: function () {
            return this.fspCa + "&t=" + Date.now();
        },
        linkedTextLegal: function () {
            return this.requiredLegal === true ? this.legalLabelLinked : this.legalLabelLinked.replace("*", "");
        },
        linkedTextFsp: function () {
            return this.requiredFsp === true ? this.$i18n.tc("legalSection.fsp") : this.$i18n.tc("legalSection.fsp").replace("*", "");
        },
        linkedTextGdpr: function () {
            return this.requiredGdpr === true ? this.$i18n.tc("legalSection.gdpr") : this.$i18n.tc("legalSection.gdpr").replace("*", "");
        },
    },
    watch: {
        gdprAceptado: function (val) {
            this.$emit("gdpr-changed", val);
        },
        legalAceptado: function (val) {
            this.$emit("legal-changed", val);
        },
        fspAceptado: function (val) {
            this.$emit("fsp-changed", val);
        },
    },
    created() {
        this.legalModalUuid = "legalModal" + Math.floor(Math.random() * 1000);
        this.gdprModalUuid = "gdprModal" + Math.floor(Math.random() * 1000);
        this.fspModalUuid = "fspModal" + Math.floor(Math.random() * 1000);
    },
    mounted() {},
    methods: {
        showLegalModal: function (checkLeido) {
            if (!checkLeido || !this.legalLeido) {
                console.debug("Showing legal modal");
                this.legalLeido = true;
                this.$modal.show(this.legalModalUuid);
            }
        },
        showGdprModal: function (checkLeido) {
            if (!checkLeido || !this.gdprLeido) {
                console.debug("Showing gdpr modal");
                this.gdprLeido = true;
                this.$modal.show(this.gdprModalUuid);
            }
        },
        showFspModal: function (checkLeido) {
            if (!checkLeido || !this.fspLeido) {
                console.debug("Showing fsp modal");
                this.fspLeido = true;
                this.$modal.show(this.fspModalUuid);
            }
        },
        legalModalResult: function (result) {
            console.debug("Result: " + result);
            this.$emit("legal-url", this.$i18n.locale === "es" ? this.legalEs : this.legalCa);
            this.legalAceptado = result;
        },
        gdprModalResult: function (result) {
            console.debug("Result: " + result);
            this.$emit("gdpr-url", this.$i18n.locale === "es" ? this.gdprEs : this.gdprCa);
            this.gdprAceptado = result;
        },
        fspModalResult: function (result) {
            console.debug("Result: " + result);
            this.$emit("fsp-url", this.$i18n.locale === "es" ? this.fspEs : this.fspCa);
            this.fspAceptado = result;
        },
    },
};
</script>
<style lang="scss" scoped>
input[type="checkbox"] {
    cursor: pointer;
    height: 26px;
    width: 26px !important;
}
.text-normal {
    @include font-size(15);
    @include line-height(16);
    font-weight: $font-primary-semiBold;
    margin-top: 8px;
}
.titlelegal {
    text-decoration: underline;
    color: $link-secondary;
}
.digital-input {
    cursor: pointer;
    height: 26px;
    width: 26px;
}
.move-div {
    position: relative;
    bottom: 10px;
}
.text-error {
    @include font-size(12);
    @include line-height(30);
    margin-left: 20px;
    color: $error-color;
    font-weight: $font-primary-medium;
}
.border-error {
    border: 2px solid red;
    width: 30px;
    border-radius: 5px;
    height: 30px;
}
.flex-justify-center {
    justify-content: center;
}
</style>
