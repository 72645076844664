<i18n src="@/components/common/i18n-components.i18n"></i18n>
<template>
    <div>
        <p class="text-normal mt-3">{{ $t("ofex.authorizationsView.subsectionPerContractSubtitle") }}</p>
        <ab-authorizations-advance-search-by-contract
            v-show="true"
            v-model="filteredContracts"
            ref="authorizationsAdvanceSearch"
            @filter-contracts="executeFilter"
            @clear-filter="resetFilter"
            :total="this.contracts.length"
            :rows="tableRows.length"
        />
        <vue-good-table
            v-if="contracts"
            ref="authorizationsGroupedByContractTable"
            :columns="columns"
            :rows="tableRows"
            :rowStyleClass="getRowStyleClass"
            style-class="vgt-table"
            :select-options="{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
            aria-label="perPageSelect"
            :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                perPageDropdown: [10, 20, 30],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: this.$i18n.tc('table.siguiente'),
                prevLabel: this.$i18n.tc('table.atras'),
                rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                ofLabel: 'de',
                allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                label: 'selector-de-paginas',
                name: 'perPageSelect',
            }"
        >
            <div slot="emptystate" class="text-center textdnormal">
                {{ $t("ofex.tuContrato.emptyResults") }}
            </div>
        </vue-good-table>

        <div class="ow mt-3 mb-3 justify-content-end">
            <div v-if="hasAnyInactiveContract === true" class="text-inactive-contract">
                <p class="text-normal mt-1">{{ $t("ofex.home.inactivos") }}</p>
            </div>
        </div>

        <div class="row mt-3 mb-3 justify-content-end">
            <div class="col-lg-4 mb-4">
                <ab-button-ofex :text-button="$t('ofex.buttonsOfex.addAuthorizationPerson')" :click-event="addContractAuthorization"></ab-button-ofex>
            </div>
            <div class="col-lg-6">
                <!--  TODO revisar añadir v-show para que solo aparezca el boton cuando no hay ningún check seleccionado-->
                <ab-button-ofex
                    :text-button="$t('ofex.buttonsOfex.manageAuthorizationPersons')"
                    :click-event="navigateToAuthorizationsByContract"
                ></ab-button-ofex>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import "vue2-datepicker/locale/ca";
import "vue2-datepicker/locale/es";
import "vue2-datepicker/index.css";
// import AbContractsAdvanceSearch from "@/modules/AbContractsAdvanceSearch";
import AbAuthorizationsAdvanceSearchByContract from "@/modules/authorizations/AbAuthorizationsAdvanceSearchByContract";
import { VueGoodTable } from "vue-good-table";
import * as actionTypes from "@/store/action-types";
import * as viewMappingTypes from "@/router/view-mapping-types";
import Swal from "sweetalert2";
import { trimBackendCode } from "@/plugins/ab-utils";

export default {
    name: "",
    components: {
        // AbContractsAdvanceSearch,
        AbAuthorizationsAdvanceSearchByContract,
        VueGoodTable,
    },
    data() {
        return {
            show: false,
            filteredContracts: null,
            tableSelectOptions: {
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: "custom-class",
                selectionText: "Autorizaciones Seleccionadas",
                clearSelectionText: this.$t("ofex.tuContrato.limpiar"),
                disableSelectInfo: true, // disable the select info panel on top
            },
        };
    },
    methods: {
        executeFilter: function (filter) {
            if (filter.municipality) {
                [...this.filteredContracts] = this.contracts.filter((contract) => contract.supplyAddress.municipality == filter.municipality);
            }
            if (filter.streetName) {
                [...this.filteredContracts] = this.contracts.filter((contract) => contract.supplyAddress.streetName == filter.streetName);
            }
            if (filter.contractNumber) {
                [...this.filteredContracts] = this.contracts.filter((contract) => contract.contractDetail.contractNumber == filter.contractNumber);
            }
            if (filter.alias) {
                [...this.filteredContracts] = this.contracts.filter((contract) => contract.contractDetail.alias == filter.alias);
            }
        },
        resetFilter: function () {
            this.filteredContracts = [...this.contracts];
        },
        computeAuthorizationsByContract: function (contractNumber) {
            let entry = this.authorizationsByContract.find((entry) => entry.contractNumber === contractNumber);
            return entry ? entry.authorizations.length : 0;
        },
        computeDocumentNumbersByContract: function (contractNumber) {
            return this.authorizationsByContract.filter((entry) => entry.contractNumber === contractNumber).map((entry) => entry.documentNumber);
        },
        mapContractToGroupEntry: function (contract) {
            return {
                contractNumber: contract.contractDetail.contractNumber,
                alias: contract.contractDetail.alias,
                supplyAddress: this.getContractSupplyCompleteAddress(contract.contractDetail.contractNumber),
                authorizedPersons: this.computeAuthorizationsByContract(contract.contractDetail.contractNumber),
                status: this.supplyStatusTranslation(contract.contractDetail.supplyStatus),
            };
        },
        navigateToAuthorizationsByContract: function () {
            if (this.$refs.authorizationsGroupedByContractTable.selectedRows.length === 1) {
                let contractNumber = this.$refs.authorizationsGroupedByContractTable.selectedRows[0].contractNumber;
                let authorizedPersons = this.$refs.authorizationsGroupedByContractTable.selectedRows[0].authorizedPersons;
                let selectInactiveContract = this.$refs.authorizationsGroupedByContractTable.selectedRows.some(
                    (e) => e.status.toUpperCase() === "BAIXA" || e.status.toUpperCase() == "BAJA"
                );
                if (selectInactiveContract && authorizedPersons == 0) {
                    Swal.fire({
                        showCloseButton: true,
                        showCancelButton: false,
                        text: this.$i18n.t("ofex.authorizationsView.warning5"),
                        icon: "warning",
                    });
                } else {
                    this.$router.push({ name: viewMappingTypes.AUTHORIZATIONS_BY_CONTRACT_VIEW_MAPPING.name, params: { contractId: contractNumber } });
                }
            } else {
                Swal.fire({
                    showCloseButton: true,
                    showCancelButton: false,
                    text: this.$i18n.t("ofex.authorizationsView.warning1"),
                    icon: "warning",
                });
            }
        },
        addContractAuthorization: function () {
            if (this.$refs.authorizationsGroupedByContractTable.selectedRows.length > 0) {
                let contractNumbers = this.$refs.authorizationsGroupedByContractTable.selectedRows.map((e) => e.contractNumber);
                let selectInactiveContract = this.$refs.authorizationsGroupedByContractTable.selectedRows.some(
                    (e) => e.status.toUpperCase() === "BAIXA" || e.status.toUpperCase() == "BAJA"
                );
                if (!selectInactiveContract) {
                    this.$router.push({
                        name: viewMappingTypes.NEW_AUTHORIZATION_VIEW_MAPPING.name,
                        query: { authorizationType: "CONTRACT", contractNumbers: JSON.stringify(contractNumbers) },
                    });
                } else {
                    Swal.fire({
                        showCloseButton: true,
                        showCancelButton: false,
                        text: this.$i18n.t("ofex.authorizationsView.warning4"),
                        icon: "warning",
                    });
                }
            } else {
                Swal.fire({
                    showCloseButton: true,
                    showCancelButton: false,
                    text: this.$i18n.t("ofex.authorizationsView.warning2"),
                    icon: "warning",
                });
            }
        },
        getRowStyleClass(row) {
            return row.status.toUpperCase() == "BAIXA" || row.status.toUpperCase() == "BAJA" ? "baja-row" : "";
        },
        supplyStatusTranslation: function (supplyStatus) {
            return this.$t("ofex.backendCodes.supplyStatus." + this.supplyStatusBackendCode(supplyStatus));
        },
        supplyStatusBackendCode: function (supplyStatus) {
            return trimBackendCode(supplyStatus);
        },
    },
    computed: {
        ...mapState(["contracts", "authorizationsByContract", "authorizations"]),
        ...mapGetters(["getContractSupplyCompleteAddress", "hasAnyInactiveContract"]),
        tableRows: function () {
            return this.filteredContracts !== null
                ? this.filteredContracts.map(this.mapContractToGroupEntry)
                : this.contracts.map(this.mapContractToGroupEntry);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contractNumber",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$t("ofex.tuContrato.estado"),
                    field: "status",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "supplyAddress",
                },
                {
                    label: this.$t("ofex.authorizationsView.personasAutorizadas"),
                    field: "authorizedPersons",
                    sortFn: function (x, y, col, rowX, rowY) {
                        return rowX.authorizedPersons < rowY.authorizedPersons ? -1 : rowX.authorizedPersons > rowY.authorizedPersons ? 1 : 0;
                    },
                },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_AUTHORIZATIONS_BY_CONTRACT_ACTION, { locale: this.$i18n.locale, triggerLoading: false });
    },
};
</script>

<style lang="scss" scoped>
.baja-row {
    background-color: #f8d5d3 !important;
}
.text-inactive-contract {
    width: 135px;
    background-color: #f8d5d3;
}
</style>
