<script>
import { Line } from "vue-chartjs";
import groupBy from "lodash/groupBy";
import moment from "moment";
import { AB_DATE_FORMAT } from "@/plugins/ab-utils";
import { mapGetters } from "vuex";
import { isBrowser } from "mobile-device-detect";
import { executeAccessibilityFixes } from "@/plugins/accesibility";

export default {
    extends: Line,
    props: {
        userInvoices: {
            type: Array,
            required: true,
        },
        concept: {
            type: String,
            required: true,
        },
        conceptName: {
            type: String,
            required: true,
        },
        selectedYears: {
            type: Array,
            required: true,
        },
        chartType: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
        };
    },
    methods: {
        downloadPdf: function (e, data) {
            if (isBrowser) {
                if (data.length > 0) {
                    let activeElement = data[0]._chart.getElementAtEvent(e);
                    console.debug(activeElement);
                    if (activeElement.length > 0) {
                        let selectedInvoice = this.userInvoices[activeElement[0]._index];
                        console.debug(selectedInvoice);
                        this.$downloadInvoice({
                            invoiceNumber: selectedInvoice.invoiceNumber,
                            invoiceYear: selectedInvoice.invoiceYear,
                            locale: this.$i18n.locale,
                        });
                    }
                }
            }
        },
        buildDatasetFromYearAndInvoice: function (year, invoices) {
            var vueContext = this;
            let dataset = invoices.map(function (i) {
                return { x: moment(i.endDate, AB_DATE_FORMAT, "es").format("MMM").toUpperCase(), y: i[vueContext.concept], invoiceNumber: i.invoiceNumber };
            });
            let pointBackgroundColorsByEstimatedReading = invoices.map((i) => (i.estimatedReading === true ? "#FFFFFF" : "#888481"));
            return {
                data: dataset,
                hoverBackgroundColor: "#95CAEA",
                label: `Factura con consumo real`,
                fill: false,
                lineTension: 0.1,
                backgroundColor: "#233A48",
                borderColor: this.getColorByYear(year), // The main line color
                borderCapStyle: "butt",
                borderDash: [], // try [5, 15] for instance
                borderDashOffset: 0.0,
                borderJoinStyle: "miter",
                pointBorderColor: this.getColorByYear(year),
                pointBackgroundColor: pointBackgroundColorsByEstimatedReading,
                pointBorderWidth: 1,
                pointHoverRadius: 8,
                pointHoverBackgroundColor: pointBackgroundColorsByEstimatedReading,
                pointHoverBorderColor: this.getColorByYear(year),
                pointHoverBorderWidth: 2,
                pointRadius: 4,
                pointHitRadius: 10,
            };
        },
        getColorByYear: function (year) {
            let lastYear = new Date().getFullYear();
            let years = [lastYear, lastYear - 1, lastYear - 2];
            switch (year) {
                case years[0]:
                    return "#233A48";
                case years[1]:
                    return "#477694";
                case years[2]:
                    return "#B85E14";
                default:
                    return "#000";
            }
        },
        renderInvoiceEvolutionChart: function () {
            console.debug("Creando gráfica comparativa de facturas");
            var vueContext = this;

            let invoicesEvolutionBarOptions = {
                responsive: true,
                maintainAspectRatio: false,
                // onClick: this.downloadPdf,
                legend: {
                    display: false,
                    position: "bottom",
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: "Importe (€)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            offset: true,
                            distribution: "series",
                            scaleLabel: {
                                display: true,
                            },
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 0,
                                minRotation: 90,
                                mirror: true,
                            },
                        },
                    ],
                },
                tooltips: {
                    backgroundColor: "#FFF",
                    borderColor: "#417290",
                    borderWidth: 1,
                    titleFontColor: "#417290",
                    callbacks: {
                        label: function () {
                            return "";
                        },
                        title: function (tooltipItem) {
                            let chartEntry = vueContext.selectedYearsFullDataset[tooltipItem[0].datasetIndex].data[tooltipItem[0].index];
                            let invoice = vueContext.getInvoiceByInvoiceNumber(chartEntry.invoiceNumber);
                            return ` Fecha: ${invoice.endDate} \n ${vueContext.conceptName}: ${vueContext.$formatNumber(invoice[vueContext.concept])} €`;
                        },
                    },
                },
            };

            let invoicesEvolutionBarData = {
                datasets: this.selectedYearsFullDataset,
                labels: this.monthYearsLabels,
            };

            this.renderChart(invoicesEvolutionBarData, invoicesEvolutionBarOptions);
        },
    },
    computed: {
        ...mapGetters(["getInvoiceByInvoiceNumber"]),
        invoiceReverseDataset: function () {
            return this.userInvoices.slice().reverse();
        },
        groupedByYearDataset: function () {
            return groupBy(this.userInvoices, (i) => moment(i.endDate, AB_DATE_FORMAT, "es").year());
        },
        selectedYearsFullDataset: function () {
            return this.selectedYears
                .filter((year) => typeof this.groupedByYearDataset[year] !== "undefined")
                .map((year) => this.buildDatasetFromYearAndInvoice(year, this.groupedByYearDataset[year]));
        },
        monthYearsLabels: function () {
            // IMPORTANTE no se pueden traducir al catalan las etiquetas porque hace que se descuadren los valores https://zenerab.atlassian.net/browse/UATOFEX-759
            return ["ENE.", "FEB.", "MAR.", "ABR.", "MAY.", "JUN.", "JUL.", "AGO.", "SEP.", "OCT.", "NOV.", "DIC."];
        },
    },
    watch: {
        userInvoices: function () {
            console.debug("Cambiando datos de facturas en gráfico");
            // this._chart.destroy();
            this.renderInvoiceEvolutionChart();
        },
        concept: function () {
            console.debug("Cambiando conceptos en gráfico");
            // this._chart.destroy();
            this.renderInvoiceEvolutionChart();
        },
        selectedYears: function () {
            console.debug("Cambiando años en gráfico");
            // this._chart.destroy();
            this.renderInvoiceEvolutionChart();
        },
    },
    mounted() {
        this.renderInvoiceEvolutionChart();
        executeAccessibilityFixes();
    },
};
</script>

<style lang="scss"></style>
