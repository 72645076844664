<template>
    <div id="info-block">
        <div id="screen-title" class="">
            <span>{{ $t("ofex.nativeAppInfoOfficesView.offices") }}</span>
        </div>
        <div id="version-block">
            <div id="version-title" class="title">
                <span>{{ $t("ofex.nativeAppInfoOfficesView.howToArrive") }}</span>
            </div>
            <div id="version-info" class="content">
                <div id="izq">
                    <a href="https://goo.gl/maps/rqgBGWE8umSdD3nH8"><font-awesome-icon class="" icon="location-dot" /><span>Barcelona</span></a>
                    <a href="https://goo.gl/maps/F7xeTYDPK47JXHEbA"><font-awesome-icon class="" icon="location-dot" /><span>Besos</span></a>
                </div>
                <div id="der">
                    <a href="https://goo.gl/maps/BZNgxjvrq6zuexjz6"><font-awesome-icon class="" icon="location-dot" /><span>Llobregat Norte</span></a>
                    <a href="https://goo.gl/maps/U7uim3CyK2dBFr359"><font-awesome-icon class="" icon="location-dot" /><span>Llobregat Sur</span></a>
                </div>
            </div>
        </div>
        <div id="legal-terms-block">
            <div id="legal-terms-icon" class="title">
                <span>{{ $t("ofex.nativeAppInfoOfficesView.timeSchedule") }}</span>
            </div>
            <div id="legal-terms-info" class="content">
                <span>{{ $t("ofex.nativeAppInfoOfficesView.time1") }}</span>
                <span>{{ $t("ofex.nativeAppInfoOfficesView.time2") }}</span>
                <span>
                    <u>{{ $t("ofex.nativeAppInfoOfficesView.time3Bold") }}</u> {{ $t("ofex.nativeAppInfoOfficesView.time3") }}
                </span>
            </div>
        </div>
        <div id="interest-numbers-block">
            <div id="interest-numbers-icon" class="title">
                <span>{{ $t("ofex.nativeAppInfoOfficesView.languages") }}</span>
            </div>
            <div id="interest-numbers-info" class="content">
                <span>{{ $t("ofex.nativeAppInfoOfficesView.lang1") }}</span>
                <span>{{ $t("ofex.nativeAppInfoOfficesView.lang2") }}</span>
                <span>{{ $t("ofex.nativeAppInfoOfficesView.lang3") }}</span>
                <span>{{ $t("ofex.nativeAppInfoOfficesView.lang4") }}</span>
            </div>
        </div>
        <div id="offices-block">
            <div id="offices-icon" class="title">
                <a href="https://citaprevia.aiguesdebarcelona.cat/" class="btn">
                    <span>{{ $t("ofex.nativeAppInfoOfficesView.citaPrevia") }}</span>
                </a>
            </div>
        </div>
        <div id="go-back-info">
            <router-link to="/native_app_info" class="href-password">
                <font-awesome-icon class="" icon="arrow-left" /><span>{{ $t("ofex.nativeAppInfoOfficesView.back") }}</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import { App } from "@capacitor/app";
export default {
    name: "NativeAppInfo",
    data() {
        return {
            appInfo: null,
        };
    },
    methods: {},
    mounted() {
        App.getInfo().then((value) => (this.appInfo = value));
    },
};
</script>

<style lang="scss" scoped>
#info-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px;
    border: 1px solid #284f6a;
    border-radius: 10px;
    color: #284f6a;

    #screen-title {
        height: 50px;
        text-align: center;
        background-color: #284f6a;
        color: white;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        display: flex;
        justify-content: center;

        span {
            align-self: center;
        }
    }
    #go-back-info {
        height: 50px;
        text-align: center;
        background-color: #d8dce1;
        color: #284f6a;
        border-bottom-left-radius: 9px;
        border-bottom-right-radius: 9px;
        display: flex;
        justify-content: center;

        a {
            align-self: center;
            display: flex;
            gap: 10px;
            justify-content: center;

            svg {
                width: 25px;
                height: 25px;
            }

            span {
                line-height: 25px;
            }
        }
    }

    #version-block,
    #legal-terms-block,
    #interest-numbers-block {
        border-bottom: 1px solid #dde2e4;
    }

    #version-block,
    #legal-terms-block,
    #interest-numbers-block,
    #offices-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 15px;

        .title {
            display: flex;
            align-items: center;
            gap: 15px;

            img {
                width: 45px;
            }

            span {
                font-size: 1.2rem;
                font-weight: 700;
            }

            a {
                display: flex;
                gap: 15px;
                align-items: center;
                color: #284f6a;

                svg {
                    width: 14px;
                    height: 14px;
                    background-color: #284f6a;
                    color: white;
                    border-radius: 100%;
                    padding: 2px;
                }

                span {
                    font-weight: 600;
                    line-height: normal;
                }
            }

            .btn {
                background-color: #284f6a;
                color: white;
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 8px;

                span {
                    font-size: 1rem;
                }
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 0 15px;
            font-size: 0.9rem;
            font-weight: 600;

            &#version-info {
                flex-direction: row;
            }

            span {
                line-height: 1.2rem;
                font-size: 0.85rem;
                font-weight: 700;
            }

            ul {
                margin-bottom: 0;
                display: flex;
                flex-direction: column;
                gap: 8px;

                li {
                    padding: 0 15px;

                    a {
                        display: flex;
                        gap: 15px;
                        align-items: center;
                        color: #284f6a;

                        svg {
                            width: 14px;
                            height: 14px;
                            background-color: #284f6a;
                            color: white;
                            border-radius: 100%;
                            padding: 2px;
                        }

                        span {
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                }
            }

            #izq,
            #der {
                display: flex;
                flex-basis: 50%;
                flex-direction: column;
                gap: 12px;

                a {
                    display: flex;
                    gap: 8px;
                    color: #284f6a;
                    align-content: center;

                    svg {
                        width: 22px;
                        height: 22px;
                    }

                    span {
                        line-height: 22px;
                    }
                }
            }
        }
    }
}
</style>
