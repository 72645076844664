<template>
    <b-container class="p-invoice-payment" v-if="contractsInvoicesPending">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.invoicePaymentByContractView.title')" />

                <div v-if="contractsInvoicesPending.length > 0 && totalUnpaidAmount">
                    <p class="number-red">
                        <span class="subtitle">{{ $t("ofex.invoicePaymentByContractView.totalApagar") }}</span>
                        <span class="nowrap"> {{ $formatNumber(totalUnpaidAmount) }} €</span>
                    </p>
                    <!-- tabla de contracto con facturas pendientes-->
                    <ab-pending-invoices-by-contract
                        ref="pendingTable"
                        v-for="(contract, index) in contractsInvoicesPending"
                        :key="contract.contractDetail.contractNumber"
                        :collapse-name="index"
                        :contract-number="contract.contractDetail.contractNumber"
                        :class="'v-payment'"
                    >
                    </ab-pending-invoices-by-contract>
                    <!--buttons-->
                    <div class="d-md-flex justify-content-end mt-4 mb-4">
                        <div class="button-invoices">
                            <ab-button-ofex
                                aria-label="listadoFacturasPDF"
                                :font-awesome-icon="'print'"
                                :text-button="$t('ofex.invoicePaymentByContractView.listadoFacturasPDF')"
                                :click-event="generatePdfInvoicePaymentList"
                            ></ab-button-ofex>
                        </div>
                        <div class="button-invoices ml-md-4 mt-2 mt-md-0">
                            <ab-button-ofex
                                aria-label="listadoFacturasExcel"
                                :font-awesome-icon="'download'"
                                :text-button="$t('ofex.invoicePaymentByContractView.listadoFacturasExcel')"
                                :click-event="generateExcelInvoicePaymentList"
                            ></ab-button-ofex>
                        </div>
                    </div>
                </div>
                <ab-without-data :text="$t('ofex.withoutData.pendingInvoices')" v-else />

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-left="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-left="$t('ofex.relatedContents.facturaDigital.image')"
                    target-left="_self"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.quePagasFactura.name')"
                    :href-right="$t('ofex.relatedContents.quePagasFactura.url')"
                    :image-right="$t('ofex.relatedContents.quePagasFactura.image')"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import AbMainTitle from "../modules/AbMainTitle.vue";
import AbYourInterest from "../modules/AbYourInterest.vue";
import AbPendingInvoicesByContract from "../modules/invoices/AbPendingInvoicesByContract.vue";
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import InvoicePaymentByContractPdfGenerator from "@/generators/pdf/InvoicePaymentByContractPdfGenerator";
import InvoicePaymentByContractXlsxGenerator from "@/generators/xlsx/InvoicePaymentByContractXlsxGenerator";

export default {
    name: "InvoicePaymentByContractView",
    components: {
        AbMainTitle,
        AbYourInterest,
        AbPendingInvoicesByContract,
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "contracts", "contractDetail", "unpaidInvoices"]),
        ...mapGetters(["totalUnpaidAmount"]),
        contractsInvoicesPending: function () {
            return this.contracts.filter((contract) => contract.contractDetail.invoicesPending === true);
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, activeContract: this.$route.params.contractId });
    },
    methods: {
        generateExcelInvoicePaymentList: function () {
            let xlsxGenerator = new InvoicePaymentByContractXlsxGenerator(`PagosContrato.xlsx`, this.$refs.pendingTable[0].tableRows, this.$i18n);
            console.log(xlsxGenerator);
        },
        generatePdfInvoicePaymentList: function () {
            let pdfGenerator = new InvoicePaymentByContractPdfGenerator(this.$i18n, this.userId, this.clientId);
            pdfGenerator.generatePdf(this.unpaidInvoices);
            pdfGenerator.downloadPdf();
        },
    },
};
</script>
<style lang="scss" scoped>
.p-invoice-payment {
    .mb-space-table {
        margin-bottom: 60px;
    }
    .number-red {
        @include font-size(20);
        @include line-height(26);
        color: $red-danger;
        font-weight: $font-primary-bold;
    }
    .button-invoices {
        width: 289px;
    }
    @include respond-below(md) {
        .button-invoices {
            width: 100%;
        }
    }
}
.vgt-responsive {
    min-height: auto !important;
}
</style>
<style lang="scss">
.v-payment {
    .vgt-responsive {
        min-height: auto !important;
    }
}
</style>
