<template>
    <div class="m-select-your-contract" :class="fatherClass">
        <ab-autocompletable-select
            v-model="modelValue"
            name="selectInvoice"
            :placeholder="$t('common.seleccionar')"
            :data-list="invoicesDataList"
            data-key="invoiceNumber"
            data-label="text"
            :editable="editable"
            :pending-ofex-slot="true"
            :label="label"
            :allow-empty="allowEmpty"
            :validation="{ required: required }"
            :input-alias="$t('ofex.forms.reclamations.tuContratoAlias')"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import * as mutationTypes from "@/store/mutation-types";

export default {
    name: "AbSelectInvoice",
    components: {},
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        vuexModel: {
            type: Boolean,
            default: true,
        },
        customDatalist: {
            type: Array,
            required: false,
        },
        allowEmpty: {
            type: Boolean,
            default: false,
        },
        fatherClass: {
            required: false,
            type: String,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        label: {
            required: false,
            default: function () {
                return this.$i18n.tc("ofex.home.tuFactura");
            },
        },
        required: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data: function () {
        return {
            model: {
                autoCompletableSelect: [],
            },
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.vuexModel === true
                    ? this.invoicesDataList.find((i) => i.invoiceNumber === this.selectedInvoiceNumber)
                    : this.invoicesDataList.find((i) => i.invoiceNumber == this.$attrs["inputValue"]);
            },
            set: function (newValue) {
                if (this.vuexModel === true && typeof this.$store !== "undefined" && this.$store) {
                    this.$store.commit(mutationTypes.CHANGE_ACTIVE_INVOICE_MUTATION, { invoiceNumber: newValue.invoiceNumber });
                } else {
                    newValue != null ? this.$emit("change", newValue.invoiceNumber) : this.$emit("change", null);
                }
            },
        },
        invoicesDataList: function () {
            return this.vuexModel === true ? this.invoiceTypeSelect : this.customDatalist;
        },
        ...mapGetters(["invoiceTypeSelect"]),
        ...mapState(["selectedInvoiceNumber"]),
    },
    methods: {},
};
</script>

<style lang="scss"></style>
