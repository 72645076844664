<template>
    <div class="m-service-incidents box">
        <table>
            <colgroup>
                <col class="colgroup-one" />
                <col />
            </colgroup>
            <thead>
                <tr>
                    <th scope="col">{{ $t("ofex.home.fechaAviso") }}</th>
                    <th scope="col">{{ $t("ofex.home.asunto") }}</th>
                </tr>
            </thead>
            <tbody>
                <!--                Bloque especial por si hay algún contrato afectado por la sequía-->
                <tr class="row-one" v-if="droughtConfiguration?.enabledAll && hasAnyDroughtAffectedContract">
                    <td class="col-one" :data-label="$t('ofex.home.fechaAviso')">{{ droughtConfiguration.notificationDate }}</td>
                    <td class="col-two" :data-label="$t('ofex.home.asunto')">
                        <router-link class="link-item" :to="{ name: viewMappingTypes.AFFECTATIONS_VIEW.name }"
                            >{{ $t("ofex.sequia.incidenciasTuServicioTexto") }}
                            <font-awesome-icon class="icon" icon="arrow-right"></font-awesome-icon>
                        </router-link>
                    </td>
                </tr>

                <tr class="row-one" :key="item.id" v-for="item in unreadNotifications">
                    <td class="col-one" :data-label="$t('ofex.home.fechaAviso')">{{ formatDateTime(item.date) }}</td>
                    <td class="col-two" :data-label="$t('ofex.home.asunto')">
                        <router-link class="link-item" :to="`/lista_avisos/${item.id}`"
                            >{{ item.summary }}
                            <font-awesome-icon class="icon" icon="arrow-right"></font-awesome-icon>
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="show-more">
            <router-link class="link-item" :to="{ name: viewMappingTypes.NOTIFICATIONS_LIST_MAPPING.name }"
                >{{ $t("ofex.home.verMas") }}<font-awesome-icon class="icon" icon="arrow-right"></font-awesome-icon
            ></router-link>
        </p>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { AB_DATETIME_FORMAT, AB_DATETIME_WITHOUT_SECONDS_FORMAT } from "@/plugins/ab-utils";
import * as viewMappingTypes from "../router/view-mapping-types";
export default {
    name: "AbServiceIncidents",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
        };
    },
    computed: {
        ...mapState(["unreadNotifications", "droughtConfiguration"]),
        ...mapGetters(["hasAnyDroughtAffectedContract"]),
    },
    methods: {
        formatDateTime: function (date) {
            return moment(date, AB_DATETIME_FORMAT, "es").format(AB_DATETIME_WITHOUT_SECONDS_FORMAT);
        },
    },
};
</script>

<style lang="scss" scoped>
.m-service-incidents {
    @include respond-below(sm) {
        border-top: 1px solid $border-table;
    }
    @include respond-above(sm) {
        .colgroup-one {
            width: 24%;
        }
    }
    @include respond-above(lg) {
        .colgroup-one {
            width: 21%;
        }
    }
    .show-more {
        text-align: right;
        padding-right: 8px;
        margin-top: 20px;
        margin-bottom: 0;

        @include respond-above(sm) {
            margin-top: 0;
        }
        .link-item {
            @include font-size(12);
            @include line-height(14);
            color: $blue-lighter;
            font-weight: $font-primary-bold;

            .icon {
                margin-left: 5px;
            }
        }
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }
    thead {
        border-bottom: 1px solid $border-table;

        @include respond-below(sm) {
            display: none;
        }
        tr {
            th {
                color: $blue-lighter;
                font-weight: $font-primary-bold;
                @include font-size(12);
                @include line-height(14);
                padding: 0 8px 5px 8px;
            }
        }
    }
    tbody {
        tr {
            border-bottom: 1px solid $border-table;
            height: 50px;

            @include respond-below(sm) {
                position: relative;
            }

            td {
                vertical-align: middle;
                padding: 0 8px;

                @include respond-below(sm) {
                    display: flex;
                    padding-top: 36px;
                    width: 100%;
                    height: 60px;
                    position: relative;

                    &:last-child {
                        padding-top: 25px;
                    }

                    &::before {
                        content: attr(data-label);
                        position: absolute;
                        margin-top: -18px;
                        color: $blue-lightest;
                        font-weight: $font-primary-bold;
                        @include font-size(12);
                        @include line-height(14);
                    }
                }
            }
            td.col-one {
                color: $color-form-text;
                font-weight: $font-primary-medium;
                @include font-size(14);
                @include line-height(16);
            }
            td.col-two {
                .link-item {
                    color: $blue-lighter;
                    font-weight: $font-primary-semiBold;
                    text-decoration: underline;
                    @include font-size(14);
                    @include line-height(16);
                }
                .icon {
                    float: right;
                    margin-top: 6px;
                    @include font-size(12);
                    @include line-height(14);

                    @include respond-below(sm) {
                        display: none;
                    }
                }
            }
        }
        tr.row-last {
            @include respond-below(sm) {
                td {
                    padding-top: 56px;
                    height: 70px;
                }
                td.col-two {
                    padding-top: 40px;
                }
            }
        }
    }
}
</style>
