<template>
    <div>
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-container class="p-invoice-payments" v-if="invoice && contract">
            <b-row>
                <ab-left-nav-bar />
                <b-col cols="12" md="12" lg="9">
                    <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                    <!--Main title -->
                    <ab-main-title :main-text="$t('ofex.pagarFactura.title')" />
                    <ab-return />
                    <!--Detalle de la factura-->
                    <div class="info-invoice">
                        <p class="text-invoice">
                            {{ $t("ofex.pagarFactura.factura") }}
                            <span class="detail-invoice ml-1">
                                {{ invoice.invoiceYear }}{{ invoice.invoiceNumber
                                }}<span class="detail-invoice ml-1">{{ changeUpperCase(monthFullName) }} {{ yearEndDate }}</span></span
                            >
                        </p>
                        <p class="address-invoice">
                            {{ contract.supplyAddress.streetType }} {{ contract.supplyAddress.streetName }}, {{ contract.supplyAddress.number }}
                            {{ contract.supplyAddress.otherAddressData }} - {{ contract.supplyAddress.municipality }}
                        </p>
                        <div class="contract-container">
                            <span class="text-contracte"
                                >{{ $t("ofex.pagarFactura.contrato")
                                }}<span class="number-contracte ml-1">
                                    {{ contract.contractDetail.contractNumber }}
                                </span></span
                            >
                            <div class="button-download">
                                <ab-button-ofex
                                    :font-awesome-icon="'download'"
                                    :text-button="$t('ofex.pagarFactura.descargar')"
                                    :click-event="downloadInvoice"
                                ></ab-button-ofex>
                            </div>
                        </div>
                    </div>
                    <!--box grey-->
                    <ab-invoice-grey-box v-if="invoice" :invoice="invoice" />
                    <div class="accordion-payments">
                        <ab-payment-methods-accordion
                            :multiple-payment="false"
                            :contract-number="contract.contractDetail.contractNumber"
                            :invoices="[`${invoice.invoiceYear}${invoice.invoiceNumber}`]"
                            :invoice-amount="invoice.amount"
                            :invoice-barcode="invoice.barcode"
                            :initial-load-barcode="true"
                        />

                        <ab-your-interest
                            :name-left="$t('ofex.relatedContents.facturaDigital.name')"
                            :href-left="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                            :image-left="$t('ofex.relatedContents.facturaDigital.image')"
                            target-left="_self"
                            :name-center="$t('ofex.relatedContents.bonifications.name')"
                            :href-center="$t('ofex.relatedContents.bonifications.url')"
                            :image-center="$t('ofex.relatedContents.bonifications.image')"
                            :name-right="$t('ofex.relatedContents.quePagasFactura.name')"
                            :href-right="$t('ofex.relatedContents.quePagasFactura.url')"
                            :image-right="$t('ofex.relatedContents.quePagasFactura.image')"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import AbMainTitle from "../modules/AbMainTitle.vue";
import AbYourInterest from "../modules/AbYourInterest.vue";
import AbInvoiceGreyBox from "../modules/invoices/AbInvoiceGreyBox.vue";
import AbPaymentMethodsAccordion from "../modules/invoices/AbPaymentMethodsAccordion";
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { AB_DATE_FORMAT } from "@/plugins/ab-utils";
import * as actionTypes from "@/store/action-types";
import AbReturn from "../components/AbReturn.vue";

export default {
    name: "InvoicePaymentView",
    components: {
        AbMainTitle,
        AbYourInterest,
        AbInvoiceGreyBox,
        AbPaymentMethodsAccordion,
        AbReturn,
    },
    computed: {
        monthFullName: function () {
            return moment(this.invoice.endDate, AB_DATE_FORMAT, this.$i18n.locale).format("MMMM").toUpperCase();
        },
        yearEndDate: function () {
            return moment(this.invoice.endDate, AB_DATE_FORMAT, this.$i18n.locale).year();
        },
        ...mapState(["unpaidInvoices", "contracts", "isLoading", "clientData"]),
        ...mapGetters(["getUnpaidInvoiceByInvoiceNumber", "getContractByContractNumber"]),
        contract: function () {
            return this.getContractByContractNumber(this.$route.params.contractId);
        },
        invoice: function () {
            return this.unpaidInvoices && this.getUnpaidInvoiceByInvoiceNumber(this.$route.params.invoiceId);
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, activeContract: this.$route.params.contractId });
        this.$store.dispatch(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, { contractNumber: this.$route.params.contractId, locale: this.$root.$i18n.locale });
    },
    methods: {
        downloadInvoice: function () {
            this.$downloadInvoice({
                invoiceNumber: this.invoice.invoiceNumber,
                invoiceYear: this.invoice.invoiceYear,
                locale: this.$i18n.locale,
            });
        },
        changeUpperCase: function (monthFullName) {
            let month = monthFullName.toLowerCase();
            let firstChar = month.substring(0, 1);
            let firstLetter = firstChar.toUpperCase();
            let tail = month.substring(1);
            let monthComplete = firstLetter + tail;
            return monthComplete;
        },
    },
};
</script>
<style lang="scss" scoped>
.p-invoice-payments {
    .info-invoice {
        margin-bottom: 20px;
        padding-bottom: 42px;
        border-bottom: 1px solid $border-table;
    }
    .info-contract {
        @include respond-below(sm) {
            margin-bottom: 20px;
        }
    }
    .contract-container {
        display: flex;
        justify-content: space-between;

        @include respond-below(sm) {
            flex-wrap: wrap;

            .text-contracte {
                margin-bottom: 15px;
            }
        }
    }
    .text-invoice {
        @include font-size(18);
        @include line-height(21);
        color: $link-primary;
        font-weight: $font-primary-bold;
    }
    .detail-invoice {
        @include font-size(18);
        @include line-height(21);
        color: $color-secondary;
        font-weight: $font-primary-bold;
    }
    .address-invoice {
        @include font-size(14);
        @include line-height(16);
        color: $color-secondary;
        font-weight: $font-primary-semiBold;
    }
    .text-contracte {
        @include font-size(14);
        @include line-height(16);
        color: $color-secondary;
        font-weight: $font-primary-bold;
    }
    .number-contracte {
        @include font-size(14);
        @include line-height(16);
        color: $color-secondary;
        font-weight: $font-primary-medium;
    }
}
.pointer {
    cursor: pointer;
}
</style>
