"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import { trimBackendCode } from "@/plugins/ab-utils";
import moment from "moment";

const ACTIVITY_CODE = "CU";
export default class ContractUnsubscribePdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId, selectedContract) {
        super("BajaSuministro.pdf", ACTIVITY_CODE, i18n, userId, clientId, selectedContract);
    }
    generatePdf(formModel, contractDetail, selectedContract, clientData, userData) {
        const POINTS = ": ";
        const SPACE = "  ";
        moment.locale("es");
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.bajaContratoDetalle.bajaContrato"), "");
        this.generatePdfSection(this.i18n.tc("ofex.bajaContratoDetalle.datosContrato"));
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.numContrato") + selectedContract);

        let otherAddressData = formModel.shippingAddress.otherAddressData;
        if (otherAddressData == null && otherAddressData == "") {
            otherAddressData = contractDetail.supplyAddress.floor;
        }

        this.generateLineText(
            this.i18n.tc("ofex.modificarDatosContrato.direccionSuministro") +
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(contractDetail.supplyAddress.streetType)) +
                SPACE +
                contractDetail.supplyAddress.streetName +
                SPACE +
                contractDetail.supplyAddress.number +
                SPACE +
                otherAddressData +
                SPACE +
                contractDetail.supplyAddress.municipality +
                SPACE
        );
        this.generateLineText(
            this.i18n.tc("ofex.bajaContratoDetalle.labelFechaRescision") + POINTS + moment(formModel.datepicker, "MMMM Do YYYY, h:mm:ss a").format("L")
        );
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.lectura") + POINTS + formModel.readValue);

        if (formModel.attachedFile.file.name) {
            this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.ficheroAdjunto") + SPACE + formModel.attachedFile.file.name);
        }

        this.generatePdfSection(this.i18n.tc("ofex.bajaContratoDetalle.identificacionClienteTitular"), "");
        this.generateLineText(
            this.i18n.tc("ofex.detalleContrato.nombreApellidos") + clientData.name + SPACE + clientData.firstLastName + SPACE + clientData.secondLastName
        );
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.tipoDocumento") + clientData.documentType);
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.nDocumento") + clientData.documentNumber);
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.tlfPrincipal") + clientData.mainPhone);
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.email") + clientData.email);

        this.generatePdfSection(this.i18n.tc("ofex.bajaContratoDetalle.identificacionSolicitanteRescision"));
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.nombre") + POINTS + userData.firstName);
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.primerApellido") + POINTS + userData.firstLastName);
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.segundoApellido") + POINTS + userData.secondLastName);
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.tipoDocumento") + userData.documentType);
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.nDocumento") + userData.documentNumber);
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.tlfPrincipal") + userData.mainPhone);
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.email") + userData.email);

        this.generatePdfSection(this.i18n.tc("ofex.bajaContratoDetalle.identificacionTitularCuenta"));
        this.generateLineText(
            this.i18n.tc("ofex.bajaContratoDetalle.checkBoxDomiciliacion") + POINTS + `${formModel.bankDetailsDomiciliation == true ? "SI" : "NO"}`
        );
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.nombre") + POINTS + formModel.headlineBankAccount.name);
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.primerApellido") + POINTS + formModel.headlineBankAccount.lastName);
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.segundoApellido") + POINTS + formModel.headlineBankAccount.secondLastName);
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.tipoDocumento") + POINTS + formModel.headlineBankAccount.doc.type);
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.nDocumento") + POINTS + formModel.headlineBankAccount.doc.number);
        this.generatePdfSection(this.i18n.tc("ofex.bajaContratoDetalle.identificacionCuenta"));
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.pais") + POINTS + formModel.dataBank.country);
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.iban") + POINTS + formModel.dataBank.iban);
        this.generatePdfSection(this.i18n.tc("ofex.bajaContratoDetalle.comprobanteRescision"));
        this.generateLineText(this.i18n.tc("ofex.bajaContratoDetalle.mismaDireccion") + POINTS + `${formModel.sameDirection == true ? "SI" : "NO"}`);

        if (!formModel.sameDirection) {
            let otherAddressData = "";
            otherAddressData =
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(formModel.shippingAddress.carrerer.streetType.code)) +
                SPACE +
                formModel.shippingAddress.carrerer.streetName +
                SPACE +
                formModel.shippingAddress.carrerer.number;

            if (formModel.shippingAddress.carrerer.piso) {
                otherAddressData = otherAddressData + SPACE + formModel.shippingAddress.carrerer.piso;
            }

            if (formModel.shippingAddress.carrerer.puerta) {
                otherAddressData = otherAddressData + SPACE + formModel.shippingAddress.carrerer.puerta;
            }

            if (formModel.shippingAddress.carrerer.escalera) {
                otherAddressData = otherAddressData + SPACE + formModel.shippingAddress.carrerer.escalera;
            }
            otherAddressData = otherAddressData + SPACE + formModel.shippingAddress.carrerer.cp;

            this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.DireccionCorrespondencia") + POINTS + otherAddressData + SPACE);
        }

        this.generateLineText(this.i18n.tc("carrerer.labels.provincia") + POINTS + formModel.shippingAddress.carrerer.province.name);
        this.generateLineText(this.i18n.tc("carrerer.labels.municipio") + POINTS + formModel.shippingAddress.carrerer.municipality.name);
        return this.pdf;
    }
}
