<template>
    <div class="container p-email-recovery" v-if="!finished">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>

        <div class="row">
            <div class="col-12">
                <ab-main-title :main-text="$t('ofex.emailRecovery.title')"></ab-main-title>
                <ab-return />
                <p class="subtitle" v-html="$t('ofex.emailRecovery.subTitle')"></p>

                <ValidationObserver ref="emailRecoveryForm" v-slot="{ reset, invalid }">
                    <ab-error-header
                        v-if="$refs.reclamationForm"
                        class="espacio-smalled"
                        :include-rest-error="true"
                        :error-list="validationErrors"
                    ></ab-error-header>

                    <p class="campos-obligados">{{ $t("ofex.emailRecovery.camposObligatorios") }}</p>

                    <ab-section-header class="section-head" :section-name="$t('ofex.emailRecovery.datosIdentificacion')"></ab-section-header>

                    <p class="reset-mail espacio-inf">{{ $t("ofex.emailRecovery.resetMail") }}</p>

                    <ab-id-type-number
                        v-if="isCompany"
                        v-model="form.companyIdentification.doc"
                        validation-type-name="company-identification-type"
                        :allow-cif="true"
                        :number-input-alias="$t('alias.document.cif')"
                        :allow-nif-nie="false"
                        validation-number-name="company-identification-number"
                        :label-number-document="$t('ofex.passwordRecovery.cif')"
                    />

                    <ab-id-type-number
                        v-model="form.userIdentification.doc"
                        validation-type-name="titular-contrato-tipo-doc"
                        :allow-cif="false"
                        :number-input-alias="$t('alias.document.nieEmpleado')"
                        validation-number-name="titular-contrato-numero-doc"
                        :label-number-document="$t('ofex.passwordRecovery.nieEmpleado')"
                    />

                    <div class="row mb-3">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="given-name"
                                v-model="form.name"
                                name="user-data-name"
                                :validation="{ required: true }"
                                data-key="key"
                                data-label="text"
                                :label="$t('ofex.emailRecovery.nombre')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="family-name"
                                v-model="form.firstLastName"
                                name="user-data-surname"
                                :validation="{ required: true }"
                                data-key="key"
                                data-label="text"
                                :label="$t('ofex.emailRecovery.primerApellido')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="family-name"
                                v-model="form.secondLastName"
                                name="user-data-second-surname"
                                data-key="key"
                                data-label="text"
                                :label="$t('ofex.emailRecovery.segundoApellido')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="tel-national"
                                v-model="form.phone"
                                name="mobile-phone-owner"
                                v-bind:validation="{ required: true, spanishMobile: true }"
                                v-bind:label="$t('ofex.emailRecovery.telefono')"
                            ></ab-input>
                        </div>
                    </div>

                    <div class="row mb-4">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                v-model="form.invoiceNumber"
                                name="numero-factura"
                                :input-alias="$t('alias.invoice.numeroFactura')"
                                :placeholder="$t('invoiceVerification.numberPlaceholder')"
                                :popover="$t('ofex.emailRecovery.popoverNumeroFactura')"
                                data-key="key"
                                data-label="text"
                                :validation="{ required: true, alpha_num: true, max: 20 }"
                                :label="$t('ofex.emailRecovery.numeroFactura')"
                            />
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                v-model="form.invoiceAmount"
                                name="importe-factura"
                                :input-alias="$t('alias.invoice.importeFactura')"
                                :placeholder="$t('invoiceVerification.amountPlaceholder')"
                                :popover="$t('ofex.emailRecovery.textDetailRead')"
                                data-key="key"
                                data-label="text"
                                :validation="{ required: true, regex: /^\d{1,3}(\.?\d{3})*(,\d{1,2})?$/ }"
                                :label="$t('ofex.emailRecovery.importeFactura')"
                            ></ab-input>
                        </div>
                    </div>
                    <p class="reset-mail">{{ $t("ofex.emailRecovery.adjuntoDni") }}</p>
                    <div class="row mb-4">
                        <!--boton adjuntar archivo-->
                        <div class="col-12 col-lg-3" :class="{ 'extra-margin-bottom': $isNativeApp() }">
                            <ab-upload
                                :label="$t('ofex.emailRecovery.ficheroAdjunto')"
                                :input-alias="$t('upload.ficheroAdjunto')"
                                name="attached-file"
                                :required="true"
                                v-model="attachedFile"
                                :actividad="activityId"
                                :father-class="'v-upload-recovery-email'"
                                :api-base-url="contentManagerBaseUrl"
                                :popover="$t('ofex.emailRecovery.detailArchivoAdjunto')"
                                :user="form.userIdentification.doc.number"
                                :client="form.companyIdentification.doc.number"
                                :use-public-subscription="true"
                            ></ab-upload>
                        </div>

                        <!-- <p class="text-detail-small mt-5" v-html="$t('ofex.bajaContratoDetalle.textDetailRead')"></p> -->

                        <!--boton adjuntar archivo-->
                        <div class="col-12 col-lg-3">
                            <ab-upload
                                :input-alias="$t('upload.ficheroAdjunto')"
                                name="attached-file2"
                                v-model="attachedFile2"
                                :actividad="activityId"
                                :father-class="'v-upload-recovery-email'"
                                :api-base-url="contentManagerBaseUrl"
                                :popover="$t('ofex.emailRecovery.detailArchivoAdjunto')"
                                :disabled="attachedFile.file.name === ''"
                                :user="form.userIdentification.doc.number"
                                :client="form.companyIdentification.doc.number"
                                :use-public-subscription="true"
                            ></ab-upload>
                        </div>
                        <div class="box-text-button mt-2">
                            <!--   <p class="text-detail-small">{{ $t("ofex.bajaContratoDetalle.detailArchivoAdjunto") }}</p> -->
                        </div>
                    </div>
                    <ab-section-header class="section-head" :section-name="$t('ofex.emailRecovery.datosIdentificacion')"></ab-section-header>
                    <p class="reset-mail espacio-inf">{{ $t("ofex.emailRecovery.nuevaDirUserOrfex") }}</p>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="email"
                                v-model="form.email"
                                name="empresa-email"
                                ref="nuevotitular-empresa-email"
                                :input-alias="$t('alias.email')"
                                :placeholder="$t('ofex.passwordRecovery.placeholder.email')"
                                type="email"
                                :validation-delay="200"
                                :validation="{
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                }"
                                :label="$t('ofex.passwordRecovery.labelEmail')"
                            >
                            </ab-input>
                        </div>

                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="email"
                                v-model="form.reEmail"
                                name="empresa-reemail"
                                :input-alias="$t('alias.reEmail')"
                                :placeholder="$t('ofex.passwordRecovery.placeholder.email')"
                                type="email"
                                :validation-delay="200"
                                :validation="{
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                    is: form.email,
                                }"
                                :label="$t('ofex.passwordRecovery.labelReEmail')"
                                :pasteable="false"
                            />
                        </div>
                    </div>

                    <div class="row espacio-inf">
                        <div class="col-12">
                            <div styler>
                                <!-- Política de privacidad -->
                                <section>
                                    <div class="pt-2 mb-4">
                                        <ab-section-header
                                            class="section-head"
                                            :section-name="$t('ofex.forms.reclamations.politicaPrivacidad')"
                                        ></ab-section-header>
                                    </div>
                                    <ab-legal-section
                                        :show-legal="false"
                                        :show-gdpr="true"
                                        :fsp-es="legalUrls.fspEs"
                                        :fsp-ca="legalUrls.fspCa"
                                        :gdpr-es="legalUrls.gdprEs"
                                        :gdpr-ca="legalUrls.gdprCa"
                                        :legal-es="legalUrls.urEs"
                                        :legal-ca="legalUrls.urCa"
                                    ></ab-legal-section>
                                    <div class="card-head"></div>
                                    <div class="line-top d-flex pt-3 justify-content-end">
                                        <div class="box-send">
                                            <vue-recaptcha
                                                ref="recaptchaComponent"
                                                :sitekey="recaptchaSiteCode"
                                                :load-recaptcha-script="true"
                                                @verify="verifyCaptcha"
                                                @expired="resetCaptcha"
                                            >
                                                <ab-button-ofex
                                                    type="submit"
                                                    class="caja-button"
                                                    :text-button="$t('ofex.buttonsOfex.enviar')"
                                                    :disabled="invalid"
                                                    :show-popover="invalid"
                                                    :popover="$t('formularios.buttom.sendMessage')"
                                                ></ab-button-ofex>
                                            </vue-recaptcha>
                                        </div>
                                    </div>
                                    <div class="pt-4 mb-4">
                                        <ab-text-end name="textos-finales" />
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </ValidationObserver>
                <!--
               <div class="row">
                   <div class="col-12">
                       <span class="title-inf">{{ $t("ofex.emailRecovery.seguridadEnvio") }}</span>
                       <p class="text-inf espacio-small">{{ $t("ofex.emailRecovery.textoTransmision") }}</p>
                       <span class="title-inf">{{ $t("ofex.emailRecovery.proteccionDatos") }}</span>
                       <p class="text-inf">{{ $t("ofex.emailRecovery.textoProteccion") }}</p>
                   </div>
               </div>
               -->
            </div>
        </div>
    </div>

    <!-- <email-recovery-confirm-view v-else /> -->

    <!--<div class="container" v-else>
    <ab-confirmation
        title="Trámite realizado correctamente"
        confirmation-description="Los datos se han enviado correctamente y en breve recibirás un aviso de confirmación de recepción."
        confirmation-msg=" "
        :show-pdf-button="false"
    />
</div>-->
</template>
<script>
import { mapGetters, mapState } from "vuex";
import ResetEmailUserPdfGenerator from "@/generators/pdf/ResetEmailUserPdfGenerator";
import LoginServiceClient from "../rest/loginServiceClient.js";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import Swal from "sweetalert2";
import * as viewMappingTypes from "../router/view-mapping-types";
import ContentManagerServiceClient from "@/rest/contentManagerServiceClient";

export default {
    name: "EmailRecoveryView",
    //components: { EmailRecoveryConfirmView },
    data: function () {
        return {
            contentManagerBaseUrl: process.env.VUE_APP_API_CONTENT_MANAGER_BASE_URL,
            activityId: "",
            finished: false,
            viewMappingTypes: viewMappingTypes,
            isCompany: true,
            showErrorFile: false,
            form: {
                companyIdentification: {
                    doc: { type: "", number: "", exists: false },
                },
                userIdentification: {
                    doc: { type: "", number: "", exists: false },
                },
                invoiceNumber: null,
                invoiceAmount: null,
                email: "",
                reEmail: "",
            },
            attachedFile: {
                file: { name: "" },
                ecmProps: { fileId: null, actividadId: null, documentoId: null },
            },
            attachedFile2: {
                file: { name: "" },
                ecmProps: { fileId: null, actividadId: null, documentoId: null },
            },
            recaptchaSiteCode: process.env.VUE_APP_RECAPTCHA_SITE_CODE_INVISIBLE_MODE,
            recaptchaResponse: null,
        };
    },
    methods: {
        sendRecoveryMailRequest: function () {
            let validator = this.$refs.emailRecoveryForm;
            this.showErrorFile = false;
            if (this.attachedFile.file.name === "") {
                this.showErrorFile = true;
                this.$refs.recaptchaComponent.reset();
            }
            validator.validate().then(async (result) => {
                if (result && !this.showErrorFile) {
                    let pdfGenerator = new ResetEmailUserPdfGenerator(
                        this.$i18n,
                        this.form.companyIdentification.doc.number,
                        this.form.userIdentification.doc.number
                    );
                    pdfGenerator.generatePdf(this.form, this.isCompany, this.attachedFile, this.attachedFile2);
                    await pdfGenerator.uploadPdfWithActivity(this.activityId, false, true);

                    //pdfGenerator.downloadPdf();
                    LoginServiceClient.recoveryMail(
                        this.isCompany ? "CIF" : this.form.userIdentification.doc.type,
                        this.form.userIdentification.doc.type,
                        this.form.userIdentification.doc.number,
                        this.form.companyIdentification.doc.number,
                        this.form.name,
                        this.form.firstLastName,
                        this.form.secondLastName,
                        this.form.phone,
                        this.form.invoiceNumber,
                        //formateamos puntos
                        this.formatInvoiceAmount(this.form.invoiceAmount),
                        this.form.email,
                        pdfGenerator.uploadedDocumentId,
                        this.activityId,
                        this.$i18n.locale
                    )
                        .then(async (recoveryMailResult) => {
                            this.$notifyFormActionToMatomo(true, "recovery_email_form");
                            console.info("Resultado: " + recoveryMailResult);
                            await ContentManagerServiceClient.commitStorageDocumentsByActivity(
                                this.activityId,
                                this.$i18n.locale,
                                process.env.VUE_APP_APIM_PUBLIC_FORMS_SUBSCRIPTION_KEY_HEADER
                            );
                            this.launchEmailRecoveryToast();
                        })
                        .catch((reason) => {
                            ContentManagerServiceClient.deleteFileFromStorage(
                                pdfGenerator.uploadedDocumentId,
                                process.env.VUE_APP_APIM_PUBLIC_FORMS_SUBSCRIPTION_KEY_HEADER
                            );
                            this.$notifyFormActionToMatomo(false, "recovery_email_form", reason.response.data.errorMessage);
                            this.launchResetMailAddress(reason);
                        })
                        .finally(this.$refs.recaptchaComponent.reset());
                }
            });
        },
        launchEmailRecoveryToast: function () {
            console.log("Lanzando mensaje de OK");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "EmailRecoveryView" });
            Swal.fire({
                text: this.$i18n.tc("ofex.emailRecoveryConfirm.confirmSubtitle"),
                icon: "success",
                confirmButtonText: "OK",
            }).then(() => {
                this.$router.push(viewMappingTypes.LOGIN_VIEW_MAPPING.path);
            });
        },
        launchResetMailAddress: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "EmailRecoveryView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.emailRecovery.toasts.changedError"),
                text: e.response.data.errorMessage,
                icon: "error",
                confirmButtonText: "OK",
            });
        },
        formatInvoiceAmount: function (amount) {
            return amount.replace(".", "").replace(",", ".");
        },
        verifyCaptcha: function (response) {
            console.debug("Captcha response: " + response);
            this.recaptchaResponse = response;
            this.sendRecoveryMailRequest();
        },
        resetCaptcha: function () {
            // eslint-disable-next-line no-undef
            this.$refs.recaptchaComponent.reset(); //resetamos el recaptcha para ser capaces de darle al botón nuevamente
        },
        mouted() {
            this.$refs.recaptchaComponent.execute();
        },
    },
    computed: {
        ...mapState(["isLoading"]),
        ...mapGetters(["legalUrls"]),

        validationErrors: function () {
            return Object.values(this.$refs.emailRecoveryForm.errors).flat();
        },
    },
    created() {
        this.$validator = this.$parent.$validator;
    },
    mounted() {
        console.log("Verificando si es empresa o particular");
        this.isCompany = this.$route.query.identification && this.$route.query.identification === "company";
        this.activityId = "UD" + new Date().getTime();
    },
};
</script>
<style lang="scss">
.p-email-recovery {
    .select-component,
    .input-container {
        @include respond-below(sm) {
            margin-bottom: 20px;
        }
    }
    .select-component {
        .select-container {
            .row-select-blue:disabled {
                background-image: none;
                background-color: #f1f1f1;
                color: $color-form-text;
                cursor: default;
            }
        }
    }
}

.notFlex {
    @include respond-above(sm) {
        display: flex;
    }
}
.v-upload-recovery-email {
    margin-left: 0px;
    padding-left: 0px;
    .custom-button {
        background: $color-lightest !important;
        width: 100%;
        .azuleta {
            color: $blue-dark !important;
        }
    }
}
</style>
<style lang="scss" scoped>
.p-email-recovery {
    .campos-obligados {
        @include font-size(14);
        @include line-height(16);
        font-weight: $font-primary-bold;
        margin-bottom: 20px;
    }

    .section-head {
        //display: flex;
        margin-bottom: 5px;
        padding-top: 15px;
    }
    .card-head {
        border-top: 1px solid $blue-dark;
        padding-top: 10px;
        margin-bottom: 25px !important;
    }

    .reset-mail {
        @include font-size(14);
        @include line-height(23);
        font-weight: $font-primary;
    }

    .espacio-inf {
        margin-bottom: 24px;
    }

    .caja-button {
        width: 100%;
        @include respond-above(sm) {
            width: 193px;
        }
    }

    .border-blue {
        border-top: 1px solid #26749f;
    }

    .espacio-small {
        margin-bottom: 20px;
    }

    .espacio-smalled {
        margin-bottom: 30px;
    }

    .espacio-medio {
        padding-top: 30px;
    }

    .margen-inf-medio {
        margin-bottom: 50px;
    }

    .title-inf {
        @include font-size(14);
        @include line-height(23);
        font-weight: $font-primary-bold;
        color: $link-secondary;
    }

    .text-inf {
        @include font-size(14);
        @include line-height(23);
        font-weight: $font-primary;
        color: $color-dark;
    }

    .extra-margin-bottom {
        margin-bottom: 25px;
    }
}
</style>
