import axios from "axios";
import i18n from "../i18n";
import store from "../store/store";
import { GENERIC_ERROR_MUTATION } from "@/store/mutation-types";
import * as actionTypes from "@/store/action-types";
import { Capacitor } from "@capacitor/core";

export default class BaseOfexServiceClient {
    constructor(handleError = true, timeout = process.env.VUE_APP_AXIOS_TIMEOUT) {
        let headers = {
            "Accept-Language": i18n.locale,
            "Ocp-Apim-Subscription-Key": Capacitor.isNativePlatform()
                ? process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_NATIVE_HEADER
                : process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_HEADER,
            "Ocp-Apim-Trace": process.env.VUE_APP_APIM_TRACE_ENABLED,
            Accept: "application/json",
        };

        this._abRestClient = axios.create({
            timeout: timeout,
            headers: headers,
            withCredentials: true,
            cancelToken: store.state.restCancelToken ? store.state.restCancelToken.token : null,
        });

        if (handleError) {
            //GESTION DE ERRORES LLAMADAS REST
            this._abRestClient.interceptors.response.use(
                function (response) {
                    // Any status code that lie within the range of 2xx cause this function to trigger
                    return response;
                },
                function (error) {
                    if (axios.isCancel(error)) {
                        console.warn(`Petición cancelada explícitamente.`);
                    } else {
                        console.error("Error tipo REST capturado");

                        //verificamos que si es un 401 debemos entonces deslogar al usuario
                        if (401 === error.response.status && store.getters.isAuthenticated) {
                            store.dispatch(actionTypes.LOGOUT_ACTION, { logoutReason: "inactivity" });
                        }

                        // Any status codes that falls outside the range of 2xx cause this function to trigger
                        // verificamos si tiene objeto response o no. Puede ser un timeout por ejemplo
                        else if (error.response && error.response.data && error.response.data.errorMessage && error.response.data.errorCode) {
                            store.commit(GENERIC_ERROR_MUTATION, {
                                errorType: "REST",
                                errorCode: error.response.data.errorCode,
                                errorMessage: error.response.data.errorMessage,
                                errorCallback: function () {},
                            });
                        } else {
                            //Lanzamos un error inesperado del front
                            store.commit(GENERIC_ERROR_MUTATION, {
                                errorType: "REST",
                                errorCode: error.code,
                                errorMessage: i18n.tc("ofex.common.unexpectedErrorMessage"),
                                errorCallback: function () {},
                            });
                        }
                    }
                    return Promise.reject(error);
                }
            );
        }
    }

    get abRestClient() {
        return this._abRestClient;
    }
}
