<template>
    <mq-layout :mq="menuNativo">
        <div class="m-nav-bar">
            <div class="left-side">
                <a class="logo-container" href="/" :title="$t('common.logoTitle')">
                    <img class="logo" alt="logo aigües de barcelona" src="../../assets/images/logo1.svg" />
                </a>
            </div>
            <div class="right-side">
                <mq-layout class="language-container" :mq="menuNativoSmall">
                    <AbHeaderLanguage name="language-container" />
                </mq-layout>
                <nav class="nav-bar">
                    <mq-layout :mq="menuNativo">
                        <ul class="nav-bar-inner" v-if="menuItems">
                            <li class="item" v-for="(item, rootIndex) in menuItems">
                                <span class="nav-link-item">
                                    <a class="item" :href="item.url"> {{ item.name }} </a>
                                </span>

                                <ul class="nav-bar-second-level" v-if="menuItems[rootIndex].children.length > 0">
                                    <li class="item-inner" v-for="childItem in menuItems[rootIndex].children">
                                        <a class="link-item-second-level" :href="childItem.url">
                                            <p class="text">{{ childItem.name }}</p>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </mq-layout>
                </nav>
            </div>
        </div>
    </mq-layout>
</template>

<script>
import AbHeaderLanguage from "./AbHeaderLanguage";
import LiferayServiceClient from "@/rest/liferayServiceClient";

export default {
    name: "AbNavBar",
    components: {
        AbHeaderLanguage,
    },
    data() {
        return {
            menuItems: null,
            isWebApp: this.$isWebApp(),
        };
    },
    methods: {
        doSearch: function () {
            window.open(`https://www.aiguesdebarcelona.cat/search?q=${this.searchText}`, "_blank");
        },
    },
    computed: {
        menuNativo() {
            let isNative = [];
            if (this.isWebApp) {
                isNative = ["md", "lg"];
            } else {
                isNative = [""];
            }
            return isNative;
        },
        menuNativoSmall() {
            let isNativeSmall = [];
            if (this.isWebApp) {
                isNativeSmall = ["xs", "sm"];
            } else {
                isNativeSmall = ["xs", "sm", "md", "lg"];
            }
            return isNativeSmall;
        },
    },
    mounted() {
        LiferayServiceClient.getNavigationMenu(this.$i18n.locale).then((value) => (this.menuItems = value.data));
    },
};
</script>
<style lang="scss">
.m-nav-bar {
    background: $color-lightest;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 70px;
    align-items: center;
    @include respond-above(md) {
        height: 110px;
    }

    .left-side {
        .logo {
            width: 75px;
            @include respond-above(md) {
                width: 105px;
            }
            @include respond-above(lg) {
                width: 182px;
            }
        }
    }
    .right-side {
        display: flex;
        flex: 0 0 auto;
        align-items: center;

        .nav-bar-inner {
            display: inline-flex;
            margin-bottom: 0;

            .item {
                margin-left: 20px;
                position: relative;

                @include respond-between(md, lg) {
                    margin-left: 12px;
                }

                &:first-child {
                    margin-left: 0;
                }
            }
        }
        .nav-bar-second-level {
            display: none;
        }
        .item {
            &:hover {
                .nav-bar-second-level {
                    display: inline-block;
                }
            }
            .nav-bar-second-level {
                background: $color-lightest;
                position: absolute;
                top: 42px;
                left: -12px;
                width: calc(100% - -20px);
                max-width: calc(100% - -20px);
                min-width: 180px;
                padding: 12px 0 7px;
                z-index: 200;

                .item-inner {
                    flex: 1 0;

                    .link-item-second-level {
                        display: inline-block;
                        height: 100%;
                        width: 100%;
                        padding: 5px 10px 5px 24px;
                        font-weight: $font-primary-medium;

                        &:hover {
                            color: $color-primary;
                            text-decoration: underline !important;
                        }
                    }
                    .text {
                        color: $color-primary;
                        margin-bottom: 0;
                        @include font-size(15);
                        @include line-height(21);
                    }
                }
            }
            .nav-link-item {
                position: relative;
                color: $color-primary;
                @include font-size(18);
                @include line-height(22);
                font-weight: $font-primary-medium;
                box-shadow: 0px 3px 5px 1px rgb(0 0 0 / 10%);
                margin-bottom: 0;
                z-index: 201;

                &:after {
                    content: "";
                    position: absolute;
                    right: 12px;
                    margin-top: -4px;
                    top: 50%;
                    border: 6px solid transparent;
                    border-left-color: $color-lightest;
                    transform: rotate(90deg);

                    @include respond-between(md, lg) {
                        right: 4px;
                    }
                }
                &:before {
                    content: "";
                    position: absolute;
                    right: 12px;
                    margin-top: -2px;
                    top: 50%;
                    border: 6px solid transparent;
                    border-left-color: $blue-lighter;
                    transform: rotate(90deg);

                    @include respond-between(md, lg) {
                        right: 4px;
                    }
                }
                @include respond-below(md) {
                    @include font-size(16);
                    padding: 44px 10px;
                }
                .item {
                    display: block;
                    padding: 10px 35px 10px 10px;

                    @include respond-between(md, lg) {
                        padding: 10px 19px 10px 5px;
                    }

                    &:hover {
                        border: 0;
                        box-shadow: 0px 3px 5px 1px rgb(0 0 0 / 50%);
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
.nav-link-item a {
    color: $color-dark;
}
</style>
