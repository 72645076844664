<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="ab-password-container">
        <div class="row mb-5">
            <div class="col-12 col-lg-6">
                <ab-input
                    :ref="name + '-password'"
                    autocomplete="new-password"
                    v-model="modelValue"
                    :name="name + '-password'"
                    :type="typeInputPassword"
                    :input-alias="inputAlias"
                    :validation-delay="500"
                    :validation="{
                        required: true,
                        min: 8,
                        regex: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\/`~!@#$%^&*()\\_+\-={}|\[\]:;'<>?,.])(?=.{8,})/,
                        passwordname: {
                            userDoc: nameCriteriaData.userDoc,
                            username: nameCriteriaData.username,
                            firstname: nameCriteriaData.firstname,
                            secondname: nameCriteriaData.secondname,
                        },
                    }"
                    :css-class-list="['input-contrasena', 'float-left']"
                    :right-icon-src="rightIconSrc"
                    :label="$t('password.label')"
                    :focus-in-event="showBox"
                    :event-blur="hideBox"
                    @right-button="toggleShowPassword"
                >
                </ab-input>

                <!--                    https://github.com/apertureless/vue-password-strength-meter-->
                <password v-model="modelValue" :strength-meter-only="true" />

                <!--                    https://github.com/dropbox/zxcvbn#usage-->

                <div ref="arrow-box-pswd" class="arrow_box mt-5 p-3 text-left" :class="{ 'hidden-mobile': allCriteriasPassed || modelValue.length === 0 }">
                    <div class="d-flex align-items-center">
                        <font-awesome-icon
                            :class="lengthCriteria ? 'criteria-ok fa-check' : 'fa-times'"
                            :icon="lengthCriteria ? 'check' : 'times'"
                            class="mr-4"
                            aria-hidden="true"
                        ></font-awesome-icon>
                        <span :class="{ 'criteria-ok': lengthCriteria }" class="card-text">{{ $t("password.criterias.lengthCriteria") }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <font-awesome-icon
                            :class="uppercaseCriteria ? 'criteria-ok fa-check' : 'fa-times'"
                            :icon="uppercaseCriteria ? 'check' : 'times'"
                            class="mr-4"
                            aria-hidden="true"
                        ></font-awesome-icon>
                        <span :class="{ 'criteria-ok': uppercaseCriteria }" class="card-text">{{ $t("password.criterias.uppercaseCriteria") }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <font-awesome-icon
                            :class="lowercaseCriteria ? 'criteria-ok fa-check' : 'fa-times'"
                            :icon="lowercaseCriteria ? 'check' : 'times'"
                            class="mr-4"
                            aria-hidden="true"
                        ></font-awesome-icon>

                        <span :class="{ 'criteria-ok': lowercaseCriteria }" class="card-text">{{ $t("password.criterias.lowercaseCriteria") }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <font-awesome-icon
                            :class="numberCriteria ? 'criteria-ok fa-check' : 'fa-times'"
                            :icon="numberCriteria ? 'check' : 'times'"
                            class="mr-4"
                            aria-hidden="true"
                        ></font-awesome-icon>
                        <span :class="{ 'criteria-ok': numberCriteria }" class="card-text">{{ $t("password.criterias.numberCriteria") }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <font-awesome-icon
                            :class="symbolCriteria ? 'criteria-ok fa-check' : 'fa-times'"
                            :icon="symbolCriteria ? 'check' : 'times'"
                            class="mr-4"
                            aria-hidden="true"
                        ></font-awesome-icon>
                        <span :class="{ 'criteria-ok': symbolCriteria }" class="card-text">{{ $t("password.criterias.symbolCriteria") }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                        <font-awesome-icon
                            :class="nameCriteria ? 'criteria-ok fa-check' : 'fa-times'"
                            :icon="nameCriteria ? 'check' : 'times'"
                            class="mr-4"
                            aria-hidden="true"
                        ></font-awesome-icon>
                        <span :class="{ 'criteria-ok': nameCriteria }" class="card-text">{{ $t("password.criterias.userCriteria") }}</span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <ab-input
                    :ref="name + '-repeat-password'"
                    autocomplete="current-password"
                    v-model="repeatValue"
                    :name="name + '-repeat-password'"
                    :input-alias="repeatInputAlias"
                    :type="typeInputRepeatPassword"
                    validation-mode="aggressive"
                    :validation-delay="500"
                    :validation="{
                        required: true,
                        confirmed: name + '-password' + '-vid',
                    }"
                    :css-class-list="['input-contrasena', 'float-left']"
                    :right-icon-src="repeatRightIconSrc"
                    :label="$t('password.repeatLabel')"
                    :pasteable="false"
                    @right-button="toggleShowRepeatPassword"
                >
                </ab-input>
            </div>
        </div>

        <div header-tag="header" role="tab">
            <div class="dropdown-section-inner" block v-b-toggle.decalogo variant="info">
                <div class="text">
                    <div variant="tab" class="text-inner">
                        <font-awesome-icon class="icon" icon="chevron-circle-right"></font-awesome-icon>
                        <span>{{ $t("password.titleText") }}</span>
                    </div>
                </div>
            </div>
        </div>
        <b-collapse id="decalogo" accordion="" role="tabpanel">
            <div class="mt-3">
                <span v-for="index in 10" :key="index" class="text-point-passwd d-block">
                    {{ $t("password.decalogo" + index) }}
                </span>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import AbInput from "./AbInput.vue";
import Password from "vue-password-strength-meter";

export default {
    components: {
        AbInput,
        Password,
    },
    model: {
        prop: "passwordValue",
        event: "change",
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        inputAlias: {
            type: String,
            required: true,
        },
        repeatInputAlias: {
            type: String,
            required: true,
        },
        reference: {
            type: String,
        },
        passwordLength: {
            type: Number,
            default: 8,
        },
        userDoc: {
            type: String,
            default: "",
        },
        username: {
            type: String,
            default: "",
        },
        firstname: {
            type: String,
            default: "",
        },
        secondname: {
            type: String,
            default: "",
        },
    },
    data: function () {
        return {
            repeatValue: "",
            showPassword: false,
            showRepeatPassword: false,
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["passwordValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
        lengthCriteria: function () {
            return this.modelValue.length >= this.passwordLength;
        },
        uppercaseCriteria: function () {
            let uppercaseRegex = new RegExp("[A-Z]+");
            return uppercaseRegex.test(this.modelValue);
        },
        lowercaseCriteria: function () {
            let lowercaseRegex = new RegExp("[a-z]+");
            return lowercaseRegex.test(this.modelValue);
        },
        numberCriteria: function () {
            let numberRegex = new RegExp("[0-9]+");
            return numberRegex.test(this.modelValue);
        },
        symbolCriteria: function () {
            let symbolRegex = new RegExp("[\\/`~!@#$%\\^&*\\(\\)_+\\-={}|\\[\\]\\\\:\";'<>?,./]+");
            return symbolRegex.test(this.modelValue);
        },
        nameCriteria: function () {
            return !(
                (this.nameCriteriaData.userDoc.length > 0 && this.modelValue.toLowerCase().includes(this.nameCriteriaData.userDoc.toLowerCase())) ||
                (this.nameCriteriaData.username.length > 0 && this.modelValue.toLowerCase().includes(this.nameCriteriaData.username.toLowerCase())) ||
                (this.nameCriteriaData.firstname.length > 0 && this.modelValue.toLowerCase().includes(this.nameCriteriaData.firstname.toLowerCase())) ||
                (this.nameCriteriaData.secondname.length > 0 && this.modelValue.toLowerCase().includes(this.nameCriteriaData.secondname.toLowerCase()))
            );
        },
        nameCriteriaData: function () {
            return {
                userDoc: this.userDoc == null ? "" : this.userDoc,
                username: this.username == null ? "" : this.username,
                firstname: this.firstname == null ? "" : this.firstname,
                secondname: this.secondname == null ? "" : this.secondname,
            };
        },
        allCriteriasPassed: function () {
            return this.uppercaseCriteria && this.lowercaseCriteria && this.numberCriteria && this.symbolCriteria && this.lengthCriteria && this.nameCriteria;
        },
        typeInputPassword: function () {
            return this.showPassword ? "text" : "password";
        },
        typeInputRepeatPassword: function () {
            return this.showRepeatPassword ? "text" : "password";
        },
        rightIconSrc: function () {
            return this.showPassword ? require("../assets/images/svg/eye-slash.svg") : require("../assets/images/svg/eye.svg");
        },
        repeatRightIconSrc: function () {
            return this.showRepeatPassword ? require("../assets/images/svg/eye-slash.svg") : require("../assets/images/svg/eye.svg");
        },
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        toggleShowPassword: function () {
            this.showPassword = !this.showPassword;
        },
        toggleShowRepeatPassword: function () {
            this.showRepeatPassword = !this.showRepeatPassword;
        },
        showBox: function () {
            this.$refs["arrow-box-pswd"].classList.remove("hidden-mobile");
        },
        hideBox: function () {
            this.$refs["arrow-box-pswd"].classList.add("hidden-mobile");
        },
        cleanPassword: function () {
            this.repeatValue = "";
            this.modelValue = "";
        },
    },
};
</script>
<style lang="scss">
.ab-password-container {
    margin-bottom: 1rem !important;
}

.arrow_box span {
    color: darkred;
    display: inline;
}

.arrow_box .fa-times {
    color: darkred;
}

.criteria-ok {
    color: #50784b !important;
}

.criteria-fail {
    color: darkred;
}

.h3-title-passwd {
    color: $color-secondary;
    @include font-size(16);
    font-weight: $font-primary-bold;
}

.text-point-passwd {
    @include font-size(12);
    font-weight: $font-primary;
}

.arrow_box {
    width: 72%;
    position: relative;
    background: $color-lightest;
    border: 4px solid $button-bg-secondary;
}

@include respond-below(sm) {
    .hidden-mobile {
        display: none;
    }
}

.arrow_box:after,
.arrow_box:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box:after {
    border-color: transparent;
    border-bottom-color: $color-lightest;
    border-width: 20px;
    margin-left: -20px;
}

.arrow_box:before {
    border-color: transparent;
    border-bottom-color: $button-bg-secondary;
    border-width: 26px;
    margin-left: -26px;
}

.input-contrasena {
    width: 60% !important;
    height: 32px;
    border-radius: 8px 0 0 8px !important;
    border: 1px solid $border-table;
    background-color: $color-lightest;
    font-weight: $font-primary;
    @include font-size(14);
}

.Password {
    width: 72% !important;
    margin: 0 !important;
}

.Password__strength-meter {
    height: 8px !important;
}

.format-card {
    border-bottom: 1px solid $blue-medium;
    font-size: 12px;
    border-radius: 0 !important;
    font-weight: $font-primary-bold;
}
.card-text {
    @include font-size(12);
}
</style>
<style lang="scss" scoped>
.dropdown-section-inner {
    .text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: $blue-dark;
        @include font-size(16);
        font-weight: $font-primary-bold;
        cursor: pointer;

        .text-inner {
            display: flex;
            align-items: center;
        }
        &.active,
        .not-collapsed {
            .icon {
                transform: rotate(90deg);
            }
        }
        .icon {
            margin-right: 10px;
            transition: all 0.2s ease-in-out;
            width: 20px;
            height: 20px;
        }
    }
    .text-box-grey {
        color: $bg-primary;
    }
    &.not-collapsed {
        .icon {
            transform: rotate(90deg);
        }
    }
}
.custom-header {
    padding: 0;
    margin-bottom: 0;
    background-color: none;
    border-bottom: none;
}
/* padding: 0.75rem 1.25rem; */
/* margin-bottom: 0; */
/* background-color: rgba(0, 0, 0, 0.03); */
/* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
</style>
