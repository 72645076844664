<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <div class="row mt-3 d-flex align-items-center">
            <div class="col-12 col-md-2 text-center"><img alt="" class="img-fixed mt-5 mt-md-0" :src="imageSrc" /></div>

            <div class="col-12 col-md-10">
                <h1 class="h1formsuccess">{{ title ? title : $t("confirmation.titleCambioTitular") }}</h1>
            </div>

            <div class="col-12">
                <h2
                    class="titleh1down text-normal mt-5 mb-4"
                    :class="enterClass"
                    v-html="confirmationMsg ? confirmationMsg : $t('confirmation.subtitleCambioTitular')"
                ></h2>
            </div>

            <div class="col-12 contract-list">
                <dl>
                    <dt>{{ $t("confirmation.contractNumberLabel") }}</dt>
                    <dd>{{ $t(contractNumber) }}</dd>
                </dl>
                <dl>
                    <dt>{{ $t("confirmation.titularLabel") }}</dt>
                    <dd>{{ $t(fullName) }}</dd>
                </dl>
                <dl>
                    <dt>{{ $t("confirmation.documentTypeLabel") }}</dt>
                    <dd>{{ $t(documentType) }}</dd>
                </dl>
                <dl>
                    <dt>{{ $t("confirmation.documentNumberLabel") }}</dt>
                    <dd>{{ $t(documentNumber) }}</dd>
                </dl>
                <dl>
                    <dt>{{ $t("confirmation.phoneLabel") }}</dt>
                    <dd>{{ $t(phoneNumber) }}</dd>
                </dl>
                <dl>
                    <dt>{{ $t("confirmation.emailLabel") }}</dt>
                    <dd>{{ $t(email) }}</dd>
                </dl>
                <dl>
                    <dt>{{ $t("confirmation.supplyAddressLabel") }}</dt>
                    <dd>{{ $t(supplyAddress) }}</dd>
                </dl>
                <dl>
                    <dt>{{ $t("confirmation.useTypeLabel") }}</dt>
                    <dd>{{ $t(useType) }}</dd>
                </dl>
            </div>

            <div class="col-12">
                <p class="text" v-html="confirmationDescription ? confirmationDescription : $t('confirmation.textCambioTitular')"></p>
            </div>

            <div v-if="showPdfButton" class="col-12 text-center mt-5">
                <p class="texto-info">{{ $t("confirmation.infoDescargaCambioTitular") }}</p>
                <font-awesome-icon icon="download" class="fa-4x mb-3 confirmation-download" @click="generatePdfHandler"></font-awesome-icon>
                <p class="confirmation-donwload-text text-normal" v-html="$t('confirmation.descargarSolicitud')"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        enterClass: {
            type: String,
            required: false,
        },
        imageSrc: {
            type: String,
            default: require("../assets/images/tic-green.png"),
        },
        homeUrl: {
            type: String,
            default: "/",
        },
        title: {
            type: String,
        },
        confirmationMsg: {
            type: String,
        },
        confirmationDescription: {
            type: String,
        },
        generatePdfHandler: {
            type: Function,
        },
        showPdfButton: {
            type: Boolean,
            default: true,
        },
        fullName: {
            type: String,
            required: true,
        },
        contractNumber: {
            type: String,
            required: true,
        },
        documentType: {
            type: String,
            required: true,
        },
        documentNumber: {
            type: String,
            required: true,
        },
        phoneNumber: {
            type: String,
            required: true,
        },
        email: {
            type: String,
            required: true,
        },
        supplyAddress: {
            type: String,
            required: true,
        },
        useType: {
            type: String,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
.confirmation-download {
    color: #515151;
}

.img-fixed {
    height: 85px;
}

.confirmation-download:hover {
    cursor: pointer;
    color: $blue-dark;
}

.h1formsuccess {
    color: $blue-dark;
    font-weight: $font-primary-bold;
    @include font-size(26);
}

.titleh1down {
    font-weight: $font-primary-bold;
    @include font-size(16);
    color: $blue-dark;
}
.text {
    @include font-size(14);
}
.texto-info {
    font-weight: $font-primary-bold;
    @include font-size(12);
    color: $blue-dark;
}
.contract-list {
    margin-bottom: 20px;
    margin-left: 150px;

    @include respond-below(sm) {
        margin-left: 0;
    }

    dl {
        margin-bottom: 0;
        display: flex;
    }
    dt {
        font-weight: $font-primary-bold;
        @include font-size(12);
        color: $blue-dark;
        margin-right: 20px;
        min-width: 150px;
    }
    dd {
        @include font-size(14);
        color: $color-dark;
    }
}
</style>
