<template>
    <div class="m-last-invoice box">
        <div class="graphic-container">
            <div class="graphic-container-inner">
                <!--Gráfica donuts-->
                <div class="graphic-position">
                    <ab-invoice-donut-chart :chartdata="invoiceDonutData" :options="invoiceDonutOptions" />
                    <div class="graphic-container-data">
                        <p class="amount">{{ $formatNumber(invoice.amount) }}{{ $t("ofex.home.euro") }}</p>
                        <p class="liters" v-html="this.$formatConsumption(invoice.consumptionVolume)"></p>
                        <router-link
                            v-if="invoice.invoiceStatus === 'No Pagada'"
                            :to="{
                                name: viewMappingTypes.INVOICE_PAYMENT.name,
                                params: { contractId: invoice.contractNumber, invoiceId: invoice.invoiceNumber },
                            }"
                        >
                            <p class="status pending">{{ $t("ofex.home.unpaidcircletext") }}</p>
                        </router-link>
                        <p class="status" v-else>{{ invoiceStatusTranslation }}</p>
                    </div>
                </div>
            </div>
            <div class="bonification-container">
                <p v-if="invoice.bonificationsTotalAmount !== null">
                    {{ $t("ofex.invoiceSummary.bonifications") }} <span>{{ $formatNumber(invoice.bonificationsTotalAmount) }} {{ $t("ofex.home.euro") }}</span>
                </p>
                <p v-if="invoice.totalSavingAmount !== null">
                    {{ $t("ofex.invoiceSummary.savings") }} <span>{{ $formatNumber(invoice.totalSavingAmount) }} {{ $t("ofex.home.euro") }}</span>
                </p>
            </div>
            <div class="invoice-action">
                <ab-button-ofex
                    v-if="invoice.invoiceStatus === 'Pagada'"
                    :click-event="downloadInvoice"
                    :text-button="$t('ofex.home.descargarFactura')"
                ></ab-button-ofex>
                <a v-else class="link separatorButton" @click.stop="downloadInvoice">
                    {{ $t("ofex.home.descargarFactura") }}
                </a>
                <div class="button-container">
                    <ab-button-ofex
                        class=""
                        :click-event="routerNavigatePaymentView"
                        v-if="invoice.invoiceStatus === 'No Pagada'"
                        :text-button="$t('ofex.buttonsOfex.pagar')"
                    ></ab-button-ofex>
                </div>
            </div>
        </div>
        <table class="invoices-container" ref="invoicesContainerTable">
            <colgroup>
                <col />
                <col />
            </colgroup>
            <tbody>
                <tr class="item item-consumption">
                    <td align="left" class="logo-container">
                        <img alt="logo aigües de barcelona" src="../assets/images/svg/logo-aguas.svg" />
                    </td>
                    <td class="text-invoice">
                        <span>{{ $formatNumber(invoice.waterServiceAmount) }}{{ $t("ofex.home.euro") }}</span>
                        {{ $t("ofex.home.suministroAgua") }}
                    </td>
                </tr>
                <tr class="item item-water-amount" v-if="invoice.waterCanonAmount !== null">
                    <td align="left" class="logo-container">
                        <img alt="logo agencia catalana de agua" src="../assets/images/svg/logo-ag-cat.svg" />
                    </td>
                    <td class="text-invoice">
                        <span>{{ $formatNumber(invoice.waterCanonAmount) }}{{ $t("ofex.home.euro") }}</span>
                        {{ $t("ofex.home.canonAgua") }}
                    </td>
                </tr>
                <tr class="item item-clavegueram" v-if="computedClavegueram !== null">
                    <td align="left" class="logo-container" v-if="contract && contract.supplyAddress.gbar">
                        <img alt="logo agencia tributaria" :src="require(`../assets/images/ayuntamientos/${contract.supplyAddress.gbar}.png`)" />
                    </td>
                    <!-- Ayuntamiento de Barcelona por defecto-->
                    <td align="left" class="logo-container" v-if="contract && !contract.supplyAddress.gbar">
                        <img alt="logo agencia tributaria" :src="require(`../assets/images/ayuntamientos/00.png`)" />
                    </td>
                    <td class="text-invoice">
                        <span v-if="invoice.isExemptClavegueram">Exento</span>
                        <span v-else>{{ $formatNumber(computedClavegueram) }}{{ $t("ofex.home.euro") }}</span>
                        {{ $t("ofex.home.alcantarillado") }}
                    </td>
                </tr>
                <tr class="item item-trr" v-if="invoice.trrAmount !== null">
                    <td align="left" class="logo-container" v-if="contract && contract.supplyAddress.gbar">
                        <img alt="logo agencia tributaria" :src="require(`../assets/images/ayuntamientos/${contract.supplyAddress.gbar}.png`)" />
                    </td>
                    <!-- Ayuntamiento de Barcelona por defecto-->
                    <td align="left" class="logo-container" v-if="contract && !contract.supplyAddress.gbar">
                        <img alt="logo agencia tributaria" :src="require(`../assets/images/ayuntamientos/00.png`)" />
                    </td>
                    <td class="text-invoice">
                        <span v-if="invoice.isExemptTrr">Exento</span>
                        <span v-else>{{ $formatNumber(invoice.trrAmount) }}{{ $t("ofex.home.euro") }}</span>
                        {{ $t("ofex.home.trr") }}
                    </td>
                </tr>
                <tr class="item item-tmtr" v-if="invoice.tmtrAmount !== null">
                    <td align="left" class="logo-container">
                        <img alt="logo metro de barcelona" src="../assets/images/svg/logo-ar-metro.svg" />
                    </td>
                    <td class="text-invoice">
                        <span>{{ $formatNumber(invoice.tmtrAmount) }}{{ $t("ofex.home.euro") }}</span>
                        {{ $t("ofex.home.tmtr") }}
                    </td>
                </tr>
                <tr class="item item-credit-debit" v-if="invoice.debitsAndCreditsAmount !== null">
                    <td align="left" class="logo-container"></td>
                    <td class="text-invoice">
                        <span>{{ $formatNumber(invoice.debitsAndCreditsAmount) }}{{ $t("ofex.home.euro") }}</span>
                        {{ $t("ofex.invoiceSummary.debitsAndCreditsAmount") }}
                    </td>
                </tr>
                <tr class="item item-regularizations" v-if="invoice.regularizationAmount !== null">
                    <td align="left" class="logo-container"></td>
                    <td class="text-invoice">
                        <span>{{ $formatNumber(invoice.regularizationAmount) }}{{ $t("ofex.home.euro") }}</span>
                        {{ $t("ofex.invoiceSummary.regularizations") }}
                    </td>
                </tr>
                <tr class="item item-iva" v-if="invoice.ivaAmount !== null">
                    <td align="left" class="logo-container">
                        <img alt="logo agencia tributaria" :src="require(`../assets/images/logo-ag-tr.png`)" />
                    </td>
                    <td class="text-invoice">
                        <span>{{ $formatNumber(invoice.ivaAmount) }}{{ $t("ofex.home.euro") }}</span>
                        {{ $t("ofex.home.iva") }}
                    </td>
                </tr>
                <tr class="item item-remainder" v-if="invoice.remainderAmount !== null">
                    <td align="left" class="logo-container"></td>
                    <td class="text-invoice">
                        <span>{{ $formatNumber(invoice.remainderAmount) }}{{ $t("ofex.home.euro") }}</span>
                        {{ $t("ofex.home.remainder") }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="summary-container" v-if="showTotalDebt && totalUnpaidAmount && totalUnpaidAmount > 0">
            <div class="summary-container-text">
                <span>{{ $t("ofex.home.pendientePago") }}</span>
                <span class="amount">{{ $formatNumber(totalUnpaidAmount) }} {{ $t("ofex.home.euro") }}</span>
            </div>
            <div class="summary-container-button" v-if="unpaidInvoices && unpaidInvoices.length > 0">
                <ab-button-ofex :text-button="$t('ofex.buttonsOfex.pagarLasFacturas')" :click-event="routerNavigatePaymentContract"></ab-button-ofex>
            </div>
        </div>
    </div>
</template>

<script>
import * as viewMappingTypes from "../router/view-mapping-types";
import AbInvoiceDonutChart from "./charts/AbInvoiceDonutChart";
import { mapGetters, mapState } from "vuex";
import { trimBackendCode } from "@/plugins/ab-utils";
import { Capacitor } from "@capacitor/core";
export default {
    name: "AbInvoiceSummary",
    components: { AbInvoiceDonutChart },
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        showTotalDebt: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
            apimSubscriptionKey: Capacitor.isNativePlatform()
                ? process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_NATIVE_HEADER
                : process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_HEADER,
        };
    },
    computed: {
        ...mapState(["userInvoices", "unpaidInvoices", "selectedContractNumber"]),
        ...mapGetters(["totalUnpaidAmount", "getContractByContractNumber"]),
        invoiceStatusTranslation: function () {
            return this.$i18n.tc(`ofex.backendCodes.invoiceStatus.${trimBackendCode(this.invoice.invoiceStatus)}`);
        },
        computedClavegueram: function () {
            if (this.invoice.clavegueramAmount !== null) {
                return this.invoice.clavegueramAmount;
            } else if (this.invoice.clavegueramSoreaAmount !== null) {
                return this.invoice.clavegueramSoreaAmount;
            } else if (this.invoice.clavegueramPortAmount !== null) {
                return this.invoice.clavegueramPortAmount;
            } else {
                return null;
            }
        },
        invoiceDonutData: function () {
            return {
                datasets: [
                    {
                        data: [
                            this.invoice.waterServiceAmount,
                            this.invoice.waterCanonAmount,
                            this.computedClavegueram,
                            this.invoice.trrAmount,
                            this.invoice.tmtrAmount,
                            this.invoice.debitsAndCreditsAmount,
                            this.invoice.regularizationAmount,
                            this.invoice.ivaAmount,
                            this.invoice.remainderAmount,
                        ],
                        backgroundColor: ["#1C7C9C", "#147EB3", "#194F6D", "#002F6B", "#757575", "#803c00", "#bd5800", "#454545", "#B8B8B8"],
                        label: this.$t("ofex.invoiceSummary.desgloseFactura"),
                    },
                ],
                labels: [
                    this.$t("ofex.invoiceSummary.suministroAgua"),
                    this.$t("ofex.invoiceSummary.canonAgua"),
                    this.$t("ofex.invoiceSummary.alcantarillado"),
                    this.$t("ofex.invoiceSummary.recogidaResiduos"),
                    "TMTR",
                    this.$t("ofex.invoiceSummary.debitsAndCreditsAmount"),
                    this.$t("ofex.invoiceSummary.regularizations"),
                    "IVA",
                    this.$t("ofex.invoiceSummary.otrosConceptos"),
                ],
            };
        },
        invoiceDonutOptions: function () {
            let vueContext = this;
            return {
                responsive: true,
                cutoutPercentage: 82,
                maintainAspectRatio: true,
                bodyAlign: true,
                legend: {
                    display: false,
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                tooltips: {
                    padding: 30,
                    bodyFontSize: 12,
                    backgroundColor: "#FFF",
                    borderColor: "#417290",
                    borderWidth: 1,
                    titleFontColor: "#417290",
                    bodyFontColor: "#417290",
                    callbacks: {
                        label: function (tooltipItems, data) {
                            let i = tooltipItems.index;
                            return data.labels[i] + ": " + vueContext.$formatNumber(data.datasets[0].data[i]) + " €";
                        },
                    },
                },
            };
        },
        contract: function () {
            return this.getContractByContractNumber(this.selectedContractNumber);
        },
    },
    methods: {
        routerNavigatePaymentContract: function () {
            this.$router.push({ name: viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.name });
        },
        routerNavigatePaymentView: function () {
            this.$router.push({
                name: viewMappingTypes.INVOICE_PAYMENT.name,
                params: { contractId: this.invoice.contractNumber, invoiceId: this.invoice.invoiceNumber },
            });
        },
        downloadInvoice: function () {
            this.$downloadInvoice({
                invoiceNumber: this.invoice.invoiceNumber,
                invoiceYear: this.invoice.invoiceYear,
                locale: this.$i18n.locale,
            });
        },
    },
    mounted() {
        // Espacio dinámico dependiendo del número de conceptos https://zenerab.atlassian.net/browse/UATOFEX-650
        let conceptElements = document.querySelectorAll(".invoices-container tbody tr").length;
        let borderSpacePixels = 15 * (7 - conceptElements > 0 ? 7 - conceptElements : 1);
        this.$refs.invoicesContainerTable.style.borderSpacing = `0 ${borderSpacePixels}px`;
    },
};
</script>

<style lang="scss" scoped>
.m-last-invoice {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    @include respond-above(sm) {
        justify-content: space-between;
    }
    @include respond-above(lg) {
        justify-content: space-between;
        padding: 0 45px;
    }

    .graphic-container {
        flex: 100%;
        text-align: center;
        margin-bottom: 40px;

        @include respond-above(sm) {
            flex: 0 0 auto;
            margin-bottom: 0;
        }
        @include respond-above(sm) {
            min-width: 300px;
        }
        .graphic-container-inner {
            flex: 0;
            width: 210px;
            margin: 0 auto 40px;
            position: relative;

            .graphic-position {
                position: relative;
                z-index: 2;
            }
            .graphic-container-data {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 140px;
                z-index: -1;
                margin-left: -70px;
                margin-top: -37px;

                .amount {
                    color: $blue-dark;
                    margin-bottom: 7px;
                    font-weight: $font-primary-bold;
                    @include font-size(28);
                    @include line-height(26);
                }
                .liters {
                    color: $blue-dark;
                    margin-bottom: 7px;
                    font-weight: $font-primary-semiBold;
                    @include font-size(18);
                    @include line-height(16);
                }
                .status {
                    color: $blue-dark;
                    font-weight: $font-primary-bold;
                    @include font-size(14);
                    @include line-height(21);
                }
                .status.pending {
                    color: $error-color;
                    //text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .invoice-action {
            display: inline-flex;
            align-items: center;

            .link {
                cursor: pointer;
                color: $button-bg-primary;
                font-weight: $font-primary-semiBold;
                @include font-size(16);
                @include line-height(21);
                padding: 8px;
            }
            .link + .button-container .btn {
                margin-left: 20px;

                @include respond-above(sm) {
                    margin-left: 45px;
                }
            }
        }
    }
    .invoices-container {
        flex: 0 0 auto;
        border-spacing: 0 15px;
        border-collapse: separate;
        justify-content: center;

        .item {
            .logo-container {
                width: 90px;
                padding: 0 10px 0 0;
            }
            .text-invoice {
                color: $color-lightest;
                font-weight: $font-primary-bold;
                padding: 10px;
                @include font-size(14);
                @include line-height(17);
            }
            &.item-consumption .text-invoice {
                background: $blue-medium;
            }
            &.item-water-amount .text-invoice {
                background: $blue-lighter;
            }
            &.item-clavegueram .text-invoice {
                background: $blue-darkest;
            }
            &.item-trr .text-invoice {
                background: $bg-primary;
            }
            &.item-tmtr .text-invoice {
                background: $border-table;
            }
            &.item-credit-debit .text-invoice {
                background: #803c00;
            }
            &.item-regularizations .text-invoice {
                background: #bd5800;
            }
            &.item-iva .text-invoice {
                background: #454545;
            }
            &.item-remainder .text-invoice {
                background: #b8b8b8;
            }
        }
    }
    .summary-container {
        flex: 100%;
        margin-top: 40px;

        @include respond-above(sm) {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
        }
        @include respond-above(lg) {
            justify-content: flex-end;
        }
        .summary-container-text {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            color: $blue-lighter;
            font-weight: $font-primary-bold;
            @include font-size(14);
            @include line-height(16);
            margin-bottom: 30px;

            span {
                flex: 100%;
                text-align: center;

                @include respond-above(sm) {
                    flex: auto;
                    text-align: left;
                }
            }
            .amount {
                margin-top: 15px;
                @include font-size(32);
                @include line-height(21);

                @include respond-above(sm) {
                    margin-left: 10px;
                    margin-top: 0;
                }
            }
            @include respond-above(sm) {
                margin-right: 25px;
                margin-bottom: 0;
                flex-wrap: nowrap;
                align-items: center;
            }
            @include respond-above(lg) {
                margin-right: 70px;
            }
        }
    }
}
</style>
