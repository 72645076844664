<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="row">
        <div class="col-12">
            <div class="">
                <div v-for="(item, index) in nuevasBaterias" :key="item.letra" class="row mt-5 mb-5">
                    <div class="col-8 col-lg-4">
                        <ab-input
                            v-model="nuevasBaterias[index].letra"
                            :name="name + 'letra' + index"
                            :input-alias="$t('alias.letraBateria')"
                            placeholder="1, 2, 3..."
                            :popover="$t('baterias.popovers.letraBateria')"
                            :validation="{ required: true, min: 1 }"
                            :label="$t('baterias.bateriaNueva')"
                        ></ab-input>
                    </div>
                    <div class="col-8 col-lg-4">
                        <ab-input
                            v-model="nuevasBaterias[index].boquillasBateria"
                            :name="name + 'boquillasBateria' + index"
                            :input-alias="$t('alias.boquillasBateria')"
                            placeholder="1, 2, 3..."
                            :popover="$t('baterias.popovers.numeroBoquillas')"
                            :validation="{ required: true, min: 1 }"
                            :label="$t('baterias.numeroBoquillasBateria')"
                        ></ab-input>
                    </div>
                </div>

                <div class="row mt-2 mb-5">
                    <div class="col-12 col-lg-8">
                        <button type="button" class="botonBateria" @click="addNewEntry">
                            <i class="fas fa-plus-circle"
                                ><span class="textdnormal ml-1">{{ $t("baterias.addBateria") }}</span></i
                            >
                        </button>
                    </div>
                    <div v-if="isMulti" class="col-12 col-lg-4">
                        <button type="button" class="botonBateria" @click="removeLastEntry">
                            <i class="fas fa-minus-circle"
                                ><span class="textdnormal ml-1">{{ $t("baterias.deleteBateria") }}</span></i
                            >
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AbInput from "./AbInput.vue";

export default {
    components: {
        AbInput,
    },
    model: {
        prop: "nuevasBateriasList",
        event: "change",
        type: Array,
    },
    props: {
        required: {
            default: false,
            type: Boolean,
        },
        name: {
            required: true,
            type: String,
        },
        validationScope: String,
        validationEvent: String,
    },
    data: function () {
        return {
            nuevasBaterias: [{ letra: "", boquillasBateria: "" }],
        };
    },
    computed: {
        isMulti: function () {
            return this.nuevasBaterias.length > 1;
        },
    },
    methods: {
        addNewEntry: function () {
            this.nuevasBaterias.push({ letra: "", boquillasBateria: "" });
        },
        removeLastEntry: function () {
            this.nuevasBaterias.pop();
        },
    },
};
</script>
