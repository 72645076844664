<template>
    <b-container>
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <b-col cols="12" md="12" lg="12">
                <ab-main-title :main-text="$t('ofex.passwordlessLogin.title')"></ab-main-title>

                <ab-error-header :error-list="dummyErrors" :include-rest-error="true" :message-header="$t('ofex.passwordlessLogin.errorHeader')" />

                <vue-recaptcha
                    ref="recaptchaComponent"
                    :sitekey="recaptchaSiteCode"
                    :load-recaptcha-script="true"
                    @verify="verifyCaptcha"
                    @expired="resetCaptcha"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapState } from "vuex";
import * as actionTypes from "../store/action-types";
import * as viewMappingTypes from "../router/view-mapping-types";

export default {
    name: "LoginPasswordlessView",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
            recaptchaSiteCode: process.env.VUE_APP_RECAPTCHA_SITE_CODE_INVISIBLE_MODE,
            recaptchaResponse: null,
            dummyErrors: [],
        };
    },
    computed: {
        ...mapState(["isLoading"]),
    },
    methods: {
        passwordlessLoginTransaction: function (username, verifyToken) {
            this.$store
                .dispatch(actionTypes.LOGIN_PASSWORDLESS_ACTION, {
                    username,
                    verifyToken,
                    recaptchaClientResponse: this.recaptchaResponse,
                })
                .finally(this.$refs.recaptchaComponent.reset());
        },
        handlePasswordlessLogin: function () {
            console.debug("Validando clave Siebel y haciendo login");
            let verifyToken = this.$route.query.pwd1;
            this.passwordlessLoginTransaction(this.$route.query.username, verifyToken);
        },
        verifyCaptcha: function (response) {
            console.debug("Captcha response: " + response);
            this.recaptchaResponse = response;
            this.handlePasswordlessLogin();
        },
        resetCaptcha: function () {
            // eslint-disable-next-line no-undef
            this.$refs.recaptchaComponent.reset(); //resetamos el recaptcha para ser capaces de darle al botón nuevamente
        },
    },
};
</script>
<style></style>
