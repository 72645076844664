<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="c-country-select">
        <ab-select
            v-model="modelValue"
            :name="name"
            :label="label"
            :input-alias="inputAlias"
            :placeholder="placeholder"
            :validation="validation"
            :data-list="countryList"
            data-key="id"
            data-label="name"
        >
        </ab-select>
    </div>
</template>

<script>
import AbSelect from "./AbSelect";

export default {
    components: {
        AbSelect,
    },
    model: {
        prop: "selectValue",
        event: "change",
    },
    props: {
        title: String,
        name: String,
        inputAlias: String,
        popover: String,
        label: String,
        placeholder: String,
        validation: Object,
    },
    data: function () {
        return {
            countryList: [],
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["selectValue"].toUpperCase();
            },
            set: function (newValue) {
                this.$emit("change", newValue.toUpperCase());
            },
        },
    },
    created() {
        var vueContext = this;
        console.debug("Filling country data");
        this.$http.get(`${process.env.VUE_APP_API_CARRERER_BASE_URL}/countries/all`, { headers: { "Accept-Language": this.$i18n.locale } }).then(
            function (response) {
                vueContext["countryList"] = response.data;
            },
            function (error) {
                console.error(error);
            }
        );
    },
    methods: {
        getCountryNameFromKey: function (key) {
            return this.countryList.filter((value) => value.id === key)[0].name;
        },
    },
};
</script>
<style lang="scss" scoped>
.c-country-select {
    @include respond-below(md) {
        margin-bottom: 20px;
    }
}
</style>
