<script>
import { Line } from "vue-chartjs";
import { AB_DATE_FORMAT } from "@/plugins/ab-utils";
import moment from "moment";
import { isBrowser } from "mobile-device-detect";
import { executeAccessibilityFixes } from "@/plugins/accesibility";

export default {
    extends: Line,
    props: {
        userInvoices: {
            type: Array,
            required: true,
        },
        concept: {
            type: String,
            required: true,
        },
        conceptName: {
            type: String,
            required: true,
        },
        chartType: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
        };
    },
    methods: {
        downloadPdf: function (e, data) {
            if (isBrowser) {
                //TODO sacar a método global de utilidades (duplicado)
                if (data.length > 0) {
                    let activeElement = data[0]._chart.getElementAtEvent(e);
                    console.debug(activeElement);
                    if (activeElement.length > 0) {
                        let selectedInvoice = this.userInvoices[activeElement[0]._index];
                        console.debug(selectedInvoice);
                        this.$downloadInvoice({
                            invoiceNumber: selectedInvoice.invoiceNumber,
                            invoiceYear: selectedInvoice.invoiceYear,
                            locale: this.$i18n.locale,
                        });
                    }
                }
            }
        },
        renderInvoiceEvolutionChart: function () {
            console.log("Creando gráfica de evolucion de facturas");
            var vueContext = this;

            let invoicesEvolutionBarOptions = {
                responsive: true,
                maintainAspectRatio: false,
                onClick: this.downloadPdf,
                legend: {
                    display: false,
                    position: "bottom",
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: "Importe (€)",
                            },
                        },
                    ],
                    xAxes: [
                        {
                            offset: true,
                            distribution: "series",
                            scaleLabel: {
                                display: true,
                            },
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 0,
                                minRotation: 90,
                                mirror: true,
                            },
                        },
                    ],
                },
                tooltips: {
                    backgroundColor: "#FFF",
                    borderColor: "#417290",
                    borderWidth: 1,
                    titleFontColor: "#417290",
                    callbacks: {
                        label: function () {
                            return "";
                        },
                        title: function (tooltipItem) {
                            console.debug(tooltipItem);
                            let invoice = vueContext.invoiceReverseDataset[tooltipItem[0].index];
                            return ` Fecha: ${invoice.endDate} \n ${vueContext.conceptName}: ${vueContext.$formatNumber(invoice[vueContext.concept])} €`;
                        },
                    },
                },
            };

            let invoicesEvolutionBarData = {
                datasets: [
                    {
                        data: this.conceptThreeYearsEvolution,
                        hoverBackgroundColor: "#95CAEA",
                        label: `Factura con consumo real`,
                        fill: false,
                        lineTension: 0.1,
                        backgroundColor: "#233A48",
                        borderColor: "#417290", // The main line color
                        borderCapStyle: "butt",
                        borderDash: [], // try [5, 15] for instance
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "black",
                        pointBackgroundColor: this.consumptionPointsBackgroundColor,
                        pointBorderWidth: 1,
                        pointHoverRadius: 8,
                        pointHoverBackgroundColor: "#233A48",
                        pointHoverBorderColor: "#233A48",
                        pointHoverBorderWidth: 2,
                        pointRadius: 4,
                        pointHitRadius: 10,
                    },
                ],
                labels: this.monthYearsLabels,
            };

            this.renderChart(invoicesEvolutionBarData, invoicesEvolutionBarOptions);
        },
    },
    computed: {
        invoiceReverseDataset: function () {
            return this.userInvoices.slice().reverse();
        },
        conceptThreeYearsEvolution: function () {
            return this.invoiceReverseDataset.map((i) => i[this.concept]);
        },
        consumptionPointsBackgroundColor: function () {
            return this.userInvoices.map((i) => (i.estimatedReading ? "white" : "gray"));
        },
        monthYearsLabels: function () {
            return this.invoiceReverseDataset.map((i) => moment(i.endDate, AB_DATE_FORMAT, "es").format("MMM YYYY").toUpperCase());
        },
    },
    watch: {
        userInvoices: function () {
            console.log("Cambiando datos de facturas en gráfico");
            // this._chart.destroy();
            this.renderInvoiceEvolutionChart();
        },
        concept: function () {
            console.debug("Cambiando conceptos en gráfico");
            // this._chart.destroy();
            this.renderInvoiceEvolutionChart();
        },
    },
    mounted() {
        this.renderInvoiceEvolutionChart();
        executeAccessibilityFixes();
    },
};
</script>

<style lang="scss"></style>
