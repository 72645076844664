<template>
    <modal :name="modalName" :reset="true" :scrollable="true" :click-to-close="!showAcceptButton" height="auto" width="1024" :adaptive="true">
        <div :id="containerId" v-html="htmlContent" class="move-to modal-politica-privacidad"></div>
        <div class="mb-3 pr-3 w-100 d-flex justify-content-end">
            <button class="boton-anterior text-center mt-2" v-if="showCancelButton" @click="cancelModal">
                <span>{{ cancelButtonText }}</span>
            </button>
            <button class="boton-pasos text-center mt-2" v-if="showAcceptButton" @click="acceptModal">
                <span>{{ acceptButtonText }}</span>
            </button>
        </div>
    </modal>
</template>

<script>
import VModal from "vue-js-modal";
import liferayServiceClient from "@/rest/liferayServiceClient";

export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        VModal,
    },
    props: {
        htmlUrlEs: {
            type: String,
        },
        htmlUrlCa: {
            type: String,
        },
        modalName: {
            default: "legalModal",
            type: String,
        },
        showCancelButton: {
            type: Boolean,
            default: true,
        },
        showAcceptButton: {
            type: Boolean,
            default: true,
        },
        acceptButtonText: {
            type: String,
            default: function () {
                return this.$i18n.tc("common.okButton");
            },
        },
        cancelButtonText: {
            type: String,
            default: function () {
                return this.$i18n.tc("ofex.UserData.cancel");
            },
        },
    },
    data: function () {
        return {
            htmlContent: "",
            containerId: "",
            screenshot: null,
        };
    },
    created() {
        this.containerId = "modalContainer-" + Math.floor(Math.random() * 100);
        console.debug("Getting remote html");
        var vueContext = this;
        let url = this.$i18n.locale === "ca" ? this.htmlUrlCa : this.htmlUrlEs;
        liferayServiceClient.getLegalHtml(url).then((html) => (vueContext.htmlContent = html));

        // this.getHtml(url, function (response) {
        //     let result = response.documentElement.innerHTML;
        //     vueContext.htmlContent = result.replace("<head></head><body>", "").replace("</body>", "");
        // });
    },
    methods: {
        cancelModal: function () {
            console.debug("Cancel modal");
            this.$modal.hide(this.modalName);
            this.$emit("modalresult", false);
        },
        acceptModal: function () {
            console.debug("Aceptar modal");
            this.$modal.hide(this.modalName);
            this.$emit("modalresult", true);
        },
        getHtml(url, callback) {
            // Feature detection
            if (!window.XMLHttpRequest) {
                console.warn("XMLHTTPREQUEST not supported");
                return;
            }

            // Create new request
            var xhr = new XMLHttpRequest();

            // Setup callback
            xhr.onload = function () {
                if (callback && typeof callback === "function") {
                    callback(this.responseXML);
                }
            };

            // Get the HTML
            xhr.open("GET", url);
            xhr.responseType = "document";
            xhr.send();
        },
    },
};
</script>
<style lang="scss" scoped>
.move-to {
    margin-top: 20px;
}
.boton-anterior {
    cursor: pointer;
    //padding-top: 11px;
    width: 193px;
    height: 47px;
    border: 0px solid $border-table;
    background-color: transparent;
    margin-right: 8px;
    border-radius: 5px;
    font-weight: $font-primary-bold;
}
.boton-anterior:hover {
    cursor: pointer;
    background-color: $blue-darker;
    color: $color-lightest;
    border-radius: 5px;
}
.boton-anterior:disabled {
    border: 0px solid $border-table;
    background-color: $color-primary;
    opacity: 50%;
    cursor: none;
}
.boton-pasos {
    cursor: pointer;
    //padding-top: 11px;
    width: 193px;
    height: 47px;
    color: $color-lightest;
    font-weight: $font-primary-bold;
    font-size: 16px;
    background-color: $blue-darker;
    border-radius: 5px;
    margin-right: 4px;
}
.boton-pasos:hover {
    cursor: pointer;
    border: 1px solid $blue-darker;
    background-color: transparent;
    color: $blue-darker;
}
.boton-pasos:disabled {
    color: $color-lightest;
    border: 0px solid $blue-darker;
    background-color: $color-primary;
    opacity: 50%;
    cursor: none;
    margin-right: 4px;
}
</style>
