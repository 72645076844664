<template>
    <div v-if="contractDetail">
        <div class="zone-data pl-4 pr-4 pt-3 mt-4 pb-3">
            <!-- Datos del contrato -->
            <h3 class="title-detail border-blue">{{ $t("ofex.modificarDatosContrato.datosContrato") }}</h3>
            <p class="element-blue contrast mt-4">
                {{ $t("ofex.modificarDatosContrato.titularContrato")
                }}<span
                    class="element-black"
                    v-html="isCompany ? clientData.name : userData.firstName + ' ' + userData.firstLastName + ' ' + userData.secondLastName"
                ></span>
            </p>
            <p class="element-blue contrast">
                {{ $t("ofex.modificarDatosContrato.nifTitular")
                }}<span class="element-black" v-html="isCompany ? clientData.documentNumber : userData.documentNumber"></span>
            </p>
            <p class="element-blue contrast">
                {{ $t("ofex.modificarDatosContrato.numeroContrato")
                }}<span class="element-black"
                    ><strong>{{ contract.contractDetail.contractNumber }}</strong></span
                >
            </p>
            <p class="element-blue contrast">
                {{ $t("ofex.modificarDatosContrato.aliasContrato") }}<span class="element-black">{{ contract.contractDetail.alias }}</span>
            </p>
            <p class="element-blue contrast">
                {{ $t("ofex.detalleContrato.direccionSuministro")
                }}<span class="element-black"
                    >{{ supplyStreetTypeTranslation() }} {{ contractDetail.supplyAddress.streetName }}, {{ contractDetail.supplyAddress.number }},
                    {{ contractDetail.supplyAddress.otherAddressData }}, {{ contractDetail.supplyAddress.municipality }}</span
                >
            </p>
            <p class="element-blue contrast">
                {{ $t("ofex.modificarDatosContrato.DireccionCorrespondencia")
                }}<span class="element-black">{{ mailStreetTypeTranslation() }} {{ contractDetail.mailAddress.otherAddressData }}</span>
            </p>
            <p class="element-blue contrast">
                {{ $t("ofex.detalleContrato.tipoUso") }}<span class="element-black">{{ useTypeCodeTranslation() }}</span>
            </p>
            <!--    <p class="element-blue" v-if="false">
                {{ $t("ofex.detalleContrato.formaPago")
                }}<span class="element-black">{{ $t("ofex.backendCodes.paymentMethod." + methodPaymentBackendCode()) }}</span>
            </p>
            <p class="element-blue" v-if="false">
                {{ $t("ofex.detalleContrato.iban") }}<span class="element-black">{{ changeIBAN() }}</span>
            </p> -->
        </div>
    </div>
</template>
<script>
import * as actionTypes from "@/store/action-types";
import { mapState, mapGetters } from "vuex";
import { trimBackendCode } from "@/plugins/ab-utils";
export default {
    name: "AbDetailMonoContract",
    props: {
        selectedContract: {
            required: true,
            default: "",
        },
    },
    data: function () {
        return {};
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "contracts", "contractDetail", "selectedContractNumber"]),
        ...mapGetters(["getContractByContractNumber", "isCompany"]),
        contract: function () {
            return this.getContractByContractNumber(this.selectedContract);
        },
    },
    mounted() {
        this.contractNumber = this.$route.params.contractId;
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, triggerChangeActiveContractLoading: false });
        this.$store.dispatch(actionTypes.GET_CONTRACT_DETAIL_ACTION, {
            contractNumber: this.selectedContract,
        });
    },
    methods: {
        supplyStreetTypeTranslation: function () {
            return this.$t("ofex.backendCodes.streetTypes." + trimBackendCode(this.contractDetail.supplyAddress.streetType));
        },
        mailStreetTypeTranslation: function () {
            return this.$t("ofex.backendCodes.streetTypes." + trimBackendCode(this.contractDetail.mailAddress.streetType));
        },
        supplyStatusTranslation: function () {
            return this.$t("ofex.backendCodes.supplyStatus." + this.supplyStatusBackendCode());
        },
        supplyStatusBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.supplyStatus);
        },
        supplyTypeTranslation: function () {
            return this.$t("ofex.backendCodes.supplyType." + this.supplyTypeBackendCode());
        },
        supplyTypeBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.supplyTypeCode);
        },
        useTypeCodeTranslation: function () {
            return this.$t("ofex.backendCodes.useType." + this.useTypeCodeBackendCode());
        },
        useTypeCodeBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.useTypeCode);
        },
        methodPaymentBackendCode: function () {
            return trimBackendCode(this.contractDetail.paymentData.paymentMethod);
        },
        changeIBAN: function () {
            var iban = this.contractDetail.paymentData.iban;
            let firstSection = iban.substring(2, 4);
            let secondSection = iban.substring(21, 23);
            let thirdSection = iban.substring(35, 39);
            iban = iban.replace(firstSection, "**");
            iban = iban.replace(secondSection, "**");
            iban = iban.replace(thirdSection, "****");
            return iban;
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/contract-modification.scss";
.contrast {
    color: $blue-darker;
}
</style>
