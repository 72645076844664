<template>
    <div class="container">
        <div class="row d-flex justify-content-between">
            <div class="ab-home ab-home1-color text-center">
                <div class="box-icon-mosaico pt-4">
                    <img src="../../assets/images/login-mosaico/home1.png" />
                </div>
                <div>
                    <span class="title-box-mosaico">Tu servicio del agua</span>
                </div>
                <div>
                    <span class="subtitle-box-mosaico">Toda la información sobre el servicio y gestiones online</span>
                </div>
            </div>
            <div class="ab-home ab-home2-color text-center mt-3 mt-md-0">
                <div class="box-icon-mosaico pt-4">
                    <img src="../../assets/images/login-mosaico/home2.png" />
                </div>
                <div>
                    <span class="title-box-mosaico">El agua en tu ciudad</span>
                </div>
                <div>
                    <span class="subtitle-box-mosaico">Conoce mejor el agua que llevamos a tu casa</span>
                </div>
            </div>
            <div class="ab-home ab-home3-color text-center mt-3 mt-xl-0">
                <div class="box-icon-mosaico pt-4">
                    <img src="../../assets/images/login-mosaico/home3.png" />
                </div>
                <div>
                    <span class="title-box-mosaico">Explora, educa y participa</span>
                </div>
                <div>
                    <span class="subtitle-box-mosaico">Súmate a nuestras propuestas y actividades</span>
                </div>
            </div>
            <div class="ab-home ab-home4-color text-center mt-3 mt-xl-0">
                <div class="box-icon-mosaico pt-4">
                    <img src="../../assets/images/login-mosaico/home4.png" />
                </div>
                <div>
                    <span class="title-box-mosaico">Contacto</span>
                </div>
                <div>
                    <span class="subtitle-box-mosaico">Contacta con nosotros cuando lo necesites</span>
                </div>
            </div>
        </div>
        <!-- 2 fila mosaico -->
        <div class="row mt-45 d-flex justify-content-between">
            <div class="box-image-mosaico">
                <div class="image-mosaico ab-img-home1"></div>
                <div class="title-mosaico-img-ofex text-center mt-30">
                    <span>Portal de transparencia</span>
                </div>
                <div class="text-mosaico-img-ofex text-center mt-2">
                    <span>Un espacio dedicado a la transparencia</span>
                </div>
            </div>
            <div class="box-image-mosaico mt-2 mt-xl-0">
                <div class="image-mosaico ab-img-home2"></div>
                <div class="title-mosaico-img-ofex text-center mt-30">
                    <span>¿Quierés saber más?</span>
                </div>
                <div class="text-mosaico-img-ofex text-center mt-2">
                    <span>Descubre los artículos de nuestro blog</span>
                </div>
            </div>
            <div class="box-image-mosaico mt-2 mt-xl-0">
                <div class="image-mosaico ab-img-home3"></div>
                <div class="title-mosaico-img-ofex text-center mt-30">
                    <span>Analítica de tu agua</span>
                </div>
                <div class="text-mosaico-img-ofex text-center mt-2">
                    <span>Agua del grifo 100% segura</span>
                </div>
            </div>
            <div class="box-image-mosaico mt-2 mt-xl-0">
                <div class="image-mosaico ab-img-home4"></div>
                <div class="title-mosaico-img-ofex text-center mt-30">
                    <span>Obras y afectaciones</span>
                </div>
                <div class="text-mosaico-img-ofex text-center mt-2">
                    <span>Conoce cómo mejoramos la red</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AbLoginMosaico",
};
</script>

<style lang="scss" scoped>
.ab-home {
    width: 24%;
    padding: 0 15px 15px;
    height: 250px;
    margin: auto;
    border-radius: 5px;
}
//hasta md
@include respond-below(md) {
    .ab-home {
        width: 93%;
    }
}
@media (min-width: 768px) and (max-width: 1199px) {
    .ab-home {
        width: 48%;
    }
}
.ab-home1-color {
    background-color: $blue-dark;
}
.ab-home2-color {
    background-color: #b45304;
}
.ab-home3-color {
    background-color: #4cbad8;
}
.ab-home4-color {
    background-color: #279399;
}
.ab-img-home1 {
    background-image: url("../../assets/images/login-mosaico/transparencia-aigues-barcelona.jpg");
}
.ab-img-home2 {
    background-image: url("../../assets/images/login-mosaico/fotos-blog.jpg");
}
.ab-img-home3 {
    background-image: url("../../assets/images/login-mosaico/b3.jpg");
}
.ab-img-home4 {
    background-image: url("../../assets/images/login-mosaico/foto-averias.jpg");
}
.box-icon-mosaico {
    height: 45%;
}
.title-box-mosaico {
    font-weight: $font-primary-extraBold;
    @include line-height(32);
    @include font-size(30);
    color: $color-lightest;
}
.subtitle-box-mosaico {
    font-weight: $font-primary-medium;
    @include line-height(22);
    @include font-size(18);
    color: $color-lightest;
}
.title-mosaico-img-ofex {
    font-weight: $font-primary-extraBold;
    color: black;
    @include font-size(24);
    @include line-height(26);
}
.text-mosaico-img-ofex {
    font-weight: $font-primary-medium;
    @include font-size(16);
    @include line-height(20);
}
.mt-45 {
    margin-top: 45px;
}
.mt-30 {
    margin-top: 30px;
}
.image-mosaico {
    height: 250px;
    width: 100%;
    border-radius: 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.box-image-mosaico {
    width: 24%;
    margin: auto;
}
//hasta md
@include respond-below(md) {
    .box-image-mosaico {
        width: 93%;
    }
}
@media (min-width: 768px) and (max-width: 1199px) {
    .box-image-mosaico {
        width: 48%;
    }
}
</style>
