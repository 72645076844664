"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import { trimBackendCode } from "@/plugins/ab-utils";
const ACTIVITY_CODE = "PM";
export default class PaymentModifyPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId, contract) {
        super("ModificacionDomiciliacionContrato.pdf", ACTIVITY_CODE, i18n, userId, clientId, contract);
    }
    generatePdf(formModel, clientData, contractDetail, selectContract) {
        const SPACE = " ";
        const POINTS = ": ";

        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.modificarDatosContrato.titlePdf"), "");
        this.generatePdfSection(this.i18n.tc("ofex.modificarDatosContrato.detail"));
        this.generateLineText(
            this.i18n.tc("ofex.modificarDatosContrato.titularContrato") +
                clientData.name +
                SPACE +
                `${clientData.firstLastName != null ? clientData.firstLastName : ""}` +
                SPACE +
                `${clientData.secondLastName != null ? clientData.secondLastName : ""}`
        );
        this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.nifTitular") + clientData.documentNumber);
        this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.numeroContrato") + selectContract);
        this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.estadoSuministro") + contractDetail.contractDetail.supplyStatus);
        this.generateLineText(
            this.i18n.tc("ofex.modificarDatosContrato.direccionSuministro") +
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(contractDetail.supplyAddress.streetType)) +
                SPACE +
                contractDetail.supplyAddress.streetName +
                SPACE +
                contractDetail.supplyAddress.number +
                SPACE +
                contractDetail.supplyAddress.otherAddressData +
                SPACE +
                contractDetail.supplyAddress.municipality +
                SPACE
        );

        let dataAddress = "";
        let otherAddressData = contractDetail.mailAddress.otherAddressData;

        if (otherAddressData == null || otherAddressData == "") {
            dataAddress = contractDetail.mailAddress.floor + " " + contractDetail.mailAddress.door;
        }
        if (contractDetail.mailAddress.streetName != null && contractDetail.mailAddress.streetName != "") {
            dataAddress = contractDetail.mailAddress.streetName;
        }
        if (contractDetail.mailAddress.number != null && contractDetail.mailAddress.number != "") {
            dataAddress = dataAddress + SPACE + contractDetail.mailAddress.number;
        }
        dataAddress = dataAddress + SPACE + otherAddressData;
        if (contractDetail.mailAddress.municipality != null && contractDetail.mailAddress.municipality != "") {
            dataAddress = dataAddress + SPACE + contractDetail.mailAddress.municipality;
        }

        this.generateLineText(
            this.i18n.tc("ofex.modificarDatosContrato.datosCorrespondencia") +
                POINTS +
                this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(contractDetail.mailAddress.streetType)) +
                dataAddress
        );

        this.generateLineText(
            this.i18n.tc("ofex.modificarDatosContrato.tipoSuministro") +
                this.i18n.tc("ofex.backendCodes.supplyType." + trimBackendCode(contractDetail.contractDetail.supplyTypeCode))
        );
        this.generatePdfSection(this.i18n.tc("ofex.modificarDatosContrato.titularCuenta"), "");
        this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.tipoDocumento") + formModel.paymentData.doc.type);
        this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.nDocumento") + formModel.paymentData.doc.number);
        this.generateLineText(
            this.i18n.tc("ofex.modificarDatosContrato.nombreTitularCuenta") +
                SPACE +
                formModel.paymentData.name +
                SPACE +
                `${formModel.paymentData.firstLastName != null ? formModel.paymentData.firstLastName : ""}` +
                SPACE +
                `${formModel.paymentData.secondLastName != null ? formModel.paymentData.secondLastName : ""}`
        );
        this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.tlfPrincipal") + formModel.paymentData.mainPhone);
        this.generatePdfSection(this.i18n.tc("ofex.modificarDatosContrato.datosBancarios"), "");

        this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.pais") + formModel.dataBank.countryTitle);
        this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.iban") + formModel.dataBank.iban);
        if (formModel.dataBank.bic != "") {
            this.generateLineText(this.i18n.tc("ofex.detalleContrato.bic") + formModel.dataBank.bic);
        }
        if (contractDetail.contractDetail.musa) {
            this.generateLineText(this.i18n.tc("ofex.modificarDatosContrato.cesionDatos") + SPACE + `${formModel.dataBank.musaRadio ? "Si" : "No"}`);
        }
        return this.pdf;
    }
}
