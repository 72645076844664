var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-3",attrs:{"aria-hidden":"true"}},[_c('ab-select',{attrs:{"name":_vm.validationTypeName,"input-alias":_vm.typeInputAlias,"placeholder":_vm.$t('idTypeNumber.tipoDocPlaceholder'),"validation":{ required: true },"data-list":[
                { key: 'NIF', text: 'NIF' },
                { key: 'NIE', text: 'NIE' },
                { key: 'CIF', text: 'CIF' },
            ],"editable":false,"show-row":false,"label":_vm.$t('idTypeNumber.tipoDocumento')},model:{value:(_vm.typeValue),callback:function ($$v) {_vm.typeValue=$$v},expression:"typeValue"}})],1),_c('div',{staticClass:"col-12 col-lg-3"},[_c('ab-input',{attrs:{"name":_vm.validationNumberName,"input-alias":_vm.numberInputAlias,"placeholder":_vm.allowNifNie && _vm.allowCif
                    ? _vm.$t('idTypeNumber.numDocPlaceholder')
                    : _vm.allowCif
                    ? _vm.$t('idTypeNumber.empresaNumDocPlaceholder')
                    : _vm.$t('idTypeNumber.onlyNifPlaceholder'),"validation-delay":1000,"validation":{ required: true, spanishdocument: { allowNifNie: _vm.allowNifNie, allowCif: _vm.allowCif } },"label":_vm.labelNumberDocument,"readonly":_vm.readonly,"event-blur":_vm.eventBlur},model:{value:(_vm.numberValue),callback:function ($$v) {_vm.numberValue=$$v},expression:"numberValue"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }