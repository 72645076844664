<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div v-if="concatedErrorList.length > 0" class="error-headers alert" role="alert">
        <h4 class="alert-heading">{{ messageHeader ? messageHeader : $t("errorHeader.debeCorregir") }}</h4>
        <ul>
            <!--            Creamos un Set para evitar los duplicados-->
            <li :key="error" v-for="error in concatedErrorList">{{ error }}</li>
        </ul>
    </div>
</template>

<script>
import { mapState } from "vuex";
import uniq from "lodash/uniq";
import concat from "lodash/concat";
import compact from "lodash/compact";

export default {
    props: {
        errorList: {
            type: Array,
            required: true,
        },
        messageHeader: {
            type: String,
        },
        includeRestError: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            lastErrorScrolledCount: 0,
        };
    },
    computed: {
        ...mapState(["restError"]),
        concatedErrorList: function () {
            return this.includeRestError ? compact(uniq(concat(this.errorList, this.restError))) : compact(uniq(this.errorList));
        },
    },
    mounted() {
        console.debug("Error header MOUNTED");
    },
    updated() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.alert {
    background-color: #feefef;
    border: 1px solid #f48f8f;
    padding: 15px 20px;

    .alert-heading {
        @include font-size(16);
        @include line-height(19);
        font-weight: $font-primary-medium;
        color: $error-color;
        margin-bottom: 15px;
    }
    ul {
        list-style: initial;
        margin-left: 40px;
        margin-bottom: 0;

        li {
            color: $error-color;
            @include font-size(14);
            @include line-height(16);
        }
    }
}
</style>
