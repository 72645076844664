<template>
    <div class="legal-ur-modal-container">
        <p class="subtitle-change">{{ $t("ofex.urModal.queHaCambiado") }}</p>
        <p class="text mb-3">{{ reason }}</p>
        <ValidationObserver :ref="'legalUrModal' + legalType" v-slot="{ reset, errors }">
            <ab-legal-section
                ref="legalSection"
                :fsp-es="legalUrls.fspEs"
                :fsp-ca="legalUrls.fspCa"
                :gdpr-es="legalUrls.gdprEs"
                :gdpr-ca="legalUrls.gdprCa"
                :legal-es="legalUrls.urEs"
                :legal-ca="legalUrls.urCa"
                :show-gdpr="false"
                :show-fsp="legalType === 'FSP'"
                :show-legal="legalType === 'LEGAL'"
                :float-left="false"
                :required-gdpr="false"
                :required-legal="legalType === 'LEGAL' && requiredAction"
                :required-fsp="legalType === 'FSP' && requiredAction"
            ></ab-legal-section>
            <p v-if="Object.values(errors).length > 0 && Object.values(errors)[0] && Object.values(errors)[0].length > 0" class="formTextErr displayYes">
                {{ Object.values(errors)[0][0] }}
            </p>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import * as viewMappingTypes from "../../router/view-mapping-types";
export default {
    name: "AbLegalUrModal",
    props: {
        modalName: {
            type: String,
            required: true,
        },
        legalType: {
            type: String,
            required: true,
        },
        htmlUrlEs: {
            type: String,
            required: true,
        },
        htmlUrlCa: {
            type: String,
            required: true,
        },
        requiredAction: {
            type: Boolean,
            required: true,
        },
    },
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
        };
    },
    computed: {
        ...mapState(["userData"]),
        ...mapGetters(["legalUrls"]),
        reason: function () {
            return this.$i18n.locale === "es" ? this.spanishReason() : this.catReason();
        },
    },
    methods: {
        spanishReason: function () {
            return this.legalType === "FSP" ? this.userData.fspTerms.reasonEs : this.userData.legalTerms.reasonEs;
        },
        catReason: function () {
            return this.legalType === "FSP" ? this.userData.fspTerms.reasonCat : this.userData.legalTerms.reasonCat;
        },
        legalLabel: function () {
            return this.legalType === "FSP" ? this.$i18n.tc("ofex.facturaDigital.condicionesFSP") : this.$i18n.tc("ofex.urModal.condicionesOfexLinkedText");
        },
        // legalLabel: function () {
        //   let text = this.legalType === "FSP" ? this.$i18n.tc("ofex.facturaDigital.condicionesFSP") : this.$i18n.tc("ofex.urModal.condicionesOfexLinkedText");
        //   if (this.requiredAction === true) {
        //     text += " *";
        //   }
        //   return text;
        // },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.subtitle-change {
    color: $blue-dark;
    font-weight: $font-primary-bold;
    @include font-size(21);
}
.text {
    font-weight: $font-primary;
    @include font-size(16);
}
.link {
    color: $bg-primary;
    text-decoration: underline;
}
.text-conditions {
    font-weight: $font-primary-semiBold;
    @include font-size(14);
}
</style>
