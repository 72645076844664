import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../i18n";
import * as mutationTypes from "../store/mutation-types";
import * as viewMappingTypes from "./view-mapping-types";

import WelcomeAppView from "../views/WelcomeAppView.vue";
import HomeView from "../views/HomeView";
import LoginView from "../views/LoginView";
import LoginPasswordlessView from "../views/LoginPasswordlessView";
import PasswordRecoveryView from "../views/PasswordRecoveryView";
import PasswordRecoveryPublicView from "@/views/PasswordRecoveryPublicView";
import InvoiceDetailView from "../views/InvoiceDetailView";
import InvoiceCheckDetailView from "../views/InvoiceCheckDetailView";
import InvoicesView from "../views/InvoicesView";
import LastInvoicesView from "../views/LastInvoicesView.vue";
import RectifyingInvoicesView from "../views/RectifyingInvoicesView";
import InvoicePaymentByContractView from "../views/InvoicePaymentByContractView";
import ContractDetailView from "../views/ContractDetailView";
import ContractsView from "../views/ContractsView";
import EmailRecoveryView from "../views/EmailRecoveryView.vue";
import EmailRecoveryConfirmView from "../views/EmailRecoveryConfirmView.vue";
import ConsumptionsDetailView from "../views/ConsumptionsDetailView";
import LastReadingsDetailView from "../views/LastReadingsDetailView";
import InvoicePaymentView from "../views/InvoicePaymentView";
import InvoicePaymentCompletedView from "../views/InvoicePaymentCompletedView";
import InvoicePaymentErrorView from "../views/InvoicePaymentErrorView";
import ConfirmationUnsuscribeContractView from "../views/ConfirmationUnsuscribeContractView";
import UnsuscribeContractSelectorView from "../views/UnsuscribeContractSelectorView";
import UnsuscribeContractView from "../views/UnsuscribeContractView";
import UserDataView from "../views/UserDataView";
import InvoiceDigitalView from "../views/InvoiceDigitalView";
import NotificationsView from "../views/NotificationsView";
import NotificationsDetailView from "../views/NotificationsDetailView";
import NotificationConfigurationView from "../views/NotificationConfigurationView";
import ContractsSelectionView from "../views/ContractsSelectionView";
import InvoiceEvolutionView from "../views/InvoiceEvolutionView";
import ModificationPaymentDataView from "../views/ModificationPaymentDataView";
import ModificationCorrespondenceDataView from "../views/ModificationCorrespondenceDataView";
import ModificationOwnerDataView from "../views/ModificationOwnerDataView";
import InsertCertificateOfOccupancyView from "../views/InsertCertificateOfOccupancyView";
import InsertIaeDataView from "../views/InsertIaeDataView";
import UserActionsView from "../views/UserActionsView";
import ModificationContractSelectorView from "../views/ModificationContractSelectorView";
import ReclamationFormView from "../views/ReclamationFormView";
import ReadMeterSelector from "../views/ReadMeterSelectorView";
import ReadMeterFormView from "../views/ReadMeterFormView";
import ModifyPaymentRedirectionView from "@/views/redirections/ModifyPaymentRedirectionView";
import NativeAppInfo from "../views/NativeAppInfo";
import NativeAppOfficesInfo from "../views/NativeAppOfficesInfo";
import AppSettingsView from "../views/AppSettingsView";
import store from "../store/store";
import * as actionTypes from "@/store/action-types";
import MonitorServiceClient from "@/rest/monitorServiceClient";
import { GET_PROFILE_ACTION } from "@/store/action-types";
import { REDIRECT_AFTER_LOGIN_PARAM } from "@/plugins/const";
import AuthorizationsView from "@/views/AuthorizationsView";
import AuthorizationCreateView from "@/views/AuthorizationCreateView";
import AuthorizationsByContractView from "@/views/AuthorizationsByContractView";
import AuthorizationsByPersonView from "@/views/AuthorizationsByPersonView";
import { cryptoSha256 } from "@/plugins/ab-utils";
import { executeAccessibilityFixes } from "@/plugins/accesibility";
import { WELCOME_SHOWED_PREFERENCE_KEY } from "@/services/services-constant-types";
import { Capacitor } from "@capacitor/core";
import AffectationsContractsView from "@/views/AffectationsContractsView.vue";
import AffectationsContractsDetailView from "@/views/AffectationsContractsDetailView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: viewMappingTypes.WELCOME_APP_VIEW_MAPPING.path,
        name: viewMappingTypes.WELCOME_APP_VIEW_MAPPING.name,
        component: WelcomeAppView,
        meta: {},
        checkRootContingencias: false,
    },
    {
        path: viewMappingTypes.HOME_VIEW_MAPPING.path,
        name: viewMappingTypes.HOME_VIEW_MAPPING.name,
        component: HomeView,
        meta: {
            breadcrumb: i18n.tc("ofex.breadcrumb.HOME_VIEW_MAPPING"),
        },
        checkRootContingencias: true,
        checkContingenciasSystems: ["crm"],
    },
    {
        path: viewMappingTypes.LOGIN_VIEW_MAPPING.path,
        name: viewMappingTypes.LOGIN_VIEW_MAPPING.name,
        component: LoginView,
        meta: {
            loadNotifications: false,
            breadcrumb: {
                label: "Login",
            },
            checkRootContingencias: false,
            biometricsLogin: false,
        },
    },
    {
        path: viewMappingTypes.LOGIN_TOUCHID_VIEW_MAPPING.path,
        name: viewMappingTypes.LOGIN_TOUCHID_VIEW_MAPPING.name,
        component: LoginView,
        meta: {
            loadNotifications: false,
            breadcrumb: {
                label: "Login",
            },
            checkRootContingencias: false,
            biometricsLogin: true,
            biometricsType: 1,
        },
    },
    {
        path: viewMappingTypes.LOGIN_TOUCHID_EMPRESA_VIEW_MAPPING.path,
        name: viewMappingTypes.LOGIN_TOUCHID_EMPRESA_VIEW_MAPPING.name,
        component: LoginView,
        meta: {
            loadNotifications: false,
            breadcrumb: {
                label: "Login",
            },
            checkRootContingencias: false,
            biometricsLogin: true,
            biometricsType: 1,
            typeSelected: "empresas",
        },
    },
    {
        path: viewMappingTypes.REGISTERED_TOUCHID_VIEW_MAPPING.path,
        name: viewMappingTypes.REGISTERED_TOUCHID_VIEW_MAPPING.name,
        component: LoginView,
        meta: {
            loadNotifications: false,
            breadcrumb: {
                label: "Login",
            },
            checkRootContingencias: false,
            biometricsRegistered: true,
        },
    },
    {
        path: viewMappingTypes.LOGIN_FACEID_VIEW_MAPPING.path,
        name: viewMappingTypes.LOGIN_FACEID_VIEW_MAPPING.name,
        component: LoginView,
        meta: {
            loadNotifications: false,
            breadcrumb: {
                label: "Login",
            },
            checkRootContingencias: false,
            biometricsLogin: true,
            biometricsType: 2,
        },
    },
    {
        path: viewMappingTypes.LOGIN_PASSWORDLESS_VIEW_MAPPING.path,
        name: viewMappingTypes.LOGIN_PASSWORDLESS_VIEW_MAPPING.name,
        component: LoginPasswordlessView,
        meta: {
            breadcrumb: {
                label: "LoginPasswordless",
            },
        },
    },
    {
        path: viewMappingTypes.PASSWORD_RECOVERY_MAPPING.path,
        name: viewMappingTypes.PASSWORD_RECOVERY_MAPPING.name,
        component: PasswordRecoveryView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.PASSWORD_RECOVERY_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.PASSWORD_RECOVERY_PUBLIC_MAPPING.path,
        name: viewMappingTypes.PASSWORD_RECOVERY_PUBLIC_MAPPING.name,
        component: PasswordRecoveryPublicView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.PASSWORD_RECOVERY_PUBLIC_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.EMAIL_RECOVERY_MAPPING.path,
        name: viewMappingTypes.EMAIL_RECOVERY_MAPPING.name,
        component: EmailRecoveryView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.EMAIL_RECOVERY_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.EMAIL_RECOVERY_CONFIRM_MAPPING.path,
        name: viewMappingTypes.EMAIL_RECOVERY_CONFIRM_MAPPING.name,
        component: EmailRecoveryConfirmView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.EMAIL_RECOVERY_CONFIRM_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.INVOICE_DETAIL_MAPPING.path,
        name: viewMappingTypes.INVOICE_DETAIL_MAPPING.name,
        component: InvoiceDetailView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICE_DETAIL_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.INVOICE_CHECK_DETAIL_MAPPING.path,
        name: viewMappingTypes.INVOICE_CHECK_DETAIL_MAPPING.name,
        component: InvoiceCheckDetailView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICE_CHECK_DETAIL_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.INVOICES_LIST_MAPPING.path,
        name: viewMappingTypes.INVOICES_LIST_MAPPING.name,
        component: InvoicesView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICES_LIST_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
            checkRootContingencias: true,
            checkContingenciasSystems: ["crm", "sicab"],
        },
    },
    {
        path: viewMappingTypes.RECTIFYING_INVOICES_LIST_MAPPING.path,
        name: viewMappingTypes.RECTIFYING_INVOICES_LIST_MAPPING.name,
        component: RectifyingInvoicesView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.RECTIFYING_INVOICES"),
                parent: viewMappingTypes.INVOICES_LIST_MAPPING.name,
            },
            checkRootContingencias: true,
            checkContingenciasSystems: ["sicab"],
        },
    },
    {
        path: viewMappingTypes.CONSUMPTIONS_DETAIL_MAPPING.path,
        name: viewMappingTypes.CONSUMPTIONS_DETAIL_MAPPING.name,
        component: ConsumptionsDetailView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.CONSUMPTIONS_DETAIL_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
            loadNotifications: false,
            checkRootContingencias: true,
            checkContingenciasSystems: ["mdm", "crm", "sicab"],
        },
    },
    {
        path: viewMappingTypes.AFFECTATIONS_CONTRACTS_MAPPING.path,
        name: viewMappingTypes.AFFECTATIONS_CONTRACTS_MAPPING.name,
        component: AffectationsContractsView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.AFFECTATIONS_CONTRACTS_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
            loadNotifications: false,
            checkRootContingencias: true,
            checkContingenciasSystems: ["mdm", "crm", "sicab"],
        },
    },
    {
        path: viewMappingTypes.AFFECTATIONS_CONTRACTS_DETAIL_MAPPING.path,
        name: viewMappingTypes.AFFECTATIONS_CONTRACTS_DETAIL_MAPPING.name,
        component: AffectationsContractsDetailView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.AFFECTATIONS_CONTRACTS_DETAIL_MAPPING"),
                parent: viewMappingTypes.AFFECTATIONS_CONTRACTS_MAPPING.name,
            },
            loadNotifications: false,
            checkRootContingencias: true,
            checkContingenciasSystems: ["mdm", "crm", "sicab"],
        },
    },
    {
        path: viewMappingTypes.LAST_READINGS_DETAIL_MAPPING.path,
        name: viewMappingTypes.LAST_READINGS_DETAIL_MAPPING.name,
        component: LastReadingsDetailView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.LAST_READINGS_DETAIL_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.CONTRACTS_LIST_MAPPING.path,
        name: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
        component: ContractsView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.CONTRACTS_LIST_MAPPING"),
                parent: viewMappingTypes.HOME_VIEW_MAPPING.name,
            },
        },
        beforeEnter: (to, from, next) => {
            console.log("Verificando si el usuario es multicontrato");
            if (isContractLoaded() && !isMulticontract()) {
                console.log("Redireccionando al detalle del contrato");

                next(`/tuscontratos/${getActiveContractNumber()}`);
            } else {
                next();
            }
        },
    },
    {
        path: viewMappingTypes.CONTRACT_DETAIL_MAPPING.path,
        name: viewMappingTypes.CONTRACT_DETAIL_MAPPING.name,
        component: ContractDetailView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.CONTRACT_DETAIL_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.UNSUSCRIBE_CONTRACT_SELECTOR_VIEW_MAPPING.path,
        name: viewMappingTypes.UNSUSCRIBE_CONTRACT_SELECTOR_VIEW_MAPPING.name,
        component: UnsuscribeContractSelectorView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.UNSUSCRIBE_CONTRACT_SELECTOR_VIEW_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.UNSUSCRIBE_CONTRACT_VIEW_MAPPING.path,
        name: viewMappingTypes.UNSUSCRIBE_CONTRACT_VIEW_MAPPING.name,
        component: UnsuscribeContractView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.UNSUSCRIBE_CONTRACT_VIEW_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.NOTIFICATIONS_LIST_MAPPING.path,
        name: viewMappingTypes.NOTIFICATIONS_LIST_MAPPING.name,
        component: NotificationsView,
        meta: {
            loadNotifications: false,
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.NOTIFICATIONS_LIST_MAPPING"),
                parent: viewMappingTypes.HOME_VIEW_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.NOTIFICATION_DETAIL_MAPPING.path,
        name: viewMappingTypes.NOTIFICATION_DETAIL_MAPPING.name,
        component: NotificationsDetailView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.NOTIFICATION_DETAIL_MAPPING"),
                parent: viewMappingTypes.NOTIFICATIONS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.path,
        name: viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.name,
        component: InvoicePaymentByContractView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICE_PAYMENT_BY_CONTRACTS"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
            checkRootContingencias: true,
            checkContingenciasSystems: ["crm", "sicab", "payments"],
        },
    },
    {
        path: viewMappingTypes.INVOICE_PAYMENT.path,
        name: viewMappingTypes.INVOICE_PAYMENT.name,
        component: InvoicePaymentView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICE_PAYMENT"),
                parent: viewMappingTypes.INVOICES_LIST_MAPPING.name,
            },
            checkRootContingencias: true,
            checkContingenciasSystems: ["crm", "sicab", "payments"],
        },
    },
    {
        path: viewMappingTypes.EVOLUTION_INVOICES_MAPPING.path,
        name: viewMappingTypes.EVOLUTION_INVOICES_MAPPING.name,
        component: InvoiceEvolutionView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.EVOLUTION_INVOICES_MAPPING"),
                parent: viewMappingTypes.INVOICES_LIST_MAPPING.name,
            },
            checkContingenciasSystems: ["crm", "sicab"],
        },
    },
    {
        path: viewMappingTypes.LAST_INVOICES_MAPPING.path,
        name: viewMappingTypes.LAST_INVOICES_MAPPING.name,
        component: LastInvoicesView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.LAST_INVOICES_MAPPING"),
                parent: viewMappingTypes.INVOICES_LIST_MAPPING.name,
            },
            checkContingenciasSystems: ["crm", "sicab"],
        },
    },
    {
        path: viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.path,
        name: viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.name,
        component: ModificationPaymentDataView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.MODIFICATION_PAYMENT_DATA_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.path,
        name: viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.name,
        component: ModificationCorrespondenceDataView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.MODIFICATION_CORRESPONDENCE_DATA_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.MODIFICATION_OWNER_DATA_MAPPING.path,
        name: viewMappingTypes.MODIFICATION_OWNER_DATA_MAPPING.name,
        component: ModificationOwnerDataView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.MODIFICATION_OWNER_DATA_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.path,
        name: viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name,
        component: InsertCertificateOfOccupancyView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.INSERT_IAE_DATA_MAPPING.path,
        name: viewMappingTypes.INSERT_IAE_DATA_MAPPING.name,
        component: InsertIaeDataView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INSERT_IAE_DATA_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.INVOICE_DETAIL_VIEW_MAPPING.path,
        name: viewMappingTypes.INVOICE_DETAIL_VIEW_MAPPING.name,
        component: InvoiceDetailView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICE_DETAIL_VIEW_MAPPING"),
                parent: viewMappingTypes.INVOICES_LIST_MAPPING.name,
            },
            checkRootContingencias: true,
            checkContingenciasSystems: ["crm", "sicab"],
        },
    },
    {
        path: viewMappingTypes.INVOICE_DIGITAL_MAPPING.path,
        name: viewMappingTypes.INVOICE_DIGITAL_MAPPING.name,
        component: InvoiceDigitalView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICE_DIGITAL_MAPPING"),
                parent: viewMappingTypes.INVOICES_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.INVOICE_PAYMENT_COMPLETED.path,
        name: viewMappingTypes.INVOICE_PAYMENT_COMPLETED.name,
        component: InvoicePaymentCompletedView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICE_PAYMENT_COMPLETED"),
                parent: viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.name,
            },
        },
    },
    {
        path: viewMappingTypes.INVOICE_PAYMENT_ERROR.path,
        name: viewMappingTypes.INVOICE_PAYMENT_ERROR.name,
        component: InvoicePaymentErrorView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICE_PAYMENT_ERROR"),
                parent: viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.name,
            },
        },
    },
    {
        path: viewMappingTypes.CONFIRMATION_UNSUSCRIBE_CONTRACT_MAPPING.path,
        name: viewMappingTypes.CONFIRMATION_UNSUSCRIBE_CONTRACT_MAPPING.name,
        component: ConfirmationUnsuscribeContractView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.CONFIRMATION_UNSUSCRIBE_CONTRACT_MAPPING"),
                parent: viewMappingTypes.UNSUSCRIBE_CONTRACT_VIEW_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.MODIFICATION_CONTRACT_SELECTOR_MAPPING.path,
        name: viewMappingTypes.MODIFICATION_CONTRACT_SELECTOR_MAPPING.name,
        component: ModificationContractSelectorView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.MODIFICATION_CONTRACT_SELECTOR_MAPPING"),
                parent: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.INVOICE_DIGITAL_MAPPING.path,
        name: viewMappingTypes.INVOICE_DIGITAL_MAPPING.name,
        component: InvoiceDigitalView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.INVOICE_DIGITAL_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.CONTRACTS_SELECTION_CONFIGURATION_MAPPING.path,
        name: viewMappingTypes.CONTRACTS_SELECTION_CONFIGURATION_MAPPING.name,
        component: ContractsSelectionView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.CONTRACTS_SELECTION_CONFIGURATION_MAPPING"),
                parent: viewMappingTypes.HOME_VIEW_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.USER_VIEW_DATA_MAPPING.path,
        name: viewMappingTypes.USER_VIEW_DATA_MAPPING.name,
        component: UserDataView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.USER_VIEW_DATA_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.CONFIGURATION_NOTICES_MAPPING.path,
        name: viewMappingTypes.CONFIGURATION_NOTICES_MAPPING.name,
        component: NotificationConfigurationView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.CONFIGURATION_NOTICES_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.READ_METER_FORM_MAPPING.path,
        name: viewMappingTypes.READ_METER_FORM_MAPPING.name,
        component: ReadMeterFormView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.READ_METER_FORM_MAPPING"),
            },
            checkRootContingencias: true,
            checkContingenciasSystems: ["crm", "sicab"],
        },
    },
    {
        path: viewMappingTypes.RECLAMATION_FORM_MAPPING.path,
        name: viewMappingTypes.RECLAMATION_FORM_MAPPING.name,
        component: ReclamationFormView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.RECLAMATION_FORM_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.READ_METER_SELECTOR_MAPPING.path,
        name: viewMappingTypes.READ_METER_SELECTOR_MAPPING.name,
        component: ReadMeterSelector,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.READ_METER_SELECTOR_MAPPING"),
            },
        },
    },
    {
        path: viewMappingTypes.USER_ACTIONS_MAPPING.path,
        name: viewMappingTypes.USER_ACTIONS_MAPPING.name,
        component: UserActionsView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.USER_ACTIONS_MAPPING"),
                parent: viewMappingTypes.HOME_VIEW_MAPPING.name,
            },
            loadNotifications: false,
        },
    },
    {
        path: viewMappingTypes.MODIFY_PAYMENT_REDIRECTION_MAPPING.path,
        name: viewMappingTypes.MODIFY_PAYMENT_REDIRECTION_MAPPING.name,
        component: ModifyPaymentRedirectionView,
        meta: {
            loadNotifications: false,
            checkRootContingencias: false,
        },
    },
    {
        path: viewMappingTypes.AUTHORIZATIONS_VIEW_MAPPING.path,
        name: viewMappingTypes.AUTHORIZATIONS_VIEW_MAPPING.name,
        component: AuthorizationsView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.AUTHORIZATIONS_VIEW_MAPPING"),
                parent: viewMappingTypes.HOME_VIEW_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.NEW_AUTHORIZATION_VIEW_MAPPING.path,
        name: viewMappingTypes.NEW_AUTHORIZATION_VIEW_MAPPING.name,
        component: AuthorizationCreateView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.NEW_AUTHORIZATION_VIEW_MAPPING"),
                parent: viewMappingTypes.AUTHORIZATIONS_VIEW_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.AUTHORIZATIONS_BY_CONTRACT_VIEW_MAPPING.path,
        name: viewMappingTypes.AUTHORIZATIONS_BY_CONTRACT_VIEW_MAPPING.name,
        component: AuthorizationsByContractView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.AUTHORIZATIONS_BY_CONTRACT_VIEW_MAPPING"),
                parent: viewMappingTypes.AUTHORIZATIONS_VIEW_MAPPING.name,
            },
        },
    },
    {
        path: viewMappingTypes.AUTHORIZATIONS_BY_PERSON_VIEW_MAPPING.path,
        name: viewMappingTypes.AUTHORIZATIONS_BY_PERSON_VIEW_MAPPING.name,
        component: AuthorizationsByPersonView,
    },
    {
        path: viewMappingTypes.NATIVE_APP_INFORMATION.path,
        name: viewMappingTypes.NATIVE_APP_INFORMATION.name,
        component: NativeAppInfo,
        meta: {
            loadNotifications: false,
            checkRootContingencias: false,
        },
    },
    {
        path: viewMappingTypes.NATIVE_APP_OFFICES_INFORMATION.path,
        name: viewMappingTypes.NATIVE_APP_OFFICES_INFORMATION.name,
        component: NativeAppOfficesInfo,
        meta: {
            loadNotifications: false,
            checkRootContingencias: false,
        },
    },
    {
        path: viewMappingTypes.APP_SETTINGS_VIEW_MAPPING.path,
        name: viewMappingTypes.APP_SETTINGS_VIEW_MAPPING.name,
        component: AppSettingsView,
        meta: {
            breadcrumb: {
                label: i18n.tc("ofex.breadcrumb.APP_SETTINGS_VIEW_MAPPING"),
            },
        },
    },
];

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    let exclusions = process.env.VUE_APP_AUTH_EXCLUSIONS.split(",");
    console.debug("Global beforeEach");
    console.debug(`Exclusiones: ${exclusions}`);
    console.debug(`Yendo a: ${to.name}`);

    recoverUserDataFromStorageCookies();
    recoverOfexTokenPayloadFromQueryParam(to);

    checkAndSetContingenciasStatus(to);

    let welcomeShowed = Vue.$cookies.get(WELCOME_SHOWED_PREFERENCE_KEY);
    console.debug("Detectado carrusel welcome ya visitado: " + welcomeShowed);

    if (
        Capacitor.isNativePlatform() &&
        Capacitor.getPlatform() === "android" &&
        sessionStorage.getItem(WELCOME_SHOWED_PREFERENCE_KEY) == null &&
        welcomeShowed == null
    ) {
        console.debug("Redireccionando a la pantalla de bienvenida");

        //TODO guardar versión en vez de true para ser capaces de mostrar welcome cuando se actualice la versión
        Vue.$cookies.set(WELCOME_SHOWED_PREFERENCE_KEY, "true", null, "", "", process.env.VUE_APP_SECURE_COOKIES === "true", "Strict");
        sessionStorage.setItem(WELCOME_SHOWED_PREFERENCE_KEY, "true");

        next({ name: viewMappingTypes.WELCOME_APP_VIEW_MAPPING.name });
    } else {
        //si vamos al Login y ya estamos logados debemos redirigir a la Home
        if ((!to.name || to.name === viewMappingTypes.LOGIN_VIEW_MAPPING.name) && isAuthenticated()) {
            console.debug("Redireccionando a la home");
            next({ name: viewMappingTypes.HOME_VIEW_MAPPING.name });
        } else if (!exclusions.includes(to.name) && !isAuthenticated()) {
            if (to.name !== null && to.name !== viewMappingTypes.LOGIN_VIEW_MAPPING.name) {
                console.debug("Almacenando parámetro para redirigir después del login");
                sessionStorage.setItem(REDIRECT_AFTER_LOGIN_PARAM, to.path);
            }

            console.debug("Moviendo al Login");
            next({ name: viewMappingTypes.LOGIN_VIEW_MAPPING.name });
        } else {
            console.debug("Moviendo sin anomalías");
            window._paq.push(["setUserId", store?.getters?.isAuthenticated ? cryptoSha256(store?.getters?.userId) : undefined]);
            next();
        }
    }
});

router.afterEach(() => {
    // noinspection EqualityComparisonWithCoercionJS
    if (process.env.VUE_APP_CANCEL_REST_PENDING_OPERATIONS === "true") {
        store.dispatch(actionTypes.CANCEL_ALL_PENDING_REST_OPERATIONS).then(() => null);
    }

    executeAccessibilityFixes();
});

function isMulticontract() {
    return store.getters.multicontract;
}

function isContractLoaded() {
    return store.getters.contractsLoaded;
}

function isAuthenticated() {
    return store.getters.isAuthenticated;
}

function getActiveContractNumber() {
    let activeContract = store.getters.getContractByContractNumber(store.state.selectedContractNumber);
    return activeContract.contractDetail.contractNumber;
}

function recoverUserDataFromStorageCookies() {
    //si alguna de la información no la tenemos en memoria se debe recuperar de las cookies
    if (!(store.state.userTokenPayload && store.state.userData && store.state.clientData)) {
        store.commit(mutationTypes.RECOVER_PERSISTED_AUTH_DATA_MUTATION);
    }
}

function recoverOfexTokenPayloadFromQueryParam(route) {
    let queryTokenPayload = route.query.ofexTokenPayload;
    console.debug("Query param JWT obtenido " + queryTokenPayload);
    if (queryTokenPayload) {
        let payloadObject = JSON.parse(atob(queryTokenPayload));
        let userId = store.getters.customUserId(payloadObject.name);
        let clientId = store.getters.customClientId(payloadObject.name);
        console.debug("Almacenando token. ClientId :" + clientId + " and userId: " + userId);
        store.commit(mutationTypes.STORE_TOKEN_MUTATION, { token: payloadObject, alreadyDecoded: true });
        store.dispatch(GET_PROFILE_ACTION, { userId, clientId });
    }
}

function checkContingenciasOk(route, contigenciasStatus) {
    if (route.meta.checkRootContingencias !== false) {
        let systemsToVerify = route.meta.checkContingenciasSystems ? route.meta.checkContingenciasSystems : ["crm"];

        return systemsToVerify.map((x) => contigenciasStatus[x]).reduce((previous, next) => previous && next);
    } else {
        console.debug("Saltando verificación de contingencias");
        return true;
    }
}

function checkAndSetContingenciasStatus(to) {
    if (process.env.VUE_APP_CHECK_CONTINGENCIAS === "true") {
        MonitorServiceClient.checkContingencias(i18n.locale).then(function (contingenciasResult) {
            store.commit(mutationTypes.CHANGE_CONTINGENCIAS_STATUS_MUTATION, { contigenciasStatus: contingenciasResult });
            store.commit(mutationTypes.CHANGE_CONTINGENCIAS_OK, { contigenciasOk: checkContingenciasOk(to, store.state.contigenciasStatus) });
        });
    }
}

export default router;
