<template>
    <div>
        <b-container>
            <b-row>
                <ab-left-nav-bar />
                <b-col cols="12" md="12" lg="9">
                    <!-- Main title -->
                    <ab-main-title :main-text="$t('ofex.forms.reclamations.title')" />
                    <!-- lectura del contador -->
                    <reclamation-form-app
                        v-if="hasAnyActiveContract === true"
                        :is-loading="isLoading"
                        :client-data="clientData"
                        :user-data="userData"
                        :selected-contract="selectedContractNumber"
                        :selected-invoice="selectedInvoiceNumber"
                        :is-authenticated="isAuthenticated"
                    ></reclamation-form-app>
                    <ab-without-data class="mt-3" v-else :text="$t('ofex.forms.reclamations.noActiveContracts')" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import ReclamationFormApp from "../forms/ReclamationFormApp";
import { mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
export default {
    name: "ReclamationFormView",
    components: { ReclamationFormApp },
    data: function () {
        return {};
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
    },
    computed: {
        ...mapState(["isLoading", "clientData", "userData", "selectedContractNumber", "selectedInvoiceNumber", "activeContracts"]),
        ...mapGetters(["isAuthenticated", "hasAnyActiveContract"]),
    },
};
</script>
<style lang="scss" scoped></style>
