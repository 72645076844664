<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="row mb-3 mt-3">
        <div class="col-12 franja-grey">
            <div class="float-left mt-2 ml-1">
                <label :for="name" class="text-franja-grey">
                    {{ label }}
                </label>
            </div>
            <div class="float-left ml-1">
                <input :id="name" v-model="modelValue" type="checkbox" :name="name" class="mt-2" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: String,
        label: {
            type: String,
            required: true,
        },
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["inputValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.text-franja-grey {
    font-weight: $font-primary-bold;
    @include font-size(16);
}
input[type="checkbox"] {
    cursor: pointer;
    height: 26px;
    width: 26px;
}
.franja-grey {
    width: 100%;
    height: 44px;
    background-color: $input-read;
}
</style>
