<template>
    <div class="m-main-title">
        <h1 class="h1 title">
            {{ mainText }} <span class="title-name">{{ username }}</span
            >{{ exclamation }}
        </h1>
        <span v-if="!routeConfigNotices" class="right-item">{{ rightText }}</span>
        <router-link v-if="routeConfigNotices" :to="{ name: viewMappingTypes.CONFIGURATION_NOTICES_MAPPING.name }">
            <span class="right-item">{{ rightText }}</span>
        </router-link>
    </div>
</template>

<script>
import * as viewMappingTypes from "../router/view-mapping-types";
export default {
    name: "AbMainTitle",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
        };
    },
    props: {
        routeConfigNotices: {
            required: false,
            type: Boolean,
            default: false,
        },
        username: {
            type: String,
            required: false,
        },
        mainText: {
            type: String,
            required: false,
        },
        exclamation: {
            type: String,
            required: false,
        },
        rightText: {
            type: String,
            required: false,
        },
        viewConfigNotices: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.m-main-title {
    border-bottom: 1px solid $blue-dark;
    padding-bottom: 12px;

    .h1.title {
        color: $color-dark;
        @include font-size(36);
        @include line-height(42);

        .title-name {
            color: $blue-lightest;
        }
    }
}
</style>
