<template>
    <div class="m-title-module box">
        <div class="title-module-img" v-if="srcImg">
            <img alt="" class="img-section" :src="srcImg" />
        </div>
        <div class="title-module-content">
            <h2 class="h2 title" :class="subTitle ? '' : 'title-alone'">{{ titleSection }}</h2>
            <p v-if="subTitle" class="h4 subtitle">{{ textSection }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        srcImg: {
            type: String,
            required: false,
        },
        titleSection: {
            type: String,
            required: true,
        },
        subTitle: {
            type: Boolean,
            default: false,
        },
        textSection: {
            type: String,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.m-title-module {
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
    border-top: 1px solid $blue-dark;

    @include respond-above(sm) {
        align-items: center;
    }

    .title-module-img {
        margin-right: 10px;
        flex: 0 0 auto;

        @include respond-above(sm) {
            flex: 0 auto;
        }

        .img-section {
            width: 80px;
            height: 80px;
        }
    }
    .title-module-content {
        .title {
            font-weight: $font-primary-bold;
            color: $blue-dark;

            &.title-alone {
                margin-top: 21px;

                @include respond-above(sm) {
                    margin-top: 0;
                }
            }
        }
    }
}
</style>
