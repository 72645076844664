import qs from "qs";
import store from "@/store/store";
import * as mutationTypes from "@/store/mutation-types";
import { SET_REST_ERROR } from "@/store/mutation-types";
import Vue from "vue";
import BaseOfexServiceClient from "./BaseOfexServiceClient";

const PAYMENTS_BASE_URL = process.env.VUE_APP_API_PAYMENTS_BASE_URL;

export default {
    getGatewayDataByContract: async function (
        clientId,
        userId,
        contractNumber,
        pendingInvoicesNumbers,
        emailUser,
        paymentType,
        internalId,
        lang = "es",
        lastMonths = 60
    ) {
        try {
            console.log("Obteniendo datos para plataforma de pago");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(
                `${PAYMENTS_BASE_URL}/payments/gateways/request-generator-by-contract`,
                {
                    contractNumber,
                    pendingInvoicesNumbers,
                    emailUser,
                    paymentType,
                },
                {
                    params: {
                        clientId: clientId,
                        userId: userId,
                        contractNumber: contractNumber,
                        lang: lang,
                        lastMonths: lastMonths,
                        internalId: internalId,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log(response.data);
                //añadimos o borramos el contrato favorito de la lista local
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    getGatewayDataByInvoice: async function (clientId, userId, contractNumber, invoiceNumber, invoiceBarcode, emailUser, paymentType, internalId, lang = "es") {
        try {
            console.log("Obteniendo datos para plataforma de pago para factura simple");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(
                `${PAYMENTS_BASE_URL}/payments/gateways/request-generator-by-invoice`,
                {
                    contractNumber,
                    invoiceNumber,
                    invoiceBarcode,
                    emailUser,
                    paymentType,
                },
                {
                    params: {
                        clientId: clientId,
                        userId: userId,
                        contractNumber: contractNumber,
                        lang: lang,
                        internalId: internalId,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log(response.data);
                //añadimos o borramos el contrato favorito de la lista local
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    confirmationPayment: async function (
        clientId,
        userId,
        contractNumber,
        pendingInvoicesNumbers,
        email,
        amount,
        dsMerchantOrder,
        dsMerchantProductDescription,
        operation,
        pdfDocumentId,
        lang = "es"
    ) {
        try {
            console.log("Procediendo a confirmar el pago en Siebel, operation:" + operation);
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(
                `${PAYMENTS_BASE_URL}/payments/gateways/confirmation`,
                {
                    pendingInvoicesNumbers: pendingInvoicesNumbers,
                    email: email,
                    amount: amount,
                    dsMerchantOrder: dsMerchantOrder,
                    dsMerchantProductDescription: dsMerchantProductDescription,
                    pdfDocumentId: pdfDocumentId,
                },
                {
                    params: {
                        clientId: clientId,
                        userId: userId,
                        contractNumber: contractNumber,
                        lang: lang,
                        operation: operation,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log("Completada confirmación del pago en Siebel, contrato:" + contractNumber);
                //Borramos la cookie de la pago
                Vue.$cookies.remove("ofexUserPayment");
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
            Vue.$cookies.remove("ofexUserPayment");
        }
    },
    modifyPayment: async function (userId, clientId, contractNumber, formModel, pdf, lang = "es") {
        console.log("Proceso modificación de datos de pago");
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.put(
            `${PAYMENTS_BASE_URL}/payments/data`,
            {
                language: lang,
                paymentHolder: {
                    firstName: formModel.paymentData.name,
                    middleName: formModel.paymentData.firstLastName,
                    lastName: formModel.paymentData.secondLastName,
                    documentNumber: formModel.paymentData.doc.number,
                    documentType: formModel.paymentData.doc.type,
                    mainPhone: formModel.paymentData.mainPhone,
                    email: formModel.paymentData.email,
                    pdf: pdf,
                },
                bankAccount: {
                    accountType: "iban",
                    country: formModel.dataBank.countryTitle,
                    iban: formModel.dataBank.iban,
                    bic: formModel.dataBank.bic,
                    musa: formModel.dataBank.musaRadio,
                },
            },
            {
                params: {
                    contractNumber: contractNumber,
                    userId: userId,
                    clientId: clientId,
                    lang: lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            console.log(response.data);
            //Modificamos el usuario
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
};
