<template>
    <div>
        <p class="title-information">{{ $t("ofex.facturaDigital.activadoServicio") }}</p>
        <p class="text-conditions">
            {{ $t("ofex.facturaDigital.puedesConsultar") }}
            <span @click="openModal()" class="link-service">{{ $t("ofex.facturaDigital.confirmacion.prestacionServicio") }}</span>
        </p>
    </div>
</template>
<script>
export default {
    name: "AbInformationMessage",
    data() {
        return {};
    },
    methods: {
        openModal: function () {
            this.$modal.show("fsp-modal");
        },
    },
};
</script>
<style lang="scss" scoped>
.link-service {
    color: $blue-href;
    cursor: pointer;
}
.title-information {
    @include font-size(18);
    @include line-height(30);
    font-weight: $font-primary-bold;
}
.text-conditions {
    @include font-size(14);
    @include line-height(30);
    font-weight: $font-primary-medium;
}
</style>
