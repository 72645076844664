<template>
    <b-container v-if="!selectedInactiveContract">
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.modificarDatosCorrespondencia.title')" />
                <ab-return />
                <!-- detalle del monocontrato -->
                <ab-detail-mono-contract :selected-contract="this.$route.params.contractId" class="mb-4"></ab-detail-mono-contract>
                <p class="normal-detail">{{ $t("ofex.modificarDatosContrato.camposObligatorios") }}</p>
                <input
                    type="checkbox"
                    :title="$t('ofex.bajaContratoDetalle.mismaDireccion')"
                    :aria-label="$t('ofex.bajaContratoDetalle.mismaDireccion')"
                    v-model="formModel.sameSupplyAddress"
                    name="payer-holder"
                    class="mt-2 space-checkbox"
                />
                <ValidationObserver ref="addressForm" v-slot="{ invalid }">
                    <label for="payer-holder" class="normal-detail ml-2 mt-2">{{ $t("ofex.bajaContratoDetalle.mismaDireccion") }}</label>
                    <!-- No se marca el check de la misma dirección de suministro-->
                    <div class="mt-3" v-if="!formModel.sameSupplyAddress">
                        <ab-carrerer
                            v-model="formModel.supplyAddress.carrerer"
                            carrerer-name="data-of-correspondence"
                            :show-province="true"
                            :api-domain="carrererBaseUrl"
                        />
                    </div>
                    <!-- se marca el check de la misma dirección de suministro-->
                    <div v-else>
                        <div class="row mt-3">
                            <div class="col-12 col-lg-4">
                                <ab-input
                                    autocomplete="address-level1"
                                    v-model="formModel.supplyAddress.carrerer.province.name"
                                    name="province-supplyAddress"
                                    placeholder=""
                                    :readonly="true"
                                    v-bind:label="$t('carrerer.labels.provincia')"
                                ></ab-input>
                            </div>
                        </div>
                        <div class="row mt-5">
                            <div class="col-12 col-lg-4">
                                <ab-input
                                    autocomplete="address-level2"
                                    v-model="formModel.supplyAddress.carrerer.municipality.name"
                                    name="municipality-supplyAddress"
                                    placeholder=""
                                    :readonly="true"
                                    v-bind:label="$t('carrerer.labels.municipio')"
                                ></ab-input>
                            </div>
                            <div class="col-12 col-lg-3">
                                <ab-input
                                    v-model="formModel.supplyAddress.carrerer.streetType.name"
                                    name="streetType-supplyAddress"
                                    placeholder=""
                                    :readonly="true"
                                    v-bind:label="$t('carrerer.labels.tipoVia')"
                                ></ab-input>
                            </div>
                            <div class="col-12 col-lg-3">
                                <ab-input
                                    autocomplete="address-line1"
                                    v-model="formModel.supplyAddress.carrerer.streetName"
                                    name="streetName-supplyAddress"
                                    placeholder=""
                                    :readonly="true"
                                    v-bind:label="$t('carrerer.labels.nombreVia')"
                                ></ab-input>
                            </div>
                            <div class="col-12 col-lg-2">
                                <ab-input
                                    autocomplete="address-line2"
                                    v-model="formModel.supplyAddress.carrerer.number"
                                    name="number-supplyAddress"
                                    placeholder=""
                                    :readonly="true"
                                    v-bind:label="$t('carrerer.labels.numeroVia')"
                                ></ab-input>
                            </div>
                        </div>
                        <div class="row mt-5 mb-5">
                            <div class="col-12 col-lg-2">
                                <ab-input
                                    autocomplete="address-line3"
                                    v-model="formModel.supplyAddress.otherAddressData"
                                    name="apartament-supplyAddress"
                                    placeholder=""
                                    :readonly="true"
                                    v-bind:label="$t('ofex.modificarDatosContrato.pisoPuerta')"
                                ></ab-input>
                            </div>
                            <div class="col-12 col-lg-3">
                                <ab-input
                                    autocomplete="postal-code"
                                    v-model="formModel.supplyAddress.carrerer.cp"
                                    name="postalCode-supplyAddress"
                                    placeholder=""
                                    :readonly="true"
                                    v-bind:label="$t('carrerer.labels.cp')"
                                ></ab-input>
                            </div>
                        </div>
                    </div>
                    <!-- Cesión de datos MUSA -->
                    <div v-if="contractDetail">
                        <ab-radio
                            v-if="contractDetail.contractDetail.musa"
                            v-model="formModel.supplyAddress.musaRadio"
                            name="MUSA-corrrespondence"
                            v-bind:title="$t('ofex.modificarDatosContrato.cesionDatos')"
                            v-bind:validation="{ required: true }"
                            v-bind:description="$t('ofex.modificarDatosContrato.exigenciaReglamento')"
                        >
                        </ab-radio>
                    </div>

                    <!-- botones enviar FORM-->
                    <div class="line-bottom mt-3 mb-3"></div>
                    <div class="w-100 d-flex justify-content-end align-items-center mb-space">
                        <div class="btn-send">
                            <ab-button-ofex
                                :text-button="$t('ofex.modificarDatosContrato.enviar')"
                                :click-event="modify"
                                :disabled="invalid"
                                :show-popover="invalid"
                                :popover="$t('formularios.buttom.sendMessage')"
                            ></ab-button-ofex>
                        </div>
                    </div>
                </ValidationObserver>
                <ab-text-end name="textos-finales"></ab-text-end>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.modificarDatosCorrespondencia.title')" />
                <ab-without-data class="mt-3" :text="$t('ofex.modificationContractSelector.noActiveSelectedContracts')" />
                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { emptyCarrerer } from "@/plugins/ab-utils";
import { trimBackendCode } from "@/plugins/ab-utils";
import * as actionTypes from "@/store/action-types";
import Swal from "sweetalert2";
import MailAddressModifyPdfGenerator from "@/generators/pdf/MailAddressModifyPdfGenerator";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import AbDetailMonoContract from "../modules/contracts/modification/AbDetailMonoContract.vue";
import AbReturn from "../components/AbReturn.vue";
export default {
    name: "ModificationCorrespondenceData",
    components: {
        AbDetailMonoContract,
        AbReturn,
    },
    data() {
        return {
            carrererBaseUrl: process.env.VUE_APP_API_CARRERER_BASE_URL,
            selectedContract: "",
            contract: "",
            supplyAddressStreetType: "",
            contrat: null,
            formModel: {
                sameSupplyAddress: false,
                supplyAddress: {
                    carrerer: emptyCarrerer(),
                    otherAddressData: "",
                    musaRadio: null,
                },
            },
        };
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "contracts", "contractDetail", "selectedContractNumber"]),
        ...mapGetters([
            "getContractByContractNumber",
            "multicontract",
            "isCompany",
            "getInvoiceByInvoiceNumber",
            "clientId",
            "userId",
            "selectedInactiveContract",
        ]),
        supplyAddressOrCarrerer: function () {
            return this.formModel.sameSupplyAddress ? this.formModel.supplyAddress : this.formModel.correspondenceData;
        },
    },
    mounted() {
        this.selectedContract = this.$route.params.contractId;
        //Validamos si contrato está en Vigor
        if (!this.selectedInactiveContract) {
            this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, triggerChangeActiveContractLoading: false }).then(() => {
                console.debug("Validando si el contrato es modificable");
            });
            this.$store.dispatch(actionTypes.GET_CONTRACT_DETAIL_ACTION, {
                contractNumber: this.$route.params.contractId,
            });
            this.contract = this.getContractByContractNumber(this.$route.params.contractId);
            //Si venimos del enlace de factura digital para cerrar la modal
            Swal.close();
        }
    },
    methods: {
        streetTypeSupplyAddressCodeTranslation: function () {
            return this.$t("ofex.backendCodes.streetTypes." + this.streetTypeSupplyAddressBackendCode());
        },
        streetTypeSupplyAddressBackendCode: function () {
            return trimBackendCode(this.contractDetail.supplyAddress.streetType);
        },
        supplyStatusTranslation: function () {
            return this.$t("ofex.backendCodes.supplyStatus." + this.supplyStatusBackendCode());
        },
        supplyStatusBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.supplyStatus);
        },
        supplyTypeTranslation: function () {
            return this.$t("ofex.backendCodes.supplyType." + this.supplyTypeBackendCode());
        },
        supplyTypeBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.supplyTypeCode);
        },
        useTypeCodeTranslation: function () {
            return this.$t("ofex.backendCodes.useType." + this.useTypeCodeBackendCode());
        },
        useTypeCodeBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.useTypeCode);
        },
        methodPaymentBackendCode: function () {
            return trimBackendCode(this.contractDetail.paymentData.paymentMethod);
        },
        dumpInfoFormModel: function () {
            this.formModel.supplyAddress.carrerer.province.name = this.contractDetail.supplyAddress.province;
            this.formModel.supplyAddress.carrerer.municipality = { name: this.contractDetail.supplyAddress.municipality };
            this.formModel.supplyAddress.carrerer.streetType = {
                code: this.contractDetail.supplyAddress.streetType,
                name: this.streetTypeSupplyAddressCodeTranslation(),
            };
            this.formModel.supplyAddress.carrerer.streetName = this.contractDetail.supplyAddress.streetName;
            this.formModel.supplyAddress.carrerer.number = this.contractDetail.supplyAddress.number;
            this.formModel.supplyAddress.otherAddressData = this.contractDetail.supplyAddress.floor;
            this.formModel.supplyAddress.carrerer.cp = this.contractDetail.supplyAddress.postalCode;
        },
        deleteInfoFormModel: function () {
            (this.formModel.supplyAddress.province = ""),
                (this.formModel.supplyAddress.carrerer.municipality.name = ""),
                (this.formModel.supplyAddress.carrerer.streetType.code = ""),
                (this.formModel.supplyAddress.carrerer.streetName = ""),
                (this.formModel.supplyAddress.carrerer.number = ""),
                (this.formModel.supplyAddress.otherAddressData = ""),
                (this.formModel.supplyAddress.carrerer.cp = ""),
                (this.formModel.supplyAddress.carrerer.piso = ""),
                (this.formModel.supplyAddress.carrerer.puerta = ""),
                (this.formModel.supplyAddress.carrerer.escalera = "");
        },
        modify: function () {
            let validator = this.$refs.addressForm;
            validator.validate().then(async (result) => {
                if (result) {
                    console.debug("Modificación de correspondecia contrato:" + this.selectedContract);
                    let pdfGenerator = new MailAddressModifyPdfGenerator(this.$i18n, this.userId, this.clientId, this.selectedContract);
                    pdfGenerator.generatePdf(this.formModel, this.contractDetail, this.selectedContract, this.userData);
                    await pdfGenerator.uploadPdf();
                    console.log("PDF ID" + pdfGenerator.uploadedDocumentId);

                    this.$store
                        .dispatch(actionTypes.UPDATE_MAIL_ADDRESS, {
                            clientId: this.clientId,
                            userId: this.userId,
                            contractNumber: this.selectedContract,
                            formModel: this.formModel,
                            userData: this.userData,
                            pdf: pdfGenerator.uploadedDocumentId,
                            activity: pdfGenerator.activityId,
                        })
                        .then((value) => {
                            Swal.fire({
                                showCloseButton: true,
                                showCancelButton: true,
                                text: this.$t("formularios.modificarCorrespondencia.sendMessage"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                                cancelButtonText: this.$t("common.descargarPDF"),
                            }).then(async (result) => {
                                this.$notifyFormActionToMatomo(true, "correspondece_modification");
                                console.debug("Datos Modificados" + value);
                                if (!result.isConfirmed) {
                                    pdfGenerator.downloadPdf();
                                }
                            });
                        })
                        .catch((reason) => {
                            this.$notifyFormActionToMatomo(false, "correspondece_modification", reason?.response?.data.errorMessage);
                            this.launchUpdateMailAddress(reason);
                        });
                }
            });
        },
        launchUpdateMailAddress: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "UserDataView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
    },
    watch: {
        "formModel.sameSupplyAddress": function (val) {
            if (val) {
                this.dumpInfoFormModel();
            } else {
                this.deleteInfoFormModel();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/contract-modification.scss";
</style>
