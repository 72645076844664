<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <validation-provider :name="inputAlias" :vid="`${name}-vid`" :rules="validation" :debounce="validationDelay" v-slot="{ errors, classes }">
        <div class="row c-multiselect">
            <div class="col-12 select-component">
                <label class="select-label" :for="name">{{ label }}</label>
                <slot name="remote-reading"></slot>
                <multiselect
                    :id="name"
                    v-model="modelValue"
                    :select-label="$t('autocompletableSelect.selectLabel')"
                    :show-labels="false"
                    :options="dataList"
                    :placeholder="placeholder"
                    :track-by="dataKey"
                    :label="dataLabel"
                    :name="name"
                    :class="errors.length > 0 ? 'fatherFormError' : ''"
                    :disabled="readonly"
                    :allow-empty="allowEmpty"
                    @select="resendEvent"
                >
                    <span slot="noResult">{{ $t("autocompletableSelect.noResult") }}</span>
                    <span slot="noOptions">{{ $t("autocompletableSelect.noResult") }}</span>
                    <template v-if="pendingOfexSlot" slot="option" slot-scope="props">
                        <span :class="{ 'invoices-pending': props.option.pending }">{{ props.option[dataLabel] }}</span>
                    </template>
                    <template v-if="inactiveOfexSlot" slot="option" slot-scope="props">
                        <span :class="{ 'inactive-contract': props.option.inactive }">{{ props.option[dataLabel] }}</span>
                    </template>
                </multiselect>

                <ab-popover :popover="popover" />
            </div>

            <div v-show="errors.length > 0" class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="formTextErr displayYes">{{ errors[0] }}</p>
                    </div>
                </div>
            </div>
        </div>
    </validation-provider>
</template>

<script>
import Multiselect from "vue-multiselect";
import AbPopover from "./AbPopover.vue";

export default {
    components: {
        Multiselect,
        AbPopover,
    },
    model: {
        prop: "selectValue",
        event: "change",
    },
    props: {
        name: String,
        inputAlias: String,
        popover: String,
        label: String,
        placeholder: String,
        validation: Object,
        validationDelay: {
            type: Number,
            default: 300,
        },
        dataList: Array,
        dataKey: String,
        dataLabel: String,
        readonly: {
            default: false,
            type: Boolean,
        },
        allowEmpty: {
            default: true,
            type: Boolean,
        },
        pendingOfexSlot: {
            default: false,
            type: Boolean,
        },
        inactiveOfexSlot: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        modelValue: {
            get: function () {
                let value = this.$attrs["selectValue"];
                return value;
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    methods: {
        resendEvent: function (selectedOption, id) {
            console.debug(`Resend event to outer component. Selected option ${selectedOption} with ID ${id}`);
            this.$emit("autocompletableSelected", selectedOption);
        },
    },
    mounted: function () {
        this.$nextTick(function () {});
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.select-component {
    @include respond-below(md) {
        margin-bottom: 20px;
    }
}

.multiselect__tags {
    border: 1px solid #979797 !important;
    height: 40px !important;
    min-height: 0px !important;
    padding-top: 3px !important;
    border-radius: 8px !important;
    padding-left: 7px !important;
    @include respond-above(sm) {
        height: 32px !important;
        padding-top: 0px !important;
    }
}
.fatherFormError {
    .multiselect__tags {
        border: 1px solid #f48989 !important;
        background-color: #feefef;
    }
}

.multiselect__select {
    z-index: 10 !important;
    height: 40px !important;
    @include respond-above(sm) {
        height: 32px !important;
    }
}

.multiselect--disabled .multiselect__select {
    display: none;
}

.multiselect__placeholder {
    margin-top: 1px;
    font-size: 14px !important;
    @include respond-above(sm) {
        margin-top: 3px;
    }
}
.multiselect--active {
    .multiselect__input {
        height: 40px !important;
        margin: 0 !important;
        @include respond-above(sm) {
            height: 32px !important;
        }
    }
}
.multiselect {
    min-height: 0 !important;
}
/*
.multiselector-container >>> .multiselect__tags {
    border: 1px solid #f48989 !important;
    background-color: #feefef !important;
}
*/

.multiselect__single {
    margin-top: 10px !important;
    @include respond-above(sm) {
        margin-top: 6px !important;
    }
}
.select-label {
    @include line-height(1);
}
</style>
