<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <div class="boxgreyForm">
            <div class="h50x">
                <h3 class="h3tarjform mt-2">{{ title }}</h3>
            </div>

            <div class="row mt-3">
                <div class="col-6 offset-3">
                    <img alt="" class="img-fluid" :src="require(`../assets/images/${imageUrl}`)" />
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-1">
                    <validation-provider mode="eager" :name="inputAlias" :vid="`${name}-vid`" :rules="validation" v-slot="{ errors, classes }">
                        <input
                            :id="name"
                            v-model="checkValue"
                            class="checkBoxForm"
                            type="checkbox"
                            :name="name"
                            data-vv-validate-on="input|change"
                            :class="{ 'checkbox-error': errors.length > 0 }"
                            @click="showContrato()"
                        />

                        <div v-if="errors.length > 0" class="row">
                            <div class="col-12">
                                <div class="anch90">
                                    <p v-show="errors.length > 0" class="fontSpecialForm formTextErr displayYes" style="float: left;">
                                        {{ errors[0] }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </validation-provider>

                    <validation-provider mode="eager" :name="inputAlias" :vid="`${docName}-vid`" :rules="{ required: true }" v-slot="{ errors, classes }">
                        <input v-model="urlOpened" type="hidden" :name="docName" :data-vv-as="docName" />

                        <div v-if="errors.length > 0" class="row">
                            <div class="col-12">
                                <div class="anch90">
                                    <p v-show="errors.length > 0" class="fontSpecialForm formTextErr displayYes" style="float: left;">
                                        {{ errors[0] }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </validation-provider>
                </div>

                <div class="col-10 letraCardForm ml-1">
                    {{ legalText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: String,
        inputAlias: String,
        title: String,
        legalText: String,
        docName: String,
        docUrl: String,
        validation: Object,
        imageUrl: {
            type: String,
            default: "../assets/images/cardform1.png",
        },
    },
    data: function () {
        return {
            checkValue: false,
            urlOpened: false,
        };
    },
    watch: {
        checkValue: function (val) {
            console.debug("Changing radio value");
            this.inputValue = val;
            this.$emit("change", this.checkValue);
        },
    },
    methods: {
        showContrato: function () {
            if (!this.urlOpened) {
                window.open(this.docUrl);
                this.urlOpened = true;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.boxgreyForm {
    width: 90%;
    height: 499px;
    border: 1px solid $border-table;
    background-color: #f1f1f1;
    padding: 17px;
}
.h3tarjform {
    color: $blue-dark;
    font-weight: $font-primary-bold;
    @include font-size(16);
}
.letraCardForm {
    color: $blue-dark;
    font-weight: $font-primary-medium;
    @include font-size(14);
}
</style>
