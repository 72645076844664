<script>
import { Bar } from "vue-chartjs";
import moment from "moment";
import { AB_DATE_FORMAT, AB_DATETIME_FORMAT } from "@/plugins/ab-utils";
import ConsumptionsServiceClient from "@/rest/consumptionsServiceClient.js";
import { mapState, mapGetters } from "vuex";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import { executeAccessibilityFixes } from "@/plugins/accesibility";

export default {
    extends: Bar,
    props: {
        filterDates: {
            type: Object,
            required: true,
        },
        consumptionFrequency: {
            type: String,
            default: "DAILY",
        },
        yAxeLabel: {
            type: String,
            default: function () {
                return this.$t("modules.consumptionTimelineChart.litros");
            },
        },
        valuePropertyName: {
            type: String,
            default: "deltaConsumption",
        },
        valueTransformer: {
            type: Function,
            default: function (val) {
                return val * 1000;
            },
        },
        beginAtZero: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
        return {
            userConsumptions: [],
        };
    },
    methods: {
        downloadPdf: function (e, data) {
            console.debug(data);
        },
        loadServiceData: function () {
            let vueContext = this;
            let showNegativeValues = false;
            //solo lo lanzamos si ya no está cargando otros datos la página
            if (!this.isLoading) {
                this.$store.commit(SET_LOADING_MUTATION, { isLoading: true, trigger: "AbConsumptionTimelineChart" });
            }

            // let consumptionsArgs = [this.selectedContractNumber, decodedJWT.clientId, decodedJWT.userId, this.$i18n.locale, this.fromDate, this.toDate];

            if (this.consumptionFrequency === "DAILY") {
                ConsumptionsServiceClient.getDailyConsumptions(
                    this.selectedContractNumber,
                    this.clientId,
                    this.userId,
                    this.$i18n.locale,
                    this.filterDates.fromDate,
                    this.filterDates.toDate,
                    showNegativeValues
                ).then((value) => {
                    this.userConsumptions = value.data;
                    if (this.isLoading) {
                        this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "AbConsumptionTimelineChart" });
                    }
                    vueContext.renderAbChart();
                });
            } else {
                ConsumptionsServiceClient.getHourlyConsumptions(
                    this.selectedContractNumber,
                    this.clientId,
                    this.userId,
                    this.$i18n.locale,
                    this.filterDates.fromDate,
                    this.filterDates.toDate,
                    showNegativeValues
                ).then((value) => {
                    this.userConsumptions = value.data;
                    if (this.isLoading) {
                        this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "AbConsumptionTimelineChart" });
                    }
                    vueContext.renderAbChart();
                });
            }
        },
        renderAbChart: function () {
            console.log("Creando gráfica de evolucion de facturas");
            var vueContext = this;

            let chartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                onClick: this.downloadPdf,
                legend: {
                    display: false,
                    position: "bottom",
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: this.beginAtZero,
                                maxTicksLimit: 5,
                            },
                            scaleLabel: {
                                display: true,
                                labelString: this.yAxeLabel,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            // barThickness: 40,
                            offset: true,
                            type: "time",
                            distribution: "series",
                            time:
                                this.consumptionFrequency === "DAILY"
                                    ? {
                                          timeFormat: AB_DATE_FORMAT,
                                          // https://momentjs.com/docs/#/displaying/format/
                                          displayFormats: {
                                              day: "ddd -  DD/MM/YY",
                                              hour: "ddd -  DD/MM/YY",
                                          },
                                      }
                                    : {
                                          timeFormat: AB_DATE_FORMAT,
                                          // https://momentjs.com/docs/#/displaying/format/
                                      },
                            scaleLabel: {
                                display: true,
                            },
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                padding: 0,
                                minRotation: 90,
                                mirror: true,
                            },
                        },
                    ],
                },
                // https://zenerab.atlassian.net/browse/UATOFEX-182
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                tooltips: {
                    backgroundColor: "#FFF",
                    borderColor: "#417290",
                    borderWidth: 1,
                    titleFontColor: "#417290",
                    callbacks: {
                        label: function () {
                            return "";
                        },
                        title: function (tooltipItem) {
                            console.debug(tooltipItem);
                            let consumptionColumn = vueContext.consumptionDataset[tooltipItem[0].index];
                            return vueContext.$i18n.t("modules.consumptionTimelineChart.chartTooltip", {
                                volume: consumptionColumn.y,
                            });
                        },
                    },
                },
            };

            let charData = {
                datasets: [
                    {
                        data: this.consumptionDataset,
                        hoverBackgroundColor: "#95CAEA",
                        backgroundColor: this.datasetBackgroundColors,
                        label: this.$t("modules.consumptionTimelineChart.consumos"),
                        minBarLength: 5,
                    },
                ],
            };

            this.renderChart(charData, chartOptions);
        },
    },
    computed: {
        ...mapState(["selectedContractNumber", "isLoading"]),
        ...mapGetters(["clientId", "userId"]),
        consumptionDataset: function () {
            return this.userConsumptions.map((i) => {
                return {
                    x: moment(i.datetime, AB_DATETIME_FORMAT, "es"),
                    y: this.valueTransformer.call(null, i[this.valuePropertyName]),
                };
            });
        },
        datasetBackgroundColors: function () {
            return this.userConsumptions.map((i) =>
                i[this.valuePropertyName] !== null ? (i.realConsumption ? (i[this.valuePropertyName] > 0 ? "#417290" : "#417290") : "#417290") : "#417290"
            );
        },
    },
    watch: {
        filterDates: function () {
            console.log("Cambiando fechas de filtros de consumos en gráfico");
            this.loadServiceData();
        },
        valuePropertyName: function () {
            this.renderAbChart();
        },
    },
    mounted() {
        moment.locale(`${this.$i18n.locale}-ES`);
        this.loadServiceData();
        executeAccessibilityFixes();
    },
};
</script>
