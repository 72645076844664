<template>
    <ValidationObserver ref="editAliasForm" v-slot="{ reset }">
        <modal
            class="modal-alias"
            name="edit-alias-modal"
            @before-open="setEditAliasContext"
            :reset="true"
            :scrollable="true"
            :click-to-close="true"
            height="auto"
            width="532"
            :adaptive="true"
        >
            <p class="title">
                {{ $t("ofex.modalEditarAlias.titulo") }}<span class="number">{{ editAliasContext.contractNumber }}</span>
            </p>
            <div class="">
                <ab-input
                    autocomplete="nickname"
                    class="input-modal-alias"
                    :placeholder="$t('ofex.modalEditarAlias.placeholderInput')"
                    name="contractAlias"
                    :show-label="false"
                    v-model="editAliasContext.alias"
                    :validation="{ max: 10 }"
                    :max-length="10"
                    input-alias="alias del contrato"
                ></ab-input>
                <p class="input-count">{{ modalInputCount }} / 10</p>
            </div>
            <div class="buttons-container">
                <a class="link" @click="$modal.hide('edit-alias-modal')">{{ $t("ofex.modalEditarAlias.cancel") }}</a>
                <ab-button-ofex :text-button="$t('common.saveButton')" :click-event="editAlias"></ab-button-ofex>
            </div>
        </modal>
    </ValidationObserver>
</template>

<script>
import * as actionTypes from "@/store/action-types";

export default {
    name: "AbEditAliasModal",
    components: {},
    data() {
        return {
            editAliasContext: {
                alias: "",
                contractNumber: null,
            },
        };
    },
    computed: {
        modalInputCount: function () {
            return this.editAliasContext.alias.length;
        },
    },
    methods: {
        openModal: function (contractNumber, alias) {
            this.$modal.show("edit-alias-modal", {
                contractNumber: contractNumber,
                alias: alias,
            });
        },
        setEditAliasContext: function (event) {
            this.editAliasContext.contractNumber = event.params.contractNumber;
            this.editAliasContext.alias = event.params.alias;
        },
        editAlias: function () {
            let validator = this.$refs.editAliasForm;
            validator.validate().then((result) => {
                if (result) {
                    console.log("Editando alias: " + this.editAliasContext.contractNumber);
                    this.$store
                        .dispatch(actionTypes.UPDATE_ALIAS, {
                            contractNumber: this.editAliasContext.contractNumber,
                            alias: this.editAliasContext.alias,
                            locale: this.$i18n.locale,
                        })
                        .then((value) => {
                            console.debug("Terminando de actualizar alias" + value);
                            this.$modal.hide("edit-alias-modal");
                            this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
                            // this.$refs.contractsAdvanceSearch.filterContracts();
                        });
                }
            });
        },
    },
    mounted() {
        console.log("Montando componente de alias editable");
    },
};
</script>
<style lang="scss">
.modal-alias {
    .v--modal-box {
        padding: 20px;
    }
    .title {
        @include font-size(16);
        @include line-height(19);
        color: $color-primary;
        font-weight: $font-primary-bold;
        margin-bottom: 20px;

        .number {
            margin-left: 3px;
        }
    }
    .input-count {
        text-align: right;
        @include font-size(12);
        @include line-height(14);
        color: #666666;
        margin-bottom: 25px;
    }
    .input-modal-alias input {
        border-top: 0;
        border-right: 0;
        border-left: 0;
        border-bottom: 1px solid $blue-lighter;
        padding: 0;
        margin-bottom: 5px;
        border-radius: 0;
    }
    .buttons-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .btn {
            width: auto;
            margin-left: 50px;
        }
        .link {
            cursor: pointer;
        }
    }
}
</style>
