import qs from "qs";
import store from "@/store/store";
import * as mutationTypes from "@/store/mutation-types";
import { GENERIC_ERROR_MUTATION, SET_REST_ERROR } from "@/store/mutation-types";
import BaseOfexServiceClient from "./BaseOfexServiceClient";
import { saveAs } from "file-saver";
import i18n from "../i18n";
import { Capacitor } from "@capacitor/core";
import { Filesystem, Directory } from "@capacitor/filesystem";
// import { FileOpener } from "@awesome-cordova-plugins/file-opener";
import { FileOpener } from "@capacitor-community/file-opener";

const INVOICES_BASE_URL = process.env.VUE_APP_API_INVOICES_BASE_URL;

export default {
    getUserInvoices: async function (
        contractNumber,
        userId,
        clientId,
        lang = "es",
        lastMonths = 60,
        mode = "ALL",
        consumptionDateRangeStart,
        consumptionDateRangeEnd,
        issueDateRangeStart,
        issueDateRangeEnd,
        consumptionVolumeMin,
        consumptionVolumeMax,
        amountMin,
        amountMax,
        statusPaid,
        statusUnpaid,
        statusInProcess
    ) {
        try {
            console.debug(`Obteniendo facturas del cliente ${clientId} con número de contrato ${contractNumber}`);
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${INVOICES_BASE_URL}/invoices`, {
                params: {
                    contractNumber,
                    userId,
                    clientId,
                    lang,
                    lastMonths,
                    mode,
                    consumptionDateRangeStart,
                    consumptionDateRangeEnd,
                    issueDateRangeStart,
                    issueDateRangeEnd,
                    consumptionVolumeMin,
                    consumptionVolumeMax,
                    amountMin,
                    amountMax,
                    statusPaid,
                    statusUnpaid,
                    statusInProcess,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response.status === 200) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    getLastInvoices: async function (userId, clientId, lang = "es", pageNumber = 1, pageSize = 50) {
        try {
            console.debug(`Obteniendo últimas facturas del cliente ${clientId} `);
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${INVOICES_BASE_URL}/invoices`, {
                params: {
                    userId,
                    clientId,
                    lang,
                    lastMonths: 60,
                    mode: "LAST_INVOICES",
                    pageNumber,
                    pageSize,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response.status === 200) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    getInvoiceBinary: async function (invoiceNumber, invoiceYear, userId, clientId, contractNumber, lang = "es", invoiceType = "NORMAL") {
        try {
            console.debug(`Obteniendo facturas ${invoiceNumber}`);
            let headers = {
                Accept: Capacitor.isNativePlatform() ? "application/json" : "application/pdf",
                "Accept-Language": lang,
            };

            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let url = Capacitor.isNativePlatform()
                ? `${INVOICES_BASE_URL}/invoices/${invoiceYear}/${invoiceNumber}/base64`
                : `${INVOICES_BASE_URL}/invoices/${invoiceYear}/${invoiceNumber}`;
            let responseType = Capacitor.isNativePlatform() ? "json" : "blob";
            let response = await abRestClient.get(url, {
                responseType: responseType,
                headers: headers,
                params: {
                    lang,
                    userId,
                    clientId,
                    contractNumber,
                    invoiceType,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            console.debug("Obtenida respuesta de servico de facturas");
            if (response.status === 200) {
                let fileName = `Factura_${invoiceYear}${invoiceNumber}.pdf`;
                if (Capacitor.isNativePlatform()) {
                    let permissions = await Filesystem.checkPermissions();
                    console.log("AB Permission " + permissions.state);
                    let writeResult = await Filesystem.writeFile({
                        path: fileName,
                        data: response.data.data,
                        directory: Directory.Data,
                    });
                    FileOpener.open({ filePath: writeResult.uri, contentType: "application/pdf" })
                        .then(() => console.log("File is opened"))
                        .catch((e) => console.log("Error opening file", e));
                } else {
                    saveAs(response.data, fileName);
                }
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: i18n.t("ofex.common.invoiceDownloadError", lang) });
            store.commit(GENERIC_ERROR_MUTATION, { errorMessage: i18n.t("ofex.common.invoiceDownloadError", lang) });
        }
    },
    updateFSP: async function (clientId, userId, active, fspUrl, pdf, contractsNumbers, lang = "es") {
        try {
            console.debug(`Servicio REST update Facturas en Papel`);
            let abRestClient = new BaseOfexServiceClient(false).abRestClient;
            let response = await abRestClient.put(
                `${INVOICES_BASE_URL}/invoices/fsp`,
                {
                    operation: active,
                    fspUrl: fspUrl,
                    pdfDocumentId: pdf,
                    contractsNumbers: contractsNumbers,
                },
                {
                    params: {
                        clientId: clientId,
                        userId: userId,
                        lang: lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log("Completada modificación de facturas");
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    getRectifyingInvoices: async function (clientId, userId, lang = "es") {
        try {
            console.debug("Llamada REST para obtener facturas rectificativas.");
            let abRestClient = new BaseOfexServiceClient().abRestClient;

            let response = await abRestClient.get(`${INVOICES_BASE_URL}/invoices/rectifying`, {
                params: {
                    userId,
                    clientId,
                    lang,
                },
            });
            if (response.status === 200) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
};
