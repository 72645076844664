<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <!--    <div class="steps-header" v-if="curStep < (stepNames.length + 1) ">-->
    <div v-show="curStep < stepNames.length + 1 && stepNames.length > 1" ref="stepsHeader" class="steps-header">
        <div class="d-flex justify-content-between">
            <div
                v-for="step in stepNames"
                :key="step"
                :tabindex="0"
                aria-pressed="false"
                role="button"
                :aria-labelledby="'stepDescriptor' + getIndexOfStep(step)"
                class="text-center d-flex"
                :class="{ appendline: !isLastStep(step) }"
                @click="moveStep(curStep, getIndexOfStep(step))"
                @keydown.prevent.space="moveStep(curStep, getIndexOfStep(step))"
                @keyup.enter="moveStep(curStep, getIndexOfStep(step))"
            >
                <div class="bolaCircularGrey" :class="{ 'step-actualForm': curStep >= getIndexOfStep(step) }">
                    <img v-if="curStep > getIndexOfStep(step)" class="checkbolaform" src="../assets/images/checkbolaform.png" alt="Paso Completado" />
                    <img v-if="curStep === getIndexOfStep(step)" class="editImageForm" src="../assets/images/page-actual-form.png" alt="Paso Actual" />
                    <p v-if="curStep < getIndexOfStep(step)" class="numberBolaForm">{{ getIndexOfStep(step) }}</p>
                </div>
                <div class="lineBoleMedChecked" v-if="!isLastStep(step)"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <div v-for="step in stepNames" :key="step" class="text-center anch55">
                    <p
                        :id="'stepDescriptor' + getIndexOfStep(step)"
                        class="mt-1"
                        :class="{ textdownbola: curStep > 1, textactualbola: curStep == 1, textdownbolagrey: curStep < 1 }"
                    >
                        {{ step }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "step",
        event: "change",
    },
    props: {
        stepNames: {
            default: function () {
                return [];
            },
            type: Array,
        },
        containerId: {
            require: true,
            type: String,
        },
        gtmCategory: {
            type: String,
        },
        contingenciasActive: {
            type: Boolean,
            default: true,
        },
        validatorObserver: {
            type: Object,
            require: true,
        },
    },
    computed: {
        curStep: {
            get: function () {
                return this.$attrs["step"];
            },
        },
    },
    mounted() {},
    methods: {
        moveStep: async function (current, destiny = current + 1, extraValidation = true) {
            if (await this.checkContingencias()) {
                if (current > destiny) {
                    this.$emit("change", destiny);
                } else if (current < destiny) {
                    console.log("Validating step " + current);

                    //TODO ver como validar los pasos al no existir ya el validation-scope
                    var vueContext = this;
                    this.$parent.currentValidatorObserver.validate().then((result) => {
                        if (process.env.VUE_APP_MATOMO_ENABLED === "true") {
                            if (this.$matomo && this.$matomo.trackPageView()) {
                                let category = this.gtmCategory ? this.gtmCategory : this.containerId;
                                this.$matomo.trackEvent(category, `click from ${current} to ${destiny},`, result);
                            }
                        }
                        if (result && extraValidation) {
                            vueContext.$emit("change", current + 1);
                            vueContext.moveStep(current + 1, destiny);
                            vueContext.scrollToHeader();
                        } else {
                            vueContext.scrollToHeader();
                        }
                    });
                }
            } else {
                this.$emit("change", -1);
            }
        },
        checkContingencias: async function () {
            if (this.contingenciasActive) {
                let contingenciasResult = await this.$http.get(`${process.env.VUE_APP_API_MONITOR_BASE_URL}/monitor/contingencies`);
                return contingenciasResult.body.crm && contingenciasResult.body.documentContentManager && contingenciasResult.body.sicab;
            } else {
                return true;
            }
        },
        getIndexOfStep: function (step) {
            return this.stepNames.indexOf(step) + 1;
        },
        isLastStep: function (step) {
            return this.getIndexOfStep(step) === this.stepNames.length;
        },
        scrollToHeader: function () {
            this.$nextTick().then(() => {
                // $('html,body').animate({scrollTop: $("#" + this.containerId).offset().top}, 'slow');
                /*document.getElementById(this.containerId).scrollIntoView({
                        behavior: 'smooth'
                    });*/
                window.scrollTo({ top: 0, behavior: "smooth" });
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.anch55 {
    width: 55px;
}

.bolaCircularGrey {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    border: 1px solid $border-table;
    background-color: $border-table;
    cursor: pointer;
    padding-top: 1px;
    padding-left: 2px;
}

.numberBolaForm {
    color: $color-lightest;
    margin-top: 10px;
    font-weight: $font-primary-bold;
    @include font-size(13);
}

.checkbolaform {
    width: 36px;
    height: 35px;
    margin-top: 5.5px;
}

.step-actualForm {
    background-color: $bg-primary !important;
}

.lineBoleMedChecked {
    height: 25px;
    border-bottom: 2px solid $blue-dark !important;
    width: calc(100% - 50px);
}

.editImageForm {
    width: 30px;
    height: 30px;
    margin-top: 7px;
    margin-left: 1px;
}

.textactualbola {
    color: $blue-darker;
    font-weight: $font-primary-bold;
    @include font-size(10);
    @include line-height(14);
}
.textdownbola {
    color: $blue-darker;
    font-weight: $font-primary-bold;
    @include font-size(10);
    @include line-height(14);
}
.appendline {
    width: 100%;
}
</style>
