<template>
    <div>
        <validation-provider
            mode="eager"
            :name="inputAliasComputed"
            :vid="`${name}-vid`"
            :rules="validation"
            :debounce="validationDelay"
            v-slot="{ errors, classes }"
        >
            <div class="input-container">
                <label v-if="showLabel" :class="'letraform-new'" :for="name">
                    {{ label }}
                </label>

                <div class="divInputDetalle">
                    <input
                        :id="name"
                        :title="title"
                        :ref="reference"
                        v-model="modelValue"
                        :maxlength="maxLength"
                        :class="{ ...cssClassListMerged, ...classes }"
                        :name="name"
                        :placeholder="placeholder"
                        :type="type"
                        :autocomplete="autocomplete"
                        :readonly="readonly"
                        :aria-label="title"
                        @focusin="focusInEvent"
                        @paste.self="handlePaste($event)"
                        @blur="eventBlur"
                    />
                    <span class="texto-detalle-input"
                        >{{ detalleText }} <sup>{{ detalleTextSup }}</sup></span
                    >
                    <div v-if="rightButton" class="float-left input-right-button" @click="handleRightButtonClick">
                        <span class="textdnormal">
                            {{ rightButton }}
                        </span>
                    </div>
                </div>
                <p v-show="errors.length > 0" class="formTextErr displayYes">{{ errors[0] }}</p>
            </div>
        </validation-provider>
    </div>
</template>

<script>
import keyBy from "lodash/keyBy";

export default {
    components: {},
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
        },
        detalleText: {
            type: String,
        },
        detalleTextSup: {
            type: String,
        },
        inputAlias: {
            type: String,
        },
        label: {
            type: String,
            default: "Label",
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        validation: {
            type: Object,
        },
        validationDelay: {
            type: Number,
            default: 300,
        },
        maxLength: {
            type: Number,
        },
        type: {
            type: String,
            default: "text",
        },
        popover: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        pasteable: {
            type: Boolean,
            default: true,
        },
        rightButton: {
            type: String,
            required: false,
        },
        cssClassList: {
            type: Array,
            default: function () {
                return [];
            },
        },
        reference: {
            type: String,
        },
        isLogin: {
            type: Boolean,
            default: false,
        },
        focusInEvent: {
            type: Function,
            default: function () {},
        },
        eventBlur: {
            type: Function,
            default: function () {},
        },
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["inputValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
        cssClassListMerged: function () {
            let cssClassListMerged = Array.from(this.cssClassList);
            cssClassListMerged.push("ab-form-input");
            cssClassListMerged.push("inputConInfoDetalle");
            cssClassListMerged.push("inputConInfo");

            return keyBy(cssClassListMerged);
        },
        inputAliasComputed: function () {
            return this.inputAlias ? this.inputAlias : this.label.toLowerCase().replace("*", "").replace(":", "");
        },
    },
    methods: {
        handlePaste: function (event) {
            console.debug("Handling paste");
            console.debug(event);

            if (this.pasteable) {
                return true;
            } else {
                event.preventDefault();
            }
        },
        handleRightButtonClick: function () {
            this.$emit("right-button");
        },
    },
};
</script>

<style lang="scss" scoped>
.divInputDetalle {
    width: 100%;
    position: relative;
    border-radius: 8px;
    height: 40px;
    background-color: white;
    border: 1px solid #979797;
    margin-bottom: 20px;
    @include respond-above(sm) {
        height: 32px;
    }
}

.inputConInfoDetalle {
    width: 100%;
    padding-right: 30px;
    height: 30px;
    padding-top: 3px;
    border-radius: 8px;
    border: 0;
    background-color: white;
    @include respond-above(sm) {
        padding-top: 1px !important;
    }
}
.texto-detalle-input {
    position: absolute;
    right: 6px;
    top: 10px;
    @include font-size(14);
    @include line-height(17);
    font-weight: $font-primary-medium;
    @include respond-above(sm) {
        top: 6px;
    }
}
.input-container {
    display: flex;
    flex-wrap: wrap;
    @include respond-below(md) {
        margin-bottom: 20px;
    }

    label {
        flex-basis: 100%;
    }
    .inputPrincipal {
        flex: 1;
    }
    .spacePopover {
        align-self: center;
    }
    .formTextErr {
        flex-basis: 100%;
    }
}
input {
    height: 40px;
    font-weight: $font-primary-medium;
    color: $color-dark;
    @include font-size(14);
    @include line-height(16);
    appearance: none;
    padding-right: 8px;
    padding-left: 8px;

    &::placeholder {
        color: $border-table;
    }
    &.inputFormErr {
        border: 1px solid #f48989;
        background-color: #feefef;
    }
    &:focus {
        outline: none;
    }
    @include respond-above(sm) {
        height: 32px;
    }
}
label {
    margin-bottom: 0;
    @include line-height(26);
}
</style>
