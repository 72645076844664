"use strict";

import BaseXlsxGenerator from "@/generators/xlsx/base/BaseXlsxGenerator";
import * as xlsx from "xlsx";

export default class InvoicesListXlsxGenerator extends BaseXlsxGenerator {
    constructor(filename, invoicesRowsArray, selectedContractNumber, supplyAddress, i18n) {
        let invoicesSheet = xlsx.utils.json_to_sheet(invoicesRowsArray, { origin: "A3" });
        xlsx.utils.sheet_add_aoa(invoicesSheet, [[i18n.tc("ofex.invoicesTable.numContrato") + " " + selectedContractNumber]], { origin: "A1" });
        xlsx.utils.sheet_add_aoa(invoicesSheet, [[i18n.tc("ofex.invoicesTable.direccion") + " " + supplyAddress]], { origin: "A2" });
        xlsx.utils.sheet_add_aoa(invoicesSheet, [[i18n.tc("ofex.xlsGenerator.invoicesList.numFactura")]], { origin: "A3" });
        xlsx.utils.sheet_add_aoa(invoicesSheet, [[i18n.tc("ofex.xlsGenerator.invoicesList.consumoHasta")]], { origin: "B3" });
        xlsx.utils.sheet_add_aoa(invoicesSheet, [[i18n.tc("ofex.invoicesTable.columnNames.issueDate")]], { origin: "C3" });
        xlsx.utils.sheet_add_aoa(invoicesSheet, [[i18n.tc("ofex.xlsGenerator.invoicesList.consumo")]], { origin: "D3" });
        xlsx.utils.sheet_add_aoa(invoicesSheet, [[i18n.tc("ofex.xlsGenerator.invoicesList.importe")]], { origin: "E3" });
        xlsx.utils.sheet_add_aoa(invoicesSheet, [[i18n.tc("ofex.invoicesTable.columnNames.status")]], { origin: "F3" });
        xlsx.utils.sheet_add_aoa(invoicesSheet, [[i18n.tc("ofex.invoicesTable.columnNames.shippmentType")]], { origin: "G3" });

        super(filename, invoicesSheet);
    }
}
