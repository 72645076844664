<template>
    <div class="without-data box">
        <p>{{ text }}</p>
    </div>
</template>

<script>
export default {
    name: "AbWithoutData",
    props: {
        text: {
            type: String,
            default: function () {
                return this.$i18n.tc("ofex.withoutData.default");
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.without-data {
    text-align: left;
    font-weight: $font-primary-bold;
    color: $blue-dark;
    @include font-size(24);
    @include line-height(29);
}
</style>
