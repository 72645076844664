"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";

export default class NewAuthPdfGenerator extends BaseGenerator {
    constructor(i18n, user, client, contract) {
        super("NuevaAutorizacion.pdf", "", i18n, user, client, contract);
    }
    generatePdf(client, formModel, contractNumbers) {
        console.log(`Generando pdf nueva autorizacion`);
        const SPACE = " ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(
            contractNumbers?.length > 0 ? this.i18n.tc("ofex.pdfGenerator.newAuth.newContract") : this.i18n.tc("ofex.pdfGenerator.newAuth.newClient"),
            ""
        );
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.newAuth.dataAuthorizator"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.typeDocument") + SPACE + client.documentType);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.numberDocument") + SPACE + client.documentNumber);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.nombre") + SPACE + client.name);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.primerApellido") + SPACE + client.firstLastName);
        if (client.secondLastName) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.segundoApellido") + SPACE + client.secondLastName);
        }
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.tfnoPrincipal") + SPACE + client.mainPhone);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.email") + SPACE + client.email);

        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.newAuth.dataAuthorizated"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.typeDocument") + SPACE + formModel.doc.type);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.numberDocument") + SPACE + formModel.doc.number);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.nombre") + SPACE + formModel.name);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.primerApellido") + SPACE + formModel.lastName);
        if (formModel.secondLastName) {
            this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.segundoApellido") + SPACE + formModel.secondLastName);
        }
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.tfnoPrincipal") + SPACE + formModel.phone);
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.newAuth.email") + SPACE + formModel.email);

        if (contractNumbers) {
            this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.newAuth.contracts"));
            let multilineContracts = contractNumbers
                .map((contractNumber) => `${this.i18n.tc("ofex.pdfGenerator.partialModification.contractNumber")} ${contractNumber}`)
                .join(" , ");

            this.generateMultilineText(multilineContracts);
        }

        return this.pdf;
    }
}
