<template>
    <div class="dropdown-section">
        <div class="dropdown-section-inner">
            <div class="text">
                <div class="inner" v-b-toggle="collapseId" variant="primary">
                    <font-awesome-icon class="icon" icon="chevron-circle-right"></font-awesome-icon>
                    <span>{{ label }}</span>
                </div>
            </div>
            <b-collapse v-model="modelValue" :id="collapseId">
                <slot> </slot>
            </b-collapse>
        </div>
    </div>
</template>

<script>
export default {
    name: "AbDropdownSection",
    model: {
        prop: "collapseVisible",
        event: "change",
    },
    props: {
        collapseId: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: "Lorem Ipsum",
        },
    },
    data() {
        return {};
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["collapseVisible"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
    },
};
</script>

<style lang="scss" scoped></style>
