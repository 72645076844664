import Vue from "vue";
import Vuex from "vuex";

import * as mutationTypes from "./mutation-types";
import * as actionTypes from "./action-types";

import LoginServiceClient from "../rest/loginServiceClient.js";
import ContractsServiceClient from "../rest/contractsServiceClient.js";
import InvoicesServiceClient from "../rest/invoicesServiceClient.js";
import NotificationsServiceClient from "../rest/notificationsServiceClient.js";
import CustomersServiceClient from "../rest/customersServiceClient.js";
import PaymentsServiceClient from "../rest/paymentsServiceClient.js";
import ConsumptionsServiceClient from "../rest/consumptionsServiceClient.js";
import PromosServiceClient from "../rest/promosServiceClient.js";
import AuthorizationsServiceClient from "../rest/authorizationsServiceClient.js";

import i18n from "@/i18n";
import router from "../router/router";
import * as viewMappingTypes from "../router/view-mapping-types";

import jwt from "jsonwebtoken";

import moment from "moment";

import first from "lodash/first";
import BaseOfexServiceClient from "../rest/BaseOfexServiceClient";
import { AB_DATE_FORMAT, isValidDateRange, trimBackendCode, isDroughtAffected } from "@/plugins/ab-utils";
import { GET_PROFILE_ACTION, LOGOUT_ACTION } from "./action-types";
import { SET_LOGIN_ERROR_MSG_MUTATION, SET_REST_ERROR } from "./mutation-types";
import axios from "axios";
import { REDIRECT_AFTER_LOGIN_PARAM } from "@/plugins/const";
import { NativeBiometric } from "capacitor-native-biometric";
import { Capacitor } from "@capacitor/core";
import { savePreference } from "@/services/nativePreferences";
import { NATIVE_LOCALE_PREFERENCE_KEY } from "@/services/services-constant-types";
import { SalesforceMarketingCloud } from "capacitor-salesforce-marketingcloud";
import DroughtServiceClient from "@/rest/droughtServiceClient";
import Swal from "sweetalert2";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        restCancelToken: axios.CancelToken.source(),
        restError: null, // TODO unificar esto con gestión genérica de errores
        genericError: null,
        contigenciasStatus: { mdm: true, crm: true, azureIdentity: true, documentContentManager: true, sicab: true, appStore: true },
        contigenciasOk: { mdm: true, siebel: true, azureIdentity: true, documentContentManager: true, sicab: true, appStore: true },
        legalCheckStatus: "PENDING", //PENDING, IN_PROGRESS, FINISHED
        isLoading: false,
        userTokenPayload: null,
        i18nLocale: i18n.locale,
        lang: "es",
        loginError: [],
        selectedContractNumber: null,
        selectedInvoiceNumber: null,
        contracts: [],
        activeContracts: [],
        allContracts: [], //asignados y sin asignar
        contractDetail: null,
        userInvoices: [],
        rectifyingInvoices: [],
        droughtConfiguration: null,
        filteredInvoices: null,
        digitalInvoices: null,
        unpaidInvoices: null,
        lastInvoices: null,
        consumptionsByContract: null,
        contractConsumptionsSummary: null,
        authorizations: [],
        contractAuthorizationsByPerson: [],
        authorizationsByContract: [],
        notifications: [],
        unreadNotifications: [],
        notificationConfig: null,
        configNotificationsList: [],
        notificationsContractList: [],
        loadNotifications: true,
        clientData: {},
        userData: {},
        payment: null,
    },
    mutations: {
        [mutationTypes.RESET_REST_CANCEL_TOKEN](state) {
            state.restCancelToken = axios.CancelToken.source();
        },
        [mutationTypes.CHANGE_I18N_LOCALE](state, payload) {
            if (Capacitor.isNativePlatform()) {
                savePreference(NATIVE_LOCALE_PREFERENCE_KEY, payload.i18nLocale).then(() => console.debug("Almacenado idioma en preferencias nativas"));
            }
            state.i18nLocale = payload.i18nLocale;
        },
        // TODO unificar esto con gestión genérica de errores
        [mutationTypes.SET_REST_ERROR](state, payload) {
            state.restError = payload.error;
        },
        [mutationTypes.GENERIC_ERROR_MUTATION](state, payload) {
            console.log("Error, pendiente de UI para gestionar error");
            state.isLoading = false;
            state.genericError = payload;
        },
        [mutationTypes.SET_LOADING_MUTATION](state, payload) {
            console.debug(`Loading trigger ${payload.trigger}. Value: ${payload.isLoading}`);
            state.isLoading = payload.isLoading;
        },
        [mutationTypes.CHANGE_CONTINGENCIAS_STATUS_MUTATION](state, payload) {
            state.contigenciasStatus = payload.contigenciasStatus;
        },
        [mutationTypes.CHANGE_CONTINGENCIAS_OK](state, payload) {
            state.contigenciasOk = payload.contigenciasOk;
        },
        [mutationTypes.SET_LOGIN_ERROR_MSG_MUTATION]: (state, payload) => {
            window._paq.push(["trackEvent", "login", `error: ${payload.errorMsg}`]);
            state.loginError.push(payload.errorMsg);
        },
        [mutationTypes.CLEAR_LOGIN_ERROR_MSG_MUTATION]: (state) => (state.loginError = []),
        [mutationTypes.STORE_TOKEN_MUTATION](state, payload) {
            let payloadToken = payload.alreadyDecoded === true ? payload.token : jwt.decode(payload.token);
            console.log(`Obtenido token: ${payloadToken}`);
            state.userTokenPayload = payloadToken;
            let tokenExpirationTimestamp = payloadToken.exp;
            Vue.$cookies.set(
                "ofexTokenPayload",
                payloadToken,
                new Date(tokenExpirationTimestamp * 1000),
                "",
                "",
                process.env.VUE_APP_SECURE_COOKIES === "true",
                "Strict"
            );
        },
        [mutationTypes.STORE_USER_DATA_MUTATION](state, payload) {
            if (payload.userData) {
                state.userData = payload.userData;
                if (state.userTokenPayload != null) {
                    Vue.$cookies.set(
                        "ofexUserData",
                        payload.userData,
                        new Date(state.userTokenPayload.exp * 1000),
                        "",
                        "",
                        process.env.VUE_APP_SECURE_COOKIES === "true",
                        "Strict"
                    );
                }
            }
        },
        [mutationTypes.STORE_CLIENT_DATA_MUTATION](state, payload) {
            if (payload.clientData) {
                state.clientData = payload.clientData;
                Vue.$cookies.set(
                    "ofexClientData",
                    payload.clientData,
                    new Date(state.userTokenPayload.exp * 1000),
                    "",
                    "",
                    process.env.VUE_APP_SECURE_COOKIES === "true",
                    "Strict"
                );
            }
        },
        [mutationTypes.STORE_PAYMENT_DATA_MUTATION](state, payload) {
            console.debug("entrado al computed del payment" + payload.payment);
            if (payload.payment) {
                state.payment = payload.payment;
                Vue.$cookies.set(
                    "ofexUserPayment",
                    payload.payment,
                    new Date(state.userTokenPayload.exp * 1000),
                    "",
                    "",
                    process.env.VUE_APP_SECURE_COOKIES === "true",
                    "Strict"
                );
            }
        },
        [mutationTypes.CLEAR_AUTH_DATA_MUTATION](state) {
            state.userTokenPayload = null;
            state.legalCheckCompleted = false;
            state.clientData = {};
            state.userData = {};
            state.userInvoices = [];
            state.rectifyingInvoices = [];
            state.droughtConfiguration = null;
            state.filteredInvoices = null;
            state.unpaidInvoices = null;
            state.lastInvoices = null;
            state.consumptionsByContract = null;
            state.contractConsumptionsSummary = null;
            state.contracts = [];
            state.activeContracts = [];
            state.allContracts = [];
            state.contractDetail = null;
            state.selectedContractNumber = null;
            state.selectedInvoiceNumber = null;
            state.authorizations = [];
            state.notifications = [];
            state.unreadNotifications = [];
            state.notificationConfig = null;
            state.configNotificationsList = [];
            state.notificationsContractList = [];
            state.loadNotifications = true;
            state.legalCheckStatus = "PENDING";
            Vue.$cookies.remove("ofexTokenPayload");
            Vue.$cookies.remove("ofexUserData");
            Vue.$cookies.remove("ofexClientData");
            Vue.$cookies.remove("ofexUserPayment");
            Vue.$cookies.remove("ofexLegalCheckStatus");
            Vue.$cookies.remove("ofexTokenJwt");
        },
        [mutationTypes.RECOVER_PERSISTED_AUTH_DATA_MUTATION](state) {
            console.debug("Recuperando información de las cookies");
            state.userTokenPayload = Vue.$cookies.get("ofexTokenPayload");
            state.userData = Vue.$cookies.get("ofexUserData");
            state.clientData = Vue.$cookies.get("ofexClientData");
            state.payment = Vue.$cookies.get("ofexUserPayment");
            state.legalCheckStatus = Vue.$cookies.get("ofexLegalCheckStatus");

            //TODO refactorizar a método comun
            if (state.userTokenPayload && state.userData) {
                if (Vue.prototype.$isMatomoAvalaible()) {
                    Vue.prototype.$notifyLoginToMatomo(`${this.getters.isCompany ? "E" : "P"}${state.clientData.multicontract ? "N" : "1"}`);
                }
            }
        },
        [mutationTypes.CHANGE_ACTIVE_CONTRACT_MUTATION](state, payload) {
            state.selectedContractNumber = payload.contractNumber;
        },
        [mutationTypes.CHANGE_ACTIVE_INVOICE_MUTATION](state, payload) {
            state.selectedInvoiceNumber = payload.invoiceNumber;
        },
        [mutationTypes.STORE_CONTRACTS_MUTATION](state, payload) {
            state.contracts = payload.contracts?.data;
        },
        [mutationTypes.STORE_ALL_CONTRACTS_MUTATION](state, payload) {
            state.allContracts = payload.allContracts?.data;
        },
        [mutationTypes.STORE_ACTIVE_CONTRACTS_MUTATION](state, payload) {
            state.activeContracts =
                payload.activeContracts?.data.filter(
                    (value) => value.contractDetail?.supplyStatus === "EN VIGOR" || value.contractDetail?.supplyStatus === "CONTRACTAT I NO INSTAL.LAT"
                ) || [];
        },
        [mutationTypes.STORE_CONTRACT_DETAIL_MUTATION](state, payload) {
            state.contractDetail = payload.contractDetail?.data;
        },
        [mutationTypes.STORE_INVOICES_MUTATION](state, payload) {
            state.userInvoices = payload.invoices?.data;
        },
        [mutationTypes.STORE_FILTERED_INVOICES_MUTATION](state, payload) {
            state.filteredInvoices = payload.filteredInvoices?.data;
        },
        [mutationTypes.STORE_UNPAID_INVOICES_MUTATION](state, payload) {
            //filtrando facturas con deudas de solamente los contratos asignados
            let contractNumbers = state.contracts.map((contract) => contract.contractDetail.contractNumber);
            state.unpaidInvoices = payload.unpaidInvoices?.data.filter((i) => contractNumbers.includes(i.contractNumber));
        },
        [mutationTypes.STORE_LAST_INVOICES_MUTATION](state, payload) {
            //filtrando facturas con deudas de solamente los contratos asignados
            // let contractNumbers = state.contracts.map((contract) => contract.contractDetail.contractNumber);
            // state.lastInvoices = payload.lastInvoices?.data.filter((i) => contractNumbers.includes(i.contractNumber));

            state.lastInvoices = payload.lastInvoices?.data;
        },
        [mutationTypes.CLEAR_INVOICES_FILTER_MUTATION](state) {
            state.filteredInvoices = null;
        },
        [mutationTypes.STORE_AUTHORIZATIONS_BY_USER](state, payload) {
            state.authorizations = payload.authorizations?.data;
        },
        [mutationTypes.STORE_AUTHORIZATIONS_BY_CONTRACT](state, payload) {
            state.authorizationsByContract = payload.authorizations?.data;
        },
        [mutationTypes.STORE_CONTRACT_AUTHORIZATIONS_BY_PERSON](state, payload) {
            state.contractAuthorizationsByPerson = payload.authorizations?.data;
        },
        [mutationTypes.CLEAR_NOTIFICATIONS_MUTATION](state) {
            state.notifications = [];
            state.loadNotifications = true;
        },
        [mutationTypes.STORE_NOTIFICATIONS_MUTATION](state, payload) {
            state.notifications = payload.notifications?.data;
        },
        [mutationTypes.STORE_UNREAD_NOTIFICATIONS_MUTATION](state, payload) {
            state.unreadNotifications = payload.unreadNotifications?.data;
        },
        [mutationTypes.STORE_CONFIG_NOTIFICATIONS_CATEGORY_MUTATION](state, payload) {
            state.configNotificationsList = payload.configNotificationsList;
        },
        [mutationTypes.STORE_CONFIG_NOTIFICATIONS_CONTRACTS_MUTATION](state, payload) {
            state.notificationsContractList = payload.notificationsContractList;
        },
        [mutationTypes.STORE_LOAD_NOTIFICATIONS](state, payload) {
            state.loadNotifications = payload.loadNotifications;
        },
        [mutationTypes.ADD_FAVORITE_CONTRACT](state, payload) {
            state.userData.favoriteContracts.push(payload.contractNumber);
        },
        [mutationTypes.REMOVE_FAVORITE_CONTRACT](state, payload) {
            state.userData.favoriteContracts = state.userData.favoriteContracts.filter((value) => value !== payload.contractNumber);
        },
        [mutationTypes.STORE_CONSUMPTIONS_BY_CONTRACTS](state, payload) {
            state.consumptionsByContract = payload.consumptionsByContract;
        },
        [mutationTypes.STORE_CONTRACT_CONSUMPTIONS_SUMMARY](state, payload) {
            state.contractConsumptionsSummary = payload.contractConsumptionsSummary?.data;
        },
        [mutationTypes.LEGAL_CHECK_STATUS](state, payload) {
            state.legalCheckStatus = payload.status;
            Vue.$cookies.set("ofexLegalCheckStatus", payload.status, null, "", "", process.env.VUE_APP_SECURE_COOKIES === "true", "Strict");
        },
        [mutationTypes.STORE_RECTIFYING_INVOICES](state, payload) {
            state.rectifyingInvoices = payload.rectifyingInvoices;
        },
        [mutationTypes.STORE_DROUGHT_CONFIGURATION](state, payload) {
            state.droughtConfiguration = payload.droughtConfiguration;
        },
    },
    actions: {
        [actionTypes.CANCEL_ALL_PENDING_REST_OPERATIONS](context) {
            console.debug("Cancelando operaciones REST pendientes");
            context.state.restCancelToken.cancel();
            context.commit(mutationTypes.RESET_REST_CANCEL_TOKEN);
        },
        [actionTypes.LOGIN_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.LOGIN_ACTION });
            let requestBody = {
                userIdentification: payload.userIdentification,
                companyIdentification: payload.companyIdentification,
                password: payload.password,
                scope: "ofex",
            };
            let userId = payload.userIdentification;
            let clientId = payload.companyIdentification ? payload.companyIdentification : userId;

            new BaseOfexServiceClient().abRestClient
                .post(process.env.VUE_APP_API_LOGIN_GET_TOKEN, requestBody, {
                    withCredentials: true,
                    params: {
                        recaptchaClientResponse: payload.recaptchaClientResponse,
                    },
                })
                .then(function (response) {
                    if (response.status === 200) {
                        //guardamos el token recibido
                        let token = response.data.access_token;

                        context.commit(mutationTypes.STORE_TOKEN_MUTATION, { token: token });

                        if (process.env.VUE_APP_PUSH_NOTIFICATIONS_ENABLED === "true" && Capacitor.isNativePlatform()) {
                            try {
                                CustomersServiceClient.getInternalUserId(userId, clientId).then((userIdentificationResponse) => {
                                    SalesforceMarketingCloud.enablePush().then();
                                    SalesforceMarketingCloud.setContactKey({ username: userIdentificationResponse.id }).then((promise) => promise.result);
                                });
                            } catch (e) {
                                console.warn("No se ha podido registrar el usuario en Marketing Cloud");
                                console.error(e);
                            }
                        }

                        //obtenemos los datos del profile y redirigimos a la home, si es la primera vez que accede lo llevamos a la página de asignacion de contratos
                        context
                            .dispatch(GET_PROFILE_ACTION, { userId, clientId })
                            .then(async () => {
                                if (Vue.prototype.$isMatomoAvalaible()) {
                                    Vue.prototype.$notifyLoginToMatomo(
                                        `${context.getters.isCompany ? "E" : "P"}${context.state.clientData.multicontract ? "N" : "1"}`
                                    );
                                }

                                console.log("IS BIOMETRIC LOGIN", payload.isBiometricLogin);
                                if (payload.isBiometricLogin) {
                                    console.debug("Gonna redirect to ok msg");
                                    // Save user's credentials
                                    NativeBiometric.setCredentials({
                                        username: payload.userIdentification,
                                        password: payload.password,
                                        server: "www.aiguesdebarcelona.cat",
                                    }).then();
                                    router.push(viewMappingTypes.REGISTERED_TOUCHID_VIEW_MAPPING.path);
                                } else {
                                    //verificamos si hay que redirigir a otra URL o seguimos comportamiento normal
                                    const redirectUri = sessionStorage.getItem(REDIRECT_AFTER_LOGIN_PARAM);
                                    if (redirectUri) {
                                        sessionStorage.removeItem(REDIRECT_AFTER_LOGIN_PARAM);
                                        console.debug("Redirigiendo a " + redirectUri);
                                        router.push(redirectUri);
                                    } else {
                                        context.state.clientData.multicontract && !context.state.userData.lastAccessDate
                                            ? router.push(viewMappingTypes.CONTRACTS_SELECTION_CONFIGURATION_MAPPING.path)
                                            : router.push(viewMappingTypes.HOME_VIEW_MAPPING.path);
                                    }
                                }
                            })
                            .catch(function (error) {
                                console.error("Fallo al obtener el profile del usuario.");
                                console.error(error);
                                // https://zenerab.atlassian.net/browse/UATOFEX-669
                                let exceptionError = context?.state?.genericError?.errorMessage;
                                context.commit(SET_LOGIN_ERROR_MSG_MUTATION, {
                                    errorMsg: exceptionError ? exceptionError : i18n.tc("ofex.common.unexpectedErrorMessage"),
                                });
                                context.dispatch(LOGOUT_ACTION).then((r) => console.log(r));
                            });
                    } else {
                        console.error("No se ha podido logar el usuario");
                        //gestionamos algún mensaje de error
                    }
                })
                .catch(function (error) {
                    console.error("No se ha podido logar el usuario");
                    console.debug(error.response);

                    //mostramos el código de error y lo ocultamos a los X segundos
                    // verificamos si tiene objeto response o no. Puede ser un timeout por ejemplo
                    if (error.response && error.response.data.errorMessage) {
                        context.commit(mutationTypes.SET_LOGIN_ERROR_MSG_MUTATION, { errorMsg: error.response.data.errorMessage });
                    } else {
                        context.commit(mutationTypes.SET_LOGIN_ERROR_MSG_MUTATION, { errorMsg: i18n.tc("ofex.common.unexpectedErrorMessage") });
                    }

                    context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.LOGIN_ACTION });
                });
        },
        [actionTypes.LOGIN_PASSWORDLESS_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.LOGIN_PASSWORDLESS_ACTION });

            let requestBody = {
                username: payload.username,
                verifyToken: payload.verifyToken,
            };

            new BaseOfexServiceClient().abRestClient
                .post(process.env.VUE_APP_API_LOGIN_PASSWORDLESS_GET_TOKEN, requestBody, {
                    params: {
                        recaptchaClientResponse: payload.recaptchaClientResponse,
                    },
                })
                .then(function (response) {
                    if (response.status === 200) {
                        //guardamos el token recibido
                        let token = response.data.access_token;

                        context.commit(mutationTypes.STORE_TOKEN_MUTATION, { token: token });
                        context.commit(mutationTypes.STORE_USER_DATA_MUTATION, { userData: response.data.user_data });
                        context.commit(mutationTypes.STORE_CLIENT_DATA_MUTATION, { clientData: response.data.client_data });

                        context.state.clientData.multicontract && !context.state.userData.lastAccessDate
                            ? router.push(viewMappingTypes.CONTRACTS_SELECTION_CONFIGURATION_MAPPING.path)
                            : router.push(viewMappingTypes.HOME_VIEW_MAPPING.path);
                    } else {
                        console.error("No se ha podido logar el usuario");
                        if (Vue.prototype.$isMatomoAvalaible()) {
                            Vue.prototype.$notifyErrorLoginToMatomo("Error Login - Passwordless. Username: " + payload.username);
                        }
                    }
                })
                .catch((e) => {
                    context.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
                });
        },
        [actionTypes.LOGIN_CERTIFICATE_ACTION](context) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.LOGIN_CERTIFICATE_ACTION });

            new BaseOfexServiceClient().abRestClient
                .get(process.env.VUE_APP_API_LOGIN_CERTIFICATE_GET_TOKEN)
                .then(function (response) {
                    if (response.status === 200) {
                        //guardamos el token recibido
                        let token = response.data.access_token;
                        let userId = response.data.userId;

                        context.commit(mutationTypes.STORE_TOKEN_MUTATION, { token: token });

                        //obtenemos los datos del profile y redirigimos a la home, si es la primera vez que accede lo llevamos a la página de asignacion de contratos
                        context
                            .dispatch(GET_PROFILE_ACTION, { userId: userId, clientId: userId })
                            .then(() =>
                                context.state.clientData.multicontract && !context.state.userData.lastAccessDate
                                    ? router.push(viewMappingTypes.CONTRACTS_SELECTION_CONFIGURATION_MAPPING.path)
                                    : router.push(viewMappingTypes.HOME_VIEW_MAPPING.path)
                            );
                    } else {
                        console.error("No se ha podido logar el usuario");
                        if (Vue.prototype.$isMatomoAvalaible()) {
                            Vue.prototype.$notifyErrorLoginToMatomo("Error Login - Certificate");
                        }
                    }
                })
                .catch((error) => {
                    //mostramos el código de error y lo ocultamos a los X segundos
                    // verificamos si tiene objeto response o no. Puede ser un timeout por ejemplo
                    if (error.response && error.response.data.errorMessage) {
                        context.commit(mutationTypes.SET_LOGIN_ERROR_MSG_MUTATION, { errorMsg: error.response.data.errorMessage });
                    } else {
                        context.commit(mutationTypes.SET_LOGIN_ERROR_MSG_MUTATION, { errorMsg: i18n.tc("ofex.common.certificateErrorMessage") });
                    }
                });
        },
        [actionTypes.LOGOUT_ACTION](context, payload) {
            LoginServiceClient.logout().then(() => {});
            context.commit(mutationTypes.CLEAR_AUTH_DATA_MUTATION);
            router.push({
                name: viewMappingTypes.LOGIN_VIEW_MAPPING.name,
                params: { logoutReason: payload && payload.logoutReason ? payload.logoutReason : "" },
            });
        },
        async [actionTypes.GET_USER_CONTRACTS_ACTION](context, payload) {
            if (typeof payload.triggerLoading !== "undefined" ? payload.triggerLoading : true) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_USER_CONTRACTS_ACTION });
            }

            let triggerChangeActiveContract = typeof payload.triggerChangeActiveContract !== "undefined" ? payload.triggerChangeActiveContract : true;

            let contracts = await ContractsServiceClient.getUserContracts(
                context.getters.clientId,
                context.getters.userId,
                ["ASSIGNED", "PENDING"],
                payload.locale
            );
            context.commit(mutationTypes.STORE_CONTRACTS_MUTATION, { contracts: contracts });
            if (context.state.contracts && context.state.contracts.length > 0 && triggerChangeActiveContract === true) {
                let activeContractNumberToSet = payload.activeContract ? payload.activeContract : context.state.contracts[0].contractDetail.contractNumber;
                context.dispatch(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, {
                    contractNumber: activeContractNumberToSet,
                    locale: payload.locale,
                    triggerLoading: typeof payload.triggerChangeActiveContractLoading !== "undefined" ? payload.triggerChangeActiveContractLoading : true,
                });
            } else {
                if (typeof payload.triggerLoading !== "undefined" ? payload.triggerLoading : true) {
                    context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_USER_CONTRACTS_ACTION });
                }
            }
        },
        async [actionTypes.GET_ALL_CONTRACTS_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_ALL_CONTRACTS_ACTION });

            let allContracts = await ContractsServiceClient.getUserContracts(
                context.getters.clientId,
                context.getters.userId,
                ["ASSIGNED", "PENDING", "NOT_ASSIGNED"],
                payload.locale
            );
            context.commit(mutationTypes.STORE_ALL_CONTRACTS_MUTATION, { allContracts });

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_ALL_CONTRACTS_ACTION });
        },
        async [actionTypes.GET_ACTIVE_CONTRACTS_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_ACTIVE_CONTRACTS_ACTION });

            let activeContracts = await ContractsServiceClient.getUserContracts(
                context.getters.clientId,
                context.getters.userId,
                ["ASSIGNED", "PENDING"],
                payload.locale
            );
            context.commit(mutationTypes.STORE_ACTIVE_CONTRACTS_MUTATION, { activeContracts: activeContracts });

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_ACTIVE_CONTRACTS_ACTION });
        },
        async [actionTypes.GET_CONTRACT_DETAIL_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_CONTRACT_DETAIL_ACTION });

            let contractDetail = await ContractsServiceClient.getContractDetail(
                context.getters.clientId,
                context.getters.userId,
                payload.contractNumber,
                payload.locale
            );
            context.commit(mutationTypes.STORE_CONTRACT_DETAIL_MUTATION, { contractDetail: contractDetail });

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_CONTRACT_DETAIL_ACTION });
        },
        async [actionTypes.FILTER_USER_INVOICES_ACTIONS](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.FILTER_USER_INVOICES_ACTIONS });

            let filter = payload.filter;

            let consumptionValidFilter = isValidDateRange(filter.consumptionDateRange);
            let issueValidFilter = isValidDateRange(filter.issueDateRange);

            let filteredInvoices = await InvoicesServiceClient.getUserInvoices(
                context.state.selectedContractNumber,
                context.getters.userId,
                context.getters.clientId,
                payload.locale,
                60,
                "ALL",
                consumptionValidFilter ? filter.consumptionDateRange[0] : undefined,
                consumptionValidFilter ? filter.consumptionDateRange[1] : undefined,
                issueValidFilter ? filter.issueDateRange[0] : undefined,
                issueValidFilter ? filter.issueDateRange[1] : undefined,
                filter.consumptionVolumeMin,
                filter.consumptionVolumeMax,
                filter.amountMin,
                filter.amountMax,
                filter.status.paid,
                filter.status.unpaid,
                filter.status.inProcess
            );
            context.commit(mutationTypes.STORE_FILTERED_INVOICES_MUTATION, { filteredInvoices });

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.FILTER_USER_INVOICES_ACTIONS });
        },
        async [actionTypes.DOWNLOAD_INVOICE_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.DOWNLOAD_INVOICE_ACTION });
            let contractNumber = payload.contractNumber ? payload.contractNumber : context.state.selectedContractNumber;
            await InvoicesServiceClient.getInvoiceBinary(
                payload.invoiceNumber,
                payload.invoiceYear,
                context.getters.userId,
                context.getters.clientId,
                contractNumber,
                payload.locale,
                payload.invoiceType
            );

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.DOWNLOAD_INVOICE_ACTION });
        },
        async [actionTypes.GET_CONTRACT_CONSUMPTIONS_SUMMARY](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_CONTRACT_CONSUMPTIONS_SUMMARY });

            let consumptionsSummary = await ConsumptionsServiceClient.getContractConsumptionsSummary(
                context.getters.clientId,
                context.getters.userId,
                payload.locale
            );

            context.commit(mutationTypes.STORE_CONTRACT_CONSUMPTIONS_SUMMARY, { contractConsumptionsSummary: consumptionsSummary });

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_CONTRACT_CONSUMPTIONS_SUMMARY });
        },
        async [actionTypes.GET_AUTHORIZATIONS_BY_USER_ACTION](context, payload) {
            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_AUTHORIZATIONS_BY_USER_ACTION });
            }

            let authorizations = await AuthorizationsServiceClient.getUserAuthorizations(
                context.getters.clientId,
                context.getters.userId,
                payload.locale,
                "GENERAL"
            );

            context.commit(mutationTypes.STORE_AUTHORIZATIONS_BY_USER, { authorizations });

            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_AUTHORIZATIONS_BY_USER_ACTION });
            }
        },
        async [actionTypes.GET_CONTRACT_AUTHORIZATIONS_BY_PERSON_ACTION](context, payload) {
            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_CONTRACT_AUTHORIZATIONS_BY_PERSON_ACTION });
            }

            let authorizations = await AuthorizationsServiceClient.getUserAuthorizations(
                context.getters.clientId,
                context.getters.userId,
                payload.locale,
                "CONTRACT"
            );

            context.commit(mutationTypes.STORE_CONTRACT_AUTHORIZATIONS_BY_PERSON, { authorizations });

            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_CONTRACT_AUTHORIZATIONS_BY_PERSON_ACTION });
            }
        },
        async [actionTypes.GET_AUTHORIZATIONS_BY_CONTRACT_ACTION](context, payload) {
            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_AUTHORIZATIONS_BY_CONTRACT_ACTION });
            }

            let authorizations = await AuthorizationsServiceClient.getAuthorizationsByContract(
                context.getters.clientId,
                context.getters.userId,
                payload.locale
            );

            context.commit(mutationTypes.STORE_AUTHORIZATIONS_BY_CONTRACT, { authorizations });

            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_AUTHORIZATIONS_BY_CONTRACT_ACTION });
            }
        },
        async [actionTypes.CREATE_NEW_AUTHORIZATION_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.CREATE_NEW_AUTHORIZATION_ACTION });

            if (payload.authorizationType === "GENERAL") {
                await AuthorizationsServiceClient.createNewAuthorization(
                    payload.personData,
                    context.getters.clientId,
                    context.getters.userId,
                    payload.locale,
                    payload.activityId,
                    payload.idDocumentoPdf
                );
                await context.dispatch(actionTypes.GET_AUTHORIZATIONS_BY_USER_ACTION, { triggerLoading: false });
            } else {
                await AuthorizationsServiceClient.createNewContractAuthorization(
                    payload.personData,
                    payload.contractNumbers,
                    context.getters.clientId,
                    context.getters.userId,
                    payload.locale,
                    payload.activityId,
                    payload.idDocumentoPdf
                );
                await context.dispatch(actionTypes.GET_AUTHORIZATIONS_BY_CONTRACT_ACTION, { triggerLoading: false });
            }

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.CREATE_NEW_AUTHORIZATION_ACTION });
        },
        async [actionTypes.DELETE_AUTHORIZATIONS_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.DELETE_AUTHORIZATIONS_ACTION });

            const result = await AuthorizationsServiceClient.deleteAuthorizations(
                payload.documentNumbers,
                payload.authorizationType,
                context.getters.clientId,
                context.getters.userId,
                payload.locale
            );

            if (result.status == 500) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.DELETE_AUTHORIZATIONS_ACTION });
                return Promise.reject(new Error(i18n.tc("ofex.authorizationsView.missedAuthorizationRevoked")));
            }

            if (payload.authorizationType === "GENERAL") {
                await context.dispatch(actionTypes.GET_AUTHORIZATIONS_BY_USER_ACTION, { triggerLoading: false });
            } else {
                await context.dispatch(actionTypes.GET_AUTHORIZATIONS_BY_CONTRACT_ACTION, { triggerLoading: false });
            }
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.DELETE_AUTHORIZATIONS_ACTION });
        },

        async [actionTypes.DELETE_UNIQUE_CONTRACT_AUTHORIZATION_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.DELETE_UNIQUE_CONTRACT_AUTHORIZATION_ACTION });

            await AuthorizationsServiceClient.deleteUniqueContractAuthorization(
                payload.documentNumbers,
                payload.contractNumber,
                context.getters.clientId,
                context.getters.userId,
                payload.locale
            );

            await context.dispatch(actionTypes.GET_AUTHORIZATIONS_BY_CONTRACT_ACTION, { triggerLoading: false });

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.DELETE_UNIQUE_CONTRACT_AUTHORIZATION_ACTION });
        },
        async [actionTypes.GET_USER_NOTIFICATIONS_ACTION](context, payload) {
            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_USER_NOTIFICATIONS_ACTION });
            }

            let notifications = await NotificationsServiceClient.getUserNotifications(
                context.getters.clientId,
                context.getters.userId,
                null,
                null,
                payload.locale
            );

            if (typeof notifications !== "undefined") {
                context.commit(mutationTypes.STORE_NOTIFICATIONS_MUTATION, { notifications: notifications });
            }

            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_USER_NOTIFICATIONS_ACTION });
            }
        },
        async [actionTypes.GET_UNREAD_NOTIFICATIONS_ACTION](context, payload) {
            let unreadNotifications = await NotificationsServiceClient.getUnreadUserNotifications(
                context.getters.clientId,
                context.getters.userId,
                null,
                null,
                payload.locale
            );

            if (typeof unreadNotifications !== "undefined") {
                context.commit(mutationTypes.STORE_UNREAD_NOTIFICATIONS_MUTATION, { unreadNotifications: unreadNotifications });
            }
        },
        async [actionTypes.GET_CONFIG_NOTIFICATIONS_ACTION](context, payload) {
            let notificationConfig = await NotificationsServiceClient.getConfigUserNotifications(
                context.getters.clientId,
                context.getters.userId,
                payload.locale
            );
            context.commit(mutationTypes.STORE_CONFIG_NOTIFICATIONS_CATEGORY_MUTATION, {
                configNotificationsList: notificationConfig.configNotificationsList,
            });
            context.commit(mutationTypes.STORE_CONFIG_NOTIFICATIONS_CONTRACTS_MUTATION, {
                notificationsContractList: notificationConfig.contractsDataList,
            });
        },
        async [actionTypes.UPDATE_CONFIG_NOTIFICATIONS_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_CONFIG_NOTIFICATIONS_ACTION });
            await NotificationsServiceClient.updateConfigUserNotifications(
                payload.clientId,
                payload.userId,
                payload.locale,
                payload.configNotificationsList,
                payload.contractsDataList
            );
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_CONFIG_NOTIFICATIONS_ACTION });
        },
        async [actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION](context, payload) {
            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION });
            }

            //recargamos las facturas del contrato
            let invoices = await InvoicesServiceClient.getUserInvoices(
                payload.contractNumber,
                context.getters.userId,
                context.getters.clientId,
                payload.locale
            );
            context.commit(mutationTypes.STORE_INVOICES_MUTATION, { invoices: invoices });

            let unpaidInvoices = await InvoicesServiceClient.getUserInvoices(
                payload.contractNumber,
                context.getters.userId,
                context.getters.clientId,
                payload.locale,
                0,
                "DEBT"
            );
            context.commit(mutationTypes.STORE_UNPAID_INVOICES_MUTATION, { unpaidInvoices: unpaidInvoices });

            context.commit(mutationTypes.CHANGE_ACTIVE_CONTRACT_MUTATION, { contractNumber: payload.contractNumber });

            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION });
            }
        },
        async [actionTypes.GET_LAST_INVOICES_ACTION](context, payload) {
            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_LAST_INVOICES_ACTION });
            }

            //recargamos las facturas del contrato
            let lastInvoices = await InvoicesServiceClient.getLastInvoices(
                context.getters.userId,
                context.getters.clientId,
                payload.locale,
                payload.pageNumber,
                payload.pageSize
            );
            context.commit(mutationTypes.STORE_LAST_INVOICES_MUTATION, { lastInvoices });

            if (payload.triggerLoading) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_LAST_INVOICES_ACTION });
            }
        },
        async [actionTypes.UPDATE_ALIAS](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_ALIAS });

            //TODO esto es necesario?
            context.commit(mutationTypes.CHANGE_ACTIVE_CONTRACT_MUTATION, { contractNumber: payload.contractNumber, alias: payload });

            //recargamos las facturas del contrato
            await ContractsServiceClient.updateAlias(context.getters.clientId, context.getters.userId, payload.contractNumber, payload.alias, payload.locale);

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_ALIAS });
        },
        async [actionTypes.UPDATE_FAVORITE](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_FAVORITE });

            //recargamos las facturas del contrato
            await ContractsServiceClient.updateFavorite(
                context.getters.clientId,
                context.getters.userId,
                payload.contractNumber,
                payload.favorite,
                context.state.userData,
                payload.locale
            );

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_FAVORITE });
        },
        async [actionTypes.UPDATE_CLIENT](context, payload) {
            //Modificamos un cliente
            if (payload.startLoading === true) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_CLIENT });
            }

            await CustomersServiceClient.modifyClient(context.getters.userId, context.getters.clientId, payload.localClientData, payload.pdf);

            if (payload.closeLoading === true) {
                context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_FAVORITE });
            }
        },
        async [actionTypes.UPDATE_USER](context, payload) {
            //Modificamos un usuario
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_USER });
            await CustomersServiceClient.modifyUser(context.getters.userId, context.getters.clientId, payload.localUserData, payload.pdf);
            //context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_FAVORITE });
        },
        async [actionTypes.UPDATE_LEGAL](context, payload) {
            //Modificamos legal
            // context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_USER });
            await CustomersServiceClient.updateLegal(
                context.getters.userId,
                context.getters.clientId,
                payload.legalUrl,
                payload.fspUrl,
                payload.contentForm,
                payload.userEmail,
                payload.locale
            );
            // context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_FAVORITE });
        },
        async [actionTypes.UPDATE_USER_CREDENTIALS](context, payload) {
            //Modificamos un usuario
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_USER_CREDENTIALS });
            if (context.state.userTokenPayload != null) {
                await CustomersServiceClient.updatePassword(
                    context.getters.userId,
                    context.getters.clientId,
                    payload.currentPassword,
                    payload.newPassword,
                    payload.locale
                );
            } else {
                await CustomersServiceClient.updatePassword(payload.userId, payload.clientId, payload.currentPassword, payload.newPassword, payload.locale);
            }
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_USER_CREDENTIALS });
        },
        async [actionTypes.RESET_USER_CREDENTIALS](context, payload) {
            //Resetea contraseña de un usuario
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.RESET_USER_CREDENTIALS });
            await CustomersServiceClient.resetPassword(payload.userId, payload.clientId, payload.email, payload.pdf, payload.locale);
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.RESET_USER_CREDENTIALS });
        },
        async [actionTypes.DELETE_USER](context, payload) {
            //Borrado de un usuario
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.DELETE_USER });
            await CustomersServiceClient.deleteUser(
                context.getters.userId,
                context.getters.clientId,
                payload.localUserData,
                payload.reason,
                payload.pdfDocumentId
            );
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_FAVORITE });
        },
        async [actionTypes.GET_PROFILE_ACTION](context, payload) {
            let profile = await LoginServiceClient.getProfile(payload.userId, payload.clientId);
            context.commit(mutationTypes.STORE_USER_DATA_MUTATION, { userData: profile.user_data });
            context.commit(mutationTypes.STORE_CLIENT_DATA_MUTATION, { clientData: profile.client_data });
        },
        async [actionTypes.UPDATE_PAYMENT_INFO](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_PAYMENT_INFO });
            await PaymentsServiceClient.modifyPayment(payload.userId, payload.clientId, payload.contractNumber, payload.formModel, payload.pdf);
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_PAYMENT_INFO });
        },
        async [actionTypes.UPDATE_MAIL_ADDRESS](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_MAIL_ADDRESS });
            await ContractsServiceClient.updateMailAddress(
                payload.clientId,
                payload.userId,
                payload.contractNumber,
                payload.formModel,
                payload.userData,
                payload.pdf,
                payload.activity
            );
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_MAIL_ADDRESS });
        },
        async [actionTypes.UPDATE_IAE](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_IAE });
            await ContractsServiceClient.updateIAE(
                payload.clientId,
                payload.userId,
                payload.contractNumber,
                payload.formModel,
                payload.userData,
                payload.pdf,
                payload.activity
            );
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_IAE });
        },
        async [actionTypes.UPDATE_CE](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_CE });
            await ContractsServiceClient.updateCE(
                payload.clientId,
                payload.userId,
                payload.contractNumber,
                payload.formModel,
                payload.userData,
                payload.pdf,
                payload.activity
            );
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_CE });
        },
        async [actionTypes.DELETE_CONTRACT](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.DELETE_CONTRACT });
            await ContractsServiceClient.deleteContract(
                payload.clientId,
                payload.userId,
                payload.contractNumber,
                payload.userData,
                payload.formModel,
                payload.pdf,
                payload.activity
            );
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.DELETE_CONTRACT });
        },
        async [actionTypes.UPDATE_CONTRACTS_ASSIGNED](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_CONTRACTS_ASSIGNED });
            await ContractsServiceClient.updateContractsAssignations(
                context.getters.clientId,
                context.getters.userId,
                payload.assignedList,
                payload.unassignedList,
                payload.locale
            );
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_CONTRACTS_ASSIGNED });
        },
        async [actionTypes.UPDATE_FSP](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.UPDATE_FSP });
            await InvoicesServiceClient.updateFSP(
                context.getters.clientId,
                context.getters.userId,
                payload.active,
                payload.fspUrl,
                payload.pdf,
                payload.contractsNumbers
            );
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.UPDATE_FSP });
        },
        async [actionTypes.GET_VALIDATE_TOKEN](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_VALIDATE_TOKEN });
            let userData = await PromosServiceClient.getValidateToken(payload.token);
            context.commit(mutationTypes.STORE_USER_DATA_MUTATION, { userData: userData });
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_VALIDATE_TOKEN });
        },
        async [actionTypes.GET_USER_HISTORY_ACTION](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_USER_HISTORY_ACTION });
            await CustomersServiceClient.historyClient(payload.userId, payload.clientId, payload.lang);
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_USER_HISTORY_ACTION });
        },
        async [actionTypes.UPDATE_PARTIAL_AUTHORIZATIONS](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true });
            console.debug(context.getters.clientId, context.getters.userId, payload);
            await AuthorizationsServiceClient.updatePartialAuthorizations(context.getters.clientId, context.getters.userId, payload);
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        },
        async [actionTypes.GET_RECTIFYING_INVOICES](context, payload) {
            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true, trigger: actionTypes.GET_RECTIFYING_INVOICES });

            let rectifyingInvoicesResponse = await InvoicesServiceClient.getRectifyingInvoices(
                context.getters.clientId,
                context.getters.userId,
                payload.locale
            );

            console.log(rectifyingInvoicesResponse);

            if (rectifyingInvoicesResponse.metadata.warningCode === "20") {
                Swal.fire({
                    showCloseButton: true,
                    showCancelButton: false,
                    text: i18n.tc("ofex.rectifying.warning20"),
                    icon: "warning",
                });
            }

            context.commit(mutationTypes.STORE_RECTIFYING_INVOICES, { rectifyingInvoices: rectifyingInvoicesResponse.data });

            context.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false, trigger: actionTypes.GET_RECTIFYING_INVOICES });
        },
        async [actionTypes.GET_DROUGHT_CONFIGURATION_ACTION](context, payload) {
            let droughtConfiguration = await DroughtServiceClient.getDroughtConfiguration(context.getters.userId, context.getters.clientId, payload.locale);

            context.commit(mutationTypes.STORE_DROUGHT_CONFIGURATION, { droughtConfiguration });
        },
    },
    modules: {},
    getters: {
        /**
         * Comprobamos que el token no esté expirado ni sea nulo (no se puede verificar la firma desde el front, pero fallará si es inválido)
         * @param state
         * @returns {boolean}
         */
        isAuthenticated: (state) => {
            let tokenPayload = state.userTokenPayload ? state.userTokenPayload : Vue.$cookies.get("ofexTokenPayload");
            if (tokenPayload) {
                try {
                    let notValidBefore = moment.unix(tokenPayload.nbf).subtract(1, "minutes");
                    let expirationDate = moment.unix(tokenPayload.exp);
                    let now = moment();
                    return now.isBefore(expirationDate) && now.isAfter(notValidBefore);
                } catch (err) {
                    console.error(err);
                    return false;
                }
            } else {
                return false;
            }
        },
        clientId: (state, getters) => {
            return getters.customClientId(state.userTokenPayload.name);
        },
        userId: (state, getters) => {
            return getters.customUserId(state.userTokenPayload.name);
        },
        customClientId: (state) => (username) => {
            if (typeof username !== "undefined" && username !== null && username.length > 0) {
                return username.substring(0, 9).toUpperCase();
            } else {
                return state.clientData.documentNumber.toUpperCase();
            }
        },
        customUserId: (state) => (username) => {
            if (typeof username !== "undefined" && username !== null && username.length > 0) {
                return username.length > 9 ? username.substr(9).toUpperCase() : username.substring(0, 9).toUpperCase();
            } else {
                return state.userData.documentNumber.toUpperCase();
            }
        },
        //monta el listado de contratos para los selects
        contractsSummaryList: (state) =>
            state.contracts
                ? state.contracts.map(function (contract) {
                      let text = `${contract.contractDetail.contractNumber} - ${contract.contractDetail.alias ? contract.contractDetail.alias + " - " : ""}
                        ${i18n.t("ofex.backendCodes.streetTypes." + trimBackendCode(contract.supplyAddress.streetType), state.i18nLocale)} ${
                          contract.supplyAddress.streetName
                      }, ${contract.supplyAddress.number},
                        ${contract.supplyAddress.otherAddressData},
                        ${contract.supplyAddress.postalCode},
                        ${contract.supplyAddress.municipality}
                        ${
                            contract.contractDetail.supplyStatus !== "EN VIGOR" && contract.contractDetail.supplyStatus !== "CONTRACTAT I NO INSTAL.LAT"
                                ? ", " + i18n.t("ofex.backendCodes.contractStatus.DISABLED")
                                : ""
                        }`;
                      return {
                          key: contract.contractDetail.contractNumber,
                          text: text,
                          pending: contract.contractDetail.invoicesPending,
                          inactive:
                              contract.contractDetail.supplyStatus === "EN VIGOR" || contract.contractDetail.supplyStatus === "CONTRACTAT I NO INSTAL.LAT"
                                  ? false
                                  : true,
                      };
                  })
                : [],
        activeContractsSummaryList: (state) =>
            state.contracts
                ? state.contracts
                      .filter((value) => value.contractDetail.supplyStatus === "EN VIGOR" || value.contractDetail.supplyStatus === "CONTRACTAT I NO INSTAL.LAT")
                      .map(function (contract) {
                          let text = `${contract.contractDetail.contractNumber} - ${contract.contractDetail.alias ? contract.contractDetail.alias + " - " : ""}
                                ${i18n.t("ofex.backendCodes.streetTypes." + trimBackendCode(contract.supplyAddress.streetType), state.i18nLocale)} ${
                              contract.supplyAddress.streetName
                          }, ${contract.supplyAddress.number},
                                ${contract.supplyAddress.otherAddressData},
                                ${contract.supplyAddress.postalCode},
                                ${contract.supplyAddress.municipality}`;
                          return {
                              key: contract.contractDetail.contractNumber,
                              text: text,
                              pending: contract.contractDetail.invoicesPending,
                          };
                      })
                : [],
        invoiceTypeSelect: (state) => {
            return state.userInvoices.map((value) => {
                return {
                    text: `${value.startDate} a ${value.endDate}`,
                    invoiceNumber: value.invoiceNumber,
                    key: `${value.startDate} ${value.endDate}`,
                };
            });
        },
        consumptionInvoiceTypeSelect: (state, getters) => {
            return state.userInvoices
                .filter((value) => moment(value.endDate, AB_DATE_FORMAT).isAfter(getters.consumptionTelerecordingFilterMinDate(value)))
                .map((value) => {
                    return {
                        text: `${value.startDate} a ${value.endDate}`,
                        invoiceNumber: value.invoiceNumber,
                        key: `${value.startDate} ${value.endDate}`,
                    };
                });
        },
        consumptionTelerecordingFilterMinDate: () => (invoice) => {
            return moment.max([moment(invoice.telerecordingDate, AB_DATE_FORMAT), moment().subtract(36, "months")]);
        },
        // TODO los dos se pueden unificar cambiando el parámetro por tipo fecha y pasándolo desde fuera
        consumptionTelerecordingFilterMinDateWhenNotHasInvoices: (state, getters) => () => {
            return moment.max([
                moment(getters.getContractByContractNumber(state.selectedContractNumber).contractDetail.registrationDate, AB_DATE_FORMAT),
                moment().subtract(36, "months"),
            ]);
        },
        getContractSupplyCompleteAddress: (state, getters) => (contractNumber) => {
            let contractEntry = getters.getContractByContractNumber(contractNumber);
            return (
                i18n.tc(`ofex.backendCodes.streetTypes.${trimBackendCode(contractEntry.supplyAddress.streetType)}`) +
                " " +
                contractEntry.supplyAddress.streetName +
                " " +
                contractEntry.supplyAddress.number +
                ", " +
                contractEntry.supplyAddress.otherAddressData +
                ", " +
                contractEntry.supplyAddress.postalCode +
                ", " +
                contractEntry.supplyAddress.municipality
            );
        },
        getContractByContractNumber: (state) => (contractNumber) => {
            return state.contracts.filter((contract) => contract.contractDetail.contractNumber === contractNumber)[0];
        },
        isFavoriteContract: (state, getters) => (contractNumber) => {
            let contract = getters.getContractByContractNumber(contractNumber);
            return contract && contract.contractDetail.favorite;
        },
        fullName: (state) => `${state.userData.firstName} ${state.userData.firstLastName}`,
        multicontractOfex: (state) => state.clientData && state.clientData.multicontract,
        multicontract: (state, getters) => getters.multicontractOfex && state.contracts.length > 1,
        multiActiveContract: (state) => state.activeContracts.length > 1,
        contractsLoaded: (state) => state.contracts.length > 0,
        isCompany: (state) =>
            state.clientData &&
            state.clientData.documentNumber &&
            state.userData.documentNumber &&
            state.clientData.documentNumber !== state.userData.documentNumber,
        lastInvoice: (state) => first(state.userInvoices),
        selectedContractTelerecording: (state, getters) =>
            state.selectedContractNumber && getters.getContractByContractNumber(state.selectedContractNumber).contractDetail.telerecording,
        selectedContractWithPendingInvoices: (state, getters) =>
            state.selectedContractNumber && getters.getContractByContractNumber(state.selectedContractNumber).contractDetail.invoicesPending,
        selectedContractDroughtAffected: (state, getters) =>
            state.selectedContractNumber && isDroughtAffected(getters.getContractByContractNumber(state.selectedContractNumber)),
        selectedInactiveContract: (state, getters) => {
            let contract = getters.getContractByContractNumber(state.selectedContractNumber);
            if (contract) {
                let status = contract.contractDetail.supplyStatus;
                return status !== "EN VIGOR" && status !== "CONTRACTAT I NO INSTAL.LAT";
            }
            return false;
        },

        showHomeDroughtModule: (state, getters) =>
            getters.getContractByContractNumber(state.selectedContractNumber)?.droughtData?.maxExpectedAffectationCode?.length > 0,
        selectedContractIsDomestic: (state, getters) =>
            state.selectedContractNumber && getters.getContractByContractNumber(state.selectedContractNumber).contractDetail.useTypeCode === "DOMESTIC",
        pendingInvoices: (state) => state.contracts.filter((value) => value.contractDetail.invoicesPending).length > 0,
        hasAnyTelerecording: (state) => state.contracts.filter((value) => value.contractDetail.telerecording).length > 0,
        hasAnyDomesticContract: (state) => state.contracts.some((value) => value.contractDetail.useTypeCode === "DOMESTIC"),
        hasAnyActiveContract: (state) => state.activeContracts.length > 0,
        hasAnyInactiveContract: (state) => state.contracts.some((value) => value.contractDetail.supplyStatus.includes("BAIXA")),
        hasAnyIndustrialOrComercialContract: (state) =>
            state.contracts.some((value) => value.contractDetail.useTypeCode === "INDUSTRIAL" || value.contractDetail.useTypeCode === "COMERCIAL"),
        hasAnyDroughtAffectedContract: (state) => state.contracts.some((value) => isDroughtAffected(value)),
        hasInvoices: (state) => state.userInvoices && state.userInvoices.length > 0,
        contractsWithTelerecording: (state) => state.contracts.filter((value) => value.contractDetail.telerecording === true),
        activeContractsWithTelerecording: (state) => state.activeContracts.filter((value) => value.contractDetail.telerecording === true),
        getContractsWithDigitalInvoices: (state) => {
            return state.contracts.filter(
                (value) =>
                    value.contractDetail.invoiceDeliveryType !== "FP" &&
                    (value.contractDetail.supplyStatus === "EN VIGOR" || value.contractDetail.supplyStatus === "CONTRACTAT I NO INSTAL.LAT")
            );
        },
        getContractsWithPaperInvoices: (state) => {
            return state.contracts.filter(
                (value) =>
                    value.contractDetail.invoiceDeliveryType === "FP" &&
                    (value.contractDetail.supplyStatus === "EN VIGOR" || value.contractDetail.supplyStatus === "CONTRACTAT I NO INSTAL.LAT")
            );
        },
        getContractNumbersByAuthorizationPerson: (state) => (documentNumber) => {
            return state.authorizationsByContract
                .filter((e) => e.authorizations.map((a) => a.documentNumber).includes(documentNumber))
                .map((e) => e.contractNumber);
        },
        getInvoiceByInvoiceNumber: (state) => (invoiceId) => {
            return state.userInvoices.filter((userInvoice) => userInvoice.invoiceNumber === invoiceId)[0];
        },
        isContractDigitalInvoiceDisabled: (state, getters) => (contractNumber) => {
            let contract = getters.getContractByContractNumber(contractNumber);
            return contract && contract.contractDetail.invoiceDeliveryType === "FP";
        },
        getUnpaidInvoiceByInvoiceNumber: (state) => (invoiceId) => {
            return state.unpaidInvoices.filter((userInvoice) => userInvoice.invoiceNumber === invoiceId)[0];
        },
        getNotificationById: (state) => (id) => {
            return [
                ...(state.notifications !== null ? state.notifications : []),
                ...(state.unreadNotifications !== null ? state.unreadNotifications : []),
            ].filter((notification) => notification.id === id)[0];
        },
        totalUnpaidAmount: (state) =>
            state.unpaidInvoices && state.unpaidInvoices.length > 0 ? state.unpaidInvoices.map((i) => i.amount).reduce((a, b) => a + b) : null,
        showLegalCheckFlow: (state) => {
            return (
                (state.clientData.advertising === null || state.clientData.surveys === null || state.clientData.profiling === null) &&
                state.clientData.passwordlessLogin !== true
            );
        },
        legalUrls: () => {
            return {
                urEs: process.env.VUE_APP_LEGAL_UR_HTML_ES_URL,
                urCa: process.env.VUE_APP_LEGAL_UR_HTML_CA_URL,
                fspEs: process.env.VUE_APP_LEGAL_FSP_HTML_ES_URL,
                fspCa: process.env.VUE_APP_LEGAL_FSP_HTML_CA_URL,
                gdprEs: process.env.VUE_APP_GDPR_HTML_ES_URL,
                gdprCa: process.env.VUE_APP_GDPR_HTML_CA_URL,
            };
        },
    },
    strict: process.env.NODE_ENV !== "production",
});
