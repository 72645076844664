<i18n src="./common/i18n-components.i18n"></i18n>
<template>
    <div>
        <validation-provider :name="inputAlias" :vid="`${name}-vid`" :rules="validation" :debounce="validationDelay" v-slot="{ errors, classes }">
            <div class="row">
                <div class="col-12 c-autocompletable">
                    <label class="letraform-new w-100" :for="name">
                        {{ label }}
                    </label>
                    <input
                        :id="name"
                        :ref="reference"
                        v-model="modelValue"
                        class="ab-form-input specialSafari pl-2 inputAuto"
                        :name="name"
                        placeholder="Seleccionar"
                        aria-expanded="false"
                        :class="classes"
                        @awesomplete-open="handleOpen"
                        @awesomplete-close="handleClose"
                        @awesomplete-selectcomplete="applySelection"
                    />

                    <ab-popover :popover="popover" />
                </div>
            </div>

            <p v-show="errors.length > 0" class="formTextErr displayYes">{{ errors[0] }}</p>
        </validation-provider>
    </div>
</template>

<script>
import AbPopover from "./AbPopover.vue";
import Awesomplete from "awesomplete/awesomplete";

export default {
    components: {
        AbPopover,
    },
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: String,
        inputAlias: String,
        popover: String,
        label: String,
        placeholder: String,
        validation: Object,
        validationDelay: {
            type: Number,
            default: 300,
        },
        reference: Object,
        dataList: Array,
        minChars: Number,
        autoFirst: Boolean,
        dataControl: {
            default: function (item) {
                return { label: item, value: item };
            },
            type: Function,
        },
        selectedFilter: {
            // eslint-disable-next-line no-unused-vars
            default: function (dataList, val, label) {
                console.debug(`Selected filter. Datatalist ${dataList}, Val ${val}, Label ${label}`);
                return val;
            },
            type: Function,
        },
    },
    data: function () {
        return {
            selected: null,
            isValid: false,
            awesomplete: null,
        };
    },
    computed: {
        modelValue: {
            get: function () {
                let value = this.$attrs["inputValue"];
                return value;
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    watch: {
        dataList: {
            immediate: true,
            // eslint-disable-next-line no-unused-vars
            handler(val) {
                console.debug("Datalist has changed");
                this.recreateAwesomplete();
            },
        },
    },
    mounted() {
        console.debug("AutocompletableInput " + this.name + " mounted");
        console.debug("Datalist size: " + this.dataList.length);
        this.$nextTick(function () {
            console.debug("Recreating awesomplete");
            this.recreateAwesomplete();
        });
    },
    methods: {
        applySelection: function (e) {
            console.debug("Apply selection: " + e.text.value);
            this.modelValue = e.text.value;
            this.selected = this.selectedFilter(this.dataList, e.text.value, e.text.label);
            this.$emit("autocompletableSelected", this.selected);
        },
        recreateAwesomplete: function () {
            console.debug("Recreating awesomplete");
            if (this.dataList.length > 0) {
                if (this.awesomplete !== null) this.awesomplete.destroy();

                var addressInput = document.getElementById(this.name);
                // eslint-disable-next-line no-undef
                this.awesomplete = new Awesomplete(addressInput, {
                    minChars: this.minChars,
                    autoFirst: this.autoFirst,
                    list: this.dataList,
                    data: this.dataControl,
                    // eslint-disable-next-line no-undef
                    filter: Awesomplete.FILTER_STARTSWITH,
                    //RegExp("^" + $.regExpEscape(input.trim()), "i").test(text);
                });
            }
        },
        handleOpen: function () {
            document.getElementById(this.name).setAttribute("aria-expanded", "true");
        },
        handleClose: function () {
            document.getElementById(this.name).setAttribute("aria-expanded", "true");
        },
    },
};
</script>
<style lang="scss" scoped>
label {
    line-height: 0px;
}

.inputAuto {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #979797;
    height: 32px;
    @include respond-below(sm) {
        height: 40px;
    }
}

.specialSafari::-webkit-contacts-auto-fill-button,
::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
}
</style>
<style lang="scss">
.c-autocompletable .awesomplete {
    display: block;
}
.c-autocompletable {
    display: flex;
    flex-wrap: wrap;
}
</style>
