<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <dropdown>
        <template slot="btn">{{ label }}</template>
        <template slot="icon">
            <font-awesome-icon class="icon" :icon="nameIcon"></font-awesome-icon>
        </template>
        <template slot="body">
            <ul class="dropdown-list">
                <li class="item" v-on:click.stop.prevent="item.callback" v-for="item in dataList">
                    <router-link v-if="!item.callback" class="link" :to="item.path">{{ item.text }}</router-link>
                    <a href="#" class="link" v-else>{{ item.text }}</a>
                </li>
            </ul>
        </template>
    </dropdown>
</template>

<script>
import Dropdown from "bp-vuejs-dropdown";

export default {
    name: "AbDropdown",
    components: {
        Dropdown,
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        nameIcon: {
            type: String,
        },
        dataList: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
};
</script>

<style lang="scss">
.bp-dropdown__btn--active {
    background: transparent;
}
</style>
