"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import { trimBackendCode } from "@/plugins/ab-utils";
import moment from "moment";

import { AB_DATETIME_FORMAT, AB_DATETIME_FRIENDLY_FORMAT } from "@/plugins/ab-utils";
const ACTIVITY_CODE = "PS";
export default class LastReadingsListPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId) {
        super("UltimasLecutras.pdf", ACTIVITY_CODE, i18n, userId, clientId, null);
    }

    generatePdf(vueContext, contracts, consumptions) {
        const SPACE = " ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.lastReading.title"), "");

        var head = [
            [
                `${this.i18n.tc("ofex.tuContrato.contractNumber")}`,
                `${this.i18n.tc("ofex.tuContrato.contractAlias")}`,
                `${this.i18n.tc("ofex.tuContrato.supplyAddress")}`,
                `${this.i18n.tc("ofex.lastReading.fechaUltimaLectura")}`,
                `${this.i18n.tc("ofex.lastReading.ultimaLectura")}`,
                `${this.i18n.tc("ofex.lastReading.ultimoConsumo")}`,
            ],
        ];
        var body = [consumptions.length];
        consumptions.forEach(
            (i, index) =>
                (body[index] = getContractNumber(i.contractId, contracts)
                    ? [
                          `${i.contractId}`,
                          `${getContractNumber(i.contractId, contracts).contractDetail.alias}`,
                          `${
                              this.i18n.tc(
                                  "ofex.backendCodes.streetTypes." + trimBackendCode(getContractNumber(i.contractId, contracts).supplyAddress.streetType)
                              ) +
                              SPACE +
                              getContractNumber(i.contractId, contracts).supplyAddress.streetName +
                              SPACE +
                              getContractNumber(i.contractId, contracts).supplyAddress.number +
                              SPACE +
                              getContractNumber(i.contractId, contracts).supplyAddress.otherAddressData +
                              SPACE +
                              getContractNumber(i.contractId, contracts).supplyAddress.municipality +
                              SPACE
                          }`,
                          `${moment(i.lastConsumptionDateTime, AB_DATETIME_FORMAT).format(AB_DATETIME_FRIENDLY_FORMAT)}`,
                          `${vueContext.$formatNumber(i.realMeterIndex)} m³`,
                          `${i.consumption} m3`,
                      ]
                    : "")
        );
        this.generateAutoTable(head, body, 4);
        return this.pdf;
    }
}

function getContractNumber(contractNumber, contracts) {
    return contracts.filter((value) => value.contractDetail.contractNumber === contractNumber)[0];
}
