<template>
    <div class="invoice-consumption-section mt-3 mb-5">
        <p>
            {{ $t("ofex.consumptionCalculatedView.consumption-last-bill") }}:
            <span>{{ tendencyConsumptionSinceLastBill }}</span>
        </p>
        <div class="invoice-consumption-section__graphics-section">
            <template v-if="!isSimilarConsumption(tendencyAnualAverageConsumptionDelta)">
                <!-- IF CONSUMPTION IS BIGGER OR LOWER  -->
                <div class="invoice-consumption-section__graphics-section__graphic">
                    <p>
                        {{
                            isTendencyConsumptionBigger(tendencyAnualAverageConsumptionDelta)
                                ? $t("ofex.consumptionCalculatedView.increased")
                                : $t("ofex.consumptionCalculatedView.decreased")
                        }}
                    </p>
                    <div class="invoice-consumption-section__graphics-section__graphic__details">
                        <img
                            v-if="isTendencyConsumptionBigger(tendencyAnualAverageConsumptionDelta)"
                            src="@/assets/images/svg/Flecha-roja.svg"
                            alt="señal cualitativa de aumento de consumo"
                            width="29px"
                            height="29px"
                        />
                        <img v-else src="@/assets/images/svg/Flecha-verde.svg" alt="señal cualitativa de aumento de consumo" width="29px" height="29px" />
                        <p :class="isTendencyConsumptionBigger(tendencyAnualAverageConsumptionDelta) ? 'red-text' : 'green-text'">
                            {{ Math.abs(tendencyAnualAverageConsumptionDelta) + " l/dia" }}
                        </p>
                    </div>
                    <p>
                        {{ $t("ofex.consumptionCalculatedView.related-to-consumption") }}
                        <strong>{{ $t("ofex.consumptionCalculatedView.anual") }}</strong>
                    </p>
                </div>
            </template>

            <template v-else>
                <!-- IF CONSUMPTION IS SIMILAR (+-1)  -->
                <div class="invoice-consumption-section__graphics-section__graphic">
                    <p>
                        {{ $t("ofex.consumptionCalculatedView.maintained-consumption") }}
                    </p>
                    <div class="invoice-consumption-section__graphics-section__graphic__details">
                        <img src="@/assets/images/svg/Igual.svg" alt="señal cualitativa de igualdad de consumo" width="29px" height="29px" />
                        <p class="invoice-consumption-section__graphics-section__graphic__details__similar-text">
                            {{ $t("ofex.consumptionCalculatedView.similar") }}
                        </p>
                    </div>
                    <p>
                        {{ $t("ofex.consumptionCalculatedView.related-to-consumption") }}
                        <strong>{{ $t("ofex.consumptionCalculatedView.anual") }}</strong>
                    </p>
                </div>
            </template>

            <template v-if="tendencyAnualDelta !== undefined">
                <!-- Case when tendency has 1 year old invoice -->
                <template v-if="!isSimilarConsumption(tendencyAnualDelta)">
                    <div class="invoice-consumption-section__graphics-section__graphic">
                        <p>
                            {{
                                isTendencyConsumptionBigger(tendencyAnualDelta)
                                    ? $t("ofex.consumptionCalculatedView.increased")
                                    : $t("ofex.consumptionCalculatedView.decreased")
                            }}
                        </p>
                        <div class="invoice-consumption-section__graphics-section__graphic__details">
                            <img
                                v-if="isTendencyConsumptionBigger(tendencyAnualDelta)"
                                src="@/assets/images/svg/Flecha-roja.svg"
                                alt="señal cualitativa de aumento de consumo"
                                width="29px"
                                height="29px"
                            />
                            <img v-else src="@/assets/images/svg/Flecha-verde.svg" alt="señal cualitativa de aumento de consumo" width="29px" height="29px" />
                            <p :class="isTendencyConsumptionBigger(tendencyAnualDelta) ? 'red-text' : 'green-text'">
                                {{ Math.abs(tendencyAnualDelta) + " l/día" }}
                            </p>
                        </div>
                        <p>
                            {{ $t("ofex.consumptionCalculatedView.related-to-consumption") }}
                            <strong>{{ $t("ofex.consumptionCalculatedView.periodic") }}</strong>
                        </p>
                    </div>
                </template>

                <template v-else>
                    <!-- IF CONSUMPTION IS SIMILAR (+-1)  -->
                    <div class="invoice-consumption-section__graphics-section__graphic">
                        <p>
                            {{ $t("ofex.consumptionCalculatedView.maintained-consumption") }}
                        </p>
                        <div class="invoice-consumption-section__graphics-section__graphic__details">
                            <img src="@/assets/images/svg/Igual.svg" alt="señal cualitativa de igualdad de consumo" width="29px" height="29px" />
                            <p class="invoice-consumption-section__graphics-section__graphic__details__similar-text">
                                {{ $t("ofex.consumptionCalculatedView.similar") }}
                            </p>
                        </div>
                        <p>
                            {{ $t("ofex.consumptionCalculatedView.related-to-consumption") }}
                            <strong>{{ $t("ofex.consumptionCalculatedView.periodic") }}</strong>
                        </p>
                    </div>
                </template>
            </template>

            <template v-else>
                <p v-html="noData" class="invoice-consumption-section__graphics-section__no-data"></p>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment/moment";

export default {
    name: "AbTendencyConsumption",
    data: function () {
        return {
            loadingText: this.$t("ofex.consumptionCalculatedView.loading"),
        };
    },
    computed: {
        ...mapState(["userInvoices"]),

        tendencyConsumptionSinceLastBill() {
            if (this.userInvoices[0].consumptionVolume === 0) return "0 m³ - 0 l/día";

            return `${this.userInvoices[0].consumptionVolume} m³ - ${(
                (this.userInvoices[0].consumptionVolume / this.userInvoices[0].consumptionDays) *
                1000
            ).toFixed(0)} l/día`;
        },
        tendencyAnualDelta() {
            const consumptionDate = moment(this.userInvoices[0].startDate, "DD-MM-YYYY")
                .subtract(1, "year")
                .add(this.userInvoices[0].consumptionDays / 2, "days")
                .format("DD-MM-YYYY");

            /*handle the case when contract change the invoiceing frequency*/
            let lastYearBill = this.userInvoices.find((invoice) =>
                moment(consumptionDate, "DD-MM-YYYY").isBetween(moment(invoice.startDate, "DD-MM-YYYY"), moment(invoice.endDate, "DD-MM-YYYY"), undefined, "[]")
            );
            if (!lastYearBill) return undefined;

            let lastYearLPD = 0;
            let thisYearLPD = 0;

            if (lastYearBill.consumptionDays !== 0) {
                lastYearLPD = (lastYearBill.consumptionVolume * 1000) / lastYearBill.consumptionDays;
            }
            if (this.userInvoices[0].consumptionDays !== 0) {
                thisYearLPD = (this.userInvoices[0].consumptionVolume * 1000) / this.userInvoices[0].consumptionDays;
            }

            return (thisYearLPD - lastYearLPD).toFixed(0);
        },
        tendencyAnualAverageConsumptionDelta() {
            let days = 0;
            let consumption = 0;

            for (let i = 0; i < this.userInvoices.length && days < 355; ++i) {
                /*calculate last year consumption if posible with agnostic invoice frequency parameter*/
                const invoice = this.userInvoices[i];
                if (invoice.consumptionVolume || invoice.consumptionVolume === 0) {
                    consumption += invoice.consumptionVolume;
                    days += invoice.consumptionDays;
                }
            }

            if (consumption === 0) return 0;

            const averageConsumption = (consumption / days) * 1000;
            const lastMonthAverageConsumption = (this.userInvoices[0].consumptionVolume * 1000) / this.userInvoices[0].consumptionDays;
            /*consumption delta in litres per day*/
            return (lastMonthAverageConsumption - averageConsumption).toFixed(0);
        },
        noData() {
            return `
                    ${this.$t("ofex.consumptionCalculatedView.no-data-1")} <strong>${this.$t(
                "ofex.consumptionCalculatedView.no-data-last-year"
            )}</strong> ${this.$t("ofex.consumptionCalculatedView.no-data-2")}`;
        },
    },
    methods: {
        isTendencyConsumptionBigger(delta) {
            return delta > 0;
        },
        isSimilarConsumption(delta) {
            return Math.abs(delta) <= 1;
        },
    },
};
</script>

<style scoped lang="scss">
.invoice-consumption-section {
    display: flex;
    flex-direction: column;

    & > p > span {
        font-weight: bolder;
        color: $blue-lightest;
    }

    &__graphics-section {
        display: flex;
        gap: 60px;
        margin-top: 20px;
        place-content: center;

        @include respond-below(sm) {
            flex-direction: column;
            gap: 40px;
            place-items: center;
        }

        &__no-data {
            width: 245px;
            text-align: center;
        }

        &__graphic {
            display: flex;
            flex-direction: column;
            width: 245px;
            place-items: center;

            p {
                text-align: center;
                margin: 0;

                strong {
                    display: block;
                }
            }

            &__details {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 5px 0;
                gap: 10px;

                .green-text {
                    color: $success-color;
                    font-weight: bolder;
                    font-size: 18px;
                }

                .red-text {
                    color: $red-danger;
                    font-weight: bolder;
                    font-size: 18px;
                }

                p {
                    margin: 0;
                }

                &__similar-text {
                    color: $bg-primary;
                    font-weight: bolder;
                    font-size: 18px;
                }
            }
        }
    }
}
</style>
