<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <validation-provider
            :mode="validationMode"
            :name="inputAliasComputed"
            :vid="`${name}-vid`"
            :rules="validation"
            :debounce="validationDelay"
            ref="validationProvider"
            v-slot="{ errors, classes }"
        >
            <div class="input-container">
                <label v-if="showLabel" :class="isLogin ? 'labels-login' : 'letraform-new'" :for="name">
                    {{ label }}
                </label>

                <!--          :class="{ inputFormErr: failed }"-->

                <div class="inputPrincipal d-flex">
                    <input
                        :id="name"
                        :title="title"
                        :ref="reference"
                        v-model="modelValue"
                        :maxlength="maxLength"
                        :class="{ ...cssClassListMerged, ...classes }"
                        :name="name"
                        :placeholder="placeholder"
                        :type="type"
                        :autocomplete="autocomplete"
                        :readonly="readonly"
                        :disabled="disabled"
                        lang="es"
                        xml:lang="es"
                        :aria-label="title"
                        @focusin="focusInEvent"
                        @paste.self="handlePaste($event)"
                        @blur="eventBlur"
                    />

                    <div v-if="rightIconSrc" class="float-left input-right-button" @click="handleRightButtonClick">
                        <span class="button-show">
                            <img class="icon" alt="icono de ayuda a campo de texto" :src="rightIconSrc" />
                        </span>
                    </div>

                    <div v-else-if="rightButton" class="float-left input-right-button" @click="handleRightButtonClick">
                        <span class="button-show">
                            <!-- {{ rightButton }} -->
                            <img v-if="showPassword" class="icon" alt="estrella" src="../assets/images/svg/eye-slash.svg" />
                            <img v-else class="icon" alt="estrella" src="../assets/images/svg/eye.svg" />
                        </span>
                    </div>
                </div>
                <ab-popover :popover="popover" />
                <p v-show="errors.length > 0" class="formTextErr displayYes">{{ errors[0] }}</p>
            </div>
        </validation-provider>
    </div>
</template>

<script>
import AbPopover from "./AbPopover.vue";
import keyBy from "lodash/keyBy";

export default {
    components: {
        AbPopover,
    },
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        title: {
            type: String,
        },
        inputAlias: {
            type: String,
        },
        label: {
            type: String,
        },
        showLabel: {
            type: Boolean,
            default: true,
        },
        validation: {
            type: Object,
        },
        validationDelay: {
            type: Number,
            default: 300,
        },
        validationMode: {
            type: String,
            default: "eager",
        },
        maxLength: {
            type: Number,
        },
        type: {
            type: String,
            default: "text",
        },
        popover: {
            type: String,
        },
        placeholder: {
            type: String,
        },
        autocomplete: {
            type: String,
            default: "off",
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        pasteable: {
            type: Boolean,
            default: true,
        },
        rightButton: {
            type: String,
            required: false,
        },
        rightIconSrc: {
            type: String,
            required: false,
        },
        cssClassList: {
            type: Array,
            default: function () {
                return [];
            },
        },
        reference: {
            type: String,
        },
        isLogin: {
            type: Boolean,
            default: false,
        },
        focusInEvent: {
            type: Function,
            default: function () {},
        },
        eventBlur: {
            type: Function,
            default: function () {},
        },
    },
    data: function () {
        return {
            showPassword: false,
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["inputValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
        cssClassListMerged: function () {
            let cssClassListMerged = Array.from(this.cssClassList);
            cssClassListMerged.push("ab-form-input");
            cssClassListMerged.push("inputConInfo");

            return keyBy(cssClassListMerged);
        },
        inputAliasComputed: function () {
            return this.inputAlias ? this.inputAlias : this.label.toLowerCase().replace("*", "").replace(":", "");
        },
    },
    methods: {
        handlePaste: function (event) {
            console.debug("Handling paste");
            console.debug(event);

            if (this.pasteable) {
                return true;
            } else {
                event.preventDefault();
            }
        },
        handleRightButtonClick: function () {
            this.showPassword = !this.showPassword;
            this.$emit("right-button");
        },
    },
};
</script>

<style lang="scss" scoped>
.button-show {
    color: $border-table;
    font-weight: $font-primary-semiBold;
    @include font-size(12);
    @include line-height(14);
}
.input-container {
    display: flex;
    flex-wrap: wrap;

    @include respond-below(md) {
        margin-bottom: 20px;
    }
    label {
        flex-basis: 100%;
    }
    .inputPrincipal {
        flex: 1;
    }
    .spacePopover {
        align-self: center;
    }
    .formTextErr {
        flex-basis: 100%;
    }
}
input {
    height: 40px;
    font-weight: $font-primary-medium;
    color: $color-dark;
    @include font-size(14);
    @include line-height(16);
    appearance: none;
    padding-right: 8px;
    padding-left: 8px;

    &::placeholder {
        color: $border-table;
    }
    &.inputFormErr {
        border: 1px solid #f48989;
        background-color: #feefef;
    }
    &:focus {
        outline: none;
    }
    @include respond-above(sm) {
        height: 32px;
    }
}
input:read-only {
    background-color: #e5e5e5;
}
</style>
