"use strict";

import * as xlsx from "xlsx";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";

export default class BaseXlsxGenerator {
    file;
    workbook;

    constructor(filename, data) {
        this.workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(this.workbook, data, "Contratos");

        /* calculate column width */
        data["!cols"] = [{ wch: 16 }, { wch: 18 }, { wch: 12 }, { wch: 12 }, { wch: 6 }, { wch: 6 }, { wch: 15 }, { wch: 15 }];

        if (Capacitor.isNativePlatform()) {
            this.file = xlsx.write(this.workbook, { bookType: "xlsx", bookSST: true, type: "base64" });

            console.log(this.file);

            Filesystem.writeFile({
                path: "Contratos.xlsx",
                data: this.file.substr(this.file.indexOf(", ") + 1),
                directory: Directory.Data,
            }).then((writeResult) => {
                FileOpener.open({ filePath: writeResult.uri, contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                    .then(() => console.log("File is opened"))
                    .catch((e) => console.log("Error opening file", e));
            });
        } else {
            this.file = xlsx.writeFile(this.workbook, filename, { bookType: "xlsx", bookSST: true, type: "base64" });
        }
    }

    getXlsxBlob() {
        return null;
    }

    downloadXlsx() {}
}
