<template>
    <div class="m-contract-links box">
        <div class="item remote-reading-link">
            <div class="left-side">
                <div class="icon-container">
                    <img class="icon-config" alt="icono configuracion" src="../assets/images/svg/config.svg" />
                </div>
                <router-link :to="{ name: viewMappingTypes.CONFIGURATION_NOTICES_MAPPING.name }">
                    <div>
                        <p class="text">{{ $t("ofex.configNotices.title") }}</p>
                        <p class="text-description">{{ $t("ofex.configNotices.seleccionaComo") }}</p>
                    </div>
                </router-link>
            </div>
            <router-link :to="{ name: viewMappingTypes.CONFIGURATION_NOTICES_MAPPING.name }">
                <div class="right-side link-item">
                    {{ $t("ofex.contractLinks.consumoTelelecturaLink") }}<font-awesome-icon class="icon" icon="arrow-right"></font-awesome-icon>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import * as viewMappingTypes from "../router/view-mapping-types";
export default {
    name: "AbConfigNotices",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
        };
    },
};
</script>

<style lang="scss" scoped>
.m-contract-links {
    .item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 25px;

        @include respond-above(sm) {
            padding: 15px 25px;
        }

        &.pending-invoices {
            background: $blue-lighter;

            .text {
                color: $color-lightest;
            }
            .link-item {
                color: $color-lightest;
            }
        }
        &.remote-reading-link {
            background: #f8f8f8;

            .text,
            .text-description {
                color: $blue-dark;
            }
            .link-item {
                color: $blue-dark;
            }
        }

        .left-side {
            display: flex;
            align-items: center;

            .icon-container {
                flex: 0 0 auto;
                width: 35px;
                text-align: center;
                margin-right: 20px;
            }
            .text {
                font-weight: $font-primary-bold;
                @include font-size(20);
                @include line-height(25);
                margin-bottom: 0;
                margin-right: 20px;
            }
            .text-description {
                @include font-size(14);
                @include line-height(17);
                margin-bottom: 0;
            }
        }
        .right-side {
            align-self: flex-end;
            white-space: nowrap;

            &.link-item {
                font-weight: $font-primary-bold;
                @include font-size(16);
                @include line-height(19);
                margin-left: 15px;

                .icon {
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>
