<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { calculateChartMaxYAxis } from "../../plugins/ab-utils";
import slice from "lodash/slice";
import moment from "moment";
import { AB_DATE_FORMAT } from "@/plugins/ab-utils";
import { isBrowser } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import { executeAccessibilityFixes } from "@/plugins/accesibility";
// import { Capacitor } from "@capacitor/core";

export default {
    extends: Bar,
    props: {
        userInvoices: {
            type: Array,
            required: true,
        },
        showLegend: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
        return {
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
        };
    },
    methods: {
        downloadPdf: function (e, data) {
            if (isBrowser) {
                //TODO sacar a método global de utilidades
                if (data.length > 0) {
                    let activeElement = data[0]._chart.getElementAtEvent(e);
                    console.debug(activeElement);
                    if (activeElement.length > 0) {
                        let selectedInvoice = this.getSelectedInvoice(activeElement[0]._datasetIndex, activeElement[0]._index);
                        console.debug(selectedInvoice);
                        this.$downloadInvoice({
                            invoiceNumber: selectedInvoice.invoiceNumber,
                            invoiceYear: selectedInvoice.invoiceYear,
                            locale: this.$i18n.locale,
                        });
                    }
                }
            }
        },
        getSelectedInvoice: function (datasetIndex, columnIndex) {
            switch (datasetIndex) {
                case 0:
                    return this.penultimateYearDataset[columnIndex];
                case 1:
                    return this.lastYearDataset[columnIndex];
                default:
                    return this.currentYearDataset[columnIndex];
            }
        },
        renderInvoiceEvolutionChart: function () {
            console.log("Creando gráfica de evolucion de facturas");

            this.addPlugin(ChartDataLabels);

            let currentYear = moment().year();
            let lastYear = currentYear - 1;
            let penultimateYear = currentYear - 2;
            console.log(moment(this.userInvoices[5].endDate, AB_DATE_FORMAT, "es").format("MMM"));

            var vueContext = this;

            let invoicesEvolutionBarOptions = {
                plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                        display: function (context) {
                            return !Capacitor.isNativePlatform() && context.dataset.data[context.dataIndex] > 0;
                        },
                        labels: {
                            symbol: {
                                anchor: "center",
                                clamp: true,
                                color: "#ffffff",
                                font: {
                                    family: "faGraph",
                                },
                                formatter: function (value, context) {
                                    if (this.$isNativeApp()) {
                                        return undefined;
                                    } else {
                                        let selectedInvoice = vueContext.getSelectedInvoice(context.datasetIndex, context.dataIndex);
                                        return selectedInvoice.estimatedReading === true ? "\uf1eb" : "";
                                    }
                                },
                            },
                            value: {
                                anchor: "end",
                                clamp: true,
                                align: "top",
                                font: {
                                    weight: "bold",
                                    family: "Raleway",
                                },
                            },
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                onClick: this.downloadPdf,
                legend: {
                    display: this.showLegend,
                    position: "bottom",
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                // Include a dollar sign in the ticks
                                callback: function (value) {
                                    return value + " €";
                                },
                            },
                        },
                    ],
                    xAxes: [
                        {
                            // barThickness: 40,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                // maxRotation: 0,
                            },
                        },
                    ],
                },
                // https://zenerab.atlassian.net/browse/UATOFEX-182
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                tooltips: {
                    backgroundColor: "#FFF",
                    borderColor: "#417290",
                    borderWidth: 1,
                    titleFontColor: "#417290",
                    callbacks: {
                        label: function () {
                            return "";
                        },
                        title: function () {
                            return "Descargar factura";
                        },
                    },
                },
            };

            let invoicesEvolutionBarData = {
                datasets: [
                    {
                        data: this.penultimateDatasetAmount,
                        hoverBackgroundColor: "#e8c02b",
                        backgroundColor: "#956d0a",
                        label: `Año ${penultimateYear}`,
                        minBarLength: 5,
                    },
                    {
                        data: this.lastYearDatasetAmount,
                        hoverBackgroundColor: "#4f9ccb",
                        backgroundColor: "#417290",
                        label: `Año ${lastYear}`,
                        minBarLength: 5,
                    },
                    {
                        data: this.currentYearDatasetAmount,
                        hoverBackgroundColor: "#31cb6f",
                        backgroundColor: "#008f39",
                        label: `Año ${currentYear}`,
                        minBarLength: 5,
                    },
                ],
                labels: this.monthsLabels,
            };

            if (this.currentYearDatasetAmount && this.lastYearDatasetAmount && this.penultimateDatasetAmount) {
                let mergedDatasets = [this.currentYearDatasetAmount, this.lastYearDatasetAmount].reduce((a, b) => a.concat(b));
                let max = calculateChartMaxYAxis(mergedDatasets, 20);
                console.debug(`Máximo valor en eje Y calculado: ${max}`);
                // invoicesEvolutionBarOptions.scales.yAxes[0].ticks.max = max;
            }

            this.renderChart(invoicesEvolutionBarData, invoicesEvolutionBarOptions);
        },
        calculateDataset: function (year) {
            let months = slice(this.userInvoices, 0, 6).map((i) => moment(i.endDate, AB_DATE_FORMAT, "es").month());
            let dataset = [];

            while (months.length > 0) {
                let curMonth = months.shift();

                //filtramos por año y por mes a busca
                let foundValue = slice(this.userInvoices, 0, this.userInvoices.length).find(
                    (i) => moment(i.endDate, AB_DATE_FORMAT, "es").year() === year && moment(i.endDate, AB_DATE_FORMAT, "es").month() === curMonth
                );

                if (foundValue !== undefined) {
                    dataset.push(foundValue);
                }
            }

            // let rawDataset = dataset.sort((a, b) => moment(a.endDate, AB_DATE_FORMAT, "es").unix() - moment(b.endDate, AB_DATE_FORMAT, "es").unix());

            let fillLenght = 6 - dataset.length;
            return [...Array(fillLenght).fill({ amount: 0 }, 0, fillLenght), ...dataset.reverse()];
        },
        invoiceMonthIsPresentInDatasetPredicate: function (invoice, dataset) {
            let invoiceMonth = moment(invoice.endDate, AB_DATE_FORMAT, "es").month();
            return dataset.some((f) => moment(f.endDate, AB_DATE_FORMAT, "es").month() === invoiceMonth);
        },
    },
    computed: {
        currentYearDataset: function () {
            return this.calculateDataset(moment().year());
        },
        lastYearDataset: function () {
            return this.calculateDataset(moment().year() - 1);
        },
        penultimateYearDataset: function () {
            let fullPenultimateDataset = this.calculateDataset(moment().year() - 2);
            //hay que filtrar para eliminar los meses que ya están en la factura del año actual
            let filtered = fullPenultimateDataset.filter((i) => !this.invoiceMonthIsPresentInDatasetPredicate(i, this.currentYearDataset));

            while (filtered.length < 6) {
                filtered.push({ amount: 0 });
            }
            return filtered;
        },
        currentYearDatasetAmount: function () {
            return this.currentYearDataset.map((i) => i.amount);
        },
        lastYearDatasetAmount: function () {
            return this.lastYearDataset.map((i) => i.amount);
        },
        penultimateDatasetAmount: function () {
            return this.penultimateYearDataset ? this.penultimateYearDataset.map((i) => i.amount) : [];
        },
        monthsLabels: function () {
            return this.lastYearDataset.map((i) => moment(i.endDate, AB_DATE_FORMAT, "es").format("MMM").toUpperCase());
        },
    },
    watch: {
        userInvoices: function () {
            console.log("Cambiando datos de facturas en gráfico");
            // this._chart.destroy();
            this.renderInvoiceEvolutionChart();
        },
    },
    mounted() {
        this.renderInvoiceEvolutionChart();
        executeAccessibilityFixes();
    },
};
</script>

<style lang="scss">
@font-face {
    font-family: faGraph;
    src: url(../../assets/fonts/fa-solid-900.woff);
}
</style>
