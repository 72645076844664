"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import { trimBackendCode } from "@/plugins/ab-utils";
const ACTIVITY_CODE = "PS";
export default class ContractListPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId, contract) {
        super("Contratos.pdf", ACTIVITY_CODE, i18n, userId, clientId, contract);
    }

    generatePdf(contracts) {
        const SPACE = " ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.tuContrato.title"), "");

        var head = [
            [
                `${this.i18n.tc("ofex.tuContrato.contractNumber")}`,
                `${this.i18n.tc("ofex.tuContrato.estado")}`,
                `${this.i18n.tc("ofex.advanceSearch.remoteReadingText")}`,
                `${this.i18n.tc("ofex.tuContrato.contractAlias")}`,
                `${this.i18n.tc("ofex.tuContrato.direccionSuministro")}`,
                `${this.i18n.tc("ofex.tuContrato.pendingInvoice")}`,
                `${this.i18n.tc("ofex.tuContrato.shipping")}`,
                `${this.i18n.tc("ofex.advanceSearch.favoritesText")}`,
            ],
        ];
        var body = [contracts.length];
        contracts.forEach(
            (i, index) =>
                (body[index] = [
                    `${i.contractDetail.contractNumber}`,
                    `${this.i18n.tc("ofex.backendCodes.supplyStatus." + trimBackendCode(i.contractDetail.supplyStatus))}`,
                    `${i.contractDetail.telerecording ? this.i18n.tc("common.yes") : this.i18n.tc("common.no")}`,
                    `${i.contractDetail.alias}`,
                    `${
                        this.i18n.tc("ofex.backendCodes.streetTypes." + trimBackendCode(i.supplyAddress.streetType)) +
                        SPACE +
                        i.supplyAddress.streetName +
                        SPACE +
                        i.supplyAddress.number +
                        SPACE +
                        i.supplyAddress.otherAddressData +
                        SPACE +
                        i.supplyAddress.municipality +
                        SPACE
                    }`,
                    `${i.contractDetail.invoicesPending ? this.i18n.tc("ofex.tuContrato.pagar") : this.i18n.tc("ofex.tuContrato.sinDeuda")}`,
                    `${this.i18n.tc("ofex.backendCodes.deliveryType." + i.contractDetail.invoiceDeliveryType)}`,
                    `${i.contractDetail.favorite ? this.i18n.tc("common.yes") : this.i18n.tc("common.no")}`,
                ])
        );

        this.generateAutoTable(head, body, 4);
        return this.pdf;
    }
}
