import qs from "qs";
import store from "@/store/store";
import { SET_REST_ERROR } from "@/store/mutation-types";
import * as mutationTypes from "@/store/mutation-types";
import BaseOfexServiceClient from "./BaseOfexServiceClient";

const AUTHORIZATIONS_BASE_URL = process.env.VUE_APP_API_AUTHORIZATIONS_BASE_URL;

export default {
    getUserAuthorizations: async function (clientId, userId, lang = "es", authorizationType = "GENERAL") {
        try {
            console.debug("Obteniendo autorizaciones del cliente");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${AUTHORIZATIONS_BASE_URL}/authorizations`, {
                params: {
                    clientId,
                    userId,
                    lang,
                    authorizationType,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response && response.status === 200) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response ? response.statusText : "Error inesperado";
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    getAuthorizationsByContract: async function (clientId, userId, lang = "es") {
        try {
            console.debug("Obteniendo autorizaciones del agrupadas por contrato");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${AUTHORIZATIONS_BASE_URL}/authorizations/contracts`, {
                params: {
                    clientId,
                    userId,
                    lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response && response.status === 200) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response ? response.statusText : "Error inesperado";
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    createNewAuthorization: async function (personData, clientId, userId, lang = "es", activityId, idDocumentoPdf) {
        try {
            console.debug("Creando nueva autorización para persona");
            removeAsteriskedField(personData);
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(
                `${AUTHORIZATIONS_BASE_URL}/authorizations`,
                { ...personData, activityId, idDocumentoPdf },
                {
                    params: {
                        clientId,
                        userId,
                        lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response && response.status === 204) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response ? response.statusText : "Error inesperado";
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    createNewContractAuthorization: async function (personData, contractNumbers, clientId, userId, lang = "es", activityId, idDocumentoPdf) {
        try {
            console.debug("Creando nueva autorización para contrato");
            removeAsteriskedField(personData);
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(
                `${AUTHORIZATIONS_BASE_URL}/authorizations/contracts`,
                { ...personData, activityId, idDocumentoPdf },
                {
                    params: {
                        clientId,
                        userId,
                        lang,
                        contractNumbers,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response && response.status === 204) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response ? response.statusText : "Error inesperado";
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    deleteAuthorizations: async function (documentNumbers, authorizationType, clientId, userId, lang = "es") {
        try {
            console.debug("Borrando autorizaciones de tipo " + authorizationType);

            var requestData;
            if (authorizationType === "CONTRACT") {
                requestData = documentNumbers.map((documentNumber) => {
                    let contractNumbers = store.getters.getContractNumbersByAuthorizationPerson(documentNumber);
                    console.log(documentNumber + " - Números de contratos: " + contractNumbers);
                    return { documentNumber, authorizationType, contractNumbers };
                });
            } else if (authorizationType === "GENERAL") {
                requestData = documentNumbers.map((documentNumber) => {
                    return { documentNumber, authorizationType };
                });
            }

            removeAsteriskedField(requestData);

            let abRestClient = new BaseOfexServiceClient(false).abRestClient;
            let response = await abRestClient.post(
                `${AUTHORIZATIONS_BASE_URL}/authorizations/delete`,
                {
                    data: requestData,
                },
                {
                    params: {
                        clientId,
                        userId,
                        lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response && response.status === 204) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response ? response.statusText : "Error inesperado";
        } catch (e) {
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            // store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
            return e.response;
        }
    },

    deleteUniqueContractAuthorization: async function (documentNumbers, contractNumber, clientId, userId, lang = "es") {
        try {
            console.debug("Borrando autorizaciones de un contrato único");

            let requestData = documentNumbers.map((documentNumber) => {
                let contractNumbers = [contractNumber];
                console.log(documentNumber + " - Números de contratos: " + contractNumbers);
                return { documentNumber, authorizationType: "CONTRACT", contractNumbers };
            });

            removeAsteriskedField(requestData);

            //TODO sacar esto a método comun
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(
                `${AUTHORIZATIONS_BASE_URL}/authorizations/delete`,
                {
                    data: requestData,
                },
                {
                    params: {
                        clientId,
                        userId,
                        lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response && response.status === 204) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response ? response.statusText : "Error inesperado";
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },

    updatePartialAuthorizations: async function (clientId, userId, payload, lang = "es") {
        console.debug("Actualizando las autorizaciones modificadas");
        let abRestClient = new BaseOfexServiceClient().abRestClient;
        removeAsteriskedField(payload);
        let response = await abRestClient.patch(`${AUTHORIZATIONS_BASE_URL}/authorizations/contracts`, payload, {
            params: {
                clientId,
                userId,
                lang,
            },
        });
        if (response && response.status === 204) {
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response ? response.statusText : "Error inesperado";
    },
};

function removeAsteriskedField(payload) {
    for (const [key, value] of Object.entries(payload)) {
        if (value && typeof value === "string" && value.length > 0 && onlyContainsAsterisk(value)) {
            console.debug(`Encontrado key ${key} con valor ${value} candidata para borrar`);
            delete payload[key];
        }
    }
}

function onlyContainsAsterisk(text) {
    for (let i = 0; i < text.length; i++) {
        if (text[i] !== "*") return false;
    }
    return true;
}
