/* eslint-disable */
import AbAutocompletableInput from "./components/AbAutocompletableInput.vue";
import AbAutocompletableSelect from "./components/AbAutocompletableSelect.vue";
import AbBaterias from "./components/AbBaterias.vue";
import AbBateriasAumento from "./components/AbBateriasAumento.vue";
import AbBateriasComprobacion from "./components/AbBateriasComprobacion.vue";
import AbBoldCheckbox from "./components/AbBoldCheckbox.vue";
import AbDatepicker from "./components/AbDatepicker.vue";
import AbDatepicker2 from "./components/AbDatepicker2.vue";
import AbSelect from "./components/AbSelect.vue";
import AbInput from "./components/AbInput.vue";
import AbErrorHeader from "./components/AbErrorHeader.vue";
import AbIban from "./components/AbIban.vue";
import AbRadio from "./components/AbRadio.vue";
import AbRadioUr from "./components/AbRadioUr.vue";
import AbLegalCheckImage from "./components/AbLegalCheckImage.vue";
import AbLegalCheck from './components/AbLegalCheck.vue'
import AbCarrerer from "./components/AbCarrerer.vue";
import AbCarrererCatalogue from './components/AbCarrererCatalogue.vue'
import AbCountrySelect from "./components/AbCountrySelect.vue";
import AbProvinceSelect from "./components/AbProvinceSelect.vue";
import AbIdTypeNumber from "./components/AbIdTypeNumber.vue";
import AbStepsHeader from "./components/AbStepsHeader.vue";
import AbSectionHeader from "./components/AbSectionHeader.vue";
import AbRemoteModal from "./components/AbRemoteModal.vue";
import AbTextArea from "./components/AbTextArea.vue";
import AbUpload from "./components/AbUpload.vue";
import AbLoading from "./components/AbLoading.vue";
import AbConfirmation from "./components/AbConfirmation.vue";
import AbConfirmationTransactional from "./components/AbConfirmationTransactional.vue";
import AbContingencias from "./components/AbContingencias.vue";
import AbDataRecover from "./components/AbDataRecover.vue";
import AbContratoTable from "./components/AbContratoTable.vue";
import AbInformativo from "./components/AbInformativo.vue";
import AbTextEnd from "./components/AbTextEnd.vue";
import AbLegalSection from "./components/AbLegalSection.vue";
import AbInvoiceVerification from "./components/AbInvoiceVerification.vue";
import AbCheckbox from "./components/AbCheckbox.vue";
import AbPostalCodeSelect from "./components/AbPostalCodeSelect.vue";
import AbPopover from "./components/AbPopover.vue";
import AbNuevaBateria from "./components/AbNuevaBateria.vue";
import AbIaeSelect from "./components/AbIaeSelect.vue";
import AbPassword from "./components/AbPassword.vue";
import validatorConfigurator from "./plugins/vue-ab-validator-configurator.js";
import commonPlugin from "./plugins/vue-ab-common";
import abUtils from "./plugins/ab-utils";
import router from "./router/router";
import store from "./store/store";
import AbButtonOfex from "./components/AbButtonOfex";
import AbDropdown from "./components/AbDropdown";
import AbNotifications from "./components/AbNotifications";
import AbUserPanelDropdown from "./components/AbUserPanelDropdown";
import AbWithoutData from "./components/AbWithoutData";
import AbReturn from "./components/AbReturn";
import AbDropdownSection from "./components/AbDropdownSection"
import AbMaintenanceCompaniesSelect from "./components/AbMaintenanceCompaniesSelect";

import HomeView from "./views/HomeView";
import LoginView from "./views/LoginView";

//Modules:
import AbAppAbout from "./modules/common/AbAppAbout";
import AbHeaderBar from "./modules/common/AbHeaderBar";
import AbHeaderLocation from "./modules/common/AbHeaderLocation";
import AbHeaderUserPanel from "./modules/common/AbHeaderUserPanel";
import AbHeaderWebLinks from "./modules/common/AbHeaderWebLinks";
import AbHeaderLanguage from "./modules/common/AbHeaderLanguage";
import AbNavBar from "./modules/common/AbNavBar";
import AbBoxErrorLogin from "./modules/common/AbBoxErrorLogin";
import AbLeftNavBar from "./modules/common/AbLeftNavBar";
import AbTitleSectionOfex from "./modules/AbTitleSectionOfex";
import AbMainTitle from "./modules/AbMainTitle";
import AbSelectContract from "./modules/AbSelectContract";
import AbSelectInvoice from "./modules/AbSelectInvoice";
import AbYourInterest from "./modules/AbYourInterest";
import AbYourConsumption from "./modules/AbYourConsumption";
import AbEvolutionInvoices from "./modules/AbEvolutionInvoices";
import AbInvoiceSummary from "./modules/AbInvoiceSummary";
import AbLegalCheckFlow from "./modules/legal/AbLegalCheckFlow";
import AbChangeLangDialog from "./modules/lang/AbChangeLangDialog";
import AbServiceIncidents from "./modules/AbServiceIncidents";
import AbYearsLegend from "./modules/invoices/AbYearsLegend.vue";
import AbContractLinks from "./modules/AbContractLinks";
import AbContractsAdvanceSearch from "./modules/AbContractsAdvanceSearch";
import AbConfigNotices from "./modules/AbConfigNotices";
import AbNotificationsAdvanceSearch from "./modules/notifications/AbNotificationsAdvanceSearch";
import AbNotificationsList from "./modules/notifications/AbNotificationsList";
import AbHistoricAdvanceSearch from "./modules/userActions/AbUserActionsAdvanceSearch";
import AbInvoiceGreyBox from "./modules/invoices/AbInvoiceGreyBox.vue";
import AbAccordionInvoice from "./modules/invoices/AbPaymentMethodsAccordion.vue";
import AbChartSummaryTable from "./modules/AbChartSummaryTable.vue";
import AbInvoiceDonutChart from "./modules/charts/AbInvoiceDonutChart.vue";
import AbInvoiceEvolutionChart from "./modules/charts/AbInvoiceEvolutionChart.vue";
import AbInvoiceThreeYearsEvolutionBarChart from "./modules/charts/AbInvoiceThreeYearsEvolutionBarChart.vue";
import AbConsumptionEvolutionTimeChart from "./modules/charts/AbConsumptionEvolutionTimeChart.vue";
import AbConsumptionEvolutionChart from "./modules/charts/AbConsumptionEvolutionChart.vue";
import AbLineEvolutionChart from "./modules/charts/AbLineEvolutionChart.vue";
import AbLineComparativeChart from "./modules/charts/AbLineComparativeChart.vue";
import AbLineConsumptionComparative from "./modules/charts/AbLineConsumptionComparative.vue";
import AbLoginMosaico from "./components/contenidos/AbLoginMosaico";

// Exportación de los componentes individualmente
export {
    HomeView,
    LoginView,
    AbLoginMosaico,
    AbInvoiceDonutChart,
    AbInvoiceEvolutionChart,
    AbInvoiceThreeYearsEvolutionBarChart,
    AbButtonOfex,
    AbTitleSectionOfex,
    AbAutocompletableInput,
    AbBaterias,
    AbBateriasAumento,
    AbDatepicker,
    AbDatepicker2,
    AbDropdownSection,
    AbSelect,
    AbInput,
    AbErrorHeader,
    AbIban,
    AbRadio,
    AbRadioUr,
    AbLegalCheck,
    AbLegalCheckImage,
    AbCarrerer,
    AbCarrererCatalogue,
    AbCountrySelect,
    AbProvinceSelect,
    AbBateriasComprobacion,
    AbBoldCheckbox,
    AbAutocompletableSelect,
    AbIdTypeNumber,
    AbStepsHeader,
    AbSectionHeader,
    AbRemoteModal,
    AbReturn,
    AbTextArea,
    AbUpload,
    AbLoading,
    AbConfirmation,
    AbConfirmationTransactional,
    AbContingencias,
    AbDataRecover,
    AbContratoTable,
    AbInformativo,
    AbTextEnd,
    AbInvoiceVerification,
    AbLegalSection,
    AbCheckbox,
    AbPostalCodeSelect,
    AbPopover,
    AbNuevaBateria,
    AbIaeSelect,
    validatorConfigurator,
    commonPlugin,
    abUtils,
    AbHeaderBar,
    AbBoxErrorLogin,
    AbLeftNavBar,
    AbMainTitle,
    AbSelectContract,
    AbSelectInvoice,
    AbYourInterest,
    AbYourConsumption,
    AbEvolutionInvoices,
    AbInvoiceSummary,
    AbServiceIncidents,
    AbHeaderLocation,
    AbHeaderUserPanel,
    AbHeaderWebLinks,
    AbHeaderLanguage,
    AbDropdown,
    AbNotifications,
    AbUserPanelDropdown,
    AbWithoutData,
    AbNavBar,
    AbAppAbout,
    AbContractLinks,
    AbContractsAdvanceSearch,
    AbNotificationsAdvanceSearch,
    AbHistoricAdvanceSearch,
    AbInvoiceGreyBox,
    AbAccordionInvoice,
    AbConfigNotices,
    AbNotificationsList,
    AbYearsLegend,
    AbChartSummaryTable,
    AbMaintenanceCompaniesSelect,
    AbLegalCheckFlow,
    AbChangeLangDialog,
    router,
    store,
};

// Instalación de la librería como un plugin
export function install(Vue) {
    Vue.component("HomeView", HomeView);
    Vue.component("LoginView", LoginView);
    Vue.component("AbLoginMosaico", AbLoginMosaico);
    Vue.component("AbBoxErrorLogin", AbBoxErrorLogin);
    Vue.component("AbButtonOfex", AbButtonOfex);
    Vue.component("AbTitleSectionOfex", AbTitleSectionOfex);
    Vue.component("AbAutocompletableInput", AbAutocompletableInput);
    Vue.component("AbBaterias", AbBaterias);
    Vue.component("AbBateriasAumento", AbBateriasAumento);
    Vue.component("AbBateriasComprobacion", AbBateriasComprobacion);
    Vue.component("AbBoldCheckbox", AbBoldCheckbox);
    Vue.component("AbDatepicker", AbDatepicker);
    Vue.component("AbDatepicker2", AbDatepicker2);
    Vue.component("AbSelect", AbSelect);
    Vue.component("AbInput", AbInput);
    Vue.component("AbErrorHeader", AbErrorHeader);
    Vue.component("AbIban", AbIban);
    Vue.component("AbRadio", AbRadio);
    Vue.component("AbRadioUr", AbRadioUr);
    Vue.component('AbLegalCheck', AbLegalCheck);
    Vue.component("AbLegalCheckImage", AbLegalCheckImage);
    Vue.component("AbLegalCheckFlow", AbLegalCheckFlow);
    Vue.component("AbChangeLangDialog", AbChangeLangDialog);
    Vue.component("AbCarrerer", AbCarrerer);
    Vue.component('AbCarrererCatalogue', AbCarrererCatalogue);
    Vue.component("AbCountrySelect", AbCountrySelect);
    Vue.component("AbProvinceSelect", AbProvinceSelect);
    Vue.component("AbAutocompletableSelect", AbAutocompletableSelect);
    Vue.component("AbIdTypeNumber", AbIdTypeNumber);
    Vue.component("AbStepsHeader", AbStepsHeader);
    Vue.component("AbSectionHeader", AbSectionHeader);
    Vue.component("AbRemoteModal", AbRemoteModal);
    Vue.component("AbTextArea", AbTextArea);
    Vue.component("AbUpload", AbUpload);
    Vue.component("AbLoading", AbLoading);
    Vue.component("AbConfirmation", AbConfirmation);
    Vue.component("AbConfirmationTransactional", AbConfirmationTransactional);
    Vue.component("AbContingencias", AbContingencias);
    Vue.component("AbDataRecover", AbDataRecover);
    Vue.component("AbContratoTable", AbContratoTable);
    Vue.component("AbInformativo", AbInformativo);
    Vue.component("AbTextEnd", AbTextEnd);
    Vue.component("AbLegalSection", AbLegalSection);
    Vue.component("AbInvoiceVerification", AbInvoiceVerification);
    Vue.component("AbCheckbox", AbCheckbox);
    Vue.component("AbPostalCodeSelect", AbPostalCodeSelect);
    Vue.component("AbPopover", AbPopover);
    Vue.component("AbDropdown", AbDropdown);
    Vue.component("AbNuevaBateria", AbNuevaBateria);
    Vue.component("AbIaeSelect", AbIaeSelect);
    Vue.component("AbPassword", AbPassword);
    Vue.component("AbHeaderBar", AbHeaderBar);
    Vue.component("AbLeftNavBar", AbLeftNavBar);
    Vue.component("AbMainTitle", AbMainTitle);
    Vue.component("AbSelectContract", AbSelectContract);
    Vue.component("AbSelectInvoice", AbSelectInvoice);
    Vue.component("AbYourInterest", AbYourInterest);
    Vue.component("AbYourConsumption", AbYourConsumption);
    Vue.component("AbEvolutionInvoices", AbEvolutionInvoices);
    Vue.component("AbInvoiceSummary", AbInvoiceSummary);
    Vue.component("AbServiceIncidents", AbServiceIncidents);
    Vue.component("AbInvoiceDonutChart", AbInvoiceDonutChart);
    Vue.component("AbInvoiceEvolutionChart", AbInvoiceEvolutionChart);
    Vue.component("AbInvoiceThreeYearsEvolutionBarChart", AbInvoiceThreeYearsEvolutionBarChart);
    Vue.component("AbConsumptionEvolutionChart", AbConsumptionEvolutionChart);
    Vue.component("AbConsumptionEvolutionTimeChart", AbConsumptionEvolutionTimeChart);
    Vue.component("AbLineEvolutionChart", AbLineEvolutionChart);
    Vue.component("AbLineComparativeChart", AbLineComparativeChart);
    Vue.component("AbLineConsumptionComparative", AbLineConsumptionComparative);
    Vue.component("AbHeaderLocation", AbHeaderLocation);
    Vue.component("AbHeaderUserPanel", AbHeaderUserPanel);
    Vue.component("AbHeaderWebLinks", AbHeaderWebLinks);
    Vue.component("AbHeaderLanguage", AbHeaderLanguage);
    Vue.component("AbUserPanelDropdown", AbUserPanelDropdown);
    Vue.component("AbWithoutData", AbWithoutData);
    Vue.component("AbNavBar", AbNavBar);
    Vue.component("AbContractLinks", AbContractLinks);
    Vue.component("AbNotificationsList", AbNotificationsList);
    Vue.component("AbConfigNotices", AbConfigNotices);
    Vue.component("AbNotificationsAdvanceSearch", AbNotificationsAdvanceSearch);
    Vue.component("AbHistoricAdvanceSearch", AbHistoricAdvanceSearch);
    Vue.component("AbReturn", AbReturn);
    Vue.component("AbYearsLegend", AbYearsLegend);
    Vue.component("AbDropdownSection", AbDropdownSection);
    Vue.component("AbMaintenanceCompaniesSelect", AbMaintenanceCompaniesSelect);
    Vue.component("AbChartSummaryTable", AbChartSummaryTable);
    Vue.component("AbAppAbout", AbAppAbout);
}

if (typeof window !== "undefined" && window.Vue) {
    Vue.use({ install });
}

// Exportación de la librería como plugin
export default { install: install };
