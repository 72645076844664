<template>
    <div class="container" ref="content">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <div class="row" v-if="contract && contractDetail">
            <ab-left-nav-bar />
            <div class="col-12 col-md-12 col-lg-9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.detalleContrato.title')"></ab-main-title>
                <div class="info-contract">
                    <ab-return></ab-return>
                    <div class="info-contract-inner">
                        <div class="contract-title">
                            {{ $t("ofex.detalleContrato.contrato") }}<span class="contract-number">{{ contract.contractDetail.contractNumber }}</span>
                            <img v-if="isFavoriteContract($route.params.contractId)" class="icon" alt="estrella" src="../assets/images/svg/favoritos.svg" />
                            <img v-else class="icon" alt="estrella" src="../assets/images/svg/favoritos-off.svg" />
                        </div>
                        <div v-if="contract.contractDetail.telerecording" class="remote-reading">{{ $t("ofex.home.telelectura") }}</div>
                    </div>
                </div>
                <div class="contract-list-item" v-if="contractDetail && contractDetail.contractDetail">
                    <ab-section-header :section-name="$t('ofex.detalleContrato.sectionDetalleContrato')"></ab-section-header>

                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.aliasContrato") }}
                        <span class="element-black" v-text="contractDetail.contractDetail.alias === '' ? '' : contractDetail.contractDetail.alias"></span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.fechaAlta")
                        }}<span class="element-black" v-text="contractDetail.contractDetail.registrationDate">{{
                            contractDetail.contractDetail.registrationDate
                        }}</span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.estadoSuministro") }}<span class="element-black">{{ supplyStatusTranslation() }}</span>
                    </p>
                    <!-- ALTERNATIVAS PARA EL TIPO DE SUMINISTRO-->
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.tipoSuministro") }}<span class="element-black" v-html="supplyTypeTranslation()"></span>
                    </p>
                    <p class="element-blue mb-5">
                        {{ $t("ofex.detalleContrato.direccionSuministro")
                        }}<span class="element-black"
                            >{{ streetTypeSupplyAddressCodeTranslation() }} {{ contractDetail.supplyAddress.streetName }},
                            {{ contractDetail.supplyAddress.number }}, {{ contractDetail.supplyAddress.otherAddressData }},
                            {{ contractDetail.supplyAddress.municipality }}
                        </span>
                    </p>

                    <ab-section-header :section-name="$t('ofex.afectaciones-contratos-detalle.detailHeader')"></ab-section-header>

                    <div class="affectation-text mb-5" v-if="detailText" v-html="detailText"></div>
                    <div class="affectation-text mb-5" v-else v-html="droughtConfiguration.defaultAdcDetailMessageHtml">
                        <p>
                            {{ $t("ofex.afectaciones-contratos-detalle.texto-por-defecto") }}
                        </p>
                    </div>
                </div>

                <ab-section-header :section-name="$t('ofex.afectaciones-contratos-detalle.prevision-header')"></ab-section-header>

                <p v-html="$t('ofex.afectaciones-contratos-detalle.privision-subtitle')"></p>

                <!-- Tabla sequia presiones -->
                <div class="grid-container">
                    <div class="grid" id="affectation-table">
                        <div class="time-container">
                            <div class="time" v-for="(hour, index) in arrayHours" :key="index">{{ hour }}</div>
                        </div>
                        <div class="scroll-container">
                            <div class="line-container">
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                                <div class="line"></div>
                            </div>
                            <div class="grid-inner">
                                <div class="column" v-for="(day, index) of contract.droughtData?.affectationsByDate">
                                    <p class="title">{{ $t(`ofex.afectaciones-contratos-detalle.day-${index}`) }}</p>
                                    <template v-if="day?.maxAffectationCode !== '00' && day?.maxAffectationCode">
                                        <div v-for="timeStamp of day?.timeSlots">
                                            <div
                                                v-if="timeStamp.heaterAffected && timeStamp.heaterAffected !== '00'"
                                                class="item bg-grey"
                                                :class="[
                                                    supplyAffectationHourClass(timeStamp.startHour, timeStamp.endHour),
                                                    'color-' + timeStamp?.heaterAffected,
                                                ]"
                                            >
                                                <p
                                                    class="description-text"
                                                    v-html="supplyAffectationCodeText(timeStamp?.heaterAffected, timeStamp.floorsNumberAffected15)"
                                                ></p>
                                            </div>
                                            <div v-else-if="timeStamp.heaterAffected === '00'"></div>
                                            <div v-else class="item no-data" :class="supplyAffectationHourClass(timeStamp.startHour, timeStamp.endHour)">
                                                <p class="description-text" v-html="$t('ofex.afectaciones-contratos-detalle.no-data')"></p>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="day?.maxAffectationCode === '00'"></template>
                                    <template v-else>
                                        <div class="item no-data full-day">
                                            <p class="description-text" v-html="$t('ofex.afectaciones-contratos-detalle.no-data')"></p>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AbReturn from "@/components/AbReturn.vue";
import AbLeftNavBar from "@/modules/common/AbLeftNavBar.vue";
import { mapGetters, mapState } from "vuex";
import { trimBackendCode } from "@/plugins/ab-utils";
import * as actionTypes from "@/store/action-types";

export default {
    name: "AffectationsContractsDetailView",
    components: { AbReturn, AbLeftNavBar },
    data: function () {
        return {
            arrayHours: ["00:00", "02:00", "04:00", "06:00", "08:00", "10:00", "12:00", "14:00", "16:00", "18:00", "20:00", "22:00", "00:00"],
        };
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "contracts", "contractDetail", "selectedContractNumber", "i18nLocale", "droughtConfiguration"]),
        ...mapGetters(["getContractByContractNumber", "isFavoriteContract", "isCompany", "clientId", "userId"]),
        contract: function () {
            return this.getContractByContractNumber(this.$route.params.contractId);
        },
        detailText: function () {
            return this.i18nLocale === "es"
                ? this.contract.droughtData.affectationsByDate[0]?.adcDetailMessageHtmlEs
                : this.contract.droughtData.affectationsByDate[0]?.adcDetailMessageHtmlCa;
        },
    },
    methods: {
        streetTypeSupplyAddressCodeTranslation: function () {
            return this.$t("ofex.backendCodes.streetTypes." + trimBackendCode(this.streetTypeSupplyAddressBackendCode()));
        },
        streetTypeSupplyAddressBackendCode: function () {
            return trimBackendCode(this.contractDetail.supplyAddress.streetType);
        },
        supplyTypeTranslation: function () {
            return this.$t("ofex.backendCodes.supplyType." + this.supplyTypeBackendCode());
        },
        supplyStatusTranslation: function () {
            return this.$t("ofex.backendCodes.supplyStatus." + this.supplyStatusBackendCode());
        },
        supplyTypeBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.supplyTypeCode);
        },
        supplyStatusBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.supplyStatus);
        },
        supplyAffectationHourClass: function (startHour, endHour) {
            const startClass = typeof startHour === "string" ? startHour.split(":")[0] : null;
            const endClass = typeof endHour === "string" ? endHour.split(":")[0] : null;
            return startClass && endClass ? `start${startClass} end${endClass}` : "";
        },
        supplyAffectationCodeText: function (code, floor) {
            /*Código corresponde al tipo de afectación, 02 necesita especificar piso*/
            if (typeof code !== "string") return null;

            return code === "02"
                ? `${this.$t("ofex.afectaciones-contratos-detalle.code-" + code)} ${parseInt(floor)}`
                : this.$t("ofex.afectaciones-contratos-detalle.code-" + code);
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, triggerChangeActiveContractLoading: false });
        this.$store.dispatch(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, { contractNumber: this.$route.params.contractId, locale: this.$root.$i18n.locale });
        this.$store.dispatch(actionTypes.GET_CONTRACT_DETAIL_ACTION, {
            contractNumber: this.$route.params.contractId,
        });
    },
};
</script>

<style lang="scss" scoped>
.info-contract {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @include respond-above(sm) {
        margin-bottom: 20px;
    }
    .info-contract-inner {
        display: flex;
        flex-wrap: wrap;
        flex: 100%;
        order: 3;
        margin-top: 30px;
        @include respond-above(sm) {
            flex-wrap: nowrap;
            flex: 0 0 auto;
            order: 2;
            margin-top: 0;
        }
        .contract-title {
            color: $color-primary;
            @include font-size(18);
            @include line-height(21);
            font-weight: $font-primary-bold;

            @include respond-below(sm) {
                flex: 100%;
            }
            .contract-number {
                font-weight: $font-primary-medium;
                margin-left: 5px;
            }
        }
        .remote-reading {
            margin-top: 20px;
            margin-bottom: 0;
            @include respond-above(sm) {
                margin: 0 0 0 20px;
            }
        }
        .icon {
            margin-left: 7px;
        }
    }
    .favorites {
        display: flex;
        align-items: center;
        order: 2;
        align-self: center;
        @include font-size(12);
        @include line-height(14);
        color: $color-primary;
        font-weight: $font-primary-medium;
        @include respond-above(sm) {
            order: 3;
        }
        span {
            line-height: 24px;
            margin-right: 7px;
        }
        label {
            margin-bottom: 0;
        }
    }
}
.contract-list-item {
    margin-bottom: 30px;
}
//26/05/2020
.title-detail {
    @include font-size(18);
    @include line-height(21);
    font-weight: $font-primary-bold;
    border-bottom: 1px solid $blue-lighter;
    padding-bottom: 5px;
    margin-bottom: 15px;
}
.element-blue {
    @include font-size(14);
    @include line-height(21);
    color: $blue-lighter;
    font-weight: $font-primary-bold;
    margin-bottom: 10px;
}
.element-black {
    @include font-size(14);
    @include line-height(21);
    font-weight: $font-primary-medium;
    margin-left: 3px;
    color: $color-primary;
}

//Tabla sequia presiones
.grid-container {
    overflow: hidden;
    margin: 60px 0;
}
.grid {
    position: relative;
    width: 652px;
    height: 540px;
    padding: 40px 0 20px 60px;
    margin: 0;

    .time-container {
        position: absolute;
        left: 0;
        top: 0;
        padding-top: 31px;
        display: flex;
        flex-direction: column;
        row-gap: 19px;

        @include respond-below(sm) {
            background: $color-lightest;
            z-index: 1;
            padding-right: 15px;
        }

        .time {
            color: $color-form-text;
            @include font-size(21);
            @include line-height(21);
            font-weight: $font-primary-bold;
        }
    }

    .grid-inner {
        display: flex;
        gap: 90px;
        position: absolute;
        left: 85px;
        right: 0;
        top: 40px;
        bottom: 20px;

        @include respond-below(sm) {
            width: 455px;
            gap: 60px;
        }
    }

    .scroll-container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 1px;
        right: 0;
        overflow: auto;

        @include respond-below(sm) {
            max-width: 500px;
        }

        @include respond-below(xs) {
            max-width: 360px;
        }
    }

    .line-container {
        position: absolute;
        top: 40px;
        right: 0;
        bottom: 0;
        left: 60px;
        display: flex;
        flex-direction: column;
        row-gap: 39px;

        @include respond-below(sm) {
            width: 535px;
        }

        .line {
            height: 1px;
            width: 100%;
            background: #979797;
        }
    }
}

.column {
    position: relative;
    flex: 0 120px;

    .title {
        width: 120px;
        color: $blue-darkest;
        margin-top: -43px;
        text-align: center;
        @include font-size(15);
        @include line-height(19);
        font-weight: $font-primary-bold;
    }

    .item {
        background-color: #79aebf;
        position: absolute;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 120px;
        border-radius: 8px;
        @include font-size(14);
        @include line-height(19);
        font-weight: $font-primary-bold;

        &.no-data {
            background-color: $border-table;
            color: $color-lightest;
            border-radius: 8px;
        }

        &.full-day {
            top: 0;
            bottom: 0;
        }

        & > .description-text {
            width: 90%;
            word-break: break-word;
            margin-bottom: 0;
        }

        &.bg-grey {
            background-color: $input-read;
        }

        &.color-01 {
            color: $blue-darkest;
        }

        &.color-02 {
            color: #974d07;
        }

        &.color-03 {
            color: #6d332c;
        }

        &.start00 {
            top: 0;
            border-radius: 0 0 8px 8px;
        }
        &.start01 {
            top: 25px;
        }
        &.start02 {
            top: 45px;
        }
        &.start03 {
            top: 65px;
        }
        &.start04 {
            top: 85px;
        }
        &.start05 {
            top: 105px;
        }
        &.start06 {
            top: 125px;
        }
        &.start07 {
            top: 145px;
        }
        &.start08 {
            top: 165px;
        }
        &.start09 {
            top: 185px;
        }
        &.start10 {
            top: 205px;
        }
        &.start11 {
            top: 225px;
        }
        &.start12 {
            top: 245px;
        }
        &.start13 {
            top: 265px;
        }
        &.start14 {
            top: 285px;
        }
        &.start15 {
            top: 305px;
        }
        &.start16 {
            top: 325px;
        }
        &.start17 {
            top: 345px;
        }
        &.start18 {
            top: 365px;
        }
        &.start19 {
            top: 385px;
        }
        &.start20 {
            top: 405px;
        }
        &.start21 {
            top: 425px;
        }
        &.start22 {
            top: 445px;
        }
        &.start23 {
            top: 465px;
        }
        &.start24 {
            top: 485px;
        }

        &.end00 {
            bottom: 485px;
        }
        &.end01 {
            bottom: 465px;
        }
        &.end02 {
            bottom: 445px;
        }
        &.end03 {
            bottom: 425px;
        }
        &.end04 {
            bottom: 405px;
        }
        &.end05 {
            bottom: 385px;
        }
        &.end06 {
            bottom: 365px;
        }
        &.end07 {
            bottom: 345px;
        }
        &.end08 {
            bottom: 325px;
        }
        &.end09 {
            bottom: 305px;
        }
        &.end10 {
            bottom: 285px;
        }
        &.end11 {
            bottom: 265px;
        }
        &.end12 {
            bottom: 245px;
        }
        &.end13 {
            bottom: 225px;
        }
        &.end14 {
            bottom: 205px;
        }
        &.end15 {
            bottom: 185px;
        }
        &.end16 {
            bottom: 165px;
        }
        &.end17 {
            bottom: 145px;
        }
        &.end18 {
            bottom: 125px;
        }
        &.end19 {
            bottom: 105px;
        }
        &.end20 {
            bottom: 85px;
        }
        &.end21 {
            bottom: 65px;
        }
        &.end22 {
            bottom: 45px;
        }
        &.end23 {
            bottom: 25px;
        }
        &.end24 {
            bottom: 0;
            border-radius: 8px 8px 0 0;
        }
    }
}
/* ---------------------- */
</style>

<style lang="scss">
.affectation-text {
    color: #26749f;

    & > p {
        margin-bottom: 20px;
    }

    ul {
        padding-left: 40px;

        li {
            position: relative;
            margin-bottom: 10px;
            &::before {
                content: "";
                position: absolute;
                top: 5px;
                left: -26px;
                background-image: url(../assets/images/flecha-li.png);
                background-repeat: no-repeat;
                width: 12px;
                height: 12px;
                background-size: 12px;
            }
        }
    }
}
</style>
