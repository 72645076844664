var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('ab-loading',{attrs:{"opacity":0.85,"full-page":true,"loader-text":_vm.$t('ofex.loadingText.home')},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),(_vm.comingFromLogin && _vm.clientData && !_vm.isLoading && _vm.legalCheckStatus === 'FINISHED' && _vm.$isWebApp())?_c('ab-change-lang-dialog'):_vm._e(),_c('b-row',[_c('ab-left-nav-bar'),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"9"}},[_c('ab-main-title',{attrs:{"main-text":_vm.$t('ofex.home.title'),"username":_vm.userData?.firstName,"exclamation":'!'}}),_c('ab-select-contract'),(_vm.selectedContractNumber && _vm.showHomeDroughtModule && _vm.droughtConfiguration?.enabledAll)?_c('ab-situacion-sequia',{attrs:{"contract-number":_vm.selectedContractNumber}}):_vm._e(),_c('ab-title-section-ofex',{attrs:{"src-img":require('../assets/images/circleGraphicDig.png'),"title-section":_vm.$t('ofex.titleSectionOfex.tusConsumos')}}),(_vm.userInvoices && _vm.userInvoices.length > 0 && _vm.selectedContractNumber)?[(_vm.showDomesticModule)?[_c('ab-domestic-consumption',{key:_vm.selectedContractNumber + '-domestic'})]:(!_vm.showDomesticModule)?[_c('ab-tendency-consumption',{key:_vm.selectedContractNumber + '-tendency'})]:_vm._e()]:_c('ab-without-data',{staticClass:"mt-3",attrs:{"text":_vm.$t('ofex.home.noDataYet')}}),(!_vm.lastInvoice)?_c('ab-title-section-ofex',{attrs:{"src-img":require('../assets/images/circleTicket.png'),"title-section":_vm.$t('ofex.titleSectionOfex.ultimaFactura.titleSection')}}):_vm._e(),(_vm.lastInvoice)?_c('ab-title-section-ofex',{attrs:{"src-img":require('../assets/images/circleTicket.png'),"sub-title":true,"title-section":_vm.$t('ofex.titleSectionOfex.ultimaFactura.titleSection'),"text-section":_vm.$t('ofex.titleSectionOfex.ultimaFactura.textSection', {
                        fromDay: _vm.startDateMoment.date(),
                        fromMonth: _vm.startDateMoment.format('MMMM'),
                        fromYear: _vm.startDateMoment.year(),
                        toDay: _vm.endDateMoment.date(),
                        toMonth: _vm.endDateMoment.format('MMMM'),
                        toYear: _vm.endDateMoment.year(),
                    })}}):_vm._e(),(_vm.userInvoices && _vm.userInvoices.length > 0)?_c('ab-invoice-summary',{attrs:{"invoice":_vm.lastInvoice}}):_c('ab-without-data',{attrs:{"text":_vm.$t('ofex.withoutData.lastInvoice')}}),_c('ab-title-section-ofex',{attrs:{"src-img":require('../assets/images/circleGraphic.png'),"title-section":_vm.$t('ofex.titleSectionOfex.EvolucionFactura')}}),(!_vm.contigenciasStatus['sicab'])?_c('ab-contingencias',{staticClass:"mb-5"}):(_vm.userInvoices && _vm.userInvoices.length > 0)?_c('ab-evolution-invoices',{attrs:{"contract":_vm.selectedContractNumber}}):_c('ab-without-data',{attrs:{"text":_vm.$t('ofex.withoutData.lastInvoice')}}),(_vm.selectedContractNumber && _vm.selectedContractTelerecording && !_vm.contigenciasStatus['mdm'])?_c('ab-contingencias',{staticClass:"mb-5"}):_vm._e(),_c('ab-title-section-ofex',{attrs:{"src-img":require('../assets/images/circleBombilla.png'),"title-section":_vm.$t('ofex.titleSectionOfex.incidenciasServicio')}}),((_vm.droughtConfiguration?.enabledAll && _vm.hasAnyDroughtAffectedContract) || (_vm.unreadNotifications && _vm.unreadNotifications.length > 0))?_c('ab-service-incidents'):_c('ab-without-data',{attrs:{"text":_vm.$t('ofex.withoutData.incidents')}}),_c('ab-your-interest',{attrs:{"name-left":_vm.$t('ofex.relatedContents.explicamosAdc.name'),"href-left":_vm.$t('ofex.relatedContents.explicamosAdc.url'),"image-left":_vm.$t('ofex.relatedContents.explicamosAdc.image'),"name-center":_vm.$t('ofex.relatedContents.quePagasFactura.name'),"href-center":_vm.$t('ofex.relatedContents.quePagasFactura.url'),"image-center":_vm.$t('ofex.relatedContents.quePagasFactura.image'),"name-right":_vm.$t('ofex.relatedContents.consellEstalvi.name'),"href-right":_vm.$t('ofex.relatedContents.consellEstalvi.url'),"image-right":_vm.$t('ofex.relatedContents.consellEstalvi.image')}})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }