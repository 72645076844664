<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="checkbox-group d-flex align-items-center">
        <div class="checkbox-input">
            <input :id="name" v-model="modelValue" type="checkbox" :name="name" class="input" :aria-label="label" />
        </div>
        <div class="checkbox-label">
            <label class="label" :for="name">
                {{ label }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        name: String,
        inputAlias: String,
        isRequired: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            required: true,
        },
        uncheckedValue: {
            type: String,
            default: "false",
        },
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["inputValue"];
            },
            set: function (newValue) {
                let computedValue = newValue === false ? JSON.parse(this.uncheckedValue) : newValue;
                this.$emit("change", computedValue);
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.checkbox-group {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    .label {
        color: $color-primary;
        font-weight: $font-primary-medium;
        @include font-size(14);
        @include line-height(16);
        margin-bottom: 0;
        top: -1px;
        position: relative;
    }
    .checkbox-input {
        margin-top: 5px;
        margin-right: 10px;
    }
    .input {
        width: 18px;
        height: 18px;
        appearance: none;
        position: relative;
        border: 1px solid #aaa;
        cursor: pointer;

        &:checked {
            background: $blue-dark;
            border: 1px solid $blue-dark;
            position: relative;
            &:before {
                content: url("../assets/images/svg/check-white.svg");
                width: 14px;
                height: 100%;
                top: -3px;
                left: 1px;
                position: absolute;
            }
        }
        &:focus {
            outline: 0;
            border: 1px solid #aaa;
        }
    }
}
</style>
