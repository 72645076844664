var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ab-input',{attrs:{"name":_vm.name,"label":_vm.label,"input-alias":_vm.inputAlias,"placeholder":_vm.placeholder,"validation":{
                    alpha_num: true,
                    required: _vm.isRequired,
                    max: 32,
                    min: 15,
                    iban: true,
                    remoteiban: false,
                    ibanCountry: { country: _vm.externalCountry },
                }},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}}),_c('ab-popover',{attrs:{"popover":_vm.popover}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }