<template>
    <div class="table-select-contract">
        <div class="mt-2 pt-4">
            <p class="subtitle-collapse">{{ $t("ofex.modificarDatosContrato.seleccionarContratos") }}</p>
            <ab-contracts-advance-search
                ref="advanceSearch"
                v-model="filteredContracts"
                :extra-filter-predicate="contractByModificationTypePredicate"
                :onlyActiveContracts="true"
            />
            <vue-good-table
                :columns="paymentColumns"
                :rows="tableRows"
                style-class="vgt-table"
                :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: 6,
                    perPageDropdown: [6, 20, 30],
                    dropdownAllowAll: false,
                    setCurrentPage: 1,
                    nextLabel: this.$i18n.tc('table.siguiente'),
                    prevLabel: this.$i18n.tc('table.atras'),
                    rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                    ofLabel: 'de',
                    allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                    label: 'selector-de-paginas',
                    name: 'perPageSelect',
                }"
            >
                <template slot="table-row" slot-scope="props">
                    <div class="d-flex" v-if="props.column.field == 'contrato'">
                        <input
                            type="radio"
                            aria-label="Selección de contrato"
                            :title="props.row.contrato"
                            v-model="modelValue"
                            :value="props.row.contrato"
                            name="radio-contract"
                            class="mr-2 align-input"
                        />
                        <span class="contract">{{ props.row.contrato }}</span>
                        <img v-if="props.row.telectura" class="icon-contrato" alt="bola telelectura" src="../../../assets/images/bola-telelectura.png" />
                    </div>
                    <div v-else-if="props.column.field == 'pago'">
                        <span class="contract">{{ props.row.pago }}</span>
                    </div>
                    <div v-else-if="props.column.field == 'iban'">
                        <span class="contract">{{ props.row.iban }}</span>
                    </div>
                    <div v-line-clamp:20="2" v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>
                <div slot="emptystate" class="text-center textdnormal">
                    {{ $t("ofex.tuContrato.emptyResults") }}
                </div>
            </vue-good-table>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { trimBackendCode } from "@/plugins/ab-utils";
import AbContractsAdvanceSearch from "../../../modules/AbContractsAdvanceSearch";
import * as viewMappingTypes from "@/router/view-mapping-types";
export default {
    name: "AbTableSelectContract",
    components: {
        AbContractsAdvanceSearch,
    },
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        modificationType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            filteredContracts: null,
            tableSelectOptions: {
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: "custom-class",
                selectionText: this.$t("table.contratosSeleccionados"),
                clearSelectionText: this.$t("ofex.tuContrato.limpiar"),
                disableSelectInfo: true, // disable the select info panel on top
            },
        };
    },
    computed: {
        ...mapState(["activeContracts", "allContracts", "contractDetail"]),
        ...mapGetters(["isCompany", "multicontract"]),
        tableRows: function () {
            return (this.filteredContracts !== null
                ? this.filteredContracts.map(this.mapContractToTableRow)
                : this.activeContracts.map(this.mapContractToTableRow)
            ).filter((row) => this.rowByModificationTypePredicate(row));
        },
        paymentColumns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contrato",
                    type: "number",
                    tdClass: "",
                    thClass: "",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                    tdClass: "",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "suministro",
                    tdClass: "col-suministro",
                    thClass: "col-suministro",
                },
                {
                    label: this.$t("ofex.tuContrato.fechaAlta"),
                    field: "fechaDeAlta",
                    tdClass: "",
                    thClass: "",
                    hidden: this.modificationType !== "baja",
                },
                {
                    label: this.$t("ofex.tuContrato.direccionCorrespondencia"),
                    field: "direccionCorrespondencia",
                    tdClass: "col-suministro",
                    thClass: "col-suministro",
                    hidden: this.modificationType !== viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.name,
                },
                {
                    label: this.$t("ofex.tuContrato.tipoUso"),
                    field: "tipoUsoCedula",
                    tdClass: "",
                    thClass: "",
                    hidden: this.modificationType !== viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name,
                },
                {
                    label: this.$t("ofex.tuContrato.cedulHabitabilidad"),
                    field: "numeroCedula",
                    tdClass: "",
                    thClass: "",
                    hidden: this.modificationType !== viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name,
                },
                {
                    label: this.$t("ofex.tuContrato.fechaCedula"),
                    field: "fechaCedula",
                    tdClass: "",
                    thClass: "",
                    hidden: this.modificationType !== viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name,
                },
                {
                    label: this.$t("ofex.tuContrato.tipoIAE"),
                    field: "tipoIae",
                    tdClass: "",
                    thClass: "",
                    hidden: this.modificationType !== viewMappingTypes.INSERT_IAE_DATA_MAPPING.name,
                },
                {
                    label: "Número IAE",
                    field: "numeroIae",
                    tdClass: "",
                    thClass: "",
                    hidden: this.modificationType !== viewMappingTypes.INSERT_IAE_DATA_MAPPING.name,
                },
                {
                    label: this.$t("ofex.tuContrato.descripcionIAE"),
                    field: "descripcionIae",
                    tdClass: "",
                    thClass: "",
                    hidden: this.modificationType !== viewMappingTypes.INSERT_IAE_DATA_MAPPING.name,
                },
                {
                    label: this.$t("ofex.modificarDatosContrato.metodosPago"),
                    field: "pago",
                    tdClass: "",
                    thClass: "",
                    hidden: this.modificationType !== viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.name,
                },
                {
                    label: "IBAN",
                    field: "iban",
                    tdClass: "",
                    thClass: "",
                    html: true,
                    hidden: this.modificationType !== viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.name,
                },
            ];
        },
        modelValue: {
            get: function () {
                return this.$attrs["inputValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    methods: {
        rowByModificationTypePredicate: function (row) {
            switch (this.modificationType) {
                case viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name:
                    return row.tipoUsoCedulaCode === "DOMESTIC";
                case viewMappingTypes.INSERT_IAE_DATA_MAPPING.name:
                    return row.tipoUsoCedulaCode !== "DOMESTIC";
                default:
                    return true;
            }
        },
        contractByModificationTypePredicate: function (contract) {
            switch (this.modificationType) {
                case viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name:
                    return contract.contractDetail.useTypeCode === "DOMESTIC";
                case viewMappingTypes.INSERT_IAE_DATA_MAPPING.name:
                    return contract.contractDetail.useTypeCode !== "DOMESTIC";
                default:
                    return true;
            }
        },
        mapContractToTableRow: function (contractEntry) {
            return {
                telectura: contractEntry.contractDetail.telerecording,
                contrato: contractEntry.contractDetail.contractNumber,
                alias: contractEntry.contractDetail.alias,
                suministro:
                    this.$i18n.tc(`ofex.backendCodes.streetTypes.${trimBackendCode(contractEntry.supplyAddress.streetType)}`) +
                    " " +
                    contractEntry.supplyAddress.streetName +
                    " " +
                    contractEntry.supplyAddress.number +
                    ", " +
                    contractEntry.supplyAddress.otherAddressData +
                    ", " +
                    contractEntry.supplyAddress.postalCode +
                    ", " +
                    contractEntry.supplyAddress.municipality,
                municipio: contractEntry.supplyAddress.municipality,
                fechaDeAlta: contractEntry.contractDetail.registrationDate,
                direccionCorrespondencia:
                    this.$i18n.tc(`ofex.backendCodes.streetTypes.${trimBackendCode(contractEntry.mailAddress.streetType)}`) +
                    " " +
                    contractEntry.mailAddress.otherAddressData,
                tipoUsoCedula: this.$i18n.tc("ofex.backendCodes.useType." + trimBackendCode(contractEntry.contractDetail.useTypeCode)),
                tipoUsoCedulaCode: contractEntry.contractDetail.useTypeCode,
                numeroCedula: contractEntry.contractDetail.habitabilityCertificate,
                fechaCedula: contractEntry.contractDetail.habitabilityCardDate,
                estado: contractEntry.contractDetail.supplyStatus,
                tipoIae: contractEntry.billingData.iae.type,
                numeroIae: contractEntry.billingData.iae.number,
                descripcionIae: contractEntry.billingData.iae.description,
                pago: this.$i18n.tc("ofex.backendCodes.paymentMethod." + trimBackendCode(contractEntry.paymentData.paymentMethod)),
                iban: contractEntry.paymentData.iban != null ? this.changeIBAN(contractEntry.paymentData.iban) : "",
            };
        },
        changeIBAN: function (iban) {
            let firstSection = iban.substring(0, 4);
            let secondSection = iban.substring(6, 23);
            let thirdSection = iban.substring(35, 39);
            iban = iban.replace(firstSection, "****");
            iban = iban.replace(secondSection, "*****");
            iban = iban.replace(thirdSection, "****");
            return iban;
        },
        getRowStyleClass(row) {
            return row.estado.toUpperCase() == "BAIXA" || row.status.toUpperCase() == "BAJA" ? "baja-row" : "";
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/contract-modification.scss";
</style>
<style lang="scss" scoped>
.table-select-contract {
    .m-advance-search {
        .multiselect__select {
            width: 28px;
            height: 32px;
        }
        .multiselect__input {
            margin-top: 5px;
        }
    }
}
.baja-row {
    background-color: #f8d5d3;
}
</style>
<style lang="scss">
.m-advance-search {
    .your-contract .select-component {
        margin-bottom: 20px;
    }
}
</style>
