<i18n src="../../components/common/i18n-components.i18n"></i18n>

<template>
    <div class="error-modal-container">
        <modal class="modal-generic-error" name="modal-generic-error" :scrollable="true" :click-to-close="false" height="auto" width="532" :adaptive="true">
            <font-awesome-icon class="icon" icon="exclamation-triangle"></font-awesome-icon>
            <p class="text">{{ modalText }}</p>

            <div class="btn-container">
                <ab-button-ofex :text-button="this.$t('common.okButton')" :click-event="closeModal"></ab-button-ofex>
            </div>

            <!--          SE HA UNIFICADO EL COMPORTAMIENTO, DEJANDO SOLO EL BOTON ACEPTAR. VER UATOFEX-530-->
            <!--            <div class="btn-container" v-if="$router.currentRoute.name === homeViewMapping">
                <ab-button-ofex :text-button="this.$t('common.okButton')" :click-event="closeModal"></ab-button-ofex>
            </div>
            <div class="btn-container" v-else>
                <ab-button-ofex :text-button="this.$t('common.inicio')" :click-event="homeButtonEvent"></ab-button-ofex>
                <ab-button-ofex :text-button="this.$t('common.atras')" :click-event="backButtonEvent"></ab-button-ofex>
            </div>-->
        </modal>
    </div>
</template>

<script>
import { mapState } from "vuex";
import * as actionTypes from "@/store/action-types";
import * as viewMappingTypes from "@/router/view-mapping-types";

export default {
    name: "AbErrorModal",
    props: {},
    data: function () {
        return {
            //https://vuex.vuejs.org/api/#subscribe
            unsubscribe: null,
            isHome: null,
            homeViewMapping: viewMappingTypes.HOME_VIEW_MAPPING.name,
        };
    },
    methods: {
        backButtonEvent: function () {
            this.$modal.hide("modal-generic-error");
            this.$router.go(-1);
        },
        homeButtonEvent: function () {
            this.$modal.hide("modal-generic-error");
            this.$router.push({ name: viewMappingTypes.HOME_VIEW_MAPPING.name });
        },
        logoutButtonEvent: function () {
            this.$modal.hide("modal-generic-error");
            this.$store.dispatch(actionTypes.LOGOUT_ACTION);
        },
        closeModal: function () {
            this.$modal.hide("modal-generic-error");
        },
    },
    computed: {
        empty: function () {
            return this.$router.currentRoute.name === viewMappingTypes.HOME_VIEW_MAPPING.name;
        },
        modalText: function () {
            return this.genericError && this.genericError.errorMessage ? this.genericError.errorMessage : "Ha ocurrido un error";
        },
        ...mapState(["genericError"]),
    },
    mounted() {},
    watch: {
        genericError: function () {
            if (this.genericError !== null) {
                this.$modal.show("modal-generic-error");
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
