<template>
    <div>
        <p class="subtitle">{{ $t("ofex.facturaDigital.subTitleActivada") }}</p>
        <div class="box-grey-activate d-xl-flex align-items-center justify-content-between">
            <div>
                <img class="img-ticket" src="../../../assets/images/ofex/factura-digital/ticket.png" alt="Logo factura digital activada" />
                <span class="title-box">{{ $t("ofex.facturaDigital.activada") }}</span>
            </div>
            <!-- Ab Legal section-->
            <div class="btn-desactivate">
                <ab-button-ofex
                    :disabled="active"
                    class="btn-white"
                    :click-event="successfullyDeactivated"
                    :text-button="$t('ofex.facturaDigital.desactivar')"
                    text="boton desactivar factura digital"
                ></ab-button-ofex>
            </div>
        </div>
        <p class="text-second-section">
            {{ $t("ofex.facturaDigital.confirmacion.consultar") }}
            <span class="href-fsp" @click="openModal()">{{ $t("ofex.facturaDigital.confirmacion.prestacionServicio") }}</span>
        </p>
        <ab-remote-modal :show-cancel-button="false" :html-url-es="legalUrls.fspEs" :html-url-ca="legalUrls.fspCa" modal-name="fsp-modal"> </ab-remote-modal>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import * as actionTypes from "@/store/action-types";
import { mapGetters, mapState } from "vuex";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import InvoiceFspPdfGeneator from "@/generators/pdf/InvoiceFspPdfGeneator";
import * as viewMappings from "@/router/view-mapping-types";
export default {
    name: "AbDigitalInvoiceEnabled",
    computed: {
        ...mapState(["isLoading", "contracts", "clientData", "selectedContractNumber"]),
        ...mapGetters(["clientId", "userId", "legalUrls", "getContractSupplyCompleteAddress"]),
    },
    props: {
        active: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        openModal: function () {
            this.$modal.show("fsp-modal");
        },
        routerBack: function () {
            this.$router.go(-1);
        },
        successfullyDeactivated: function () {
            console.debug("Activar Factura");
            Swal.fire({
                text: this.$i18n.tc("ofex.facturaDigital.modalMonoDesactivate"),
                icon: "question",
                confirmButtonText: "OK",
                denyButtonText: "No",
                showDenyButton: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let contractNumbers = this.contracts.map((value) => value.contractDetail.contractNumber);
                    let pdfGenerator = new InvoiceFspPdfGeneator(this.$i18n, this.userId, this.clientId, "");
                    pdfGenerator.generatePdf(contractNumbers, "", false);
                    await pdfGenerator.uploadPdf();
                    this.$store
                        .dispatch(actionTypes.UPDATE_FSP, {
                            active: "INACTIVE",
                            fspUrl: "",
                            pdf: pdfGenerator.uploadedDocumentId,
                            contractsNumbers: contractNumbers,
                        })
                        .then((value) => {
                            this.$notifyFormActionToMatomo(true, "fsp_inactive_modification");
                            console.debug("Datos Modificados" + value);
                            let supplyAddress = this.getContractSupplyCompleteAddress(this.selectedContractNumber);

                            Swal.fire({
                                showCloseButton: true,
                                showCancelButton: false,
                                text: this.$t("formularios.desabilitarFacturaDigital.sendMessage"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                                html:
                                    this.$i18n.t("ofex.facturaDigital.modaltitleDesactivate1", { address: supplyAddress }) +
                                    this.$i18n.t("ofex.facturaDigital.modaltitleDesactivate2", {
                                        windowLocationPathname: this.$windowLocationPathname,
                                        modifyUrl: viewMappings.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.path.replace(
                                            ":contractId",
                                            this.selectedContractNumber
                                        ),
                                    }),
                            });
                            this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
                            this.$store.commit(SET_LOADING_MUTATION, { isLoading: true, trigger: "InvoiceDigitalView" });
                        })
                        .catch((reason) => {
                            this.launchUpdateFSP(reason);
                            this.$notifyFormActionToMatomo(false, "fsp_inactive_modification", reason?.response?.data?.errorMessage);
                        });
                }
            });
        },
        launchUpdateFSP: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "InvoiceDigitalView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: e.response && e.response.data ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/digital-invoice.scss";
</style>
