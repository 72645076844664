<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <dropdown :close-on-click="true">
        <template slot="btn">{{ label }}</template>
        <template slot="icon">
            <font-awesome-icon class="icon" :icon="nameIcon"></font-awesome-icon>
            <div
                class="notification-alert"
                v-if="userData.unreadNotifications > 0 && loadNotifications === true"
                :class="{ 'is-loading': isLoading }"
                disabled="true"
            >
                <span class="number-bell" v-if="notifications && notifications.length > 0">
                    {{ userData.unreadNotifications > 9 ? "+9" : userData.unreadNotifications }}
                </span>
                <div class="notification-loader">
                    <ab-loading v-model="isLoading" :opacity="0" :full-page="false"></ab-loading>
                </div>
            </div>
        </template>
        <template slot="body">
            <div class="loading-container">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <ab-notifications-list
                    :father-class="'v-header'"
                    :text="this.$i18n.tc('ofex.notificationList.noAvisos')"
                    :header-notice="false"
                    :notifications="filterLastNotifications"
                />
                <div class="btn-container">
                    <div>
                        <router-link class="item" :to="{ name: viewMappingTypes.CONFIGURATION_NOTICES_MAPPING.name }"
                            ><img class="icon-config" alt="icono configuracion" src="../assets/images/svg/config.svg" />{{
                                $t("ofex.notificationsView.configurar")
                            }}</router-link
                        >
                    </div>
                    <div>
                        <router-link class="item" :to="{ name: viewMappingTypes.NOTIFICATIONS_LIST_MAPPING.name }"
                            >{{ $t("ofex.notificationsView.verTodo") }}<font-awesome-icon class="icon" icon="arrow-right"></font-awesome-icon
                        ></router-link>
                    </div>
                </div>
            </div>
        </template>
    </dropdown>
</template>

<script>
import Dropdown from "bp-vuejs-dropdown";
import * as actionTypes from "../store/action-types";
import { mapState } from "vuex";
import AbNotificationsList from "../modules/notifications/AbNotificationsList";
import * as viewMappingTypes from "../router/view-mapping-types";

export default {
    name: "AbNotifications",
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
        };
    },
    components: {
        AbNotificationsList,
        Dropdown,
    },
    props: {
        label: {
            type: String,
        },
        nameIcon: {
            type: String,
        },
    },
    computed: {
        ...mapState(["notifications", "userData", "loadNotifications"]),
        isLoading: {
            get: function () {
                return this.userData.unreadNotifications > 0 && this.notifications.length === 0;
            },
            set: function () {
                console.warn("No se deberia cambiar el estado en este caso");
            },
        },
        filterLastNotifications: function () {
            return this.loadNotifications === true && this.notifications ? this.notifications.slice(0, this.userData.unreadNotifications) : [];
        },
    },
    mounted() {
        this.$nextTick(function () {
            if (this.$router.currentRoute.meta.loadNotifications !== false && this.$store.state.loadNotifications === true) {
                console.debug("Cargando notificaciones de la campana para ruta " + this.$router.currentRoute.name);
                this.$store.dispatch(actionTypes.GET_USER_NOTIFICATIONS_ACTION, { locale: this.$i18n.locale, triggerLoading: false });
            }
        });
    },
};
</script>

<style lang="scss">
.number-bell {
    @include font-size(12);
    top: 3.5px;
    position: relative;
    right: 1px;
}
.v-header {
    .m-notices {
        overflow: auto;
        height: auto;
        max-height: 490px;
    }
}
.loading-container {
    position: relative;
    min-width: 340px;

    .vld-overlay.is-full-page {
        position: absolute;
    }
    .vld-overlay .vld-icon,
    .vld-parent {
        position: initial;
        z-index: 10;
    }
}
.notification-alert {
    &:focus,
    div {
        border: 0;
        outline: 0;
    }
    .notification-loader {
        position: relative;

        svg {
            width: 13px;
            height: 13px;
            position: absolute;
            left: 0;
            stroke: $color-lightest;
        }
        .vld-overlay .vld-icon,
        .vld-parent {
            position: relative;
            height: 13px;
            width: 13px;
        }
        .vld-overlay.is-full-page {
            position: relative;
        }
    }
}
.user-panel-container .logged-container .notification-container .notification-alert.is-loading {
    background: transparent;
}
.dropdown-list {
    margin-bottom: 0;

    .notification-link {
        display: flex;
        border-bottom: 1px solid $border-table;
        padding: 15px;
        color: $color-primary;

        .img-container {
            flex: 0 0 auto;
            width: 50px;
            margin-right: 15px;
        }
    }
    .item {
        &.special {
            .notification-link {
                border-left: 6px solid $special-notice;

                @include respond-above(md) {
                    border-left-width: 5px;
                }
            }
        }
        &.periodic {
            .notification-link {
                border-left: 6px solid $periodic-notice;

                @include respond-above(md) {
                    border-left-width: 5px;
                }
            }
        }
        &.punctual {
            .notification-link {
                border-left: 6px solid $punctual-notice;

                @include respond-above(md) {
                    border-left-width: 5px;
                }
            }
        }
    }
    .item-container {
        p {
            margin-bottom: 0;
            @include font-size(12);
            @include line-height(16);
            font-weight: $font-primary-medium;

            span:first-child {
                margin-right: 5px;
            }
        }
        .notification-title {
            @include font-size(12);
            @include line-height(16);
            color: $blue-dark;
            font-weight: $font-primary-bold;
            margin-bottom: 5px;
            white-space: nowrap;
            display: inline-block;
        }
        .info {
            color: $color-primary;
            @include font-size(12);
            @include line-height(16);
            font-weight: $font-primary-medium;
        }
    }
}
.notification-container {
    .notification-link {
        &:hover {
            background: $bg-grey-light;
        }
    }
}
.btn-container {
    display: flex;

    div {
        padding: 20px;
        flex: 50%;
        text-align: left;

        &:last-child {
            text-align: right;
        }
        .item {
            color: $link-primary;
            @include font-size(16);
            @include line-height(19);
            font-weight: $font-primary-semiBold;
        }
    }
}
</style>
