"use strict";

import BaseXlsxGenerator from "@/generators/xlsx/base/BaseXlsxGenerator";
import * as xlsx from "xlsx";

export default class InvoicePaymentByContractXlsxGenerator extends BaseXlsxGenerator {
    constructor(filename, invoicePaymentByContractRowsArray, i18n) {
        let rows = invoicePaymentByContractRowsArray.map((i) => {
            return {
                fullInvoiceNumber: i.fullInvoiceNumber,
                endDate: i.endDate,
                issueDate: i.issueDate,
                consumption: i.consumption,
                amount: i.amount,
                status: i.status,
                shippmentType: i.shippmentType,
            };
        });

        let invoicePaymentByContractSheet = xlsx.utils.json_to_sheet(rows);

        invoicePaymentByContractSheet["A1"]["v"] = i18n.tc("ofex.invoicesTable.columnNames.invoiceNumber");
        invoicePaymentByContractSheet["B1"]["v"] = i18n.tc("ofex.invoicesTable.columnNames.consuptiomUntil");
        invoicePaymentByContractSheet["C1"]["v"] = i18n.tc("ofex.invoicesTable.columnNames.issueDate");
        invoicePaymentByContractSheet["D1"]["v"] = i18n.tc("ofex.invoicesTable.columnNames.consumption");
        invoicePaymentByContractSheet["E1"]["v"] = i18n.tc("ofex.invoicesTable.columnNames.amount");
        invoicePaymentByContractSheet["F1"]["v"] = i18n.tc("ofex.invoicesTable.columnNames.status");
        invoicePaymentByContractSheet["G1"]["v"] = i18n.tc("ofex.invoicesTable.columnNames.shippmentType");
        super(filename, invoicePaymentByContractSheet);
    }
}
