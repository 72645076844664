<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="float-left">
                    <label class="letraform-new w-100" :for="name">{{ label }} </label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-8">
                <validation-provider
                    mode="eager"
                    :name="inputAlias"
                    :vid="`${name}-vid`"
                    :rules="{ required: required, max: maxLength }"
                    :debounce="validationDelay"
                    v-slot="{ errors, classes }"
                >
                    <textarea
                        :id="name"
                        :ref="reference"
                        v-model="modelValue"
                        class="inputnewform alturaTextArea"
                        :name="name"
                        :maxlength="maxLength"
                        :data-vv-as="inputAlias"
                        :class="errors.length > 0 ? 'inputFormErr' : ''"
                        :required="true"
                        :placeholder="placeholder"
                    >
                    </textarea>
                    <p v-show="errors.length > 0" class="formTextErr displayYes">{{ errors[0] }}</p>
                </validation-provider>

                <div class="row">
                    <div class="col-12">
                        <div class="float-left">
                            <span class="textdownbolagrey">{{ $t("textArea.charsMax", { number: maxLength }) }}</span>
                        </div>
                        <div class="float-right">
                            <span class="textdownbolagrey"> <span class="textdownbolagrey"></span> {{ currentLength }}/{{ maxLength }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: "inputValue",
        event: "change",
    },
    props: {
        required: {
            default: false,
            type: Boolean,
        },
        maxLength: {
            default: 1000,
            type: Number,
        },
        name: {
            required: true,
            type: String,
        },
        popover: {
            required: false,
            type: String,
        },
        label: {
            required: true,
            type: String,
        },
        placeholder: {
            required: false,
            type: String,
        },
        reference: {
            required: false,
            type: Object,
        },
        inputAlias: String,
        validationDelay: {
            type: Number,
            default: 300,
        },
    },
    data: function () {
        return {};
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["inputValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
        currentLength: function () {
            return this.modelValue.length;
        },
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
textarea {
    border-radius: 8px;
    &.inputFormErr {
        border: 1px solid #f48989;
        background-color: #feefef;
    }
}
.textdownbolagrey {
    @include font-size(10);
    color: $border-table;
    font-weight: $font-primary-bold;
}
</style>
