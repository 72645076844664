<template>
    <b-container>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-main-title :main-text="$t('ofex.bajaContratoDetalle.bajaContrato')" />
                <ab-confirmation
                    :title="$t('ofex.confirmacionBajaContrato.title')"
                    :confirmation-msg="$t('ofex.confirmacionBajaContrato.subtitle')"
                    :show-pdf-button="false"
                ></ab-confirmation>
                <p>{{ $t("ofex.confirmacionBajaContrato.funcionFecha") }}</p>
                <p>{{ $t("ofex.confirmacionBajaContrato.satisfacerImportes") }}</p>
                <div class="line-top d-md-flex justify-content-end">
                    <div class="box-button-download mt-4">
                        <ab-button-ofex :font-awesome-icon="'download'" :text-button="$t('ofex.confirmacionBajaContrato.descargarSolicitud')"></ab-button-ofex>
                    </div>
                    <div class="box-button mt-4">
                        <ab-button-ofex
                            class="p-0 pt-2 pb-2"
                            :click-event="routerNavigateHome"
                            :text-button="$t('ofex.pagarFactura.volverInicio')"
                        ></ab-button-ofex>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: "ConfirmationUnsuscribeContractView",
    methods: {
        routerNavigateHome: function () {
            this.$router.push({ name: "Area Privada" });
        },
    },
};
</script>
<style lang="scss" scoped>
.line-top {
    width: 100%;
    border-top: 1px solid $blue-dark;
}
.box-button {
    margin-left: 30px;
    @include respond-below(sm) {
        width: 100%;
        margin-left: 0;
    }
}
.box-button-download {
    @include respond-below(sm) {
        width: 100%;
    }
}
</style>
