<template>
    <div>
        <ab-legal-ur-modal
            ref="legalUr"
            v-show="showLegalURFlow"
            modal-name="legal-ur-modal"
            legal-type="LEGAL"
            :required-action="true"
            :html-url-es="legalUrls.urEs"
            :html-url-ca="legalUrls.urCa"
        ></ab-legal-ur-modal>

        <ab-legal-ur-modal
            ref="legalFsp"
            v-show="showLegalFSPFlow"
            modal-name="legal-fsp-modal"
            legal-type="FSP"
            :required-action="true"
            :html-url-es="legalUrls.fspEs"
            :html-url-ca="legalUrls.fspCa"
        ></ab-legal-ur-modal>

        <ab-consents-modal ref="consents" @consents-accepted="consentsAcceptedHandler" v-show="showTermsFlow" />
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import * as viewMappingTypes from "../../router/view-mapping-types";
import Swal from "sweetalert2";
import AbConsentsModal from "@/modules/legal/AbConsentsModal";
import AbLegalUrModal from "@/modules/legal/AbLegalUrModal";
import * as actionTypes from "../../store/action-types";
import * as mutationTypes from "@/store/mutation-types";
import LiferayServiceClient from "@/rest/liferayServiceClient";
import ConsentsModifyPdfGenerator from "@/generators/pdf/ConsentsModifyPdfGenerator";
export default {
    name: "AbLegalCheckFlow",
    components: {
        AbConsentsModal,
        AbLegalUrModal,
    },
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
            showLegalURFlow: false,
            showLegalFSPFlow: false,
            showTermsFlow: false,
            hasAcceptedLegal: false,
            hasAcceptedFsp: false,
            hasAcceptedConsents: false,
            stepNumber: null,
        };
    },
    computed: {
        ...mapState(["clientData", "userData"]),
        ...mapGetters(["showLegalCheckFlow", "legalUrls", "clientId", "userId"]),
        legalUrlAccepted: function () {
            return this.hasAcceptedLegal ? (this.$i18n.locale === "ca" ? this.legalUrls.urCa : this.legalUrls.urEs) : null;
        },
        fspUrlAccepted: function () {
            return this.hasAcceptedFsp ? (this.$i18n.locale === "ca" ? this.legalUrls.fspCa : this.legalUrls.fspEs) : null;
        },
    },
    methods: {
        checkAndShowLegalURModal: function () {
            console.debug("Verificando si es necesario mostrar nuevas condiciones legales");
            if (this.userData.legalTerms.show === true) {
                console.debug("Mostrando condiciones legales");
                this.stepNumber = this.stepNumber + 1;
                this.showLegalURFlow = true;
                Swal.fire({
                    title: `${this.$t("ofex.urModal.paso")} ${this.stepNumber} \n${this.$t("ofex.urModal.condicionesOfex")}`,
                    html: '<div id="legal-ur-modal"></div>',
                    showConfirmButton: true,
                    confirmButtonText: this.$t("common.okButton"),
                    showCancelButton: !this.userData.legalTerms.required,
                    cancelButtonText: this.$t("ofex.urModal.omitir"),
                    allowOutsideClick: false, // !this.userData.legalTerms.required,  https://zenerab.atlassian.net/browse/UATOFEX-814
                    allowEscapeKey: !this.userData.legalTerms.required,
                    customClass: {
                        popup: "re-size",
                    },
                    onBeforeOpen: () => {
                        document.getElementById("legal-ur-modal").appendChild(this.$refs.legalUr.$el);
                    },
                    preConfirm: async () => {
                        let validator = this.$refs.legalUr.$refs.legalUrModalLEGAL;
                        let result = await validator.validate();
                        let legalAccepted = this.$refs.legalUr.$refs.legalSection.legalAceptado;
                        console.debug(`Modal aceptado (${legalAccepted}) y validación con resultado (${result})`);
                        return result === true
                            ? {
                                  accepted: legalAccepted,
                                  validated: result,
                              }
                            : false;
                    },
                }).then((result) => {
                    if (result.isConfirmed && result.value.accepted) {
                        this.hasAcceptedLegal = true;
                    }
                    this.checkAndShowLegalFSPModal();
                });
            } else {
                console.debug("No es necesario mostrar las condiciones legales");
                this.checkAndShowLegalFSPModal();
            }
        },
        checkAndShowLegalFSPModal: function () {
            console.debug("Verificando si es necesario mostrar nuevas condiciones FSP");
            if (this.userData.fspTerms.show === true) {
                console.debug("Mostrando condiciones FSP");
                this.stepNumber = this.stepNumber + 1;
                this.showLegalFSPFlow = true;
                Swal.fire({
                    title: `${this.$t("ofex.urModal.paso")} ${this.stepNumber} \n${this.$t("ofex.urModal.condicionesFacturaDigital")}`,
                    html: '<div id="legal-fsp-modal"></div>',
                    showConfirmButton: true,
                    confirmButtonText: this.$t("common.okButton"),
                    showCancelButton: !this.userData.fspTerms.required,
                    cancelButtonText: this.$t("ofex.urModal.omitir"),
                    allowOutsideClick: false, // !this.userData.fspTerms.required,  https://zenerab.atlassian.net/browse/UATOFEX-814
                    allowEscapeKey: !this.userData.fspTerms.required,
                    customClass: {
                        popup: "re-size",
                    },
                    onBeforeOpen: () => {
                        document.getElementById("legal-fsp-modal").appendChild(this.$refs.legalFsp.$el);
                    },
                    preConfirm: async () => {
                        let validator = this.$refs.legalFsp.$refs.legalUrModalFSP;
                        let result = await validator.validate();
                        let fspAccepted = this.$refs.legalFsp.$refs.legalSection.fspAceptado;
                        console.debug(`Modal aceptado (${fspAccepted}) y validación con resultado (${result})`);
                        return result === true
                            ? {
                                  accepted: fspAccepted,
                                  validated: result,
                              }
                            : false;
                    },
                }).then((result) => {
                    if (result.isConfirmed && result.value.accepted) {
                        this.hasAcceptedFsp = true;
                    }
                    this.checkAndShowConsentsModal();
                });
            } else {
                console.debug("No es necesario mostrar las condiciones FSP");
                this.checkAndShowConsentsModal();
            }
        },
        checkAndShowConsentsModal: function () {
            console.debug("Verificando si es necesario mostrar consentimientos");
            console.debug(`${this.clientData.advertising} ${this.clientData.surveys} ${this.clientData.profiling}`);
            if (this.showLegalCheckFlow) {
                console.debug("Se ha detectado al menos un consentimiento sin rellenar. Se muestran los 3");
                this.stepNumber = this.stepNumber + 1;
                this.showTermsFlow = true;
                Swal.fire({
                    title: `${this.$t("ofex.urModal.paso")} ${this.stepNumber} \n${this.$t("ofex.urModal.aceptarConsentimiento")}`,
                    html: '<div id="consents-modal"></div>',
                    showConfirmButton: false,
                    confirmButtonText: this.$t("common.saveButton"),
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: {
                        popup: "re-size",
                    },
                    onBeforeOpen: () => {
                        document.getElementById("consents-modal").appendChild(this.$refs.consents.$el);
                    },
                }).then(() => {
                    this.hasAcceptedConsents = true;
                    this.sendData();
                });
            } else {
                this.sendData();
            }
        },
        sendData: async function () {
            console.debug("Consentimientos aceptados");

            let localClientData = Object.assign({}, this.clientData);
            localClientData.advertising = this.$refs.consents.updateConsents.advertising;
            localClientData.surveys = this.$refs.consents.updateConsents.surveys;
            localClientData.profiling = this.$refs.consents.updateConsents.profiling;

            if (this.hasAcceptedLegal === true || this.hasAcceptedFsp === true) {
                await this.updateLegal();
            }
            if (this.hasAcceptedConsents === true) {
                let pdfGenerator = new ConsentsModifyPdfGenerator(this.$i18n, this.userId, this.clientId);

                pdfGenerator.generatePdf(this.userData, localClientData);
                await pdfGenerator.uploadPdf();

                await this.$store.dispatch(actionTypes.UPDATE_CLIENT, {
                    clientId: this.clientId,
                    userId: this.userId,
                    localClientData: localClientData,
                    pdf: pdfGenerator.uploadedDocumentId,
                    startLoading: true,
                    closeLoading: true,
                });

                await this.$store.dispatch(actionTypes.GET_PROFILE_ACTION, {
                    userId: this.userId,
                    clientId: this.clientId,
                });
            }
            this.$store.commit(mutationTypes.LEGAL_CHECK_STATUS, { status: "FINISHED" });
        },
        updateLegal: async function () {
            console.debug("Consentimientos legales aceptados y obteniendo url legales");

            let urls = await LiferayServiceClient.getLegalUrls(this.$i18n.locale);

            let result = await this.$store.dispatch(actionTypes.UPDATE_LEGAL, {
                userId: this.userId,
                clientId: this.clientId,
                legalUrl: this.hasAcceptedLegal ? urls.ur : "",
                fspUrl: this.hasAcceptedFsp ? urls.fsp : "",
                contentForm: this.computeLegalText(urls.ur, urls.fsp),
                userEmail: this.userData.email,
                locale: this.$i18n.locale,
            });

            console.debug(result);
        },
        consentsAcceptedHandler: function () {
            Swal.close(true);
        },
        computeLegalText: function (urUrl, fspUrl) {
            let text = "<html><body>";
            if (this.hasAcceptedLegal === true) {
                text += `${this.$i18n.t("ofex.pdfGenerator.changeOwnership.aceptoCondicionesUso", { url: urUrl })}: Sí <br>`;
            }
            if (this.hasAcceptedFsp === true) {
                text += this.$i18n.t("ofex.pdfGenerator.digitalInvoice.legalWithHref", { url: fspUrl });
            }
            text += "</body></html>";
            return text;
        },
    },
    mounted() {
        this.stepNumber = 0;
        this.$store.commit(mutationTypes.LEGAL_CHECK_STATUS, { status: "IN_PROGRESS" });
        this.checkAndShowLegalURModal();
    },
};
</script>

<style lang="scss">
.swal2-actions {
    z-index: 0 !important;
}
.re-size {
    width: 68% !important;
}
@media (max-width: 991px) {
    .re-size {
        width: 90% !important;
    }
}
@media (max-width: 991px) {
    .re-size {
        width: 90% !important;
    }
}
</style>
