<template>
    <b-container v-if="this.hasAnyActiveContract === true">
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.bajaContrato.title')" />
                <p class="text-selection">{{ $t("ofex.bajaContrato.contratoBaja") }}</p>
                <div class="unsuscribe-selector-contract">
                    <!-- Busqueda avanzada-->
                    <ab-contracts-advance-search v-model="filteredContracts" ref="contractsAdvanceSearch" :onlyActiveContracts="true" />
                    <!--Tabla de contratos-->
                    <vue-good-table
                        :columns="columns"
                        :rows="tableRows"
                        style-class="vgt-table"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            perPageDropdown: [10, 20, 30],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: this.$i18n.tc('table.siguiente'),
                            prevLabel: this.$i18n.tc('table.atras'),
                            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                            ofLabel: 'de',
                            allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                            label: 'selector-de-paginas',
                            name: 'perPageSelect',
                        }"
                    >
                        <template slot="table-row" slot-scope="props">
                            <div class="d-flex" v-if="props.column.field == 'contrato'">
                                <input
                                    type="radio"
                                    aria-label="Selección de contrato"
                                    :title="props.row.contrato"
                                    v-model="selectedContractNumber"
                                    :value="props.row.contrato"
                                    name="radio-contract"
                                />
                                <span class="contract ml-2" :class="{ pending: props.row.deuda }">{{ props.row.contrato }}</span>
                                <img
                                    v-if="props.row.telectura"
                                    class="icon-contrato"
                                    alt="imagen bola telelectura"
                                    src="../assets/images/bola-telelectura.png"
                                />
                            </div>
                            <div
                                class="col-alias-inner"
                                v-else-if="props.column.field == 'alias'"
                                @click="openEditAliasModal(props.row.contrato, props.row.alias)"
                            >
                                <span>{{ props.row.alias }}</span>
                            </div>
                            <div v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </div>
                        </template>
                    </vue-good-table>
                </div>
                <div class="mb-2">
                    <img class="icon-t" alt="imagen bola telelectura" src="../assets/images/bola-telelectura.png" />
                    <span class="ml-2 text-telelectura">{{ $t("ofex.home.telelectura") }}</span>
                </div>
                <div class="d-flex flex-row-reverse">
                    <div class="box-next">
                        <ab-button-ofex :click-event="navigateView" :text-button="$t('ofex.bajaContrato.siguiente')"></ab-button-ofex>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.bajaContrato.title')" />
                <ab-without-data class="mt-3" :text="$t('ofex.modificationContractSelector.noActiveContracts')" />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import AbContractsAdvanceSearch from "../modules/AbContractsAdvanceSearch";
import { mapGetters, mapState } from "vuex";
import * as actionTypes from "../store/action-types";
import * as viewMappings from "@/router/view-mapping-types";
export default {
    name: "UnsuscribeContractSelectorView",
    data() {
        return {
            filteredContracts: null,
            selectedContractNumber: "",
        };
    },
    components: {
        AbContractsAdvanceSearch,
        VueGoodTable,
    },
    methods: {
        mapContractToTableRow: function (contractEntry) {
            return {
                yearInvoice: contractEntry.contractDetail.invoiceYear,
                contrato: contractEntry.contractDetail.contractNumber,
                alias: contractEntry.contractDetail.alias ? contractEntry.contractDetail.alias : "",
                suministro: contractEntry.supplyAddress.streetType + " " + contractEntry.supplyAddress.streetName + " " + contractEntry.supplyAddress.number,
                municipio: contractEntry.supplyAddress.municipality,
                alta: contractEntry.contractDetail.registrationDate,
                deuda: contractEntry.contractDetail.invoicesPending,
                envio: this.$i18n.tc(`ofex.backendCodes.deliveryType.${contractEntry.contractDetail.invoiceDeliveryType}`),
                telectura: contractEntry.contractDetail.telerecording,
                favorito: contractEntry.contractDetail.favorite,
            };
        },
        navigateView: function () {
            this.$router.push({ name: viewMappings.UNSUSCRIBE_CONTRACT_VIEW_MAPPING.name, params: { contractId: this.selectedContractNumber } });
        },
    },
    computed: {
        ...mapState(["activeContracts", "isLoading", "clientData"]),
        ...mapGetters(["pendingInvoices", "hasAnyTelerecording", "hasAnyActiveContract"]),
        tableRows: function () {
            return this.filteredContracts !== null
                ? this.filteredContracts.map(this.mapContractToTableRow)
                : this.activeContracts.map(this.mapContractToTableRow);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contrato",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                    tdClass: "col-alias",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "suministro",
                    tdClass: "col-suministro",
                    thClass: "col-suministro",
                },

                {
                    label: this.$t("ofex.tuContrato.municipio"),
                    field: "municipio",
                    tdClass: "col-municipio",
                },
                {
                    label: this.$t("ofex.tuContrato.fechaAlta"),
                    field: "alta",
                    tdClass: "col-alta",
                },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_ACTIVE_CONTRACTS_ACTION, { locale: this.$i18n.locale });
    },
    beforeDestroy() {
        this.filteredContracts = null;
    },
};
</script>
<style lang="scss" scoped>
.text-selection {
    @include font-size(18);
    @include line-height(28);
    color: $link-secondary;
    font-weight: $font-primary-bold;
}
.icon-t {
    width: 16px;
    height: auto;
}
.icon-info {
    width: 17px;
    height: auto;
    margin-left: 7px;
}
.text-telelectura {
    @include font-size(12);
    @include line-height(14);
    color: $color-primary;
    font-weight: $font-primary-medium;
}
.box-next {
    @include respond-below(sm) {
        margin-top: 10px;
        width: 100%;
    }
}
</style>
<style lang="scss">
.unsuscribe-selector-contract {
    .m-advance-search {
        .multiselect__select {
            width: 28px;
            height: 32px;
        }
        .multiselect__input {
            margin-top: 5px;
        }
    }
}
</style>
