<template>
    <div class="container" ref="content">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <div class="row" v-if="contract && contractDetail">
            <ab-left-nav-bar />
            <div class="col-12 col-md-12 col-lg-9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.detalleContrato.title')"></ab-main-title>
                <div class="info-contract">
                    <ab-return></ab-return>
                    <div class="info-contract-inner">
                        <div class="contract-title">
                            {{ $t("ofex.detalleContrato.contrato") }}<span class="contract-number">{{ contract.contractDetail.contractNumber }}</span>
                            <img v-if="isFavoriteContract($route.params.contractId)" class="icon" alt="estrella" src="../assets/images/svg/favoritos.svg" />
                            <img v-else class="icon" alt="estrella" src="../assets/images/svg/favoritos-off.svg" />
                        </div>
                        <div v-if="contract.contractDetail.telerecording" class="remote-reading">{{ $t("ofex.home.telelectura") }}</div>
                    </div>
                    <div class="favorites">
                        <span>{{
                            isFavoriteContract($route.params.contractId) ? $t("ofex.detalleContrato.toggleActive") : $t("ofex.detalleContrato.toggleDisable")
                        }}</span>

                        <!--                        https://github.com/euvl/vue-js-toggle-button -->
                        <toggle-button
                            :height="20"
                            :width="40"
                            :value="isFavoriteContract($route.params.contractId)"
                            @change="editFavorite"
                            :sync="true"
                            title="favoritos"
                            aria-label="checkbox-favoritos"
                        />
                    </div>
                </div>
                <div class="contract-list-item" v-if="contractDetail && contractDetail.contractDetail">
                    <h2 class="title-detail">{{ $t("ofex.detalleContrato.sectionDetalleContrato") }}</h2>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.aliasContrato") }}
                        <!-- TODO arnaldo en espera de que nos dice Julià para el nombrar -->
                        <span class="element-black" v-text="contractDetail.contractDetail.alias == '' ? '' : contractDetail.contractDetail.alias"></span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.estadoSuministro") }}<span class="element-black">{{ supplyStatusTranslation() }}</span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.fechaAlta")
                        }}<span class="element-black" v-text="contractDetail.contractDetail.registrationDate">{{
                            contractDetail.contractDetail.registrationDate
                        }}</span>
                    </p>
                    <p class="element-blue" v-if="contractDetail.contractDetail.disabledDate != null">
                        {{ $t("ofex.detalleContrato.fechaBaja")
                        }}<span class="element-black" v-text="contractDetail.contractDetail.disabledDate">{{
                            contractDetail.contractDetail.registrationDate
                        }}</span>
                    </p>

                    <!-- ALTERNATIVAS PARA EL TIPO DE SUMINISTRO-->
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.tipoSuministro") }}<span class="element-black" v-html="supplyTypeTranslation()"></span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.direccionSuministro")
                        }}<span class="element-black"
                            >{{ streetTypeSupplyAddressCodeTranslation() }} {{ contractDetail.supplyAddress.streetName }},
                            {{ contractDetail.supplyAddress.number }}, {{ contractDetail.supplyAddress.otherAddressData }},
                            {{ contractDetail.supplyAddress.municipality }}
                        </span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.tipoUso") }}<span class="element-black">{{ useTypeCodeTranslation() }}</span>
                    </p>
                    <p class="element-blue" v-if="contractDetail.contractDetail.supplyTypeCode !== 'C.INCENDI'">
                        <span class="element-blue" v-html="returnSupplyTypeCode(contractDetail.contractDetail.supplyTypeCode)"></span
                        ><span class="element-black">{{ contractDetail.contractDetail.contractedWaterFlow }}</span>
                    </p>
                    <p class="element-blue" v-if="contractDetail.contractDetail.useTypeCode === 'DOMESTIC'">
                        {{ $t("ofex.detalleContrato.cedulaHabitabilidad")
                        }}<span
                            class="element-black"
                            v-text="
                                contractDetail.contractDetail.habitabilityCertificate == null || contractDetail.contractDetail.habitabilityCertificate == ''
                                    ? '-'
                                    : contractDetail.contractDetail.habitabilityCertificate
                            "
                        ></span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.tipoEnvioFactura")
                        }}<span class="element-black">
                            {{ $t("ofex.backendCodes.deliveryType." + contractDetail.contractDetail.invoiceDeliveryType) }}
                        </span>
                    </p>
                </div>
                <div class="contract-list-item">
                    <h3 class="title-detail border-blue">
                        {{ $t("ofex.detalleContrato.datosTitular") }}
                    </h3>
                    <div v-if="isCompany">
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.nombreApellidos") }}<span class="element-black"> {{ clientData.name }} </span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.tipoDocumento") }}<span class="element-black">{{ clientData.documentType }}</span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.nDocumento") }}<span class="element-black">{{ clientData.documentNumber }}</span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.tlfPrincipal") }}<span class="element-black">{{ clientData.mainPhone }}</span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.correoElectronico") }}<span class="element-black">{{ clientData.email }}</span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.fax") }}<span class="element-black">{{ clientData.fax }}</span>
                        </p>
                    </div>
                    <div v-else>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.nombreApellidos")
                            }}<span class="element-black"> {{ userData.firstName }} {{ userData.firstLastName }} {{ userData.secondLastName }} </span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.tipoDocumento") }}<span class="element-black">{{ userData.documentType }}</span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.nDocumento") }}<span class="element-black">{{ userData.documentNumber }}</span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.tlfPrincipal") }}<span class="element-black">{{ userData.mainPhone }}</span>
                        </p>
                        <p class="element-blue">
                            {{ $t("ofex.detalleContrato.correoElectronico") }}<span class="element-black">{{ userData.email }}</span>
                        </p>
                    </div>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.idioma")
                        }}<span
                            class="element-black"
                            v-text="userData.language == 'es' ? $t('ofex.detalleContrato.espanol') : $t('ofex.detalleContrato.catalan')"
                        ></span>
                    </p>
                </div>
                <div class="contract-list-item">
                    <h4 class="title-detail border-blue">{{ $t("ofex.detalleContrato.datosDirPostal") }}</h4>

                    <p
                        class="element-blue"
                        v-if="
                            contractDetail.mailHolderData.documentNumber === userData.documentNumber ||
                            contractDetail.mailHolderData.documentNumber === clientData.documentNumber
                        "
                    >
                        {{ $t("ofex.detalleContrato.nombreApellidos") }}<span class="element-black"> {{ contractDetail.mailHolderData.fullName }} </span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.datosCorrespondencia") }}
                        <!-- TODO arnaldo dirección de correspondencia no la localizo en el servicio-->
                        <span class="element-black">{{ streetTypeCodeTranslation() }} {{ contractDetail.mailAddress.otherAddressData }} </span>
                    </p>
                    <p
                        class="element-blue"
                        v-if="
                            contractDetail.mailHolderData.documentNumber === userData.documentNumber ||
                            contractDetail.mailHolderData.documentNumber === clientData.documentNumber
                        "
                    >
                        {{ $t("ofex.detalleContrato.tipoDocumento") }}<span class="element-black">{{ contractDetail.mailHolderData.documentType }}</span>
                    </p>
                    <p
                        class="element-blue"
                        v-if="
                            contractDetail.mailHolderData.documentNumber === userData.documentNumber ||
                            contractDetail.mailHolderData.documentNumber === clientData.documentNumber
                        "
                    >
                        {{ $t("ofex.detalleContrato.nDocumento") }}<span class="element-black">{{ contractDetail.mailHolderData.documentNumber }}</span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.idioma")
                        }}<span
                            class="element-black"
                            v-text="contractDetail.mailHolderData.language == 'ES' ? $t('ofex.detalleContrato.espanol') : $t('ofex.detalleContrato.catalan')"
                        ></span>
                    </p>
                </div>
                <div class="contract-list-item" v-if="contractDetail && contractDetail.paymentData">
                    <h5 class="title-detail border-blue">{{ $t("ofex.detalleContrato.responsablePago") }}</h5>
                    <p
                        class="element-blue"
                        v-if="
                            contractDetail.paymentData.documentNumber === userData.documentNumber ||
                            contractDetail.paymentData.documentNumber === clientData.documentNumber
                        "
                    >
                        {{ $t("ofex.detalleContrato.nombreApellidos")
                        }}<span class="element-black"
                            >{{ contractDetail.paymentData.firstName }} {{ contractDetail.paymentData.firstLastName }}
                            {{ contractDetail.paymentData.secondLastName }}</span
                        >
                    </p>
                    <p
                        class="element-blue"
                        v-if="
                            contractDetail.paymentData.documentNumber === userData.documentNumber ||
                            contractDetail.paymentData.documentNumber === clientData.documentNumber
                        "
                    >
                        {{ $t("ofex.detalleContrato.tipoDocumento") }}<span class="element-black">{{ contractDetail.paymentData.documentType }}</span>
                    </p>
                    <p
                        class="element-blue"
                        v-if="
                            contractDetail.paymentData.documentNumber === userData.documentNumber ||
                            contractDetail.paymentData.documentNumber === clientData.documentNumber
                        "
                    >
                        {{ $t("ofex.detalleContrato.nDocumento") }}<span class="element-black">{{ contractDetail.paymentData.documentNumber }}</span>
                    </p>
                    <p
                        class="element-blue"
                        v-if="
                            contractDetail.paymentData.documentNumber === userData.documentNumber ||
                            contractDetail.paymentData.documentNumber === clientData.documentNumber
                        "
                    >
                        {{ $t("ofex.detalleContrato.email") }}<span class="element-black">{{ contractDetail.paymentData.email }}</span>
                    </p>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.formaPago")
                        }}<span class="element-black">{{ $t("ofex.backendCodes.paymentMethod." + methodPaymentBackendCode()) }}</span>
                    </p>
                    <p
                        class="element-blue"
                        v-if="contractDetail.paymentData.paymentMethod === 'BANCARIA' || contractDetail.paymentData.paymentMethod === 'ENTITAT BANCARIA'"
                    >
                        {{ $t("ofex.detalleContrato.iban")
                        }}<span
                            ref="iban"
                            class="element-black"
                            v-if="contractDetail.paymentData.iban"
                            v-html="contractDetail.paymentData.country !== 'ES' ? changeAccountForeign() : changeIBAN()"
                        ></span>
                    </p>
                    <p class="element-blue" v-if="contractDetail.paymentData.iban && contractDetail.paymentData.country !== 'ES'">
                        {{ $t("ofex.detalleContrato.pais") }}<span class="element-black">{{ getCountryNameFromKey(contractDetail.paymentData.country) }}</span>
                    </p>
                    <p class="element-blue" v-if="contractDetail.paymentData.iban && contractDetail.paymentData.country !== 'ES'">
                        {{ $t("ofex.detalleContrato.bic") }}<span class="element-black">{{ contractDetail.paymentData.bic }}</span>
                    </p>
                </div>
                <div class="contract-list-item" v-if="contractDetail && contractDetail.billingData">
                    <h6 class="title-detail border-blue">{{ $t("ofex.detalleContrato.datosFacturacion") }}</h6>
                    <p class="element-blue">
                        {{ $t("ofex.detalleContrato.frecuenciaFacturacion")
                        }}<span class="element-black"> {{ $t("ofex.backendCodes.frequencyBilling." + contractDetail.billingData.billingFrequency) }}</span>
                    </p>

                    <div class="contract-list-item" v-if="useTypeCodeBackendCode() === 'COMERCIAL' || useTypeCodeBackendCode() === 'INDUSTRIAL'">
                        <p class="element-blue" v-if="contractDetail.contractDetail.supplyTypeCode !== 'C.INCENDI'">
                            {{ $t("ofex.detalleContrato.tipoIAE") }}<span class="element-black"> {{ contractDetail.billingData.iae.type }}</span>
                        </p>
                        <p class="element-blue" v-if="contractDetail.contractDetail.supplyTypeCode !== 'C.INCENDI'">
                            {{ $t("ofex.detalleContrato.numeroIAE") }}<span class="element-black"> {{ contractDetail.billingData.iae.number }}</span>
                        </p>
                        <p class="element-blue" v-if="contractDetail.contractDetail.supplyTypeCode !== 'C.INCENDI'">
                            {{ $t("ofex.detalleContrato.descripcionIAE") }}<span class="element-black"> {{ contractDetail.billingData.iae.description }}</span>
                        </p>
                    </div>

                    <div v-if="useTypeCodeBackendCode() === 'DOMESTIC'">
                        <p class="element-blue mb-0">
                            {{ $t("ofex.detalleContrato.nPersonas")
                            }}<span
                                class="element-black"
                                v-html="
                                    contractDetail.billingData.residentsSize
                                        ? contractDetail.billingData.residentsSize
                                        : $t('ofex.detalleContrato.noDisponible')
                                "
                            ></span>
                        </p>
                        <div class="box-facturacion">
                            <p class="text-facturacion">{{ $t("ofex.detalleContrato.textFacturacion") }}</p>
                        </div>
                    </div>
                </div>

                <div class="buttons-container">
                    <div class="box-buttons-bot">
                        <ab-button-ofex
                            :click-event="downloadPDF"
                            :font-awesome-icon="'download'"
                            :text-button="$t('ofex.detalleContrato.descargarPDF')"
                        ></ab-button-ofex>
                    </div>

                    <!--                    <div class="box-buttons-bot">
                        <ab-button-ofex
                            :click-event="navigateRouterContractModification"
                            :font-awesome-icon="'edit'"
                            :text-button="$t('ofex.detalleContrato.modificar')"
                        ></ab-button-ofex>
                    </div>-->
                    <div class="box-buttons-bot">
                        <ab-button-ofex
                            :click-event="navigateRouterYourInvoices"
                            :font-awesome-icon="'file-invoice'"
                            :text-button="$t('ofex.detalleContrato.verFacturas')"
                        ></ab-button-ofex>
                    </div>
                    <div class="box-buttons-bot" v-if="contract.contractDetail.invoicesPending">
                        <ab-button-ofex
                            :click-event="navigateRouterPaymentContracts"
                            :font-awesome-icon="'file-invoice-dollar'"
                            :text-button="$t('ofex.detalleContrato.pagarFacturas')"
                        ></ab-button-ofex>
                    </div>
                    <div class="box-buttons-bot" v-if="$isNativeApp() && contract.contractDetail.telerecording">
                        <ab-button-ofex
                            :click-event="navigateRouterConsumptions"
                            :font-awesome-icon="'file-invoice-dollar'"
                            :text-button="$t('ofex.contractLinks.consumoTelelecturaTtileNative')"
                        ></ab-button-ofex>
                    </div>
                </div>
                <ab-contract-links :contract="$route.params.contractId" :show-telerecording-button="contract.contractDetail.telerecording" v-if="$isWebApp()" />

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </div>
        </div>
    </div>
</template>
<script>
import AbYourInterest from "../modules/AbYourInterest.vue";
import AbLeftNavBar from "../modules/common/AbLeftNavBar.vue";
import AbContractLinks from "../modules/AbContractLinks";
import * as viewMappingTypes from "../router/view-mapping-types";
import { mapGetters, mapState } from "vuex";
import ContractDetailPdfGenerator from "../generators/pdf/ContractDetailPdfGenerator";
import * as actionTypes from "@/store/action-types";
import { trimBackendCode } from "@/plugins/ab-utils";
import AbReturn from "../components/AbReturn.vue";

export default {
    name: "ContractDetailView",
    components: {
        AbLeftNavBar,
        AbYourInterest,
        AbContractLinks,
        AbReturn,
    },
    data: function () {
        return {
            countryList: [],
            iban: "",
            typeVolume: "",
            booleanInvoices: null,
            items: [{ color: "#E8D2AE", value: false }],
        };
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "contracts", "contractDetail", "selectedContractNumber"]),
        ...mapGetters(["getContractByContractNumber", "isFavoriteContract", "isCompany", "clientId", "userId"]),
        contract: function () {
            return this.getContractByContractNumber(this.$route.params.contractId);
        },
    },
    created: function () {
        var vueContext = this;
        this.$http.get(`${process.env.VUE_APP_API_CARRERER_BASE_URL}/countries/all`).then(
            function (response) {
                vueContext["countryList"] = response.data;
            },
            function (error) {
                console.error(error);
            }
        );
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, triggerChangeActiveContractLoading: false });
        this.$store.dispatch(actionTypes.CHANGE_ACTIVE_CONTRACT_ACTION, { contractNumber: this.$route.params.contractId, locale: this.$root.$i18n.locale });
        this.$store.dispatch(actionTypes.GET_CONTRACT_DETAIL_ACTION, {
            contractNumber: this.$route.params.contractId,
        });
    },
    methods: {
        getCountryNameFromKey: function getCountryNameFromKey(key) {
            return this.countryList.filter(function (value) {
                return value.id === key;
            })[0].name;
        },
        downloadPDF: function () {
            let pdfGenerator = new ContractDetailPdfGenerator(this.$i18n, this.userId, this.clientId, this.selectedContractNumber);
            pdfGenerator.generatePdf(
                this.userData,
                this.clientData,
                this.contract,
                this.contractDetail,
                this.iban,
                this.isCompany,
                this.getCountryNameFromKey(this.contractDetail.paymentData.country)
            );
            pdfGenerator.downloadPdf();
        },
        editFavorite: function (e) {
            console.log("Editando favorito: " + e.value);
            this.$store
                .dispatch(actionTypes.UPDATE_FAVORITE, {
                    contractNumber: this.$route.params.contractId,
                    favorite: e.value,
                    locale: this.$i18n.locale,
                })
                .then((value) => {
                    console.debug("Terminando de actualizar favorito" + value);
                    this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
                });
        },
        updateItemValue(index) {
            this.items[index].value = !this.items[index].value;
        },
        supplyTypeTranslation: function () {
            return this.$t("ofex.backendCodes.supplyType." + this.supplyTypeBackendCode());
        },
        supplyStatusTranslation: function () {
            return this.$t("ofex.backendCodes.supplyStatus." + this.supplyStatusBackendCode());
        },
        useTypeCodeTranslation: function () {
            return this.$t("ofex.backendCodes.useType." + this.useTypeCodeBackendCode());
        },
        streetTypeCodeTranslation: function () {
            return this.$t("ofex.backendCodes.streetTypes." + trimBackendCode(this.streetTypeBackendCode()));
        },
        streetTypeSupplyAddressCodeTranslation: function () {
            return this.$t("ofex.backendCodes.streetTypes." + trimBackendCode(this.streetTypeSupplyAddressBackendCode()));
        },
        supplyTypeBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.supplyTypeCode);
        },
        supplyStatusBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.supplyStatus);
        },
        useTypeCodeBackendCode: function () {
            return trimBackendCode(this.contractDetail.contractDetail.useTypeCode);
        },
        methodPaymentBackendCode: function () {
            return trimBackendCode(this.contractDetail.paymentData.paymentMethod);
        },
        streetTypeSupplyAddressBackendCode: function () {
            return trimBackendCode(this.contractDetail.supplyAddress.streetType);
        },
        streetTypeBackendCode: function () {
            return trimBackendCode(this.contractDetail.mailAddress.streetType);
        },
        changeIBAN: function () {
            let iban = this.contractDetail.paymentData.iban;
            let firstSection = iban.substring(2, 4);
            let secondSection = iban.substring(21, 23);
            let thirdSection = iban.substring(35, 39);
            iban = iban.replace(firstSection, "**");
            iban = iban.replace(secondSection, "**");
            iban = iban.replace(thirdSection, "****");
            this.iban = iban;
            return this.iban;
        },
        //TODO refactorizar esta funcion
        changeAccountForeign: function () {
            let iban = this.contractDetail.paymentData.iban;
            let firstSection = iban.substring(21, 22);
            iban = iban.replace(firstSection, "**");
            this.iban = iban;
            return this.iban;
        },
        returnSupplyTypeCode: function (supplyTypeCode) {
            if (supplyTypeCode === "AFORA.DIV" || supplyTypeCode === "AFORA.GEN") {
                return this.$t("ofex.detalleContrato.dotacion");
            } else {
                return this.$t("ofex.detalleContrato.caudalContratado");
            }
        },
        navigateRouterYourInvoices: function () {
            this.$router.push({ name: viewMappingTypes.INVOICES_LIST_MAPPING.name, params: { contractId: this.contract.contractDetail.contractNumber } });
        },
        navigateRouterPaymentContracts: function () {
            this.$router.push({ name: viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.name });
        },
        navigateRouterConsumptions: function () {
            this.$router.push({ name: viewMappingTypes.CONSUMPTIONS_DETAIL_MAPPING.name, params: this.contract });
        },
    },
};
</script>

<style lang="scss" scoped>
.info-contract {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @include respond-above(sm) {
        margin-bottom: 20px;
    }
    .info-contract-inner {
        display: flex;
        flex-wrap: wrap;
        flex: 100%;
        order: 3;
        margin-top: 30px;
        @include respond-above(sm) {
            flex-wrap: nowrap;
            flex: 0 0 auto;
            order: 2;
            margin-top: 0;
        }
        .contract-title {
            color: $color-primary;
            @include font-size(18);
            @include line-height(21);
            font-weight: $font-primary-bold;

            @include respond-below(sm) {
                flex: 100%;
            }
            .contract-number {
                font-weight: $font-primary-medium;
                margin-left: 5px;
            }
        }
        .remote-reading {
            margin-top: 20px;
            margin-bottom: 0;
            @include respond-above(sm) {
                margin: 0 0 0 20px;
            }
        }
        .icon {
            margin-left: 7px;
        }
    }
    .favorites {
        display: flex;
        align-items: center;
        order: 2;
        align-self: center;
        @include font-size(12);
        @include line-height(14);
        color: $color-primary;
        font-weight: $font-primary-medium;
        @include respond-above(sm) {
            order: 3;
        }
        span {
            line-height: 24px;
            margin-right: 7px;
        }
        label {
            margin-bottom: 0;
        }
    }
}
.buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;

    @include respond-above(lg) {
        flex-wrap: nowrap;
    }
    .box-buttons-bot {
        flex: 100%;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        @include respond-above(sm) {
            flex: 0 0 48%;
        }
        @include respond-above(lg) {
            flex-wrap: nowrap;
            flex: 1 0 auto;
            margin-right: 25px;
            margin-bottom: 0;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.contract-list-item {
    margin-bottom: 30px;
}
//26/05/2020
.title-detail {
    @include font-size(18);
    @include line-height(21);
    font-weight: $font-primary-bold;
    border-bottom: 1px solid $blue-lighter;
    padding-bottom: 5px;
    margin-bottom: 15px;
}
.element-blue {
    @include font-size(14);
    @include line-height(21);
    color: $blue-lighter;
    font-weight: $font-primary-bold;
    margin-bottom: 10px;
}
.element-black {
    @include font-size(14);
    @include line-height(21);
    font-weight: $font-primary-medium;
    margin-left: 3px;
    color: $color-primary;
}
.text-facturacion {
    @include font-size(10);
    @include line-height(15);
    font-weight: $font-primary-medium;
    margin-bottom: 0;
}
.box-facturacion {
    width: 222px;
}
</style>
