<template>
    <ab-autocompletable-select
        v-model="modelValue"
        :name="name"
        :label="label"
        :input-alias="inputAlias"
        :placeholder="placeholder"
        :validation-scope="validationScope"
        :validation="validation"
        :data-list="companiesList"
        data-label="name"
    >
    </ab-autocompletable-select>
</template>

<script>
import AbAutocompletableSelect from "./AbAutocompletableSelect";

export default {
    components: {
        AbAutocompletableSelect,
    },
    model: {
        prop: "selectValue",
        event: "change",
    },
    props: {
        title: String,
        name: String,
        inputAlias: String,
        popover: String,
        label: String,
        placeholder: {
            default: "Seleccionar",
            type: String,
        },
        validation: Object,
        validationScope: String,
        apiBaseUrl: {
            required: false,
            default: function () {
                return catalogueApiBaseUrl;
            },
            type: String,
        },
    },
    data: function () {
        return {
            companiesList: [],
        };
    },
    computed: {
        scopedName: function () {
            return this.validationScope + "." + this.name;
        },
        modelValue: {
            get: function () {
                return this.$attrs["selectValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    created() {
        this.$validator = this.$parent.$validator;

        var vueContext = this;
        this.$http.get(`${this.apiBaseUrl}/miscellany/catalogue/maintenance-companies`).then(
            function (response) {
                vueContext["companiesList"] = response.body;
            },
            function (error) {
                console.error(error);
            }
        );
    },
};
</script>
<style lang="scss">
@import "../assets/styles/common.scss";
</style>
