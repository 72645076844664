var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('b-row',[_c('ab-left-nav-bar'),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"9"}},[_c('ab-loading',{attrs:{"opacity":0.85,"full-page":true,"loader-text":_vm.$t('ofex.loadingText.home')},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),_c('app-breadcrumbs'),_c('ab-main-title',{attrs:{"main-text":_vm.$t('ofex.menuLateral.facturasRectificativas')}}),(_vm.rectifyingInvoices && _vm.rectifyingInvoices.length > 0)?_c('div',[_c('ab-invoices-advance-search'),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.tableRows,"style-class":"vgt-table","pagination-options":{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        perPageDropdown: [10, 20, 30],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: this.$i18n.tc('table.siguiente'),
                        prevLabel: this.$i18n.tc('table.atras'),
                        rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                        ofLabel: 'de',
                        allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                        label: 'selector-de-paginas',
                        name: 'perPageSelect',
                    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'compensationStatus')?_c('div',{staticClass:"col-envio-inner"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(props.row.compensationStatus))]),_c('div',{staticClass:"buttons-group"},[_c('b-dropdown',{staticClass:"table-dropdown",attrs:{"id":props.row.fullInvoiceNumber,"dropleft":"","title":"Menu dropdown","size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":""}},[_c('b-dropdown-item-button',{attrs:{"aria-describedby":"desplegable","aria-label":"Descargar factura","title":"Menu dropdown"},on:{"click":function($event){return _vm.downloadInvoice(props.row.invoiceNumber, props.row.invoiceYear, props.row.contractNumber)}}},[_vm._v(_vm._s(_vm.$t("ofex.invoicesTable.downloadButtonText")))]),(props.row.compensationStatusCode !== 'COMPENSATED')?_c('a',{attrs:{"href":"/facturas-rectificativas","target":"_blank"}},[_c('b-dropdown-item-button',{attrs:{"aria-describedby":"dropdown-header-1","aria-label":"Menu dropdown","title":"Menu dropdown"}},[_vm._v(_vm._s(_vm.$t("common.reclamar")))])],1):_vm._e()],1)],1)]):_vm._e()]}}],null,false,1513378134)},[_c('div',{staticClass:"text-center textdnormal",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t("ofex.invoiceView.emptyResults"))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"table-buttons-container"},[_c('ab-button-ofex',{attrs:{"text-button":_vm.$t('ofex.invoicePaymentByContractView.listadoFacturasPDF'),"font-awesome-icon":'download',"click-event":_vm.generatePdfInvoiceList}}),_c('ab-button-ofex',{attrs:{"click-event":_vm.generateExcelContractList,"text-button":_vm.$t('ofex.invoicePaymentByContractView.listadoFacturasExcel'),"font-awesome-icon":'download'}})],1)],1):_c('ab-without-data',{attrs:{"text":_vm.$t('ofex.withoutData.rectifyingInvoices')}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }