export let CHANGE_I18N_LOCALE = "changeI18nLocale";
export let SET_LOGIN_ERROR_MSG_MUTATION = "setLoginErrorMsg";
export let CLEAR_LOGIN_ERROR_MSG_MUTATION = "clearLoginErrorMsg";
export let CLEAR_AUTH_DATA_MUTATION = "clearAuthData";
export const RECOVER_PERSISTED_AUTH_DATA_MUTATION = "recoverPersistedAuthData";
export const SET_REST_ERROR = "setRestError";
export const RESET_REST_CANCEL_TOKEN = "resetRestCancelToken";
export const SET_LOADING_MUTATION = "setLoading";
export const STORE_TOKEN_MUTATION = "storeToken";
export const STORE_USER_DATA_MUTATION = "storeUserData";
export const STORE_CLIENT_DATA_MUTATION = "storeClientData";
export const STORE_PAYMENT_DATA_MUTATION = "storePaymentData";
export const STORE_CONTRACTS_MUTATION = "storeContracts";
export const STORE_ACTIVE_CONTRACTS_MUTATION = "storeActiveContracts";
export const STORE_ALL_CONTRACTS_MUTATION = "storeAllContracts";
export const STORE_CONTRACT_DETAIL_MUTATION = "contractDetail";
export const STORE_FILTERED_INVOICES_MUTATION = "storeInvoicesTable";
export const CLEAR_INVOICES_FILTER_MUTATION = "clearInvoicesFilter";
export const CLEAR_NOTIFICATIONS_MUTATION = "clearNotifications";
export const STORE_INVOICES_MUTATION = "storeInvoices";
export const STORE_UNPAID_INVOICES_MUTATION = "storeUnpaidInvoices";
export const STORE_LAST_INVOICES_MUTATION = "storeLastInvoices";
export const STORE_NOTIFICATIONS_MUTATION = "storeNotifications";
export const STORE_UNREAD_NOTIFICATIONS_MUTATION = "storeUnreadNotifications";
export const STORE_CONFIG_NOTIFICATIONS_CATEGORY_MUTATION = "storeConfigNotificationsCategory";
export const STORE_CONFIG_NOTIFICATIONS_CONTRACTS_MUTATION = "storeConfigNotificationsContracts";
export const STORE_LOAD_NOTIFICATIONS = "storeLoadNotifications";
export const STORE_CONSUMPTIONS_BY_CONTRACTS = "storeConsumptionsByContract";
export const STORE_AUTHORIZATIONS_BY_USER = "storeAuthorizationByUser";
export const STORE_CONTRACT_AUTHORIZATIONS_BY_PERSON = "storeContractAuthorizationByPerson";
export const STORE_AUTHORIZATIONS_BY_CONTRACT = "storeAuthorizationByContract";
export const STORE_CONTRACT_CONSUMPTIONS_SUMMARY = "storeContractConsumptionsSummary";
export const CHANGE_ACTIVE_CONTRACT_MUTATION = "changeActiveContract";
export const CHANGE_ACTIVE_INVOICE_MUTATION = "changeActiveInvoice";
export const CHANGE_CONTINGENCIAS_STATUS_MUTATION = "changeContingeciasStatus";
export const CHANGE_CONTINGENCIAS_OK = "changeContingeciasOk";
export const ADD_FAVORITE_CONTRACT = "addFavoriteContract";
export const REMOVE_FAVORITE_CONTRACT = "removeFavoriteContract";
export const GENERIC_ERROR_MUTATION = "genericErrorMutation";
export const LEGAL_CHECK_STATUS = "legalCheckStatus";
export const STORE_RECTIFYING_INVOICES = "storeRectifyingInvoices";
export const STORE_DROUGHT_CONFIGURATION = "storeDroughtConfiguration";
