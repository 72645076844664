"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
const ACTIVITY_CODE = "UU";
export default class UnsubscribeUserPdfGeneator extends BaseGenerator {
    constructor(i18n, userId, clientId) {
        super("BajaUsuario.pdf", ACTIVITY_CODE, i18n, userId, clientId, "");
    }

    generatePdf(reason, isCompany, userData, clientData) {
        const POINTS = ": ";
        console.log(`Generando pdf de baja de usuario`);
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.unsubscribeUser.title"), this.i18n.tc("ofex.pdfGenerator.unsubscribeUser.subtitle"));
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.userDataModify.title"));
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.unsubscribeUser.login") +
                POINTS +
                (isCompany ? clientData.documentNumber + userData.documentNumber : userData.documentNumber)
        );
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.unsubscribeUser.motivo"));
        this.generateMultilineText(reason);

        return this.pdf;
    }
}
