<template>
    <div class="mt-3 mb-5">
        <!--  HOME CONTRACTS   -->
        <p class="main-info mb-5" v-html="headerText" />

        <div class="consumption-calcule">
            <div class="consumption-calcule__number-selector ml-5 mb-5">
                <ab-autocompletable-select
                    v-model="numberOfPersons"
                    name="consumption-number-selector"
                    input-alias="consumption-number-selector"
                    :data-list="persons"
                    data-key="key"
                    data-label="key"
                    :label="$t('ofex.consumptionCalculatedView.select-persons')"
                    placeholder="Seleccionar"
                >
                </ab-autocompletable-select>
            </div>
            <!--  HOME TELERECORDING CONTRACTS  -->
            <template v-if="selectedContractTelerecording && hasYesterdayConsumption">
                <div class="consumption-calcule__results">
                    <div>
                        <p>{{ $t("ofex.consumptionCalculatedView.last-seven-days") }}</p>
                        <p v-if="consumption7 || consumption7 === 0">{{ $formatNumber(consumption7, 0, 0) }}</p>
                        <p v-else>{{ loadingText }}</p>
                        <p>{{ $t("ofex.consumptionCalculatedView.litres") }}</p>
                    </div>
                    <div>
                        <p>{{ $t("ofex.consumptionCalculatedView.represents") }}</p>
                        <p v-if="consumption7 || consumption7 === 0">{{ $formatNumber(personalConsumption7, 0, 0) }}</p>
                        <p v-else>{{ loadingText }}</p>
                        <p>{{ $t("ofex.consumptionCalculatedView.litres-person-day") }}</p>
                    </div>
                    <div class="consumption-calcule__results__graph" v-if="isConsumeOk(personalConsumption7)">
                        <img src="@/assets/images/svg/Carita-contenta.svg" alt="indicador cualitativo de consumo positivo" />
                        <p>{{ $t("ofex.consumptionCalculatedView.below-consumption") }}</p>
                    </div>
                    <div class="consumption-calcule__results__graph" v-else>
                        <img src="@/assets/images/svg/Carita-triste.svg" alt="indicador cualitativo de consumo negativo" />
                        <p>{{ $t("ofex.consumptionCalculatedView.above-consumption") }}</p>
                    </div>
                </div>
                <div class="consumption-calcule__results">
                    <div>
                        <p>{{ $t("ofex.consumptionCalculatedView.last-thirty-days") }}</p>
                        <p v-if="consumption30 || consumption30 === 0">{{ $formatNumber(consumption30, 0, 0) }}</p>
                        <p v-else>{{ loadingText }}</p>
                        <p>{{ $t("ofex.consumptionCalculatedView.litres") }}</p>
                    </div>
                    <div>
                        <p>{{ $t("ofex.consumptionCalculatedView.represents") }}</p>
                        <p v-if="consumption30 || consumption30 === 0">{{ $formatNumber(personalConsumption30, 0, 0) }}</p>
                        <p v-else>{{ loadingText }}</p>
                        <p>{{ $t("ofex.consumptionCalculatedView.litres-person-day") }}</p>
                    </div>
                    <div class="consumption-calcule__results__graph" v-if="isConsumeOk(personalConsumption30)">
                        <img src="@/assets/images/svg/Carita-contenta.svg" alt="indicador cualitativo de consumo positivo" />
                        <p>{{ $t("ofex.consumptionCalculatedView.below-consumption") }}</p>
                    </div>
                    <div class="consumption-calcule__results__graph" v-else>
                        <img src="@/assets/images/svg/Carita-triste.svg" alt="indicador cualitativo de consumo negativo" />
                        <p>{{ $t("ofex.consumptionCalculatedView.above-consumption") }}</p>
                    </div>
                </div>
            </template>

            <div class="consumption-calcule__results">
                <div>
                    <p>{{ $t("ofex.consumptionCalculatedView.last-bill") }}</p>
                    <p v-if="noTelerecordingContractConsumption || noTelerecordingContractConsumption === 0">
                        {{ $formatNumber(noTelerecordingContractConsumption, 0, 0) }}
                    </p>
                    <p v-else>{{ loadingText }}</p>
                    <p v-if="!isMobile">{{ $t("ofex.consumptionCalculatedView.cubic-meters") }}</p>
                    <p v-else>m³</p>
                </div>
                <div>
                    <p>{{ $t("ofex.consumptionCalculatedView.represents") }}</p>
                    <p v-if="noTelerecordingContractConsumption || noTelerecordingContractConsumption === 0">
                        {{ $formatNumber(noTelerecordingPersonalConsumption, 0, 0) }}
                    </p>
                    <p v-else>{{ loadingText }}</p>
                    <p>{{ $t("ofex.consumptionCalculatedView.litres-person-day") }}</p>
                </div>
                <div class="consumption-calcule__results__graph" v-if="isConsumeOk(noTelerecordingPersonalConsumption)">
                    <img src="@/assets/images/svg/Carita-contenta.svg" alt="indicador cualitativo de consumo positivo" />
                    <p>{{ $t("ofex.consumptionCalculatedView.below-consumption") }}</p>
                </div>
                <div class="consumption-calcule__results__graph" v-else>
                    <img src="@/assets/images/svg/Carita-triste.svg" alt="indicador cualitativo de consumo negativo" />
                    <p>{{ $t("ofex.consumptionCalculatedView.above-consumption") }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment/moment";
import ConsumptionsServiceClient from "@/rest/consumptionsServiceClient";
import * as actionTypes from "@/store/action-types";

export default {
    name: "AbDomesticConsumption",
    data() {
        return {
            numberOfPersons: { key: 3 },
            isMobile: window.innerWidth < 768,
            persons: [],
            loadingText: this.$t("ofex.consumptionCalculatedView.loading"),
            defaultConsumptionByPerson: 90,
            consumption7: null,
            consumption7AmountOfDays: null,
            consumption30: null,
            consumption30AmountOfDays: null,
            hasYesterdayConsumption: false,
            selectPopover: this.$t("ofex.consumptionCalculatedView.residents"),
        };
    },
    computed: {
        ...mapState(["userInvoices", "contractDetail", "selectedContractNumber", "droughtConfiguration"]),
        ...mapGetters(["clientId", "userId", "selectedContractTelerecording"]),

        personalConsumption7() {
            return this.consumption7 / this.numberOfPersons.key / this.consumption7AmountOfDays;
        },
        personalConsumption30() {
            return this.consumption30 / this.numberOfPersons.key / this.consumption30AmountOfDays;
        },

        noTelerecordingContractConsumption() {
            return this.userInvoices[0].consumptionVolume;
        },
        noTelerecordingPersonalConsumption() {
            return (this.noTelerecordingContractConsumption * 1000) / this.numberOfPersons.key / this.userInvoices[0].consumptionDays;
        },
        headerText() {
            return this.$i18n.locale === "es"
                ? this.droughtConfiguration?.domesticConsumption.headerTextEs
                : this.droughtConfiguration?.domesticConsumption.headerTextCa;
        },
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth < 768;
        },
        calculateConsumption() {
            if (this.selectedContractTelerecording) {
                ConsumptionsServiceClient.getDailyConsumptions(
                    this.selectedContractNumber,
                    this.clientId,
                    this.userId,
                    this.$i18n.locale,
                    moment().startOf("day").subtract(7, "days").format("DD-MM-YYYY"),
                    moment().subtract(1, "day").format("DD-MM-YYYY"),
                    false
                )
                    .then((value) => {
                        this.consumption7 = value.data.reduce((acc, consumption) => acc + consumption.deltaConsumption, 0) * 1000;
                        this.consumption7AmountOfDays = value.data.length;

                        if (value.data && value.data.length > 0) {
                            let yesterday = moment().subtract(1, "day");
                            this.hasYesterdayConsumption = value.data.some((consumption) => moment(consumption.datetime).isSame(yesterday, "day"));
                        }
                    })
                    .catch((error) => {
                        console.log("Error:" + error);
                    });
                ConsumptionsServiceClient.getDailyConsumptions(
                    this.selectedContractNumber,
                    this.clientId,
                    this.userId,
                    this.$i18n.locale,
                    moment().startOf("day").subtract(30, "days").format("DD-MM-YYYY"),
                    moment().subtract(1, "day").format("DD-MM-YYYY"),
                    false
                )
                    .then((value) => {
                        this.consumption30 = value.data.reduce((acc, consumption) => acc + consumption.deltaConsumption, 0) * 1000;
                        this.consumption30AmountOfDays = value.data.length;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        isConsumeOk(consumption) {
            return consumption.toFixed(0) <= this.defaultConsumptionByPerson;
        },
    },
    watch: {
        contractDetail(newValue) {
            if (newValue !== null) {
                this.$http.get(`${process.env.VUE_APP_API_CARRERER_BASE_URL}/municipalities/province?province=BARCELONA&validate=false`).then((res) => {
                    const municipality = res.data.find((municipality) => this.contractDetail.supplyAddress.municipality === municipality.name);
                    if (municipality.defaultConsumptionByPerson) this.defaultConsumptionByPerson = municipality.defaultConsumptionByPerson;
                });
            }
        },
    },
    created() {
        this.calculateConsumption();

        for (let i = 1; i < 100; i++) {
            this.persons.push({ key: i });
        }

        /*Assign the number of residents predefined in the contract if exists, otherwise is 3 by default */
        this.$store
            .dispatch(actionTypes.GET_CONTRACT_DETAIL_ACTION, {
                contractNumber: this.selectedContractNumber,
            })
            .then(() => {
                if (this.contractDetail.billingData.residentsSize && this.contractDetail.billingData.residentsSize < 100) {
                    this.numberOfPersons = { key: parseInt(this.contractDetail.billingData.residentsSize) };
                }
            });
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
};
</script>

<style scoped lang="scss">
.consumption-calcule {
    &__number-selector {
        display: flex;
        width: 160px;
        place-items: center;

        p {
            margin-right: 20px;
            margin-bottom: 0;
        }
        #number-selector {
            margin: 0;
        }
        #number-input {
            margin: 0;
            width: 75px;
        }
    }

    &__results {
        display: grid;
        grid-template-columns: 25% 25% 40%;
        margin: 15px 0 40px 0;
        color: $blue-lightest;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        @include respond-below(md) {
            grid-template-columns: 25% 25% 50%;
        }

        @include respond-below(sm) {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        img {
            width: 100%;
            max-width: 60px;
        }

        p {
            padding-bottom: 0;
            max-width: 200px;
            text-align: center;
            margin-bottom: 0;

            @include respond-below(sm) {
                max-width: max-content;
                margin: 0 0 0 5px;
            }
            &:first-child {
                @include respond-below(sm) {
                    margin: 0;
                }
            }
        }
        p:last-child {
            font-weight: normal;
            margin-bottom: 0;
        }

        div {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            place-items: center;
            padding: 0;
            height: 100px;
            border-right: 1px solid $blue-lightest;

            @include respond-below(sm) {
                display: flex;
                height: auto;
                border: none;
            }
        }

        &__graph {
            @include respond-below(sm) {
                margin-left: 20px;
            }

            p {
                @include respond-below(sm) {
                    min-width: min-content;
                    margin-left: 20px;
                }
            }
        }

        div:last-child {
            display: grid;
            grid-template: 1fr / 1fr 1fr;
            margin: 0;
            border: none;

            p {
                text-align: center;
            }
        }
    }
}
</style>
