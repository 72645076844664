"use strict";

import BaseXlsxGenerator from "@/generators/xlsx/base/BaseXlsxGenerator";
import * as xlsx from "xlsx";
import { AB_DATETIME_FORMAT } from "@/plugins/ab-utils";
import moment from "moment";

export default class ConsumptionEvolutionListXlsxGenerator extends BaseXlsxGenerator {
    constructor(filename, consumptions, i18n, format, min, hour) {
        let rows = consumptions.map((i) => {
            return hour
                ? {
                      datetime: moment(i.datetime, AB_DATETIME_FORMAT, "es").format(format),
                      accumulatedConsumption: i.accumulatedConsumption,
                      deltaConsumption: min ? i.minFlow : i.deltaConsumption,
                  }
                : {
                      datetime: moment(i.datetime, AB_DATETIME_FORMAT, "es").format(format),
                      number: i.number,
                      accumulatedConsumption: i.accumulatedConsumption,
                      deltaConsumption: i.deltaConsumption,
                      exceesed: i.exceesed ? i18n.tc("common.yes") : i18n.tc("common.no"),
                      leaked: i.leaked ? i18n.tc("common.yes") : i18n.tc("common.no"),
                      minFlowTime: i.minFlowTime,
                      minFlow: i.minFlow,
                  };
        });

        let consumptionsSheet = xlsx.utils.json_to_sheet(rows);
        if (rows.length > 0) {
            if (hour) {
                consumptionsSheet["A1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.fecha");
                consumptionsSheet["B1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.lectura");
                consumptionsSheet["C1"]["v"] = min
                    ? i18n.tc("modules.ConsumptionEvolutionChart.consumoMin3")
                    : i18n.tc("modules.ConsumptionEvolutionChart.consumo3");
            } else {
                consumptionsSheet["A1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.fecha");
                consumptionsSheet["B1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.contador");
                consumptionsSheet["C1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.lectura");
                consumptionsSheet["D1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.consumo3");
                consumptionsSheet["E1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.exceso");
                consumptionsSheet["F1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.fuga");
                consumptionsSheet["G1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.timeMinFlow");
                consumptionsSheet["H1"]["v"] = i18n.tc("modules.ConsumptionEvolutionChart.consumoMin3");
            }
        }
        super(filename, consumptionsSheet);
    }
}
