import qs from "qs";
import store from "@/store/store";
import { SET_REST_ERROR } from "@/store/mutation-types";
import * as mutationTypes from "@/store/mutation-types";
import BaseOfexServiceClient from "./BaseOfexServiceClient";

const CONTENT_MANAGER_BASE_URL = process.env.VUE_APP_API_CONTENT_MANAGER_BASE_URL;

export default {
    commitStorageDocument: async function (storageFileId, lang = "es", subscription = process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_HEADER) {
        try {
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(`${CONTENT_MANAGER_BASE_URL}/content-manager/files/${storageFileId}`, null, {
                params: {
                    lang,
                    userId: store.getters.isAuthenticated ? store.getters.userId : null,
                    clientId: store.getters.isAuthenticated ? store.getters.clientId : null,
                },
                headers: {
                    "Ocp-Apim-Subscription-Key": subscription,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response.status !== 200 && response.status !== 204) {
                throw response.statusText;
            }
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    commitStorageDocumentsByActivity: async function (activityId, lang = "es", subscription = process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_HEADER) {
        try {
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.post(`${CONTENT_MANAGER_BASE_URL}/content-manager/files/activities/${activityId}`, null, {
                params: {
                    lang,
                    userId: store.getters.isAuthenticated ? store.getters.userId : null,
                    clientId: store.getters.isAuthenticated ? store.getters.clientId : null,
                },
                headers: {
                    "Ocp-Apim-Subscription-Key": subscription,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response.status !== 200 && response.status !== 204) {
                throw response.statusText;
            }
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    deleteFileFromStorage: async function (storageFileId, subscription = process.env.VUE_APP_APIM_SUBSCRIPTION_KEY_HEADER) {
        try {
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.delete(`${CONTENT_MANAGER_BASE_URL}/content-manager/files/${storageFileId}`, {
                params: {
                    userId: store.getters.isAuthenticated ? store.getters.userId : null,
                    clientId: store.getters.isAuthenticated ? store.getters.clientId : null,
                },
                headers: {
                    "Ocp-Apim-Subscription-Key": subscription,
                },
            });
            if (response.status !== 200 && response.status !== 204) {
                throw response.statusText;
            }
            console.log("Borrado correctamente");
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
};
