<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="carrerer-container">
        <div v-if="showProvince" class="row">
            <div class="col-12 col-lg-4">
                <ab-province-select
                    v-model="provinceModel"
                    :name="carrererName + '-province'"
                    :input-alias="$t('carrerer.alias.provincia')"
                    :placeholder="$t('carrerer.placeholders.provincia')"
                    :label="$t('carrerer.labels.provincia')"
                    :validation="{ required: true }"
                    :api-domain="apiDomain"
                >
                </ab-province-select>
            </div>
        </div>

        <div :class="showProvince ? 'row mt-5' : 'row'">
            <div class="col-12 col-lg-4">
                <ab-autocompletable-select
                    v-model="municipalityModel"
                    :name="carrererName + '-municipality'"
                    :input-alias="$t('carrerer.alias.municipio')"
                    placeholder="Seleccionar"
                    class="widthCarrererSelect"
                    :validation="{ required: true }"
                    :data-list="municipalityDataSource"
                    data-key="name"
                    data-label="name"
                    :label="$t('carrerer.labels.municipio')"
                    @change="onMunicipalityChangeRecalculateDatasources"
                >
                </ab-autocompletable-select>
            </div>

            <div class="col-12 col-lg-3">
                <ab-autocompletable-select
                    v-model="streetTypeModel"
                    :name="carrererName + '-street-type'"
                    :input-alias="$t('carrerer.alias.tipoVia')"
                    placeholder="Seleccionar"
                    class="widthCarrererSelect"
                    :validation="{ required: true }"
                    :data-list="streetTypeDataSource"
                    data-key="streetType"
                    :data-label="$i18n.locale === 'ca' ? 'streetTypeOfexCAT' : 'streetTypeOfexESN'"
                    :label="$t('carrerer.labels.tipoVia')"
                    @change="onStreetTypeChangeRecalculateDatasources"
                >
                </ab-autocompletable-select>
            </div>

            <div v-if="mustAutocomplete" class="col-12 col-lg-5">
                <ab-autocompletable-input
                    v-model="streetNameModel"
                    :name="carrererName + '-street-name'"
                    :input-alias="$t('carrerer.alias.nombreVia')"
                    class="widthNameVia"
                    :validation="{
                        required: true,
                        carrerer: { municipality: municipalityModel.name, streetType: streetTypeModel.streetType, apiDomain: apiDomain },
                    }"
                    :data-list="streetDataSource"
                    :min-chars="autocompletableMinChars"
                    :auto-first="true"
                    :data-control="carrererDataControl"
                    :selected-filter="selectedFilter"
                    :label="$t('carrerer.labels.nombreVia')"
                    @autocompletableSelected="handleStreetSelection"
                >
                </ab-autocompletable-input>
            </div>

            <div v-if="!mustAutocomplete" class="col-12 col-lg-5">
                <ab-input
                    v-model="streetNameModel"
                    :name="carrererName + '-street-name'"
                    :input-alias="$t('carrerer.alias.nombreVia')"
                    :placeholder="$t('carrerer.placeholders.nombreVia')"
                    :validation="{ required: true }"
                    :label="$t('carrerer.labels.nombreVia')"
                >
                </ab-input>
            </div>
        </div>

        <div class="row mb-5 mt-5">
            <div class="col-12 col-lg-2">
                <ab-input
                    v-model="numberModel"
                    :name="carrererName + '-number'"
                    :input-alias="$t('carrerer.alias.numero')"
                    :placeholder="$t('carrerer.placeholders.numeroVia')"
                    :validation="{ required: true }"
                    :label="$t('carrerer.labels.numeroVia')"
                >
                </ab-input>
            </div>
            <div class="col-12 col-lg-2">
                <ab-input
                    v-model="pisoModel"
                    :name="carrererName + '-piso'"
                    :input-alias="$t('carrerer.alias.piso')"
                    :placeholder="$t('carrerer.placeholders.piso')"
                    :validation="{ max: 10 }"
                    :label="$t('carrerer.labels.piso')"
                >
                </ab-input>
            </div>
            <div class="col-12 col-lg-2">
                <ab-input
                    v-model="puertaModel"
                    :name="carrererName + '-puerta'"
                    :input-alias="$t('carrerer.alias.puerta')"
                    :placeholder="$t('carrerer.placeholders.puerta')"
                    :validation="{ max: 20 }"
                    :label="$t('carrerer.labels.puerta')"
                >
                </ab-input>
            </div>
            <div class="col-12 col-lg-2">
                <ab-input
                    v-model="escaleraModel"
                    :name="carrererName + '-escalera'"
                    :input-alias="$t('carrerer.alias.escalera')"
                    :placeholder="$t('carrerer.placeholders.escalera')"
                    :label="$t('carrerer.labels.escalera')"
                >
                </ab-input>
            </div>

            <div class="col-12 col-lg-3">
                <ab-postal-code-select
                    v-model="cpModel"
                    :city="municipalityModel.name"
                    :api-domain="apiDomain"
                    :name="carrererName + '-cp'"
                    :input-alias="$t('carrerer.alias.cp')"
                >
                </ab-postal-code-select>
            </div>
        </div>

        <!--region accesibilidad-->
        <div id="carrererInfo" role="region" aria-live="polite" class="d-none">
            <h2 :id="'carrererInfoTitle' + carrererName">Dirección introducida</h2>
            <p :id="'provinceInfoTitle' + carrererName">Provincia: {{ carrerer.province }}</p>
            <p :id="'municipalityInfoTitle' + carrererName">Municipio: {{ carrerer.municipality.name }}</p>
            <p :id="'addressInfoTitle' + carrererName">
                Dirección:: {{ carrerer.streetType.streetTypeOfexESN }} {{ carrerer.streetName }} {{ carrerer.number }}
            </p>
        </div>
    </div>
</template>

<script>
// import cloneDeep from 'lodash/cloneDeep'

import AbAutocompletableInput from "./AbAutocompletableInput.vue";
import AbAutocompletableSelect from "./AbAutocompletableSelect.vue";
import AbProvinceSelect from "./AbProvinceSelect.vue";
import AbInput from "./AbInput.vue";
import AbPostalCodeSelect from "./AbPostalCodeSelect.vue";

export default {
    components: {
        AbAutocompletableInput,
        AbAutocompletableSelect,
        AbProvinceSelect,
        AbInput,
        AbPostalCodeSelect,
    },
    model: {
        prop: "carrererModel",
        event: "carrerer-changed",
    },
    props: {
        validation: Object,
        carrererName: String,
        showProvince: {
            default: true,
            type: Boolean,
        },
        autocompletableMinChars: {
            default: 1,
            type: Number,
        },
        apiDomain: {
            required: false,
            default: "",
            type: String,
        },
    },
    data: function () {
        return {
            selectedFilter: function (dataList, val, label) {
                if (!dataList) console.warn("Datalist is not valid!!");

                return dataList.filter(function (item) {
                    return item.streetName === val && item.municipality === label.substring(label.lastIndexOf(", ") + 2) && label.includes(item.streetType);
                })[0];
            },
            // eslint-disable-next-line no-unused-vars
            carrererDataControl: function (item, input) {
                return {
                    label: item.streetName + " (" + item.streetType + "), " + item.municipality,
                    value: item.streetName,
                };
            },
            carrerer: {
                municipality: [],
                province: { id: "08", name: "BARCELONA" },
                sapCode: "",
                streetName: "",
                streetType: [],
                number: "",
                piso: "",
                puerta: "",
                escalera: "",
                cp: "",
            },
            streetDataSource: [],
            streetTypeDataSource: [],
            municipalityDataSource: [],
        };
    },
    computed: {
        /*modelValue: {
                get: function () {
                    return this.$attrs["carrererModel"];
                },
                set: function (newValue) {
                    this.$emit('carrerer-changed', newValue)
                }
            },*/

        provinceModel: {
            get: function () {
                return this.$attrs["carrererModel"].province;
            },
            set: function (newValue) {
                this.carrerer.province = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        municipalityModel: {
            get: function () {
                return this.$attrs["carrererModel"].municipality;
            },
            set: function (newValue) {
                this.carrerer.municipality = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        streetTypeModel: {
            get: function () {
                return this.$attrs["carrererModel"].streetType;
            },
            set: function (newValue) {
                this.carrerer.streetType = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        streetNameModel: {
            get: function () {
                return this.$attrs["carrererModel"].streetName;
            },
            set: function (newValue) {
                this.carrerer.streetName = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        numberModel: {
            get: function () {
                return this.$attrs["carrererModel"].number;
            },
            set: function (newValue) {
                this.carrerer.number = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        pisoModel: {
            get: function () {
                return this.$attrs["carrererModel"].piso;
            },
            set: function (newValue) {
                this.carrerer.piso = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        puertaModel: {
            get: function () {
                return this.$attrs["carrererModel"].puerta;
            },
            set: function (newValue) {
                this.carrerer.puerta = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        escaleraModel: {
            get: function () {
                return this.$attrs["carrererModel"].escalera;
            },
            set: function (newValue) {
                this.carrerer.escalera = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        cpModel: {
            get: function () {
                return this.$attrs["carrererModel"].cp;
            },
            set: function (newValue) {
                this.carrerer.cp = newValue;
                this.$emit("carrerer-changed", this.carrerer);
            },
        },
        mustAutocomplete: function () {
            return this.provinceModel.name === "BARCELONA" && this.municipalityModel.validate;
        },
    },
    watch: {
        "carrerer.province": function (province) {
            console.debug("Provincia cambiada: " + province.name);

            this.municipalityModel = [];
            this.streetTypeModel = [];
            this.streetNameModel = "";
            this.numberModel = "";

            this.calculateDatasource(this.getMunicipalitiesRestURL(province.name), this, "municipalityDataSource");
            this.calculateDatasource(`${this.apiDomain}/streettypes/all`, this, "streetTypeDataSource");
        },
    },
    created() {
        this.$emit("carrerer-changed", this.carrerer);
        this.calculateDatasource(`${this.apiDomain}/streets/all`, this, "streetDataSource", { allMunicipality: this.showProvince });
        this.calculateDatasource(this.getMunicipalitiesRestURL(this.carrerer.province.name), this, "municipalityDataSource");
        this.calculateDatasource(`${this.apiDomain}/streettypes/all`, this, "streetTypeDataSource");

        console.debug("CARRERER CREATED");
    },
    mounted() {
        console.debug("CARRERER MOUNTED");
    },
    methods: {
        handleStreetSelection: function (streetSelection) {
            this.municipalityModel = this.calculateMunicipalityDetail(streetSelection.municipality);
            this.streetTypeModel = this.calculateStreetTypeDetail(streetSelection.streetType);
            this.streetNameModel = streetSelection.streetName;
            this.carrerer.sapCode = streetSelection.sapCode;
            // this.$emit('carrerer-changed', this.carrerer)
        },
        calculateDatasource: function (url, vueContext, targetName, params, callback) {
            console.debug("Filling carrerer street data source");
            this.$http.get(url, { params: params }).then(
                function (response) {
                    console.debug("OK calling to -> " + url);
                    vueContext[targetName] = response.data;
                    if (callback) callback.call();
                },
                function (error) {
                    console.debug("ERROR calling to -> /streets/all");
                    console.error(error);
                }
            );
        },
        calculateMunicipalityDetail: function (municipalityName) {
            return this.municipalityDataSource.find(function (item) {
                return item.name === municipalityName;
            });
        },
        calculateStreetTypeDetail: function (streetTypeName) {
            return this.streetTypeDataSource.find(function (item) {
                return item.streetType === streetTypeName;
            });
        },
        onMunicipalityChangeRecalculateDatasources: function () {
            console.debug("Recalculando tipos de vias");
            this.calculateDatasource(`${this.apiDomain}/streettypes/all`, this, "streetTypeDataSource", {
                municipality: this.carrerer.municipality.name,
            });
            this.streetNameModel = "";
            this.recalculateStreetDatasource();
        },
        onStreetTypeChangeRecalculateDatasources: function () {
            this.recalculateStreetDatasource();
        },
        recalculateStreetDatasource: function () {
            console.debug(
                "Filtering street datasource by municipality: " + this.municipalityModel.name + " and street type " + this.streetTypeModel.streetType
            );
            this.calculateDatasource(`${this.apiDomain}/streets/filter`, this, "streetDataSource", {
                municipality: this.municipalityModel.name,
                type: this.streetTypeModel.streetType,
            });
        },
        getMunicipalitiesRestURL: function (provinceName) {
            return `${this.apiDomain}/municipalities/province?province=${provinceName}&validate=${this.showProvince ? "false" : "true"}`;
        },
    },
};
</script>
<style lang="scss" scoped>
/*
@media (max-width: 991px) {
    .widthCarrererSelect {
        width: 90% !important;
    }

    .widthNameVia {
        width: 96% !important;
    }
}
*/
</style>
<style lang="scss">
.carrerer-container {
    .multiselect__placeholder {
        margin-top: 1px;
        @include font-size(14);
        font-weight: $font-primary-medium;
        @include respond-below(sm) {
            margin-top: 3px;
        }
    }
    .multiselect__single {
        margin-top: 0px;
        @include font-size(14);
        font-weight: $font-primary-medium;
    }
}
</style>
