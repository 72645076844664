var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-select-contract"},[_c('div',{staticClass:"mt-2 pt-4"},[_c('p',{staticClass:"subtitle-collapse"},[_vm._v(_vm._s(_vm.$t("ofex.modificarDatosContrato.seleccionarContratos")))]),_c('ab-contracts-advance-search',{ref:"advanceSearch",attrs:{"extra-filter-predicate":_vm.contractByModificationTypePredicate,"onlyActiveContracts":true},model:{value:(_vm.filteredContracts),callback:function ($$v) {_vm.filteredContracts=$$v},expression:"filteredContracts"}}),_c('vue-good-table',{attrs:{"columns":_vm.paymentColumns,"rows":_vm.tableRows,"style-class":"vgt-table","pagination-options":{
                enabled: true,
                mode: 'records',
                perPage: 6,
                perPageDropdown: [6, 20, 30],
                dropdownAllowAll: false,
                setCurrentPage: 1,
                nextLabel: this.$i18n.tc('table.siguiente'),
                prevLabel: this.$i18n.tc('table.atras'),
                rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                ofLabel: 'de',
                allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                label: 'selector-de-paginas',
                name: 'perPageSelect',
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'contrato')?_c('div',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelValue),expression:"modelValue"}],staticClass:"mr-2 align-input",attrs:{"type":"radio","aria-label":"Selección de contrato","title":props.row.contrato,"name":"radio-contract"},domProps:{"value":props.row.contrato,"checked":_vm._q(_vm.modelValue,props.row.contrato)},on:{"change":function($event){_vm.modelValue=props.row.contrato}}}),_c('span',{staticClass:"contract"},[_vm._v(_vm._s(props.row.contrato))]),(props.row.telectura)?_c('img',{staticClass:"icon-contrato",attrs:{"alt":"bola telelectura","src":require("../../../assets/images/bola-telelectura.png")}}):_vm._e()]):(props.column.field == 'pago')?_c('div',[_c('span',{staticClass:"contract"},[_vm._v(_vm._s(props.row.pago))])]):(props.column.field == 'iban')?_c('div',[_c('span',{staticClass:"contract"},[_vm._v(_vm._s(props.row.iban))])]):_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp:20",value:(2),expression:"2",arg:"20"}]},[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{staticClass:"text-center textdnormal",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t("ofex.tuContrato.emptyResults"))+" ")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }