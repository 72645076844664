"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
const ACTIVITY_CODE = "FS";
export default class InvoiceFspPdfGeneator extends BaseGenerator {
    constructor(i18n, userId, clientId, contract) {
        super("ModificaciónFacturasDigitales.pdf", ACTIVITY_CODE, i18n, userId, clientId, contract);
    }

    generatePdf(contractNumbers, fspUrl, operation) {
        const SPACE = " ";
        console.log(`Generando pdf de ${operation ? "Alta " : "Baja"} Facturas digitales`);
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(
            `${operation ? this.i18n.tc("ofex.pdfGenerator.digitalInvoice.titleEnabled") : this.i18n.tc("ofex.pdfGenerator.digitalInvoice.titleDisabled")}`,
            ""
        );
        contractNumbers.forEach((i) => this.generateLineText(this.i18n.tc("ofex.pdfGenerator.digitalInvoice.contract") + SPACE + `${i}`));
        if (operation) {
            this.generateLineTextWithLink(this.i18n.tc("ofex.pdfGenerator.digitalInvoice.legal"), fspUrl);
        }
        return this.pdf;
    }
}
