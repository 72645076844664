<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="row">
        <div class="col-12">
            <div class="float-left">
                <label :for="name" class="letraform-new mt-1 ptx">{{ label }}</label>
            </div>

            <div class="float-left">
                <p :id="name" class="fontNormal mt-1 ml-2">{{ valor }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        valor: String,
        name: String,
        label: String,
        datosUsuario: [
            {
                type: Array,
                required: true,
            },
        ],
    },
};
</script>
<style>
.ptx {
    padding-top: 2px;
}
</style>
