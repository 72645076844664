import { render, staticRenderFns } from "./AbLegalCheckImage.vue?vue&type=template&id=bac564a8&scoped=true"
import script from "./AbLegalCheckImage.vue?vue&type=script&lang=js"
export * from "./AbLegalCheckImage.vue?vue&type=script&lang=js"
import style0 from "./AbLegalCheckImage.vue?vue&type=style&index=0&id=bac564a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bac564a8",
  null
  
)

/* custom blocks */
import block0 from "./common/i18n-components.i18n?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Farnaldo%2FProyectosOficiales%2FZener%2FAguasDeBarcelona%2Fprojects%2Fab-ofex-components%2Fsrc%2Fcomponents%2FAbLegalCheckImage.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports