<template>
    <b-container class="p-contract-view">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar ref="navBar" />
        </b-row>
    </b-container>
</template>

<script>
import * as actionTypes from "@/store/action-types";
import { mapState } from "vuex";

export default {
    name: "ModifyPaymentRedirectionView",
    computed: {
        ...mapState(["selectedContractNumber", "isLoading"]),
    },
    watch: {
        selectedContractNumber: function (val) {
            console.debug("Contrato seleccionado " + val);
            this.$router.push(this.$refs.navBar.$refs.navBarRenderless.navigatePaymentData());
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale }).then(() => {
            console.debug("Contratos cargados");
        });
    },
    beforeDestroy() {},
};
</script>
