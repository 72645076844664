export const CANCEL_ALL_PENDING_REST_OPERATIONS = "cancelAllPendingRestOperations";
export const CHANGE_ACTIVE_CONTRACT_ACTION = "changeActiveContract";
export const DELETE_CONTRACT = "deleteContract";
export const DELETE_USER = "deleteUser";
export const DOWNLOAD_INVOICE_ACTION = "downloadInvoice";
export const FILTER_USER_INVOICES_ACTIONS = "filterUserInvoices";
export const GET_RECTIFYING_INVOICES = "getRectifyingInvoices";
export const GET_LAST_INVOICES_ACTION = "getLastInvoices";
export const GET_ALL_CONTRACTS_ACTION = "getAllContracts"; //contratos asignados y no asignados
export const GET_ACTIVE_CONTRACTS_ACTION = "getActiveContracts";
export const GET_CONFIG_NOTIFICATIONS_ACTION = "getConfigUserNotifications";
export const GET_CONTRACT_DETAIL_ACTION = "getContractDetail";
export const GET_PROFILE_ACTION = "getProfile";
export const GET_UNREAD_NOTIFICATIONS_ACTION = "getUnreadUserNotifications";
export const GET_USER_CONTRACTS_ACTION = "getUserContracts";
export const GET_USER_HISTORY_ACTION = "getUserActionsHistory";
export const GET_USER_NOTIFICATIONS_ACTION = "getUserNotifications";
export const GET_VALIDATE_TOKEN = "getValidateToken";
export const GET_CONTRACT_CONSUMPTIONS_SUMMARY = "getContractConsumptionsSummary";
export const GET_AUTHORIZATIONS_BY_USER_ACTION = "getAuthorizationsByUser";
export const GET_AUTHORIZATIONS_BY_CONTRACT_ACTION = "getAuthorizationsByContract";
export const GET_CONTRACT_AUTHORIZATIONS_BY_PERSON_ACTION = "getContractAuthorizationByPerson";
export const DELETE_AUTHORIZATIONS_ACTION = "deleteAuthorizations";
export const DELETE_UNIQUE_CONTRACT_AUTHORIZATION_ACTION = "deleteUniqueContractAuthorization";
export const CREATE_NEW_AUTHORIZATION_ACTION = "createNewAuthorization";
export const LOGIN_ACTION = "login";
export const LOGIN_PASSWORDLESS_ACTION = "loginPasswordless";
export const LOGIN_CERTIFICATE_ACTION = "loginCertificate";
export const LOGOUT_ACTION = "logout";
export const RESET_USER_CREDENTIALS = "resetUserCredentials";
export const SEND_LEGAL_FLOW_INFO = "sendLegalFlowInfo";
export const UPDATE_ALIAS = "updateAlias";
export const UPDATE_CE = "updateCE";
export const UPDATE_CLIENT = "updateClient";
export const UPDATE_LEGAL = "updateLegal";
export const UPDATE_CONFIG_NOTIFICATIONS_ACTION = "updateConfigUserNotifications";
export const UPDATE_CONTRACTS_ASSIGNED = "updateContractsAssigned";
export const UPDATE_FAVORITE = "updateFavorite";
export const UPDATE_FSP = "updateFsp";
export const UPDATE_IAE = "updateIAE";
export const UPDATE_MAIL_ADDRESS = "updateMailAddress";
export const UPDATE_PAYMENT_INFO = "updatePaymentInfo";
export const UPDATE_USER = "updateUser";
export const UPDATE_USER_CREDENTIALS = "updateUserCredentials";
export const UPDATE_PARTIAL_AUTHORIZATIONS = "updatePartialAuthorizations";
export const GET_DROUGHT_CONFIGURATION_ACTION = "getDroughtConfiguration";
