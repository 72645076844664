<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container class="p-last-reading-detail-view">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <ab-edit-alias-modal ref="editAliasModal" />
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9" v-if="contracts && contractConsumptionsSummary">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.lastReading.title')" />
                <p class="subtitle">{{ $t("ofex.lastReading.subtitle") }}</p>
                <!-- Advanced search -->
                <ab-contracts-advance-search
                    v-model="filteredContracts"
                    :extra-filter-predicate="intoConsumptionContractsPredicate"
                    :show-telerecording-filter="false"
                />
                <vue-good-table
                    :columns="columns"
                    :rows="tableRows"
                    style-class="vgt-table"
                    :pagination-options="{
                        enabled: true,
                        mode: 'records',
                        perPage: 10,
                        perPageDropdown: [10, 20, 30],
                        dropdownAllowAll: false,
                        setCurrentPage: 1,
                        nextLabel: this.$i18n.tc('table.siguiente'),
                        prevLabel: this.$i18n.tc('table.atras'),
                        rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                        ofLabel: 'de',
                        allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                        label: 'selector-de-paginas',
                        name: 'perPageSelect',
                    }"
                >
                    <template slot="table-row" slot-scope="props">
                        <div v-if="props.column.field === 'contrato'">
                            <span class="contract" :class="{ pending: props.row.deuda }">{{ props.row.contrato }}</span>
                        </div>
                        <div
                            class="col-alias-inner"
                            v-else-if="props.column.field === 'alias'"
                            @click="openEditAliasModal(props.row.contrato, props.row.alias)"
                        >
                            <span>{{ props.row.alias }}</span>
                            <ab-popover fontawesome-icon="pen" v-bind:popover="$t('ofex.tuContrato.popoverEditAlias')" />
                        </div>
                        <div v-else-if="props.column.field === 'fechaHoraultimaLectura'">
                            <span>{{ props.row.fechaHoraUltimaLectura }}</span>
                        </div>
                        <div class="col-envio-inner" v-else-if="props.column.field === 'ultimaLectura'">
                            <span class="text">{{ $formatNumber(props.row.ultimaLectura) }} m<sup>3</sup></span>
                        </div>
                        <div class="col-envio-inner d-flex justify-content-end align-items-center" v-else-if="props.column.field === 'consumo'">
                            <span class="text">{{ props.row.consumo }} m<sup>3</sup></span>
                            <img class="img-last-reading" v-if="props.row.fuga" src="../assets/images/svg/lectura_fuga.svg" alt="Fuga" />
                            <img class="img-last-reading" v-if="props.row.exceso" src="../assets/images/svg/lectura_exceso.svg" alt="Exceso" />
                            <div class="buttons-group d-flex align-items-center">
                                <b-dropdown
                                    :id="`dropdow-first-level-${props.row.contrato}`"
                                    :aria-label="'Menu edición datos de contrato ' + props.row.contrato"
                                    dropleft
                                    size="lg"
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    class="table-dropdown"
                                >
                                    <router-link :to="`/tuscontratos/${props.row.contrato}/tusconsumos`">
                                        <b-dropdown-item-button aria-describedby="desplegable">{{
                                            $t("ofex.tuContrato.consumptionDetail")
                                        }}</b-dropdown-item-button>
                                    </router-link>
                                </b-dropdown>
                            </div>
                        </div>
                        <div v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </div>
                    </template>
                </vue-good-table>
                <div class="table-buttons-container">
                    <ab-button-ofex
                        :text-button="$t('ofex.lastReading.imprimirLista')"
                        :font-awesome-icon="'download'"
                        :click-event="generatePdfLastReadingsList"
                    ></ab-button-ofex>
                    <ab-button-ofex
                        :text-button="$t('ofex.lastReading.listaExcel')"
                        :font-awesome-icon="'download'"
                        :click-event="generateExcelLastReadingsList"
                    ></ab-button-ofex>
                </div>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.beneficiosTelelectura.name')"
                    :href-left="$t('ofex.relatedContents.beneficiosTelelectura.url')"
                    :image-left="$t('ofex.relatedContents.beneficiosTelelectura.image')"
                    :name-center="$t('ofex.relatedContents.consellEstalvi.name')"
                    :href-center="$t('ofex.relatedContents.consellEstalvi.url')"
                    :image-center="$t('ofex.relatedContents.consellEstalvi.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { VueGoodTable } from "vue-good-table";
import { mapGetters, mapState } from "vuex";
import * as actionTypes from "../store/action-types";
import moment from "moment";
import AbMainTitle from "../modules/AbMainTitle.vue";
import AbYourInterest from "../modules/AbYourInterest.vue";
import { AB_DATETIME_FORMAT, AB_DATETIME_WITHOUT_SECONDS_FORMAT } from "@/plugins/ab-utils";
import LastReadingsListPdfGenerator from "@/generators/pdf/LastReadingsListPdfGenerator";
import LastReadingListXlsxGenerator from "@/generators/xlsx/LastReadingListXlsxGenerator";
import AbContractsAdvanceSearch from "@/modules/AbContractsAdvanceSearch";
import AbEditAliasModal from "@/modules/common/AbEditAliasModal";

export default {
    name: "LastReadingsDetailView",
    components: {
        AbEditAliasModal,
        AbMainTitle,
        AbYourInterest,
        VueGoodTable,
        AbContractsAdvanceSearch,
    },
    data: function () {
        return {
            filteredContracts: null,
            itemsSP: [],
            itemsFP: [],
            selected: [],
            selectAll: false,
            loading: false,
            tableSelectOptions: {
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: "custom-class",
                selectionText: "Contratos Seleccionados",
                clearSelectionText: this.$t("ofex.tuContrato.limpiar"),
                disableSelectInfo: true, // disable the select info panel on top
            },
        };
    },
    methods: {
        mapContractToTableRow: function (contractSummary) {
            let contractEntry = this.getContractByContractNumber(contractSummary.contractNumber);
            let supplyAddress = this.getContractSupplyCompleteAddress(contractEntry.contractDetail.contractNumber);
            return {
                contrato: contractEntry.contractDetail.contractNumber,
                alias: contractEntry.contractDetail.alias ? contractEntry.contractDetail.alias : "",
                suministro: supplyAddress,
                deuda: contractEntry.contractDetail.invoicesPending,
                telectura: contractEntry.contractDetail.telerecording,
                fechaHoraUltimaLectura: moment(contractSummary.lastConsumptionDateTime, AB_DATETIME_FORMAT).format(AB_DATETIME_WITHOUT_SECONDS_FORMAT),
                ultimaLectura: contractSummary.realMeterIndex,
                consumo: contractSummary.consumption,
                fuga: contractSummary.leaked,
                exceso: contractSummary.exceesed,
                aviso: contractSummary.leaked === 1 ? "Fuga" : contractSummary.exceesed === 1 ? "Exceso" : "",
            };
        },
        mapContractToTableExcellRow: function (contractSummary) {
            let contractEntry = this.getContractByContractNumber(contractSummary.contractNumber);
            let supplyAddress = this.getContractSupplyCompleteAddress(contractEntry.contractDetail.contractNumber);
            return {
                contrato: contractEntry.contractDetail.contractNumber,
                suministro: supplyAddress,
                fechaHoraUltimaLectura: moment(contractSummary.lastConsumptionDateTime, AB_DATETIME_FORMAT).format(AB_DATETIME_WITHOUT_SECONDS_FORMAT),
                ultimaLectura: contractSummary.realMeterIndex,
                consumo: contractSummary.consumption,
                aviso: contractSummary.leaked === 1 ? "Fuga" : contractSummary.exceesed === 1 ? "Exceso" : "",
            };
        },
        generateExcelLastReadingsList: function () {
            let xlsxGenerator = new LastReadingListXlsxGenerator(`UltimasLecturas.xlsx`, this.tableExcellRows, this.$i18n);
            console.log(xlsxGenerator);
        },
        generatePdfLastReadingsList: function () {
            let vueContext = this;
            let pdfGenerator = new LastReadingsListPdfGenerator(this.$i18n, this.userId, this.clientId);
            pdfGenerator.generatePdf(vueContext, this.contracts, this.contractConsumptionsSummary);
            pdfGenerator.downloadPdf();
        },
        isValidConsumptionContract: function (consumptionContract) {
            //verificamos que existe dentro de los contratos
            return this.filteredContracts !== null
                ? this.filteredContracts.map((value) => value.contractDetail.contractNumber).includes(consumptionContract.contractNumber)
                : typeof this.getContractByContractNumber(consumptionContract.contractNumber) !== "undefined";
        },
        intoConsumptionContractsPredicate: function (contract) {
            return this.contractConsumptionsSummary.map((value) => value.contractNumber).includes(contract.contractDetail.contractNumber);
        },
        openEditAliasModal: function (contractNumber, alias) {
            this.$refs.editAliasModal.openModal(contractNumber, alias);
        },
    },
    computed: {
        ...mapState(["contracts", "isLoading", "clientData", "contractConsumptionsSummary"]),
        ...mapGetters(["getContractByContractNumber", "getContractSupplyCompleteAddress"]),
        tableRows: function () {
            return this.contractConsumptionsSummary.filter(this.isValidConsumptionContract).map(this.mapContractToTableRow);
        },
        tableExcellRows: function () {
            return this.contractConsumptionsSummary.filter(this.isValidConsumptionContract).map(this.mapContractToTableExcellRow);
        },
        columns: function () {
            return [
                {
                    label: this.$t("ofex.tuContrato.contractNumber"),
                    field: "contrato",
                    type: "number",
                    tdClass: "first-col",
                    thClass: "first-col nowrap",
                },
                {
                    label: this.$t("ofex.tuContrato.contractAlias"),
                    field: "alias",
                    tdClass: "col-alias",
                },
                {
                    label: this.$t("ofex.tuContrato.supplyAddress"),
                    field: "suministro",
                    tdClass: "col-suministro",
                    thClass: "col-suministro",
                },
                {
                    label: this.$t("ofex.lastReading.fechaUltimaLectura"),
                    field: "fechaHoraUltimaLectura",
                },
                {
                    label: this.$t("ofex.lastReading.ultimaLectura"),
                    field: "ultimaLectura",
                    sortFn: function (x, y, col, rowX, rowY) {
                        // console.debug(`Comparando X:${x} Y:${y} COL:${col} ROWX:${rowX} ROWY:${rowY}`);
                        return Number(rowX.ultimaLectura) < Number(rowY.ultimaLectura) ? -1 : Number(rowX.ultimaLectura) > Number(rowY.ultimaLectura) ? 1 : 0;
                    },
                },
                {
                    label: this.$t("ofex.lastReading.ultimoConsumo"),
                    field: "consumo",
                    tdClass: "col-envio",
                    thClass: "col-envio",
                    sortFn: function (x, y, col, rowX, rowY) {
                        // console.debug(`Comparando X:${x} Y:${y} COL:${col} ROWX:${rowX} ROWY:${rowY}`);
                        return Number(rowX.consumo) < Number(rowY.consumo) ? -1 : Number(rowX.consumo) > Number(rowY.consumo) ? 1 : 0;
                    },
                },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, {
            locale: this.$i18n.locale,
            triggerLoading: false,
            triggerChangeActiveContractLoading: false,
        });
        this.$store.dispatch(actionTypes.GET_CONTRACT_CONSUMPTIONS_SUMMARY, { locale: this.clientData.language });
    },
};
</script>

<style lang="scss" scoped>
.last-reading {
    width: 110px;
    text-align: right;
}
.img-last-reading {
    width: 35px;
    margin-left: 10px;
}
.table-buttons-container {
    padding-top: 41px;
    @include respond-above(sm) {
        .btn {
            flex-basis: auto;
            width: auto;
        }
    }
}
</style>
<style lang="scss">
.p-last-reading-detail-view {
    .m-advance-search {
        .multiselect__select {
            width: 28px;
            height: 32px;
        }
        .multiselect__input {
            margin-top: 5px;
        }
    }
    .subtitle {
        margin-bottom: 25px;
    }
}
</style>
