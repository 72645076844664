<template>
    <b-container>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-main-title :main-text="$t('ofex.pagarFactura.pagoErroneo')" />

                <ab-confirmation
                    :image-src="require('../assets/images/caution-sign.png')"
                    :title="$t('ofex.pagarFactura.titlePagoErroneo')"
                    :confirmation-msg="' '"
                    :confirmation-description="'  '"
                    :show-pdf-button="false"
                ></ab-confirmation>

                <div class="line-top">
                    <div class="box-button float-right mt-4">
                        <ab-button-ofex
                            :click-event="routerNavigateHome"
                            class="p-0 pt-2 pb-2"
                            :text-button="$t('ofex.pagarFactura.volverInicio')"
                        ></ab-button-ofex>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import * as viewMappingTypes from "../router/view-mapping-types";
export default {
    name: "InvoicePaymentErrorView",
    computed: {
        ...mapState(["clientData", "payment"]),
        ...mapGetters(["clientId", "userId"]),
    },
    methods: {
        routerNavigateHome: function () {
            this.$router.push({ name: viewMappingTypes.HOME_VIEW_MAPPING.name });
        },
    },
};
</script>
<style lang="scss" scoped>
.line-top {
    width: 100%;
    border-top: 1px solid $blue-dark;
}
.box-button {
    width: 238px;
}
</style>
