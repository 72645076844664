import qs from "qs";
import store from "@/store/store";
import { SET_REST_ERROR } from "@/store/mutation-types";
import * as mutationTypes from "@/store/mutation-types";
import BaseOfexServiceClient from "./BaseOfexServiceClient";

const CONSUMPTIONS_BASE_URL = process.env.VUE_APP_API_CONSUMPTIONS_BASE_URL;

export default {
    getDailyConsumptions: async function (contractNumber, clientId, userId, lang = "es", fromDate, toDate, showNegativeValues) {
        return baseCall(contractNumber, clientId, userId, lang, fromDate, toDate, "DAILY", showNegativeValues);
    },
    getHourlyConsumptions: async function (contractNumber, clientId, userId, lang = "es", fromDate, toDate, showNegativeValues) {
        return baseCall(contractNumber, clientId, userId, lang, fromDate, toDate, "HOURLY", showNegativeValues);
    },
    getContractConsumptionsSummary: async function (clientId, userId, lang = "es") {
        try {
            let abRestClient = new BaseOfexServiceClient().abRestClient;

            let response = await abRestClient.get(`${CONSUMPTIONS_BASE_URL}/meter`, {
                params: {
                    clientId,
                    userId,
                    lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response.status === 200) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
};

async function baseCall(contractNumber, clientId, userId, lang = "es", fromDate, toDate, frequency, showNegativeValues) {
    try {
        console.log(`Obteniendo consumos diarios del cliente ${clientId} con número de contrato ${contractNumber}`);
        let abRestClient = new BaseOfexServiceClient().abRestClient;

        let response = await abRestClient.get(`${CONSUMPTIONS_BASE_URL}/meter/consumptions`, {
            params: {
                consumptionFrequency: frequency,
                contractNumber,
                clientId,
                userId,
                lang,
                fromDate,
                toDate,
                showNegativeValues,
            },
            //https://github.com/axios/axios/issues/604#issuecomment-321460450
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        });
        if (response.status === 200) {
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    } catch (e) {
        console.error(e.response);
        store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
        store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
    }
}
