<template>
    <div class="container p-email-recovery-confirm">
        <div class="row">
            <div class="col-12">
                <!--                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>-->
                <ab-main-title class="espaciado-sup-menu" :main-text="$t('ofex.emailRecovery.title')"></ab-main-title>
                <div class="confirm-info">
                    <img class="image" src="../assets/images/tic-green.png" alt="" />
                    <p class="text">{{ $t("ofex.emailRecoveryConfirm.confirmTitle") }}</p>
                </div>
                <p class="subtitle">{{ $t("ofex.emailRecoveryConfirm.confirmSubtitle") }}</p>

                <a href="/">
                    <ab-button-ofex class="espaciado-inf-footer" :text-button="$t('ofex.emailRecoveryConfirm.confirmbutton')"></ab-button-ofex>
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "EmailRecoveryConfirmView",
    data: function () {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.espaciado-sup-menu {
    margin-top: 80px;
}
.espaciado-inf-footer {
    margin-bottom: 72px;
}
.p-email-recovery-confirm {
    .m-main-title {
        margin-bottom: 50px;
    }
    .confirm-info {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        .image {
            margin-right: 20px;
        }
        .text {
            color: $blue-dark;
            font-weight: $font-primary-bold;
            @include font-size(26);
            @include line-height(31);
            margin-bottom: 0;
        }
    }
    .subtitle {
        margin-bottom: 50px;
    }
    .btn {
        @include respond-above(sm) {
            width: auto;
            margin: 0 auto;
            padding: 13px 30px;
        }
    }
}
</style>
