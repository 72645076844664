var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-invoice-digital"},[_c('ab-contracts-advance-search',{ref:"contractsAdvanceSearch",attrs:{"contracts-invoices-f-s-p":_vm.contractsInvoicesFSP,"extra-filter-predicate":_vm.fspPredicate,"onlyActiveContracts":true},model:{value:(_vm.filteredContracts),callback:function ($$v) {_vm.filteredContracts=$$v},expression:"filteredContracts"}}),_c('vue-good-table',{ref:"table-desactivate",attrs:{"columns":_vm.columns,"rows":_vm.tableRows,"style-class":"vgt-table","select-options":{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
            perPageDropdown: [10, 20, 30],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: this.$i18n.tc('table.siguiente'),
            prevLabel: this.$i18n.tc('table.atras'),
            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
            ofLabel: 'de',
            allLabel: this.$i18n.tc('ofex.tuContrato.tableTodo'),
            label: 'selector-de-paginas',
            name: 'perPageSelect',
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'contrato')?_c('div',[_c('label',{staticClass:"contract",class:{ pending: props.row.deuda },attrs:{"for":props.row.contrato}},[_vm._v(_vm._s(props.row.contrato))]),(props.row.telectura)?_c('img',{staticClass:"icon-contrato",attrs:{"alt":"imagen bola telelectura","src":require("../../../assets/images/bola-telelectura.png")}}):_vm._e()]):_c('div',{directives:[{name:"line-clamp",rawName:"v-line-clamp:20",value:(2),expression:"2",arg:"20"}]},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:(props.formattedRow[props.column.field]),expression:"props.formattedRow[props.column.field]",modifiers:{"top-center":true}}]},[_vm._v(_vm._s(props.formattedRow[props.column.field]))])])]}}])},[_c('div',{staticClass:"text-center textdnormal",attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" "+_vm._s(_vm.$t("ofex.tuContrato.emptyResults"))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }