"use strict";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import BaseOfexServiceClient from "../../../rest/BaseOfexServiceClient";
import { loadFont } from "@/generators/pdf/base/fonts/fontsLoader";
import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import { fileToBase64 } from "@/plugins/ab-utils";

export default class BasePdfGenerator {
    pdf;
    generationDate;
    SPACE = "    ";
    POINTS = ": ";
    MARGIN = 25;
    pdfInMM = 210;
    offsetY = 6;
    lineHeight = 5;
    lineCount = 11;
    pageNumber = 0;
    imgLogo = require("../../../assets/images/logo.png");
    filename;
    uploadedDocumentId;
    activityId;
    activityCode;
    uploadedFileId;
    i18n;
    userId;
    clientId;
    contract;

    constructor(filename, code, i18n, userId, clientId, contract) {
        this.pdf = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            // unit: 'pt',
            format: "a4",
        });

        //cargamos las fuentes
        if (!Capacitor.isNativePlatform()) {
            loadFont(this.pdf, "Raleway-Bold", "bold");
            loadFont(this.pdf, "Raleway-Regular", "normal");
        }

        this.generationDate = new Date();
        this.filename = filename;
        this.activityCode = code;
        this.i18n = i18n;
        this.userId = userId;
        this.clientId = clientId;
        this.contract = contract;
    }

    get pdfGenerated() {
        return this.pdf;
    }

    generatePdfSection(title) {
        let lineY = ++this.lineCount * this.lineHeight + this.offsetY;
        let titleY = ++this.lineCount * this.lineHeight + this.offsetY;

        console.debug("Generando seccion: " + title + " en lineY: " + lineY + " y titleY: " + titleY);
        this.pdf.setTextColor("#2F2F2F");
        this.pdf.setDrawColor(38, 116, 159);
        this.pdf.setLineWidth(0.5);
        this.pdf.line(25, lineY, 175, lineY, "S");
        this.pdf.setFontSize(10);
        this.pdf.text(title, 25, titleY);
        this.pdf.setFontSize(7);
        this.pdf.setTextColor("#26749F");
    }

    generatePdfFooter() {
        this.pdf.setDrawColor(38, 116, 159);
        this.pdf.setLineWidth(0.8);
        this.pdf.line(20, 275, 182, 275, "S");
        this.pdf.setFontSize(8);
        this.pdf.setTextColor(38, 116, 159);
    }

    addPage() {
        var current = this.pdf.pageNumber();
        this.pdf.setPage(current + 1);
        var newCurrent = this.pdf.pageNumber();
        if (newCurrent === current) {
            this.pdf.addPage();
        }
    }

    generatePdfHeader() {
        //Cabecera
        this.pdf.addImage(this.imgLogo, "PNG", 20, 10, 20, 9.46);
        this.pdf.setDrawColor(38, 116, 159);
        this.pdf.setLineWidth(0.8);
        this.pdf.line(20, 25, 182, 25, "S");
    }

    generatePdfTitleAndSubtitle(title, subtitle) {
        this.pdf.setTextColor("#2F2F2F");
        this.pdf.setFont("Raleway-Bold", "bold");
        this.pdf.setFontSize(14);
        this.pdf.text(title, 100, 35, { align: "center" });
        this.pdf.setFontSize(10);
        this.pdf.text("", this.MARGIN, 45);
        this.pdf.setFontSize(7);
        this.pdf.text(subtitle, this.MARGIN, 55);
    }

    /**
     * Pinta una línea de texto y hace un salto de linea
     *
     * @param text Texto a renderizar
     */
    generateLineText(text) {
        this.pdf.text(text + this.SPACE, this.MARGIN, ++this.lineCount * this.lineHeight + this.offsetY);
    }

    generateLineTextWithLink(text, enlace) {
        this.pdf.textWithLink(text, this.MARGIN, ++this.lineCount * this.lineHeight + this.offsetY, { url: enlace });
    }

    /**
     * Pinta una línea titulo y texto y hace un salto de linea
     *
     * @param text Texto a renderizar
     */
    generateLineTitleText(title, text) {
        this.pdf.setTextColor("#2F2F2F");
        this.pdf.setFont("Raleway-Bold", "bold");
        this.pdf.setFontSize(14);
        this.pdf.text(title + ":" + this.SPACE, ++this.lineCount * this.lineHeight + this.offsetY);
        this.pdf.setFont("Raleway-Bold", "normal");
        this.pdf.text(text + this.SPACE, this.lineCount * this.lineHeight + this.offsetY);
    }
    generateAutoTable(headParam, bodyParam, start) {
        this.pdf.autoTable({
            startY: this.lineCount * start,
            head: headParam,
            body: bodyParam,
            margin: this.MARGIN,
            headStyles: {
                textColor: "#FFFFFF",
                fontStyle: "bold",
                halign: "center",
                fontSize: 8,
            },
            styles: {
                halign: "center",
                overflow: "linebreak",
                fontSize: 7,
                color: "#2F2F2F",
                textColor: "#26749F",
                fontStyle: "bold",
            },
        });
    }

    /**
     * Pinta un texto muy largo en varias lineas
     * @param rawText
     */
    generateMultilineText(rawText) {
        let lines = this.pdf.splitTextToSize(rawText, this.pdfInMM - this.MARGIN - this.MARGIN);
        this.pdf.text(this.MARGIN, ++this.lineCount * this.lineHeight + this.offsetY, lines);
    }

    /**
     * Obtiene el binario del PDF
     *
     * @returns {*}
     */
    getPdfBlob() {
        return this.pdf.output("blob", {
            filename: this.filename,
        });
    }

    /**
     * Descarga el PDF al navegador
     */
    downloadPdf() {
        let base64String = this.pdf.output("datauri", this.filename);
        console.debug(base64String);

        if (Capacitor.isNativePlatform()) {
            Filesystem.writeFile({
                path: this.filename,
                // data: base64String.substr(base64String.indexOf(", ") + 1),
                data: base64String,
                directory: Directory.Data,
            }).then((writeResult) => {
                FileOpener.open({ filePath: writeResult.uri, contentType: "application/pdf" })
                    .then(() => console.log("File is opened"))
                    .catch((e) => console.log("Error opening file", e));
            });
        } else {
            this.pdf.save(this.filename);
        }
    }

    async uploadPdfWithActivity(activityCode, uploadImmediately = true, publicSubscription = false) {
        this.activityId = activityCode;
        return this.uploadPdf(uploadImmediately, publicSubscription);
    }

    /**
     * Sube el PDF al Content Manager y devuelve el identificador
     *
     * @returns {Promise<AxiosResponse<any>>}
     */
    async uploadPdf(uploadImmediately = true, publicSubscription = false) {
        console.debug("Subiendo pdf a content manager");
        console.debug("La actividad es " + this.activityId);
        if (!this.activityId) {
            console.warn("El pdf no tiene una actividadId asociada. Se genera una nueva");
            this.activityId = this.activityCode + new Date().getTime();
        }

        let pdfBlob = this.getPdfBlob();

        console.log("PDF BLOB SIZE " + pdfBlob.size);

        let uploadResponse;
        if (publicSubscription === true) {
            let jsonData = {
                actividadId: this.activityId,
                filename: this.filename,
                uploadImmediately: `${uploadImmediately}`,
                userId: this.userId ? this.userId : "SP",
                clientId: this.clientId ? this.clientId : "SP",
                contract: this.contract ? this.contract : "SP",
                content: await fileToBase64(pdfBlob),
            };

            uploadResponse = await new BaseOfexServiceClient().abRestClient.post(
                `${process.env.VUE_APP_API_CONTENT_MANAGER_BASE_URL}/content-manager/upload/file-base64`,
                jsonData,
                {
                    params: {
                        clientId: this.clientId,
                        userId: this.userId,
                    },
                    headers: {
                        "Ocp-Apim-Subscription-Key": process.env.VUE_APP_APIM_PUBLIC_FORMS_SUBSCRIPTION_KEY_HEADER,
                    },
                }
            );
        } else {
            let jsonData = {
                actividadId: this.activityId,
                filename: this.filename,
                uploadImmediately: `${uploadImmediately}`,
                userId: this.userId ? this.userId : "SP",
                clientId: this.clientId ? this.clientId : "SP",
                contract: this.contract ? this.contract : "SP",
                content: await fileToBase64(pdfBlob),
            };

            uploadResponse = await new BaseOfexServiceClient().abRestClient.post(
                `${process.env.VUE_APP_API_CONTENT_MANAGER_BASE_URL}/content-manager/upload/file-base64`,
                jsonData,
                {
                    params: {
                        clientId: this.clientId,
                        userId: this.userId,
                    },
                }
            );
            this.uploadedDocumentId = uploadResponse.data.documentoId;
            this.uploadedFileId = uploadResponse.data.fileId;
            return uploadResponse.data;
        }
    }
}
