<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="row">
        <div class="col-12">
            <div v-for="(item, index) in bateriasAumento" :key="item.letra" class="row mt-5 mb-5">
                <div class="col-8 col-lg-4">
                    <ab-input
                        v-model="bateriasAumento[index].letra"
                        :name="name + 'letra' + index"
                        :input-alias="$t('alias.letraBateriaAumento')"
                        placeholder="Bateria o, A,B C..."
                        :popover="$t('baterias.letraIdentificaPopover')"
                        :validation="{ required: true, min: 1 }"
                        :label="$t('baterias.bateriaAntigua')"
                    ></ab-input>
                </div>

                <div class="col-8 col-lg-4">
                    <ab-input
                        v-model="bateriasAumento[index].destinos"
                        :name="name + 'destinos' + index"
                        :input-alias="$t('alias.destinosActuales')"
                        placeholder="1, 2, 3..."
                        :popover="$t('baterias.popovers.destinosActuales')"
                        :validation="{ required: true, min: 1 }"
                        :label="$t('baterias.destinosActuales')"
                    ></ab-input>
                </div>
                <div class="col-8 col-lg-4">
                    <ab-input
                        v-model="bateriasAumento[index].numeroAumento"
                        :name="name + 'numeroAumento' + index"
                        :input-alias="$t('alias.numeroAumento')"
                        placeholder="1, 2, 3..."
                        :popover="$t('baterias.popovers.numeroAumento')"
                        :validation="{ required: true, min: 1 }"
                        :label="$t('baterias.numeroAumento')"
                    ></ab-input>
                </div>
            </div>

            <div class="row mt-2 mb-5">
                <div class="col-12 col-lg-8">
                    <button type="button" class="botonBateria" @click="addNewEntry">
                        <i class="fas fa-plus-circle"
                            ><span class="textdnormal ml-1">{{ $t("baterias.addBateria") }}</span></i
                        >
                    </button>
                </div>

                <div v-if="isMulti" class="col-12 col-lg-4">
                    <button type="button" class="botonBateria" @click="removeLastEntry">
                        <i class="fas fa-minus-circle"
                            ><span class="textdnormal ml-1">{{ $t("baterias.deleteBateria") }}</span></i
                        >
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AbInput from "./AbInput.vue";

export default {
    components: {
        AbInput,
    },
    model: {
        prop: "bateriasAumentoList",
        event: "change",
        type: Array,
    },
    props: {
        required: {
            default: false,
            type: Boolean,
        },
        name: {
            required: true,
            type: String,
        },
        validationScope: String,
        validationEvent: String,
    },
    data: function () {
        return {
            bateriasAumento: [{ letra: "", destinos: "", numeroAumento: "" }],
        };
    },
    computed: {
        isMulti: function () {
            return this.bateriasAumento.length > 1;
        },
    },
    methods: {
        addNewEntry: function () {
            this.bateriasAumento.push({ letra: "", destinos: "", numeroAumento: "" });
        },
        removeLastEntry: function () {
            this.bateriasAumento.pop();
        },
    },
};
</script>
