<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <validation-provider
            mode="lazy"
            :name="inputAliasComputed"
            :vid="`${name}-vid`"
            :rules="{ required: true }"
            :debounce="300"
            v-slot="{ errors, classes }"
        >
            <div class="c-datepicker">
                <label class="letraform-new w-100" :for="name">
                    {{ label }}
                </label>
                <div class="div-left-datapicker d-flex" :class="errors.length > 0 ? 'datepickerFormErr' : ''">
                    <date-picker
                        :id="name"
                        :ref="name"
                        v-model="modelValue"
                        type="day"
                        value-type="date"
                        :format="dateFormat"
                        :placeholder="dateFormat"
                        :title-format="dateFormat"
                        :lang="$i18n.locale"
                        :clearable="false"
                        :open.sync="open"
                        :disabled-date="calculateDisabledDates"
                        @change="handleChange"
                    ></date-picker>
                    <ab-popover class="div-right-datapicker" :popover="popover"></ab-popover>
                </div>
            </div>
            <p v-show="errors.length > 0" class="formTextErr displayYes">{{ errors[0] }}</p>
        </validation-provider>
    </div>
</template>

<script>
import moment from "moment/src/moment";
import AbPopover from "./AbPopover.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ca";
import "vue2-datepicker/locale/es";
import "vue2-datepicker/index.css";

export default {
    components: {
        AbPopover,
        DatePicker,
    },
    model: {
        prop: "dateValue",
        event: "changedate",
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        inputAlias: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: true,
        },
        popover: {
            type: String,
        },
        dateFormat: {
            type: String,
            default: "DD/MM/YYYY",
        },
        maxDate: {
            type: Date,
            default: () => new Date(),
        },
        minDate: {
            type: Date,
            default: () => new Date(0), //1970
        },
    },
    dateValue: {
        default: "",
    },
    data: function () {
        return {
            open: false,
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["dateValue"];
            },
            set: function (newValue) {
                this.$emit("changedate", newValue);
            },
        },
        inputAliasComputed: function () {
            return this.inputAlias ? this.inputAlias : this.label.toLowerCase().replace("*", "").replace(":", "");
        },
    },
    created: function () {
        console.debug("CREAThttps://zenerab.atlassian.net/browse/UATOFEX-566ED DATEPICKER VUE");
        moment.locale(this.$i18n.locale);
    },
    mounted: function () {
        console.debug("MOUNTED DATEPICKER VUE");
    },
    methods: {
        handleChange(value, type) {
            if (type === "date") {
                this.open = false;
            }
        },
        calculateDisabledDates(date) {
            return date > this.maxDate || date < this.minDate;
        },
    },
};
</script>
<style src="pikaday/css/pikaday.css"></style>
<style lang="scss" scoped>
label {
    line-height: 1px;
}

.c-datepicker {
    display: flex;
    flex-wrap: wrap;
    @include respond-below(md) {
        margin-bottom: 20px;
    }
}
/*Quitar icono agenda safari */
::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.minicalendar {
    background-image: url(../assets/images/minicalendar.png) !important;
    background-position: right 0.5em center !important;
    background-repeat: no-repeat !important;
    background-size: 1.5em auto !important;
    padding-right: 2em !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 40px !important;
    @include respond-above(sm) {
        height: 32px !important;
    }
}

.ab-datepicker-theme pika-select {
    color: #26749f;
}
</style>
<style lang="scss">
.datepickerFormErr .mx-datepicker .mx-input-wrapper .mx-input {
    border: 1px solid #f48989 !important;
    background-color: #feefef !important;
}
</style>
