<template>
    <!--    https://www.npmjs.com/package/vue-loading-overlay-->
    <div class="vld-parent" :class="classFather">
        <loading :active.sync="isLoading" :opacity="opacity" :can-cancel="false" :is-full-page="fullPage" color="#26749f">
            <template v-if="loaderText" slot="after">
                <p class="loader-text">{{ loaderText }}</p>
            </template>
        </loading>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";

export default {
    components: {
        Loading,
    },
    model: {
        prop: "modelValue",
        event: "change",
    },
    props: {
        fullPage: {
            default: true,
            type: Boolean,
        },
        loaderText: {
            required: false,
            type: String,
        },
        opacity: {
            type: Number,
            default: 0.5,
        },
        classFather: {
            type: String,
            default: "",
            required: false,
        },
    },
    data() {
        return {};
    },
    computed: {
        isLoading: {
            get: function () {
                return this.$attrs["modelValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
    },
};
</script>

<style src="vue-loading-overlay/dist/vue-loading.css"></style>
<style lang="scss">
.vld-icon {
    text-align: center;

    .loader-text {
        color: $blue-dark;
        width: auto;
        font-weight: $font-primary-bold;
        margin-top: 10px;
        max-width: 250px;
    }
}
</style>
