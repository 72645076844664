import qs from "qs";
import store from "@/store/store";
import { SET_REST_ERROR } from "@/store/mutation-types";
import * as mutationTypes from "@/store/mutation-types";
import BaseOfexServiceClient from "./BaseOfexServiceClient";

const NOTIFICATIONS_BASE_URL = process.env.VUE_APP_API_NOTIFICATIONS_BASE_URL;

export default {
    getUserNotifications: async function (clientId, userId, fromDate, toDate, lang = "es") {
        try {
            console.debug("Obteniendo notificaciones del cliente");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${NOTIFICATIONS_BASE_URL}/notifications`, {
                params: {
                    clientId,
                    userId,
                    lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response && response.status === 200) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response ? response.statusText : "Error inesperado";
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    getUnreadUserNotifications: async function (clientId, userId, fromDate, toDate, lang = "es") {
        try {
            console.debug("Obteniendo notificaciones (unread) del cliente");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.get(`${NOTIFICATIONS_BASE_URL}/notifications/unread`, {
                params: {
                    clientId,
                    userId,
                    lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response && response.status === 200) {
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response ? response.statusText : "Error inesperado";
        } catch (e) {
            console.error(e);
            let msg = e.response ? e.response.data.errorMessage : e.toString();
            store.commit(SET_REST_ERROR, { error: msg });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    getConfigUserNotifications: async function (clientId, userId, lang = "es") {
        console.log("Obteniendo la codificación de notificaciones del cliente");
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.get(`${NOTIFICATIONS_BASE_URL}/notifications/managements`, {
            params: {
                clientId,
                userId,
                lang,
            },
            //https://github.com/axios/axios/issues/604#issuecomment-321460450
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        });
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
    updateConfigUserNotifications: async function (clientId, userId, lang, configNotificationsList, contractsDataList) {
        console.log("Modificación de notificaciones por contratos");
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.put(
            `${NOTIFICATIONS_BASE_URL}/notifications/managements`,
            {
                configNotificationsList: configNotificationsList,
                contractsDataList: contractsDataList,
            },
            {
                params: {
                    clientId: clientId,
                    userId: userId,
                    lang: lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        console.log("Error" + response.statusText);
        throw response.statusText;
    },
};
