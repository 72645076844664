<template slot="header-web-links">
    <div class="left-side">
        <mq-layout class="header-links-container" :mq="menuNativo">
            <a href="https://www.aiguesdebarcelona.cat/es/web/ab-corporativa" class="link">{{ $t("ofex.header.webCorporativa") }}</a>
            <a href="https://www.aiguesdebarcelona.cat/web/guest/aigues-de-barcelona" class="link">{{ $t("ofex.header.webAguas") }}</a>
            <router-link :to="{ name: viewMappingTypes.HOME_VIEW_MAPPING.name }" class="link selected">{{ $t("ofex.header.areaPrivada") }}</router-link>
        </mq-layout>
    </div>
</template>

<script>
import * as viewMappingTypes from "../../router/view-mapping-types";

export default {
    name: "AbHeaderWebLinks",
    data() {
        return {
            viewMappingTypes: viewMappingTypes,
            labelExt: this.$i18n.tc("ofex.header.ir"),
            isWebApp: this.$isWebApp(),
        };
    },
    computed: {
        menuNativo() {
            let isNative = [];
            if (this.isWebApp) {
                isNative = ["md", "lg"];
            } else {
                isNative = [""];
            }
            return isNative;
        },
    },
};
</script>

<style lang="scss">
.header-links-container {
    position: relative;

    .link {
        @include respond-above(md) {
            position: relative;
            color: $color-lightest;
            font-weight: $font-primary-medium;
            @include font-size(10);
            @include line-height(12);
            padding-right: 7px;
            margin-right: 7px;
            border-right: 1px solid $color-lightest;

            &.selected {
                font-weight: $font-primary-bold;
            }
            &:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: none;
            }
        }
    }

    @include respond-below(md) {
        .bp-dropdown__btn {
            color: $color-lightest;
            border: 0;
            padding: 0;

            .icon {
                margin-left: 7px;
                margin-top: -2px;
            }
        }
        .bp-dropdown__body {
            background: #e0e0e0;
            font-weight: $font-primary;
            @include font-size(14);
            @include line-height(16);
            padding: 0;
            border-radius: 5px;

            &:before {
                content: "";
                position: absolute;
                margin-top: -3px;
                left: 30px;
                top: -9px;
                border: 6px solid transparent;
                border-bottom-color: $color-lightest;
            }
            .dropdown-list {
                margin-bottom: 0;
            }
            li {
                padding: 8px 12px;
                border-bottom: 1px solid $bg-primary;

                &:last-child {
                    border: 0;
                }

                .link {
                    color: $bg-primary;
                    font-weight: $font-primary-medium;
                }
            }
        }
    }
}
</style>
