<template>
    <!-- <div class="d-flex"> -->
    <button :disabled="disabled" @click.stop="clickEvent" class="btn btn-primary" @mouseover="finalSvg = srcSvgHover" @mouseleave="finalSvg = srcSvg">
        <font-awesome-icon :icon="fontAwesomeIcon" class="icon" v-if="fontAwesomeIcon"></font-awesome-icon>
        <img class="icon" alt="icon" :src="finalSvg" v-if="srcSvg" :class="{ limitHeight: maxImgHeight }" />
        <span>{{ textButton }}</span>
    </button>
    <!--  <ab-popover v-if="showPopover" :popover="popover" /> 
    </div> -->
</template>

<script>
export default {
    props: {
        textButton: {
            type: String,
            required: true,
        },
        clickEvent: {
            type: Function,
            default: function () {
                console.debug(`Button pulsed`);
            },
        },
        fontAwesomeIcon: {
            type: String,
        },
        srcSvg: {
            type: String,
        },
        srcSvgHover: {
            type: String,
        },
        maxImgHeight: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        popover: {
            type: String,
            required: false,
        },
        showPopover: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            finalSvg: null,
        };
    },
    mounted() {
        this.finalSvg = this.srcSvg;
    },
};
</script>

<style lang="scss" scoped>
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 13px 20px;
    border-radius: 8px;
    @include font-size(16);
    @include line-height(18);
    font-weight: $font-primary-semiBold;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;

    .limitHeight {
        height: 20px;
    }
    .icon {
        margin-right: 15px;
    }
    &.btn-primary {
        //background-color: $button-bg-primary;
        //border: 1px solid $button-bg-primary;
        border: 1px solid $bg-primary;
        background: $bg-primary;
        color: $button-text-primary;

        &:hover,
        &:not(:disabled):not(.disabled):active {
            background-color: $button-text-primary;
            color: $button-bg-primary;
            border: 1px solid $button-bg-primary;
            box-shadow: none;
        }
        &:hover:disabled {
            background-color: $blue-darker;
            color: $color-lightest;
            cursor: not-allowed;
        }
    }
    &.btn-secondary {
        background-color: $button-text-primary;
        color: $button-bg-primary;
        border: 1px solid $button-bg-primary;

        &:hover,
        &:not(:disabled):not(.disabled):active {
            background-color: $button-bg-primary;
            border: 1px solid $button-bg-primary;
            color: $button-text-primary;
            box-shadow: none;
        }
    }
    &.btn-white {
        border: 1px solid $blue-darker;
        background-color: white;
        color: $blue-darker;

        &:hover {
            border: 1px solid $blue-darker;
            background-color: $blue-darker;
            color: white;
        }
    }
}
</style>
