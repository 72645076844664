<template name="location-container">
    <div class="location-container">
        <span class="location-text">Barcelona - Horta Guinardo</span>
        <font-awesome-icon class="icon" icon="map-marker-alt"></font-awesome-icon>
    </div>
</template>

<script>
export default {
    name: "AbHeaderLocation",
};
</script>

<style lang="scss" scoped>
.location-container {
    position: relative;
    margin-right: 20px;
    padding-right: 16px;

    .location-text {
        color: $color-lightest;
        margin-right: 7px;
        font-weight: $font-primary;
        @include font-size(10);
        @include line-height(11);

        @include respond-below(sm) {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow-x: hidden;
            max-width: 150px;
            display: inline-block;
            margin-top: 8px;
        }
    }
    .icon {
        color: $color-lightest;
        @include font-size(21);
        position: absolute;
        top: 1px;
        right: 0;
    }
}
</style>
