<template>
    <b-container v-if="!selectedInactiveContract">
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.modificarDatosPago.title')" />
                <ab-return />
                <!-- detalle del monocontrato -->
                <ab-detail-mono-contract :selected-contract="this.$route.params.contractId"></ab-detail-mono-contract>
                <!-- monocontrato y paso 2 de multicontrato -->
                <div class="mt-2 pt-4">
                    <span class="subtitle-collapse">{{ $t("ofex.modificarDatosContrato.titularCuentaBancaria") }}</span>
                    <p class="normal-detail mt-4">{{ $t("ofex.modificarDatosContrato.camposObligatorios") }}</p>
                    <input
                        type="checkbox"
                        :title="$t('ofex.modificarDatosContrato.pagadorTitularContrato')"
                        v-model="formModel.payerHolder"
                        name="payer-holder"
                        id="payer-holder"
                        aria-labelledby="payer-holder-label-modification-view"
                        class="mt-2 space-checkbox"
                    />
                    <label id="payer-holder-label-modification-view" for="payer-holder" class="normal-detail ml-2 mt-2">{{
                        $t("ofex.modificarDatosContrato.pagadorTitularContrato")
                    }}</label>
                </div>
                <!--El pagador NO es el titular del contrato-->
                <ValidationObserver ref="paymentForm" v-slot="{ reset, invalid }">
                    <section>
                        <ab-id-type-number
                            class="mt-3"
                            v-model="formModel.paymentData.doc"
                            :allow-cif="true"
                            :remote-validation="false"
                            :readonly="formModel.payerHolder ? true : false"
                            :label-number-document="$t('idTypeNumber.numeroDocumento')"
                            validation-type-name="payment-data-tipe-document"
                            validation-number-name="payment-data-number-document"
                            :event-blur="checkEventBlur"
                        ></ab-id-type-number>
                        <div class="row mt-5">
                            <div class="col-12 col-lg-2" v-if="formModel.paymentData.doc.type != 'CIF'">
                                <ab-select
                                    v-if="!formModel.payerHolder"
                                    v-model="formModel.paymentData.treatment"
                                    name="payment-data-treatment"
                                    :placeholder="'Seleccionar'"
                                    :validation="{ required: true }"
                                    :data-list="treatmentSelectData"
                                    :label="$t('ofex.bajaContratoDetalle.tratamiento')"
                                >
                                </ab-select>
                                <ab-input
                                    autocomplete="honorific-prefix"
                                    v-if="formModel.payerHolder"
                                    v-model="formModel.paymentData.treatment"
                                    name="payment-data-treatment"
                                    :placeholder="''"
                                    :readonly="true"
                                    :validation="{ required: true, max: 50 }"
                                    :label="$t('ofex.bajaContratoDetalle.tratamiento')"
                                ></ab-input>
                            </div>
                            <div class="col-12 col-lg-4">
                                <ab-input
                                    autocomplete="given-name"
                                    v-model="formModel.paymentData.name"
                                    name="payment-data-name"
                                    :placeholder="$t('ofex.bajaContratoDetalle.placeholders.nombre')"
                                    :readonly="formModel.payerHolder ? true : false"
                                    :validation="{ required: true, max: 50 }"
                                    :label="$t('ofex.bajaContratoDetalle.nombre')"
                                ></ab-input>
                            </div>
                            <div class="col-12 col-lg-3" v-if="formModel.paymentData.doc.type != 'CIF'">
                                <ab-input
                                    autocomplete="last-name"
                                    v-model="formModel.paymentData.firstLastName"
                                    name="payment-data-lastname"
                                    :placeholder="$t('ofex.bajaContratoDetalle.placeholders.primerApellido')"
                                    :readonly="formModel.payerHolder ? true : false"
                                    :validation="{ required: true, max: 50 }"
                                    :label="$t('ofex.bajaContratoDetalle.primerApellido')"
                                ></ab-input>
                            </div>
                            <div class="col-12 col-lg-3" v-if="formModel.paymentData.doc.type != 'CIF'">
                                <ab-input
                                    autocomplete="last-name"
                                    v-model="formModel.paymentData.secondLastName"
                                    name="payment-data-second-lastName"
                                    :placeholder="$t('ofex.bajaContratoDetalle.placeholders.segundoApellido')"
                                    :readonly="formModel.payerHolder ? true : false"
                                    :validation="{ required: false, max: 50 }"
                                    :label="$t('ofex.bajaContratoDetalle.segundoApellido')"
                                ></ab-input>
                            </div>
                        </div>
                        <div class="row mt-5 mb-3">
                            <div class="col-12 col-lg-4">
                                <ab-input
                                    autocomplete="email"
                                    v-model="formModel.paymentData.email"
                                    name="payment-data-email"
                                    :placeholder="$t('ofex.modificarDatosContrato.placeholderEmail')"
                                    :readonly="formModel.payerHolder ? true : false"
                                    :validation-delay="200"
                                    :validation="{
                                        required: true,
                                        email: true,
                                        regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                    }"
                                    :label="$t('ofex.modificarDatosContrato.email')"
                                >
                                </ab-input>
                            </div>
                            <div class="col-12 col-lg-4">
                                <ab-input
                                    autocomplete="email"
                                    v-model="formModel.paymentData.reEmail"
                                    name="payment-data-reemail"
                                    :placeholder="$t('ofex.modificarDatosContrato.placeholderEmail')"
                                    :readonly="formModel.payerHolder ? true : false"
                                    :validation-delay="200"
                                    :validation="{
                                        required: true,
                                        email: true,
                                        regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                        is: formModel.paymentData.email,
                                    }"
                                    :label="$t('ofex.modificarDatosContrato.reemail')"
                                    :pasteable="false"
                                >
                                </ab-input>
                            </div>
                            <div class="col-12 col-lg-3">
                                <ab-input
                                    autocomplete="tel-national"
                                    v-model="formModel.paymentData.mainPhone"
                                    name="payment-data-telephone"
                                    :placeholder="$t('ofex.modificarDatosContrato.placeholderTelefono')"
                                    :readonly="formModel.payerHolder ? true : false"
                                    :validation="{ required: true, spanishPhone: true }"
                                    :label="$t('ofex.modificarDatosContrato.telefono')"
                                    :popover="$t('ofex.modificarDatosContrato.tlfPreferente')"
                                >
                                </ab-input>
                            </div>
                        </div>
                    </section>
                    <!--Datos bancarios-->
                    <section>
                        <p class="subtitle-collapse">{{ $t("ofex.modificarDatosContrato.datosBancarios") }}</p>
                        <div class="row mt-3">
                            <div class="col-12 col-lg-3">
                                <ab-country-select
                                    v-model="formModel.dataBank.country"
                                    name="country-data-bank"
                                    :input-alias="$t('ofex.bajaContratoDetalle.pais')"
                                    placeholder="Seleccionar"
                                    ref="countryiban"
                                    :validation="{ required: true }"
                                    :label="$t('ofex.bajaContratoDetalle.pais')"
                                >
                                </ab-country-select>
                            </div>
                        </div>
                        <div class="row mt-sm-5 mb-5">
                            <div class="col-12 col-lg-6">
                                <ab-iban
                                    name="iban-data-bank"
                                    v-model="formModel.dataBank.iban"
                                    :input-alias="$t('ofex.bajaContratoDetalle.iban')"
                                    :placeholder="$t('ofex.bajaContratoDetalle.placeholders.iban')"
                                    :is-required="true"
                                    :external-country="formModel.dataBank.country"
                                    :label="$t('ofex.bajaContratoDetalle.iban')"
                                ></ab-iban>
                            </div>
                            <div class="col-12 col-lg-2" v-if="formModel.dataBank.country != 'ES'">
                                <ab-input
                                    v-model="formModel.dataBank.bic"
                                    name="bic-data-bank"
                                    :input-alias="'BIC'"
                                    :placeholder="'BIC'"
                                    :validation="{ required: true, max: 11, min: 8 }"
                                    label="BIC*"
                                >
                                    >
                                </ab-input>
                            </div>
                        </div>
                        <!-- Cesión de datos MUSA -->
                        <div v-if="contractDetail">
                            <ab-radio
                                v-if="contractDetail.contractDetail.musa"
                                v-model="formModel.dataBank.musaRadio"
                                name="MUSA-payment"
                                :title="$t('ofex.modificarDatosContrato.cesionDatos')"
                                :validation="{ required: true }"
                                :description="$t('ofex.modificarDatosContrato.exigenciaReglamento')"
                            >
                            </ab-radio>
                        </div>
                        <!-- botones enviar FORM-->
                        <div class="line-bottom mt-3 mb-3"></div>
                        <div class="w-100 d-flex justify-content-end align-items-center mb-space">
                            <div class="btn-send">
                                <ab-button-ofex
                                    :text-button="$t('ofex.modificarDatosContrato.enviar')"
                                    :click-event="modify"
                                    :disabled="invalid"
                                    :show-popover="invalid"
                                    :popover="$t('formularios.buttom.sendMessage')"
                                ></ab-button-ofex>
                            </div>
                        </div>
                        <ab-text-end name="textos-finales"></ab-text-end>
                    </section>
                </ValidationObserver>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
    <b-container v-else>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <!--Main title -->
                <ab-main-title :main-text="$t('ofex.modificarDatosPago.title')" />
                <ab-without-data class="mt-3" :text="$t('ofex.modificationContractSelector.noActiveSelectedContracts')" />
                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.tarifasContratacion.name')"
                    :href-left="$t('ofex.relatedContents.tarifasContratacion.url')"
                    :image-left="$t('ofex.relatedContents.tarifasContratacion.image')"
                    :name-center="$t('ofex.relatedContents.bonifications.name')"
                    :href-center="$t('ofex.relatedContents.bonifications.url')"
                    :image-center="$t('ofex.relatedContents.bonifications.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import * as actionTypes from "../store/action-types";
// import MailAddressModifyPdfGenerator from "@/generators/MailAddressModifyPdfGenerator";
import Swal from "sweetalert2";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import PaymentModifyPdfGenerator from "@/generators/pdf/PaymentModifyPdfGenerator";
import AbDetailMonoContract from "../modules/contracts/modification/AbDetailMonoContract.vue";
import AbReturn from "../components/AbReturn.vue";
export default {
    name: "ModificationPaymentData",
    components: {
        AbDetailMonoContract,
        AbReturn,
    },
    data: function () {
        return {
            formModel: {
                selectedContract: "",
                payerHolder: false,
                paymentData: {
                    doc: { type: "", number: "", exists: false },
                    treatment: "",
                    name: "",
                    firstLastName: "",
                    secondLastName: "",
                    email: "",
                    reEmail: "",
                    mainPhone: "",
                },
                dataBank: {
                    country: "ES",
                    countryTitle: "",
                    iban: "",
                    bic: "",
                    musaRadio: null,
                },
            },
        };
    },
    computed: {
        ...mapState(["userData", "isLoading", "clientData", "contracts", "contractDetail", "selectedContractNumber"]),
        ...mapGetters(["isCompany", "multicontract", "clientId", "userId", "getContractByContractNumber", "selectedInactiveContract"]),
        treatmentSelectData: function () {
            return [
                { key: "Sr.", text: this.$t("ofex.bajaContratoDetalle.selectTratamiento.sr") },
                { key: "Sra.", text: this.$t("ofex.bajaContratoDetalle.selectTratamiento.sra") },
                { key: "Sres.", text: this.$t("ofex.bajaContratoDetalle.selectTratamiento.sres") },
            ];
        },
    },
    methods: {
        dumpInfoFormModel: function () {
            this.formModel.paymentData.doc.type = this.isCompany ? this.clientData.documentType : this.userData.documentType;
            this.formModel.paymentData.doc.number = this.isCompany ? this.clientData.documentNumber : this.userData.documentNumber;
            this.formModel.paymentData.treatment = this.isCompany ? "" : this.userData.treatment + ".";
            this.formModel.paymentData.name = this.isCompany ? this.clientData.name : this.userData.firstName;
            this.formModel.paymentData.firstLastName = this.isCompany ? "" : this.userData.firstLastName;
            this.formModel.paymentData.secondLastName = this.isCompany ? "" : this.userData.secondLastName;
            this.formModel.paymentData.email = this.isCompany ? this.clientData.email : this.userData.email;
            this.formModel.paymentData.reEmail = this.isCompany ? this.clientData.email : this.userData.email;
            this.formModel.paymentData.mainPhone = this.isCompany ? this.clientData.mainPhone : this.userData.mainPhone;
            this.formModel.payerHolder = true;
        },
        checkEventBlur: function () {
            //Pone documento y es el mismo que el pagador
            if (this.formModel.paymentData.doc.number.toUpperCase() == this.userOrClient().documentNumber.toUpperCase()) {
                this.readOnlyvalue = true;
                this.dumpInfoFormModel();
            } else {
                this.deleteInfoFormModel();
                this.warningOwnerPayment();
            }
        },
        deleteInfoFormModel: function () {
            this.formModel.paymentData.doc.type = "";
            this.formModel.paymentData.doc.number = "";
            this.formModel.paymentData.name = "";
            this.formModel.paymentData.firstLastName = "";
            this.formModel.paymentData.secondLastName = "";
            this.formModel.paymentData.email = "";
            this.formModel.paymentData.reEmail = "";
            this.formModel.paymentData.mainPhone = "";
            this.formModel.payerHolder = false;
        },
        modify: function () {
            let validator = this.$refs.paymentForm;
            validator.validate().then(async (result) => {
                if (result) {
                    console.debug("Modificación datos de pago:" + this.selectedContract);
                    this.formModel.dataBank.countryTitle = this.$refs.countryiban.countryList.filter(
                        (value) => value.id === this.formModel.dataBank.country
                    )[0].name;
                    let pdfGenerator = new PaymentModifyPdfGenerator(this.$i18n, this.userId, this.clientId, this.selectedContract);
                    pdfGenerator.generatePdf(this.formModel, this.clientData, this.contractDetail, this.selectedContract);
                    await pdfGenerator.uploadPdf();
                    // pdfGenerator.downloadPdf();
                    console.log("PDF ID" + pdfGenerator.uploadedDocumentId);
                    this.$store
                        .dispatch(actionTypes.UPDATE_PAYMENT_INFO, {
                            userId: this.userId,
                            clientId: this.clientId,
                            contractNumber: this.selectedContract,
                            formModel: this.formModel,
                            userData: this.userData,
                            pdf: pdfGenerator.uploadedDocumentId,
                        })
                        .then(() => {
                            this.$notifyFormActionToMatomo(true, "payment_modification");
                            this.$store
                                .dispatch(actionTypes.GET_ALL_CONTRACTS_ACTION, { locale: this.$i18n.locale })
                                .then((value) => {
                                    Swal.fire({
                                        showCloseButton: true,
                                        showCancelButton: true,
                                        text: this.$t("formularios.modificacionPago.sendMessage"),
                                        icon: "success",
                                        confirmButtonText: this.$t("common.okButton"),
                                        cancelButtonText: this.$t("common.descargarPDF"),
                                    }).then(async (result) => {
                                        console.debug("Datos Modificados" + value);
                                        if (!result.isConfirmed) {
                                            pdfGenerator.downloadPdf();
                                        }
                                    });
                                })
                                .catch((reason) => this.launchUpdatePaymentError(reason));
                        })
                        .catch((reason) => {
                            this.$notifyFormActionToMatomo(false, "payment_modification", reason?.response?.data.errorMessage);
                            this.launchUpdatePaymentError(reason);
                        });
                }
            });
        },
        launchUpdatePaymentError: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "UserDataView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: e.response ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
        userOrClient: function () {
            return this.isCompany ? this.clientData : this.userData;
        },
        warningOwnerPayment: function () {
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                html: this.$i18n.tc("ofex.modificarDatosContrato.warningOwnerPayment"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
        validateModifiablePaymentMethod: function () {
            if (this.getContractByContractNumber(this.selectedContract).paymentData.paymentMethodModifiable === false) {
                Swal.fire({
                    icon: "warning",
                    text: this.$t("ofex.modificarDatosPago.contractNotModifiableText"),
                    showConfirmButton: true,
                    confirmButtonText: this.$t("common.okButton"),
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then((result) => {
                    console.debug(result);
                    if (result.isConfirmed) {
                        this.$router.go(-1);
                    }
                });
            }
        },
    },
    mounted() {
        this.selectedContract = this.$route.params.contractId;
        //aqui recibira la prop del numero de contrato que seleccionen de la tabla

        //Comprobamos que el contrato seleccionado no sea inactivo
        if (!this.selectedInactiveContract) {
            this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale, triggerChangeActiveContractLoading: false }).then(() => {
                console.debug("Validando si el método de pago es modificable");
                if (!this.selectedInactiveContract) {
                    this.validateModifiablePaymentMethod();
                }
            });
            this.$store.dispatch(actionTypes.GET_CONTRACT_DETAIL_ACTION, { contractNumber: this.$route.params.contractId });
        }
    },
    watch: {
        "formModel.payerHolder": function (val) {
            if (val) {
                this.dumpInfoFormModel();
            } else {
                this.deleteInfoFormModel();
            }
        },
        "formModel.paymentData.doc.number": function (val) {
            if (val === this.contractDetail.contractHolderData.documentNumber) {
                this.dumpInfoFormModel();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/contract-modification.scss";
</style>
