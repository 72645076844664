"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";

const ACTIVITY_CODE = "CO";
export default class ConsentsModifyPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId) {
        super("ModificaciónConsentimientos.pdf", ACTIVITY_CODE, i18n, userId, clientId, "");
    }

    generatePdf(userData, consents) {
        const POINTS = ": ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("ofex.pdfGenerator.consents.title"), "");
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.consents.title"));
        this.generateLineText(this.i18n.tc("ofex.detalleContrato.email") + POINTS + userData.email);
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.changeOwnership.comunicacionesPromocionales") +
                POINTS +
                (consents.advertising === true ? this.i18n.tc("common.yes") : this.i18n.tc("common.no"))
        );
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.changeOwnership.encuestas") +
                POINTS +
                (consents.surveys === true ? this.i18n.tc("common.yes") : this.i18n.tc("common.no"))
        );
        this.generateLineText(
            this.i18n.tc("ofex.pdfGenerator.changeOwnership.perfilado") +
                POINTS +
                (consents.profiling === true ? this.i18n.tc("common.yes") : this.i18n.tc("common.no"))
        );

        return this.pdf;
    }
}
