<template>
    <div class="m-advance-search">
        <div class="contract-info">
            <div class="text">
                <span class="dark-text">{{ $t("ofex.advanceSearch.tens") }}</span>
                <span class="dark-text" style="font-variant-numeric: lining-nums;">{{ total }}</span>
                <span class="dark-text">{{ $t("ofex.advanceSearch.personesAuth") }}</span>
                <span v-if="total != rows" class="dark-text">{{ $t("ofex.advanceSearch.infoContractFilter", { filter: rows }) }}</span>
            </div>
            <div class="text" v-on:click="show = !show" :class="show ? 'active' : ''">
                <span>{{ $t("ofex.advanceSearch.advanceSearchText") }}</span>
                <font-awesome-icon class="icon" icon="chevron-down"></font-awesome-icon>
            </div>
        </div>
        <transition name="slide-fade">
            <div class="dropdown" v-if="show">
                <div class="dropdown-inner">
                    <div class="search-input-container">
                        <div class="item">
                            <ab-input
                                class="mb-lg-3"
                                v-model="filter.document"
                                name="document"
                                placeholder="NIF/NIE"
                                :label="this.$t('ofex.pdfGenerator.userDataModify.numeroDocumento')"
                            >
                            </ab-input>
                            <ab-input
                                autocomplete="name"
                                class="mb-lg-3"
                                v-model="filter.name"
                                name="name"
                                :label="this.$t('modules.authorizationsViewByPersons.nomCognomsInputLabel')"
                            >
                            </ab-input>
                            <ab-input
                                autocomplete="email"
                                class="mb-lg-3"
                                v-model="filter.email"
                                name="email"
                                :label="this.$t('modules.authorizationsViewByPersons.correoInputLabel')"
                            >
                            </ab-input>
                        </div>
                    </div>
                    <div class="search-button-container">
                        <a href="#" @click.prevent="clearFilter" class="link">{{ $t("ofex.advanceSearch.removeFilters") }}</a>
                        <div class="button-container-inner">
                            <ab-button-ofex :click-event="filterPersons" :text-button="$t('ofex.advanceSearch.search')"></ab-button-ofex>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import AbInput from "../../components/AbInput.vue";
import "vue2-datepicker/locale/ca";
import "vue2-datepicker/locale/es";
import "vue2-datepicker/index.css";

export default {
    name: "",
    components: {
        AbInput,
    },
    props: {
        total: Number,
        rows: Number,
    },
    data() {
        return {
            show: false,
            filter: {
                document: null,
                name: null,
                email: null,
            },
        };
    },
    methods: {
        filterPersons: function () {
            this.$emit("filter-persons", this.filter);
        },
        clearFilter: function () {
            this.filter = {
                document: null,
                name: null,
                email: null,
            };
            this.$emit("clear-filter", this.filter);
        },
    },
    computed: {
        ...mapGetters(["selectedContractTelerecording"]),
        ...mapState(["contracts"]),
    },
};
</script>

<style lang="scss" scoped>
.dark-text {
    color: #333333;
    cursor: text;
}
.contract-info-inner {
    width: 80% !important;
    margin-bottom: 40px !important;
}

.m-advance-search {
    width: 100%;

    .title-module-filter {
        flex-basis: 100%;
        margin-bottom: 15px;
        @include font-size(14);
        @include line-height(16);
        font-weight: $font-primary-bold;
    }
    .m-select-your-contract {
        margin-bottom: 0;
    }
    .contract-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid $link-primary;
        padding-bottom: 20px;

        @include respond-above(sm) {
            margin-bottom: 0;
        }
        .contract-info-inner {
            color: $color-primary;
            font-weight: $font-primary-bold;
            @include font-size(18);
            @include line-height(21);

            @include respond-below(sm) {
                margin-bottom: 10px;
            }
            @include respond-between(sm, lg) {
                margin-bottom: 20px;
            }
            .text-filter {
                margin-left: 15px;
            }
        }
    }
    .text {
        display: flex;
        align-items: center;
        color: $blue-dark;
        font-weight: $font-primary-bold;
        @include font-size(16);
        @include line-height(19);
        cursor: pointer;

        @include respond-below(sm) {
            margin-bottom: 10px;
        }
        .icon {
            transition: all 0.2s ease-in-out;
        }
        &.active {
            .icon {
                transform: rotate(180deg);
            }
        }
        span {
            margin-right: 10px;
        }
        .icon {
            margin-right: 7px;
        }
    }
    .dropdown {
        position: relative;
        flex: 100%;
        background: #f8f8f8;

        .icon {
            width: 17px;
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;

            @include respond-above(sm) {
                width: 10px;
            }
        }
        .dropdown-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px;

            @include respond-above(sm) {
                padding: 20px;
            }
            .search-button-container {
                display: flex;
                flex-basis: 100%;
                justify-content: flex-end;
                align-items: center;

                @include respond-below(sm) {
                    margin-top: 25px;
                }
                .link {
                    color: $button-bg-primary;
                    font-weight: $font-primary-semiBold;
                    @include font-size(16);
                    @include line-height(21);
                    padding: 8px;
                    margin-right: 30px;

                    @include respond-below(sm) {
                        flex: 1;
                        text-align: center;
                    }
                }
                .button-container-inner {
                    @include respond-below(sm) {
                        flex: 1;
                    }
                }
            }
            .search-select-container {
                flex-basis: 100%;
                position: relative;
                margin-bottom: 20px;

                .select-label {
                    margin: 0;
                }
                .select-component {
                    &:first-child {
                        margin-bottom: 20px !important;
                    }
                }
                @include respond-above(lg) {
                    flex-basis: 35%;
                    padding-right: 30px;
                    margin-bottom: 0;
                }
                .info {
                    color: $color-primary;
                    @include font-size(11);
                    @include line-height(18);
                    margin-top: -15px;
                }
                .multiselect__select {
                    height: 32px;
                    text-align: right;
                    right: 0;
                }
            }
            .search-input-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                flex-basis: 100%;
                position: relative;
                margin-bottom: 20px;

                .input-container {
                    margin-bottom: 20px;
                }
                .item {
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 30px;

                    div {
                        margin-right: 1rem;
                        flex-basis: 65%;

                        &:first-child {
                            flex-basis: 30%;
                        }
                    }

                    @include respond-above(lg) {
                        margin-right: 0;
                    }
                }
                @include respond-above(sm) {
                    flex-basis: 100%;
                }
                @include respond-above(lg) {
                    justify-content: space-between;
                    flex-basis: 100%;
                    padding-right: 30px;
                    padding-left: 30px;
                    margin-bottom: 1rem;

                    .item {
                        margin-right: 0;
                    }
                }
            }
            .search-checkbox-container {
                flex-basis: 100%;

                @include respond-above(sm) {
                    flex-basis: 29%;
                }
                @include respond-above(lg) {
                    flex-basis: 19%;
                    padding-left: 30px;
                }
            }
            .checkbox-group {
                margin-bottom: 10px;
            }
        }
    }
    .slide-fade-enter-active {
        max-height: 500px;
        transition: max-height 0.3s ease-in-out;
    }
    .slide-fade-leave-active {
        max-height: 500px;
        transition: max-height 0.3s ease-in-out;
    }
    .slide-fade-enter,
    .slide-fade-leave-to {
        max-height: 0;
        transition: max-height 0.1s ease-in-out;
        opacity: 0;
    }
}
</style>
<style lang="scss">
// .contract-info-inner {
//     width: 100%;
//     .multiselect {
//         width: 100% !important;
//     }
// }
//Si se quita esto se rompe en los demás buscadores avanzados
@media (min-width: 1280px) {
    .contract-info-inner {
        width: 80%;
    }
}
.m-advance-search {
    .special {
        .label {
            color: $special-notice;
            font-weight: $font-primary-bold;
        }
    }
    .periodic {
        .label {
            color: $periodic-notice;
            font-weight: $font-primary-bold;
        }
    }
    .punctual {
        .label {
            color: $punctual-notice;
            font-weight: $font-primary-bold;
        }
    }
    .select-component {
        @include respond-below(sm) {
            align-items: center;
            display: block;

            &:first-child {
                margin-bottom: 20px;
            }
        }
    }
}
</style>
