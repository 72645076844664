<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div>
        <validation-provider
            mode="lazy"
            :name="inputAliasComputed"
            :vid="`${name}-vid`"
            :rules="{ required: true }"
            :debounce="300"
            v-slot="{ errors, classes }"
        >
            <div class="c-datepicker">
                <label class="letraform-new w-100" :for="name">
                    {{ label }}
                </label>
                <div class="div-left-datapicker d-flex">
                    <vue-pikaday
                        :id="name"
                        :ref="name"
                        v-model="modelValue"
                        :options="pikadayOptions"
                        :name="name"
                        :placeholder="dateFormat"
                        class="pl-2 textdnormal minicalendar inputConInfo"
                        :class="errors ? 'inputFormErr' : ''"
                        aria-label="eleccion-fecha-contrato"
                    >
                    </vue-pikaday>
                    <ab-popover class="div-right-datapicker" :popover="popover"></ab-popover>
                </div>
            </div>
            <p v-show="errors.length > 0" class="formTextErr displayYes">{{ errors[0] }}</p>
        </validation-provider>
    </div>
</template>

<script>
import moment from "moment/src/moment";
import AbPopover from "./AbPopover.vue";

const lang = {
    es: {
        previousMonth: "Mes Anterior",
        nextMonth: "Siguiente Mes",
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        weekdaysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    },
    ca: {
        previousMonth: "Mes Anterior",
        nextMonth: "Següent Mes",
        months: ["Gener", "Febrer", "Març", "Abril", "Maig", "Juny", "Juliol", "Agost", "Setembre", "Octubre", "Novembre", "Disembre"],
        weekdays: ["Diumenge", "Dilluns", "Dimarts", "Dimecres", "Dijous", "Divendres", "Dissabte"],
        weekdaysShort: ["Diu", "Dil", "Dim", "Dim", "Dij", "Div", "Dis"],
    },
};

export default {
    components: {
        AbPopover,
    },
    model: {
        prop: "dateValue",
        event: "changedate",
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        inputAlias: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: true,
        },
        popover: {
            type: String,
        },
        dateFormat: {
            type: String,
            default: "DD/MM/YYYY",
        },
        maxDate: {
            type: Date,
            default: () => new Date(),
        },
        minDate: {
            type: Date,
            required: false,
        },
    },
    dateValue: {
        default: "",
    },
    data: function () {
        return {
            pikadayOptions: {
                field: document.getElementById(this.name),
                format: this.dateFormat,
                defaultDate: moment().toDate(),
                i18n: lang[typeof themeDisplay !== "undefined" ? themeDisplay.getLanguageId().substring(0, 2) : "es"],
                maxDate: this.maxDate,
                minDate: this.minDate,
                theme: "ab-datepicker-theme",
                toString(date, format) {
                    return moment(date).format(format);
                },
                parse(dateString, format) {
                    return moment(dateString, format, "es").toDate();
                },
            },
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["dateValue"];
            },
            set: function (newValue) {
                this.$emit("changedate", newValue);
            },
        },
        inputAliasComputed: function () {
            return this.inputAlias ? this.inputAlias : this.label.toLowerCase().replace("*", "").replace(":", "");
        },
    },
    created: function () {
        console.debug("CREATED DATEPICKER VUE");
        moment.locale(this.$i18n.locale);
    },
    mounted: function () {
        console.debug("MOUNTED DATEPICKER VUE");
    },
    methods: {},
};
</script>
<style src="pikaday/css/pikaday.css"></style>
<style lang="scss" scoped>
label {
    line-height: 1px;
}

.c-datepicker {
    @include respond-below(md) {
        margin-bottom: 20px;
    }
}
/*Quitar icono agenda safari */
::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}
.div-left-datapicker {
    width: 100%;
}

.minicalendar {
    background-image: url(../assets/images/minicalendar.png) !important;
    background-position: right 0.5em center !important;
    background-repeat: no-repeat !important;
    background-size: 1.5em auto !important;
    padding-right: 2em !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    height: 40px !important;
    @include respond-above(sm) {
        height: 32px !important;
    }
}

.ab-datepicker-theme pika-select {
    color: #26749f;
}

.datepickerFormErr input {
    border: 1px solid #f48989 !important;
    background-color: #feefef !important;
}
</style>
