"use strict";

import BaseXlsxGenerator from "@/generators/xlsx/base/BaseXlsxGenerator";
import * as xlsx from "xlsx";

export default class ContractListXlsxGenerator extends BaseXlsxGenerator {
    constructor(filename, contractRowsArray, i18n) {
        let rows = contractRowsArray.map((i) => {
            return {
                contrato: i.contrato,
                estado: i.estado,
                telectura: i.telectura ? i18n.tc("common.yes") : i18n.tc("common.no"),
                alias: i.alias ? i.alias : "",
                suministro: i.suministro,
                deuda: i.deuda,
                envio: i.envio,
                favorito: i.favorito ? i18n.tc("common.yes") : i18n.tc("common.no"),
            };
        });

        let contractsSheet = xlsx.utils.json_to_sheet(rows);
        contractsSheet["A1"]["v"] = i18n.tc("ofex.tuContrato.contractNumber");
        contractsSheet["B1"]["v"] = i18n.tc("ofex.tuContrato.estado");
        contractsSheet["C1"]["v"] = i18n.tc("ofex.home.telelectura");
        contractsSheet["D1"]["v"] = i18n.tc("ofex.tuContrato.contractAlias");
        contractsSheet["E1"]["v"] = i18n.tc("ofex.tuContrato.supplyAddress");
        contractsSheet["F1"]["v"] = i18n.tc("ofex.tuContrato.pendingInvoice");
        contractsSheet["G1"]["v"] = i18n.tc("table.envio");
        contractsSheet["H1"]["v"] = i18n.tc("ofex.detalleContrato.toggleActive");

        super(filename, contractsSheet);
    }
}
