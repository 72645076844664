<template>
    <b-container>
        <b-row>
            <b-col>
                <div class="container-wizard">
                    <b-carousel
                        id="carousel-no-animation"
                        ref="myCarousel"
                        no-wrap
                        indicators
                        v-model="carouselIndex"
                        :interval="interval"
                        img-width="302"
                        img-height="302"
                    >
                        <b-carousel-slide
                            caption="Ràpid, fàcil i segur"
                            text="Accedeix més ràpid, fàcil i segur amb la teva petjada/FaceID."
                            img-src="https://www.aiguesdebarcelona.cat/documents/20126/3801803/image+5.png/a9254246-72c4-86c7-a38f-57b976064d37"
                            img-alt="imagen carrusel"
                        ></b-carousel-slide>
                        <b-carousel-slide
                            caption="Informació rellevant"
                            text="Tota la teva informació rellevant (factures, consums) resumida a la pantalla inicial."
                            img-src="https://www.aiguesdebarcelona.cat/documents/20126/3801803/image+6.png/20dcc5a9-796c-a2ef-a26a-b5a8cab6fdc2"
                            img-alt="imagen carrusel"
                        >
                        </b-carousel-slide>
                        <b-carousel-slide
                            caption="Totes les teves factures"
                            text="Consulta totes les teves factures dels últims 3 anys i descarrega-te-les al dispositiu. "
                            img-src="https://www.aiguesdebarcelona.cat/documents/20126/3801803/image+7.png/370dd2db-90c6-d695-bb94-3407914dbe54"
                            img-alt="imagen carrusel"
                        >
                        </b-carousel-slide>
                        <b-carousel-slide
                            caption="Gestió ràpida"
                            text="Realitza totes les gestions des d'un mateix lloc."
                            img-src="https://www.aiguesdebarcelona.cat/documents/20126/3801803/image+8.png/9deeb28f-f142-53ce-e876-2428bdc36710"
                            img-alt="imagen carrusel"
                        >
                        </b-carousel-slide>
                        <b-carousel-slide
                            caption="Analitza i compara"
                            text="Analitza i compara les lectures diàries i horàries."
                            img-src="https://www.aiguesdebarcelona.cat/documents/20126/3801803/image+9.png/b12c355f-388d-0724-6481-85d5aac29ce6"
                            img-alt="imagen carrusel"
                        >
                        </b-carousel-slide>
                        <b-carousel-slide
                            caption="Notificacions d'interès"
                            text="No us perdeu cap informació rellevant amb les notificacions de l'app. "
                            img-src="https://www.aiguesdebarcelona.cat/documents/20126/3801803/image+4.png/d1ad24e9-940a-2a8b-8045-b879626d9c6d"
                            img-alt="imagen carrusel"
                        >
                        </b-carousel-slide>
                    </b-carousel>
                    <div class="container-button">
                        <a v-if="carouselIndex === 5" class="btn btn-primary" @click="gotoLogin()">Finalizar</a>
                        <a v-else class="btn btn-primary" @click="next()">Següent</a>
                        <a class="link" @click="gotoLogin()">Descartar</a>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import * as viewMappingTypes from "@/router/view-mapping-types";

export default {
    name: "Wizard",
    data: function () {
        return {
            carouselIndex: 0,
            interval: 0,
        };
    },
    methods: {
        next() {
            this.$refs.myCarousel.next();
        },
        gotoLogin() {
            this.$router.push({ name: viewMappingTypes.LOGIN_VIEW_MAPPING.name });
        },
    },
};
</script>

<style lang="scss">
#app {
    &.wizard {
        background-color: $bg-primary;

        &.native {
            main {
                padding-top: max(20px, env(safe-area-inset-top));

                .container-wizard {
                    margin-top: 0;
                }
            }
        }
    }
}

.container-wizard {
    background: $color-lightest;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    width: 350px;
    padding: 24px;
    margin: 75px auto;

    .carrusel {
        margin-bottom: 32px;
    }

    img {
        margin-bottom: 15px;
    }

    .carousel-caption {
        position: relative;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding-bottom: 0;

        h3 {
            color: $bg-primary;
            @include font-size(18);
            @include line-height(24);
            font-weight: $font-primary-bold;
        }
        p {
            color: $bg-primary;
            @include font-size(13);
            @include line-height(18);
            font-weight: $font-primary;
            padding: 0 15px;
            margin-bottom: 25px;
        }
    }

    .carousel-indicators {
        position: relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin-bottom: 30px;

        li {
            width: 8px;
            height: 8px;
            border-radius: 2px;
            background: $input-read;
            border: 0;
            margin-right: 8px;
            margin-left: 8px;
            pointer-events: none;

            &.active {
                background: $bg-primary;
            }
        }
    }

    .container-button {
        margin-top: 15px;

        .btn {
            margin-bottom: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 13px 20px;
            border-radius: 8px;
            @include font-size(16);
            @include line-height(18);
            font-weight: $font-primary-semiBold;
            cursor: pointer;
            text-align: center;
            -webkit-appearance: none;

            &.btn-primary {
                @include font-size(16);
                @include line-height(24);
                font-weight: $font-primary-bold;
                background-color: $bg-primary;
                border: 1px solid $bg-primary;
                color: $button-text-primary;

                &:hover,
                &:not(:disabled):not(.disabled):active {
                    background-color: $button-text-primary;
                    color: $bg-primary;
                    border: 1px solid $button-bg-primary;
                    box-shadow: none;
                }
                &:hover:disabled {
                    background-color: $blue-darker;
                    color: $color-lightest;
                    cursor: not-allowed;
                }
            }
        }
        .link {
            cursor: pointer;
            color: $border-table;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 46px;
            @include font-size(16);
            @include line-height(24);
            font-weight: $font-primary-bold;

            &:hover {
                color: $bg-primary;
            }
        }
    }
}
</style>
