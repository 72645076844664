<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <ab-autocompletable-input
        v-model="modelValue"
        :name="name"
        :label="$t('postalCode.label')"
        :input-alias="inputAlias"
        :placeholder="$t('common.seleccionar')"
        :validation="{ required: isRequired, cp: { municipality: city, apiDomain: apiDomain } }"
        :data-list="postalCodeList"
        :readonly="readonly"
        :min-chars="1"
        :auto-first="true"
        :data-control="postalCodeDataControl"
    ></ab-autocompletable-input>
</template>

<script>
import AbAutocompletableInput from "./AbAutocompletableInput";

export default {
    components: {
        AbAutocompletableInput,
    },
    model: {
        prop: "selectValue",
        event: "change",
    },
    props: {
        title: String,
        name: String,
        inputAlias: String,
        popover: String,
        isRequired: {
            default: true,
            type: Boolean,
        },
        city: {
            default: "",
            type: String,
        },
        readonly: {
            default: false,
            type: Boolean,
        },
        apiDomain: {
            required: false,
            default: "",
            type: String,
        },
    },
    data: function () {
        return {
            postalCodeList: [],
            postalCodeDataControl: function (item) {
                return { label: item.code + " (" + item.city + ")", value: item.code.toUpperCase() };
            },
        };
    },
    computed: {
        modelValue: {
            get: function () {
                return this.$attrs["selectValue"];
            },
            set: function (newValue) {
                this.$emit("change", newValue);
            },
        },
    },
    watch: {
        city: {
            immediate: true,
            handler(newValue, oldValue) {
                console.debug("City changed from " + oldValue + " to " + newValue);
                this.calculatePostalCodes(newValue);
            },
        },
    },
    methods: {
        calculatePostalCodes: async function (city) {
            var vueContext = this;
            this.$http
                .get(`${this.apiDomain}/cp`, {
                    params: {
                        city: city,
                    },
                })
                .then(
                    function (response) {
                        vueContext["postalCodeList"] = response.data;
                    },
                    function (error) {
                        console.error(error);
                    }
                );
        },
    },
};
</script>
