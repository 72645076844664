import qs from "qs";
import store from "@/store/store";
import * as mutationTypes from "@/store/mutation-types";
import { SET_REST_ERROR } from "@/store/mutation-types";
import BaseOfexServiceClient from "./BaseOfexServiceClient";
const CUSTOMERS_BASE_URL = process.env.VUE_APP_API_CUSTOMERS_BASE_URL;

export default {
    getInternalUserId: async function (userId, clientId, lang = "es") {
        try {
            console.log("Obteniendo ID interno del usuario en Salesforce");
            let abRestClient = new BaseOfexServiceClient(false).abRestClient;
            let response = await abRestClient.get(`${CUSTOMERS_BASE_URL}/customers/id`, {
                params: {
                    clientId: clientId,
                    userId: userId,
                    lang: lang,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log(response.data);
                //Modificamos el usuario
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.warn("No se ha podido registrar el usuario en Marketing Cloud");
            console.error(e.response);
        }
    },
    modifyUser: async function (userId, clientId, userData, pdf, lang = "es") {
        try {
            console.log("Proceso modificación de usuario");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.put(
                `${CUSTOMERS_BASE_URL}/customers/users/${userId}`,
                {
                    language: lang,
                    UserData: {
                        existingUser: true,
                        firstName: userData.firstName,
                        firstLastName: userData.firstLastName,
                        secondLastName: userData.secondLastName,
                        documentNumber: userData.documentNumber,
                        documentType: userData.documentType,
                        mainPhone: userData.mainPhone,
                        email: userData.email,
                        pdf: pdf,
                        language: userData.language,
                        fsp: userData.fsp,
                        legalWarning: userData.legalWarning,
                        invoiceNotification: userData.invoiceNotification,
                        favoriteContracts: userData.favoriteContracts,
                        favoriteTransactions: userData.favoriteTransactions,
                        twitter: userData.twitter,
                        lastAccessDate: userData.lastAccessDate,
                    },
                },
                {
                    params: {
                        clientId: clientId,
                        userId: userId,
                        lang: lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log(response.data);
                //Modificamos el usuario
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    modifyClient: async function (id, clientId, client, pdf, lang = "es") {
        try {
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: true });
            console.log("Proceso modificación de cliente " + clientId);
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.put(
                `${CUSTOMERS_BASE_URL}/customers/clients/${id}`,
                {
                    language: client.language,
                    advertising: client.advertising,
                    surveys: client.surveys,
                    profiling: client.profiling,
                    mainPhone: client.mainPhone,
                    phone: client.phone,
                    email: client.email,
                    fax: client.fax,
                    pdf: pdf,
                    twitter: client.twitter,
                },
                {
                    params: {
                        clientId: clientId,
                        userId: id,
                        lang: lang,
                    },
                    //https://github.com/axios/axios/issues/604#issuecomment-321460450
                    paramsSerializer: function (params) {
                        return qs.stringify(params, { arrayFormat: "repeat" });
                    },
                }
            );
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log(response.data);
                //Modificamos el cliente
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    deleteUser: async function (userId, clientId, userData, reason, pdfDocumentId, lang = "es") {
        try {
            console.log("Proceso inactivación de usuario");
            let abRestClient = new BaseOfexServiceClient().abRestClient;
            let response = await abRestClient.delete(`${CUSTOMERS_BASE_URL}/customers/${userId}`, {
                params: {
                    clientId: clientId,
                    userId: userId,
                    documentType: userData.documentType,
                    pdf: pdfDocumentId,
                    lang: lang,
                    reason: reason,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            });
            if (response.status === 200 || response.status === 202 || response.status === 204) {
                console.log(response.data);
                //Borramos el usuario
                return response.data;
            }
            //si la respuesta no ha sido 200 lanzamos un error
            throw response.statusText;
        } catch (e) {
            console.error(e.response);
            store.commit(SET_REST_ERROR, { error: e.response.data.errorMessage });
            store.commit(mutationTypes.SET_LOADING_MUTATION, { isLoading: false });
        }
    },
    //En esta operación manejaremos el error externamente
    updatePassword: async function (userId, clientId, currentPassword, newPassword, lang = "es") {
        console.log("Cambiando contraseña del usuario");
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.patch(
            `${CUSTOMERS_BASE_URL}/customers/clients/${userId}/credentials`,
            {
                currentPassword: currentPassword,
                newPassword: newPassword,
            },
            {
                headers: {
                    "Accept-Language": lang,
                },
                params: {
                    clientId: clientId,
                    userId: userId,
                    lang: lang,
                },

                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            console.log(response.data);
            //Borramos el usuario
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
    resetPassword: async function (userId, clientId, email, pdf, lang = "es") {
        console.debug("Reseteando contraseña del usuario");
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.patch(
            `${CUSTOMERS_BASE_URL}/customers/clients/${userId}/credentials/reset`,
            {
                email: email,
                pdf: pdf,
            },
            {
                headers: {
                    "Accept-Language": lang,
                },
                params: {
                    clientId: clientId,
                    userId: userId,
                    lang: lang,
                },

                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        console.log("Error" + response.statusText);
        throw response.statusText;
    },
    historyClient: async function (userId, clientId, lang) {
        console.debug("Proceso histórico de acciones de usuario " + userId);
        let abRestClient = new BaseOfexServiceClient().abRestClient;
        let response = await abRestClient.get(
            `${CUSTOMERS_BASE_URL}/customers/users/${userId}/history`,

            {
                params: {
                    userId: userId,
                    clientId: clientId,
                    lang: lang,
                    pdf: 0,
                },
                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            //Obtenemos histórico
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
    updateLegal: async function (userId, clientId, legalUrl, legalfsp, contentForm, userEmail, lang = "es") {
        console.log("Updating legal");
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.patch(
            `${CUSTOMERS_BASE_URL}/customers/${userId}/legals`,
            {
                legalUrl: legalUrl,
                fspUrl: legalfsp,
                userEmail: userEmail,
                contentForm: contentForm,
            },
            {
                params: {
                    clientId: clientId,
                    userId: userId,
                    lang: lang,
                },

                //https://github.com/axios/axios/issues/604#issuecomment-321460450
                paramsSerializer: function (params) {
                    return qs.stringify(params, { arrayFormat: "repeat" });
                },
            }
        );
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        console.log("Error" + response.statusText);
        throw response.statusText;
    },
};
