"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
const ACTIVITY_CODE = "PS";
export default class PaymentSummaryPdfGeneator extends BaseGenerator {
    constructor(i18n, userId, clientId, contract) {
        super("PagosFacturas.pdf", ACTIVITY_CODE, i18n, userId, clientId, contract);
    }

    generatePdf(invoices, totalAmount, paymentResult) {
        console.log(`Generando pdf de pago ${paymentResult ? "satisfactorio" : "erroneo"}`);
        const SPACE = " ";
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(
            this.i18n.tc("ofex.pdfGenerator.paymentSummary.title") +
                `${paymentResult ? this.i18n.tc("ofex.pdfGenerator.paymentSummary.satisfactorio") : this.i18n.tc("ofex.pdfGenerator.paymentSummary.erroneo")}`,
            this.i18n.tc("ofex.pdfGenerator.paymentSummary.desglose")
        );
        this.generatePdfSection(this.i18n.tc("ofex.pdfGenerator.paymentSummary.pagoRealizado"));
        this.generateLineText(this.i18n.tc("ofex.pdfGenerator.paymentSummary.importe") + SPACE + `${totalAmount.toFixed(2)}`);

        var head = [
            [
                "Factura",
                `${this.i18n.tc("ofex.pdfGenerator.paymentSummary.fechaEmision")}`,
                `${this.i18n.tc("ofex.pdfGenerator.paymentSummary.volumen")}`,
                `${this.i18n.tc("ofex.pdfGenerator.paymentSummary.importeLabel")}`,
            ],
        ];
        var body = [invoices.length];
        invoices.forEach((i, index) => (body[index] = [`${i.invoiceYear}${i.invoiceNumber}`, `${i.issueDate}`, `${i.consumptionVolume} m3`, `${i.amount} €`]));

        this.generateAutoTable(head, body, 4);
        return this.pdf;
    }
}
