<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container>
        <ab-loading v-model="isLoading" :opacity="0.95" :full-page="true" />
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.authorizationsView.title')"></ab-main-title>
                <p class="subtitle">{{ $t("ofex.authorizationsView.subtitle") }}</p>
                <ab-loading v-if="tableLoading" class="small-loader" v-model="tableLoading" :opacity="0.95" :full-page="false" />
                <ab-dropdown-section v-else v-model="section1Visible" collapse-id="authorizationSection1" :label="$t('ofex.authorizationsView.section1Text')">
                    <p class="authorizations-section-subtitle">{{ $t("ofex.authorizationsView.section1Subtitle") }}</p>
                    <ab-authorizations-advance-search-by-persons
                        v-show="true"
                        ref="authorizationsAdvanceSearch"
                        @filter-persons="executeFilter"
                        @clear-filter="resetFilter"
                        :total="this.authorizations.length"
                        :rows="tableRows.length"
                    />
                    <vue-good-table
                        v-if="authorizations"
                        ref="authorizationsTable"
                        :columns="columns"
                        :rows="tableRows"
                        style-class="vgt-table"
                        :select-options="{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
                        :sort-options="{
                            enabled: true,
                            initialSortBy: { field: 'authorizationDate', type: 'desc' },
                        }"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            perPageDropdown: [10, 20, 30],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: this.$i18n.tc('table.siguiente'),
                            prevLabel: this.$i18n.tc('table.atras'),
                            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                            ofLabel: 'de',
                            allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                            label: 'selector-de-paginas',
                            name: 'perPageSelect',
                        }"
                    >
                        <div slot="emptystate" class="text-center textdnormal">
                            {{ $t("ofex.tuContrato.noPersons") }}
                        </div>
                    </vue-good-table>

                    <div class="row mt-3 mb-3 justify-content-end">
                        <div class="col-lg-4 mb-4">
                            <ab-button-ofex :text-button="$t('ofex.buttonsOfex.revokeAuthorization')" :click-event="revokeAuthorizations"></ab-button-ofex>
                        </div>
                        <div class="col-lg-6">
                            <ab-button-ofex
                                :text-button="$t('ofex.buttonsOfex.addAuthorization')"
                                :disabled="!hasAnyActiveContract"
                                :click-event="addNewAuthorization"
                            ></ab-button-ofex>
                        </div>
                    </div>
                </ab-dropdown-section>

                <ab-dropdown-section v-model="section2Visible" collapse-id="authorizationSection2" :label="$t('ofex.authorizationsView.section2Text')">
                    <p class="authorizations-section-subtitle">{{ $t("ofex.authorizationsView.section2Subtitle") }}</p>

                    <div class="group-authorizations-inner">
                        <ab-dropdown-section
                            v-model="subsection2PerContractVisible"
                            collapse-id="subsectionPerContractDropdown"
                            :label="$t('ofex.authorizationsView.subsectionPerContractDropdownText')"
                        >
                            <ab-authorizations-group-by-contract />
                        </ab-dropdown-section>

                        <ab-dropdown-section
                            v-model="subsection2PerPersonVisible"
                            collapse-id="subsectionPerPersonDropdown"
                            :label="$t('ofex.authorizationsView.subsectionPerPersonDropdownText')"
                        >
                            <ab-authorizations-group-by-persons />
                        </ab-dropdown-section>
                    </div>
                </ab-dropdown-section>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.beneficiosTelelectura.name')"
                    :href-left="$t('ofex.relatedContents.beneficiosTelelectura.url')"
                    :image-left="$t('ofex.relatedContents.beneficiosTelelectura.image')"
                    :name-center="$t('ofex.relatedContents.consellEstalvi.name')"
                    :href-center="$t('ofex.relatedContents.consellEstalvi.url')"
                    :image-center="$t('ofex.relatedContents.consellEstalvi.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import AbLeftNavBar from "../modules/common/AbLeftNavBar.vue";
import AbLoading from "../components/AbLoading";

import * as actionTypes from "../store/action-types";

import { mapState, mapGetters } from "vuex";
import { VueGoodTable } from "vue-good-table";
import Swal from "sweetalert2";
import * as viewMappingTypes from "@/router/view-mapping-types";
import AbAuthorizationsGroupByContract from "@/modules/authorizations/AbAuthorizationsGroupByContract";
import AbAuthorizationsGroupByPersons from "@/modules/authorizations/AbAuthorizationsGroupByPersons";
import AbAuthorizationsAdvanceSearchByPersons from "@/modules/authorizations/AbAuthorizationsAdvanceSearchByPersons";

export default {
    name: "AuthorizationsView",
    components: {
        AbLeftNavBar,
        AbLoading,
        AbAuthorizationsGroupByContract,
        AbAuthorizationsGroupByPersons,
        AbAuthorizationsAdvanceSearchByPersons,
        VueGoodTable,
    },
    data: function () {
        return {
            section1Visible: true,
            section2Visible: false,
            subsection2PerContractVisible: false,
            subsection2PerPersonVisible: false,
            selected: [],
            selectAll: false,
            filteredAuthorizations: null,
            tableSelectOptions: {
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: "custom-class",
                selectionText: "Autorizaciones Seleccionadas",
                clearSelectionText: this.$t("ofex.tuContrato.limpiar"),
                disableSelectInfo: true, // disable the select info panel on top
            },
            tableLoading: true,
        };
    },

    methods: {
        executeFilter: function (filter) {
            if (filter.document) {
                [...this.filteredAuthorizations] = this.authorizations.filter((person) => person.documentNumber.toLowerCase() == filter.document.toLowerCase());
            }
            if (filter.name) {
                [...this.filteredAuthorizations] = this.authorizations.filter(
                    (person) => person.firstName.toLowerCase().indexOf(filter.name.toLowerCase()) !== -1
                );
            }
            if (filter.email) {
                [...this.filteredAuthorizations] = this.authorizations.filter(
                    (person) => person.email.toLowerCase().indexOf(filter.email.toLowerCase()) !== -1
                );
            }
        },
        resetFilter: function () {
            this.filteredAuthorizations = [...this.authorizations];
        },
        addNewAuthorization: function () {
            this.$router.push({ name: viewMappingTypes.NEW_AUTHORIZATION_VIEW_MAPPING.name });
        },
        revokeAuthorizations: function () {
            if (this.$refs.authorizationsTable.selectedRows.length == 0) {
                Swal.fire({
                    text: this.$i18n.tc("ofex.authorizationsView.noAuthorizationSelected"),
                    icon: "warning",
                    confirmButtonText: "OK",
                    showCancelButton: false,
                });
                return;
            }

            Swal.fire({
                text: this.$i18n.tc("ofex.authorizationsView.revokeConfirmation"),
                icon: "warning",
                confirmButtonText: "OK",
                showCancelButton: true,
                cancelButtonText: this.$i18n.tc("ofex.common.unsavedFormDataCancelText"),
            }).then((result) => {
                if (result.isConfirmed) {
                    let documentNumbers = this.$refs.authorizationsTable.selectedRows.map((row) => row.documentNumber);
                    this.$store
                        .dispatch(actionTypes.DELETE_AUTHORIZATIONS_ACTION, {
                            documentNumbers: documentNumbers,
                            authorizationType: "GENERAL",
                            locale: this.$i18n.locale,
                        })
                        .then(() => {
                            Swal.fire({
                                text: this.$i18n.tc("ofex.authorizationsView.confirmedAuthorizationRevoked"),
                                icon: "success",
                                confirmButtonText: "OK",
                                showCancelButton: false,
                            });
                        })
                        .catch(() => {
                            Swal.fire({
                                text: this.$i18n.tc("ofex.authorizationsView.missedAuthorizationRevoked"),
                                icon: "error",
                                confirmButtonText: "OK",
                                showCancelButton: false,
                            });
                        });
                }
            });
        },
        mapAuthorizationToTableRow: function (entry) {
            return {
                documentNumber: entry.documentNumber,
                fullName: `${entry.firstName} ${entry.firstLastName} ${entry.secondLastName}`,
                email: entry.email,
                phone: entry.phone,
                authorizationDate: entry.authorizationDate,
            };
        },
    },
    computed: {
        ...mapState(["authorizations", "isLoading"]),
        ...mapGetters(["hasAnyActiveContract"]),
        selectedRows: function () {
            //TODO esto devuelve undefined, ver la razón
            return this.$refs.authorizationsTable && this.$refs.authorizationsTable.selectedRows;
        },
        tableRows: function () {
            return this.filteredAuthorizations !== null
                ? this.filteredAuthorizations.map(this.mapAuthorizationToTableRow)
                : this.authorizations.map(this.mapAuthorizationToTableRow);
        },
        columns: function () {
            return [
                {
                    label: "NIF / NIE",
                    field: "documentNumber",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$i18n.t("modules.authorizationsViewByPersons.nomCognomsInputLabel"),
                    field: "fullName",
                },
                {
                    label: this.$i18n.t("modules.authorizationsViewByPersons.correoInputLabel"),
                    field: "email",
                },

                {
                    label: this.$i18n.t("ofex.authorizationsView.phoneColumnName"),
                    field: "phone",
                },
                {
                    label: this.$i18n.t("ofex.tuContrato.fechaAlta"),
                    field: "authorizationDate",
                },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_ACTIVE_CONTRACTS_ACTION, {
            locale: this.$i18n.locale,
            triggerLoading: false,
            triggerChangeActiveContractLoading: false,
        });
        this.$store.dispatch(actionTypes.GET_AUTHORIZATIONS_BY_USER_ACTION, { locale: this.$i18n.locale, triggerLoading: false }).then(() => {
            this.tableLoading = false;
        });
    },
};
</script>

<style lang="scss" scoped>
.authorizations-section-subtitle {
    @include font-size(14);
    @include line-height(16);
    color: $blue-dark;
    font-weight: $font-primary-semiBold;
    margin-top: 1rem;
}
.small-loader {
    height: 200px;
    z-index: 1;
}
.group-authorizations-inner {
    padding-left: 40px;
}
</style>
