<template>
    <b-container class="p-settings-detail-view">
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" :loader-text="$t('ofex.loadingText.home')"></ab-loading>
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.fingerprintSettings.configuracionApp')"></ab-main-title>
                <p class="subtitle">{{ $t("ofex.fingerprintSettings.modificarHuella") }}</p>
                <div class="dropdown-section" role="tablist">
                    <b-card no-body>
                        <b-card-header header-tag="header" role="tab">
                            <div class="dropdown-section-inner" block v-b-toggle.accordion-1 variant="info">
                                <div class="text">
                                    <div variant="tab" class="text-inner">
                                        <font-awesome-icon class="icon" icon="chevron-circle-right"></font-awesome-icon>
                                        <span class="mr-2">{{ $t("ofex.fingerprintSettings.accesoHuella") }}</span>
                                    </div>
                                </div>
                            </div>
                        </b-card-header>
                        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                            <div class="col text-center mb-lg-0" v-show="hasFingerprint">
                                <img class="biometric-icon" alt="icono acceso biométrico" :src="authImageSrc" />
                            </div>
                            <div class="container-buttons mb-3">
                                <ab-button-ofex
                                    class="caja-button"
                                    :text-button="$t('ofex.buttonsOfex.huellaOff')"
                                    :click-event="checkExistingCredentials"
                                ></ab-button-ofex>
                            </div>
                        </b-collapse>
                    </b-card>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { mapState } from "vuex";
import "sweetalert2/src/sweetalert2.scss";
import { NativeBiometric } from "capacitor-native-biometric";
import * as viewMappingTypes from "../router/view-mapping-types";
import AbButtonOfex from "../components/AbButtonOfex.vue";
import Swal from "sweetalert2";

export default {
    name: "AppSettingsView",
    components: { AbButtonOfex },
    data: function () {
        return {
            viewMappingTypes: viewMappingTypes,
            biometryType: 3,
            credentials: null,
            appRequiredVersion: null,
        };
    },
    methods: {
        checkExistingCredentials: async function () {
            const result = await NativeBiometric.isAvailable();
            if (result && result.isAvailable) {
                // Verificar que si existen credenciales biométricas las borre
                const credentials = await NativeBiometric.getCredentials({
                    server: "www.aiguesdebarcelona.cat",
                });
                if (credentials) {
                    await NativeBiometric.deleteCredentials({
                        server: "www.aiguesdebarcelona.cat",
                    });
                    Swal.fire({
                        titleText: this.$t("ofex.fingerprint.huellaDesactivada"),
                        icon: "success",
                    });
                } else {
                    this.credentials = null;
                    Swal.fire({
                        titleText: this.$t("ofex.fingerprint.access"),
                        icon: "warning",
                    });
                }
            }
        },
        hasFingerprint: function () {
            return this.biometryType === 1 || this.biometryType === 3 || this.biometryType === 6;
        },
    },
    computed: {
        ...mapState(["isLoading", "loginError"]),
        authImageSrc: function () {
            switch (this.biometryType) {
                case 1:
                case 3:
                    return require("../assets/images/svg/huella.svg");
                case 2:
                    return require("../assets/images/svg/face-id.svg");
                case 6:
                    return require("../assets/images/svg/multiple-auth.svg");
                default:
                    return undefined;
            }
        },
    },
};
</script>
<style lang="scss">
.p-settings-detail-view {
    .container-buttons {
        display: flex;
        justify-content: center;

        .btn {
            width: auto;
        }
        @include respond-below(sm) {
            flex-wrap: wrap;

            .btn {
                width: 100%;
                margin-bottom: 15px;
            }
            .link-btn {
                width: 100%;
                text-align: center;
                order: 2;
                margin-right: 0;
            }
        }
    }
}
.caja-button {
    margin: 20px;
}
.biometric-icon {
    justify-content: center;
    align-items: center;
}
</style>
<style lang="scss">
.unsaved-text {
    color: $warning-color;
}
</style>
