<template>
    <div class="public-form">
        <ab-loading v-model="isLoading"></ab-loading>
        <div v-if="curStep == 1">
            <p class="subtitle pb-2">{{ $t("ofex.forms.readMeter.subtitle") }}</p>
            <p class="subtitle pb-2">{{ $t("ofex.forms.readMeter.camposObligatorios") }}</p>
            <ValidationObserver ref="readMeterForm" v-slot="{ errors, invalid }">
                <ab-error-header v-if="$refs.readMeterForm" :error-list="validationErrors"></ab-error-header>
                <ab-error-header :error-list="submitErrorList"></ab-error-header>
                <section>
                    <div class="mb-4 line-top pt-2">
                        <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                        <span class="item-collapse">{{ $t("ofex.forms.readMeter.datosPersonales") }}</span>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3" v-if="!contractNumber">
                            <ab-select
                                v-model="formModel.contactData.idWith"
                                validation-type-name="identificacion-mediante"
                                name="identificacion-mediante"
                                placeholder="Seleccionar"
                                v-bind:validation="{ required: true }"
                                v-bind:data-list="select.identificationWith"
                                :label="$t('ofex.forms.readMeter.identificacionMediante')"
                            />
                        </div>
                        <div class="col-12 col-lg-3" v-if="formModel.contactData.idWith === 'contrato'">
                            <ab-input
                                name="identificacionContratoRead"
                                :placeholder="$t('ofex.forms.readMeter.phNumContrato')"
                                :label="$t('ofex.forms.readMeter.numContrato')"
                                :readonly="contractNumber ? true : false"
                                v-bind:validation="{ required: true, min: 1, max: 50 }"
                                v-model="formModel.contactData.identificationContract"
                                validation-type-name="titular-contrato-tipo-doc"
                            />
                        </div>
                        <div class="col-12 col-lg-3" v-if="formModel.contactData.idWith === 'factura'">
                            <ab-input
                                name="identificacionFactura"
                                :placeholder="$t('ofex.forms.readMeter.phNumFactura')"
                                :label="$t('ofex.forms.readMeter.numFactura')"
                                v-bind:validation="{ required: true, min: 1, max: 50 }"
                                v-model="formModel.contactData.identificationInvoice"
                                validation-type-name="titular-contrato-tipo-doc"
                            />
                        </div>
                    </div>
                    <!-- tipo de documento y eso  -->
                    <ab-id-type-number
                        v-if="!contractNumber"
                        v-model="formModel.contactData.doc"
                        validation-type-name="titular-contrato-tipo-doc"
                        :allow-cif="true"
                        :number-input-alias="$t('alias.idTypeNumber.numeroDocumentoUser')"
                        validation-number-name="tipo-documento"
                        class="mb-5"
                    >
                    </ab-id-type-number>
                    <div class="row mb-5" v-if="contractNumber">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                name="document-type"
                                placeholder=""
                                :label="$t('ofex.forms.readMeter.tipoDocumento')"
                                :readonly="true"
                                v-bind:validation="{ required: false, min: 1, max: 50 }"
                                v-model="formModel.contactData.doc.type"
                                validation-type-name="titular-contrato-tipo-doc"
                            />
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                name="document-number"
                                placeholder=""
                                :readonly="true"
                                :label="$t('ofex.forms.readMeter.numDocumento')"
                                v-bind:validation="{ required: false, min: 1, max: 50 }"
                                v-model="formModel.contactData.doc.number"
                                validation-type-name="titular-contrato-documento-doc"
                            />
                        </div>
                    </div>
                    <!-- Nombre y apellidos -->
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="given-name"
                                v-model="formModel.contactData.name"
                                name="nombre-datos-contacto"
                                :readonly="contractNumber ? true : false"
                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.nombre')"
                                v-bind:validation="{ required: true, max: 50 }"
                                v-bind:label="$t('ofex.bajaContratoDetalle.nombre')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="family-name"
                                v-model="formModel.contactData.lastName"
                                name="primer-apellido-datos-contacto"
                                :readonly="contractNumber ? true : false"
                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.primerApellido')"
                                v-bind:validation="{ required: true, max: 50 }"
                                v-bind:label="$t('ofex.bajaContratoDetalle.primerApellido')"
                            ></ab-input>
                        </div>
                        <div class="col-12 col-lg-3" v-if="!contractNumber || userData.secondLastName != ''">
                            <ab-input
                                autocomplete="family-name"
                                v-model="formModel.contactData.secondLastName"
                                name="segundo-apellido-datos-contacto"
                                :readonly="contractNumber ? true : false"
                                v-bind:placeholder="$t('ofex.bajaContratoDetalle.placeholders.segundoApellido')"
                                v-bind:validation="{ required: false, max: 50 }"
                                v-bind:label="$t('ofex.bajaContratoDetalle.segundoApellido')"
                            ></ab-input>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="tel-national"
                                v-model="formModel.contactData.mainPhone"
                                name="telefono-principal-datos-contacto"
                                :readonly="contractNumber ? true : false"
                                v-bind:placeholder="$t('ofex.forms.reclamations.placeholders.telefono')"
                                :popover="$t('ofex.forms.reclamations.prefeMovil')"
                                v-bind:validation="{ required: true, spanishPhone: true }"
                                v-bind:label="$t('ofex.forms.reclamations.telefono')"
                            >
                            </ab-input>
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                autocomplete="email"
                                v-model="formModel.contactData.email"
                                name="email-datos-contacto"
                                :readonly="contractNumber ? true : false"
                                placeholder="ejemplo@dominio.com"
                                v-bind:validation-delay="200"
                                v-bind:validation="{
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                }"
                                v-bind:label="$t('ofex.forms.reclamations.email')"
                            >
                            </ab-input>
                        </div>
                        <div class="col-12 col-lg-3" v-if="!contractNumber">
                            <ab-input
                                autocomplete="email"
                                v-model="formModel.contactData.emailConfirmation"
                                name="email-confirmation-datos-contacto"
                                placeholder="ejemplo@dominio.com"
                                v-bind:validation-delay="200"
                                v-bind:validation="{
                                    required: true,
                                    email: true,
                                    regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                    is: formModel.contactData.email,
                                }"
                                v-bind:label="$t('ofex.forms.reclamations.repiteMail')"
                                :pasteable="false"
                            >
                            </ab-input>
                        </div>
                    </div>
                    <p v-if="!contractNumber" class="link-owner mb-5 d-inline-flex" @click="exitForm()">{{ $t("ofex.UserData.modifyClient") }}</p>
                </section>
                <section>
                    <div class="mb-3 line-top pt-2">
                        <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                        <span class="item-collapse subtitle">{{ $t("ofex.forms.readMeter.datosLectura") }}</span>
                    </div>
                    <div class="row mb-5">
                        <div class="col-12 col-lg-3">
                            <ab-datepicker
                                v-model="formModel.dataRead.dateRead"
                                name="fechaLectura"
                                v-bind:validation="{ required: true }"
                                v-bind:input-alias="$t('ofex.forms.readMeter.fechaLectura')"
                                :label="$t('ofex.forms.readMeter.fechaLectura')"
                                date-format="DD-MM-YYYY"
                            />
                        </div>
                        <div class="col-12 col-lg-3">
                            <ab-input
                                v-model="formModel.dataRead.read"
                                name="lectura-enviada"
                                placeholder="Indica lectura actual"
                                :popover="$t('ofex.forms.readMeter.popoverLecturaEnviada')"
                                :validation="{ required: true }"
                                validation-mode="aggressive"
                                :label="$t('ofex.forms.readMeter.lecturaEnviada')"
                            >
                            </ab-input>
                        </div>
                    </div>
                </section>
                <section>
                    <!-- politica de privacidad -->
                    <div class="line-top pt-2 mb-3">
                        <font-awesome-icon class="icon mr-2" icon="chevron-circle-right"></font-awesome-icon>
                        <span class="item-collapse">{{ $t("ofex.forms.readMeter.politicaPrivacidad") }}</span>
                    </div>
                    <ab-legal-section
                        :show-legal="false"
                        :show-gdpr="true"
                        :fsp-es="legalUrls.fspEs"
                        :fsp-ca="legalUrls.fspCa"
                        :gdpr-es="legalUrls.gdprEs"
                        :gdpr-ca="legalUrls.gdprCa"
                        :legal-es="legalUrls.urEs"
                        :legal-ca="legalUrls.urCa"
                    ></ab-legal-section>
                    <div class="line-top d-flex pt-3 justify-content-end">
                        <div class="box-send">
                            <vue-recaptcha
                                ref="formRecaptcha"
                                :sitekey="recaptchaSiteCode"
                                :load-recaptcha-script="true"
                                @verify="verifyCaptcha"
                                @expired="resetCaptcha"
                            >
                                <ab-button-ofex text-button="Enviar" :disabled="invalid"></ab-button-ofex>
                            </vue-recaptcha>
                        </div>
                    </div>
                    <!-- textos finales -->
                    <ab-text-end name="textos-finales" />
                </section>
            </ValidationObserver>
        </div>
        <div v-if="curStep == 2">
            <ab-confirmation
                :generate-pdf-handler="downloadPdf"
                confirmation-description=" "
                v-bind:confirmation-msg="$t('ofex.forms.reclamations.textConfirmation')"
            />
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import * as viewMappingTypes from "@/router/view-mapping-types";
import AbInput from "../components/AbInput.vue";
import AbSelect from "../components/AbSelect.vue";
import ReadMeterPdfGenerator from "@/generators/pdf/ReadMeterPdfGenerator";
import moment from "moment";
import BaseOfexServiceClient from "@/rest/BaseOfexServiceClient";

export default {
    components: { AbSelect, AbInput },
    name: "ReadMeterFormApp",
    props: {
        contractNumber: {
            required: false,
            type: String,
        },
        isAuthenticated: {
            type: Boolean,
            default: false,
        },
        userData: {
            type: Object,
            required: false,
        },
        clientData: {
            type: Object,
            required: false,
        },
        legalUrls: {
            type: Object,
            required: false,
        },
    },
    data: function () {
        return {
            recaptchaSiteCode: process.env.VUE_APP_RECAPTCHA_SITE_CODE_INVISIBLE_MODE,
            recaptchaResponse: null,
            pdfGenerator: null,
            curStep: 1,
            viewMappingTypes: viewMappingTypes,
            actividadId: "",
            isLoading: false,
            submitErrorList: [],
            select: {
                identificationWith: [
                    { key: "contrato", text: this.$t("ofex.forms.readMeter.selectContrato") },
                    { key: "factura", text: "Número de factura" },
                ],
            },
            formModel: {
                contactData: {
                    idWith: "",
                    identificationContract: "",
                    identificationInvoice: "",
                    doc: { type: "", number: "", exists: false },
                    name: "",
                    lastName: "",
                    secondLastName: "",
                    mainPhone: "",
                    email: "",
                    emailConfirmation: "",
                },
                dataRead: {
                    dateRead: moment().toDate(),
                    read: "",
                },
            },
        };
    },
    computed: {
        validationErrors: function () {
            return Object.values(this.$refs.readMeterForm.errors).flat();
        },
    },
    mounted() {
        if (this.isAuthenticated) {
            this.formModel.contactData.idWith = "contrato";
            this.formModel.contactData.identificationContract = this.$route.params.contractId;
            this.formModel.contactData.doc.type = this.userData.documentType;
            this.formModel.contactData.doc.number = this.userData.documentNumber;
            this.formModel.contactData.name = this.userData.firstName;
            this.formModel.contactData.lastName = this.userData.firstLastName;
            this.formModel.contactData.secondLastName = this.userData.secondLastName;
            this.formModel.contactData.mainPhone = this.userData.mainPhone;
            this.formModel.contactData.email = this.userData.email;
        }
    },
    created: function () {
        console.debug("CREATED ROOT VUE");
        this.actividadId = "LC" + new Date().getTime();
    },
    methods: {
        verifyCaptcha: function (response) {
            console.debug("Captcha response: " + response);
            this.recaptchaResponse = response;
            this.submitForm();
        },
        resetCaptcha: function () {
            this.isLoading = false;
            this.$refs.formRecaptcha.reset();
        },
        exitForm: function () {
            Swal.fire({
                text: this.$i18n.tc("ofex.forms.readMeter.enlaceDatosUser"),
                icon: "question",
                confirmButtonText: "OK",
                denyButtonText: "No",
                showDenyButton: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: viewMappingTypes.MODIFICATION_OWNER_DATA_MAPPING.name });
                }
            });
        },
        submitForm: function () {
            let validator = this.$refs.readMeterForm;
            validator.validate().then(async (result) => {
                if (result) {
                    this.pdfGenerator = new ReadMeterPdfGenerator(
                        this.$i18n,
                        this.userData.documentNumber,
                        this.clientData.documentNumber,
                        this.contractNumber
                    );
                    this.pdfGenerator.generatePdf(this.formModel);
                    await this.pdfGenerator.uploadPdfWithActivity(this.actividadId);
                    this.idDocumentoPdf = this.pdfGenerator.uploadedDocumentId;
                    this.sendForm();
                }
            });
        },
        sendForm: function () {
            moment.locale("es");
            this.isLoading = true;
            var vueContext = this;
            console.log("Submiting form");
            new BaseOfexServiceClient(false).abRestClient
                .post(
                    `${process.env.VUE_APP_API_FORMS_PUBLIC_BASE_URL}/meter/consumptions`,
                    {
                        captchaClientResponse: this.recaptchaResponse,
                        contractNumber: this.formModel.contactData.idWith == "contrato" ? this.formModel.contactData.identificationContract : "",
                        invoiceNumber: this.formModel.contactData.idWith == "factura" ? this.formModel.contactData.identificationInvoice : "",
                        cif: this.formModel.contactData.doc.number,
                        name: this.formModel.contactData.name,
                        firstSurname: this.formModel.contactData.lastName,
                        secondSurname: this.formModel.contactData.secondLastName,
                        phone: this.formModel.contactData.mainPhone,
                        email: this.formModel.contactData.email,
                        readDate: moment(this.formModel.dataRead.dateRead, "Do MMMM YYYY, h:mm:ss a").format("DD-MM-YYYY"),
                        readValue: this.formModel.dataRead.read,
                        adjunto: null,
                        lang: "esn",
                    },
                    {
                        params: {
                            captchaClientResponse: this.recaptchaResponse,
                            clientId: this.clientData.documentNumber,
                            userId: this.userData.documentNumber,
                            lang: "esn",
                        },
                    }
                )
                .then(() => {
                    this.$notifyFormActionToMatomo(true, "read_meter_form");
                    this.sendContentForm();
                })
                .catch((error) => {
                    console.error(error);
                    vueContext.submitErrorList.push(error.response.data.errorMessage);
                    vueContext.isLoading = false;
                    this.$notifyFormActionToMatomo(false, "read_meter_form", error.response.data.errorMessage);
                });
        },
        downloadPdf: function () {
            this.pdfGenerator.downloadPdf();
        },
        sendContentForm: function () {
            var vueContext = this;
            this.$http
                .post(
                    `${process.env.VUE_APP_API_FORMS_PUBLIC_BASE_URL}/contentform/send`,
                    {
                        captchaClientResponse: this.recaptchaResponse,
                        idDocumentoPdf: this.idDocumentoPdf,
                        actividadId: this.actividadId,
                        navigationLang: this.$i18n.locale,
                        tipoPeticion: "022",
                        email: this.formModel.contactData.email,
                        numeroContrato: this.formModel.contactData.identificationContract, //VER COMENTARIO 2/10/2019 https://zenerab.atlassian.net/browse/NW-1352
                        userId: this.userData.documentNumber,
                        clientId: this.clientData.documentNumber,
                    },
                    {
                        params: {
                            clientId: this.clientData.documentNumber,
                            userId: this.userData.documentNumber,
                        },
                    }
                )
                .then(function (response) {
                    if (response.status == "200") {
                        vueContext.isLoading = false;
                        vueContext.curStep = 2;
                    } else {
                        this.submitErrorList.push(response.body.errorMessage);
                    }
                });
        },
    },
};
</script>

<style scoped lang="scss">
.link-owner {
    cursor: pointer;
    color: $link-secondary;
    border-bottom: 1px solid $link-secondary;
    font-weight: $font-primary-medium;
    @include font-size(16);
    @include line-height(19);
}
.subtitle {
    @include font-size(14);
    @include line-height(16);
    font-weight: $font-primary;
    color: $color-secondary;
}
.text-normal {
    @include font-size(14);
    @include line-height(16);
    font-weight: $font-primary;
    color: $color-secondary;
}
.line-top {
    border-top: 1px solid $blue-dark;
}

.icon {
    position: relative;
    top: 2px;
    color: $blue-dark;
    width: 20px;
    height: 20px;
}
.item-collapse {
    @include font-size(16);
    @include line-height(26);
    color: $blue-dark;
    font-weight: $font-primary-bold;
}
.box-send {
    width: 120px;
}
</style>

<style lang="scss">
#observaciones-reclamaciones {
    min-height: 200px;
    @include font-size(14);
}
</style>
