<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <b-container>
        <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" />
        <b-row>
            <ab-left-nav-bar />
            <b-col cols="12" md="12" lg="9">
                <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                <ab-main-title :main-text="$t('ofex.authorizationsView.title')"></ab-main-title>
                <ab-return />

                <ab-dropdown-section
                    v-model="dropdownVisible"
                    collapse-id="authorizationsByContractSection1"
                    :label="$t('ofex.authorizationsViewByContract.dropdownTitle')"
                >
                    <p class="authorizations-section-subtitle">{{ $t("ofex.authorizationsViewByContract.section1Text") }}</p>
                    <div class="m-3">
                        <p class="small-element-blue">
                            {{ $t("ofex.invoicesTable.numContrato") }}<span class="element-black">{{ contract.contractDetail.contractNumber }}</span>
                        </p>
                        <p class="small-element-blue">
                            {{ $t("ofex.detalleContrato.aliasContrato") }}<span class="element-black">{{ contract.contractDetail.alias }}</span>
                        </p>
                        <p class="small-element-blue">
                            {{ $t("ofex.detalleContrato.direccionSuministro")
                            }}<span class="element-black"
                                >{{ contract.supplyAddress.streetName }}, {{ contract.supplyAddress.number }}, {{ contract.supplyAddress.otherAddressData }},
                                {{ contract.supplyAddress.municipality }}
                            </span>
                        </p>
                    </div>

                    <!--  TODO pendiente de diseño-->
                    <ab-authorizations-advance-search v-show="false" v-model="filteredAuthorizations" ref="authorizationAdvanceSearch" />
                    <ab-authorizations-advance-search-by-persons
                        v-show="true"
                        ref="authorizationAdvanceSearch"
                        :count="tableRows.length"
                        @filter-persons="executeFilter"
                        @clear-filter="resetFilter"
                    />
                    <vue-good-table
                        v-if="authorizationsByContract"
                        ref="authorizationsByContractTable"
                        :columns="columns"
                        :rows="tableRows"
                        style-class="vgt-table"
                        :select-options="{ enabled: true, checked: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
                        :sort-options="{
                            enabled: true,
                            initialSortBy: { field: 'authorizationDate', type: 'desc' },
                        }"
                        :pagination-options="{
                            enabled: true,
                            mode: 'records',
                            perPage: 10,
                            perPageDropdown: [10, 20, 30],
                            dropdownAllowAll: false,
                            setCurrentPage: 1,
                            nextLabel: this.$i18n.tc('table.siguiente'),
                            prevLabel: this.$i18n.tc('table.atras'),
                            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
                            ofLabel: 'de',
                            allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
                            label: 'selector-de-paginas',
                            name: 'perPageSelect',
                        }"
                    >
                        <div slot="emptystate" class="text-center textdnormal">
                            {{ $t("ofex.tuContrato.noPersons") }}
                        </div>
                    </vue-good-table>

                    <div class="row mt-3 mb-3 justify-content-end">
                        <div class="col-lg-4 mb-4">
                            <ab-button-ofex :text-button="$t('ofex.buttonsOfex.revokeAuthorization')" :click-event="deleteAuthorizations"></ab-button-ofex>
                        </div>
                        <div class="col-lg-6">
                            <!--  TODO revisar añadir v-show para que solo aparezca el boton cuando no hay ningún check seleccionado-->
                            <ab-button-ofex
                                :disabled="statusInactiveContract"
                                :text-button="$t('ofex.buttonsOfex.addAuthorization')"
                                :click-event="addContractAuthorization"
                            ></ab-button-ofex>
                        </div>
                    </div>
                </ab-dropdown-section>

                <ab-your-interest
                    :name-left="$t('ofex.relatedContents.beneficiosTelelectura.name')"
                    :href-left="$t('ofex.relatedContents.beneficiosTelelectura.url')"
                    :image-left="$t('ofex.relatedContents.beneficiosTelelectura.image')"
                    :name-center="$t('ofex.relatedContents.consellEstalvi.name')"
                    :href-center="$t('ofex.relatedContents.consellEstalvi.url')"
                    :image-center="$t('ofex.relatedContents.consellEstalvi.image')"
                    :name-right="$t('ofex.relatedContents.facturaDigital.name')"
                    :href-right="$t('ofex.relatedContents.facturaDigital.url', { windowLocationPathname: this.$windowLocationPathname })"
                    :image-right="$t('ofex.relatedContents.facturaDigital.image')"
                    target-right="_self"
                />
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import AbLeftNavBar from "../modules/common/AbLeftNavBar.vue";
import AbLoading from "../components/AbLoading";

import * as actionTypes from "../store/action-types";

import { mapGetters, mapState } from "vuex";
import * as viewMappingTypes from "@/router/view-mapping-types";
import AbAuthorizationsAdvanceSearchByPersons from "@/modules/authorizations/AbAuthorizationsAdvanceSearchByPersons";
import Swal from "sweetalert2";
import moment from "moment";

export default {
    name: "AuthorizationsByContractView",
    components: {
        AbLeftNavBar,
        AbLoading,
        AbAuthorizationsAdvanceSearchByPersons,
    },
    data: function () {
        return {
            selected: [],
            selectAll: false,
            filteredAuthorizations: null,
            dropdownVisible: true,
            tableSelectOptions: {
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: "custom-class",
                selectionText: "Autorizaciones Seleccionadas",
                clearSelectionText: this.$t("ofex.tuContrato.limpiar"),
                disableSelectInfo: true, // disable the select info panel on top
            },
        };
    },

    methods: {
        executeFilter: function (filter) {
            if (filter.document) {
                [...this.filteredAuthorizations] = this.authorizations.filter((person) => person.documentNumber.toLowerCase() == filter.document.toLowerCase());
            }
            if (filter.name) {
                [...this.filteredAuthorizations] = this.authorizations.filter(
                    (person) => person.firstName.toLowerCase().indexOf(filter.name.toLowerCase()) !== -1
                );
            }
            if (filter.email) {
                [...this.filteredAuthorizations] = this.authorizations.filter(
                    (person) => person.email.toLowerCase().indexOf(filter.email.toLowerCase()) !== -1
                );
            }
        },
        resetFilter: function () {
            this.filteredAuthorizations = [...this.authorizations];
        },
        mapAuthorizationToTableRow: function (entry) {
            return {
                documentNumber: entry.documentNumber,
                fullName: `${entry.firstName} ${entry.firstLastName} ${entry.secondLastName}`,
                email: entry.email,
                phone: entry.phone,
                authorizationDate: entry.authorizationDate,
            };
        },
        addContractAuthorization: function () {
            this.$router.push({
                name: viewMappingTypes.NEW_AUTHORIZATION_VIEW_MAPPING.name,
                query: { authorizationType: "CONTRACT", contractNumbers: JSON.stringify([this.$route.params.contractId]) },
            });
        },
        deleteAuthorizations: function () {
            if (this.$refs.authorizationsByContractTable.selectedRows.length > 0) {
                Swal.fire({
                    text: this.$i18n.tc("ofex.authorizationsView.revokeConfirmation"),
                    icon: "warning",
                    confirmButtonText: "OK",
                    showCancelButton: true,
                    cancelButtonText: this.$i18n.tc("ofex.common.unsavedFormDataCancelText"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        let documentNumbers = this.$refs.authorizationsByContractTable.selectedRows.map((row) => row.documentNumber);
                        this.$store
                            .dispatch(actionTypes.DELETE_UNIQUE_CONTRACT_AUTHORIZATION_ACTION, {
                                documentNumbers: documentNumbers,
                                contractNumber: this.$route.params.contractId,
                                locale: this.$i18n.locale,
                            })
                            .then((result) => {
                                console.debug(result);
                                Swal.fire({
                                    text: this.$i18n.tc("ofex.authorizationsView.confirmedAuthorizationRevoked"),
                                    icon: "success",
                                    confirmButtonText: "OK",
                                    showCancelButton: false,
                                });
                            })
                            .catch(() => {
                                Swal.fire({
                                    text: this.$i18n.tc("ofex.authorizationsView.missedAuthorizationRevoked"),
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    showCancelButton: false,
                                });
                            });
                    }
                });
            } else {
                Swal.fire({
                    showCloseButton: true,
                    showCancelButton: false,
                    text: this.$i18n.t("ofex.authorizationsView.warning3"),
                    icon: "warning",
                });
            }
        },
        sortDateColumnAuthDate: function (x, y, col, rowX, rowY) {
            let rowXMoment = moment(rowX.authorizationDate, "DD-MM-YYYY");
            let rowYMoment = moment(rowY.authorizationDate, "DD-MM-YYYY");
            return rowXMoment.isBefore(rowYMoment) ? -1 : rowXMoment.isAfter(rowYMoment) ? 1 : 0;
        },
    },
    computed: {
        ...mapState(["contracts", "authorizations", "authorizationsByContract", "isLoading"]),
        ...mapGetters(["getContractByContractNumber"]),
        contract: function () {
            return this.getContractByContractNumber(this.$route.params.contractId);
        },
        statusInactiveContract: function () {
            return this.contract
                ? this.contract.contractDetail.supplyStatus.toUpperCase() === "BAIXA" || this.contract.contractDetail.supplyStatus.toUpperCase() === "BAJA"
                    ? true
                    : false
                : false;
        },
        selectedRows: function () {
            //TODO esto devuelve undefined, ver la razón
            return this.$refs.authorizationsByContractTable && this.$refs.authorizationsByContractTable.selectedRows;
        },
        tableRows: function () {
            let contractEntry = this.authorizationsByContract.filter((entry) => entry.contractNumber === this.$route.params.contractId);
            let authorizations = contractEntry && contractEntry.length > 0 ? contractEntry[0].authorizations.map(this.mapAuthorizationToTableRow) : [];
            return this.filteredAuthorizations !== null ? this.filteredAuthorizations.map(this.mapAuthorizationToTableRow) : authorizations;
        },
        columns: function () {
            return [
                {
                    label: "NIF / NIE",
                    field: "documentNumber",
                    tdClass: "first-col",
                    thClass: "first-col",
                },
                {
                    label: this.$i18n.t("modules.authorizationsViewByPersons.nomCognomsInputLabel"),
                    field: "fullName",
                },
                {
                    label: this.$i18n.t("modules.authorizationsViewByPersons.correoInputLabel"),
                    field: "email",
                },

                {
                    label: this.$i18n.t("ofex.authorizationsView.phoneColumnName"),
                    field: "phone",
                },
                {
                    label: this.$i18n.t("ofex.tuContrato.fechaAlta"),
                    field: "authorizationDate",
                    sortFn: this.sortDateColumnAuthDate,
                },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
        this.$store.dispatch(actionTypes.GET_AUTHORIZATIONS_BY_USER_ACTION, { locale: this.$i18n.locale });
    },
};
</script>

<style lang="scss" scoped>
.small-element-blue {
    @include font-size(12);
    @include line-height(14);
    color: $blue-lighter;
    font-weight: $font-primary-bold;
    margin-bottom: 10px;
}
</style>
