"use strict";

import BaseGenerator from "./base/BasePdfGenerator.js";
import { trimBackendCode } from "@/plugins/ab-utils";

const ACTIVITY_CODE = "PS";
export default class InvoiceListPdfGenerator extends BaseGenerator {
    constructor(i18n, userId, clientId, contract, supplyAddress) {
        super("Facturas.pdf", ACTIVITY_CODE, i18n, userId, clientId, contract, supplyAddress);
    }

    generatePdf(invoices, contractSelect, supplyAddress) {
        this.generatePdfHeader();
        this.generatePdfTitleAndSubtitle(this.i18n.tc("consultarTusFacturas.title"), "");
        this.generateLineText(this.i18n.tc("ofex.invoicesTable.numContrato") + "  " + contractSelect);
        this.generateLineText(this.i18n.tc("ofex.invoicesTable.direccion") + "  " + supplyAddress);

        var head = [
            [
                `${this.i18n.tc("ofex.invoicesTable.columnNames.invoiceNumber")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.consuptiomUntil")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.issueDate")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.consumption")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.amount")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.estado")}`,
                `${this.i18n.tc("ofex.invoicesTable.columnNames.shippmentType")}`,
            ],
        ];
        var body = [invoices.length];
        invoices.forEach(
            (i, index) =>
                (body[index] = [
                    `${i.invoiceYear}${i.invoiceNumber}`,
                    `${i.endDate}`,
                    `${i.issueDate}`,
                    `${i.consumptionVolume}`,
                    `${i.amount.toFixed(2)}`,
                    `${this.i18n.tc(`ofex.backendCodes.invoiceStatus.${trimBackendCode(i.invoiceStatus)}`)}`,
                    `${this.i18n.tc("ofex.backendCodes.deliveryType." + i.invoice.toString())}`,
                ])
        );
        console.log(head, body);
        this.generateAutoTable(head, body, 6);
        return this.pdf;
    }
}
