<i18n src="../components/common/i18n-components.i18n"></i18n>
<template>
    <ValidationObserver ref="userForm" v-slot="{ reset, invalid, dirty }">
        <b-container>
            <ab-loading v-model="isLoading" :opacity="0.85" :full-page="true" />
            <b-row>
                <ab-left-nav-bar />
                <b-col cols="12" md="12" lg="9">
                    <app-breadcrumbs v-if="$isWebApp()"></app-breadcrumbs>
                    <ab-main-title v-if="authorizationType === 'GENERAL'" :main-text="$t('ofex.newAuthorizationsView.title')"></ab-main-title>
                    <ab-main-title v-else-if="authorizationType === 'CONTRACT'" :main-text="$t('ofex.newAuthorizationsView.titleContract')"></ab-main-title>
                    <ab-return />
                    <div v-if="confirmed">
                        <ab-confirmation
                            :title="$t('ofex.newAuthorizationsView.confirmedTitle')"
                            confirmation-msg=" "
                            confirmation-description=" "
                            :show-pdf-button="false"
                        ></ab-confirmation>
                    </div>
                    <div v-else>
                        <section>
                            <p
                                v-if="authorizationType === 'GENERAL'"
                                class="subtitle-new-authorization mb-5"
                                v-html="$t('ofex.newAuthorizationsView.subtitle')"
                            ></p>
                            <p
                                v-if="authorizationType === 'CONTRACT'"
                                class="subtitle-new-authorization mb-5"
                                v-html="$t('ofex.newAuthorizationsView.subtitleContract')"
                            ></p>
                            <ab-id-type-number
                                v-model="formModel.doc"
                                validation-type-name="autorizado-tipo-doc"
                                validation-number-name="autorizado-tipo-numero"
                                :allow-cif="false"
                                class="mb-3"
                                :number-input-alias="$t('alias.idTypeNumber.numeroDocumentoUser')"
                            />
                            <div class="row mb-5">
                                <div class="col-12 col-lg-3">
                                    <ab-input
                                        autocomplete="given-name"
                                        v-model="formModel.name"
                                        name="autorizado-nombre"
                                        :placeholder="$t('ofex.bajaContratoDetalle.placeholders.nombre')"
                                        :validation="{ required: true, max: 50 }"
                                        :label="$t('ofex.bajaContratoDetalle.nombre')"
                                    ></ab-input>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <ab-input
                                        autocomplete="family-name"
                                        v-model="formModel.lastName"
                                        name="autorizado-primer-apellido"
                                        :placeholder="$t('ofex.bajaContratoDetalle.placeholders.primerApellido')"
                                        :validation="{ required: true, max: 50 }"
                                        :label="$t('ofex.bajaContratoDetalle.primerApellido')"
                                    ></ab-input>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <ab-input
                                        autocomplete="family-name"
                                        v-model="formModel.secondLastName"
                                        name="autorizado-segundo-apellido"
                                        :placeholder="$t('ofex.bajaContratoDetalle.placeholders.segundoApellido')"
                                        :validation="{ required: false, max: 50 }"
                                        :label="$t('ofex.bajaContratoDetalle.segundoApellido')"
                                    ></ab-input>
                                </div>
                            </div>
                            <div class="row mb-5">
                                <div class="col-12 col-lg-3">
                                    <ab-input
                                        autocomplete="tel-national"
                                        v-model="formModel.phone"
                                        name="telefono-principal-datos-contacto"
                                        :placeholder="$t('ofex.forms.reclamations.placeholders.telefono')"
                                        :validation="{ required: true, spanishMobile: true }"
                                        :label="$t('ofex.passwordRecovery.telefono')"
                                    >
                                    </ab-input>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <ab-input
                                        autocomplete="email"
                                        v-model="formModel.email"
                                        name="email-datos-contacto"
                                        placeholder="ejemplo@dominio.com"
                                        :validation-delay="200"
                                        :validation="{
                                            required: true,
                                            email: true,
                                            regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                        }"
                                        :label="$t('ofex.forms.reclamations.email')"
                                    >
                                    </ab-input>
                                </div>
                                <div class="col-12 col-lg-3">
                                    <ab-input
                                        autocomplete="email"
                                        v-model="formModel.emailConfirmation"
                                        name="email-confirmation-datos-contacto"
                                        placeholder="ejemplo@dominio.com"
                                        :validation-delay="200"
                                        :validation="{
                                            required: true,
                                            email: true,
                                            regex: /(^[0-9a-zA-Z]([-_.]?[-_0-9a-zA-Z])*@[0-9a-zA-Z]([-.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,63}$)/,
                                            is: formModel.email,
                                        }"
                                        :label="$t('ofex.forms.reclamations.repiteMail')"
                                        :pasteable="false"
                                    >
                                    </ab-input>
                                </div>
                            </div>
                            <div class="row mb-5">
                                <div class="col-12 col-lg-3">
                                    <ab-select
                                        v-model="formModel.language"
                                        name="authorization-language"
                                        data-key="key"
                                        data-label="text"
                                        placeholder="Seleccionar"
                                        :validation="{ required: true }"
                                        :data-list="languageSelectData"
                                        :label="$t('ofex.UserData.languageLabel')"
                                    >
                                    </ab-select>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="line-top d-flex pt-3 justify-content-end">
                                <div class="box-send mb-4">
                                    <ab-button-ofex text-button="Enviar" :disabled="invalid" :click-event="createNewAuthorization"></ab-button-ofex>
                                </div>
                            </div>
                            <ab-text-end name="textos-finales" />
                        </section>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </ValidationObserver>
</template>
<script>
import AbLeftNavBar from "../modules/common/AbLeftNavBar.vue";
import AbLoading from "../components/AbLoading";
import NewAuthPdfGenerator from "@/generators/pdf/NewAuthPdfGenerator";
import ContentManagerServiceClient from "@/rest/contentManagerServiceClient";

import * as actionTypes from "../store/action-types";

import { mapGetters, mapState } from "vuex";

export default {
    name: "AuthorizationCreateView",
    components: {
        AbLeftNavBar,
        AbLoading,
    },
    data: function () {
        return {
            activityId: "",
            authorizationType: "GENERAL",
            contractNumbers: null,
            confirmed: false,
            formModel: {
                doc: { type: "", number: "", exists: false },
                name: "",
                lastName: "",
                secondLastName: "",
                phone: "",
                email: "",
                emailConfirmation: "",
                language: "",
            },
        };
    },

    methods: {
        createNewAuthorization: function () {
            let validator = this.$refs.userForm;
            validator.validate().then(async (result) => {
                if (result) {
                    this.pdfGenerator = new NewAuthPdfGenerator(this.$i18n, this.userData.documentNumber, this.clientData.documentNumber, null);
                    this.pdfGenerator.generatePdf(this.clientData, this.formModel, this.contractNumbers);
                    await this.pdfGenerator.uploadPdfWithActivity(this.activityId, false);
                    await ContentManagerServiceClient.commitStorageDocumentsByActivity(this.activityId);
                    this.idDocumentoPdf = this.pdfGenerator.uploadedDocumentId;
                    this.$store
                        .dispatch(actionTypes.CREATE_NEW_AUTHORIZATION_ACTION, {
                            activityId: this.activityId,
                            idDocumentoPdf: this.idDocumentoPdf,
                            authorizationType: this.authorizationType,
                            personData: {
                                documentType: this.formModel.doc.type,
                                documentNumber: this.formModel.doc.number,
                                firstName: this.formModel.name,
                                firstLastName: this.formModel.lastName,
                                secondLastName: this.formModel.secondLastName,
                                phone: this.formModel.phone,
                                email: this.formModel.email,
                                authorizedContractNumbers: this.contractNumbers,
                            },
                            contractNumbers: this.contractNumbers,
                            locale: this.formModel.language,
                        })
                        .then(() => {
                            this.confirmed = true;
                            this.$notifyFormActionToMatomo(
                                true,
                                this.authorizationType === "GENERAL" ? "authorization_create_client" : "authorization_create_contract"
                            );
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        });
                }
            });
        },
    },
    computed: {
        ...mapState(["authorizations", "isLoading", "userData", "clientData"]),
        ...mapGetters(["legalUrls"]),
        languageSelectData: function () {
            return [
                { key: "es", text: this.$t("ofex.detalleContrato.espanol") },
                { key: "ca", text: this.$t("ofex.detalleContrato.catalan") },
            ];
        },
    },
    mounted() {
        this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });

        this.activityId = "AC" + new Date().getTime();

        if (this.$route.query.authorizationType === "CONTRACT") {
            this.authorizationType = "CONTRACT";
            this.contractNumbers = JSON.parse(this.$route.query.contractNumbers);
        }
    },
};
</script>

<style lang="scss" scoped>
.subtitle-new-authorization {
    @include font-size(12);
    @include line-height(21);
    font-weight: $font-primary-bold;
    color: $blue-dark;
}
</style>
