<template>
    <header class="header" :class="{ iosHeader: $isIos() }">
        <div class="header-top" v-show="$isWebApp() || ($isNativeApp() && isAuthenticated && !$router.currentRoute.meta.biometricsRegistered)">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="header-container" :class="{ native: $isNativeApp() }">
                            <AbHeaderWebLinks v-show="$isWebApp()" name="header-web-links" />
                            <div class="right-side">
                                <mq-layout :mq="menuNativo">
                                    <div class="search-container" v-if="$isWebApp()">
                                        <ab-input
                                            v-model="searchText"
                                            class="input-searcher"
                                            title="busqueda"
                                            input-alias="busqueda"
                                            :show-label="false"
                                            name="searcher"
                                            placeholder="Búsqueda..."
                                        ></ab-input>
                                        <button class="search-button" type="submit" aria-label="Buscar" @click="doSearch">
                                            <font-awesome-icon class="icon" icon="search"></font-awesome-icon>
                                        </button>
                                    </div>
                                </mq-layout>
                                <!--                              https://zenerab.atlassian.net/browse/UATOFEX-706-->
                                <AbHeaderLocation v-show="false" name="location-container" />

                                <mq-layout class="language-container" :mq="menuNativo">
                                    <AbHeaderLanguage name="language-container" />
                                </mq-layout>
                                <AbHeaderUserPanel v-if="isAuthenticated" name="user-panel-container" />

                                <!-- SE QUITA ESTE BOTON https://zenerab.atlassian.net/browse/UATOFEX-671-->
                                <div v-if="!isAuthenticated && false" class="login-container">
                                    <a class="link" :href="ofexSignUpUrl">{{ $t("ofex.header.registrate") }}</a>
                                    <a class="link" href="/#/login">{{ $t("ofex.header.accede") }}</a>
                                </div>
                                <mq-layout :mq="menuNativoSmall" v-if="isAuthenticated">
                                    <nav-bar-renderless
                                        v-slot="{
                                            navigateReadMeterForm,
                                            navigateUnsuscribeContract,
                                            navigateIaeData,
                                            navigateCertificateOccupancy,
                                            navigateOwnerData,
                                            navigateCorrespondenceData,
                                            navigatePaymentData,
                                            selectedContractNumber,
                                            lastInvoice,
                                            contractsWithTelerecording,
                                            isCompany,
                                            hasAnyDomesticContract,
                                            hasAnyIndustrialOrComercialContract,
                                        }"
                                    >
                                        <v-collapse-wrapper class="wrapper-menu" ref="menuRootCollapseWrapper">
                                            <font-awesome-icon
                                                :class="{ active: firstLevelActive }"
                                                @click="firstLevelActive = !firstLevelActive"
                                                v-collapse-toggle
                                                class="icon-menu"
                                                icon="bars"
                                                ref="iconMenu"
                                            ></font-awesome-icon>
                                            <div class="menu-mobile" :class="{ iosMenu: $isIos(), native: $isNativeApp() }" v-collapse-content>
                                                <v-collapse-group :only-one-active="true">
                                                    <mq-layout :mq="menuNativoSmall">
                                                        <div class="container-mobile-inner">
                                                            <div class="header-links-container" v-if="$isWebApp()">
                                                                <ab-dropdown
                                                                    :label="labelExt"
                                                                    :data-list="AbExternalLinks"
                                                                    name-icon="caret-down"
                                                                ></ab-dropdown>
                                                            </div>
                                                            <AbHeaderLanguage name="language-container" />
                                                        </div>
                                                        <div class="search-container" v-if="$isWebApp()">
                                                            <ab-input
                                                                v-model="searchText"
                                                                class="input-searcher"
                                                                title="busqueda"
                                                                input-alias="busqueda"
                                                                :show-label="false"
                                                                name="searcher"
                                                                placeholder="Búsqueda..."
                                                                :class="{ native: $isNativeApp() }"
                                                            ></ab-input>
                                                            <button class="search-button" type="submit" aria-label="Buscar" @click="doSearch">
                                                                <font-awesome-icon class="icon" icon="search"></font-awesome-icon>
                                                            </button>
                                                        </div>
                                                    </mq-layout>
                                                    <ul class="list-first-level" v-scroll-lock="open">
                                                        <li class="item-first-level">
                                                            <router-link class="link-item" :to="viewMappingTypes.HOME_VIEW_MAPPING.path">{{
                                                                $t("ofex.menuLateral.inicio")
                                                            }}</router-link>
                                                        </li>

                                                        <v-collapse-wrapper>
                                                            <li class="item-first-level">
                                                                <router-link
                                                                    class="link-item"
                                                                    :to="{
                                                                        name: viewMappingTypes.INVOICES_LIST_MAPPING.name,
                                                                        params: { contractId: selectedContractNumber },
                                                                    }"
                                                                    >{{ $t("ofex.menuLateral.tusFacturas") }}</router-link
                                                                >
                                                                <span
                                                                    class="icon-container"
                                                                    :class="{ active: secondLevelInvoicesActive }"
                                                                    @click="secondLevelInvoicesActive = !secondLevelInvoicesActive"
                                                                    v-collapse-toggle
                                                                >
                                                                    <font-awesome-icon class="icon" icon="chevron-down"></font-awesome-icon>
                                                                </span>
                                                                <ul v-collapse-content class="list-second-level">
                                                                    <li class="item-second-level">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :to="{ name: viewMappingTypes.INVOICE_PAYMENT_BY_CONTRACTS.name }"
                                                                            >{{ $t("ofex.menuLateral.pagarTusFacturas") }}</router-link
                                                                        >
                                                                    </li>
                                                                    <li class="item-second-level mt-2" v-if="lastInvoice">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :to="{
                                                                                name: viewMappingTypes.INVOICE_DETAIL_VIEW_MAPPING.name,
                                                                                params: {
                                                                                    contractId: selectedContractNumber,
                                                                                    invoiceId: lastInvoice.invoiceNumber,
                                                                                },
                                                                            }"
                                                                            >{{ $t("ofex.menuLateral.entenderFactura") }}</router-link
                                                                        >
                                                                    </li>
                                                                    <li class="item-second-level mt-2">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :to="{
                                                                                name: viewMappingTypes.EVOLUTION_INVOICES_MAPPING.name,
                                                                            }"
                                                                            >{{ $t("ofex.menuLateral.evolucionFacturas") }}</router-link
                                                                        >
                                                                    </li>

                                                                    <li class="item-second-level mt-2" v-if="multicontract">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :to="{
                                                                                name: viewMappingTypes.LAST_INVOICES_MAPPING.name,
                                                                            }"
                                                                            >{{ $t("ofex.menuLateral.ultimasFacturas") }}</router-link
                                                                        >
                                                                    </li>
                                                                    <li class="item-second-level mt-2">
                                                                        <router-link class="link-item" :to="navigateReadMeterForm()">{{
                                                                            $t("ofex.menuLateral.lecturaContador")
                                                                        }}</router-link>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </v-collapse-wrapper>

                                                        <li class="item-first-level">
                                                            <router-link class="link-item" :to="{ name: viewMappingTypes.INVOICE_DIGITAL_MAPPING.name }">{{
                                                                $t("ofex.menuLateral.facturaDigital")
                                                            }}</router-link>
                                                        </li>

                                                        <v-collapse-wrapper>
                                                            <li class="item-first-level">
                                                                <router-link
                                                                    class="link-item"
                                                                    :to="{
                                                                        name: viewMappingTypes.CONSUMPTIONS_DETAIL_MAPPING.name,
                                                                        params: { contractId: selectedContractNumber },
                                                                    }"
                                                                    >{{ $t("ofex.menuLateral.tusConsumos") }}</router-link
                                                                >
                                                                <span
                                                                    class="icon-container"
                                                                    :class="{ active: secondLevelConsumptionsActive }"
                                                                    @click="secondLevelConsumptionsActive = !secondLevelConsumptionsActive"
                                                                    v-collapse-toggle
                                                                >
                                                                    <font-awesome-icon
                                                                        v-if="contractsWithTelerecording?.length > 1"
                                                                        class="icon"
                                                                        icon="chevron-down"
                                                                    >
                                                                    </font-awesome-icon>
                                                                </span>
                                                                <ul v-collapse-content class="list-second-level">
                                                                    <li class="item-second-level mt-2" v-if="contractsWithTelerecording?.length > 1">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :to="{ name: viewMappingTypes.LAST_READINGS_DETAIL_MAPPING.name }"
                                                                            >{{ $t("ofex.menuLateral.ultimasLecturas") }}</router-link
                                                                        >
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </v-collapse-wrapper>

                                                        <v-collapse-wrapper>
                                                            <li class="item-first-level">
                                                                <router-link
                                                                    class="link-item"
                                                                    :to="{
                                                                        name: viewMappingTypes.CONTRACTS_LIST_MAPPING.name,
                                                                        params: { contractId: selectedContractNumber },
                                                                    }"
                                                                    >{{ $t("ofex.menuLateral.tusContratos") }}</router-link
                                                                >
                                                                <span
                                                                    class="icon-container"
                                                                    :class="{ active: secondLevelContractsActive }"
                                                                    @click="secondLevelContractsActive = !secondLevelContractsActive"
                                                                    v-collapse-toggle
                                                                >
                                                                    <font-awesome-icon class="icon" icon="chevron-down"></font-awesome-icon>
                                                                </span>
                                                                <ul v-collapse-content class="list-second-level">
                                                                    <li class="item-second-level mt-2">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :class="{
                                                                                'router-link-exact-active router-link-active':
                                                                                    $route.name === viewMappingTypes.MODIFICATION_PAYMENT_DATA_MAPPING.name,
                                                                            }"
                                                                            :to="navigatePaymentData()"
                                                                            >{{ $t("ofex.menuLateral.modificar.datosPago") }}</router-link
                                                                        >
                                                                    </li>
                                                                    <li class="item-second-level mt-2">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :class="{
                                                                                'router-link-exact-active router-link-active':
                                                                                    $route.name ===
                                                                                    viewMappingTypes.MODIFICATION_CORRESPONDENCE_DATA_MAPPING.name,
                                                                            }"
                                                                            :to="navigateCorrespondenceData()"
                                                                            >{{ $t("ofex.menuLateral.modificar.datosCorrespondencia") }}</router-link
                                                                        >
                                                                    </li>
                                                                    <li class="item-second-level mt-2" v-if="isCompany">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :class="{
                                                                                'router-link-exact-active router-link-active':
                                                                                    $route.name === viewMappingTypes.MODIFICATION_OWNER_DATA_MAPPING.name,
                                                                            }"
                                                                            :to="navigateOwnerData()"
                                                                            >{{ $t("ofex.menuLateral.modificar.datosTitular") }}</router-link
                                                                        >
                                                                    </li>
                                                                    <li class="item-second-level mt-2" v-if="hasAnyDomesticContract === true">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :class="{
                                                                                'router-link-exact-active router-link-active':
                                                                                    $route.name ===
                                                                                    viewMappingTypes.INSERT_CERTIFICATE_OCCUPANCY_DATA_MAPPING.name,
                                                                            }"
                                                                            :to="navigateCertificateOccupancy()"
                                                                            >{{ $t("ofex.menuLateral.introduce.cedulaHabitabilidad") }}</router-link
                                                                        >
                                                                    </li>
                                                                    <li class="item-second-level mt-2" v-if="hasAnyIndustrialOrComercialContract === true">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :class="{
                                                                                'router-link-exact-active router-link-active':
                                                                                    $route.name === viewMappingTypes.INSERT_IAE_DATA_MAPPING.name,
                                                                            }"
                                                                            :to="navigateIaeData()"
                                                                            >{{ $t("ofex.menuLateral.introduce.datosIae") }}</router-link
                                                                        >
                                                                    </li>
                                                                    <li class="item-second-level mt-2">
                                                                        <router-link
                                                                            class="link-item"
                                                                            :class="{
                                                                                'router-link-exact-active router-link-active':
                                                                                    $route.name === viewMappingTypes.UNSUSCRIBE_CONTRACT_VIEW_MAPPING.name,
                                                                            }"
                                                                            :to="navigateUnsuscribeContract()"
                                                                            >{{ $t("ofex.menuLateral.bajaContrato") }}</router-link
                                                                        >
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </v-collapse-wrapper>

                                                        <li class="item-first-level" v-if="droughtConfiguration?.enabledAll">
                                                            <router-link
                                                                class="link-item"
                                                                :to="{ name: viewMappingTypes.AFFECTATIONS_CONTRACTS_MAPPING.name }"
                                                                >{{ $t("ofex.menuLateral.afectaciones") }}</router-link
                                                            >
                                                        </li>
                                                        <li class="item-first-level">
                                                            <router-link class="link-item" :to="{ name: viewMappingTypes.NOTIFICATIONS_LIST_MAPPING.name }">{{
                                                                $t("ofex.menuLateral.tusAVisos")
                                                            }}</router-link>
                                                        </li>
                                                        <li class="item-first-level">
                                                            <router-link class="link-item" :to="{ name: viewMappingTypes.AUTHORIZATIONS_VIEW_MAPPING.name }">{{
                                                                $t("ofex.menuLateral.autorizaciones")
                                                            }}</router-link>
                                                        </li>
                                                        <li class="item-first-level">
                                                            <router-link class="link-item" :to="{ name: viewMappingTypes.USER_ACTIONS_MAPPING.name }">{{
                                                                $t("ofex.menuLateral.historialGestiones")
                                                            }}</router-link>
                                                        </li>

                                                        <v-collapse-wrapper>
                                                            <li class="item-first-level">
                                                                <span class="link-item">{{ $t("ofex.menuLateral.nuevoContrato") }}</span>
                                                                <span
                                                                    class="icon-container"
                                                                    :class="{ active: secondLevelFormsActive }"
                                                                    @click="secondLevelFormsActive = !secondLevelFormsActive"
                                                                    v-collapse-toggle
                                                                >
                                                                    <font-awesome-icon class="icon" icon="chevron-down"></font-awesome-icon>
                                                                </span>
                                                                <ul v-collapse-content class="list-second-level">
                                                                    <li class="item-second-level mt-2">
                                                                        <a target="_blank" class="link-item" :href="cambioTitularUrl">
                                                                            {{ $t("ofex.menuLateral.cambioTitular") }}
                                                                        </a>
                                                                    </li>
                                                                    <li class="item-second-level mt-2">
                                                                        <a target="_blank" class="link-item" :href="altaSuministroUrl">
                                                                            {{ $t("ofex.menuLateral.altaSuministro") }}
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </li>
                                                        </v-collapse-wrapper>

                                                        <li class="item-first-level">
                                                            <router-link class="link-item" :to="{ name: viewMappingTypes.RECLAMATION_FORM_MAPPING.name }">{{
                                                                $t("ofex.menuLateral.reclamacion")
                                                            }}</router-link>
                                                        </li>
                                                    </ul>
                                                </v-collapse-group>
                                            </div>
                                        </v-collapse-wrapper>
                                    </nav-bar-renderless>
                                </mq-layout>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        TODO APP revisar esto-->
        <div
            class="container"
            v-if="$isWebApp()"
            v-show="showNavbar && !$router.currentRoute.meta.biometricsLogin && !$router.currentRoute.meta.biometricsRegistered"
        >
            <div class="row">
                <div class="col-12">
                    <AbNavBar />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import AbHeaderLocation from "./AbHeaderLocation";
import AbHeaderUserPanel from "./AbHeaderUserPanel";
import AbHeaderWebLinks from "./AbHeaderWebLinks";
import AbHeaderLanguage from "./AbHeaderLanguage";
import NavBarRenderless from "@/modules/common/NavBarRenderless";
import * as viewMappingTypes from "../../router/view-mapping-types";
import { mapGetters, mapState } from "vuex";
import AbNavBar from "./AbNavBar";
import AbDropdown from "../../components/AbDropdown";

export default {
    name: "HeaderBar",
    components: {
        AbHeaderLocation,
        AbHeaderUserPanel,
        AbHeaderWebLinks,
        AbHeaderLanguage,
        NavBarRenderless,
        AbNavBar,
        AbDropdown,
    },
    data() {
        return {
            viewMappingTypes: viewMappingTypes,
            searchText: "",
            firstLevelActive: false,
            secondLevelInvoicesActive: false,
            secondLevelConsumptionsActive: false,
            secondLevelContractsActive: false,
            secondLevelFormsActive: false,
            open: false,
            showNavbar: true,
            lastScrollPosition: 0,
            AbExternalLinks: [
                { text: this.$i18n.tc("ofex.header.webCorporativa"), path: "https://www.aiguesdebarcelona.cat/es/web/ab-corporativa" },
                { text: this.$i18n.tc("ofex.header.webAguas"), path: "https://www.aiguesdebarcelona.cat/web/guest/aigues-de-barcelona" },
                { text: this.$i18n.tc("ofex.header.areaPrivada"), path: "#" },
            ],
            labelExt: this.$i18n.tc("ofex.header.ir"),
            isWebApp: this.$isWebApp(),
        };
    },
    methods: {
        doSearch: function () {
            window.open(`https://www.aiguesdebarcelona.cat/search?q=${this.searchText}`, "_blank");
        },
        onScroll: function () {
            // Get the current scroll position
            const currentScrollPosition = window.scrollY || document.documentElement.scrollTop; // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
            if (currentScrollPosition < 0) {
                return;
            } // Here we determine whether we need to show or hide the navbar
            this.showNavbar = currentScrollPosition < this.lastScrollPosition; // Set the current scroll position as the last scroll position
            this.lastScrollPosition = currentScrollPosition;
        },
    },
    computed: {
        ...mapGetters(["multicontract"]),
        ofexSignUpUrl: function () {
            return this.$i18n.locale === "es" ? process.env.VUE_APP_SIGNUP_ES_URL : process.env.VUE_APP_SIGNUP_CA_URL;
        },
        cambioTitularUrl: function () {
            return process.env[`VUE_APP_CAMBIO_TITULAR_${this.$i18n.locale.toUpperCase()}_URL`];
        },
        altaSuministroUrl: function () {
            return process.env[`VUE_APP_ALTA_SUMINISTRO_${this.$i18n.locale.toUpperCase()}_URL`];
        },
        menuNativo() {
            let isNative = [];
            if (this.isWebApp) {
                isNative = ["md", "lg"];
            } else {
                isNative = [""];
            }
            return isNative;
        },
        menuNativoSmall() {
            let isNativeSmall = [];
            if (this.isWebApp) {
                isNativeSmall = ["xs", "sm"];
            } else {
                isNativeSmall = ["xs", "sm", "md", "lg"];
            }
            return isNativeSmall;
        },
        ...mapState(["droughtConfiguration"]),
        ...mapGetters(["isAuthenticated", "hasAnyDroughtAffectedContract"]),
    },
    watch: {
        $route() {
            if (this.$refs.menuRootCollapseWrapper && this.$refs.menuRootCollapseWrapper.status === true) {
                this.$refs.menuRootCollapseWrapper.close();
            }
            this.firstLevelActive = false;
        },
    },
    mounted() {
        // if (this.$isNativeApp()) {
        //     window.addEventListener("scroll", this.onScroll);
        // }
    },
    beforeDestroy() {
        // if (this.$isNativeApp()) {
        //     window.removeEventListener("scroll", this.onScroll);
        // }
    },
};
</script>

<style lang="scss" scoped>
header {
    @include respond-below(md) {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        right: 0;
    }
}
.header-top {
    background: $bg-primary;
    z-index: $zindex-fixed;
    position: relative;
}

.header-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    .right-side {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
    }
}
.login-container {
    background: $color-lightest;
    border-radius: 3px;

    .link {
        color: $bg-primary;
        font-weight: $font-primary-extraBold;
        @include font-size(11);
        @include line-height(25);
        padding: 10px;
        position: relative;

        &:first-child:after {
            background: $bg-primary;
            content: "";
            position: absolute;
            right: 0;
            top: 10px;
            bottom: 10px;
            background: #002e6b;
            width: 1px;
        }
    }
}
.wrapper-menu {
    padding: 11px 0 7px 5px;
    margin-left: 20px;
}
.icon-menu {
    width: auto;
    height: 25px;
    color: $color-lightest;

    &.active {
        background-image: url("../../assets/images/svg/close.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
        path {
            fill: transparent;
        }
    }
}
.search-container {
    margin-left: 13px;
}
.native {
    .container-mobile-inner {
        display: flex;
        justify-content: flex-end;
        @include respond-above(md) {
            margin: 25px 0;
        }
    }
    .header-container {
        justify-content: flex-end !important;
    }
}
@include respond-below(md) {
    .v-collapse-group {
        max-width: 720px;
        margin: 0 auto;
    }
    .container-mobile-inner {
        display: flex;
        justify-content: space-between;
        padding-left: 25px;
        padding-right: 25px;
        margin: 12px 0;
    }
    .search-container {
        margin-left: 25px;
        margin-top: 20px;
    }
}
@include respond-below(sm) {
    .v-collapse-group {
        max-width: 540px;
        margin: 0 auto;
    }
}
@include respond-below(md) {
    .menu-mobile {
        background: $bg-primary;
        position: fixed;
        left: 0;
        right: 0;
        top: 50px;
        height: 100%;

        .list-first-level {
            padding: 0 15px 5px 15px;
            overflow-y: auto;
            max-height: calc(79vh - 100px);

            .item-first-level {
                position: relative;
                padding: 15px;
                border-bottom: 1px solid $color-lightest;

                .link-item {
                    color: $color-lightest;
                    @include font-size(16);
                    @include line-height(21);
                    font-weight: $font-primary-medium;
                    position: relative;
                    z-index: 10000000;
                }
                .icon-container {
                    position: absolute;
                    right: 16px;
                    top: 12px;
                    padding: 2px 0 0 10px;
                    .icon {
                        transition: all 0.2s ease-in-out;
                        color: $color-lightest;
                    }
                }
                .icon-container.active {
                    .icon {
                        transition: all 0.2s ease-in-out;
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .list-second-level {
            .vc-collapse {
                margin-top: 20px;
            }
            .item-second-level {
                position: relative;
                padding-left: 23px;
                margin-top: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 5px;
                    background-image: url("../../assets/images/svg/arrow-right-circle.svg");
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                    background-size: 75%;
                }

                .link-item {
                    @include font-size(14);
                }
            }
        }
        .list-third-level {
            margin-left: 20px;
            .vc-collapse {
                margin-top: 20px;
            }
            .item-third-level {
                position: relative;
                margin-top: 20px;
                .link-item {
                    @include font-size(14);
                    @include line-height(16);
                }
            }
        }
    }
}
.native {
    .menu-mobile {
        background: $bg-primary;
        position: fixed;
        left: 0;
        right: 0;
        top: 50px;
        height: 100%;

        .list-first-level {
            padding: 0 15px 5px 15px;
            overflow-y: auto;
            max-height: calc(96vh - 100px);

            .item-first-level {
                position: relative;
                padding: 15px;
                border-bottom: 1px solid $color-lightest;

                .link-item {
                    color: $color-lightest;
                    @include font-size(16);
                    @include line-height(21);
                    font-weight: $font-primary-medium;
                    position: relative;
                    z-index: 10000000;
                }
                .icon-container {
                    position: absolute;
                    right: 16px;
                    top: 12px;
                    padding: 2px 0 0 10px;
                    .icon {
                        transition: all 0.2s ease-in-out;
                        color: $color-lightest;
                    }
                }
                .icon-container.active {
                    .icon {
                        transition: all 0.2s ease-in-out;
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .list-second-level {
            .vc-collapse {
                margin-top: 20px;
            }
            .item-second-level {
                position: relative;
                padding-left: 23px;
                margin-top: 8px;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 5px;
                    background-image: url("../../assets/images/svg/arrow-right-circle.svg");
                    background-repeat: no-repeat;
                    width: 20px;
                    height: 20px;
                    background-size: 75%;
                }

                .link-item {
                    @include font-size(14);
                }
            }
        }
        .list-third-level {
            margin-left: 20px;
            .vc-collapse {
                margin-top: 20px;
            }
            .item-third-level {
                position: relative;
                margin-top: 20px;
                .link-item {
                    @include font-size(14);
                    @include line-height(16);
                }
            }
        }
    }
}
@supports (-webkit-touch-callout: none) {
    .menu-mobile {
        top: 0;
        padding-top: 0;
    }
}
</style>
<style lang="scss">
.search-container {
    display: flex;
    position: relative;
    margin-right: 30px;

    input {
        border: 1px solid $bg-primary;
        border-radius: 5px 0 0 5px;
        width: 115px;
        padding-right: 5px;

        @include respond-above(lg) {
            width: 135px;
        }
        @include respond-between(md, lg) {
            width: 100px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .search-button {
        cursor: pointer;
        position: absolute;
        top: 2px;
        left: auto;
        right: 5px;
        background: $color-lightest;
        border: 0 none;
        @include font-size(14);

        @include respond-below(lg) {
            background: $bg-grey-light;
            border: 0 none;
            top: 5px;
        }
        @include respond-above(sm) {
            top: 2px;
        }
    }
    .icon {
        color: $color-primary;
    }

    @include respond-below(md) {
        margin-right: 25px;
        margin-left: 25px;
    }
    @include respond-below(md) {
        margin-top: 15px;
        margin-bottom: 20px;
    }
}
.input-searcher {
    input {
        border-radius: 5px;
        width: 240px;
        padding-right: 28px;
    }
    @include respond-below(lg) {
        width: 100%;

        input {
            width: 100%;
            background: $bg-grey-light;
            padding-right: 34px;
            border: 0 none;
        }
    }
}

.native {
    .header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        right: 0;

        .header-top {
            .container {
                @include respond-above(md) {
                    padding: 0;
                }
            }
        }
    }
}

.iosHeader {
    bottom: 0 !important;
    top: initial !important;
    display: flex;
    flex-direction: column-reverse;

    .header-top {
        .header-container .right-side {
            flex-basis: 100%;
            justify-content: center;

            .user-panel-container .logged-container .notification-container {
                margin-right: 65px;

                .bp-dropdown__body {
                    top: auto !important;
                    bottom: 90px;

                    @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
                        bottom: 70px;
                    }
                }
            }

            .wrapper-menu {
                margin-left: 65px;
            }
        }
        .header-container {
            height: 90px;

            @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
                height: 70px;
            }
        }
    }
}

.iosMenu {
    top: auto !important;
    bottom: 90px;
    height: calc(100% - 90px) !important;

    @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
        height: calc(100% - 70px) !important;
        bottom: 70px;
    }

    .list-first-level {
        max-height: calc(84vh - 90px) !important;

        @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
            max-height: calc(88vh - 70px) !important;
        }
    }
    .v-collapse-group {
        margin-top: 45px;
        @media only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
            margin-top: 0;
        }
    }
}
</style>
