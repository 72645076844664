<template>
    <div class="m-your-consumption box" v-show="stillLoading || showWhenNotData || !noData">
        <div class="your-consumption-inner" v-if="stillLoading">
            <div class="item item-loading">
                <ab-loading
                    :class-father="'consumption-view'"
                    :full-page="false"
                    :opacity="0"
                    :loader-text="this.$t('ofex.loadingText.consumos')"
                    v-model="stillLoading"
                ></ab-loading>
            </div>
        </div>
        <div class="your-consumption-inner" v-if="!stillLoading && noData">
            <div class="item">
                <p class="title">{{ $t("ofex.detalleContrato.noDisponible") }}</p>
            </div>
        </div>
        <div class="your-consumption-inner" v-else>
            <div class="item" v-if="weekConsumption !== null">
                <p class="title">
                    {{ $t("ofex.home.tusConsumos.semana") }}
                </p>
                <p class="text">
                    {{ $t("ofex.home.tusConsumos.llevasConsumido") }}
                </p>
                <p class="consumption" v-html="this.$formatConsumption(weekConsumption, true)"></p>
            </div>
            <div class="item" v-if="monthConsumption !== null">
                <p class="title">
                    {{ $t("ofex.home.tusConsumos.mes") }}
                </p>
                <p class="text">
                    {{ $t("ofex.home.tusConsumos.hasConsumido") }}
                </p>
                <p class="consumption" v-html="this.$formatConsumption(monthConsumption, true)"></p>
            </div>
        </div>
        <p class="data-time mt-3" v-if="updateMoment">
            {{ $t("ofex.home.consumoActualizado") }}<span class="date">{{ updateMoment.format("DD-MM-YYYY HH:mm") }}</span>
        </p>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import ConsumptionsServiceClient from "@/rest/consumptionsServiceClient.js";
import { AB_DATETIME_FORMAT } from "@/plugins/ab-utils";

export default {
    name: "AbYourConsumption",
    props: {
        showWhenNotData: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
        return {
            updateMoment: null,
            weekConsumption: null,
            monthConsumption: null,
            loadingWeek: false,
            loadingMonth: false,
        };
    },
    computed: {
        ...mapState(["selectedContractNumber", "isLoading"]),
        ...mapGetters(["selectedContractTelerecording", "clientId", "userId"]),
        stillLoading: function () {
            // Mostramos el loader del componente si está cargando los consumos diarios/semanales/mensuales y no está activo el loader global
            return (this.loadingWeek || this.loadingMonth) && !this.isLoading;
        },
        noData: function () {
            return this.weekConsumption === null && this.monthConsumption === null;
        },
    },
    methods: {
        loadConsumptions: function () {
            let vueContext = this;
            let startTomorrowFormated = moment().add(1, "day").startOf("day").format("DD-MM-YYYY");
            let startOfMonth = moment().startOf("month").format("DD-MM-YYYY");
            let showNegativeValues = false;
            this.weekConsumption = null;
            this.monthConsumption = null;
            this.loadingWeek = true;
            this.loadingMonth = true;

            ConsumptionsServiceClient.getDailyConsumptions(
                this.selectedContractNumber,
                this.clientId,
                this.userId,
                this.$i18n.locale,
                moment().startOf("week").format("DD-MM-YYYY"),
                startTomorrowFormated,
                showNegativeValues
            ).then((response) => {
                vueContext.loadingWeek = false;
                vueContext.calculateDailyConsumption(response.data, "weekConsumption");
            });

            ConsumptionsServiceClient.getDailyConsumptions(
                this.selectedContractNumber,
                this.clientId,
                this.userId,
                this.$i18n.locale,
                startOfMonth,
                startTomorrowFormated,
                showNegativeValues
            ).then((response) => {
                vueContext.loadingMonth = false;
                vueContext.calculateDailyConsumption(response.data, "monthConsumption");
            });
        },
        calculateDailyConsumption(consumptionValues, dataName) {
            if (consumptionValues.length > 0) {
                this[dataName] += consumptionValues.map((x) => x.deltaConsumption).reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                let monthNewestDate =
                    consumptionValues.length > 0 ? moment(consumptionValues[consumptionValues.length - 1].datetime, AB_DATETIME_FORMAT) : null;
                this.updateMoment = this.updateMoment ? moment.max([monthNewestDate, this.updateMoment]) : monthNewestDate;
            }
        },
    },
    mounted() {
        this.loadConsumptions();
    },
};
</script>

<style lang="scss" scoped>
.notification-loader {
    position: relative;

    svg {
        width: 13px;
        height: 13px;
        position: absolute;
        left: 0;
        stroke: $color-lightest;
    }
    .vld-overlay .vld-icon,
    .vld-parent {
        position: relative;
        height: 13px;
        width: 13px;
    }
    .vld-overlay.is-full-page {
        position: relative;
    }
}
.m-your-consumption {
    .data-time {
        color: $blue-dark;
        text-align: right;
        font-weight: $font-primary-bold;
        @include font-size(14);
        @include line-height(16);

        @include respond-below(sm) {
            text-align: center;
        }

        .time,
        .date {
            margin-left: 5px;
        }
    }
    .your-consumption-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include respond-above(sm) {
            flex-wrap: nowrap;
        }

        .item-loading {
            height: 90px;
        }

        .item {
            flex: 100%;
            color: $blue-dark;
            text-align: center;
            border-bottom: 1px solid $blue-dark;
            padding: 0 10px 30px;
            margin-bottom: 30px;

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }

            @include respond-above(sm) {
                border-right: 1px solid $blue-dark;
                border-bottom: 0;
                padding-bottom: 0;

                &:last-child {
                    border-right: 0;
                }
            }

            .title {
                font-weight: $font-primary-bold;
                @include font-size(24);
                @include line-height(28);
                margin-bottom: 5px;
            }

            .text {
                font-weight: $font-primary-medium;
                @include font-size(22);
                @include line-height(25);
            }

            .consumption {
                font-weight: $font-primary-bold;
                @include font-size(36);
                @include line-height(42);
                margin-bottom: 0;
            }
        }
    }
}
</style>
<style lang="scss">
.consumption-view .vld-overlay {
    height: 125px !important;
    width: 100%;
}
</style>
