<template>
    <div>
        <p class="subtitle">{{ $t("ofex.facturaDigital.subtitleDesactivada") }}</p>
        <div class="box-grey">
            <img class="img-ticket" src="@/assets/images/ofex/factura-digital/ticket.png" alt="imagen ticket factura digital" />
            <span class="title-box">{{ $t("ofex.facturaDigital.desactivada") }}</span>
            <ValidationObserver ref="invoiceDigitalForm" v-slot="{ reset }">
                <div class="space-row d-xl-flex justify-content-between">
                    <!-- Ab Legal section-->
                    <div class="d-flex flex-column">
                        <div class="height-check">
                            <ab-legal-section
                                :father-class="'v-invoice-digital'"
                                ref="legalSection"
                                :fsp-es="legalUrls.fspEs"
                                :fsp-ca="legalUrls.fspCa"
                                :gdpr-es="legalUrls.gdprEs"
                                :gdpr-ca="legalUrls.gdprCa"
                                :legal-es="legalUrls.urEs"
                                :legal-ca="legalUrls.urCa"
                                :show-gdpr="false"
                                :show-fsp="true"
                                :show-legal="false"
                                :reference="'check-legal'"
                                :legal-label-linked="$t('ofex.facturaDigital.condicionesFSP')"
                            ></ab-legal-section>
                        </div>
                    </div>
                    <!--boton activar -->
                    <div class="btn-activate">
                        <ab-button-ofex
                            :disabled="active"
                            class="btn-activate"
                            :click-event="successfullyActivated"
                            :text-button="$t('ofex.facturaDigital.activar')"
                            text="boton activar factura digital"
                        ></ab-button-ofex>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import * as actionTypes from "@/store/action-types";
import { SET_LOADING_MUTATION } from "@/store/mutation-types";
import { mapGetters, mapState } from "vuex";
import InvoiceFspPdfGeneator from "@/generators/pdf/InvoiceFspPdfGeneator";
import * as viewMappings from "@/router/view-mapping-types";
import LiferayServiceClient from "@/rest/liferayServiceClient";
export default {
    name: "AbDigitalInvoiceDisabled",
    computed: {
        ...mapState(["isLoading", "contracts", "clientData"]),
        ...mapGetters(["clientId", "userId", "legalUrls"]),
    },
    props: {
        active: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        successfullyActivated: function () {
            console.debug("Activar Factura");
            let validator = this.$refs.invoiceDigitalForm;
            validator.validate().then(async (result) => {
                if (result) {
                    let contractNumbers = this.contracts.map((value) => value.contractDetail.contractNumber);
                    let urls = await LiferayServiceClient.getLegalUrls(this.$i18n.locale);
                    let fspUrl = urls.fsp;
                    let pdfGenerator = new InvoiceFspPdfGeneator(this.$i18n, this.userId, this.clientId, "");
                    pdfGenerator.generatePdf(contractNumbers, fspUrl, true);
                    await pdfGenerator.uploadPdf();
                    this.$store
                        .dispatch(actionTypes.UPDATE_FSP, {
                            active: "ACTIVE_FSP",
                            fspUrl: fspUrl,
                            pdf: pdfGenerator.uploadedDocumentId,
                            contractsNumbers: contractNumbers,
                        })
                        .then(() => {
                            this.$notifyFormActionToMatomo(true, "fsp_enabled_modification");
                            this.$store.dispatch(actionTypes.GET_USER_CONTRACTS_ACTION, { locale: this.$i18n.locale });
                            console.debug("Datos Modificados");
                            Swal.fire({
                                showCloseButton: true,
                                showCancelButton: false,
                                text: this.$t("formularios.habilitarFacturaDigital.sendMessage"),
                                icon: "success",
                                confirmButtonText: this.$t("common.okButton"),
                                html:
                                    this.$i18n.t("ofex.facturaDigital.modalMonoActivate1", { email: this.clientData.email }) +
                                    this.$i18n.t("ofex.facturaDigital.modalActivate", {
                                        windowLocationPathname: window.location.pathname,
                                        modifyUserUrl: viewMappings.USER_VIEW_DATA_MAPPING.path,
                                    }),
                            });
                        })
                        .catch((reason) => {
                            this.launchUpdateFSP(reason);
                            this.$notifyFormActionToMatomo(false, "fsp_enabled_modification", reason?.response?.data?.errorMessage);
                        });
                }
            });
        },
        launchUpdateFSP: function (e) {
            console.log("Lanzando mensaje de error");
            this.$store.commit(SET_LOADING_MUTATION, { isLoading: false, trigger: "InvoiceDigitalView" });
            Swal.fire({
                titleText: this.$i18n.tc("ofex.toasts.changedError"),
                text: e.response && e.response.data ? e.response.data.errorMessage : this.$i18n.tc("ofex.common.unexpectedErrorMessage"),
                icon: "error",
                confirmButtonText: "OK",
            });
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/digital-invoice.scss";
</style>
