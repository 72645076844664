import { render, staticRenderFns } from "./AbBoxErrorLogin.vue?vue&type=template&id=171dc9a6&scoped=true"
import script from "./AbBoxErrorLogin.vue?vue&type=script&lang=js"
export * from "./AbBoxErrorLogin.vue?vue&type=script&lang=js"
import style0 from "./AbBoxErrorLogin.vue?vue&type=style&index=0&id=171dc9a6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "171dc9a6",
  null
  
)

/* custom blocks */
import block0 from "../../components/common/i18n-components.i18n?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Farnaldo%2FProyectosOficiales%2FZener%2FAguasDeBarcelona%2Fprojects%2Fab-ofex-components%2Fsrc%2Fmodules%2Fcommon%2FAbBoxErrorLogin.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports