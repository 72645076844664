<template>
    <div class="m-advance-search">
        <div class="contract-info">
            <div class="text">
                <span class="dark-text">{{ $t("ofex.advanceSearch.tens") }}</span>
                <span class="dark-text" style="font-variant-numeric: lining-nums;">{{ total }}</span>
                <span class="dark-text">{{ $t("ofex.advanceSearch.contractsAuth") }}</span>
                <span v-if="total != rows" class="dark-text">{{ $t("ofex.advanceSearch.infoContractFilter", { filter: rows }) }}</span>
            </div>
            <div class="text" v-on:click="show = !show" :class="show ? 'active' : ''">
                <span>{{ $t("ofex.advanceSearch.advanceSearchText") }}</span>
                <font-awesome-icon class="icon" icon="chevron-down"></font-awesome-icon>
            </div>
        </div>
        <transition name="slide-fade">
            <div class="dropdown" v-if="show">
                <div class="dropdown-inner">
                    <div class="search-select-container">
                        <ab-autocompletable-select
                            class="your-contract"
                            v-model="filter.municipality"
                            name="selectMunicipality"
                            input-alias="municipio"
                            :placeholder="$t('common.todos')"
                            :data-list="contractMunicipalityList"
                            :label="$t('ofex.advanceSearch.municipio')"
                        />
                        <div class="space-height"></div>
                        <ab-autocompletable-select
                            name="selectStreet"
                            input-alias="calle"
                            v-model="filter.streetName"
                            :placeholder="$t('common.todos')"
                            :data-list="contractStreetNameList"
                            :readonly="filter.municipality === null || typeof filter.municipality === 'undefined'"
                            :label="$t('ofex.advanceSearch.calle')"
                        />
                        <p class="info" v-if="!filter.municipality && !filter.streetName">{{ $t("ofex.advanceSearch.information") }}</p>
                    </div>
                    <div class="search-input-container">
                        <ab-autocompletable-select
                            class="your-contract"
                            name="search-contract-number"
                            input-alias="numero de contrato"
                            v-model="filter.contractNumber"
                            :placeholder="$t('common.todos')"
                            :data-list="contractNumberList"
                            :label="$t('ofex.advanceSearch.numContract')"
                        />
                        <div class="space-height"></div>
                        <ab-autocompletable-select
                            name="search-alias"
                            input-alias="alias"
                            v-model="filter.alias"
                            :placeholder="$t('common.todos')"
                            :data-list="contractAliasList"
                            :label="$t('ofex.advanceSearch.alias')"
                        />
                    </div>
                    <div class="search-checkbox-container">
                        <ab-checkbox
                            v-if="show_checkbox"
                            v-model="filter.auth"
                            :label="$t('ofex.advanceSearch.authorized')"
                            name="auth_on"
                            unchecked-value="null"
                        />
                        <ab-checkbox
                            v-if="show_checkbox"
                            v-model="filter.notAuth"
                            :label="$t('ofex.advanceSearch.notAuthorized')"
                            name="auth_off"
                            unchecked-value="null"
                        />
                    </div>
                    <div class="search-button-container">
                        <a href="#" @click.prevent="clearFilter" class="link">{{ $t("ofex.advanceSearch.removeFilters") }}</a>
                        <div class="button-container-inner">
                            <ab-button-ofex :click-event="filterContracts" :text-button="$t('ofex.advanceSearch.search')"></ab-button-ofex>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import "vue2-datepicker/locale/ca";
import "vue2-datepicker/locale/es";
import "vue2-datepicker/index.css";
import AbAutocompletableSelect from "../../components/AbAutocompletableSelect.vue";
import uniq from "lodash/uniq";
import compact from "lodash/compact";

export default {
    name: "",
    components: {
        AbAutocompletableSelect,
    },
    props: {
        total: Number,
        rows: Number,
        show_checkbox: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            show: false,
            filter: {
                municipality: null,
                streetName: null,
                contractNumber: null,
                alias: null,
                auth: null,
                notAuth: null,
            },
        };
    },
    methods: {
        filterContracts: function () {
            this.$emit("filter-contracts", this.filter);
        },
        clearFilter: function () {
            this.filter = {
                municipality: null,
                streetName: null,
                contractNumber: null,
                alias: null,
                auth: null,
                notAuth: null,
            };
            this.$emit("clear-filter", this.filter);
        },
    },
    computed: {
        ...mapGetters(["selectedContractTelerecording"]),
        ...mapState(["contracts"]),
        contractMunicipalityList: function () {
            return uniq(this.contracts.map((contract) => contract.supplyAddress.municipality));
        },
        contractStreetNameList: function () {
            return uniq(
                this.contracts
                    .filter((contract) => contract.supplyAddress.municipality === this.filter.municipality)
                    .map((contract) => contract.supplyAddress.streetName)
                    .sort()
            );
        },
        contractNumberList: function () {
            return compact(uniq(this.contracts.map((contract) => contract.contractDetail.contractNumber)));
        },
        contractAliasList: function () {
            return compact(uniq(this.contracts.map((contract) => contract.contractDetail.alias)));
        },
    },
};
</script>

<style lang="scss" scoped>
.dark-text {
    color: #333333;
    cursor: text;
}
.m-advance-search {
    .contract-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 1px solid $link-primary;
        padding-bottom: 20px;

        @include respond-above(sm) {
            margin-bottom: 0;
        }
        .contract-info-inner {
            color: $color-primary;
            font-weight: $font-primary-bold;
            @include font-size(18);
            @include line-height(21);

            @include respond-below(md) {
                margin-bottom: 10px;
            }

            .text-filter {
                margin-left: 15px;
            }
        }
    }
    .text {
        display: flex;
        align-items: center;
        color: $blue-dark;
        font-weight: $font-primary-bold;
        @include font-size(16);
        @include line-height(19);
        cursor: pointer;

        @include respond-below(sm) {
            margin-bottom: 10px;
        }
        .icon {
            transition: all 0.2s ease-in-out;
        }
        &.active {
            .icon {
                transform: rotate(180deg);
            }
        }
        span {
            margin-right: 5px;
        }
        .icon {
            margin-right: 7px;
        }
    }
    .dropdown {
        position: relative;
        flex: 100%;
        background: #f8f8f8;

        .icon {
            width: 17px;
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;

            @include respond-above(sm) {
                width: 10px;
            }
        }
        .dropdown-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px;

            @include respond-above(sm) {
                padding: 20px;
            }
            .search-button-container {
                display: flex;
                flex-basis: 100%;
                justify-content: flex-end;
                align-items: center;

                @include respond-below(sm) {
                    justify-content: space-between;
                    margin-top: 25px;
                }
                .link {
                    color: $button-bg-primary;
                    font-weight: $font-primary-semiBold;
                    @include font-size(16);
                    @include line-height(21);
                    padding: 8px;
                    margin-right: 30px;

                    @include respond-below(sm) {
                        flex: 1;
                        text-align: center;
                    }
                }
                .button-container-inner {
                    @include respond-below(sm) {
                        flex: 1;
                    }
                }
            }
            .search-select-container {
                flex-basis: 100%;
                position: relative;

                .select-label {
                    margin: 0;
                }
                .select-component {
                    &:first-child {
                        margin-bottom: 20px !important;
                    }
                }
                @include respond-above(sm) {
                    flex-basis: 35%;
                    padding-right: 30px;
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 27px;
                        width: 1px;
                        height: 110px;
                        background: $blue-dark;
                    }
                }
                .info {
                    color: $color-primary;
                    @include font-size(11);
                    @include line-height(18);
                }
                .multiselect__select {
                    height: 32px;
                    text-align: right;
                    right: 0;
                }
            }
            .search-input-container {
                flex-basis: 100%;
                position: relative;
                margin-bottom: 20px;

                .input-container {
                    margin-bottom: 20px;
                }
                @include respond-above(sm) {
                    flex-basis: 35%;
                    padding-right: 30px;
                    padding-left: 30px;
                    margin-bottom: 0;
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 27px;
                        width: 1px;
                        height: 110px;
                        background: $blue-dark;
                    }
                }
            }
            .search-checkbox-container {
                flex-basis: 100%;

                .checkbox-group {
                    margin-bottom: 10px;
                }
                @include respond-above(sm) {
                    flex-basis: 30%;
                    padding-left: 30px;
                    padding-top: 27px;
                }
            }
        }
    }
    .slide-fade-enter-active {
        max-height: 500px;
        transition: max-height 0.3s ease-in-out;
    }
    .slide-fade-leave-active {
        max-height: 500px;
        transition: max-height 0.3s ease-in-out;
    }
    .slide-fade-enter,
    .slide-fade-leave-to {
        max-height: 0;
        transition: max-height 0.1s ease-in-out;
        opacity: 0;
    }
}
</style>
