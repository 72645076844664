var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"line-bottom"},[_c('span',{staticClass:"label-black"},[_vm._v(_vm._s(_vm.$t("ofex.invoicePaymentByContractView.tuContrato"))+" "),_c('span',{staticClass:"value-address ml-1"},[_vm._v(" "+_vm._s(_vm.contractAddressHeader))])])]),(_vm.unpaidInvoices)?_c('vue-good-table',{staticClass:"mb-space-table",class:_vm.fatherClass,attrs:{"columns":_vm.columns,"rows":_vm.tableRows,"style-class":"vgt-table","sort-options":{
            enabled: true,
            initialSortBy: { field: 'issueDate', type: 'asc' },
        },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
            perPageDropdown: [10, 20, 30],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            nextLabel: this.$i18n.tc('table.siguiente'),
            prevLabel: this.$i18n.tc('table.atras'),
            rowsPerPageLabel: this.$i18n.tc('ofex.tuContrato.tablePagination'),
            ofLabel: 'de',
            allLabel: this.$i18n.tc('ofex.tuContrato.todo'),
            label: 'selector-de-paginas',
            name: 'perPageSelect',
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'fullInvoiceNumber')?_c('span',[_c('span',{staticClass:"pending"},[_vm._v(_vm._s(props.row.fullInvoiceNumber))])]):(props.column.field == 'status')?_c('span',[_c('router-link',{attrs:{"to":`/tuscontratos/${_vm.contractNumber}/tusfacturas/${props.row.invoiceNumber}/pagar-facturas`}},[_c('span',{staticClass:"pending"},[_vm._v(_vm._s(_vm.$t("ofex.tuContrato.pagar")))])]),_c('font-awesome-icon',{staticClass:"icon-pay",attrs:{"icon":"arrow-right"}})],1):(props.column.field == 'consumption')?_c('div',[_c('span',{staticClass:"text"},[_vm._v(_vm._s(props.row.consumption))]),_vm._v(" m"),_c('sup',[_vm._v("3")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,3620083830)}):_vm._e(),_c('div',{staticClass:"payment-all-invoices d-flex align-items-center"},[_c('div',{staticClass:"btn-payment-acordion"},[_c('ab-button-ofex',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-payment-method-contract' + _vm.contractNumber),expression:"'collapse-payment-method-contract' + contractNumber"}],staticClass:"btn-white",attrs:{"font-awesome-icon":'euro-sign',"text-button":_vm.$t('ofex.invoicePaymentByContractView.titleButton')}})],1),_c('div',[_c('span',{staticClass:"text-payment-all"},[_vm._v(_vm._s(_vm.$t("ofex.invoicePaymentByContractView.totalPendiente"))+" "),_c('span',{staticClass:"number-payment-all"},[_vm._v(_vm._s(_vm.$formatNumber(_vm.totalAmount))+"€")])])])]),_c('b-collapse',{attrs:{"id":'collapse-payment-method-contract' + _vm.contractNumber,"accordion":"ab-payment-methods-accordion"}},[_c('div',{staticClass:"box-accordion pl-4 pr-4"},[_c('ab-payment-methods-accordion',{attrs:{"multiple-payment":true,"contract-number":_vm.contractNumber,"collapse-name":_vm.collapseName,"initial-load-barcode":_vm.$parent.visible,"invoices":_vm.unpaidInvoiceNumbersWithYear}})],1)]),_c('div',{staticClass:"line-bottom mt-5"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }