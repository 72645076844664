import Vue from "vue";
import VueI18n from "vue-i18n";
import es from "vee-validate/dist/locale/es.json";
import ca from "vee-validate/dist/locale/ca.json";
import merge from "lodash/merge";
import { Capacitor } from "@capacitor/core";
import { getPreferenceValue, savePreference } from "@/services/nativePreferences";
import { NATIVE_LOCALE_PREFERENCE_KEY } from "@/services/services-constant-types";

Vue.use(VueI18n);

function loadLocaleMessages() {
    const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    const validationMessages = {
        es: {
            validations: es.messages,
        },
        ca: {
            validations: ca.messages,
        },
    };
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1].slice(-2).toLowerCase();
            messages[locale] = merge(messages[locale], locales(key)); //TODO revisar que esto puede dar problemas de rendimientos
        }
    });

    let mergedMessages = merge(messages, validationMessages);

    return mergedMessages;
}

//obtenemos el locale de la URL
let vueLocale = window.location.pathname.startsWith("/es/") ? "es" : window.location.pathname.startsWith("/ca/") ? "ca" : process.env.VUE_APP_I18N_LOCALE;

if (Capacitor.isNativePlatform()) {
    getPreferenceValue(NATIVE_LOCALE_PREFERENCE_KEY).then((lang) => {
        if (!lang) {
            savePreference(NATIVE_LOCALE_PREFERENCE_KEY, vueLocale).then(() => console.debug("Almacenado idioma en preferencias nativas"));
        }
    });
}

const i18n = new VueI18n({
    locale: typeof ofexLiferayLocale !== "undefined" ? ofexLiferayLocale : vueLocale,
    fallbackLocale: "ca",
    messages: loadLocaleMessages(),
});

export default i18n;
