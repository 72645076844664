import qs from "qs";
import BaseOfexServiceClient from "./BaseOfexServiceClient";
import axios from "axios";

const LIFERAY_INTERNAL_BASE_URL = process.env.VUE_APP_API_LIFERAY_INTERNAL_BASE_URL;

export default {
    getNavigationMenu: async function (lang = "es") {
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.get(`${LIFERAY_INTERNAL_BASE_URL}/liferay/navigation-menu`, {
            params: {
                lang: lang,
            },
            //https://github.com/axios/axios/issues/604#issuecomment-321460450
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        });
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            //Respuesta en la validación del token
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
    getLegalUrls: async function (lang = "es") {
        let abRestClient = new BaseOfexServiceClient(false).abRestClient;
        let response = await abRestClient.get(`${LIFERAY_INTERNAL_BASE_URL}/liferay/html/legal-urls`, {
            params: {
                lang: lang,
            },
            //https://github.com/axios/axios/issues/604#issuecomment-321460450
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        });
        if (response.status === 200 || response.status === 202 || response.status === 204) {
            //Respuesta en la validación del token
            return {
                ur: `${window.location.protocol}//${window.location.host}${response.data.ur}`,
                fsp: `${window.location.protocol}//${window.location.host}${response.data.fsp}`,
                gdpr: `${window.location.protocol}//${window.location.host}${response.data.gdpr}`,
            };
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
    getLegalHtml: async function (url, lang = "es") {
        console.log("Obteniendo HTML legal");

        let abRestClient = axios.create();

        let response = await abRestClient.get(url, {
            params: {
                lang: lang,
            },
            headers: {
                Accept: "text/html; charset=UTF-8",
            },
            //https://github.com/axios/axios/issues/604#issuecomment-321460450
            paramsSerializer: function (params) {
                return qs.stringify(params, { arrayFormat: "repeat" });
            },
        });

        if (response.status === 200) {
            //Respuesta en la validación del token
            return response.data;
        }
        //si la respuesta no ha sido 200 lanzamos un error
        throw response.statusText;
    },
};
