<i18n src="./common/i18n-components.i18n"></i18n>

<template>
    <div class="row">
        <div class="col-12 col-lg-3" aria-hidden="true">
            <ab-select
                v-model="typeValue"
                :name="validationTypeName"
                :input-alias="typeInputAlias"
                :placeholder="$t('idTypeNumber.tipoDocPlaceholder')"
                :validation="{ required: true }"
                :data-list="[
                    { key: 'NIF', text: 'NIF' },
                    { key: 'NIE', text: 'NIE' },
                    { key: 'CIF', text: 'CIF' },
                ]"
                :editable="false"
                :show-row="false"
                :label="$t('idTypeNumber.tipoDocumento')"
            >
            </ab-select>
        </div>

        <div class="col-12 col-lg-3">
            <ab-input
                v-model="numberValue"
                :name="validationNumberName"
                :input-alias="numberInputAlias"
                :placeholder="
                    allowNifNie && allowCif
                        ? $t('idTypeNumber.numDocPlaceholder')
                        : allowCif
                        ? $t('idTypeNumber.empresaNumDocPlaceholder')
                        : $t('idTypeNumber.onlyNifPlaceholder')
                "
                :validation-delay="1000"
                :validation="{ required: true, spanishdocument: { allowNifNie: allowNifNie, allowCif: allowCif } }"
                :label="labelNumberDocument"
                :readonly="readonly"
                :event-blur="eventBlur"
            >
            </ab-input>
        </div>
    </div>
</template>

<script>
import AbInput from "./AbInput.vue";
import AbSelect from "./AbSelect.vue";
import validateSpanishDoc from "./common/cif-validator";

export default {
    components: {
        AbInput,
        AbSelect,
    },
    model: {
        prop: "typeNumberValue",
        event: "change",
    },
    props: {
        allowNifNie: {
            default: true,
            type: Boolean,
        },
        allowCif: {
            default: true,
            type: Boolean,
        },
        validationTypeName: String,
        validationNumberName: String,
        validationAppend: {
            default: null,
            type: Object,
        },
        remoteValidation: {
            default: false,
            type: Boolean,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        typeInputAlias: {
            type: String,
            default: function () {
                return this.$i18n.tc("alias.idTypeNumber.tipoDocumento");
            },
        },
        labelNumberDocument: {
            type: String,
            default: function () {
                return this.$i18n.tc("idTypeNumber.numeroDocumento");
            },
        },
        numberInputAlias: {
            type: String,
            default: function () {
                return this.$i18n.tc("alias.idTypeNumber.numeroDocumento");
            },
        },
        eventBlur: {
            type: Function,
            default: function () {},
        },
    },
    data: function () {
        return {
            doc: {
                type: "",
                number: "",
                exists: false,
                isValid: false,
            },
        };
    },
    computed: {
        typeValue: {
            get: function () {
                return this.$attrs["typeNumberValue"].type;
            },
            set: function (newValue) {
                console.debug("Changing docnumber type + " + newValue);
                this.doc.type = newValue;
                this.$emit("change", this.doc);
            },
        },
        numberValue: {
            get: function () {
                return this.$attrs["typeNumberValue"].number;
            },
            set: function (newValue) {
                console.debug("Changing docnumber number + " + newValue);
                this.doc.number = newValue;
                this.$emit("change", this.doc);
            },
        },
    },
    watch: {
        "doc.number": function (val) {
            this.validateDoc(val);
        },
        validationAppend: {
            handler: function (newAppend) {
                console.debug("Cambiando append: " + newAppend.number);
                this.validateIfExists(this.doc.number, newAppend);
            },
            deep: true,
        },
    },
    methods: {
        validateIfExists: function (val, toAppend) {
            console.debug("Change number and validating");

            //verificamos que el usuario existe
            if (this.doc.isValid && this.remoteValidation) {
                let appended = val;
                if (toAppend != null) {
                    //TODO revisar === 9 ó > 1
                    if (toAppend.isValid === true && toAppend.number.length === 9) {
                        appended = val + toAppend.number;
                    } else {
                        return;
                    }
                }

                console.log("Checking if user exist: " + appended);
                var vueContext = this;
                this.$root.$checkUserExists(appended).then(function (exists) {
                    vueContext.doc.exists = exists;
                });
            } else {
                this.doc.exists = false;
            }
        },
        validateDoc: function (val) {
            let result = validateSpanishDoc(val, this.allowCif, this.allowNifNie);
            this.doc.isValid = result.valid;

            this.validateIfExists(val, this.validationAppend);

            this.doc.type = typeof result.type !== "undefined" ? result.type : "";
        },
    },
};
</script>
