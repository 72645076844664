<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { AB_DATE_FORMAT, calculateChartMaxYAxis } from "@/plugins/ab-utils";
import slice from "lodash/slice";
import zip from "lodash/zip";
import moment from "moment";
import { isBrowser } from "mobile-device-detect";
import { Capacitor } from "@capacitor/core";
import { executeAccessibilityFixes } from "@/plugins/accesibility";

export default {
    extends: Bar,
    props: {
        userInvoices: {
            type: Array,
            required: true,
        },
        /*invoicesFrequency: {
            type: String,
            default: function () {

            },
        },*/
        showLegend: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
        return {
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
        };
    },
    methods: {
        downloadPdf: function (e, data) {
            if (isBrowser) {
                //TODO sacar a método global de utilidades
                if (data.length > 0) {
                    let activeElement = data[0]._chart.getElementAtEvent(e);
                    console.debug(activeElement);
                    if (activeElement.length > 0) {
                        let selectedInvoice = this.getInvoiceByDatasetIndex(activeElement[0]._datasetIndex, activeElement[0]._index);
                        console.debug(selectedInvoice);
                        this.$downloadInvoice({
                            invoiceNumber: selectedInvoice.invoiceNumber,
                            invoiceYear: selectedInvoice.invoiceYear,
                            locale: this.$i18n.locale,
                        });
                    }
                }
            }
        },
        renderInvoiceEvolutionChart: function () {
            console.log("Creando gráfica de evolucion de facturas");

            this.addPlugin(ChartDataLabels);

            var vueContext = this;

            let invoicesEvolutionBarOptions = {
                plugins: {
                    // Change options for ALL labels of THIS CHART
                    datalabels: {
                        display: function (context) {
                            return !Capacitor.isNativePlatform() && context.dataset.data[context.dataIndex] > 0;
                        },
                        labels: {
                            // symbol: {
                            //     anchor: "center",
                            //     clamp: true,
                            //     color: "#ffffff",
                            //     font: {
                            //         family: "faGraph",
                            //     },
                            //     formatter: function (value, context) {
                            //         let selectedInvoice =
                            //             context.datasetIndex === 0 ? vueContext.secondDataset[context.dataIndex] : vueContext.firstDataset[context.dataIndex];
                            //         return selectedInvoice.estimatedReading === true ? "\uf1da" : "";
                            //     },
                            // },
                            value: {
                                anchor: "end",
                                clamp: true,
                                align: "top",
                                font: {
                                    weight: "bold",
                                    family: "Raleway",
                                },
                                formatter: function (value) {
                                    return Math.trunc(value);
                                },
                            },
                        },
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                onClick: this.downloadPdf,
                hover: {
                    mode: "point",
                },
                legend: {
                    display: this.showLegend,
                    position: "bottom",
                },
                animation: {
                    animateScale: true,
                    animateRotate: true,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                // Include a dollar sign in the ticks
                                callback: function (value) {
                                    return value + " €";
                                },
                            },
                        },
                    ],
                    xAxes: [
                        {
                            // barThickness: 40,
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                // maxRotation: 0,
                            },
                        },
                    ],
                },
                // https://zenerab.atlassian.net/browse/UATOFEX-182
                layout: {
                    padding: {
                        top: 20,
                    },
                },
                tooltips: {
                    backgroundColor: "#FFF",
                    borderColor: "#417290",
                    borderWidth: 1,
                    titleFontColor: "#417290",
                    callbacks: {
                        label: function () {
                            return "";
                        },
                        title: function (tooltipItem) {
                            console.debug(tooltipItem);
                            let invoice = vueContext.getInvoiceByDatasetIndex(tooltipItem[0].datasetIndex, tooltipItem[0].index);
                            return vueContext.$i18n.t(
                                vueContext.$isWebApp() ? "ofex.invoiceEvolutionView.consumoHasta" : "ofex.invoiceEvolutionView.consumoHastaNative",
                                {
                                    date: invoice.endDate,
                                    amount: vueContext.$formatNumber(Math.trunc(invoice.amount)),
                                }
                            );
                        },
                    },
                },
            };

            let invoicesEvolutionBarData = {
                datasets: [
                    {
                        data: this.secondDatasetAmount,
                        maxBarThickness: 80,
                        hoverBackgroundColor: this.secondDatasetHoverColor,
                        backgroundColor: this.secondDatasetBackgroundColor,
                        label: this.$t("modules.invoiceEvolutionChart.periodoAnnoAnterior"),
                        minBarLength: 5,
                    },
                    {
                        data: this.firstDatasetAmount,
                        maxBarThickness: 80,
                        hoverBackgroundColor: this.firstDatasetHoverColor,
                        backgroundColor: this.firstDatasetBackgroundColor,
                        label: `${this.$t("ofex.invoiceEvolutionView.ultimasFacturas", { invoice: this.invoicesNumberByFrequency(this.invoicesFrequency) })}`,
                        minBarLength: 5,
                    },
                ],
                labels: this.monthYearsLabels,
            };

            if (this.firstDatasetAmount && this.secondDatasetAmount) {
                let mergedDatasets = [this.firstDatasetAmount, this.secondDatasetAmount].reduce((a, b) => a.concat(b));
                let max = calculateChartMaxYAxis(mergedDatasets, 20);
                console.debug(`Máximo valor en eje Y calculado: ${max}`);
                // invoicesEvolutionBarOptions.scales.yAxes[0].ticks.max = max;
            }

            this.renderChart(invoicesEvolutionBarData, invoicesEvolutionBarOptions);
        },
        calculateDataset: function (start, amount) {
            let dataset = slice(this.userInvoices, start, start + amount).reverse();

            let fillLenght = this.invoicesNumberByFrequency(this.invoicesFrequency) - dataset.length;
            if (fillLenght > 0) {
                let filledDataset = [...Array(fillLenght).fill({ amount: null }, 0, fillLenght), ...dataset];

                //calcular fecha de meses
                for (let i = filledDataset.length - 2; i >= 0; i--) {
                    if (filledDataset[i].endDate === undefined) {
                        //rellenamos los valores que se han rellenado hacia atrás
                        filledDataset[i] = {
                            amount: filledDataset[i].amount,
                            endDate: moment(filledDataset[i + 1].endDate, AB_DATE_FORMAT, "es")
                                .subtract(this.invoicesSeparationByFrequency(this.invoicesFrequency), "months")
                                .format(AB_DATE_FORMAT),
                        };
                    }
                }
                console.info(filledDataset);
                return filledDataset;
            } else {
                return dataset;
            }
        },
        invoicesNumberByFrequency: function (frequency) {
            switch (frequency) {
                case "ANUAL":
                    return 1;
                case "BIMESTRAL":
                    return 6;
                case "MENSUAL":
                    return 12;
                case "TRIMESTRAL":
                    return 4;
                default:
                    return NaN;
            }
        },
        invoicesSeparationByFrequency: function (frequency) {
            switch (frequency) {
                case "ANUAL":
                    return 12;
                case "BIMESTRAL":
                    return 2;
                case "MENSUAL":
                    return 1;
                case "TRIMESTRAL":
                    return 3;
                default:
                    return NaN;
            }
        },
        getInvoiceByDatasetIndex(datasetIndex, itemIndex) {
            return datasetIndex === 0 ? this.secondDataset[itemIndex] : this.firstDataset[itemIndex];
        },
    },
    computed: {
        firstDataset: function () {
            return this.calculateDataset(0, this.invoicesNumberByFrequency(this.invoicesFrequency));
        },
        secondDataset: function () {
            return this.calculateDataset(this.invoicesNumberByFrequency(this.invoicesFrequency), this.invoicesNumberByFrequency(this.invoicesFrequency));
        },
        firstDatasetAmount: function () {
            return this.firstDataset.map((i) => i.amount);
        },
        secondDatasetAmount: function () {
            return this.secondDataset.map((i) => i.amount);
        },
        firstDatasetBackgroundColor: function () {
            return this.firstDataset.map((i) => (i.estimatedReading ? "#008f39" : "#417290"));
        },
        firstDatasetHoverColor: function () {
            return this.firstDataset.map((i) => (i.estimatedReading ? "#31cb6f" : "#95CAEA"));
        },
        secondDatasetBackgroundColor: function () {
            return this.secondDataset.map((i) => (i.estimatedReading ? "#008f39" : "#417290"));
        },
        secondDatasetHoverColor: function () {
            return this.secondDataset.map((i) => (i.estimatedReading ? "#31cb6f" : "#95CAEA"));
        },
        monthsLabels: function () {
            return this.firstDataset.map((i) => moment(i.endDate, AB_DATE_FORMAT, "es").format("MMM").toUpperCase());
        },
        monthYearsLabels: function () {
            return zip(this.secondDataset, this.firstDataset).map((pair) => {
                let month = moment(pair[1].endDate, AB_DATE_FORMAT, "es").format("MMM").toUpperCase();
                let startYear = moment(pair[0].endDate, AB_DATE_FORMAT, "es").year();
                let endYear = moment(pair[1].endDate, AB_DATE_FORMAT, "es").year();
                let yearLabel = startYear ? `${startYear} - ${endYear}` : `${endYear - 1} - ${endYear}`;
                return [yearLabel, month];
            });
        },
        /**
         * Calculamos la frecuencia en base a las 2 primeras facturas  MENSUAL<49 BIMESTRAL >80 -> ANUAL"

         * @returns {string}
         */
        invoicesFrequency: function () {
            if (this.userInvoices.length > 1) {
                let firstEndDate = moment(this.userInvoices[0].endDate, AB_DATE_FORMAT);
                let secondEndDate = moment(this.userInvoices[1].endDate, AB_DATE_FORMAT);
                let invoicesDayInterval = firstEndDate.diff(secondEndDate, "days");

                if (invoicesDayInterval < 49) {
                    return "MENSUAL";
                } else if (invoicesDayInterval >= 49 && invoicesDayInterval < 80) {
                    return "BIMESTRAL";
                } else {
                    return "ANUAL";
                }
            } else {
                //TODO que hacer si no hay facturas
                return "ANUAL";
            }
        },
    },
    watch: {
        userInvoices: function () {
            console.log("Cambiando datos de facturas en gráfico");
            // this._chart.destroy();
            this.renderInvoiceEvolutionChart();
        },
    },
    mounted() {
        this.renderInvoiceEvolutionChart();
        executeAccessibilityFixes();
    },
};
</script>

<style lang="scss">
@font-face {
    font-family: faGraph;
    src: url(../../assets/fonts/fa-solid-900.woff);
}
</style>
