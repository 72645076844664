<template>
    <div :class="fatherClass">
        <div class="m-notices" v-if="notificationsPage && notificationsPage.length > 0">
            <ul class="dropdown-list">
                <li :class="`item ${notificationTypeCss(item.frequency)}`" :key="item.id" v-for="item in notificationsPage">
                    <div class="notification-link" :to="`/notifications/${item.id}`" @click="goToNotificationDetail(item.id)">
                        <div class="d-flex">
                            <div class="img-container">
                                <img :alt="item.type.type" :src="require(`../../assets/images/svg/AVISOS/${item.type.categoryCode}.svg`)" />
                            </div>
                            <div class="item-container">
                                <span class="notification-title">{{ item.summary }}</span>
                                <span class="notice-type" v-if="headerNotice">{{ $t("ofex.notificationsView.frequency." + item.frequency) }}</span>
                                <div class="info" v-html="item.body"></div>
                            </div>
                        </div>
                        <div v-if="headerNotice">
                            <b-dropdown :id="item.id" dropleft size="lg" variant="link" toggle-class="text-decoration-none" no-caret class="table-dropdown">
                                <b-dropdown-item-button
                                    aria-describedby="desplegable"
                                    @click.stop="goToNotificationDetail(item.id)"
                                    text="desplegable detalle contrato"
                                >
                                    {{ $t("ofex.tuContrato.detail") }}
                                </b-dropdown-item-button>
                                <b-dropdown-item-button aria-describedby="desplegable" v-if="item.metadata.invoiceNumber" @click.stop="downloadInvoice(item)">
                                    {{ $t("ofex.buttonsOfex.descargarFactura") }}
                                </b-dropdown-item-button>
                                <b-dropdown-item-button aria-describedby="desplegable" v-if="item.type.categoryCode === 'A001'" @click.stop="goToReadingMeter">
                                    {{ $t("ofex.notificationsView.readingDropdown") }}
                                </b-dropdown-item-button>
                            </b-dropdown>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="m-notices text-center d-flex justify-content-center align-items-center height-special p-3" v-else>
            <span class="text-information" v-html="text"></span>
        </div>
        <div class="pagination box mt-4" v-if="pagination">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="pageSize" aria-controls=""></b-pagination>
        </div>
    </div>
</template>

<script>
import * as viewMappingTypes from "@/router/view-mapping-types";
import router from "../../router/router";
export default {
    name: "AbNotificationsList",
    props: {
        headerNotice: {
            type: Boolean,
            default: true,
        },
        fatherClass: {
            type: String,
            default: "",
        },
        notifications: {
            type: Array,
            required: true,
        },
        pagination: {
            type: Boolean,
            default: false,
        },
        pageSize: {
            type: Number,
            default: 10,
        },
        text: {
            type: String,
            default: "",
        },
    },
    data: function () {
        return {
            imageSrc: {
                type: String,
                default: "../../assets/images/svg/AVISOS/Lectura.svg",
            },
            viewMappingTypes: viewMappingTypes,
            currentPage: 1,
            invoiceBaseURL: process.env.VUE_APP_API_INVOICES_BASE_URL,
        };
    },
    methods: {
        notificationTypeCss: function (frequency) {
            switch (frequency) {
                case "PUNTUAL":
                    return "punctual";
                case "PERIODICO":
                    return "periodic";
                case "ESPECIAL":
                    return "special";
                default:
                    return "";
            }
        },
        goToNotificationDetail: function (notificationId) {
            router.push({ name: viewMappingTypes.NOTIFICATION_DETAIL_MAPPING.name, params: { notificationId } });
        },
        downloadInvoice: function (item) {
            this.$downloadInvoice({
                invoiceNumber: item.metadata.invoiceNumber.substring(4),
                invoiceYear: item.metadata.invoiceNumber.substring(0, 4),
                contractNumber: item.contractNumber,
                locale: this.$i18n.locale,
            });
        },
        goToReadingMeter: function () {
            router.push({ name: viewMappingTypes.READ_METER_SELECTOR_MAPPING.name });
        },
    },
    computed: {
        totalRows: function () {
            return this.notifications ? this.notifications.length : 0;
        },
        notificationsPage: function () {
            let start = (this.currentPage - 1) * this.pageSize;
            return this.pagination === true ? this.notifications?.slice(start, start + this.pageSize) : this.notifications;
        },
    },
};
</script>

<style lang="scss" scoped>
.img-caution {
    height: 25px;
    width: 25px;
}
.m-notices {
    .dropdown-list {
        .notification-link {
            justify-content: space-between;
            padding-right: 0;
        }
        .table-dropdown {
            .btn {
                padding-right: 0;
            }
        }
        .item-container {
            .notification-title {
                white-space: normal;
                margin-right: 15px;
            }
            .info {
                color: $color-primary;
                @include font-size(16);
                @include line-height(18);
                font-weight: $font-primary-medium;
            }
        }
    }
    .text-information {
        color: $blue-dark;
        font-weight: $font-primary-semiBold;
        @include font-size(16);
    }
}
</style>
<style lang="scss">
.height-special {
    height: 225px !important;
}
.m-notices {
    .notice-type {
        position: relative;
        padding-left: 14px;
        font-weight: $font-primary-bold;
        @include font-size(12);
        @include line-height(30);
        white-space: nowrap;
        vertical-align: top;
        display: inline-flex;

        &:before {
            position: absolute;
            content: "";
            border-radius: 50%;
            top: 11px;
            left: 0;
            width: 8px;
            height: 8px;
        }
    }
    .item {
        &.special {
            .notice-type {
                color: $special-notice;
            }
            .notice-type:before {
                background: $special-notice;
            }
        }
        &.periodic {
            .notice-type {
                color: $periodic-notice;
            }
            .notice-type:before {
                background: $periodic-notice;
            }
        }
        &.punctual {
            .notice-type {
                color: $punctual-notice;
            }
            .notice-type:before {
                background: $punctual-notice;
            }
        }
    }
}
</style>
